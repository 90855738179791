import React, { useEffect } from "react";

import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../../redux/store";

import {
	fetchAllevamentiCommittente,
	AllevamentiCommittente,
} from "../ospitiSlice";
import Pagination from "@mui/material/Pagination";
import { Committente, Allevamento } from "../../anagrafiche/anagraficheSlice";

import AllevamentiCommittenteCard from "./AllevamentiCommittenteCard";
import { utenteAutorizzato } from "../../../authentication/UtenteAutorizzato";

import { UtenteLogged } from "../../../authentication/authenticationSlice";
import { PermissionTypes } from "../../../authentication/permissionsGroups";

import { DateTime } from 'luxon';
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import AllevamentoPage from "../../anagrafiche/allevamento/AllevamentoPage";

import PaperMui from "@mui/material/Paper";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid2";

import {
	ErrorStatusTypes,
	ErrorsStack,
} from "../../../common/errorsDeclarations";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled, Box } from "@mui/system";

const Paper = styled(PaperMui)(({ theme }) => ({
	color: theme.palette.primary.contrastText,
	backgroundColor: "#b0b0b0",
	padding: theme.spacing(1),
	borderRadius: theme.shape.borderRadius,
}));

interface AllevamentiCommittenteBoxProps {
	committenteCorrente: Committente;
	numeroRecord?: number;
}

const AllevamentiCommittenteBox = ({
	committenteCorrente,
	numeroRecord,
}: AllevamentiCommittenteBoxProps) => {
	
	const dispatch = useAppDispatch();

	const allevamenti: AllevamentiCommittente = useSelector<
		RootState,
		AllevamentiCommittente
	>((state) => state.ospitiState.allevamentiCommittente);

	const utente = useSelector<RootState, UtenteLogged>(
		(state) => state.authentication.utenteLogged
	);

	const [page, setPage] = React.useState<number>(1);
	const [allevamentoCorrente, setAllevamentoCorrente] =
		React.useState<Allevamento>();

	const handlerPager = (event: React.ChangeEvent<unknown>, page: number) => {
		setPage(page);
	};

	useEffect(() => {
		setPage(1);
	}, [committenteCorrente]);

	useEffect(() => {
		committenteCorrente?.id &&
			dispatch(
				fetchAllevamentiCommittente({
					committente_id: committenteCorrente?.id,
					numeroRecord: numeroRecord,
					page: page,
				})
			);
	}, [committenteCorrente, page]);

	return (
		<Box sx={{ m: 1, bgcolor: "background.paper" }}>
			{allevamenti.errorsStack.status == ErrorStatusTypes.PENDING ? (
				<Typography align="center" sx={{ p: "15vh" }}>
					<CircularProgress color="inherit" />
				</Typography>
			) : (
				<Grid container spacing={{ xs: 0.5, md: 1, lg: 4 }}>
					<Grid size={{  md:12, lg:allevamentoCorrente ? 5 : 12 }}>
						<List sx={{ mt: 2 }}>
							<ListItemText
								key={"Box_allevamentoCommittente_page_0"}
								// component={Link}
								// to={Routes_path.LOTTO_COMMITTENTE + allevamento.id}
								sx={{ backgroundColor: "#1b75b1", px: 2, color: "#ddd" }}
							>
								<Grid container spacing={{ xs: 0.5, md: 1, lg: 2 }} sx={{ width:"100%"}}>
									<Grid size={{ xs:5, sm:4, md:3, lg:3 }}>
										<Typography variant="h6" gutterBottom>
											Allevamento
										</Typography>
									</Grid>

									<Grid size={{ xs:4, sm:4, md:3, lg:4 }}>
										<Typography
											variant="h6"
											// component="div"
											gutterBottom
											align="left"
										>
											Indirizzo
										</Typography>
									</Grid>
									<Grid size={{ xs:3, sm:3, md:3, lg:3 }}>
										<Typography
											variant="h6"
											// component="div"
											gutterBottom
											align="left"
										>
											Distanza
										</Typography>
									</Grid>
									<Grid size={{ xs:3, sm:3, md:3, lg:2 }}>
										<Typography
											variant="h6"
											// component="div"
											gutterBottom
											align="left"
										>
											Resa ultimi 6 mesi
										</Typography>
									</Grid>
								</Grid>
							</ListItemText>

							{allevamenti.results.map((allevamento) => (
								<AllevamentiCommittenteCard
									key={"allevamenti_lotto_" + allevamento.id}
									allevamento={allevamento}
									setAllevamentoCorrente={() => {
										if (
											utenteAutorizzato(utente, [
												PermissionTypes.PIANIFICATORI,
												PermissionTypes.OPERATORI_LOGISTICA,
												PermissionTypes.SCHEDULATORI_LAVORAZIONI,
											])
										) {
											setAllevamentoCorrente(allevamento);
										}
									}}
								/>
							))}
							{!numeroRecord && (
								<ListItem sx={{ alignContent: "right" }}>
									<Pagination
										count={allevamenti.num_pages}
										onChange={handlerPager}
										page={page}
									/>
								</ListItem>
							)}
						</List>
					</Grid>
					<Grid size={{  md:12, lg:7 }} sx={{ mt: 10, pr: 2 }}>
						<AllevamentoPage
							allevamento_id={allevamentoCorrente?.id}
							annulla={() => {
								if (
									utenteAutorizzato(utente, [
										PermissionTypes.PIANIFICATORI,
										PermissionTypes.OPERATORI_LOGISTICA,
										PermissionTypes.SCHEDULATORI_LAVORAZIONI,
									])
								) {
									setAllevamentoCorrente(undefined);
								}
							}}
						/>
					</Grid>
				</Grid>
			)}
		</Box>
	);
};

export default AllevamentiCommittenteBox;
