import React from "react";
import {Text, View, StyleSheet, Image} from "@react-pdf/renderer";
import {useState} from "react";

import {
    ColoreTypes,
    Lotto,
} from "components/main/pianificazione/pianificazioneSlice";
import {getColore} from "components/main/pianificazione/utility/lottoUtility";
import {Lavorazione} from "components/main/lavorazioni/lavorazioniSlice";
import { DateTime } from 'luxon';
import em_poultry from "../../../../../../static/images/common/em_poultry.png";
import {PackingList, Pallet} from "../../pesaturaPalletSlice";


interface Rafood6789PdfProps {
    // colore: string
    packingList: PackingList;
    // pallets: Pallet[];
}

const Rafood6789Pdf = ({packingList}: Rafood6789PdfProps) => {

    return (
        <>
            <View style={{
                flexDirection: "column",
                justifyContent: "space-between",
                borderStyle: "solid",
                borderColor: "#000",
                borderWidth: 1,
                padding: 5,
                height: 97
            }}>
                <View style={{flexDirection: "row"}}>
                    <View style={{flexDirection: "column",  width: '25%', padding:3}}>
                        <View style={{flexDirection: "row",}}>
                            <Text style={{fontSize: 9,}}>6</Text>
                            <View style={{flexDirection: "column", marginLeft: 6,}}>
                                <Text style={{fontSize: 6,}}>
                                    Contrassegni e numeri
                                </Text>
                                <Text style={{fontSize: 6,}}>
                                    Marks and Nos
                                </Text>
                                <Text style={{fontSize: 6,}}>
                                    Kennzeichen und Nummern
                                </Text>
                            </View>
                        </View>
                        <View style={{flexDirection: "column", marginTop: 5}}>
                            {/*<Text style={{fontSize: 10,}}>Magnum Boxed</Text>*/}
                        </View>
                    </View>
                    <View style={{flexDirection: "column",  width: '25%', padding:3}}>
                        <View style={{flexDirection: "row", }}>
                            <Text style={{fontSize: 9,}}>7</Text>
                            <View style={{flexDirection: "column", marginLeft: 6,}}>
                                <Text style={{fontSize: 6,}}>
                                    Numero di colli
                                </Text>
                                <Text style={{fontSize: 6,}}>
                                    Number of packages
                                </Text>
                                <Text style={{fontSize: 6,}}>
                                    Anzahl der Packstücke
                                </Text>
                            </View>
                        </View>
                        <View style={{flexDirection: "column", marginTop: 5}}>
                            <Text style={{fontSize: 16, textAlign:'center'}}>{packingList.num_pallets}</Text>
                        </View>
                    </View>
                    <View style={{flexDirection: "column",  width: '25%', padding:3}}>
                        <View style={{flexDirection: "row", }}>
                            <Text style={{fontSize: 9,}}>8</Text>
                            <View style={{flexDirection: "column", marginLeft: 6,}}>
                                <Text style={{fontSize: 6,}}>
                                    Imballaggio
                                </Text>
                                <Text style={{fontSize: 6,}}>
                                    Method of packaging
                                </Text>
                                <Text style={{fontSize: 6,}}>
                                    Art der Verpackung
                                </Text>
                            </View>
                        </View>
                        <View style={{flexDirection: "column", marginTop: 5}}>
                            <Text style={{fontSize: 10,}}>Magnum Boxed</Text>
                        </View>
                    </View>

                    <View style={{flexDirection: "column",  width: '25%', padding:3}}>
                        <View style={{flexDirection: "row",}}>
                            <Text style={{fontSize: 9,}}>9</Text>
                            <View style={{flexDirection: "column", marginLeft: 6,}}>
                                <Text style={{fontSize: 6,}}>
                                    Denominaz. merce
                                </Text>
                                <Text style={{fontSize: 6,}}>
                                    Nature of the goods
                                </Text>
                                <Text style={{fontSize: 6,}}>
                                    Bezeichnung des Gutes
                                </Text>
                            </View>
                        </View>
                        <View style={{flexDirection: "column", marginTop: 5}}>
                            <Text style={{fontSize: 10,}}>Fresh Whole Heavy Hens</Text>
                        </View>
                    </View>
                </View>


            </View>

        </>
    );
}

export default Rafood6789Pdf;
