import React, { useState, useEffect, Fragment } from "react";
import { RootState, useAppDispatch } from "../../../redux/store";

import { Camion } from "../anagrafiche/anagraficheSlice";
import { assegnaCamion } from "../schedeCarico/schedeCaricoSlice";
import { Box } from "@mui/system";
import Stack from "@mui/material/Stack";
import DnDBox from "../../common/DnDBox";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid2";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";

import Typography from "@mui/material/Typography";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import LinearProgress from "@mui/material/LinearProgress";
import Tooltip from "@mui/material/Tooltip";

import RvHookupIcon from "@mui/icons-material/RvHookup";

interface CamionCardProps {
	camion: Camion;
	handlerAssegnaCamion: (
		dragId: number,
		hoverValue: {
			schedaCarico_id: number;
			lotto_id: number;
			data_lavorazione: Date | string;
		}
	) => void;
}

const CamionCard = ({ camion, handlerAssegnaCamion }: CamionCardProps) => {
	return (
		<DnDBox
			key={camion.id}
			id={camion.id || 9999}
			index={camion.id}
			typeBox={"CAMION"}
			accept={"day_"}
			moveDnDBox={() => {
				console.log("CAMION");
			}}
			dragDnDBox={handlerAssegnaCamion}
			// disabled={lotto.resa_confermata}
		>
			<Card
				key={camion.targa}
				sx={{
					backgroundColor:
						camion.stato_camion_alla_data.stato == 0
							? "white"
							: camion.stato_camion_alla_data.stato == 1
							? "lightgreen"
							: camion.stato_camion_alla_data.stato == 2
							? "#51acf5"
							: camion.stato_camion_alla_data.stato == 3
							? "#ffdc10"
							: camion.stato_camion_alla_data.stato == 4
							? "#999"
							: "#ff8072",
					border: camion.stato_camion_alla_data.viaggio_lungo ? "1" : "inherit",
					borderStyle: camion.stato_camion_alla_data.viaggio_lungo
						? "solid"
						: "inherit",
					borderColor: camion.stato_camion_alla_data.viaggio_lungo
						? "darkviolet"
						: "inherit",

					pb: 0,
				}}
			>
				<CardContent sx={{ p: 0.6, "&:last-child": { pb: 0 } }}>
					<Grid container spacing={0.5}>
						<Grid size={{  xs:2}}>
							<Box sx={{ flexGrow: 1 }} display="grid" justifyContent="center">
								{/* {camion.numero_fori != 96 && <RvHookupIcon />} */}
								<Tooltip
									title={
										camion.stato_camion_alla_data.descrizione +
										" " +
										camion.stato_camion_alla_data.messaggio
									}
									placement="left-start"
									disableFocusListener
									enterTouchDelay={10}
									arrow
								>
									<LocalShippingIcon
										sx={{
											// color: camion.stato_camion_alla_data.viaggio_lungo
											// 	? "darkviolet"
											// 	: "inherit",
											color: camion.stato_camion_alla_data.viaggio_lungo
												? "darkviolet"
												: camion.stato_camion_alla_data.stato == 0
												? "#555"
												: camion.stato_camion_alla_data.stato == 1
												? "green"
												: camion.stato_camion_alla_data.stato == 2
												? "#053df5"
												: camion.stato_camion_alla_data.stato == 3
												? "#ffa010"
												: camion.stato_camion_alla_data.stato == 4
												? "#222"
												: "red",
										}}
									/>
								</Tooltip>
							</Box>
						</Grid>
						<Grid size={{  xs:5}}>
							{camion.nome}
						</Grid>
						<Grid size={{  xs:5}}>
							{camion.targa}
						</Grid>
					</Grid>
					<Grid container spacing={0.5}>
						<Grid size={{ xs:12 }}>
							<Box sx={{ width: "100%", p: 1 }}>
								<Box sx={{ display: "flex", alignItems: "center" }}>
									<Box sx={{ width: "100%", mr: 1 }}>
										<LinearProgress
											key={"LinearProgress_" + camion.id}
											variant="determinate"
											value={
												camion.saturazione > 100 ? 100 : camion.saturazione
											}
											color={
												camion.saturazione == 100
													? "success"
													: camion.saturazione > 100
													? "error"
													: "secondary"
											}
										/>
									</Box>
									<Box sx={{ minWidth: 35 }}>
										<Typography
											variant="body2"
											color="text.secondary"
										>{`${Math.round(camion.saturazione)}%`}</Typography>
									</Box>
								</Box>
							</Box>
						</Grid>
					</Grid>
				</CardContent>
			</Card>
		</DnDBox>
	);
};

interface CamionsBoxProps {
	camions: Camion[];
}
const CamionsBox = ({ camions }: CamionsBoxProps) => {
	const style = {
		position: "absolute" as "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		maxWidth: 400,
		width: "95%",
		bgcolor: "background.paper",
		border: "2px solid #000",
		boxShadow: 24,
		p: 4,
		pb: 1,
		textAlign: "center",
	};

	const [tuttiCamionOn, setTuttiCamionOn] = useState<boolean>(false);
	const dispatch = useAppDispatch();

	const handlerAssegnaCamion = (
		dragId: number,
		hoverValue: {
			schedaCarico_id: number;
			lotto_id: number;
			data_lavorazione: Date | string;
		}
	) => {
		// dragId identifica il camion
		// in hoverValue si identifica id della scheda carico, id del lotto e data di lavorazione
		hoverValue &&
			dispatch(
				assegnaCamion({
					camion_id: dragId,
					schedaCarico_id: hoverValue.schedaCarico_id,
					lotto_id: hoverValue.lotto_id,
					data_lavorazione: hoverValue.data_lavorazione,
				})
			);
	};

	return (
		<Stack direction="column" spacing={1} justifyContent="center">
			{camions
				.filter((camion) => camion.proprieta == true)
				.map((camion) => (
					<CamionCard
						key={"camion_" + camion.id}
						camion={camion}
						handlerAssegnaCamion={handlerAssegnaCamion}
					/>
				))}

			{tuttiCamionOn && (
				<Fragment>
					<Divider>Camion non di proprietà</Divider>
					{camions
						.filter((camion) => camion.proprieta == false)
						.map((camion) => (
							<CamionCard
								key={"camion_" + camion.id}
								camion={camion}
								handlerAssegnaCamion={handlerAssegnaCamion}
							/>
						))}
				</Fragment>
			)}
			<Button
				onClick={() => setTuttiCamionOn(!tuttiCamionOn)}
				sx={{ color: "white" }}
			>
				{" "}
				{tuttiCamionOn
					? "Mostra solo camion di proprietà"
					: "Mostra tutti i camion"}
			</Button>
		</Stack>
	);
};

export default CamionsBox;
