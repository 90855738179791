import React from "react";
import {Text, View, StyleSheet, Image} from "@react-pdf/renderer";
import {useState} from "react";

import {
    ColoreTypes,
    Lotto,
} from "components/main/pianificazione/pianificazioneSlice";
import {getColore} from "components/main/pianificazione/utility/lottoUtility";
import {Lavorazione} from "components/main/lavorazioni/lavorazioniSlice";
import { DateTime } from 'luxon';
import em_poultry from "../../../../../../static/images/common/em_poultry.png";
import {PackingList, Pallet} from "../../pesaturaPalletSlice";


interface Rafood19PdfProps {
    // colore: string
    packingList: PackingList;
    // pallets: Pallet[];
}

const Rafood19Pdf = ({packingList}: Rafood19PdfProps) => {

    return (
        <>
            <View style={{
                flexDirection: "column",
                // justifyContent: "space-between",
                borderStyle: "solid",
                borderColor: "#000",
                borderWidth: 1,
                padding:2,
                height: 70
            }}>
                <View key={'rettangolo_colorato_numero'} style={{flexDirection: "row"}}>
                    <Text style={{fontSize: 9,}}>19</Text>
                    <View
                        style={{flexDirection: "column", marginLeft: 2,}}
                    >
                        <Text style={{fontSize: 5,}}>
                            Convenzioni particolari
                        </Text>
                        <Text style={{fontSize: 5,}}>
                            Special agreements
                        </Text>
                        <Text style={{fontSize: 5,}}>
                            Besondere Vereinbarungen
                        </Text>
                    </View>
                </View>
                {/*<Text style={{fontSize: 12,marginTop:10}}>Macellazione:  {convertToDateTime(packingList.data)?.setLocale("it").toFormat('dd/MM/yyyy')}</Text>*/}
                <Text style={{fontSize: 8,marginTop:2}}>Schlachtdatum/Macellazione:  {packingList.date_macellazione?.join(",")}</Text>
                <Text style={{fontSize: 8,marginTop:2}}>MHD/Scadenza:  {packingList.date_scadenza?.join(",")}</Text>
                <Text style={{fontSize: 8,marginTop:2}}>Charge nr./Lotto nr.:  {packingList.lista_lotti?.join(",")}</Text>
                <Text style={{fontSize: 8,marginTop:2}}>nr.order:  {packingList.lista_ordini_cliente?.join(",")}</Text>

            </View>

        </>
    );
}

export default Rafood19Pdf;
