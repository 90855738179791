import React from "react";
import {Text, View, StyleSheet, Image} from "@react-pdf/renderer";
import {useState} from "react";

import {
    ColoreTypes,
    Lotto,
} from "components/main/pianificazione/pianificazioneSlice";
import {getColore} from "components/main/pianificazione/utility/lottoUtility";
import {Lavorazione} from "components/main/lavorazioni/lavorazioniSlice";
import moment from "moment";
import em_poultry from "../../../../../../static/images/common/em_poultry.png";
import {PackingList, Pallet} from "../../pesaturaPalletSlice";
import RafoodSpallaPdf from "./RafoodSpallaPdf";
import RafoodIntestazioneCopiaPdf from "./RafoodIntestazioneCopiaPdf";
import RafoodSpallaDestraPdf from "./RafoodSpallaDestraPdf";
import Rafood1Pdf from "./Rafood1Pdf";
import Rafood2Pdf from "./Rafood2Pdf";
import Rafood3Pdf from "./Rafood3Pdf";
import Rafood4Pdf from "./Rafood4Pdf";
import Rafood5Pdf from "./Rafood5Pdf";
import RafoodLetteraVetturaPdf from "./RafoodLetteraVetturaPdf";
import Rafood16Pdf from "./Rafood16Pdf";
import Rafood18Pdf from "./Rafood18Pdf";
import Rafood6789Pdf from "./Rafood6789Pdf";
import RafoodSub6789Pdf from "./RafoodSub6789Pdf";
import Rafood10Pdf from "./Rafood10Pdf";
import Rafood11Pdf from "./Rafood11Pdf";
import Rafood12Pdf from "./Rafood12Pdf";
import Rafood13Pdf from "./Rafood13Pdf";
import Rafood14Pdf from "./Rafood14Pdf";
import Rafood21Pdf from "./Rafood21Pdf";
import Rafood19Pdf from "./Rafood19Pdf";
import Rafood20Pdf from "./Rafood20Pdf";
import Rafood15Pdf from "./Rafood15Pdf";
import Rafood22Pdf from "./Rafood22Pdf";
import Rafood23Pdf from "./Rafood23Pdf";
import Rafood24Pdf from "./Rafood24Pdf";

// Create styles
const styles = StyleSheet.create({
    page: {
        backgroundColor: "#fff",
        paddingTop: 20,
    },
    section: {
        marginTop: 10,
        marginLeft: 10,
        marginRight: 10,
        padding: 10,
    },
    sectionIntestazione: {
        // width: "100%",
        marginTop: 10,
        marginHorizontal: 20,
        padding: 5,
        // paddingTop: 10,
        // paddingBottom: 7,
        // backgroundColor: "#777777",
        // color: "#ffffff",
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: "#000",
    },
    text: {
        fontSize: 15,
        fontStyle: "medium",
        // marginVertical: 20,
    },
    sectionTable: {
        flexDirection: "row",
        justifyContent: "space-between",
        marginLeft: 0,
        borderBottomWidth: 1,
    },
    sectionFrame: {
        flexDirection: "row",
        paddingHorizontal: 60,
        paddingVertical: 40,
        fontSize: 180,
        fontStyle: "medium",
        borderWidth: 1,
    },
});

interface RafoodPdfProps {
    packingList: PackingList;
    // pallets: Pallet[];
    colore?: string;
    numeroPagina: string;
    testoItaliano: string;
    testoFrancese: string;
    testoInglese: string;
    testoTedesco: string;
}

const RafoodPdf = ({
                       packingList,
                       colore,
                       numeroPagina,
                       testoItaliano,
                       testoFrancese,
                       testoInglese,
                       testoTedesco
                   }: RafoodPdfProps) => {
    return (
        <>
            <View key={'colonne_principali'} style={{flexDirection: "row", justifyContent: "center",}}>
                <View key={'prima_colonna'} style={{width: '6%'}}>
                    <RafoodSpallaPdf colore={colore}/>
                </View>
                <View key={'prima_colonna'} style={{width: '88%', paddingLeft: 5}}>
                    <RafoodIntestazioneCopiaPdf colore={colore}
                                                numeroPagina={numeroPagina}
                                                testoItaliano={testoItaliano}
                                                testoFrancese={testoFrancese}
                                                testoInglese={testoInglese}
                                                testoTedesco={testoTedesco}
                    />
                    <View key={'rettangolone'} style={{
                        flexDirection: "column",
                        borderStyle: "solid",
                        borderColor: "#000",
                        borderWidth: 1,
                        width: '100%'
                    }}>
                        <View key={'rettangolone'} style={{flexDirection: "row",}}>
                            <View style={{
                                width: '50%',
                                flexDirection: "column",
                                // justifyContent: "center",
                            }}>
                                <Rafood1Pdf/>
                                <Rafood2Pdf/>
                                <Rafood3Pdf/>
                                <Rafood4Pdf packingList={packingList}/>
                                <Rafood5Pdf/>
                            </View>
                            <View style={{
                                width: '50%',
                                flexDirection: "column",
                            }}>
                                <RafoodLetteraVetturaPdf/>
                                <Rafood16Pdf packingList={packingList}/>
                                <Rafood18Pdf/>

                            </View>

                        </View>
                        <View key={'rettangolone'} style={{flexDirection: "row",}}>
                            <View style={{
                                width: '65%',
                                flexDirection: "column",
                            }}>
                                <Rafood6789Pdf packingList={packingList}/>
                                <RafoodSub6789Pdf/>
                            </View>
                            <View style={{width: '35%', flexDirection: "row",}}>
                                <View style={{width: '33%',}}>
                                    <Rafood10Pdf/>
                                </View>
                                <View style={{width: '34%',}}>
                                    <Rafood11Pdf packingList={packingList}/>
                                </View>
                                <View style={{width: '33%',}}>
                                    <Rafood12Pdf/>
                                </View>
                            </View>
                        </View>

                        <View style={{flexDirection: "row"}}>
                            <View style={{flexDirection: "column", width: '50%',}}>
                                <Rafood13Pdf/>
                                <Rafood14Pdf/>
                                <Rafood21Pdf packingList={packingList}/>
                            </View>
                            <View style={{flexDirection: "column", width: '50%',}}>
                                <Rafood19Pdf packingList={packingList}/>
                                <Rafood20Pdf/>
                                <Rafood15Pdf/>
                            </View>
                        </View>
                        <View style={{flexDirection: "row"}}>
                            <View style={{flexDirection: "column", width: '33%',}}>
                                <Rafood22Pdf/>
                            </View>
                            <View style={{flexDirection: "column", width: '33%',}}>
                                <Rafood23Pdf packingList={packingList}/>
                            </View>
                            <View style={{flexDirection: "column", width: '34%',}}>
                                <Rafood24Pdf/>
                            </View>
                        </View>
                    </View>
                </View>

                <View key={'prima_colonna'} style={{width: '6%'}}>
                    <RafoodSpallaDestraPdf/>
                </View>
            </View>

        </>
    );
}

export default RafoodPdf;
