import React from "react";
import { DateTime } from 'luxon';

import { PianiViaggio } from "../schedeCaricoSlice";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import Grid from "@mui/material/Grid2";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import RvHookupIcon from "@mui/icons-material/RvHookup";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import Button from "@mui/material/Button";
import {convertToDateTime} from "../../../common/dateUtils/convertToDateTime";

interface ViaggioAutistaCardProps {
	viaggioAutista: PianiViaggio;
	utenteGestore: boolean;
}
const ViaggioAutistaCard = ({
	viaggioAutista,
	utenteGestore,
}: ViaggioAutistaCardProps) => {
	// 

	return (
		<Card key={viaggioAutista.id} sx={{ my: 1 }} raised>
			<Grid container spacing={1}>
				<Grid size={{ xs:12, sm:4, md:2, lg:2 }}>
					<CardContent>
						<Typography
							sx={{ fontSize: 14 }}
							color="text.secondary"
							gutterBottom
						>
							autisti:
						</Typography>
						{viaggioAutista.autisti_pack.map((autista) => {
							return (
								<Stack
									direction="row"
									spacing={1}
									key={"viaggio_autista_" + autista.id}
								>
									<Typography
										variant="h6"
										color="text.secondary"
										gutterBottom
										key={"autista_" + autista.id}
									>
										{autista.nome + " " + autista.cognome}
									</Typography>
								</Stack>
							);
						})}
					</CardContent>
				</Grid>
				<Grid size={{ xs:12, sm:4, md:2, lg:2 }}>
					<CardContent>
						<Typography
							sx={{ fontSize: 14 }}
							color="text.secondary"
							gutterBottom
						>
							mezzo:
						</Typography>
						<Typography variant="h6" color="text.secondary">
							{viaggioAutista.camion_pack?.nome +
								" " +
								viaggioAutista.camion_pack?.targa}
						</Typography>
						<Typography variant="subtitle1" color="text.secondary" gutterBottom>
							{viaggioAutista.rimorchio
								? "   con rimorchio"
								: "   senza rimorchio"}
						</Typography>
						<Typography variant="subtitle1">
							gabbie:{" "}
							{Intl.NumberFormat("it-IT").format(
								viaggioAutista.numero_fori || 0
							)}
						</Typography>

						<Stack direction="row" spacing={0.4} justifyContent="flex-start">
							{viaggioAutista.rimorchio && (
								<Avatar sx={{ bgcolor: "#ddd" }}>
									<RvHookupIcon color="primary" fontSize="large" />
								</Avatar>
							)}
							<Avatar sx={{ bgcolor: "#ddd" }}>
								<LocalShippingIcon color="primary" fontSize="large" />
							</Avatar>
						</Stack>
					</CardContent>
				</Grid>
				<Grid size={{ xs:12, sm:4, md:2, lg:2 }}>
					<CardContent>
						<Typography
							sx={{ fontSize: 14 }}
							color="text.secondary"
							gutterBottom
						>
							appuntamento:
						</Typography>
						<Typography variant="h6" color="text.secondary">
							{convertToDateTime(viaggioAutista.data_ora_carico_pianificata)?.setLocale("it").toFormat('cccc, d LLLL yyyy, H:mm')}
						</Typography>

						<Typography variant="subtitle1" color="text.secondary">
							pesa:
						</Typography>
						{viaggioAutista.lotto_pack.allevamento_pack?.indirizzo_pesa ? (
							<Typography variant="h6" color="text.secondary" gutterBottom>
								{viaggioAutista.lotto_pack.allevamento_pack?.indirizzo_pesa}
							</Typography>
						) : (
							<Typography variant="h6" color="text.secondary" gutterBottom>
								presso l'allevamento
							</Typography>
						)}
					</CardContent>

					<CardContent>
						<Typography variant="subtitle1" color="text.secondary">
							contatti:
						</Typography>
						<Typography variant="h6" color="text.secondary" gutterBottom>
							{viaggioAutista.lotto_pack.allevamento_pack?.contatti?.map(
								(e) => e.valore
							)}
						</Typography>
					</CardContent>
				</Grid>
				<Grid size={{ xs:12, sm:6, md:4, lg:4 }}>
					<CardContent>
						<Typography
							sx={{ fontSize: 14 }}
							color="text.secondary"
							gutterBottom
						>
							Allevatore:
						</Typography>

						<Typography variant="h6" color="text.secondary">
							{viaggioAutista.lotto_pack.allevamento_pack?.nome +
								" (" +
								viaggioAutista.lotto_pack.allevamento_pack?.codice_asl +
								")"}
						</Typography>
						<Typography variant="h6" color="text.secondary">
							{viaggioAutista.lotto_pack.allevamento_pack?.indirizzo}
						</Typography>
						<Typography variant="h6" color="text.secondary" gutterBottom>
							{viaggioAutista.lotto_pack.allevamento_pack?.cap}{" "}
							{viaggioAutista.lotto_pack.allevamento_pack?.comune} -{" "}
							{viaggioAutista.lotto_pack.allevamento_pack?.provincia_desc}
						</Typography>
						<Stack direction="row" justifyContent="flex-start" spacing={1}>
							<Typography variant="h6" color="text.secondary" gutterBottom>
								{viaggioAutista.lotto_pack.allevamento_pack?.distanza}km
							</Typography>

							<Typography variant="subtitle1">
								animali x foro:{" "}
								{Intl.NumberFormat("it-IT").format(
									viaggioAutista.numero_capi_foro || 0
								)}
							</Typography>
						</Stack>
					</CardContent>
				</Grid>
				<Grid size={{ xs: 12, sm:6, md:2 }}>
					<CardContent>
						<Typography
							sx={{ fontSize: 14 }}
							color="text.secondary"
							gutterBottom
						>
							{"destinatario:   "}
						</Typography>
						<Typography variant="h6" gutterBottom color="text.secondary">
							{viaggioAutista.lotto_pack.committente_desc}
						</Typography>
						<Typography variant="h5" gutterBottom>
							{Intl.NumberFormat("it-IT").format(
								viaggioAutista.numero_capi_pianificati || 0
							)}{" "}
							{viaggioAutista.lotto_pack.tipo_capo_desc}
						</Typography>
					</CardContent>
				</Grid>
			</Grid>
		</Card>
	);
};

export default ViaggioAutistaCard;
