import React, { useState, useEffect } from "react";
import {GiudizioColoreTypes, SchedaMacello, UniformitaTypes} from "./schedeMacelloSlice";
import { Lotto } from "../pianificazione/pianificazioneSlice";
import { theme } from "../../../theme";
import moment from "moment";
import { NumericFormat, NumericFormatProps } from "react-number-format";

import { useForm, SubmitHandler, Controller } from "react-hook-form";
import FormHelperText from "@mui/material/FormHelperText";

import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

import { TimePicker } from "@mui/x-date-pickers/TimePicker";

import ClockIcon from "@mui/icons-material/AccessTime";
import AlarmIcon from "@mui/icons-material/Alarm";
import ShutterSpeedIcon from "@mui/icons-material/ShutterSpeed";
import SnoozeIcon from "@mui/icons-material/Snooze";
import { Button, IconButton } from "@mui/material";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import {PettorinaTypes} from "../lavorazioni/lavorazioniSlice";
import ReactHookFormSelect from "../../common/ReactHookFormSelect";

interface SchedaMacelloFormProps {
	schedaMacello: SchedaMacello;
	saveSchedaMacello: (schedaMacello: SchedaMacello) => void;
}

const SchedaMacelloForm = ({
	schedaMacello,
	saveSchedaMacello,
}: SchedaMacelloFormProps) => {
	const {
		handleSubmit,
		register,
		formState: { errors, isDirty },
		setValue,
		watch,
		getValues,
		control,
		setError,
		clearErrors,
		reset,
	} = useForm<SchedaMacello>();

	const [pausaOn, setPausaOn] = useState<boolean>(false);
	const listaCampi = Object.keys(schedaMacello) as Array<keyof SchedaMacello>;

	useEffect(() => {
		listaCampi.map((field) => {
			setValue(field, schedaMacello[field]);
		});
	}, [
		listaCampi.forEach((field) => {
			schedaMacello[field];
		}),
	]);

	useEffect(() => {
		clearErrors();
		listaCampi.forEach((field) => {
			schedaMacello?.errorsStack?.fieldsErrors &&
				schedaMacello.errorsStack.fieldsErrors[field] &&
				setError(field, {
					type: "server",
					message: schedaMacello.errorsStack.fieldsErrors[field].toString(),
				});
		});
	}, [schedaMacello?.errorsStack?.fieldsErrors]);

	useEffect(() => {
		const data_ora_inizio_pausa: Date | null | undefined = getValues(
			"data_ora_inizio_pausa"
		);
		const data_ora_fine_pausa: Date | null | undefined = getValues(
			"data_ora_fine_pausa"
		);

		const ora_inizio_pausa_presente = parseInt(
			moment(data_ora_inizio_pausa).format("HHmm")
		);
		const ora_fine_pausa_presente = parseInt(
			moment(data_ora_fine_pausa).format("HHmm")
		);

		if (!!ora_inizio_pausa_presente || !!ora_fine_pausa_presente) {
			setPausaOn(true);
		}
	}, [watch("data_ora_inizio_pausa"), watch("data_ora_fine_pausa")]);

	// useEffect(() => {
	// 	reset({}, { keepValues: true });
	// }, [schedaMacello, reset]);

	const onSubmit: SubmitHandler<SchedaMacello> = (schedaMacello) => {
		saveSchedaMacello(schedaMacello);
	};

	// #############################################
	// Controllo e gestioni errori
	// #############################################

	React.useEffect(() => {
		clearErrors("data_ora_inizio_macello");
		clearErrors("data_ora_fine_macello");
		clearErrors("data_ora_inizio_pausa");
		clearErrors("data_ora_fine_pausa");
		const data_ora_inizio_macello: Date | null | undefined = getValues(
			"data_ora_inizio_macello"
		);
		const data_ora_fine_macello: Date | null | undefined = getValues(
			"data_ora_fine_macello"
		);
		const data_ora_inizio_pausa: Date | null | undefined = getValues(
			"data_ora_inizio_pausa"
		);
		const data_ora_fine_pausa: Date | null | undefined = getValues(
			"data_ora_fine_pausa"
		);
		if (data_ora_inizio_macello && data_ora_fine_macello) {
			if (new Date(data_ora_inizio_macello) > new Date(data_ora_fine_macello)) {
				setError("data_ora_inizio_macello", {
					type: "custom",
					message: "Inizio macello successivo a fine macello",
				});
				setError("data_ora_fine_macello", {
					type: "custom",
					message: "Fine macello anteriore a inizio macello",
				});
			}
		}

		if (data_ora_inizio_pausa && data_ora_fine_pausa) {
			if (new Date(data_ora_inizio_pausa) > new Date(data_ora_fine_pausa)) {
				setError("data_ora_inizio_pausa", {
					type: "custom",
					message: "Inizio pausa successiva a fine pausa",
				});
				setError("data_ora_fine_pausa", {
					type: "custom",
					message: "Fine pausa anteriore a inizio pausa",
				});
			}
		}
	}, [
		watch("data_ora_inizio_macello"),
		watch("data_ora_fine_macello"),
		watch("data_ora_inizio_pausa"),
		watch("data_ora_fine_pausa"),
	]);

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			{/* {schedaCarico?.errorsStack?.fieldsErrors?.non_field_errors && (
        <Alert severity="error">
          {schedaCarico?.errorsStack?.fieldsErrors?.non_field_errors.toString()}
        </Alert>
      )} */}

			<Grid
				container
				// sx={{ flexGrow: 1, mb: 1 }}
				rowSpacing={1}
				columnSpacing={{ xs: 1 }}
				// justifyContent="center"
				justifyContent="center"
				alignItems="center"
			>
				<Grid item xs={12} sm={2} md={2} lg={1}>
					<FormControlLabel
						value={watch("digiuno") || schedaMacello?.digiuno}
						checked={watch("digiuno") || false}
						control={<Checkbox />}
						label="Digiuno"
						labelPlacement="top"
						onChange={(e, checked) => {
							setValue("digiuno", checked, {
								shouldDirty: true,
							});
						}}
						style={{ padding: 0 }}
					/>
					{!!errors?.digiuno && (
						<FormHelperText error>{errors?.digiuno.toString()}</FormHelperText>
					)}
				</Grid>

				{/* <Grid item xs={6} sm={2} md={2}>
					<Stack direction="row" spacing={2} justifyContent="center">
						<IconButton color="secondary" aria-label="aggiungi pausa">
							<SnoozeIcon />
						</IconButton>
					</Stack>
				</Grid> */}

				<Grid item xs={4} sm={3} md={3} lg={2}>
					<TextField
						label="capi morti"
						type="number"
						// disabled={consuntivoVivo.km_ar}
						variant="outlined"
						InputProps={{ inputProps: { min: 0, max: 1000 } }}
						InputLabelProps={{
							shrink: true,
						}}
						{...register("numero_capi_morti")}
						error={!!errors?.numero_capi_morti}
						helperText={errors?.numero_capi_morti?.message}
						autoComplete={"off"}
						sx={{ width: "100%" }}
						size="small"
					/>
				</Grid>
				{/* <Grid item> */}
				<Grid item xs={4} sm={3} md={3} lg={2}>
					<TextField
						label="scarto 1"
						type="number"
						// disabled={consuntivoVivo.numero_capi_ritirati}
						variant="outlined"
						InputProps={{ inputProps: { min: 0, max: 1000 } }}
						InputLabelProps={{
							shrink: true,
						}}
						{...register("numero_scarti_1")}
						error={!!errors?.numero_scarti_1}
						helperText={errors?.numero_scarti_1?.message}
						autoComplete={"off"}
						sx={{ width: "100%" }}
						size="small"
					/>
				</Grid>
				{/* <Grid item> */}
				<Grid item xs={4} sm={3} md={3} lg={2}>
					<TextField
						label="scarto 2"
						type="number"
						// disabled={consuntivoVivo.numero_capi_ritirati}
						variant="outlined"
						InputProps={{ inputProps: { min: 0, max: 1000 } }}
						InputLabelProps={{
							shrink: true,
						}}
						{...register("numero_scarti_2")}
						error={!!errors?.numero_scarti_2}
						helperText={errors?.numero_scarti_2?.message}
						autoComplete={"off"}
						sx={{ width: "100%" }}
						size="small"
					/>
				</Grid>
				<Grid
					sx={{
						display: { xs: "none", sm: "block", md: "block", lg: "none" },
					}}
					item
					xs={0}
					sm={1}
					md={1}
					lg={0}
				></Grid>
				<Grid item xs={12} sm={10} md={10} lg={4}>
					<Stack direction="row" justifyContent="center">
						{/* <Grid item xs={6} sm={2} md={2}> */}
						<TimePicker
							components={{
								OpenPickerIcon: ClockIcon,
							}}
							inputFormat="HH:mm"
							mask={"__:__"}
							label="ora inizio macello"
							value={
								watch("data_ora_inizio_macello") ||
								schedaMacello?.data_ora_inizio_macello
							}
							renderInput={(params) => (
								<TextField
									{...params}
									error={!!errors?.data_ora_inizio_macello}
									helperText={errors?.data_ora_inizio_macello?.message}
									sx={{ width: "100%" }}
									autoComplete={"off"}
									size="small"
								/>
							)}
							onChange={(newValue: any) => {
								setValue(
									"data_ora_inizio_macello",
									newValue ? new Date(newValue) : null,
									{
										shouldDirty: true,
									}
								);
								clearErrors("data_ora_inizio_macello");
							}}
						/>
						{/* </Grid> */}
						<IconButton
							sx={{
								color: pausaOn ? "transparent" : theme.palette.secondary.main,
							}}
							aria-label="aggiungi pausa"
							onClick={() => setPausaOn(!pausaOn)}
						>
							<SnoozeIcon />
						</IconButton>

						{/* <Grid item xs={6} sm={2} md={2}> */}
						<TimePicker
							components={{
								OpenPickerIcon: ClockIcon,
							}}
							inputFormat="HH:mm"
							mask={"__:__"}
							label="ora fine macello"
							value={
								watch("data_ora_fine_macello") ||
								schedaMacello?.data_ora_fine_macello
							}
							renderInput={(params) => (
								<TextField
									{...params}
									error={!!errors?.data_ora_fine_macello}
									helperText={errors?.data_ora_fine_macello?.message}
									sx={{ width: "100%" }}
									autoComplete={"off"}
									size="small"
								/>
							)}
							onChange={(newValue: any) => {
								setValue(
									"data_ora_fine_macello",
									newValue ? new Date(newValue) : null,
									{
										shouldDirty: true,
									}
								);
								clearErrors("data_ora_fine_macello");
							}}
						/>
						{/* </Grid> */}
					</Stack>
				</Grid>

				<Grid item xs={12} sm={2} md={2} lg={1}>
					{/* <Button disabled={!isDirty} variant="outlined" type="submit">
						Salva
					</Button> */}
				</Grid>

				<Grid
					sx={{
						display: { xs: "none", md: "none", lg: "block" },
					}}
					item
					xs={6}
					sm={2}
					md={0}
					lg={6}
				></Grid>
				{pausaOn && (
					<Grid item xs={12} sm={10} md={10} lg={4}>
						<Stack
							direction="row"
							spacing={1}
							justifyContent="center"
							sx={{
								px: 6,
							}}
						>
							{/* <Grid item xs={6} sm={2} md={2}> */}
							<TimePicker
								components={{
									OpenPickerIcon: ClockIcon,
								}}
								inputFormat="HH:mm"
								mask={"__:__"}
								label="ora inizio pausa"
								value={
									watch("data_ora_inizio_pausa") ||
									schedaMacello?.data_ora_inizio_pausa
								}
								renderInput={(params) => (
									<TextField
										{...params}
										error={!!errors?.data_ora_inizio_pausa}
										helperText={errors?.data_ora_inizio_pausa?.message}
										sx={{ width: "100%" }}
										autoComplete={"off"}
										size="small"
									/>
								)}
								onChange={(newValue: any) => {
									setValue(
										"data_ora_inizio_pausa",
										newValue ? new Date(newValue) : null,
										{
											shouldDirty: true,
										}
									);
									clearErrors("data_ora_inizio_pausa");
								}}
							/>
							<Box
								sx={{
									width: 0,
									height: 0,
									mt: "-45px !important",
									// bgcolor: "#1b75b1",
									borderLeft: "5px solid transparent",
									borderRight: "5px solid transparent",
									borderBottom: "52px solid #1b75b1",
								}}
							/>
							<TimePicker
								components={{
									OpenPickerIcon: ClockIcon,
								}}
								inputFormat="HH:mm"
								mask={"__:__"}
								label="ora fine pausa"
								value={
									watch("data_ora_fine_pausa") ||
									schedaMacello?.data_ora_fine_pausa
								}
								renderInput={(params) => (
									<TextField
										{...params}
										error={!!errors?.data_ora_fine_pausa}
										helperText={errors?.data_ora_fine_pausa?.message}
										sx={{ width: "100%" }}
										autoComplete={"off"}
										size="small"
									/>
								)}
								onChange={(newValue: any) => {
									setValue(
										"data_ora_fine_pausa",
										newValue ? new Date(newValue) : null,
										{
											shouldDirty: true,
										}
									);
									clearErrors("data_ora_fine_pausa");
								}}
							/>
							{/* </Grid> */}
						</Stack>
					</Grid>
				)}
				<Grid item xs={6} sm={2} md={2} lg={7}></Grid>
			</Grid>
			<Grid
				container
				// sx={{ flexGrow: 1, mb: 1 }}
				rowSpacing={1}
				columnSpacing={{ xs: 1 }}
				justifyContent="flex-start"
				alignItems="center"
			>
				<Grid item xs={12} sm={2} md={2} lg={1}></Grid>
				<Grid item xs={12} sm={2} md={2} lg={10}>
					<Grid
						container
						// sx={{ flexGrow: 1, mb: 1 }}
						rowSpacing={1}
						columnSpacing={{ xs: 1 }}
						justifyContent="flex-start"
						alignItems="center"
					>
						<Grid item xs={4} sm={3} md={3} lg={3}>
							<Controller
								name="croste"
								control={control}
								render={({ field }) => (
									<NumericFormat
										id="croste"
										customInput={TextField}
										variant="outlined"
										label="croste"
										InputLabelProps={{
											shrink: true,
											size: "small",
										}}
										error={!!errors?.croste}
										helperText={errors?.croste?.message}
										// {...register("croste")}
										value={field.value}
										autoComplete="off"
										prefix="% "
										thousandSeparator="."
										decimalSeparator=","
										valueIsNumericString={true}
										onValueChange={(v) => {
											setValue("croste", Number(v.value), {
												shouldDirty: true,
											});
										}}
										sx={{ width: "100%" }}
									/>
								)}
							/>
						</Grid>

						<Grid item xs={4} sm={3} md={3} lg={3}>
							<Controller
								name="gozzi"
								control={control}
								render={({ field }) => (
									<NumericFormat
										id="gozzi"
										customInput={TextField}
										variant="outlined"
										label="gozzi"
										InputLabelProps={{
											shrink: true,
											size: "small",
										}}
										error={!!errors?.gozzi}
										helperText={errors?.gozzi?.message}
										// {...register("gozzi")}
										value={field.value}
										autoComplete="off"
										prefix="% "
										thousandSeparator="."
										decimalSeparator=","
										valueIsNumericString={true}
										onValueChange={(v) => {
											setValue("gozzi", Number(v.value), {
												shouldDirty: true,
											});
										}}
										sx={{ width: "100%" }}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={4} sm={3} md={3} lg={3}>
							<Controller
								name="ali_rotte_carico"
								control={control}
								render={({ field }) => (
									<NumericFormat
										id="ali_rotte_carico"
										customInput={TextField}
										variant="outlined"
										label="ali rotte carico"
										InputLabelProps={{
											shrink: true,
											size: "small",
										}}
										error={!!errors?.ali_rotte_carico}
										helperText={errors?.ali_rotte_carico?.message}
										// {...register("ali_rotte_carico")}
										value={field.value}
										autoComplete="off"
										prefix="% "
										thousandSeparator="."
										decimalSeparator=","
										valueIsNumericString={true}
										onValueChange={(v) => {
											setValue("ali_rotte_carico", Number(v.value), {
												shouldDirty: true,
											});
										}}
										sx={{ width: "100%" }}
									/>
								)}
							/>
						</Grid><Grid item xs={4} sm={3} md={3} lg={3}>
							<Controller
								name="ali_rotte_macello"
								control={control}
								render={({ field }) => (
									<NumericFormat
										id="ali_rotte_macello"
										customInput={TextField}
										variant="outlined"
										label="ali rotte macello"
										InputLabelProps={{
											shrink: true,
											size: "small",
										}}
										error={!!errors?.ali_rotte_macello}
										helperText={errors?.ali_rotte_macello?.message}
										// {...register("ali_rotte_macello")}
										value={field.value}
										autoComplete="off"
										prefix="% "
										thousandSeparator="."
										decimalSeparator=","
										valueIsNumericString={true}
										onValueChange={(v) => {
											setValue("ali_rotte_macello", Number(v.value), {
												shouldDirty: true,
											});
										}}
										sx={{ width: "100%" }}
									/>
								)}
							/>
						</Grid>

						<Grid item xs={4} sm={3} md={3} lg={3}>
							<Controller
								name="grasso"
								control={control}
								render={({ field }) => (
									<NumericFormat
										id="grasso"
										customInput={TextField}
										variant="outlined"
										label="grasso"
										InputLabelProps={{
											shrink: true,
											size: "small",
										}}
										error={!!errors?.grasso}
										helperText={errors?.grasso?.message}
										// {...register("grasso")}
										value={field.value}
										autoComplete="off"
										prefix="% "
										thousandSeparator="."
										decimalSeparator=","
										valueIsNumericString={true}
										onValueChange={(v) => {
											setValue("grasso", Number(v.value), {
												shouldDirty: true,
											});
										}}
										sx={{ width: "100%" }}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={4} sm={3} md={3} lg={3}>
							<Controller
								name="ematomi_cosce"
								control={control}
								render={({ field }) => (
									<NumericFormat
										id="ematomi_cosce"
										customInput={TextField}
										variant="outlined"
										label="cosce emat."
										InputLabelProps={{
											shrink: true,
											size: "small",
										}}
										error={!!errors?.ematomi_cosce}
										helperText={errors?.ematomi_cosce?.message}
										// {...register("ematomi_cosce")}
										value={field.value}
										autoComplete="off"
										prefix="% "
										thousandSeparator="."
										decimalSeparator=","
										valueIsNumericString={true}
										onValueChange={(v) => {
											setValue("ematomi_cosce", Number(v.value), {
												shouldDirty: true,
											});
										}}
										sx={{ width: "100%" }}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={4} sm={3} md={3} lg={3}>
							<Controller
								name="ematomi_petto"
								control={control}
								render={({ field }) => (
									<NumericFormat
										id="ematomi_petto"
										customInput={TextField}
										variant="outlined"
										label="petto emat."
										InputLabelProps={{
											shrink: true,
											size: "small",
										}}
										error={!!errors?.ematomi_petto}
										helperText={errors?.ematomi_petto?.message}
										// {...register("ematomi_petto")}
										value={field.value}
										autoComplete="off"
										prefix="% "
										thousandSeparator="."
										decimalSeparator=","
										valueIsNumericString={true}
										onValueChange={(v) => {
											setValue("ematomi_petto", Number(v.value), {
												shouldDirty: true,
											});
										}}
										sx={{ width: "100%" }}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={4} sm={3} md={3} lg={3}>
							<Controller
								name="fusi_verdi"
								control={control}
								render={({ field }) => (
									<NumericFormat
										id="fusi_verdi"
										customInput={TextField}
										variant="outlined"
										label="fusi verdi"
										InputLabelProps={{
											shrink: true,
											size: "small",
										}}
										error={!!errors?.fusi_verdi}
										helperText={errors?.fusi_verdi?.message}
										// {...register("fusi_verdi")}
										value={field.value}
										autoComplete="off"
										prefix="% "
										thousandSeparator="."
										decimalSeparator=","
										valueIsNumericString={true}
										onValueChange={(v) => {
											setValue("fusi_verdi", Number(v.value), {
												shouldDirty: true,
											});
										}}
										sx={{ width: "100%" }}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={4} sm={3} md={3} lg={3}>
							<ReactHookFormSelect
								name="uniformita"
								label="uniformita"
								register={register}
								control={control}
								watch={watch}
								variant="outlined"
								error={errors?.uniformita?.message}
								sx={{ width: "100%" }}
							>
								<MenuItem value="">
									<em>Nessuno</em>
								</MenuItem>
								{Object.entries(UniformitaTypes).map(([key, value]) => {
									return (
										<MenuItem key={key} value={key}>
											{value}
										</MenuItem>
									);
								})}
							</ReactHookFormSelect>
						</Grid>
						<Grid item xs={4} sm={3} md={3} lg={3}>
							<ReactHookFormSelect
								name="giudizio_colore"
								label="giudizio colore"
								register={register}
								control={control}
								watch={watch}
								variant="outlined"
								error={errors?.giudizio_colore?.message}
								sx={{ width: "100%" }}
							>
								<MenuItem value="">
									<em>Nessuno</em>
								</MenuItem>
								{Object.entries(GiudizioColoreTypes).map(([key, value]) => {
									return (
										<MenuItem key={key} value={key}>
											{value}
										</MenuItem>
									);
								})}
							</ReactHookFormSelect>
						</Grid>
						<Grid item xs={4} sm={3} md={3} lg={3}>
							<Controller
								name="temperatura_fuori_tunnel"
								control={control}
								render={({ field }) => (
									<NumericFormat
										id="temperatura_fuori_tunnel"
										customInput={TextField}
										variant="outlined"
										label="temperatura fuori dal tunnel"
										InputLabelProps={{
											shrink: true,
											size: "small",
										}}
										error={!!errors?.temperatura_fuori_tunnel}
										helperText={errors?.temperatura_fuori_tunnel?.message}
										// {...register("temperatura_fuori_tunnel")}
										value={field.value}
										autoComplete="off"
										prefix="° "
										decimalScale={1}
										thousandSeparator="."
										decimalSeparator=","
										valueIsNumericString={true}
										onValueChange={(v) => {
											setValue("temperatura_fuori_tunnel", Number(v.value), {
												shouldDirty: true,
											});
										}}
										sx={{ width: "100%" }}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={4} sm={3} md={3} lg={3}>
							<Controller
								name="temperatura_prima_carico"
								control={control}
								render={({ field }) => (
									<NumericFormat
										id="temperatura_prima_carico"
										customInput={TextField}
										variant="outlined"
										label="temperatura prima del carico"
										InputLabelProps={{
											shrink: true,
											size: "small",
										}}
										error={!!errors?.temperatura_prima_carico}
										helperText={errors?.temperatura_prima_carico?.message}
										// {...register("temperatura_prima_carico")}
										value={field.value}
										autoComplete="off"
										decimalScale={1}
										prefix="° "
										thousandSeparator="."
										decimalSeparator=","
										valueIsNumericString={true}
										onValueChange={(v) => {
											setValue("temperatura_prima_carico", Number(v.value), {
												shouldDirty: true,
											});
										}}
										sx={{ width: "100%" }}
									/>
								)}
							/>
						</Grid>
					</Grid>
				</Grid>

				<Grid item xs={12} sm={2} md={2} lg={1}>
					<Button disabled={!isDirty} variant="outlined" type="submit">
						Salva
					</Button>
				</Grid>
			</Grid>
		</form>
	);
};

export default SchedaMacelloForm;
