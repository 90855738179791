import React, { useEffect } from "react";

import { SaldoPalletCommittente } from "./palletsSlice";

import { Box } from "@mui/system";

import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";

interface ReportPalletListProps {
	saldiPalletCommittenti: SaldoPalletCommittente[];
	committenteCorrenteId: number | null;
	setCommittenteCorrenteId: (committenteCorrenteId: number | null) => void;
}

const calcoloSaldoTotalePallet = (saldiPallet: SaldoPalletCommittente[]) => {
	const saldoTotalePallet = saldiPallet?.reduce(
		(previousValue: number, currentValue: SaldoPalletCommittente) => {
			return previousValue + currentValue.saldo_pallet;
		},
		0
	);
	return saldoTotalePallet;
};

const ReportPalletList = ({
	saldiPalletCommittenti,
	committenteCorrenteId,
	setCommittenteCorrenteId,
}: ReportPalletListProps) => {
	const [saldoTotalePallet, setSaldoTotalePallet] = React.useState<number>(0);

	useEffect(() => {
		setSaldoTotalePallet(calcoloSaldoTotalePallet(saldiPalletCommittenti));
	}, [saldiPalletCommittenti]);

	return (
		<React.Fragment>
			<Box sx={{ m: 1, bgcolor: "background.paper" }}>
				<Typography variant="h6" gutterBottom align={"center"} sx={{ pt: 1 }}>
					Giacenza Corrente Magazzino:{" "}
					{Intl.NumberFormat("it-IT", {
						minimumFractionDigits: 2,
						maximumFractionDigits: 2,
					}).format(saldoTotalePallet || 0)}
				</Typography>
				<List sx={{ mt: 2 }}>
					<ListItemText
						key={"Lista_saldi_pallet_committenti"}
						sx={{ backgroundColor: "#1b75b1", color: "#ddd" }}
					>
						<Grid container spacing={{ xs: 0.5, md: 1, lg: 2 }} sx={{ px: 2 }}>
							<Grid size={{ xs:6, sm:6, md:6, lg:6 }}>
								<Typography variant="h6" gutterBottom>
									Committente
								</Typography>
							</Grid>

							<Grid size={{ xs:6, sm:6, md:6, lg:6 }}>
								<Typography
									variant="h6"
									// component="div"
									gutterBottom
									align="right"
								>
									Saldo Bancali
								</Typography>
							</Grid>
						</Grid>
					</ListItemText>
					{saldiPalletCommittenti.map((saldo) => (
						<ListItemButton
							key={saldo.id_committente}
							onClick={() => {
								setCommittenteCorrenteId(saldo.id_committente);
							}}
							sx={{
								color: saldo.saldo_pallet < 0 ? "#FF0000" : "inherit",
								py: 0,
								backgroundColor:
									committenteCorrenteId == saldo.id_committente
										? "#ddd"
										: "inherit",
							}}
						>
							<Grid container spacing={{ xs: 0.5, md: 1, lg: 2 }} sx={{width:"100%"}}>
								<Grid size={{ xs:6, sm:6, md:6, lg:6 }}>
									<Typography variant="subtitle1" component="div">
										{saldo.committente}
									</Typography>
								</Grid>
								<Grid size={{ xs:6, sm:6, md:6, lg:6 }}>
									<Typography variant="subtitle1" align="right" component="div">
										{saldo.saldo_pallet}
									</Typography>
								</Grid>
							</Grid>
						</ListItemButton>
					))}
				</List>
			</Box>
			{/* </TableContainer> */}
		</React.Fragment>
	);
};

export default ReportPalletList;
