import React, { useEffect } from "react";

import { ReportAutistiIolepec } from "../reportAutistiCamionSlice";

import {DateTime, Duration} from 'luxon';

import { Box } from "@mui/system";

import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

interface IolepecAutistiMeseTableProps {
	reportAutistiIolepec: ReportAutistiIolepec[];
}

const calcoloCostoTotaleAutisti = (costiAutisti: ReportAutistiIolepec[]) => {
	const costoTotaleAutisti = costiAutisti?.reduce(
		(previousValue, currentValue: ReportAutistiIolepec) => {
			return previousValue + currentValue.costo_totale;
		},
		0
	);
	return costoTotaleAutisti;
};

const IolepecAutistiMeseTable = ({
	reportAutistiIolepec,
}: IolepecAutistiMeseTableProps) => {
	const [costoTotaleAutisti, setCostoTotaleAutisti] = React.useState<number>(0);

	useEffect(() => {
		setCostoTotaleAutisti(calcoloCostoTotaleAutisti(reportAutistiIolepec));
	}, [reportAutistiIolepec]);

	return (
		<React.Fragment>
			<TableContainer component={Paper} sx={{ width: "100%" }}>
				<Box>
					<Table size="small" aria-label="report mensile autisti">
						<TableHead>
							<TableRow>
								<TableCell
									align="center"
									colSpan={5}
									sx={{
										paddingTop: 3,
										paddingBottom: 3,
										fontWeight: "bold",
										fontSize: 18,
										textTransform: "uppercase",
									}}
								>
									Costo totale autisti mese: €{" "}
									{Intl.NumberFormat("it-IT", {
										minimumFractionDigits: 2,
										maximumFractionDigits: 2,
									}).format(costoTotaleAutisti || 0)}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell sx={{ fontWeight: "bold" }}>Cognome</TableCell>
								<TableCell sx={{ fontWeight: "bold" }}>Nome</TableCell>
								<TableCell sx={{ fontWeight: "bold" }} align="right">
									Ore totali
								</TableCell>
								<TableCell sx={{ fontWeight: "bold" }} align="right">
									Costo orario
								</TableCell>
								<TableCell sx={{ fontWeight: "bold" }} align="right">
									Costo totale autista
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{reportAutistiIolepec.map((autista) => (
								<TableRow key={autista.id}>
									<TableCell>
										{autista.cognome.charAt(0).toUpperCase()}
										{autista.cognome.slice(1).toLowerCase()}
									</TableCell>
									<TableCell sx={{ textTransform: "capitalize" }}>
										{autista.nome.charAt(0).toUpperCase()}
										{autista.nome.slice(1).toLowerCase()}
									</TableCell>
									<TableCell align="right">
										{Math.floor(Duration.fromObject({ seconds: Number(autista.ore_totali) || 0 }).as("hours"))}
										:
										{Math.floor(Duration.fromObject({ seconds: Number(autista.ore_totali) || 0 }).as("milliseconds") / 60000)}
									</TableCell>
									<TableCell align="right">
										€{" "}
										{Intl.NumberFormat("it-IT", {
											minimumFractionDigits: 2,
											maximumFractionDigits: 2,
										}).format(autista.costo_orario || 0)}
									</TableCell>
									<TableCell
										align="right"
										sx={{
											color: autista.costo_totale == 0 ? "#FF0000" : "inherit",
										}}
									>
										{"€ "}
										{Intl.NumberFormat("it-IT", {
											minimumFractionDigits: 2,
											maximumFractionDigits: 2,
										}).format(autista.costo_totale || 0)}
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</Box>
			</TableContainer>
		</React.Fragment>
	);
};

export default IolepecAutistiMeseTable;
