import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "../../redux/store";
import {useForm, SubmitHandler} from "react-hook-form";

import {useNavigate, useLocation} from "react-router-dom";

import {Utente, utenteTokenObtain} from "./authenticationSlice";
import {
    Typography,
    Link,
    Grid,
    Box,
    Container,
    FormControlLabel,
    CircularProgress,
    Avatar,
    Button,
    CssBaseline,
    TextField,
    Checkbox,
    Backdrop,
} from "@mui/material";
import {userIsLogged} from "./UserIsLogged";
import logoScarso from "../../../static/images/logo/logo-Scarso_nuovo_solo.png";

const SignIn = ({...props}) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const utenteLogged: Utente = useSelector<RootState, Utente>(
        (state) => state.authentication.utenteLogged
    );

    interface User {
        username: string;
        email: string;
        password: string;
    }

    interface Field {
        name: string;
        value: any;
    }

    const [user, setUser] = useState<User>({
        username: "",
        email: "",
        password: "",
    });
    const [saving, setSaving] = useState(false);

    type LocationState = {
        from: {
            pathname: string;
        };
    };
    const location = useLocation();
    const {from} = (location.state as LocationState) || {
        from: {pathname: "/"},
    };

    useEffect(() => {
        if (userIsLogged(utenteLogged)) {
            navigate(from);
        }
    }, [utenteLogged]);

    const handleFieldChange = (
        e: React.FormEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => {
        setUser({...user, [e.currentTarget.name]: e.currentTarget.value});
    };

    const handleUtenteTokenObtain = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        setSaving(true);
        dispatch(utenteTokenObtain(user))
            .then(() => {
                setSaving(false);
            })
            .catch((error) => {
                setSaving(false);

                setUser({username: "", email: "", password: ""});
            });
    };

    return (
        <Container component="main" maxWidth="xs">
            <Backdrop
                sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={saving}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
            <CssBaseline/>
            <Box
                sx={{
                    marginTop: (theme) => theme.spacing(8),
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                {/* <StyledDiv> */}
                <Avatar alt="Logo Scarso" src={logoScarso}/>
                <Typography component="h1" variant="h5">
                    T{"&"}MA Sign in
                </Typography>
                {/* {errors.detail && <Alert severity="error">{errors.detail}</Alert>} */}
                <Box
                    component="form"
                    sx={{width: "100%", marginTop: (theme) => theme.spacing(1)}}
                    onSubmit={handleUtenteTokenObtain}
                >
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        value={user.username}
                        onChange={handleFieldChange}
                        fullWidth
                        id="username"
                        label="username"
                        name="username"
                        // error={errors.username}
                        // helperText={errors.username}
                        autoComplete={"off"}
                        autoFocus
                    />

                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        value={user.password}
                        onChange={handleFieldChange}
                        type="password"
                        id="password"
                        autoComplete="current-password"
                    />
                    <FormControlLabel
                        control={<Checkbox value="remember" color="primary"/>}
                        label="Remember me"
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        sx={{margin: (theme) => theme.spacing(3, 0, 2)}}
                    >
                        Sign In
                    </Button>

                    <Link href="/forgotPassword" variant="body2" underline="hover">
                        Password Dimenticata?
                    </Link>
                    {/* <Grid item>
              <Link href="/register#" variant="body2" underline="hover">
                {"Non hai ancora un utente? Registrati"}
              </Link>
            </Grid> */}
                </Box>
            </Box>
        </Container>
    );
};

export default SignIn;
