import React, { useState, useEffect } from "react";

import { useForm, SubmitHandler, Controller } from "react-hook-form";

import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import ReactHookFormSelect from "../../../common/ReactHookFormSelect";
import { Button } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Switch from "@mui/material/Switch";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";

import {
	NumericFormat,
	NumericFormatProps,
	PatternFormat,
} from "react-number-format";
import {
	Lavorazione,
	PettorinaTypes,
	DimensioneCassettaTypes,
	MaterialeCassettaTypes,
	MaterialeSottoTypes,
	MaterialeSopraTypes,
	DisposizioneBilanciaTypes,
} from "../lavorazioniSlice";
import {
	Articolo,
	Etichetta,
	CodiceAI,
} from "../../anagrafiche/anagraficheSlice";
import {
	ErrorStatusTypes,
	ErrorsStack,
} from "../../../common/errorsDeclarations";

interface LavorazionePianoFormProps {
	lavorazione: Lavorazione;
	annulla: () => void;
	lotto_id: number;
	saveLavorazione: (lottoId: number, lavorazioneToSave: Lavorazione) => void;
	cosuntivazione?: boolean;
}

const LavorazionePianoForm = ({
	lavorazione,
	annulla,
	lotto_id,
	saveLavorazione,
	cosuntivazione,
}: LavorazionePianoFormProps) => {
	const {
		handleSubmit,
		register,
		formState: { errors, isDirty, isValid },
		setValue,
		watch,
		getValues,
		control,
		setError,
		clearErrors,
		reset,
	} = useForm<Lavorazione>();

	const listaCampi = Object.keys(lavorazione) as Array<keyof Lavorazione>;

	useEffect(() => {
		listaCampi.map((field) => {
			setValue(field, lavorazione[field]);
		});
	}, [
		lavorazione.id,
		listaCampi.forEach((field) => {
			lavorazione[field];
		}),
		lavorazione.errorsStack?.status == ErrorStatusTypes.SUCCESS,
	]);

	useEffect(() => {
		listaCampi.forEach((field) => {
			lavorazione?.errorsStack?.fieldsErrors &&
				lavorazione.errorsStack.fieldsErrors[field] &&
				setError(field, {
					type: "server",
					message: lavorazione.errorsStack.fieldsErrors[field].toString(),
				});
		});
	}, [lavorazione?.errorsStack?.fieldsErrors]);

	const handlerDetailChaingSubmit = () => {
		if (isValid && isDirty) {
			const formValues = getValues();
			saveLavorazione(lotto_id, formValues);
		}
	};

	const onSubmit: SubmitHandler<Lavorazione> = (lavorazione) => {
		saveLavorazione(lotto_id, lavorazione);
	};

	//  ######################################################

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Grid
				container
				sx={{ flexGrow: 1, mb: 1 }}
				rowSpacing={1}
				columnSpacing={1}
				justifyContent="center"
				alignItems="flex-start"
			>
				<Grid size={{ xs:6, sm:4, md:4, lg:4 }}>
					<TextField
						// label="Note specifiche lotto"
						disabled={cosuntivazione}
						size="small"
						variant="outlined"
						InputLabelProps={{
							shrink: true,
						}}
						{...register("note")}
						error={!!errors?.note}
						helperText={errors?.note?.message}
						autoComplete={"off"}
						sx={{ width: "100%" }}
						onBlur={handlerDetailChaingSubmit}
					/>
				</Grid>

				<Grid size={{ xs:3, sm:2, md:2, lg:2 }}>
					{!cosuntivazione ? (
						<TextField
							// label="cassette richieste"
							disabled={cosuntivazione}
							size="small"
							variant="outlined"
							type="number"
							InputLabelProps={{
								shrink: true,
							}}
							{...register("numero_cassette_richieste")}
							// required
							error={!!errors?.numero_cassette_richieste}
							helperText={errors?.numero_cassette_richieste?.message}
							autoComplete={"off"}
							sx={{ width: "100%" }}
							onBlur={handlerDetailChaingSubmit}
						/>
					) : (
						<Typography variant="body2">
							{lavorazione.numero_cassette_richieste?.toString()}
						</Typography>
					)}
				</Grid>
				<Grid size={{ xs:3, sm:2, md:2, lg:2 }}>
					{!cosuntivazione ? (
						<TextField
							// label="cassette x pallet"
							disabled={cosuntivazione}
							size="small"
							variant="outlined"
							type="number"
							InputLabelProps={{
								shrink: true,
							}}
							{...register("cassette_x_pallet")}
							// required
							error={!!errors?.cassette_x_pallet}
							helperText={errors?.cassette_x_pallet?.message}
							autoComplete={"off"}
							sx={{ width: "100%" }}
							onBlur={handlerDetailChaingSubmit}
						/>
					) : (
						<Typography variant="body2">
							{lavorazione.cassette_x_pallet?.toString()}
						</Typography>
					)}
				</Grid>
				<Grid size={{ xs:3, sm:2, md:2, lg:2 }}>
					{cosuntivazione ? (
						<Controller
							name="peso_cassette_prodotte"
							control={control}
							render={({ field }) => (
								<NumericFormat
									id="peso_cassette_prodotte"
									customInput={TextField}
									variant="outlined"
									size="small"
									// label="peso cassette prodotte"
									InputLabelProps={{
										shrink: true,
									}}
									error={!!errors?.peso_cassette_prodotte}
									helperText={errors?.peso_cassette_prodotte?.message}
									// {...register("peso_cassette_prodotte")}

									// variant="outlined"
									value={field.value}
									autoComplete="off"
									prefix="kg "
									thousandSeparator="."
									decimalSeparator=","
									valueIsNumericString={true}
									onValueChange={(v) => {
										setValue("peso_cassette_prodotte", Number(v.value), {
											shouldDirty: true,
										});
									}}
									sx={{ width: "100%", backgroundColor: "#8bc98b" }}
								/>
							)}
						/>
					) : (
						<Typography variant="body2" color="#b21515" align="right">
							{lavorazione.peso_cassette_prodotte &&
								Intl.NumberFormat("it-IT", {
									minimumFractionDigits: 2,
									maximumFractionDigits: 2,
								}).format(lavorazione.peso_cassette_prodotte) + " kg"}
						</Typography>
					)}
				</Grid>
				<Grid size={{ xs:3, sm:2, md:2, lg:2 }}>
					{cosuntivazione ? (
						<TextField
							// label="cassette prodotte"
							disabled={!cosuntivazione}
							size="small"
							variant="outlined"
							InputLabelProps={{
								shrink: true,
							}}
							{...register("numero_cassette_prodotte")}
							required
							type="number"
							error={!!errors?.numero_cassette_prodotte}
							helperText={errors?.numero_cassette_prodotte?.message}
							autoComplete={"off"}
							sx={{ width: "100%", backgroundColor: "#8bc98b" }}
							onBlur={handlerDetailChaingSubmit}
						/>
					) : (
						<Typography variant="body2" color="#b21515" align="right">
							{lavorazione.numero_cassette_prodotte?.toString()}
						</Typography>
					)}
				</Grid>
			</Grid>
		</form>
	);
};

export default LavorazionePianoForm;
