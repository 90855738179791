import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../../redux/store";

import { useForm, SubmitHandler } from "react-hook-form";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import {
	CorrispettiviLotto,
	ListinoTrasportoVivo,
	modificaCorrispettiviLotto,
} from "../bolleFattureSlice";
import Alert from "../../../common/Alert";

interface LottoCorrispettiviListinoTrasportiVivoSelectProps {
	corrispettiviLotto: CorrispettiviLotto;
}

const LottoCorrispettiviListinoTrasportiVivoSelect = ({
	corrispettiviLotto,
}: LottoCorrispettiviListinoTrasportiVivoSelectProps) => {
	const {
		handleSubmit,
		register,
		formState: { errors, isDirty },
		setValue,
		watch,
		getValues,
		control,
		setError,
		reset,
	} = useForm<CorrispettiviLotto>();

	// const committenti = useSelector<RootState, Committente[]>(
	//   (state) => state.anagrafiche.committenti.results
	// );

	const listiniTrasportoVivo = useSelector<RootState, ListinoTrasportoVivo[]>(
		(state) => state.bolleFattureState.listiniTrasportoVivo.results
	);

	const dispatch = useAppDispatch();

	const handlerCambiaListinoTrasportoVivo = (
		corrispettiviLotto: CorrispettiviLotto
	) => {
		dispatch(modificaCorrispettiviLotto(corrispettiviLotto));
	};

	const listaCampi = Object.keys(corrispettiviLotto) as Array<
		keyof CorrispettiviLotto
	>;

	const [listinoTrasportoVivoCorrente, setListinoTrasportoVivoCorrente] =
		React.useState<ListinoTrasportoVivo | undefined>(undefined);

	useEffect(() => {
		handleSubmit(onSubmit);
		setListinoTrasportoVivoCorrente(
			listiniTrasportoVivo.find(
				(listinoTrasportoVivoScelto) =>
					listinoTrasportoVivoScelto.id == getValues("listino_trasporti_vivo")
			)
		);
	}, [watch("listino_trasporti_vivo")]);
	// #############################################

	useEffect(() => {
		listaCampi.map((field) => {
			setValue(field, corrispettiviLotto[field]);
		});
	}, [
		listaCampi.forEach((field) => {
			corrispettiviLotto[field];
		}),
		corrispettiviLotto.lotto_id,
	]);

	useEffect(() => {
		listaCampi.forEach((field) => {
			corrispettiviLotto?.errorsStack?.fieldsErrors &&
				corrispettiviLotto.errorsStack.fieldsErrors[field] &&
				setError(field, {
					type: "server",
					message:
						corrispettiviLotto.errorsStack.fieldsErrors[field].toString(),
				});
		});
	}, [corrispettiviLotto?.errorsStack?.fieldsErrors]);

	const onSubmit: SubmitHandler<CorrispettiviLotto> = (corrispettiviLotto) => {
		handlerCambiaListinoTrasportoVivo(corrispettiviLotto);
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			{corrispettiviLotto?.errorsStack?.fieldsErrors?.non_field_errors && (
				<Alert severity="error">
					{corrispettiviLotto?.errorsStack?.fieldsErrors?.non_field_errors.toString()}
				</Alert>
			)}
			<Autocomplete
				key={listinoTrasportoVivoCorrente?.id}
				// disabled={getValues("confermato")}
				value={listinoTrasportoVivoCorrente}
				isOptionEqualToValue={(option, value) => option.id === value.id}
				onChange={(event: any, newValue: any) => {
					setValue("listino_trasporti_vivo", newValue ? newValue.id : null, {
						shouldDirty: true,
					});
					handleSubmit((corrispettiviLotto) =>
						handlerCambiaListinoTrasportoVivo(corrispettiviLotto)
					)();
				}}
				id="listino_trasporti_vivo"
				options={listiniTrasportoVivo}
				getOptionLabel={(option) =>
					`${option.nome}${!!option.descrizione? "(" + option.descrizione + ")": ""}`
				}
				renderOption={(props, option) => (
					<Box component="li" {...props}>
						{`${option.nome}${!!option.descrizione? "(" + option.descrizione + ")": ""}`}
					</Box>
				)}
				renderInput={(params: any) => (
					<TextField
						variant="outlined"
						{...params}
						label="Listino Trasporto Vivo"
						error={!!errors?.listino_trasporti_vivo}
						helperText={errors?.listino_trasporti_vivo?.message}
					/>
				)}
			/>
		</form>
	);
};

export default LottoCorrispettiviListinoTrasportiVivoSelect;
