import React from "react";
import { DateTime } from 'luxon';

import { Text, View, Image } from "@react-pdf/renderer";

import LottoLavorazioniIntestazionePDF from "./LottoLavorazioniIntestazionePDF";

import { Lotto } from "../../../pianificazione/pianificazioneSlice";
import ProgammaIncassettamentoLavorazioniPDF from "./ProgammaIncassettamentoLavorazioniPDF";
import {convertToDateTime} from "../../../../common/dateUtils/convertToDateTime";

const logoScarso = require("../../../../../../static/images/logo/logo-Scarso_nuovo_solo.png");

interface LottoLavorazioniPdfProps {
	lotto: Lotto;
}

const LottoLavorazioniPdf = ({ lotto }: LottoLavorazioniPdfProps) => (
	<>
		<View
			key={"scheda_macellazione_intestazione"}
			style={{
				paddingLeft: 23,
				paddingRight: 23,
				flexDirection: "row",
				justifyContent: "space-between",
			}}
		>
			<Text style={{ fontSize: 18, paddingRight: 50, color: "#1c325c" }}>
				Programma Incassettamento{" "}
				<Text>{convertToDateTime(lotto.data_lavorazione)?.setLocale("it").toFormat('ccc dd LLL yyyy')}</Text>
				<Text>
					{lotto.ordine_cliente && " ord. consegna " + lotto.ordine_cliente}
				</Text>
			</Text>
			<Text style={{ fontSize: 18, paddingRight: 50, color: "#1c325c" }}>
				<Text style={{ fontSize: 9 }}>ord. macellaz.</Text>
				<Text style={{ backgroundColor: "#3b7d9c", color: "#FFF" }}>
					{"\u00A0" + lotto.ordinamento_lotto + "\u00A0"}
				</Text>
			</Text>
			<Text
				style={{
					fontWeight: "bold",
					marginBottom: 1,
					paddingBottom: 0,
					fontSize: 20,
				}}
			>
				<Image
					src={logoScarso}
					style={{
						width: 30,
						height: 30,
						// margin: 0,
						padding: 0,
						paddingLeft: "10px",
						marginLeft: "3px",
					}}
				/>
			</Text>
		</View>
		<LottoLavorazioniIntestazionePDF lotto={lotto} />
		<ProgammaIncassettamentoLavorazioniPDF
			lavorazioni={lotto.lavorazioni_lotto}
		/>
		{/* <View
			style={{
				flexDirection: "row",
				justifyContent: "center",
			}}
		></View> */}
	</>
);

export default LottoLavorazioniPdf;
