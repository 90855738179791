import React, {useEffect} from "react";
import {RootState, useAppDispatch} from "../../redux/store";
import {useSelector} from "react-redux";
import {ErrorStatusTypes} from "../common/errorsDeclarations";
import {UtenteLogged, requestPasswordResetEmail} from "./authenticationSlice";
import {useForm, SubmitHandler} from "react-hook-form";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import {
    Backdrop,
    CircularProgress,
    TextField,
    Link,
    Box,
    Typography,
    Container,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";

import logoScarso  from "../../../static/images/logo/logo-Scarso_nuovo_solo.png";

const isValidEmail = (email: string) =>
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
    );

const ForgotPassword = () => {
    const dispatch = useAppDispatch();
    const {
        handleSubmit,
        register,
        formState: {errors, isDirty},
        setValue,
        watch,
        getValues,
        control,
        setError,
        clearErrors,
        reset,
    } = useForm<{ email: string }>();

    const utenteLogged: UtenteLogged = useSelector<RootState, UtenteLogged>(
        (state) => state.authentication.utenteLogged
    );

    const listaCampi = ["email"] as Array<keyof { email: string }>;

    useEffect(() => {
        clearErrors();
        listaCampi.forEach((field) => {
            utenteLogged?.errorsStack?.fieldsErrors &&
            utenteLogged.errorsStack.fieldsErrors[field] &&
            setError(field, {
                type: "server",
                message: utenteLogged.errorsStack.fieldsErrors[field].toString(),
            });
        });
    }, [utenteLogged?.errorsStack?.fieldsErrors]);

    const onSubmit: SubmitHandler<{ email: string }> = ({email}) => {
        isValidEmail(email)
            ? dispatch(requestPasswordResetEmail(email))
            : setError("email", {
                type: "server",
                message: "formato email non valido",
            });
    };

    return (
        <Container component="main" maxWidth="xs">
            <Backdrop
                sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={utenteLogged.errorsStack.status == ErrorStatusTypes.PENDING}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
            <Box
                sx={{
                    marginTop: (theme) => theme.spacing(8),
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Avatar alt="Logo Scarso" src={logoScarso}/>
                <Typography component="h1" variant="h5">
                    T{"&"}MA Re-imposta la Password
                </Typography>
                {utenteLogged.errorsStack.status == ErrorStatusTypes.SUCCESS ? (
                    <Card sx={{mt: 3, minWidth: 275}}>
                        <CardContent>
                            <Typography
                                sx={{fontSize: 14}}
                                color="text.secondary"
                                gutterBottom
                            >
                                La tua richiesta è stata accolta.
                            </Typography>
                            <Typography variant="h5" component="div">
                                Ti abbiamo mandato una mail.
                            </Typography>
                            <Typography sx={{mb: 1.5}} color="text.secondary">
                                Segui il link per resettare la tua password
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button href="/">Torna alla Home</Button>
                        </CardActions>
                    </Card>
                ) : (
                    <>
                        <Typography
                            sx={{
                                marginTop: (theme) => theme.spacing(3),
                                marginBottom: (theme) => theme.spacing(3),
                            }}
                        >
                            {
                                "Hai dimenticato la tua password? Inserisci qui sotto l'indirizzo e-mail con cui ti sei registrato, ti invieremo una mail con un link per re-impostarla."
                            }
                        </Typography>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Grid container spacing={2} justifyContent="flex-start">
                                <Grid size={{xs: 12}}>
                                    <TextField
                                        id="email"
                                        label="Email Address"
                                        {...register("email")}
                                        variant="outlined"
                                        required
                                        error={!!errors?.email}
                                        helperText={errors?.email?.message}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        // disabled={confermato}
                                        autoComplete={"off"}
                                        sx={{width: "100%", bgcolor: "white"}}
                                        fullWidth
                                        // autoComplete="email"
                                    />
                                </Grid>
                            </Grid>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                sx={{margin: (theme) => theme.spacing(3, 0, 2)}}
                            >
                                mandami una mail di recupero password
                            </Button>
                            <Link href="/signin" variant="body2" underline="hover">
                                Hai già un utente? Accedi
                            </Link>
                        </form>
                    </>
                )}
            </Box>
        </Container>
    );
};

export default ForgotPassword;
