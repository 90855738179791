import { ListaBIViaggiAutistiCamionMese } from "../reportAutistiCamionSlice";
import { DateTime } from 'luxon';

export const tabellaListaBIViaggiAutistiCamionMese = (
	reportViaggiAutistiCamion: ListaBIViaggiAutistiCamionMese[]
): string[][] => {
	const csvData: string[][] = [];

	csvData.push([
		"id_lotto",
		"id_scheda_viaggio",
		"data_lavorazione",
		"id_camion",
		"targa",
		"autista_id",
		"autista",
		"km_ar",
		"ore_di_viaggio",
		"ore_di_lavoro",
		"kg_vivo_partenza",
		"committente",
		"tipo_capo",
		"lotti_per_viaggio",
	]);

	reportViaggiAutistiCamion.map((viaggiAutistiCamion) => {
		csvData.push([
			viaggiAutistiCamion.id_lotto.toString(),
			viaggiAutistiCamion.id_scheda_viaggio.toString(),
			viaggiAutistiCamion.data_lavorazione.toString(),
			viaggiAutistiCamion.id_camion.toString(),
			viaggiAutistiCamion.targa.toString(),
			viaggiAutistiCamion.autista_id.toString(),
			viaggiAutistiCamion.autista.toString(),
			viaggiAutistiCamion.km_ar.toString(),
			viaggiAutistiCamion.ore_di_viaggio.toString(),
			viaggiAutistiCamion.ore_di_lavoro.toString(),
			viaggiAutistiCamion.kg_vivo_partenza.toString(),
			viaggiAutistiCamion.committente.toString(),
			viaggiAutistiCamion.tipo_capo.toString(),
			viaggiAutistiCamion.lotti_per_viaggio.toString(),
		]);
	});

	return csvData;
};
