import { Location } from "react-router-dom";
import { DATA_CORRENTE, DATA_CORRENTE_DATA } from "api/apiUtils";
import { DateTime } from "luxon";
import { convertToDateTime } from "components/common/dateUtils/convertToDateTime";
//
// type LocationState = {
// 	giornoSettimana:  string | undefined;
// };

export const RecuperaDataCorrente = (location?: Location): DateTime | null => {
	if (location && location.state) {
		const  giornoSettimana = location.state as string;
		if (giornoSettimana) {
			return convertToDateTime(giornoSettimana);
		}
	}
	const dataCorrente = localStorage.getItem(DATA_CORRENTE);
	const dataCorrenteData = localStorage.getItem(DATA_CORRENTE_DATA);
	// sostanzailmente al refresh torno alla data che stò guardando solo se è oggi il giorno in cui l'ho settata, domani ricomincio da domani
	if (
		!!dataCorrente &&
		dataCorrenteData ==
		convertToDateTime(new Date())?.setLocale("it").toFormat("yyyy-MM-dd")
	) {
		return convertToDateTime(dataCorrente);
	}
	return convertToDateTime(new Date());
};

export const FissaDataCorrente = (data: DateTime | null) => {
	localStorage.setItem(
		DATA_CORRENTE,
		convertToDateTime(data)?.setLocale("it").toFormat("yyyy-MM-dd") || ""
	);
	localStorage.setItem(
		DATA_CORRENTE_DATA,
		convertToDateTime(new Date())?.setLocale("it").toFormat("yyyy-MM-dd") || ""
	);
};
