import React from "react";
import {Text, View, StyleSheet, Image} from "@react-pdf/renderer";
import {useState} from "react";

import {
    ColoreTypes,
    Lotto,
} from "components/main/pianificazione/pianificazioneSlice";
import {getColore} from "components/main/pianificazione/utility/lottoUtility";
import {Lavorazione} from "components/main/lavorazioni/lavorazioniSlice";
import { DateTime } from 'luxon';
import em_poultry from "../../../../../../static/images/common/em_poultry.png";
import {PackingList, Pallet} from "../../pesaturaPalletSlice";


interface Rafood24PdfProps {
    // colore: string
    // packingList: PackingList;
    // pallets: Pallet[];
}

const Rafood24Pdf = ({}: Rafood24PdfProps) => {

    return (
        <>
            <View style={{
                flexDirection: "column",
                justifyContent: "space-between",
                borderStyle: "solid",
                borderColor: "#000",
                borderWidth: 1,
                padding:2,
                height: 90
            }}>
                <View key={'rettangolo_colorato_numero'} style={{flexDirection: "row"}}>
                    <Text style={{fontSize: 9,}}>23</Text>
                    <View
                        style={{flexDirection: "column", marginLeft: 2,}}
                    >
                        <Text style={{fontSize: 5,}}>
                            Merce ricevuta
                        </Text>
                        <Text style={{fontSize: 5,}}>
                            Goods received
                        </Text>
                        <Text style={{fontSize: 5,}}>
                            Gut empfangen
                        </Text>
                    </View>
                </View>
                <View
                    style={{flexDirection: "column", marginLeft: 2, }}
                >
                    <Text style={{fontSize: 5,}}>
                        Luogo                                     il
                    </Text>
                    <Text style={{fontSize: 5,}}>
                        Place                                      on
                    </Text>
                    <Text style={{fontSize: 5,}}>
                        Ort                                          am
                    </Text>
                </View>
                <View
                    style={{flexDirection: "column", marginLeft: 2,}}
                >
                    <Text style={{fontSize: 5,}}>
                        Firma e timbro del destinatario
                    </Text>
                    <Text style={{fontSize: 5,}}>
                        Signature and stamp of the consignee
                    </Text>
                    <Text style={{fontSize: 5,}}>
                        Unterschrift und Stempel des Empfängers
                    </Text>
                </View>


            </View>

        </>
    );
}

export default Rafood24Pdf;
