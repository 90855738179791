import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { RootState, useAppDispatch } from "./../../../../redux/store";
import { getColore } from "./../../pianificazione/utility/lottoUtility";

import {
	LottiState,
	fetchLotti,
	resetLotti,
	Lotto,
} from "../../pianificazione/pianificazioneSlice";
import { Lavorazioni, fetchLavorazioni } from "./../lavorazioniSlice";
import { fetchArticoli } from "../../anagrafiche/anagraficheSlice";
import NavigazioneGiorniToolbar from "./../../../common/dateUtils/NavigazioneGiorniToolbar";
// import { recuperaPesature } from "./confezionamentiSlice";
// import { DATA_PIANIFICAZIONE_LAVORAZIONI_CORRENTE } from "./../../../../api/apiUtils";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

import moment from "moment";
import LavorazioniLottoManage from "./../lavorazioniPianificazione/LavorazioniLottoManage";
import ConfezionamentoHeader from "./ConfezionamentoHeader";

import {
	RecuperaDataCorrente,
	FissaDataCorrente,
} from "../../../common/dateUtils/RecuperaDataCorrente";

import {
	ErrorStatusTypes,
	ErrorsStack,
} from "./../../../common/errorsDeclarations";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

// import DateToday from "./../../../common/dateUtils/DateToday";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { styled, Box } from "@mui/system";

const LavorazioniConsuntiviGiornoPage = () => {
	moment.locale("it");
	const dispatch = useAppDispatch();
	const lotti: LottiState = useSelector<RootState, LottiState>(
		(state) => state.pianificazione.lotti
	);

	type LocationState = {
		giornoSettimana: any;
	};
	const location = useLocation();

	const recupera_data_corrente = RecuperaDataCorrente(location);

	// const recupera_data_corrente = () => {
	// 	if (location.state) {
	// 		const { giornoSettimana } = location.state as LocationState;
	// 		if (giornoSettimana) {
	// 			return giornoSettimana;
	// 		}
	// 	}
	// 	const dataLavorazioniCorrente = localStorage.getItem(
	// 		DATA_PIANIFICAZIONE_LAVORAZIONI_CORRENTE
	// 	);
	// 	if (!!dataLavorazioniCorrente) {
	// 		return new Date(dataLavorazioniCorrente);
	// 	}
	// 	return DateToday();
	// };

	const [data, setData] = React.useState<Date | undefined>(
		recupera_data_corrente
	);
	const [refresh, setRefresh] = React.useState<boolean>(false);

	useEffect(() => {
		data && FissaDataCorrente(data);

		// localStorage.setItem(
		// 	DATA_PIANIFICAZIONE_LAVORAZIONI_CORRENTE,
		// 	moment(data).format("YYYY-MM-DD")
		// );
		const data_da = data;
		const data_a = data;
		dispatch(resetLotti());
		dispatch(fetchLotti({ data_da, data_a }));
		dispatch(fetchArticoli());
		setRefresh(false);
	}, [data, refresh]);

	const lavorazioni: Lavorazioni = useSelector<RootState, Lavorazioni>(
		(state) => state.lavorazioniState.lavorazioni
	);

	const confezionamentiErrorsStack: ErrorsStack = useSelector<
		RootState,
		ErrorsStack
	>((state) => state.confezionamentiState.confezionamenti.errorsStack);

	const [expanded, setExpanded] = React.useState<string | false>(false);
	const handleChangeLottoPanel =
		(lotto: Lotto) => (event: React.SyntheticEvent, isExpanded: boolean) => {
			if (lotto.id) {
				dispatch(fetchLavorazioni(lotto.id));
			}
			setExpanded(isExpanded ? "lotto_" + lotto.id : false);
		};

	// const handleRecuperaPesature = () => {
	// 	setExpanded(false);
	// 	data && dispatch(recuperaPesature(data));
	// };

	return (
		<>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={
					lotti.errorsStack.status == ErrorStatusTypes.PENDING ||
					lavorazioni.errorsStack.status == ErrorStatusTypes.PENDING ||
					confezionamentiErrorsStack.status == ErrorStatusTypes.PENDING
				}
			>
				<CircularProgress color="inherit" />
			</Backdrop>

			<NavigazioneGiorniToolbar
				titolo={"Consuntivo Lavorazioni"}
				setData={setData}
				data={data}
				refresh={() => {
					setRefresh(true);
				}}
				// Toolbar={() => {
				// 	return (
				// 		<Stack
				// 			direction="row"
				// 			justifyContent="flex-end"
				// 			alignItems="center"
				// 			sx={{ ml: 2 }}
				// 			spacing={1}
				// 		>
				// 			<Button
				// 				onClick={handleRecuperaPesature}
				// 				variant="contained"
				// 				disabled={true}
				// 			>
				// 				Recupera Pesature
				// 			</Button>
				// 		</Stack>
				// 	);
				// }}
			/>

			<Box
				sx={{
					p: 1,
					// elevation: 0,
				}}
			>
				{lotti.results.map((lotto) => (
					<Accordion
						key={"lotto_panel" + lotto.id}
						expanded={expanded === "lotto_" + lotto.id}
						onChange={handleChangeLottoPanel(lotto)}
					>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="lavorazione_accordion"
							id="lavorazione_accordion_header"
						>
							<Grid container spacing={1}>
								<Grid item xs={1} md={1}>
									<Typography variant="h6">
										{lotto.ordinamento_lotto}
									</Typography>
								</Grid>
								<Grid item xs={3} md={2}>
									<Typography variant="h6">{lotto.committente_desc}</Typography>
								</Grid>
								<Grid item xs={2} md={2}>
									<Typography variant="h6">{lotto.tipo_capo_desc}</Typography>
								</Grid>
								<Grid item xs={2} md={1}>
									<Typography variant="h6">{getColore(lotto)}</Typography>
								</Grid>
								<Grid
									sx={{
										display: {
											xs: "none",
											sm: "none",
											md: "block",
											lg: "block",
										},
									}}
									item
									xs={0}
									sm={0}
									md={1}
									lg={2}
								></Grid>
							</Grid>
						</AccordionSummary>
						<AccordionDetails
							sx={{
								py: 0,
								"&:last-child": { pb: 1 },
							}}
						>
							{expanded === "lotto_" + lotto.id && (
								<LavorazioniLottoManage lotto={lotto} cosuntivazione={true} />
							)}
						</AccordionDetails>
					</Accordion>
				))}
			</Box>
		</>
	);
};

export default LavorazioniConsuntiviGiornoPage;
