import {createSlice, createAsyncThunk, PayloadAction} from "@reduxjs/toolkit";
import {
    ErrorStatusTypes,
    ErrorsStack,
    parseErrorMessage,
} from "../../common/errorsDeclarations";

import * as palletsApi from "../../../api/pallets/palletsApi";
import moment from "moment";
import {toast} from "react-toastify";
// import { useDispatch } from "react-redux";

moment.locale("it");


export interface PackingList {
    id?: number | null;
    committente: number;
    numero_ordinine_consegna?: string;
    numero_ddt_committente?: string;
    stato: "A" | "C" | "S";
    data?: Date | string;
    targhe_camion?: string;
    num_pallets?: number;
    numero_cassette?: number;
    peso_netto?: number;
    peso_lordo?: number;

    lista_lotti?:string[],
    lista_ordini_cliente?:string[],
    date_macellazione?:string[],
    date_scadenza?:string[],
    errorsStack?: ErrorsStack;
}

export interface PackingListState {
    count: number;
    page: number;
    num_pages: number;
    next?: URL;
    previous?: URL;
    results: PackingList[];
    errorsStack: ErrorsStack;
}


export interface Pallet {
    id?: number | null;
    lavorazione: number;
    lavorazione_descrizione?: string;
    lotto?: number;
    lotto_codice_tracciabilita?: string;
    packing_list?: number;
    numero_cassette?: number;
    peso_netto?: number;
    peso_lordo?: number;
    data_produzione?: Date | string;
    data_scadenza?: Date | string;
    errorsStack?: ErrorsStack;
}

export interface PesaturaPalletsState {
    count: number;
    page: number;
    num_pages: number;
    next?: URL;
    previous?: URL;
    results: Pallet[];
    errorsStack: ErrorsStack;
}

// export interface LottoCorrente extends Lotto {
//   errorsStack: ErrorsStack;
// }

export interface PesaturaPalletsStrutturaState {
    pallets: PesaturaPalletsState;
    packingListState: PackingListState;
    nuovopalletId?: number;
}

const initialState: PesaturaPalletsStrutturaState = {
    pallets: {
        count: 0,
        page: 0,
        num_pages: 0,
        next: undefined,
        previous: undefined,
        results: [],
        errorsStack: {status: ErrorStatusTypes.OK},
    },
    packingListState: {
        count: 0,
        page: 0,
        num_pages: 0,
        next: undefined,
        previous: undefined,
        results: [],
        errorsStack: {status: ErrorStatusTypes.OK},
    },
};

export const fetchPackingListPallets = createAsyncThunk(
    "PesaturaPallet/fetchPackingListPallets",
    async (parametri: {
        packing_list_id: number;
    }) => {
        return await palletsApi.fetchPackingListPallets(
            parametri.packing_list_id,
        );
    }
);

export const fetchLavorazionePallets = createAsyncThunk(
    "PesaturaPallet/fetchLavorazionePallets",
    async (parametri: {
        lavorazione_id: number;
    }) => {
        return await palletsApi.fetchLavorazionePallets(
            parametri.lavorazione_id,
        );
    }
);

export const getPallet = createAsyncThunk(
    "PesaturaPallet/getPallet",
    async (parametri: { pallet_id: number }) => {
        return await palletsApi.getPallet(
            parametri.pallet_id
        );
    }
);

export const savePallet = createAsyncThunk(
    "PesaturaPallet/savePallet",
    async (parametri: { palletToSave: Pallet }, thunkApi) => {
        return await palletsApi.savePallet(
            parametri.palletToSave
        ).then((response) => {
            parametri.palletToSave.packing_list &&
            thunkApi.dispatch(fetchPackingListPallets({packing_list_id: parametri.palletToSave.packing_list}));
            thunkApi.dispatch(getPackingList({packing_list_id: parametri.palletToSave.packing_list || 0}));
            return response;
        });
    }
);

export const deletePallet = createAsyncThunk(
    "PesaturaPallet/deletePallet",
    async (parametri: { palletToDelete: Pallet }, thunkApi) => {
        return await palletsApi.deletePallet(
            parametri.palletToDelete
        ).then(
            (response) => {
                parametri.palletToDelete.packing_list &&
                thunkApi.dispatch(fetchPackingListPallets({packing_list_id: parametri.palletToDelete.packing_list}));
                thunkApi.dispatch(getPackingList({packing_list_id: parametri.palletToDelete.packing_list || 0}));
                return response;
            }
        )
            ;
    }
);


export const fetchPackingList = createAsyncThunk(
    "PesaturaPallet/fetchPackingList",
    async (parametri: {
        committente_id: number;
        stato?: string;
        data_da?: Date;
        data_a?: Date;
        numeroRecord?: number;
        page?: number,
        search?: string
    }) => {
        return await palletsApi.fetchPackingList(
            parametri.committente_id,
            parametri.stato,
            parametri.data_da,
            parametri.data_a,
            parametri.numeroRecord,
            parametri.page,
            parametri.search
        );
    }
);

export const getPackingList = createAsyncThunk(
    "PesaturaPallet/getPackingList",
    async (parametri: { packing_list_id: number }) => {
        return await palletsApi.getPackingList(
            parametri.packing_list_id
        );
    }
);

export const savePackingList = createAsyncThunk(
    "PesaturaPallet/savePackingList",
    async (parametri: { packingListToSave: PackingList }, thunkApi) => {
        return await palletsApi.savePackingList(
            parametri.packingListToSave
        ).then((response) => {
                parametri.packingListToSave.committente &&
                thunkApi.dispatch(fetchPackingList({committente_id: parametri.packingListToSave.committente, stato: "A"}));
                return response;

            }
        );
    }
);


export const palletSlice = createSlice({
    name: "palletState",
    initialState,
    reducers: {
        resetPalletId: (state) => {
            state.nuovopalletId = undefined;
        },
    },
    extraReducers: (builder) => {
        // fetch Pallet del PackingList
        builder.addCase(fetchPackingListPallets.pending, (state, action) => {
            state.pallets.errorsStack = {
                status: ErrorStatusTypes.PENDING,
            };
        });
        builder.addCase(fetchPackingListPallets.fulfilled, (state, action) => {
            state.pallets = action.payload;

            // riotorna l'array ordinato per id
            state.pallets.results.sort((a, b) => {
                if (a.id && b.id) {
                    return b.id - a.id;
                } else {
                    return 0;
                }
            });

            state.pallets.errorsStack = {status: ErrorStatusTypes.OK};
        });
        builder.addCase(fetchPackingListPallets.rejected, (state, action) => {
            state.pallets.errorsStack = parseErrorMessage(action.error);
        });

        // get PackingList
        builder.addCase(getPackingList.pending, (state, action) => {
            state.packingListState.errorsStack = {
                status: ErrorStatusTypes.PENDING,
            };
        });
        builder.addCase(getPackingList.fulfilled, (state, action) => {
            state.packingListState.results =
                state.packingListState.results.filter((packingList) => {
                    packingList.id != action.payload.id;
                });
            state.packingListState.results.push(action.payload);
            // riotorna l'array ordinato per data
            state.packingListState.results.sort((a, b) => {
                return (
                    new Date(a?.data || "").getTime() -
                    new Date(b?.data || "").getTime()

                );
            });
            state.packingListState.errorsStack = {status: ErrorStatusTypes.OK};
        });
        builder.addCase(getPackingList.rejected, (state, action) => {
            state.packingListState.errorsStack = parseErrorMessage(action.error);
        });

        // fetch Pallet della lavorazione
        builder.addCase(fetchLavorazionePallets.pending, (state, action) => {
            state.pallets.errorsStack = {
                status: ErrorStatusTypes.PENDING,
            };
        });
        builder.addCase(fetchLavorazionePallets.fulfilled, (state, action) => {
            state.pallets = action.payload;

            // riotorna l'array ordinato per id
            state.pallets.results.sort((a, b) => {
                if (a.id && b.id) {
                    return b.id - a.id;
                } else {
                    return 0;
                }
            });

            state.pallets.errorsStack = {status: ErrorStatusTypes.OK};
        });
        builder.addCase(fetchLavorazionePallets.rejected, (state, action) => {
            state.pallets.errorsStack = parseErrorMessage(action.error);
        });

        // get
        builder.addCase(getPallet.pending, (state, action) => {
            state.pallets.errorsStack = {
                status: ErrorStatusTypes.PENDING,
            };
        });
        builder.addCase(getPallet.fulfilled, (state, action) => {
            state.pallets.results =
                state.pallets.results.filter((Pallet) => {
                    Pallet.id != action.payload.id;
                });
            state.pallets.results.push(action.payload);
            // riotorna l'array ordinato per id
            state.pallets.results.sort((a, b) => {
                if (a.id && b.id) {
                    return b.id - a.id;
                } else {
                    return 0;
                }
            });
            state.pallets.errorsStack = {status: ErrorStatusTypes.OK};
        });
        builder.addCase(getPallet.rejected, (state, action) => {
            state.pallets.errorsStack = parseErrorMessage(action.error);
        });

        // save save Pallet
        builder.addCase(savePallet.pending, (state, action) => {
            state.pallets.errorsStack = {
                status: ErrorStatusTypes.PENDING,
            };
        });
        builder.addCase(savePallet.fulfilled, (state, action) => {
            state.pallets.results = state.pallets.results.map(
                (Pallet) => {
                    if (Pallet.id == action.payload.id) {
                        return action.payload;
                    } else {
                        return Pallet;
                    }
                }
            );
            state.pallets.errorsStack = {
                status: ErrorStatusTypes.SUCCESS,
            };


        });
        builder.addCase(savePallet.rejected, (state, action) => {
            toast.error("Errore:" + action?.error?.message || "");

            state.pallets.results = state.pallets.results.map(
                (Pallet) => {
                    if (Pallet.id == action.meta.arg?.palletToSave?.id) {
                        return {
                            ...Pallet,
                            errorsStack: parseErrorMessage(action.error),
                        };
                    } else {
                        return Pallet;
                    }
                }
            );
            state.pallets.errorsStack = parseErrorMessage(action.error);
        });

        // cancella Pallet
        builder.addCase(deletePallet.pending, (state, action) => {
            state.pallets.errorsStack = {
                status: ErrorStatusTypes.PENDING,
            };
        });
        builder.addCase(deletePallet.fulfilled, (state, action) => {
            state.pallets.errorsStack = {
                status: ErrorStatusTypes.SUCCESS,
            };
            state.pallets.results =
                state.pallets.results.filter(
                    (Pallet) => Pallet.id != action.meta.arg.palletToDelete.id
                );
            toast.success(action.payload.message || "Scheda macello cancellata.");
        });
        builder.addCase(deletePallet.rejected, (state, action) => {
            state.pallets.errorsStack = parseErrorMessage(action.error);
        });

        // fetch PackingList
        builder.addCase(fetchPackingList.pending, (state, action) => {
            state.packingListState.errorsStack = {
                status: ErrorStatusTypes.PENDING,
            };
        });
        builder.addCase(fetchPackingList.fulfilled, (state, action) => {
            state.packingListState = action.payload;
            state.packingListState.errorsStack = {
                status: ErrorStatusTypes.SUCCESS,
            };
        });
        builder.addCase(fetchPackingList.rejected, (state, action) => {
            state.packingListState.errorsStack = parseErrorMessage(action.error);
        });


        // save save savePackingList
        builder.addCase(savePackingList.pending, (state, action) => {
            state.packingListState.errorsStack = {
                status: ErrorStatusTypes.PENDING,
            };
        });

        builder.addCase(savePackingList.fulfilled, (state, action) => {

            state.packingListState.results = state.packingListState.results.filter(
                (packingList) => packingList.id !== action.payload.id
            );

            state.packingListState.results.push({
                ...action.payload,
                errorsStack: {status: ErrorStatusTypes.SUCCESS},
            });
            // riotorna l'array ordinato per data
            state.packingListState.results.sort((a, b) => {
                return (
                    new Date(a?.data || "").getTime() -
                    new Date(b?.data || "").getTime()

                );
            });
            state.packingListState.errorsStack = {
                status: ErrorStatusTypes.SUCCESS,
            };
        });

        builder.addCase(savePackingList.rejected, (state, action) => {
            toast.error("Errore:" + action?.error?.message || "");

            state.packingListState.results = state.packingListState.results.map(
                (packingList) => {
                    if (packingList.id == action.meta.arg?.packingListToSave?.id) {
                        return {
                            ...packingList,
                            errorsStack: parseErrorMessage(action.error),
                        };
                    } else {
                        return packingList;
                    }
                }
            );
            state.pallets.errorsStack = parseErrorMessage(action.error);
        });
    },
});

// Action creators are generated for each case reducer function
export const {resetPalletId} = palletSlice.actions;

export const pesaturaPalletReducer = palletSlice.reducer;
