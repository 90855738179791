import React, { useState, useEffect } from "react";

import { useForm, SubmitHandler, Controller } from "react-hook-form";

import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import ReactHookFormSelect from "../../common/ReactHookFormSelect";
import { Button } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Switch from "@mui/material/Switch";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";

import {
	NumericFormat,
	NumericFormatProps,
	PatternFormat,
} from "react-number-format";
import {
	LavorazioneCommittente,
	PettorinaTypes,
	DimensioneCassettaTypes,
	MaterialeCassettaTypes,
	MaterialeSottoTypes,
	MaterialeSopraTypes,
	DisposizioneBilanciaTypes,
} from "./lavorazioniSlice";
import { Articolo, Etichetta, CodiceAI } from "../anagrafiche/anagraficheSlice";
import {
	ErrorStatusTypes,
	ErrorsStack,
	parseErrorMessage,
} from "../../common/errorsDeclarations";

interface LavorazioneCommittenteFormProps {
	lavorazioneCommittente: LavorazioneCommittente;
	articoli: Articolo[];
	etichette: Etichetta[];
	codiciAI: CodiceAI[];
	annulla: () => void;
	lotto_id: number;
	saveLavorazioneCommittente: (
		lottoId: number,
		lavorazioneCommittenteToSave: LavorazioneCommittente
	) => void;
	deleteLavorazioneCommittente: (
		lottoId: number,
		lavorazioneCommittenteToDelete: LavorazioneCommittente
	) => void;
}

const LavorazioneCommittenteForm = ({
	lavorazioneCommittente,
	articoli,
	etichette,
	codiciAI,
	annulla,
	lotto_id,
	saveLavorazioneCommittente,
	deleteLavorazioneCommittente,
}: LavorazioneCommittenteFormProps) => {
	const {
		handleSubmit,
		register,
		formState: { errors, isDirty },
		setValue,
		watch,
		getValues,
		control,
		setError,
		clearErrors,
		reset,
	} = useForm<LavorazioneCommittente>();

	const listaCampi = Object.keys(lavorazioneCommittente) as Array<
		keyof LavorazioneCommittente
	>;

	useEffect(() => {
		listaCampi.map((field) => {
			setValue(field, lavorazioneCommittente[field]);
		});
	}, [
		lavorazioneCommittente.id,
		lavorazioneCommittente.tipo_capo,
		lavorazioneCommittente.committente,
		listaCampi.forEach((field) => {
			lavorazioneCommittente[field];
		}),
		lavorazioneCommittente.errorsStack?.status == ErrorStatusTypes.SUCCESS,
	]);

	useEffect(() => {
		listaCampi.forEach((field) => {
			lavorazioneCommittente?.errorsStack?.fieldsErrors &&
				lavorazioneCommittente.errorsStack.fieldsErrors[field] &&
				setError(field, {
					type: "server",
					message:
						lavorazioneCommittente.errorsStack.fieldsErrors[field].toString(),
				});
		});
	}, [lavorazioneCommittente?.errorsStack?.fieldsErrors]);

	const onSubmit: SubmitHandler<LavorazioneCommittente> = (
		lavorazioneCommittente
	) => {
		saveLavorazioneCommittente(lotto_id, lavorazioneCommittente);
	};

	//  ######################################################

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Grid
				container
				sx={{ flexGrow: 1, mb: 1 }}
				rowSpacing={1}
				columnSpacing={1}
				justifyContent="center"
				alignItems="flex-start"
			>
				<Grid size={{ xs:3, sm:2, md:2, lg:2 }}>
					<ReactHookFormSelect
						name="articolo"
						label="lavorazione"
						register={register}
						control={control}
						watch={watch}
						variant="outlined"
						required={true}
						error={errors?.articolo?.message}
						sx={{ width: "100%" }}
					>
						{articoli.map((option) => {
							return (
								<MenuItem key={option.id} value={option.id}>
									{option.descrizione}
								</MenuItem>
							);
						})}
					</ReactHookFormSelect>
				</Grid>
				<Grid size={{ xs:6, sm:4, md:4, lg:4 }}>
					<TextField
						label="descrizione"
						// disabled={schedaCarico.numero_capi_pianificati}
						variant="outlined"
						InputLabelProps={{
							shrink: true,
						}}
						{...register("descrizione")}
						error={!!errors?.descrizione}
						helperText={errors?.descrizione?.message}
						autoComplete={"off"}
						sx={{ width: "100%" }}
					/>
				</Grid>
				<Grid size={{ xs:3, sm:2, md:2, lg:2 }}>
					<TextField
						label="codice cliente"
						// disabled={schedaCarico.numero_capi_pianificati}
						variant="outlined"
						InputLabelProps={{
							shrink: true,
						}}
						{...register("codice_articolo_committente")}
						error={!!errors?.codice_articolo_committente}
						helperText={errors?.codice_articolo_committente?.message}
						autoComplete={"off"}
						sx={{ width: "100%" }}
					/>
				</Grid>
				<Grid size={{ xs:6, sm:4, md:4, lg:4 }}>
					<TextField
						label="destinazione"
						// disabled={schedaCarico.numero_capi_pianificati}
						variant="outlined"
						InputLabelProps={{
							shrink: true,
						}}
						{...register("destinazione")}
						error={!!errors?.destinazione}
						helperText={errors?.destinazione?.message}
						autoComplete={"off"}
						sx={{ width: "100%" }}
					/>
				</Grid>
			</Grid>
			<Grid
				container
				sx={{ flexGrow: 1, mb: 1 }}
				rowSpacing={1}
				columnSpacing={1}
				justifyContent="center"
				alignItems="flex-start"
			>
				<Grid size={{ xs:3, sm:2, md:2, lg:2 }}>
					<ReactHookFormSelect
						name="pettorina"
						label="pettorina"
						register={register}
						control={control}
						watch={watch}
						variant="outlined"
						error={errors?.pettorina?.message}
						sx={{ width: "100%" }}
					>
						<MenuItem value="">
							<em>Nessuno</em>
						</MenuItem>
						{Object.entries(PettorinaTypes).map(([key, value]) => {
							return (
								<MenuItem key={key} value={key}>
									{value}
								</MenuItem>
							);
						})}
					</ReactHookFormSelect>
				</Grid>
				<Grid size={{ xs:3, sm:2, md:2, lg:2 }}>
					<ReactHookFormSelect
						name="dimensione_cassetta"
						label="dimensione cassetta"
						register={register}
						control={control}
						watch={watch}
						variant="outlined"
						required={true}
						error={errors?.dimensione_cassetta?.message}
						sx={{ width: "100%" }}
					>
						{Object.entries(DimensioneCassettaTypes).map(([key, value]) => {
							return (
								<MenuItem key={key} value={key}>
									{value}
								</MenuItem>
							);
						})}
					</ReactHookFormSelect>
				</Grid>
				<Grid size={{ xs:3, sm:2, md:2, lg:2 }}>
					<ReactHookFormSelect
						name="materiale_cassetta"
						label="materiale imballo"
						register={register}
						control={control}
						watch={watch}
						variant="outlined"
						required={true}
						error={errors?.materiale_cassetta?.message}
						sx={{ width: "100%" }}
					>
						{Object.entries(MaterialeCassettaTypes).map(([key, value]) => {
							return (
								<MenuItem key={key} value={key}>
									{value}
								</MenuItem>
							);
						})}
					</ReactHookFormSelect>
				</Grid>
				<Grid size={{ xs:3, sm:2, md:2, lg:2 }}>
					<ReactHookFormSelect
						name="materiale_sotto"
						label="materiale sotto"
						register={register}
						control={control}
						watch={watch}
						variant="outlined"
						error={errors?.materiale_sotto?.message}
						sx={{ width: "100%" }}
					>
						<MenuItem value="">
							<em>Nessuno</em>
						</MenuItem>
						{Object.entries(MaterialeSottoTypes).map(([key, value]) => {
							return (
								<MenuItem key={key} value={key}>
									{value}
								</MenuItem>
							);
						})}
					</ReactHookFormSelect>
				</Grid>
				<Grid size={{ xs:3, sm:2, md:2, lg:2 }}>
					<ReactHookFormSelect
						name="materiale_sopra"
						label="materiale sopra"
						register={register}
						control={control}
						watch={watch}
						variant="outlined"
						error={errors?.materiale_sopra?.message}
						sx={{ width: "100%", ml: 2 }}
					>
						<MenuItem value="">
							<em>Nessuno</em>
						</MenuItem>
						{Object.entries(MaterialeSopraTypes).map(([key, value]) => {
							return (
								<MenuItem key={key} value={key}>
									{value}
								</MenuItem>
							);
						})}
					</ReactHookFormSelect>
				</Grid>
			</Grid>
			<Divider sx={{ my: 2 }} textAlign="left">
				In bilancia
			</Divider>
			<Grid
				container
				sx={{
					flexGrow: 1,
					mb: 1,
				}}
				rowSpacing={1}
				columnSpacing={1}
				// justifyContent="center"
				alignItems="flex-start"
			>
				<Grid size={{ xs:3, sm:2, md:2, lg:2 }}>
					<ReactHookFormSelect
						name="disposizione_bilancia"
						label="disposizione in bilancia"
						register={register}
						control={control}
						watch={watch}
						required={true}
						variant="outlined"
						error={errors?.disposizione_bilancia?.message}
						sx={{ width: "100%" }}
					>
						{Object.entries(DisposizioneBilanciaTypes).map(([key, value]) => {
							return (
								<MenuItem key={key} value={key}>
									{value}
								</MenuItem>
							);
						})}
					</ReactHookFormSelect>
				</Grid>
				<Grid size={{ xs:3, sm:2, md:1, lg:1 }}>
					<FormControlLabel
						label={
							<Typography fontSize="0.8rem" marginLeft="0.1rem">
								{getValues("etichetta_su_cassetta")
									? "con etichetta cassetta"
									: "senza etichetta cassetta"}
							</Typography>
						}
						control={
							<Switch
								id="etichetta_su_cassetta"
								checked={watch("etichetta_su_cassetta") || false}
								onChange={(
									event: React.ChangeEvent<HTMLInputElement>,
									newValue: any
								) => {
									setValue("etichetta_su_cassetta", newValue, {
										shouldDirty: true,
									});
								}}
								color="info"
								size="small"
							/>
						}
					/>
					<FormControlLabel
						label={
							<Typography fontSize="0.8rem" marginLeft="0.1rem">
								{getValues("etichetta_su_pallet")
									? "con etichetta pallet"
									: "senza etichetta pallet"}
							</Typography>
						}
						control={
							<Switch
								id="etichetta_su_pallet"
								checked={watch("etichetta_su_pallet") || false}
								onChange={(
									event: React.ChangeEvent<HTMLInputElement>,
									newValue: any
								) => {
									setValue("etichetta_su_pallet", newValue, {
										shouldDirty: true,
									});
								}}
								color="info"
								size="small"
							/>
						}
					/>
				</Grid>
				<Grid size={{ xs:3, sm:2, md:2, lg:2 }}>
					<ReactHookFormSelect
						name="etichetta_cassette"
						label="etichetta cassette"
						register={register}
						control={control}
						watch={watch}
						variant="outlined"
						error={errors?.etichetta_cassette?.message}
						sx={{ width: "100%" }}
					>
						<MenuItem key={"default"} value={""}>
							{"default committente"}
						</MenuItem>
						{etichette.map((option) => {
							return (
								<MenuItem key={option.id} value={option.id}>
									{option.nome}
								</MenuItem>
							);
						})}
					</ReactHookFormSelect>
				</Grid>
				<Grid size={{ xs:3, sm:2, md:2, lg:2 }}>
					<ReactHookFormSelect
						name="etichetta_pallet"
						label="etichetta pallet"
						register={register}
						control={control}
						watch={watch}
						variant="outlined"
						error={errors?.etichetta_pallet?.message}
						sx={{ width: "100%" }}
					>
						{etichette.map((option) => {
							return (
								<MenuItem key={option.id} value={option.id}>
									{option.nome}
								</MenuItem>
							);
						})}
					</ReactHookFormSelect>
				</Grid>
				<Grid size={{ xs:3, sm:2, md:2, lg:2 }}>
					<ReactHookFormSelect
						name="velocita_bilancia"
						label="velocità bilancia pallet"
						register={register}
						control={control}
						watch={watch}
						variant="outlined"
						required={true}
						error={errors?.velocita_bilancia?.message}
						sx={{ width: "100%" }}
					>
						<MenuItem key={"V"} value={"V"}>
							Veloce
						</MenuItem>
						<MenuItem key={"L"} value={"L"}>
							Lenta
						</MenuItem>
					</ReactHookFormSelect>
				</Grid>

				<Grid size={{ xs:3, sm:2, md:1, lg:1 }}>
					<Controller
						name="tara"
						control={control}
						render={({ field }) => (
							<NumericFormat
								id="peso_medio"
								customInput={TextField}
								variant="outlined"
								// size="small"
								label="tara kg"
								InputLabelProps={{
									shrink: true,
								}}
								error={!!errors?.tara}
								helperText={errors?.tara?.message}
								{...register("tara")}
								// value={Intl.NumberFormat("it-IT").format(field.value || 0)}
								// value={Intl.NumberFormat("it-IT").format(getValues("peso_medio") || 0)}
								value={field.value}
								autoComplete="off"
								prefix="kg "
								thousandSeparator="."
								decimalSeparator=","
								valueIsNumericString={true}
								onValueChange={(v) => {
									setValue("tara", Number(v.value));
								}}
								sx={{ width: "100%", height: "100%" }}
								// onBlur={handlerDetailChaingSubmit}
							/>
						)}
					/>
				</Grid>
				<Grid size={{ xs:3, sm:2, md:1, lg:1 }}>
					<TextField
						label="cassette x pallet"
						// disabled={schedaCarico.numero_capi_pianificati}
						variant="outlined"
						InputLabelProps={{
							shrink: true,
						}}
						{...register("cassette_x_pallet")}
						required
						error={!!errors?.codice_articolo_committente}
						helperText={errors?.codice_articolo_committente?.message}
						autoComplete={"off"}
						sx={{ width: "100%" }}
					/>
				</Grid>

				<Grid size={{ xs:3, sm:2, md:2, lg:2 }}>
					<ReactHookFormSelect
						name="codiciAI"
						label="codici AI"
						register={register}
						control={control}
						watch={watch}
						variant="outlined"
						error={errors?.codiciAI?.message}
						sx={{ width: "100%" }}
					>
						<MenuItem key={"nessuno"} value={undefined}>
							{"nessuno"}
						</MenuItem>
						{codiciAI.map((option) => {
							return (
								<MenuItem key={option.id} value={option.id}>
									{option.nome}
								</MenuItem>
							);
						})}
					</ReactHookFormSelect>
				</Grid>
			</Grid>

			<Divider sx={{ my: 2 }} textAlign="left">
				Per pallettizzatore
			</Divider>
			<Grid
				container
				sx={{
					flexGrow: 1,
					mb: 1,
				}}
				rowSpacing={1}
				columnSpacing={1}
				// justifyContent="center"
				alignItems="flex-start"
			>
				<Grid size={{ xs:3, sm:2, md:1, lg:1 }}>
					<FormControlLabel
						label={
							<Typography fontSize="0.8rem" marginLeft="0.1rem">
								{getValues("da_pallettizzare")
									? "da gestire con il pallettizzatore"
									: "palletizzare manualmente"}
							</Typography>
						}
						control={
							<Switch
								id="da_pallettizzare"
								checked={watch("da_pallettizzare") || false}
								onChange={(
									event: React.ChangeEvent<HTMLInputElement>,
									newValue: any
								) => {
									setValue("da_pallettizzare", newValue, {
										shouldDirty: true,
									});
								}}
								color="info"
								size="small"
							/>
						}
					/>
				</Grid>
			</Grid>

			<Grid sx={{ flexGrow: 1, mt: 3 }} container spacing={2}>
				<Grid size={{ xs:12 }}>
					<Stack direction="row" spacing={2} justifyContent="center">
						<Button disabled={!isDirty} variant="outlined" type="submit">
							{lavorazioneCommittente.id ? "Salva" : "Aggiungi"}
						</Button>
						<Button
							variant="outlined"
							disabled={!lavorazioneCommittente.id}
							onClick={() =>
								deleteLavorazioneCommittente(lotto_id, lavorazioneCommittente)
							}
						>
							Elimina
						</Button>
						<Button variant="outlined" onClick={annulla}>
							Annulla
						</Button>
					</Stack>
				</Grid>
			</Grid>
		</form>
	);
};

export default LavorazioneCommittenteForm;
