import React from "react";
import { Lotto } from "../../pianificazione/pianificazioneSlice";
import { Parametri } from "../../anagrafiche/anagraficheSlice";

import ConsuntiviVivoLottoList from "./ConsuntiviVivoLottoList";
import Avatar from "@mui/material/Avatar";
import { theme } from "../../../../theme";

import InviaAllegatiBottoni from "../../allegati/InviaAllegatiBottoni";
import Grid from "@mui/material/Grid2";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

interface ConsuntiviVivoLottoCardProps {
	lotto: Lotto;
	parametri: Parametri;
}

const ConsuntiviVivoLottoCard = ({
	lotto,
	parametri,
}: ConsuntiviVivoLottoCardProps) => {
	return (
		<Card key={"Box_consuntiviVivo_page_" + lotto.id} sx={{ my: 2 }}>
			<CardContent>
						<Stack
							direction="row"
							justifyContent="flex-start"
							alignItems="flex-start"
							spacing={4}
							sx={{ mb: 1 }}
						>
							{" "}
							<Avatar
								sx={{
									width: "1.5rem",
									height: "1.5rem",
									bgcolor: lotto.resa_confermata
										? theme.palette.success.main
										: theme.palette.primary.main,
									color: "white",
								}}
								aria-label="recipe"
							>
								{lotto.ordinamento_lotto}
							</Avatar>
							<Typography
								variant="h6"
								component="div"
								color="text.secondary"
								gutterBottom
							>
								{lotto.committente_desc}
							</Typography>
							<Typography
								variant="h6"
								component="div"
								color="text.secondary"
								gutterBottom
							>
								{lotto.numero_capi + " " + lotto.tipo_capo_desc}
							</Typography>
							<Typography
								variant="h6"
								component="div"
								color="text.secondary"
								gutterBottom
							>
								{lotto.allevamento_pack?.nome}
							</Typography>
						</Stack>

				<ConsuntiviVivoLottoList lotto={lotto} parametri={parametri} />
				<InviaAllegatiBottoni lotto={lotto} />
			</CardContent>
		</Card>
	);
};

export default ConsuntiviVivoLottoCard;
