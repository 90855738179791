import { handleResponse, handleError, headerset } from "../apiUtils";
import { DateTime } from 'luxon';
import {convertToDateTime} from "../../components/common/dateUtils/convertToDateTime";

const baseUrl = () => {
	return process.env.API_URL + "/api/bolle_fatture/saldo_pallet_committenti/";
};

export function fetchSaldoPalletCommittenti(
	committente_id?: number | null,
	data_da?: Date,
	data_a?: Date
) {
	// let url =
	// 	process.env.API_URL + "/api/bolle_fatture/saldo_pallet_committenti/";
	let url = baseUrl();
	if (committente_id || data_da || data_a) {
		url += "?";
	}
	if (committente_id) {
		url += `committente_id=${committente_id}&`;
	}
	if (data_da) {
		url += `data_da=${convertToDateTime(data_da)?.setLocale("it").toFormat("yyyy-MM-dd")}&`;
	}
	if (data_a) {
		url += `data_a=${convertToDateTime(data_a)?.setLocale("it").toFormat("yyyy-MM-dd")}&`;
	}
	if (data_a) {
		url += `?data_da=${convertToDateTime(data_da)?.setLocale("it").toFormat("yyyy-MM-dd")}&data_a=${convertToDateTime(data_a)?.setLocale("it").toFormat('yyyy-MM-dd')}`;
	}
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}
