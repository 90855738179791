import React, {useEffect, useState} from "react";
import {RootState, useAppDispatch} from "../../../redux/store";
import {useSelector} from "react-redux";
import {ErrorStatusTypes} from "../../common/errorsDeclarations";
import {useParams} from "react-router-dom";
import {
    UtenteLogged,
    ResetPasswordProps,
    passwordResetCheck,
    passwordReset,
} from "../../authentication/authenticationSlice";
import {useForm, SubmitHandler} from "react-hook-form";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import {
    Backdrop,
    CircularProgress,
    TextField,
    Link,
    Typography,
    Container,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import logoScarso from "../../../../static/images/logo/logo-Scarso_nuovo_solo.png";

const WellComePage = () => {
    const dispatch = useAppDispatch();

    const {uidb64} = useParams();
    const {token} = useParams();

    const {
        handleSubmit,
        register,
        formState: {errors,},
        setValue,
        setError,
        clearErrors,
        reset,
    } = useForm<ResetPasswordProps>();

    const utenteLogged: UtenteLogged = useSelector<RootState, UtenteLogged>(
        (state) => state.authentication.utenteLogged
    );
    const [resetPassword, setResetPassword] = useState<ResetPasswordProps>({
        uidb64: uidb64 || "",
        token: token || "",
        new_password: "",
        new_password2: "",
    });

    useEffect(() => {
        console.log("uidb64: ", uidb64);
        console.log("utenteLogged: ", utenteLogged);
        !(
            utenteLogged.id ||
            utenteLogged.errorsStack.status == ErrorStatusTypes.ERROR
        ) && dispatch(passwordResetCheck(resetPassword));
    }, []);

    const listaCampi = Object.keys(resetPassword) as Array<
        keyof ResetPasswordProps
    >;

    useEffect(() => {
        reset();
        listaCampi.map((field) => {
            setValue(field, resetPassword[field]);
        });
    }, [
        listaCampi.forEach((field) => {
            resetPassword[field];
        }),
        resetPassword,
    ]);

    useEffect(() => {
        clearErrors();
        listaCampi.forEach((field) => {
            utenteLogged?.errorsStack?.fieldsErrors &&
            utenteLogged.errorsStack.fieldsErrors[field] &&
            setError(field, {
                type: "server",
                message: utenteLogged.errorsStack.fieldsErrors[field].toString(),
            });
        });
    }, [utenteLogged?.errorsStack?.fieldsErrors]);

    const onSubmit: SubmitHandler<ResetPasswordProps> = (resetPassword) => {
        if (resetPassword.new_password === resetPassword.new_password2) {
            dispatch(passwordReset(resetPassword));
        } else {
            setError("new_password2", {
                type: "server",
                message: "le password devono essere uguali",
            });
        }
    };

    return (
        <Container component="main">
            <Backdrop
                sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={utenteLogged.errorsStack.status == ErrorStatusTypes.PENDING}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
            <Grid container justifyContent="center" sx={{mt: 5}}>
                <Grid size={{xs: 12, sm: 9, md: 7, lg: 7}}>
                    <Card raised>
                        <CardContent sx={{p: 2}}>
                            {/* <Box
				sx={{
					marginTop: (theme) => theme.spacing(8),
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
				}}
			> */}
                            <Avatar alt="Logo Scarso" src={logoScarso}/>
                            <Typography component="h1" variant="h3">
                                T{"&"}MA - Benvenuto nel sistema di Macello Scarso
                            </Typography>
                            {utenteLogged.errorsStack.status == ErrorStatusTypes.ERROR &&
                            utenteLogged.username == "anonimo" ? (
                                <Alert severity="error" sx={{mt: 10}}>
                                    <AlertTitle>Errore</AlertTitle>
                                    <strong>
                                        {utenteLogged?.errorsStack?.fieldsErrors &&
                                            utenteLogged?.errorsStack?.fieldsErrors[
                                                "token"
                                                ].toString()}
                                        !
                                    </strong>
                                </Alert>
                            ) : utenteLogged.errorsStack.status == ErrorStatusTypes.SUCCESS ? (
                                <Card sx={{mt: 3, minWidth: 275}}>
                                    <CardContent>
                                        <Typography
                                            sx={{fontSize: 14}}
                                            color="text.secondary"
                                            gutterBottom
                                        >
                                            {"Grazie " +
                                                utenteLogged.first_name +
                                                ", la tua password è stata impostata correttamente."}
                                        </Typography>
                                        <Typography variant="h5" component="div">
                                            Accedi con la nuova Password.
                                        </Typography>
                                        <Typography sx={{mb: 1.5}} color="text.secondary">
                                            Segui il link per accedere
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        <Button href="/signin/">Accedi</Button>
                                    </CardActions>
                                </Card>
                            ) : (
                                <>
                                    <Typography
                                        sx={{
                                            marginTop: (theme) => theme.spacing(3),
                                            marginBottom: (theme) => theme.spacing(3),
                                        }}
                                    >
                                        {"Ciao " + utenteLogged.first_name + "."}
                                    </Typography>
                                    <Typography display="inline">
                                        Potrai accedere con la tua username (
                                    </Typography>

                                    <Typography sx={{color: "red"}} display="inline">
                                        {utenteLogged.username}
                                    </Typography>
                                    <Typography
                                        // sx={{
                                        // 	marginTop: (theme) => theme.spacing(3),
                                        // 	marginBottom: (theme) => theme.spacing(3),
                                        // }}
                                        display="inline"
                                    >
                                        ).
                                    </Typography>
                                    <Typography
                                        sx={{
                                            marginTop: (theme) => theme.spacing(3),
                                            marginBottom: (theme) => theme.spacing(3),
                                        }}
                                    >
                                        {"Ma prima di accedere a T&MA devi impostare una password."}
                                    </Typography>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <Grid container spacing={2} justifyContent="flex-start">
                                            <Grid size={{xs: 12}}>
                                                <TextField
                                                    id="new_password"
                                                    label="Password"
                                                    {...register("new_password")}
                                                    variant="outlined"
                                                    required
                                                    error={!!errors?.new_password}
                                                    helperText={errors?.new_password?.message}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    // disabled={confermato}
                                                    autoComplete={"off"}
                                                    sx={{width: "100%", bgcolor: "white"}}
                                                    fullWidth
                                                    type="password"
                                                />
                                            </Grid>
                                            <Grid size={{xs: 12}}>
                                                <TextField
                                                    id="new_password2"
                                                    label="Ripeti Password"
                                                    {...register("new_password2")}
                                                    variant="outlined"
                                                    required
                                                    error={!!errors?.new_password2}
                                                    helperText={errors?.new_password2?.message}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    // disabled={confermato}
                                                    autoComplete={"off"}
                                                    sx={{width: "100%", bgcolor: "white"}}
                                                    fullWidth
                                                    type="password"
                                                />
                                            </Grid>
                                        </Grid>
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                            sx={{margin: (theme) => theme.spacing(3, 0, 2)}}
                                        >
                                            Registra Password
                                        </Button>
                                        <Link href="/signin" variant="body2" underline="hover">
                                            Hai già un utente ed una password valida? Accedi
                                        </Link>
                                    </form>
                                </>
                            )}
                            {/* </Box> */}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    );
};

export default WellComePage;
