import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../../redux/store";
import { Lotto } from "../../pianificazione/pianificazioneSlice";

import { ConsuntivoVivo, saveConsuntivoVivo } from "./consuntiviVivoSlice";

import { Parametri } from "../../anagrafiche/anagraficheSlice";
import Grid from "@mui/material/Grid2";
import ConsuntivoVivoCard from "./ConsuntivoVivoCard";

interface ConsuntiviVivoLottoListProps {
  lotto: Lotto;
  parametri: Parametri;
}

const ConsuntiviVivoLottoList = ({
  lotto,
  parametri,
}: ConsuntiviVivoLottoListProps) => {
  const dispatch = useAppDispatch();
  const consuntiviVivo = useSelector<RootState, ConsuntivoVivo[]>((state) =>
    state.consuntiviVivoState.consuntiviVivo.results.filter(
      (consuntivo) => consuntivo.lotto == lotto.id
    )
  );

  const handlerSaveConsuntivoVivo = (consuntivoVivoToSave: ConsuntivoVivo) => {
    dispatch(saveConsuntivoVivo(consuntivoVivoToSave));
  };

  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChangeConsuntivoVivoPanel =
    (consuntivoVivo: ConsuntivoVivo) =>
    (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? "consuntivoVivo_" + consuntivoVivo.id : false);
    };

  return (
    <Grid sx={{ flexGrow: 1, pt: 1 }} container spacing={2}>
      {consuntiviVivo.map((consuntivoVivo) => (
        <Grid key={"consuntivoVivo_" + consuntivoVivo.id}  size={{ xs:12 }}>
          <ConsuntivoVivoCard
            consuntivoVivo={consuntivoVivo}
            saveConsuntivoVivo={handlerSaveConsuntivoVivo}
            parametri={parametri}
            expanded={expanded}
            handleChangeConsuntivoVivoPanel={handleChangeConsuntivoVivoPanel}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default ConsuntiviVivoLottoList;
