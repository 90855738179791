import React, { useEffect } from "react";

import { Lotto } from "../../pianificazione/pianificazioneSlice";
import {
	Allevamento,
	TipoContattoTypes,
} from "../../anagrafiche/anagraficheSlice";

import { percentualeSchedulazione } from "../../pianificazione/utility/lottoUtility";

import SchedeCaricoAllevamentoSelect from "./SchedeCaricoAllevamentoSelect";
import SchedaCaricoLottoPesoMedioForm from "./SchedaCaricoLottoPesoMedioForm";

import PhoneIcon from "@mui/icons-material/Phone";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import ForumIcon from "@mui/icons-material/Forum";
import LinearProgress from "@mui/material/LinearProgress";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import Grid from "@mui/material/Grid2";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import Button from "@mui/material/Button";
import ReplayIcon from "@mui/icons-material/Replay";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import InfoIcon from "@mui/icons-material/Info";

interface SchedeCaricoLottoCardIntestazioneProps {
	lotto: Lotto;
	allevamento?: Allevamento;
	mandaM4: (lotto: Lotto, contatto_id: number) => void;
	setEditaAllevamento: (editaAllevamento: boolean) => void;
}

const SchedeCaricoLottoCardIntestazione = ({
	lotto,
	allevamento,
	mandaM4,
	setEditaAllevamento,
}: SchedeCaricoLottoCardIntestazioneProps) => {
	const [scegliAllevamento, setScegliAllevamento] =
		React.useState<boolean>(false);

	useEffect(() => {
		setScegliAllevamento(!lotto.allevamento);
	}, [lotto.allevamento]);

	const [openContattiProduttore, setOpenContattiProduttore] =
		React.useState(false);

	const handleOpenContattiProduttore = () => {
		setOpenContattiProduttore(true);
	};

	const handleCloseContattiProduttore = () => {
		setOpenContattiProduttore(false);
	};

	const handlermandaM4 = (contatto_id: number) => {
		mandaM4(lotto, contatto_id);
	};

	return (
		<Box>
			<Grid container spacing={{ xs: 1, md: 2 }} sx={{ mb: 2 }}>
				<Grid size={{ xs:6, sm:6, md:3, lg:3 }}>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="flex-start"
						spacing={4}
						sx={{ mb: 1 }}
					>
						<Typography
							variant="h6"
							component="div"
							color="text.secondary"
							gutterBottom
						>
							{lotto.committente_desc}
						</Typography>
						<Typography
							variant="h6"
							component="div"
							color="text.secondary"
							gutterBottom
						>
							{lotto.numero_capi + " " + lotto.tipo_capo_desc}
						</Typography>
					</Stack>
				</Grid>
				<Grid size={{ xs:3, sm:3, md:2, lg:2 }}>
					<SchedaCaricoLottoPesoMedioForm lotto={lotto} />
				</Grid>
				<Grid size={{ xs:3, sm:3, md:2, lg:2 }}></Grid>
				<Grid size={{ xs:3, sm:3, md:5, lg:5 }}>
					<Stack
						direction="row"
						justifyContent="flex-end"
						alignItems="flex-start"
						spacing={0.5}
						sx={{ mb: 1 }}
					>
						{lotto.in_dubbio && (
							<Tooltip title={"lotto in dubbio:" + lotto.note_dubbio}>
								<IconButton
									sx={{ color: "red" }}
									aria-label="lotto in dubbio"
									component="span"
								>
									<ReportGmailerrorredIcon />
								</IconButton>
							</Tooltip>
						)}

						<Tooltip
							title={
								lotto.stato_lotto.descrizione +
								": " +
								lotto.stato_lotto.messaggio
							}
						>
							<IconButton
								sx={{
									color:
										lotto.stato_lotto.stato == 4
											? "red"
											: lotto.stato_lotto.stato == 3
											? "lightgreen"
											: lotto.stato_lotto.stato == 2
											? "rgba(91, 188, 247, 1)"
											: lotto.stato_lotto.stato == 1
											? "#ffdc10"
											: "#555",
								}}
								aria-label="stato lotto"
								component="span"
							>
								{lotto.stato_lotto.stato == 4 ? (
									<ReportGmailerrorredIcon />
								) : (
									<InfoIcon />
								)}
							</IconButton>
						</Tooltip>
						{(lotto.produttore_pack || lotto.allevamento_pack?.nome) && (
							<Button
								variant="outlined"
								endIcon={<ForumIcon />}
								onClick={handleOpenContattiProduttore}
								color={lotto.m4_inviato ? "secondary" : "primary"}
							>
								{lotto.produttore_pack
									? lotto.produttore_pack?.nome
									: lotto.allevamento_pack?.nome.substring(0, 15)}
							</Button>
						)}
					</Stack>
				</Grid>
				<Dialog
					open={openContattiProduttore}
					onClose={handleCloseContattiProduttore}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle id="alert-dialog-title">
						{"Contatti di " + lotto.produttore_pack?.nome}
					</DialogTitle>
					<DialogContent>
						<List sx={{ pt: 0 }}>
							{lotto.produttore_pack?.contatti.map((contatto) => (
								<ListItem key={contatto.id}>
									<ListItemAvatar>
										<Avatar>
											{contatto.tipo_contatto == TipoContattoTypes.M && (
												<AlternateEmailIcon />
											)}
											{contatto.tipo_contatto == TipoContattoTypes.T && (
												<PhoneIcon />
											)}
											{contatto.tipo_contatto == TipoContattoTypes.C && (
												<PhoneIphoneIcon />
											)}
										</Avatar>
									</ListItemAvatar>
									<ListItemText
										primary={contatto.riferimento}
										secondary={contatto.valore}
									/>
									{contatto.tipo_contatto == TipoContattoTypes.M && (
										<ListItemAvatar>
											<Button
												variant="contained"
												onClick={() => {
													handlermandaM4(contatto.id);
												}}
												disabled={!(lotto.stato_lotto.stato == 3)}
												sx={{ ml: 2 }}
												color={
													lotto.m4_inviati.includes(contatto.id)
														? "secondary"
														: "primary"
												}
											>
												{lotto.m4_inviati.includes(contatto.id)
													? "Reinvia M4"
													: "invia M4"}
												<AlternateEmailIcon />
											</Button>
										</ListItemAvatar>
									)}
								</ListItem>
							))}
							{!lotto.produttore_pack &&
								lotto.allevamento_pack?.contatti?.map((contatto) => (
									<ListItem key={contatto.id}>
										<ListItemAvatar>
											<Avatar>
												{contatto.tipo_contatto == TipoContattoTypes.M && (
													<AlternateEmailIcon />
												)}
												{contatto.tipo_contatto == TipoContattoTypes.T && (
													<PhoneIcon />
												)}
												{contatto.tipo_contatto == TipoContattoTypes.C && (
													<PhoneIphoneIcon />
												)}
											</Avatar>
										</ListItemAvatar>
										<ListItemText
											primary={contatto.riferimento}
											secondary={contatto.valore}
										/>
										{contatto.tipo_contatto == TipoContattoTypes.M && (
											<ListItemAvatar>
												<Button
													variant="contained"
													onClick={() => {
														handlermandaM4(contatto.id);
													}}
													disabled={!(lotto.stato_lotto.stato == 3)}
													sx={{ ml: 2 }}
												>
													invia M4
													<AlternateEmailIcon />
												</Button>
											</ListItemAvatar>
										)}
									</ListItem>
								))}
						</List>
					</DialogContent>
				</Dialog>
			</Grid>

			{scegliAllevamento ? (
				<SchedeCaricoAllevamentoSelect
					lotto={lotto}
					annulla={() => setScegliAllevamento(false)}
				/>
			) : (
				<Grid container spacing={{ xs: 1, md: 2 }}>
					<Grid size={{ xs:6, sm:6, md:4, lg:4 }}>
						<Box>
							<Typography variant="h5" component="div">
								{allevamento?.nome || "Allevamento ancora da definire"}
								<Tooltip title="Cambia allevamento di destinazione">
									<IconButton
										color="primary"
										aria-label="upload picture"
										component="span"
										onClick={() => {
											setScegliAllevamento(true);
										}}
									>
										<ReplayIcon />
									</IconButton>
								</Tooltip>

								<Tooltip
									title={"Cambia i dati di " + lotto.allevamento_pack?.nome}
								>
									<IconButton
										color="primary"
										aria-label="upload picture"
										component="span"
										onClick={() => {
											setEditaAllevamento(true);
										}}
									>
										<EditIcon />
									</IconButton>
								</Tooltip>
							</Typography>

							{allevamento && (
								<Typography variant="body2" gutterBottom sx={{ flexGrow: 1 }}>
									{allevamento.indirizzo +
										" - " +
										allevamento?.comune +
										" - " +
										allevamento?.provincia_desc}
								</Typography>
							)}
						</Box>
					</Grid>
					<Grid size={{ xs:6, sm:6, md:4, lg:4 }}>
						{allevamento && (
							<Box>
								<Stack direction="row" spacing={2}>
									<Typography
										variant="h6"
										component="span"
										gutterBottom
										sx={{
											flexGrow: 1,
											color: allevamento.distanza ? "inherit" : "red",
										}}
									>
										{allevamento.distanza
											? allevamento.distanza + " km "
											: "Inserire distanza"}
									</Typography>
									<Typography
										variant="h6"
										component="span"
										gutterBottom
										sx={{
											flexGrow: 1,
											color: allevamento.distanza_concordata ? "inherit" : "red",
										}}
									>
										{allevamento.distanza_concordata
											? `dist. concord.:${allevamento.distanza_concordata} km`
											: "Manca distanza concordata"}
									</Typography>
									<Typography
										variant="h6"
										component="span"
										gutterBottom
										sx={{ flexGrow: 1, color: "red" }}
									>
										{!allevamento.consente_rimorchio &&
											"Non consente il rimorchio"}
									</Typography>
								</Stack>
								{allevamento.indirizzo_pesa && (
									<Typography variant="body2" gutterBottom sx={{ flexGrow: 1 }}>
										{"Pesa: " + allevamento.indirizzo_pesa}
									</Typography>
								)}
							</Box>
						)}
					</Grid>
					<Grid size={{ xs:3, sm:3, md:3, lg:3 }}>
						{allevamento && (
							<Typography variant="body2" gutterBottom sx={{ flexGrow: 1 }}>
								{"Contatto: " + allevamento.note_di_contatto}
							</Typography>
						)}
					</Grid>
					<Grid size={{ xs:3, sm:3, md:1, lg:1 }}>
						<Box sx={{ width: "100%", textAlign: "right" }}>
							<Typography variant="body2" gutterBottom>
								Fori:
								{lotto.fori_schedulati + "/" + lotto.numero_fori}
							</Typography>
							<LinearProgress
								key={"LinearProgress_" + lotto.id}
								variant="determinate"
								value={percentualeSchedulazione(lotto)}
								// color="info"
							/>
						</Box>
					</Grid>
				</Grid>
			)}
		</Box>
	);
};

export default SchedeCaricoLottoCardIntestazione;
