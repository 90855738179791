import React from "react";
import {useEffect} from "react";
import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "../../../../redux/store";

import LottoCommittenteAllegatiList from "./LottoCommittenteAllegatiList";
import {Lotto} from "../../pianificazione/pianificazioneSlice";
import {
    DocumentoDiTrasporto,
    getDocumentoDiTrasporto,
    resetDocumentiDiTrasporto,
} from "../../bolle_fatture/bolleFattureSlice";
import {
    Allegato,
    fetchAllegati,
    fetchTipiDocumento,
    deleteAllegato,
    mandaDocumentiLotto,
} from "../../allegati/allegatiSlice";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import ScaricaCSVLavorazioniButton from "../../reportistica/rese/ScaricaCSVLavorazioniButton";

interface AllegatiLottoCommittenteBoxProps {
    lotto?: Lotto;
}

const listaIdSchedeCarico = (lotto: Lotto): number[] => {
    return lotto.schede_carico.map((a) => a.id || 0);
};

const AllegatiLottoCommittenteBox = ({
                                         lotto,
                                     }: AllegatiLottoCommittenteBoxProps) => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchTipiDocumento());
        lotto?.schede_carico &&
        lotto?.schede_carico.map(
            (schedaCarico) =>
                schedaCarico.id &&
                dispatch(fetchAllegati({schedaCarico_id: schedaCarico.id}))
        );
        dispatch(resetDocumentiDiTrasporto());
        lotto?.documenti_trasporto &&
        lotto?.documenti_trasporto.map((ddt) =>
            dispatch(getDocumentoDiTrasporto(ddt))
        );
    }, []);

    const allegati: Allegato[] | undefined = useSelector<
        RootState,
        Allegato[] | undefined
    >((state) =>
        state.allegati.allegati.results.filter(
            (allegato) =>
                typeof allegato.scheda_carico === "number" &&
                lotto &&
                listaIdSchedeCarico(lotto).includes(allegato.scheda_carico)
        )
    );

    const documentiDiTrasporto: DocumentoDiTrasporto[] | undefined = useSelector<
        RootState,
        DocumentoDiTrasporto[]
    >((state) =>
        state.bolleFattureState.documentiDiTrasporto.results.filter(
            (ddt) => ddt.destinatario == lotto?.committente
        )
    );

    return (
        <Card raised sx={{backgroundColor: "#49a6f2ee", color: "#fff"}}>
            <CardContent sx={{mb: 2}}>
                {allegati && (
                    <LottoCommittenteAllegatiList
                        lotto={lotto}
                        allegati={allegati}
                        documentiDiTrasporto={documentiDiTrasporto}
                    />
                )}

            </CardContent>
        </Card>
    );
};

export default AllegatiLottoCommittenteBox;
