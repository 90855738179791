import React, {useEffect} from "react";
import { useState } from "react";
import {useLocation, useParams} from "react-router-dom";

import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../../redux/store";
import { Link } from "react-router-dom";
import { Routes_path } from "../../../routerApp";

import {getLotto, Lotto} from "../../pianificazione/pianificazioneSlice";
import {getSchedaCarico, SchedaCarico} from "../schedeCaricoSlice";

import { Parametri } from "../../anagrafiche/anagraficheSlice";
import { PDFViewer, Page, Document, StyleSheet } from "@react-pdf/renderer";

import FogliOrdiniCaricoPdf from "./FogliOrdiniCaricoPdf";

import EtichetteGabbiePdf from "./EtichetteGabbiePdf";
import FogliModuliDisinfezionePdf from "./FogliModuliDisinfezionePdf";

import { DateTime } from 'luxon';

import Stack from "@mui/material/Stack";

import { Box } from "@mui/system";
import Button from "@mui/material/Button";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import SaveAltIcon from "@mui/icons-material/SaveAlt";

import stampaConNome from "../../../common/pdf/stampaConNome";
import {convertToDateTime} from "../../../common/dateUtils/convertToDateTime";

const nomeScheda = (schedaCarico: SchedaCarico, lotto: Lotto) => {
	const listaAutisti = schedaCarico.autisti_pack
		.map((autista) => autista.cognome)
		.join("_");
	const dataStr = schedaCarico;
	const nomescheda: string =
		convertToDateTime(lotto.data_lavorazione)?.setLocale("it").toFormat('yy_MM_dd_') + listaAutisti;
	return nomescheda;
};
interface documentoCaricoProps {
	lotto: Lotto;
	schedaCarico: SchedaCarico;
	parametri: Parametri;
}

const DocumentoCarico = ({
	lotto,
	schedaCarico,
	parametri,
}: documentoCaricoProps) => {
	return (
		<Document>
			<Page
				size="A4"
				style={styles.page}
				key={"scheda_carico_" + schedaCarico?.id}
			>
				<FogliOrdiniCaricoPdf lotto={lotto} schedaCarico={schedaCarico} />
				{/*<FogliModuliDisinfezionePdf*/}
				{/*	lotto={lotto}*/}
				{/*	schedaCarico={schedaCarico}*/}
				{/*	parametri={parametri}*/}
				{/*/>*/}
			</Page>
			<Page
				size="A4"
				style={styles.page}
				key={"scheda_carico_" + schedaCarico?.id}
			>
				<FogliOrdiniCaricoPdf lotto={lotto} schedaCarico={schedaCarico} />
				{/*<FogliModuliDisinfezionePdf*/}
				{/*	lotto={lotto}*/}
				{/*	schedaCarico={schedaCarico}*/}
				{/*	parametri={parametri}*/}
				{/*/>*/}
			</Page>
			<Page
				size="A4"
				style={styles.page}
				key={"scheda_carico_" + schedaCarico?.id}
			>
				<FogliModuliDisinfezionePdf
					lotto={lotto}
					schedaCarico={schedaCarico}
					parametri={parametri}
				/>
			</Page>
			<Page
				size="A4"
				style={styles.page}
				// orientation="landscape"
				key={"etich_scheda_carico_" + schedaCarico.id}
			>
				<EtichetteGabbiePdf lotto={lotto} schedaCarico={schedaCarico} />
			</Page>
			<Page
				size="A4"
				style={styles.page}
				// orientation="landscape"
				key={"etich_scheda_carico_" + schedaCarico.id}
			>
				<EtichetteGabbiePdf lotto={lotto} schedaCarico={schedaCarico} />
			</Page>
			<Page
				size="A4"
				style={styles.page}
				// orientation="landscape"
				key={"etich_scheda_carico_" + schedaCarico.id}
			>
				<EtichetteGabbiePdf lotto={lotto} schedaCarico={schedaCarico} />
			</Page>
		</Document>
	);
};
// Create styles
const styles = StyleSheet.create({
	page: {
		backgroundColor: "#fff",
		paddingTop: 20,
	},
	section: {
		marginTop: 10,
		marginLeft: 10,
		marginRight: 10,
		padding: 10,
	},
	sectionIntestazione: {
		marginTop: 5,
		marginLeft: 10,
		marginRight: 10,
		padding: 10,
		paddingTop: 10,
		paddingBottom: 7,
		backgroundColor: "#777777",
		color: "#ffffff",
	},
	text: {
		fontSize: 12,
		fontStyle: "normal",
	},
});

const OrdiniCaricoPage = () => {
	
	const dispatch = useAppDispatch();

	const {scheda_carico_id} = useParams();

	const [schedaCaricoId, setSchedaCaricoId] = useState<number>(
		parseInt(scheda_carico_id || "")
	);


	useEffect(() => {
		console.log("schedaCaricoId", schedaCaricoId);
		if (schedaCaricoId) {
			dispatch(getSchedaCarico(schedaCaricoId));
		}
	}, [schedaCaricoId]);

	const schedaCarico: SchedaCarico | undefined = useSelector<RootState, SchedaCarico | undefined>(
		(state) =>
			state.schedeCaricoState.schedeCarico.results.find(
				(schedaCarico) => schedaCarico.id == schedaCaricoId
			)
	);

	useEffect(() => {
		console.log("schedaCarico", schedaCarico);
		if (schedaCarico?.lotto) {
			dispatch(getLotto(schedaCarico?.lotto));
		}
	}, [schedaCarico?.lotto]);

	const lotto: Lotto | undefined = useSelector<RootState, Lotto | undefined>(
		(state) =>
			state.pianificazione.lotti.results.find(
				(lotto) => lotto.id == schedaCarico?.lotto
			)
	);

	// const parametri = useSelector<RootState, Parametri | undefined>((state) =>
	// 	state.anagrafiche.storicoParametri.results.find(
	// 		(parametri) =>
	// 			new Date(parametri.data_validta_da) <
	// 			new Date(lotto?.data_lavorazione || "")
	// 	)
	// );

	const parametri = useSelector<RootState, Parametri | undefined>((state) =>
		state.anagrafiche.storicoParametri.results.find(
			(p) =>
				new Date(p.data_validta_da) <=
					new Date(
						new Date(lotto?.data_lavorazione || "").getTime() +
							6 * 60 * 60 * 1000
					) &&
				new Date(
					new Date(lotto?.data_lavorazione || "").getTime() - 6 * 60 * 60 * 1000
				) <= new Date(p.data_validta_a)
		)
	);

	return (
		<Box>
			<Box sx={{ backgroundColor: "#999", height: "80vh" }}>
				{schedaCarico && lotto && parametri && (
					<PDFViewer width={"100%"} height={"100%"}>
						<DocumentoCarico
							lotto={lotto}
							schedaCarico={schedaCarico}
							parametri={parametri}
						/>
					</PDFViewer>
				)}
			</Box>
			<Stack
				direction="row"
				justifyContent="flex-start"
				alignItems="flex-start"
				mt={2}
				spacing={1}
			>
				<Button
					variant="contained"
					component={Link}
					to={{
						pathname: Routes_path.SCHEDE_CARICO,
					}}
					endIcon={<KeyboardReturnIcon />}
					// color="success"
				>
					Torna alle schede carico
				</Button>
				<Button
					variant="contained"
					endIcon={<SaveAltIcon />}
					onClick={() => {
						schedaCarico &&
							lotto &&
							parametri &&
							stampaConNome({
								Documento: (
									<DocumentoCarico
										lotto={lotto}
										schedaCarico={schedaCarico}
										parametri={parametri}
									/>
								),
								nome: nomeScheda(schedaCarico, lotto),
							});
					}}
					// color="success"
				>
					Salva
				</Button>
			</Stack>
		</Box>
	);
};

export default OrdiniCaricoPage;
