import React from "react";
import { GraficoCommittenteTipiCapoMese } from "../../reportistica/grafici/graficiKpiSlice";
import { DateTime } from 'luxon';

import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer,
} from "recharts";

import { stringToColour } from "../../../common/stringToColour";
import {convertToDateTime} from "../../../common/dateUtils/convertToDateTime";
const generaData = (
	graficoCommittenteTipiCapoMese: GraficoCommittenteTipiCapoMese[]
) => {
	const data: {}[] = [];

	const mesi: string[] = [];
	graficoCommittenteTipiCapoMese.map((mese_tipo_kg) => {
		if (!mesi.includes(mese_tipo_kg.mese)) {
			mesi.push(mese_tipo_kg.mese);
		}
	});

	const tipiCapoBar: string[] = [];

	mesi.map((mese) => {
		var dataRecord: any = {};
		dataRecord["mese"] = convertToDateTime(mese)?.setLocale("it").toFormat('LLL yy');
		graficoCommittenteTipiCapoMese
			.filter((mese_tipo_kg) => mese_tipo_kg.mese == mese)
			.map((tipo_capo) => {
				dataRecord[tipo_capo.tipo_capo] = tipo_capo.kg;
				if (tipo_capo.kg > 0) {
					if (!tipiCapoBar.includes(tipo_capo.tipo_capo)) {
						tipiCapoBar.push(tipo_capo.tipo_capo);
					}
				}
			});
		data.push(dataRecord);
	});

	return { data: data, tipiCapoBar: tipiCapoBar };
};

interface CommittenteTipiCapoMeseGraficoProps {
	graficoCommittenteTipiCapoMese: GraficoCommittenteTipiCapoMese[];
}
const CommittenteTipiCapoMeseGrafico = ({
	graficoCommittenteTipiCapoMese,
}: CommittenteTipiCapoMeseGraficoProps) => {
	return (
		<ResponsiveContainer>
			<BarChart
				// width={400}
				// height={200}
				data={generaData(graficoCommittenteTipiCapoMese).data}
				margin={{
					top: 8,
					right: 5,
					left: 5,
					bottom: 5,
				}}
			>
				<CartesianGrid strokeDasharray="3 3" />
				<XAxis dataKey="mese" fontSize={12} />
				<YAxis
					type="number"
					tickFormatter={(value) => Intl.NumberFormat("it-IT").format(value)}
					fontSize={13}
				/>
				<Tooltip
					formatter={(value) => {
						if (typeof value == "number") {
							return (
								Intl.NumberFormat("it-IT").format(value).toString() + " Kg"
							);
						} else {
							return value;
						}
					}}
					wrapperStyle={{ fontSize: 13 }}
				/>
				<Legend wrapperStyle={{ fontSize: 13 }} />
				{generaData(graficoCommittenteTipiCapoMese).tipiCapoBar.map((tipo) => {
					return (
						<Bar
							key={"key_" + tipo}
							dataKey={tipo}
							fill={stringToColour(tipo)}
						/>
					);
				})}
			</BarChart>
		</ResponsiveContainer>
	);
};
export default CommittenteTipiCapoMeseGrafico;
