import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../../redux/store";
import { Committente, Committenti } from "../../anagrafiche/anagraficheSlice";
import { SelectCommittente } from "../dashboard/DashboardCommittentiToolbarOptions";
import NavigazioneGiorniToolbar from "../../../common/dateUtils/NavigazioneGiorniToolbar";
import {ErrorStatusTypes,
} from "../../../common/errorsDeclarations";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import { Utente } from "../../../authentication/authenticationSlice";
import { PermissionTypes } from "../../../authentication/permissionsGroups";
import AllevamentiCommittenteBox from "./AllevamentiCommittenteBox";

import { Box } from "@mui/system";


const AllevamentiCommittentePage = () => {

	const utente = useSelector<RootState, Utente>(
		(state) => state.authentication.utenteLogged
	);
	const committenti: Committenti = useSelector<RootState, Committenti>(
		(state) => state.anagrafiche.committenti
	);

	const [committenteCorrente, setCommittenteCorrente] =
		React.useState<Committente | null>(null);

	useEffect(() => {
		if (utente.gruppi?.includes(PermissionTypes.PIANIFICATORI)) {
			!committenteCorrente && setCommittenteCorrente(committenti.results[0]);
		} else {
			!!utente.committente &&
				setCommittenteCorrente(
					committenti.results.find(
						(committente) => committente.id == utente.committente
					) || null
				);
		}
	}, [committenti.results]);


	const [refresh, setRefresh] = React.useState<boolean>(false);

	useEffect(() => {
		committenteCorrente?.id &&
			// dispatch(
			// 	fetchLottiCommittente({ committente_id: committenteCorrente?.id })
			// );
			setRefresh(false);
	}, [committenteCorrente, refresh]);

	return (
		<Box>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={committenti.errorsStack.status == ErrorStatusTypes.PENDING}
			>
				<CircularProgress color="inherit" />
			</Backdrop>

			<NavigazioneGiorniToolbar
				titolo={"Lotti " + committenteCorrente?.nome}
				dataPresente={false}
				setData={() => {}}
				refresh={() => {
					setRefresh(true);
				}}
				Toolbar={() => {
					return (
						<Stack
							direction="row"
							justifyContent="flex-end"
							alignItems="center"
							sx={{ ml: 2 }}
							spacing={1}
						>
							{utente.gruppi?.includes(PermissionTypes.PIANIFICATORI) &&
								committenti.results && (
									<SelectCommittente
										committenteCorrente={committenteCorrente}
										committenti={committenti.results}
										setCommittenteCorrente={setCommittenteCorrente}
									/>
								)}
						</Stack>
					);
				}}
			/>
			{committenteCorrente?.id && (
				<AllevamentiCommittenteBox
					key={"allevamentiCommittente_" + refresh}
					committenteCorrente={committenteCorrente}
				/>
			)}
		</Box>
	);
};

export default AllevamentiCommittentePage;
