import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../../redux/store";
import {  useLocation } from "react-router-dom";
import {
	LottiState,
	fetchLotti,
	resetLotti,
} from "../../pianificazione/pianificazioneSlice";
import {
	ConsuntiviVivoState,
	fetchConsuntiviVivo,
} from "./consuntiviVivoSlice";
import { Parametri } from "../../anagrafiche/anagraficheSlice";
import NavigazioneGiorniToolbar from "../../../common/dateUtils/NavigazioneGiorniToolbar";
import ConsuntiviVivoLottoCard from "./ConsuntiviVivoLottoCard";
import { DateTime } from 'luxon';
import {
	RecuperaDataCorrente,
	FissaDataCorrente,
} from "../../../common/dateUtils/RecuperaDataCorrente";
import {
	ErrorStatusTypes,
	ErrorsStack,
} from "../../../common/errorsDeclarations";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid2";
import { Box } from "@mui/system";
import {convertToDateTime} from "../../../common/dateUtils/convertToDateTime";


const ConsuntiviVivoPage = () => {
	
	const dispatch = useAppDispatch();
	const lotti: LottiState = useSelector<RootState, LottiState>(
		(state) => state.pianificazione.lotti
	);

	const consuntiviVivo: ConsuntiviVivoState = useSelector<
		RootState,
		ConsuntiviVivoState
	>((state) => state.consuntiviVivoState.consuntiviVivo);


	const anagraficheErrorsStack: ErrorsStack = useSelector<
		RootState,
		ErrorsStack
	>((state) => state.anagrafiche.errorsStack);

	const location = useLocation();
	const recupera_data_corrente = RecuperaDataCorrente(location);


	const [data, setData] = React.useState<DateTime | null>(
		recupera_data_corrente
	);

	const parametri = useSelector<RootState, Parametri | undefined>((state) =>
		state.anagrafiche.storicoParametri.results.find((p) => {
			const startDate = convertToDateTime(p.data_validta_da);
			const endDate = convertToDateTime(p.data_validta_a);
			const adjustedData = convertToDateTime(data);

			return (
				startDate &&
				endDate &&
				adjustedData &&
				startDate <= adjustedData.plus({ hours: 6 }) &&
				adjustedData.minus({ hours: 6 }) <= endDate
			);
		})
	);

	const [refresh, setRefresh] = React.useState<boolean>(false);

	useEffect(() => {
		data && FissaDataCorrente(data);

		// localStorage.setItem(
		// 	DATA_CONSUNTIVAZIONE_CORRENTE,
		// 	convertToDateTime(data)?.setLocale("it").toFormat("yyyy-MM-dd")
		// );
		const data_da = data;
		const data_a = data;
		dispatch(resetLotti());
		dispatch(fetchLotti({ data_da: data_da?.toJSDate(), data_a: data_a?.toJSDate() }));
		dispatch(fetchConsuntiviVivo({ data_da: data_da?.toJSDate(), data_a: data_a?.toJSDate() }));
		// dispatch(fetchschedeCarico({ data_da, data_a }));
		// dispatch(fetchCamions(data));
		// dispatch(fetchAutisti(data));
		setRefresh(false);
	}, [data, refresh]);

	return (
		<Box>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={
					lotti.errorsStack.status == ErrorStatusTypes.PENDING ||
					// schedeCarico.errorsStack.status == ErrorStatusTypes.PENDING ||
					consuntiviVivo.errorsStack.status == ErrorStatusTypes.PENDING ||
					anagraficheErrorsStack.status == ErrorStatusTypes.PENDING
				}
			>
				<CircularProgress color="inherit" />
			</Backdrop>

			<NavigazioneGiorniToolbar
				titolo={"Consuntivi Vivo"}
				setData={setData}
				data={data}
				refresh={() => {
					setRefresh(true);
				}}
			/>
			<Grid container spacing={2}>
				<Grid size={{  xs:12, sm:12, md:12 }}>
					{parametri &&
						lotti.results.map((lotto) => (
							<ConsuntiviVivoLottoCard
								key={"consuntivi_lotto_" + lotto.id}
								lotto={lotto}
								parametri={parametri}
							/>
						))}
				</Grid>
			</Grid>
		</Box>
	);
};

export default ConsuntiviVivoPage;
