import React from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, Link } from "react-router-dom";

import { RootState, useAppDispatch } from "../../../../redux/store";
import { getColore } from "../../pianificazione/utility/lottoUtility";
import { Routes_path } from "../../../routerApp";

import {
	LottiState,
	fetchLotti,
	resetLotti,
	Lotto,
} from "../../pianificazione/pianificazioneSlice";
import { Lavorazioni, fetchLavorazioni } from "../lavorazioniSlice";
import { fetchArticoli } from "../../anagrafiche/anagraficheSlice";
import NavigazioneGiorniToolbar from "../../../common/dateUtils/NavigazioneGiorniToolbar";
// import { DATA_PIANIFICAZIONE_LAVORAZIONI_CORRENTE } from "../../../../api/apiUtils";
import { DateTime } from 'luxon';
import LavorazioniLottoManage from "./LavorazioniLottoManage";
import { ErrorStatusTypes } from "../../../common/errorsDeclarations";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import {
	RecuperaDataCorrente,
	FissaDataCorrente,
} from "../../../common/dateUtils/RecuperaDataCorrente";
import { utenteAutorizzato } from "../../../authentication/UtenteAutorizzato";
import { UtenteLogged } from "../../../authentication/authenticationSlice";
import {
	PROGRAMMA_INCASSETTAMENTO_PERMISSIONS,
	STAMPE_MACELLO_PERMISSIONS,
} from "../../../authentication/permissionsGroups";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { Button } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { theme } from "../../../../theme";
import {convertToDateTime} from "../../../common/dateUtils/convertToDateTime";

const LavorazioniPianificateGiornoPage = () => {
	
	const dispatch = useAppDispatch();
	const lotti: LottiState = useSelector<RootState, LottiState>(
		(state) => state.pianificazione.lotti
	);


	const location = useLocation();
	const recupera_data_corrente = RecuperaDataCorrente(location);
	console.log('recupera_data_corrente=====', recupera_data_corrente);
	const utente = useSelector<RootState, UtenteLogged>(
		(state) => state.authentication.utenteLogged
	);

	const [data, setData] = useState<DateTime | null>(recupera_data_corrente);
	const [refresh, setRefresh] = useState<boolean>(false);

	// useEffect(() => {
	// 	if (lotti.results.length == 1) {
	// 		const unicoLotto = lotti.results[0];
	// 		dispatch(fetchLavorazioni(unicoLotto.id || 0));
	// 		setExpanded("lotto_" + unicoLotto.id);
	// 	}
	// }, [lotti]);

	useEffect(() => {
		data && FissaDataCorrente(data);

		// localStorage.setItem(
		// 	DATA_PIANIFICAZIONE_LAVORAZIONI_CORRENTE,
		// 	convertToDateTime(data)?.setLocale("it").toFormat("yyyy-MM-dd")
		// );
		const data_da = data?.toJSDate();
		const data_a = data?.toJSDate();
		dispatch(resetLotti());
		dispatch(fetchLotti({ data_da, data_a }));
		dispatch(fetchArticoli());

		setRefresh(false);
	}, [data, refresh]);

	const lavorazioni: Lavorazioni = useSelector<RootState, Lavorazioni>(
		(state) => state.lavorazioniState.lavorazioni
	);

	const [expanded, setExpanded] = useState<string | false>(false);
	const handleChangeLottoPanel =
		(lotto: Lotto) => (event: React.SyntheticEvent, isExpanded: boolean) => {
			if (lotto.id) {
				dispatch(fetchLavorazioni(lotto.id));
			}
			setExpanded(isExpanded ? "lotto_" + lotto.id : false);
		};

	return (
		<>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={
					lotti.errorsStack.status == ErrorStatusTypes.PENDING ||
					lavorazioni.errorsStack.status == ErrorStatusTypes.PENDING
				}
			>
				<CircularProgress color="inherit" />
			</Backdrop>

			<NavigazioneGiorniToolbar
				titolo={"Piano Lavorazioni"}
				setData={setData}
				data={data}
				refresh={() => {
					setRefresh(true);
				}}
				Toolbar={() => {
					return (
						<Stack
							direction="row"
							spacing={2}
							alignItems="center"
							justifyContent="flex-end"
							sx={{ height: "100%" }}
						>
							{utenteAutorizzato(utente, STAMPE_MACELLO_PERMISSIONS) && (
								<Button
									variant="contained"
									component={Link}
									to={{
										pathname: Routes_path.SCHEDA_MACELLAZIONE,
									}}
									state={{
										giornoSettimana: data,
									}}
									startIcon={<PrintIcon />}
								>
									Scheda Macellazione
								</Button>
							)}
							{utenteAutorizzato(
								utente,
								PROGRAMMA_INCASSETTAMENTO_PERMISSIONS
							) && (
								<Button
									variant="contained"
									component={Link}
									to={{
										pathname: Routes_path.PROGRAMMA_INCASSETTAMENTO + convertToDateTime(data)?.toISODate(),
									}}
									state={{
										giornoSettimana: data,
									}}
									startIcon={<PrintIcon />}
								>
									Programma Incassettamento
								</Button>
							)}
						</Stack>
					);
				}}
			/>

			<Box
				sx={{
					p: 1,
					// elevation: 0,
				}}
			>
				{lotti.results.map((lotto) => (
					<Accordion
						key={"lotto_panel" + lotto.id}
						expanded={expanded === "lotto_" + lotto.id}
						onChange={handleChangeLottoPanel(lotto)}
					>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="lavorazione_accordion"
							id="lavorazione_accordion_header"
						>
							<Grid container spacing={1} sx={{width:"100%"}}>
								<Grid size={{ xs:12, sm:6, md:3 }}>
									<CardHeader
										sx={{ p: 0 }}
										avatar={
											<Box alignItems="center">
												<IconButton
												// sx={{ ml: -1 }}
												// aria-label="delete"
												// onClick={() => setDettagliOn(!dettagliOn)}
												>
													<Avatar
														sx={{
															// width: "1.5rem",
															// height: "1.5rem",
															bgcolor: theme.palette.primary.main,
															color: "white",
															// pl: 1,
														}}
														aria-label="recipe"
													>
														{lotto.ordinamento_lotto}
													</Avatar>
												</IconButton>
												<Typography variant="caption" display="block">
													ord.macel.
												</Typography>
											</Box>
										}
										title={
											<Stack
												direction="row"
												justifyContent="space-between"
												alignItems="center"
												mr={2}
												spacing={2}
											>
												<Box>
													<Typography variant="h6">
														{lotto.committente_desc}
													</Typography>
													<Typography variant="body1">
														{Intl.NumberFormat("it-IT").format(
															lotto.numero_capi || 0
														) +
															" " +
															lotto.tipo_capo_desc +
															" " +
															(lotto.colore ? " " + getColore(lotto) : "")}
													</Typography>
												</Box>
											</Stack>
										}
									/>
								</Grid>

								<Grid size={{ xs:12, sm:6, md:3 }}>
									<Typography variant="caption" display="block">
										cod. rintr.
									</Typography>
									<Typography variant="h6">
										{lotto.codice_tracciabilita}
									</Typography>
								</Grid>
								<Grid size={{ xs:12, sm:6, md:3 }}>
									<Typography variant="h6">
										<em>{lotto.produttore_pack?.nome}</em>
									</Typography>
									<Typography variant="body1" display="block">
										{lotto.allevamento_pack?.nome +
											" (" +
											lotto.allevamento_pack?.comune +
											") - " +
											lotto.allevamento_pack?.codice_asl}
									</Typography>
								</Grid>
								<Grid size={{ xs:12, sm:6, md:3 }}>
									{lotto.aggiustamento_resa_bst ? (
										<Box sx={{ mr: 2 }}>
											<Typography variant="h6" align="right">
												{"TZ eviscer.: " +
													(lotto.num_tz_eviscerati_pianificati_lotto != 0
														? Intl.NumberFormat("it-IT").format(
																lotto.num_tz_eviscerati_pianificati_lotto || 0
														  )
														: "___")}
											</Typography>
											<Typography variant="h6" align="right">
												{"TZ: " +
													(lotto.num_tz_pianificati_lotto != 0
														? Intl.NumberFormat("it-IT").format(
																lotto.num_tz_pianificati_lotto || 0
														  )
														: "___")}
											</Typography>
										</Box>
									) : (
										<Box sx={{ mr: 2 }}>
											<Typography variant="h6" align="right">
												{"BST: " +
													(lotto.num_busti_pianificati_lotto != 0
														? Intl.NumberFormat("it-IT").format(
																lotto.num_busti_pianificati_lotto || 0
														  )
														: "___")}
											</Typography>
										</Box>
									)}
								</Grid>
							</Grid>
						</AccordionSummary>
						<AccordionDetails
							sx={{
								py: 0,
								"&:last-child": { pb: 1 },
							}}
						>
							{expanded === "lotto_" + lotto.id && (
								<LavorazioniLottoManage lotto={lotto} />
							)}
						</AccordionDetails>
					</Accordion>
				))}
			</Box>
		</>
	);
};

export default LavorazioniPianificateGiornoPage;
