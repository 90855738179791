import React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../../../redux/store";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";

import {
	DocumentoDiTrasporto,
	DestinazioneTrasporto,
	VettoreTrasporto,
	getDocumentoDiTrasporto,
	mandaDdtEmail,
} from "components/main/bolle_fatture/bolleFattureSlice";
import {
	Committente,
	TipoContattoTypes,
	Contatto, RegolaLottoTypes, Allevamento,
} from "components/main/anagrafiche/anagraficheSlice";
import { UtenteLogged } from "components/authentication/authenticationSlice";
import { PermissionTypes } from "components/authentication/permissionsGroups";

import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import Stack from "@mui/material/Stack";
import { PDFViewer, pdf } from "@react-pdf/renderer";

import { DateTime } from 'luxon';
import SendIcon from "@mui/icons-material/Send";

import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import PhoneIcon from "@mui/icons-material/Phone";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import DocumentoDdtContoTerzi from "./DocumentoDdtContoTerzi";
import { Box } from "@mui/system";
import {getPackingList, PackingList} from "../../pesaturaPalletSlice";
import {UtenteEsterno} from "../../../ospiti/ospitiSlice";

const DdtContoTerziStampaPage = () => {
	
	const dispatch = useAppDispatch();

	const { packing_list_id } = useParams();
	const packingListId: number = parseInt(
		packing_list_id || ""
	);

	const utente = useSelector<RootState, UtenteLogged>(
		(state) => state.authentication.utenteLogged
	);

	useEffect(() => {
		packingListId && dispatch(getPackingList({packing_list_id: packingListId}));
	}, [packingListId]);


	const packingList = useSelector<RootState, PackingList | undefined>(
		(state) => state.pesaturaPalletState.packingListState.results.find(
			(packingList) => packingList.id == packingListId
		)
	);

	const destinatario:Committente = {
		id: 0,
		nome: "",
		ragione_sociale: "EM Poultry srl",
		codice_fiscale: "",
		indirizzo: "via Procaccini 22",
		cap: "",
		piva: "IT08717190964",
		comune: "20154 Milano (MI)",
		provincia: "",
		regola_lotto: RegolaLottoTypes.A,
		gestisce_colore: false,
		allevamenti: [],
		causale_trasporto_default: "",
		contatti: [],
		utenti_esterni: []
	}
	
	const destinazione: DestinazioneTrasporto = {
		id: 0,
		destinatario: 0,
		ragione_sociale: "Geti Wilba GMBH",
		indirizzo: "Hansestraße 2, D",
		comune: "27432 Bremervörde, Germany",
		provincia: ""
	}
	//
	// const vettore = useSelector<RootState, VettoreTrasporto | undefined>(
	// 	(state) =>
	// 		state.bolleFattureState.vettoriTrasporto.results.find(
	// 			(vettore) => vettore.id == packingList?.vettore
	// 		)
	// );

	useEffect(() => {
		dispatch(getDocumentoDiTrasporto(packingListId));
	}, [getDocumentoDiTrasporto]);

	const [openContattiCommittente, setOpenContattiCommittente] = useState(false);
	const handleOpenContattiCommittente = () => {
		setOpenContattiCommittente(true);
	};
	const handleCloseContattiCommittente = () => {
		setOpenContattiCommittente(false);
	};

	return (
		<Box>
			<Box sx={{ backgroundColor: "#999", height: "80vh" }}>
				{packingList && destinatario && (
					<PDFViewer width={"100%"} height={"100%"}>
						<DocumentoDdtContoTerzi
							packingList={packingList}
							destinatario={destinatario}
							destinazione={destinazione}
							// vettore={vettore}
						/>
					</PDFViewer>
				)}
			</Box>
			{/*{(utente.is_staff ||*/}
			{/*	(utente?.gruppi &&*/}
			{/*		utente.gruppi.indexOf(PermissionTypes.PIANIFICATORI) > -1) ||*/}
			{/*	(utente?.gruppi &&*/}
			{/*		utente.gruppi.indexOf(PermissionTypes.OPERATORI_LOGISTICA) > -1)) && (*/}
			{/*	<Stack direction="row" spacing={2} sx={{ mt: 2 }}>*/}
			{/*		<Button*/}
			{/*			variant="contained"*/}
			{/*			component={Link}*/}
			{/*			to={{*/}
			{/*				pathname: "/documento_di_trasporto",*/}
			{/*			}}*/}
			{/*			state={{*/}
			{/*				packing_list_id: packingList?.id,*/}
			{/*			}}*/}
			{/*			endIcon={<KeyboardReturnIcon />}*/}
			{/*		>*/}
			{/*			Torna al DDT*/}
			{/*		</Button>*/}
			{/*	</Stack>*/}
			{/*)}*/}

		</Box>
	);
};

export default DdtContoTerziStampaPage;
