import React from "react";
import {Text, View, StyleSheet, Image} from "@react-pdf/renderer";
import {useState} from "react";

import {
    ColoreTypes,
    Lotto,
} from "components/main/pianificazione/pianificazioneSlice";
import {getColore} from "components/main/pianificazione/utility/lottoUtility";
import {Lavorazione} from "components/main/lavorazioni/lavorazioniSlice";
import { DateTime } from 'luxon';
import em_poultry from "../../../../../../static/images/common/em_poultry.png";
import {PackingList, Pallet} from "../../pesaturaPalletSlice";


interface RafoodLetteraVetturaPdfProps {
    // colore: string
    // packingList: PackingList;
    // pallets: Pallet[];
}

const RafoodLetteraVetturaPdf = ({}: RafoodLetteraVetturaPdfProps) => {

    return (
        <>
            <View style={{
                flexDirection: "column",
                justifyContent: "space-between",
                borderStyle: "solid",
                borderColor: "#000",
                borderWidth: 1,
                padding: 5
            }}>
                <View key={'rettangolo_colorato_numero'} style={{flexDirection: "row"}}>
                    <View
                        style={{
                            flexDirection: "column",
                            marginLeft: 10,
                            justifyContent: 'flex-start',
                        }}
                    >
                        <Text style={{fontSize: 10,}}>
                            LETTERA DI VETTURA INTERNAZIONALE
                        </Text>
                        <Text style={{fontSize: 8,}}>INTERNATIONAL CONSIGNMENT NOTE</Text>
                        <Text style={{fontSize: 8,}}>INTERNATIONAL CONSIGNMENT NOTE</Text>
                        <Text style={{fontSize: 8,}}>FRACHTBRIEF - TRANSPORTDOKUMENT</Text>
                    </View>
                </View>
                <View style={{flexDirection: "row", marginTop: 50}}>
                    <Text style={{fontSize: 6, width: '33%', padding:3,textAlign: 'justify'}}>
                        Questo trasporto è sottomes-
                        so, nonostante qualunque clausola contraria, alla conven-
                        zione relativa al contratto di trasporto internazionale di
                        merci su strada (CMR).
                    </Text>
                    <Text style={{fontSize: 6, width: '33%', padding:3,textAlign: 'justify'}}>
                        This carriage is notwithstanding any
                        clause to the contrary, subject to
                        the Convention on the contract for
                        the international carriage of goods
                        by road (CMR).
                    </Text>
                    <Text style={{fontSize: 6, width: '33%', padding:3, textAlign: 'justify'}}>
                        Diese Beförderung unterliegt trotz
                        einer gegenteiligen Abmachung den Bestimmungen des Übereinkommens über den Beförderungsvertrag
                        im internationalen
                        Straßengüterverkehr (CMR).
                    </Text>
                </View>


            </View>

        </>
    );
}

export default RafoodLetteraVetturaPdf;
