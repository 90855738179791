import React from "react";
import {Text, View, StyleSheet, Image} from "@react-pdf/renderer";
import {useState} from "react";

import {
    ColoreTypes,
    Lotto,
} from "components/main/pianificazione/pianificazioneSlice";
import {getColore} from "components/main/pianificazione/utility/lottoUtility";
import {Lavorazione} from "components/main/lavorazioni/lavorazioniSlice";
import { DateTime } from 'luxon';
import em_poultry from "../../../../../../static/images/common/em_poultry.png";
import {PackingList, Pallet} from "../../pesaturaPalletSlice";


interface Rafood13PdfProps {
    // colore: string
    // packingList: PackingList;
    // pallets: Pallet[];
}

const Rafood13Pdf = ({}: Rafood13PdfProps) => {

    return (
        <>
            <View style={{
                flexDirection: "column",
                // justifyContent: "space-between",
                borderStyle: "solid",
                borderColor: "#000",
                borderWidth: 1,
                padding:2,
                height: 97.5
            }}>
                <View key={'rettangolo_colorato_numero'} style={{flexDirection: "row"}}>
                    <Text style={{fontSize: 9,}}>13</Text>
                    <View
                        style={{flexDirection: "column", marginLeft: 2,}}
                    >
                        <Text style={{fontSize: 5,}}>
                            Istruzioni del mittente
                        </Text>
                        <Text style={{fontSize: 5,}}>
                            Sender's instructions
                        </Text>
                        <Text style={{fontSize: 5,}}>
                            Anweisungen des Adsenders
                        </Text>
                    </View>
                </View>
                <Text style={{fontSize: 12,marginTop:10}}>Templerature Transport 0°</Text>

            </View>

        </>
    );
}

export default Rafood13Pdf;
