import React, { useEffect } from "react";

import { SaldoPalletCommittente } from "./palletsSlice";

import { Box } from "@mui/system";

import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

import Grid from "@mui/material/Grid2";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

interface ReportPalletCommittenteListProps {
	saldoPalletCommittente?: SaldoPalletCommittente;
}

const ReportPalletCommittenteList = ({
	saldoPalletCommittente,
}: ReportPalletCommittenteListProps) => {
	return (
		<Box sx={{ m: 1, bgcolor: "background.paper" }}>
			<Stack
				direction="row"
				justifyContent="space-around"
				alignItems="center"
				spacing={2}
			>
				<Typography variant="h6" gutterBottom align={"center"} sx={{ pt: 1 }}>
					{saldoPalletCommittente?.committente} - ultimi 20 Ddt
				</Typography>
				<Typography
					variant="h6"
					gutterBottom
					align={"center"}
					sx={{
						pt: 1,
						color:
							(saldoPalletCommittente?.saldo_pallet || 1) < 0
								? "#FF0000"
								: "inherit",
					}}
				>
					{"saldo: "}
					{Intl.NumberFormat("it-IT", {
						minimumFractionDigits: 2,
						maximumFractionDigits: 2,
					}).format(saldoPalletCommittente?.saldo_pallet || 0)}
				</Typography>
			</Stack>
			<List sx={{ mt: 2 }}>
				<ListItemText
					key={"Lista_saldo_pallet_committente"}
					sx={{ backgroundColor: "#1b75b1", color: "#ddd", mb: 1 }}
				>
					<Grid container spacing={{ xs: 0.5, md: 1, lg: 2 }} sx={{ px: 2 , width:"100%"}}>
						<Grid size={{ xs:3, sm:3, md:3, lg:3 }}>
							<Typography variant="subtitle1" component="div">
								Ddt
							</Typography>
						</Grid>
						<Grid size={{ xs:3, sm:3, md:3, lg:3 }}>
							<Typography variant="subtitle1" component="div">
								data
							</Typography>
						</Grid>
						<Grid size={{ xs:3, sm:3, md:3, lg:3 }}>
							<Typography variant="subtitle1" component="div" align="right">
								Pallet Ingresso
							</Typography>
						</Grid>
						<Grid size={{ xs:3, sm:3, md:3, lg:3 }}>
							<Typography variant="subtitle1" component="div" align="right">
								Pallet Uscita
							</Typography>
						</Grid>
					</Grid>
				</ListItemText>
				{saldoPalletCommittente?.ultimi_movimenti.map((movimento) => (
					<ListItemButton key={movimento.id_bolla} sx={{ py: 0 }}>
						<Grid container spacing={{ xs: 0.5, md: 1, lg: 2 }} sx={{ width:"100%"}}>
							<Grid size={{ xs:3, sm:3, md:3, lg:3 }}>
								<Typography variant="subtitle1" component="div">
									{movimento.id_bolla}
								</Typography>
							</Grid>
							<Grid size={{ xs:3, sm:3, md:3, lg:3 }}>
								<Typography variant="subtitle1" component="div">
									{movimento.data_documento.toString()}
								</Typography>
							</Grid>
							<Grid size={{ xs:3, sm:3, md:3, lg:3 }}>
								<Typography variant="subtitle1" component="div" align="right">
									{movimento.pallet_ingresso}
								</Typography>
							</Grid>
							<Grid size={{ xs:3, sm:3, md:3, lg:3 }}>
								<Typography variant="subtitle1" component="div" align="right">
									{movimento.pallet_uscita}
								</Typography>
							</Grid>
						</Grid>
					</ListItemButton>
				))}
			</List>
		</Box>
	);
};

export default ReportPalletCommittenteList;
