import React from "react";
import RouterApp from "./routerApp";
import SideBar from "./common/sideBar/SideBar";
import TopBar from "./common/topBar/TopBar";
import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "../redux/store";
import {PreferenzeUtente, setDrawerAperto,} from "./userPreference/userPreferenceSlice";
import CssBaseline from "@mui/material/CssBaseline";
import "react-toastify/dist/ReactToastify.css";
import Box from "@mui/material/Box";
import Copyright from "./common/Copyright";
import logoPiccolo from "../../static/images/logo/logo-Scarso_nuovo_solo.png";
import logoGrande from "../../static/images/logo/logo-Scarso-Nuovo.png";
import {styled,} from "@mui/material/styles";


const sideMenuDrawerWidth = 300;
const miniSideMenuDrawerWidth = 80;
const appBarAltezza = 100;

const HeaderSpacer = styled("div")(({theme}) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: 0,
    marginTop: appBarAltezza,
    // // necessary for content to be below app bar
    // ...theme.mixins.toolbar,
}));


const AppComposer = () => {
    const dispatch = useAppDispatch();
    const preferenzeUtente = useSelector<RootState, PreferenzeUtente>(
        (state) => state.userPreferenceState.preferenzeUtente
    );

    const [drawerPreview, setDrawerPreview] = React.useState(false);

    const [drawerIsOpen, setDrawerIsOpen] = React.useState(
        preferenzeUtente.drawer_aperto
    );

    const tooggleDrawer = () => {
        setDrawerIsOpen(!drawerIsOpen);
        dispatch(setDrawerAperto(!drawerIsOpen));
    };

    const drawerPreviewOn = () => {
        setDrawerPreview(true);
    };

    const drawerPreviewOff = () => {
        setDrawerPreview(false);
    };

    return (
        <Box sx={{display: "flex"}}>
            <CssBaseline/>

            <TopBar
                open={drawerIsOpen || drawerPreview}
                sideMenuDrawerWidth={0}
                miniSideMenuDrawerWidth={miniSideMenuDrawerWidth}
                appBarAltezza={appBarAltezza}
                logoPiccolo={logoPiccolo}
                tooggleDrawer={tooggleDrawer}
            />
            <SideBar
                drawerPreviewOn={drawerPreviewOn}
                drawerPreviewOff={drawerPreviewOff}
                open={drawerIsOpen || drawerPreview}
                sideMenuDrawerWidth={sideMenuDrawerWidth}
                miniSideMenuDrawerWidth={miniSideMenuDrawerWidth}
                appBarAltezza={appBarAltezza}
                tooggleDrawer={tooggleDrawer}
                logoPiccolo={logoPiccolo}
                logoGrande={logoGrande}
            />
            <Box component="main" sx={{flexGrow: 1, p: 0}}>
                <HeaderSpacer/>
                <Box sx={{minHeight: "50vh", px: 2, pt: 0, m: 0}}>
                    <RouterApp/>
                    <Copyright/>
                </Box>
            </Box>
        </Box>
    );
};

export default AppComposer;
