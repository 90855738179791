import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../redux/store";
import { Routes_path } from "../../routerApp";
import {
	Lotto,
	LottiState,
	fetchLotti,
	resetLotti,
} from "../pianificazione/pianificazioneSlice";
import {
	fetchschedeCarico,
	generaSchedeCarico,
	mandaM4,
} from "./schedeCaricoSlice";
import {
	Camion,
	Autista,
	Allevamento,
	fetchCamions,
	fetchAutisti,
	fetchAllevamenti,
} from "../anagrafiche/anagraficheSlice";
import TransitionsModal from "../../common/TransitionsModal";
import NavigazioneGiorniToolbar from "../../common/dateUtils/NavigazioneGiorniToolbar";
import SchedeCaricoLottoCard from "./schedeCaricoLottoCard/SchedeCaricoLottoCard";
import SchedeCaricoGrid from "./SchedeCaricoGrid";
import CamionsBox from "./CamionsBox";
import AutistiBox from "./AutistiBox";
import {
	RecuperaDataCorrente,
	FissaDataCorrente,
} from "../../common/dateUtils/RecuperaDataCorrente";
import { DateTime } from 'luxon';
import PaperMui from "@mui/material/Paper";
import Grid from "@mui/material/Grid2";
import { styled, Box } from "@mui/system";
import Button from "@mui/material/Button";
import PrintIcon from "@mui/icons-material/Print";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import {convertToDateTime} from "../../common/dateUtils/convertToDateTime";


const Paper = styled(PaperMui)(({ theme }) => ({
	color: theme.palette.primary.contrastText,
	backgroundColor: "#b0b0b0",
	padding: theme.spacing(1),
	borderRadius: theme.shape.borderRadius,
}));

const lottiOrdinati = (lotti: Lotto[]) => {
	const lottiOrdinati = lotti
		.slice()
		.sort((a, b) => a.ordinamento_lotto - b.ordinamento_lotto);
	return lottiOrdinati;
};


const SchedeCaricoPage = () => {
	
	const dispatch = useAppDispatch();
	const lotti: LottiState = useSelector<RootState, LottiState>(
		(state) => state.pianificazione.lotti
	);

	const minutiRefreshLotti = useSelector<RootState, number>(
		(state) => state.authentication.minutiRefreshLotti
	);

	const allevamenti: Allevamento[] = useSelector<RootState, Allevamento[]>(
		(state) => state.anagrafiche.allevamenti.results
	);
	const camions: Camion[] = useSelector<RootState, Camion[]>((state) =>
		state.anagrafiche.camions.results.filter((c) => c.tipo_trasporto == "V")
	);
	const autisti: Autista[] = useSelector<RootState, Autista[]>(
		(state) => state.anagrafiche.autisti.results
	);
	const location = useLocation();
	const recupera_data_corrente = RecuperaDataCorrente(location);

	const [data, setData] = React.useState<DateTime | null>(
		recupera_data_corrente
	);

	const handlerGeneraSchedeCarico = (lotto: Lotto, viaggioLungo: boolean) => {
		dispatch(generaSchedeCarico({ lotto, viaggioLungo }));
	};
	const handlerMandaM4 = (lotto: Lotto, contatto_id: number) => {
		dispatch(mandaM4({ lotto, contatto_id }));
	};

	const [refresh, setRefresh] = React.useState<boolean>(false);

	useEffect(() => {
		data && FissaDataCorrente(data);

		const data_da = convertToDateTime(data)?.toJSDate();
		const data_a = convertToDateTime(data)?.toJSDate();
		dispatch(resetLotti());
		dispatch(fetchLotti({ data_da, data_a }));
		dispatch(fetchschedeCarico({ data_da, data_a }));
		data && dispatch(fetchCamions(data_da));
		data && dispatch(fetchAutisti(data_da));
		dispatch(fetchAllevamenti());
		setRefresh(false);
	}, [data, refresh]);

	useEffect(() => {
		const timeRefresh = minutiRefreshLotti * 60 * 1000;

		const intervalloRefreshLotti = setInterval(() => {
			const ultimoAggiornamento = lotti.ultimo_aggiornamento;
			const data_da = convertToDateTime(data)?.toJSDate();
			const data_a = convertToDateTime(data)?.toJSDate();
			data && dispatch(fetchCamions(data_da));
			data && dispatch(fetchAutisti(data_da));

			dispatch(fetchLotti({ data_da, data_a, ultimoAggiornamento }));

			dispatch(fetchschedeCarico({ data_da, data_a }));
		}, timeRefresh);
		return () => clearInterval(intervalloRefreshLotti);
	}, [data, lotti.ultimo_aggiornamento]);

	const [openDettaglioSchedeCarico, setOpenDettaglioSchedeCarico] =
		useState(false);

	return (
		<Box>
			{/* <Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={
					lotti.errorsStack.status == ErrorStatusTypes.PENDING ||
					schedeCarico.errorsStack.status == ErrorStatusTypes.PENDING
				}
			>
				<CircularProgress color="inherit" />
			</Backdrop> */}

			<NavigazioneGiorniToolbar
				titolo={"Schede di carico"}
				setData={setData}
				data={data}
				refresh={() => {
					setRefresh(true);
				}}
			/>
			{/* <DndProvider backend={HTML5Backend}> */}
			<Grid container spacing={2}>
				<Grid size={{ xs:12, sm:9, md:10, lg:10 }}>
					{lottiOrdinati(lotti.results).map((lotto) => (
						<SchedeCaricoLottoCard
							key={"schede_lotto_" + lotto.id}
							lotto={lotto}
							allevamento={allevamenti.find(
								(allevamento) => allevamento.id == lotto.allevamento
							)}
							camions={camions}
							autisti={autisti}
							// deleteSchedaCarico,
							generaSchedeCarico={handlerGeneraSchedeCarico}
							mandaM4={handlerMandaM4}
						/>
					))}
				</Grid>
				<Grid size={{ xs:12, sm:3, md:2, lg:2 }}>
					<Grid container spacing={1}>
						<Grid size={{ xs:9, sm:9, md:9, lg:9 }}>
							{/* <Grid size={{ xs:12 }}> */}
							<Button
								sx={{ width: "100%", marginTop: 2, marginBottom: 0 }}
								startIcon={<PrintIcon />}
								variant="contained"
								component={Link}
								to={{
									pathname: Routes_path.ORDINI_CARICO + convertToDateTime(data)?.toISODate(),
								}}
								state={{
									giornoSettimana: convertToDateTime(data),
								}}
							>
								Ordini di Carico
							</Button>
						</Grid>
						<Grid size={{ xs:3, sm:3, md:3, lg:3 }}>
							<Button
								sx={{ width: "100%", marginTop: 2, marginBottom: 0 }}
								startIcon={<LocalShippingIcon />}
								variant="contained"
								onClick={() => setOpenDettaglioSchedeCarico(true)}
							/>
						</Grid>
						<Grid size={{ xs:12 }}>
							<Paper
								elevation={2}
								// sx={{
								//   mt: 1,
								// }}
							>
								<AutistiBox autisti={autisti} />
							</Paper>
						</Grid>
						<Grid size={{ xs:12 }}>
							<Paper
								elevation={2}
								// sx={{
								//   borderColor: "#e09f0a",
								//   borderStyle: "groove",

								//   minHeight: 300,
								// }}
							>
								<CamionsBox camions={camions} />
							</Paper>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			{/* </DndProvider> */}
			<TransitionsModal
				open={openDettaglioSchedeCarico}
				handleClose={() => {
					setOpenDettaglioSchedeCarico(false);
				}}
			>
				<SchedeCaricoGrid lotti={lottiOrdinati(lotti.results)} />
			</TransitionsModal>
		</Box>
	);
};

export default SchedeCaricoPage;
