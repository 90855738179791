import React, { useState, useEffect } from "react";
import { Utente, UtenteLogged } from "../authenticationSlice";
import { ErrorStatusTypes } from "../../common/errorsDeclarations";
import { useForm, SubmitHandler } from "react-hook-form";

import Grid from "@mui/material/Grid2";

import { TextField, Button, ButtonGroup } from "@mui/material";
import ReplayIcon from "@mui/icons-material/Replay";

import Typography from "@mui/material/Typography";

interface ProfileFormProps {
  utenteLogged: UtenteLogged;
  saveUtente: (utente: Utente) => void;
  editing: boolean;
  switchEdit: (switchOn?: boolean) => void;
}

const ProfileForm = ({
  utenteLogged,
  saveUtente,
  editing,
  switchEdit,
  ...props
}: ProfileFormProps) => {
  const [restore, setRestore] = useState(false);
  const [saving, setSaving] = useState(false);

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    setError,
    reset,
  } = useForm<Utente>();

  useEffect(() => {
    setValue("username", utenteLogged.username);
    setValue("first_name", utenteLogged.first_name);
    setValue("last_name", utenteLogged.last_name);
    setValue("email", utenteLogged.email);
    setRestore(false);
  }, [restore]);

  useEffect(() => {
    utenteLogged?.errorsStack?.fieldsErrors?.username &&
      setError("username", {
        type: "server",
        message: utenteLogged.errorsStack.fieldsErrors.username.toString(),
      });
    utenteLogged?.errorsStack?.fieldsErrors?.first_name &&
      setError("first_name", {
        type: "server",
        message: utenteLogged.errorsStack.fieldsErrors.first_name.toString(),
      });
    utenteLogged?.errorsStack?.fieldsErrors?.last_name &&
      setError("last_name", {
        type: "server",
        message: utenteLogged.errorsStack.fieldsErrors.last_name.toString(),
      });
    utenteLogged?.errorsStack?.fieldsErrors?.email &&
      setError("email", {
        type: "server",
        message: utenteLogged.errorsStack.fieldsErrors.email.toString(),
      });
  }, [utenteLogged?.errorsStack?.fieldsErrors]);

  const handleRestore = () => {
    setRestore(true);
    switchEdit();
  };

  const onSubmit: SubmitHandler<Utente> = (utente) => {
    saveUtente(utente);
    switchEdit();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid size={{ xs:12, sm:6}}>
          <TextField
            disabled={!editing}
            size="small"
            label="username"
            variant="standard"
            fullWidth={true}
            inputProps={{ readOnly: true }}
            {...register("username")}
            error={!!errors?.username}
            helperText={errors?.username?.message}
          />
        </Grid>

        <Grid size={{ xs:12, sm:6}}>
          <TextField
            id="email"
            {...register("email", {
              required: "Campo obbligatorio",
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "valore dell'email non valido",
              },
            })}
            error={!!errors?.email}
            helperText={errors?.email?.message}
            disabled={!editing}
            size="small"
            label="email"
            variant="standard"
            fullWidth={true}
          />
        </Grid>
        <Grid size={{ xs:12, sm:6}}>
          <TextField
            id="first_name"
            {...register("first_name")}
            error={!!errors?.first_name}
            helperText={errors?.first_name?.message}
            disabled={!editing}
            size="small"
            label="first_name"
            variant="standard"
            fullWidth={true}

            // helperText={errors.first_name}
          />
        </Grid>
        <Grid size={{ xs:12, sm:6}}>
          <TextField
            required
            id="last_name"
            {...register("last_name")}
            error={!!errors?.last_name}
            helperText={errors?.last_name?.message}
            disabled={!editing}
            size="small"
            label="last_name"
            variant="standard"
            fullWidth={true}
          />
        </Grid>

        <Grid size={{ xs:12, sm:12, md:12, lg:12 }}>
          <Typography variant="subtitle2">Accesso a moduli</Typography>
          <div>
            {utenteLogged.gruppi?.map((gruppo, index) => (
              <Typography variant="body2" key={"gruppo_"+index}>
                <span> • </span>
                {gruppo}
              </Typography>
            ))}
          </div>
        </Grid>
      </Grid>

      {editing && (
        <Grid size={{ xs:12, sm:12, md:12, lg:12 }} sx={{ mt: 3 }}>
          <ButtonGroup
            size="small"
            color="primary"
            aria-label="small outlined primary button group"
          >
            <Button
              startIcon={<ReplayIcon />}
              disabled={saving}
              aria-label="restore"
              onClick={handleRestore}
            ></Button>
            <Button
              type="submit"
              disabled={
                utenteLogged?.errorsStack?.status == ErrorStatusTypes.PENDING
              }
            >
              Salva
            </Button>
          </ButtonGroup>
        </Grid>
      )}
    </form>
  );
};

export default ProfileForm;
