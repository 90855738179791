import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { RootState, useAppDispatch } from "../../../../redux/store";
import { getColore } from "../../pianificazione/utility/lottoUtility";
import {
	LottiState,
	fetchLotti,
	resetLotti,
	Lotto,
} from "../../pianificazione/pianificazioneSlice";
import { Lavorazioni, fetchLavorazioni } from "../lavorazioniSlice";
import { fetchArticoli } from "../../anagrafiche/anagraficheSlice";
import NavigazioneGiorniToolbar from "./../../../common/dateUtils/NavigazioneGiorniToolbar";
import { DateTime } from 'luxon';
import LavorazioniLottoManage from "./../lavorazioniPianificazione/LavorazioniLottoManage";
import {RecuperaDataCorrente, FissaDataCorrente,} from "../../../common/dateUtils/RecuperaDataCorrente";
import {ErrorStatusTypes, ErrorsStack,} from "../../../common/errorsDeclarations";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import {convertToDateTime} from "../../../common/dateUtils/convertToDateTime";

const LavorazioniConsuntiviGiornoPage = () => {

	const dispatch = useAppDispatch();
	const lotti: LottiState = useSelector<RootState, LottiState>(
		(state) => state.pianificazione.lotti
	);

	const location = useLocation();
	const recupera_data_corrente = RecuperaDataCorrente(location);

	// const recupera_data_corrente = () => {
	// 	if (location.state) {
	// 		const { giornoSettimana } = location.state as LocationState;
	// 		if (giornoSettimana) {
	// 			return giornoSettimana;
	// 		}
	// 	}
	// 	const dataLavorazioniCorrente = localStorage.getItem(
	// 		DATA_PIANIFICAZIONE_LAVORAZIONI_CORRENTE
	// 	);
	// 	if (!!dataLavorazioniCorrente) {
	// 		return new Date(dataLavorazioniCorrente);
	// 	}
	// 	return DateToday();
	// };

	const [data, setData] = React.useState<DateTime | null>(
		recupera_data_corrente
	);
	const [refresh, setRefresh] = React.useState<boolean>(false);

	useEffect(() => {
		data && FissaDataCorrente(data);

		// localStorage.setItem(
		// 	DATA_PIANIFICAZIONE_LAVORAZIONI_CORRENTE,
		// 	convertToDateTime(data)?.setLocale("it").toFormat("yyyy-MM-dd")
		// );
		const data_da = data;
		dispatch(resetLotti());
		dispatch(fetchLotti({ data_da: data_da?.toJSDate(), data_a: data_da?.toJSDate(), }));
		dispatch(fetchArticoli());
		setRefresh(false);
	}, [data, refresh]);

	const lavorazioni: Lavorazioni = useSelector<RootState, Lavorazioni>(
		(state) => state.lavorazioniState.lavorazioni
	);

	const confezionamentiErrorsStack: ErrorsStack = useSelector<
		RootState,
		ErrorsStack
	>((state) => state.confezionamentiState.confezionamenti.errorsStack);

	const [expanded, setExpanded] = React.useState<string | false>(false);
	const handleChangeLottoPanel =
		(lotto: Lotto) => (event: React.SyntheticEvent, isExpanded: boolean) => {
			if (lotto.id) {
				dispatch(fetchLavorazioni(lotto.id));
			}
			setExpanded(isExpanded ? "lotto_" + lotto.id : false);
		};

	// const handleRecuperaPesature = () => {
	// 	setExpanded(false);
	// 	data && dispatch(recuperaPesature(data));
	// };

	return (
		<>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={
					lotti.errorsStack.status == ErrorStatusTypes.PENDING ||
					lavorazioni.errorsStack.status == ErrorStatusTypes.PENDING ||
					confezionamentiErrorsStack.status == ErrorStatusTypes.PENDING
				}
			>
				<CircularProgress color="inherit" />
			</Backdrop>

			<NavigazioneGiorniToolbar
				titolo={"Consuntivo Lavorazioni"}
				setData={setData}
				data={data}
				refresh={() => {
					setRefresh(true);
				}}
				// Toolbar={() => {
				// 	return (
				// 		<Stack
				// 			direction="row"
				// 			justifyContent="flex-end"
				// 			alignItems="center"
				// 			sx={{ ml: 2 }}
				// 			spacing={1}
				// 		>
				// 			<Button
				// 				onClick={handleRecuperaPesature}
				// 				variant="contained"
				// 				disabled={true}
				// 			>
				// 				Recupera Pesature
				// 			</Button>
				// 		</Stack>
				// 	);
				// }}
			/>

			<Box
				sx={{
					p: 1,
					// elevation: 0,
				}}
			>
				{lotti.results.map((lotto) => (
					<Accordion
						key={"lotto_panel" + lotto.id}
						expanded={expanded === "lotto_" + lotto.id}
						onChange={handleChangeLottoPanel(lotto)}
					>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="lavorazione_accordion"
							id="lavorazione_accordion_header"
						>
							<Grid container spacing={1} sx={{width:"100%"}}>
								<Grid size={{ xs:1, sm:1, md:1, lg:1 }}>
									<Typography variant="h6">
										{lotto.ordinamento_lotto}
									</Typography>
								</Grid>
								<Grid size={{ xs:3, sm:3, md:2, lg:2 }}>
									<Typography variant="h6">{lotto.committente_desc}</Typography>
								</Grid>
								<Grid size={{ xs:2, sm:2, md:2, lg:2 }}>
									<Typography variant="h6">{lotto.tipo_capo_desc}</Typography>
								</Grid>
								<Grid size={{ xs:2, sm:2, md:1, lg:1 }}>
									<Typography variant="h6">{getColore(lotto)}</Typography>
								</Grid>
								<Grid size={{ xs:0, sm:0, md:1, lg:2 }}
									sx={{
										display: {
											xs: "none",
											sm: "none",
											md: "block",
											lg: "block",
										},
									}}
								></Grid>
								<Grid size={{ xs:5, sm:5, md:5, lg:4 }}>
									<Typography	>
										{lotto.data_ora_inizio_incassettamento && `inizio: ${convertToDateTime(lotto.data_ora_inizio_incassettamento)?.setLocale("it").toFormat('HH:mm')}`}
										{lotto.data_ora_fine_incassettamento && `  fine: ${convertToDateTime(lotto.data_ora_fine_incassettamento)?.setLocale("it").toFormat('HH:mm')}`}
									</Typography>
									{/*{data && <ConfezionamentoHeader lotto={lotto} data={data} />}*/}
								</Grid>
							</Grid>
						</AccordionSummary>
						<AccordionDetails
							sx={{
								py: 0,
								"&:last-child": { pb: 1 },
							}}
						>
							{expanded === "lotto_" + lotto.id && (
								<LavorazioniLottoManage lotto={lotto} cosuntivazione={true} />
							)}
						</AccordionDetails>
					</Accordion>
				))}
			</Box>
		</>
	);
};

export default LavorazioniConsuntiviGiornoPage;
