import React, { useEffect } from "react";

import { KgCommittenti } from "./kgCommittentiSlice";
import { Committente } from "../../../anagrafiche/anagraficheSlice";

import { DateTime } from 'luxon';

import { Box } from "@mui/system";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { lottoForiPerCamion } from "components/main/pianificazione/utility/lottoUtility";

interface KgCommittentiMeseTableProps {
	kgCommittentiMese: KgCommittenti[];
	committenti: Committente[];
}

interface KgMensiliCommittenti {
	committenteId: number;
	nome: string;
	kg_partenza: number;
	kg_arrivo: number;
}

const calcoloKgMensiliCommittenti = (
	kgCommittentiMese: KgCommittenti[],
	committenti: Committente[]
) => {
	/// kgMensiliCommittenti = array con le informazioni da esporre poi nella table per committente (id, nome, kg vivo alla partenza e kg vivo all'arrivo)

	const kgMensiliCommittenti: KgMensiliCommittenti[] = committenti.map(
		(committente) =>
			kgCommittentiMese
				.filter((lotto) => lotto.committente == committente.id)
				.reduce(
					(
						previousValue: KgMensiliCommittenti,
						currentValue: KgCommittenti
					) => {
						return {
							committenteId: committente.id,
							nome: committente.nome,
							kg_partenza:
								previousValue.kg_partenza +
								Number(currentValue.peso_lotto_partenza || 0),
							kg_arrivo:
								previousValue.kg_arrivo +
								Number(currentValue.peso_lotto_arrivo || 0),
						};
					},
					{
						committenteId: committente.id,
						nome: committente.nome,
						kg_partenza: 0,
						kg_arrivo: 0,
					}
				)
	);
	return kgMensiliCommittenti.filter(
		(committente) => committente.kg_partenza > 0
	);
};

// totaleKgPartenzaMensili = somma dei valori kg_partenza nell'array kgMensiliCommittenti
const calcoloTotaleKgPartenzaMensili = (
	kgMensiliCommittenti: KgMensiliCommittenti[] | undefined
) => {
	const totaleKgPartenzaMensili = kgMensiliCommittenti?.reduce(
		(previousValue, currentValue: KgMensiliCommittenti) => {
			return previousValue + currentValue.kg_partenza;
		},
		0
	);
	return totaleKgPartenzaMensili;
};

// totaleKgArrivoMensili = somma dei valori kg_arrivo nell'array kgMensiliCommittenti
const calcoloTotaleKgArrivoMensili = (
	kgMensiliCommittenti: KgMensiliCommittenti[] | undefined
) => {
	const totaleKgArrivoMensili = kgMensiliCommittenti?.reduce(
		(previousValue, currentValue: KgMensiliCommittenti) => {
			return previousValue + currentValue.kg_arrivo;
		},
		0
	);
	return totaleKgArrivoMensili;
};

const KgCommittentiMeseTable = ({
	kgCommittentiMese,
	committenti,
}: KgCommittentiMeseTableProps) => {
	const [kgMensiliCommittenti, setKgMensiliCommittenti] = React.useState<
		KgMensiliCommittenti[]
	>([]);
	const [totaleKgPartenzaMensili, setTotaleKgPartenzaMensili] = React.useState<
		number | undefined
	>(0);
	const [totaleKgArrivoMensili, setTotaleKgArrivoMensili] = React.useState<
		number | undefined
	>(0);

	useEffect(() => {
		setKgMensiliCommittenti(
			calcoloKgMensiliCommittenti(kgCommittentiMese, committenti)
		);
		setTotaleKgPartenzaMensili(
			calcoloTotaleKgPartenzaMensili(
				calcoloKgMensiliCommittenti(kgCommittentiMese, committenti)
			)
		);
		setTotaleKgArrivoMensili(
			calcoloTotaleKgArrivoMensili(
				calcoloKgMensiliCommittenti(kgCommittentiMese, committenti)
			)
		);
	}, [kgCommittentiMese, committenti]);

	return (
		<React.Fragment>
			<TableContainer component={Paper} sx={{ width: "100%" }}>
				<Box>
					<Table size="small" aria-label="report mensile kg committenti">
						<TableHead>
							<TableRow>
								<TableCell
									align="center"
									colSpan={3}
									sx={{
										paddingTop: 3,
										paddingBottom: 3,
										fontWeight: "bold",
										fontSize: 18,
										// textTransform: "uppercase",
									}}
								>
									<div>
										Totale peso Partenza{" "}
										{Intl.NumberFormat("it-IT").format(
											totaleKgPartenzaMensili || 0
										)}{" "}
										kg
									</div>
									<div>
										Totale peso Arrivo{" "}
										{Intl.NumberFormat("it-IT").format(
											totaleKgArrivoMensili || 0
										)}{" "}
										kg
									</div>
								</TableCell>
							</TableRow>

							<TableRow>
								<TableCell sx={{ fontWeight: "bold" }}>Committente</TableCell>
								<TableCell sx={{ fontWeight: "bold" }}>
									Kg vivo partenza
								</TableCell>
								<TableCell sx={{ fontWeight: "bold" }}>
									Kg vivo arrivo
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{kgMensiliCommittenti &&
								kgMensiliCommittenti.map((kgMensileCommittente) => (
									<TableRow key={kgMensileCommittente.committenteId}>
										<TableCell>{kgMensileCommittente.nome}</TableCell>
										<TableCell>
											{Intl.NumberFormat("it-IT").format(
												kgMensileCommittente.kg_partenza || 0
											)}
										</TableCell>
										<TableCell>
											{Intl.NumberFormat("it-IT").format(
												kgMensileCommittente.kg_arrivo || 0
											)}
										</TableCell>
									</TableRow>
								))}
						</TableBody>
					</Table>
				</Box>
			</TableContainer>
		</React.Fragment>
	);
};

export default KgCommittentiMeseTable;
