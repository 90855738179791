import React, {useEffect} from "react";
import {useForm, Controller, SubmitHandler} from "react-hook-form";
import {TextField, Button, Grid, Box} from "@mui/material";
import moment from "moment";
import {RootState, useAppDispatch} from "../../../redux/store";


import {NumericFormat} from "react-number-format";
import Typography from "@mui/material/Typography";
import {fetchLavorazionePallets, savePallet, Pallet, deletePallet} from "./pesaturaPalletSlice";
import DeleteIcon from "@mui/icons-material/Delete";
import Avatar from "@mui/material/Avatar";
import {Link as ReactLink} from "react-router-dom";
import {Routes_path} from "../../routerApp";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import {deepOrange} from "@mui/material/colors";
import Stack from "@mui/material/Stack";

interface PalletFormProps {
    pallet: Pallet;
    consentiModifica: boolean
}

const PalletForm: React.FC<PalletFormProps> = ({pallet, consentiModifica = false}: PalletFormProps) => {
    const {
        handleSubmit,
        register,
        formState: {errors, isDirty, isValid},
        setValue,
        watch,
        getValues,
        control,
        setError,
        clearErrors,
        reset,
    } = useForm<Pallet>({
        defaultValues: {
            numero_cassette: pallet.numero_cassette || 0,
            peso_netto: pallet.peso_netto || 0,
            peso_lordo: pallet.peso_lordo || 0,
            data_produzione: pallet.data_produzione || '',
            data_scadenza: pallet.data_scadenza || '',
            // Aggiungi qui altri campi se necessario
        }
    });

    const dispatch = useAppDispatch();
    const listaCampi = Object.keys(pallet) as Array<keyof Pallet>;

    useEffect(() => {
        reset();
        listaCampi.map((field) => {
            setValue(field, pallet[field]);
        });
    }, [
        listaCampi.forEach((field) => {
            pallet[field];
        }),
        pallet.id,
    ]);

    useEffect(() => {
        clearErrors();
        listaCampi.forEach((field) => {
            pallet?.errorsStack?.fieldsErrors &&
            pallet.errorsStack.fieldsErrors[field] &&
            setError(field, {
                type: "server",
                message:
                    pallet.errorsStack.fieldsErrors[field].toString(),
            });
        });
    }, [
        pallet?.errorsStack?.fieldsErrors,
    ]);

    const handlerSavePallet = () => {
        const formValues = getValues();
        const palletToSave: Pallet = {...formValues, id: pallet.id, lavorazione: pallet.lavorazione};
        palletToSave.lavorazione && dispatch(savePallet({palletToSave: palletToSave}));
    }

    const onSubmit: SubmitHandler<Pallet> = () => {
        handlerSavePallet();
    };

    return (
        <Box sx={{p: 0.5}} key={`pallet_${pallet.id}`}>
            <form onSubmit={handleSubmit(onSubmit)} key={`form_${pallet.id}`}>

                <Grid container spacing={1} alignItems="center" wrap="wrap" justifyContent="center">
                    <Grid item md={2}>
                        <Stack direction="row" spacing={2} alignItems="center">
                            <Avatar
                                sx={{bgcolor: deepOrange[500]}}
                                component={ReactLink}
                                to={{
                                    pathname: Routes_path.STAMPA_GRANDE_IMBALLO_DDT + pallet.id,
                                }}
                                target="_blank"
                            >
                                {pallet.id}
                            </Avatar>
                            <Stack direction="column" spacing={1} alignItems="flex-start">
                                <Typography variant="body1">lotto:{pallet.lotto_codice_tracciabilita}</Typography>
                                <Typography variant="caption">{pallet.lavorazione_descrizione}</Typography>
                            </Stack>
                        </Stack>

                    </Grid>
                    <Grid item xs={3} md={1}>
                        <Controller
                            name="numero_cassette"
                            control={control}
                            // rules={{
                            //     required: "Il numero di cassette è obbligatorio",
                            //     min: { value: 1, message: "Deve essere almeno 1" },
                            // }}
                            render={({field}) => (
                                <TextField
                                    {...field}
                                    label="N° Cassette"
                                    type="number"
                                    disabled={!consentiModifica}
                                    autoComplete="off"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    fullWidth
                                    error={!!errors.numero_cassette}
                                    helperText={errors.numero_cassette?.message}
                                    onBlur={() => handlerSavePallet()}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={3} md={2}>
                        <Controller
                            name="peso_netto"
                            control={control}
                            // rules={{
                            //     required: "Il peso netto è obbligatorio",
                            //     min: { value: 0.1, message: "Deve essere almeno 0.1" },
                            // }}
                            render={({field}) => (
                                <NumericFormat
                                    id="Peso Netto"
                                    customInput={TextField}
                                    variant="outlined"
                                    label="Peso Netto"
                                    disabled={!consentiModifica}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    error={!!errors?.peso_netto}
                                    helperText={errors?.peso_netto?.message}
                                    // {...register("km_evento")}
                                    value={field.value}
                                    autoComplete="off"
                                    prefix="kg "
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    valueIsNumericString={true}
                                    onValueChange={(v) => {
                                        setValue("peso_netto", Number(v.value), {
                                            shouldDirty: true,
                                        });
                                    }}
                                    sx={{width: "100%"}}
                                    onBlur={() => handlerSavePallet()}

                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={3} md={2}>
                        <Controller
                            name="peso_lordo"
                            control={control}
                            // rules={{
                            //     required: "Il peso lordo è obbligatorio",
                            //     min: { value: 0.1, message: "Deve essere almeno 0.1" },
                            // }}
                            render={({field}) => (

                                <NumericFormat
                                    id="peso_lordo"
                                    customInput={TextField}
                                    variant="outlined"
                                    label="peso_lordo"
                                    disabled={!consentiModifica}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    error={!!errors?.peso_netto}
                                    helperText={errors?.peso_netto?.message}
                                    // {...register("km_evento")}
                                    value={field.value}
                                    autoComplete="off"
                                    prefix="kg "
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    valueIsNumericString={true}
                                    onValueChange={(v) => {
                                        setValue("peso_lordo", Number(v.value), {
                                            shouldDirty: true,
                                        });
                                    }}
                                    sx={{width: "100%"}}
                                    onBlur={() => handlerSavePallet()}

                                />

                            )}
                        />
                    </Grid>
                    <Grid item xs={3} md={2}>
                        <Controller
                            name="data_produzione"
                            control={control}
                            rules={{
                                required: "La data di produzione è obbligatoria",
                            }}
                            render={({field}) => (
                                <TextField
                                    {...field}
                                    label="Data Produzione"
                                    type="date"
                                    fullWidth
                                    disabled={!consentiModifica}
                                    autoComplete="off"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    error={!!errors.data_produzione}
                                    helperText={errors.data_produzione?.message}
                                    onChange={(event) => {
                                        setValue("data_produzione", event.target.value, {
                                            shouldDirty: true,
                                        });
                                        handlerSavePallet()
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={3} md={2}>
                        <Controller
                            name="data_scadenza"
                            control={control}
                            render={({field}) => (
                                <TextField
                                    {...field}
                                    label="Data Scadenza"
                                    type="date"
                                    fullWidth
                                    disabled={!consentiModifica}
                                    autoComplete="off"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    error={!!errors.data_scadenza}
                                    helperText={errors.data_scadenza?.message}
                                    onChange={(event) => {
                                        setValue("data_scadenza", event.target.value, {
                                            shouldDirty: true,
                                        });
                                        handlerSavePallet()
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item md={1}>
                        <Button startIcon={<DeleteIcon/>}
                                type="submit"
                                disabled={!consentiModifica}
                                variant="contained"
                                color="primary"
                                onClick={() => dispatch(deletePallet({palletToDelete: pallet}))}
                        />
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
};

export default PalletForm;
