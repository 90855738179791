import React from "react";

import {Navigate, Route, Routes} from "react-router-dom";
import PrivateRoute from "./authentication/PrivateRoute";
import ScrollToTop from "./common/ScrollToTop";

import HomePage from "./main/home/HomePage";
import ProfilePage from "./authentication/profile/ProfilePage";
import PianificazionePage from "./main/pianificazione/PianificazionePage";
import UnderConstruction from "./common/UnderConstruction";
import NotFoundPage from "./common/NotFoundPage";
import NotPermittedPage from "./common/NotPermittedPage";

import LavorazioniConsuntiviGiornoPage from "./main/lavorazioni/lavorazioniConsuntivazione/LavorazioniConsuntiviGiornoPage";
import LavorazioniPianificateGiornoPage from "./main/lavorazioni/lavorazioniPianificazione/LavorazioniPianificateGiornoPage";
import SchedaMacellazioneStampaPage from "./main/lavorazioni/lavorazioniPianificazione/stampe/SchedaMacellazioneStampaPage";
import GrandeImballoStampaPage from "./main/lavorazioni/lavorazioniPianificazione/stampe/GrandeImballoStampaPage";
import SchedaHaccpStampaPage from "./main/lavorazioni/lavorazioniPianificazione/stampe/SchedaHaccpStampaPage";
import ProgammaIncassettamentoStampaPage from "./main/lavorazioni/lavorazioniPianificazione/stampe/ProgammaIncassettamentoStampaPage";
import SchedeCaricoPage from "./main/schedeCarico/SchedeCaricoPage";
import PianiViaggioPage from "./main/schedeCarico/pianiViaggio/PianiViaggioPage";
import ConsuntiviVivoPage from "./main/schedeCarico/consuntiviVivo/ConsuntiviVivoPage";
import SchedeMacelloGiornoPage from "./main/schedeMacello/SchedeMacelloGiornoPage";
import OrdiniCaricoPage from "./main/schedeCarico/stampe/OrdiniCaricoPage";
import OrdineCaricoPage from "./main/schedeCarico/stampe/OrdineCaricoPage";
import VeterinarioSettimanaPage from "./main/reportistica/veterinario/VeterinarioSettimanaPage";
import ModuloVeterinarioPage from "./main/reportistica/veterinario/stampe/ModuloVeterinarioPage";
import ReportAutistiMesePage from "./main/reportistica/reportAutisti/ReportAutistiMesePage";
import ReportIolepecMesePage from "./main/reportistica/reportIolepec/ReportIolepecMesePage";
import ReportisticaCommittentiPage from "./main/reportistica/committenti/ReportisticaCommittentiPage";
import DocumentiDiTrasportoPage from "./main/bolle_fatture/DocumentiDiTrasportoPage";
import ReportPalletPage from "./main/bolle_fatture/pallets/ReportPalletPage";
import ReportPalletCommittentePage from "./main/ospiti/pallets/ReportPalletCommittentePage";
import DocumentoDiTrasportoPage from "./main/bolle_fatture/DocumentoDiTrasportoPage";
import DdtStampaPage from "./main/bolle_fatture/stampe/DdtStampaPage";
import DashboardCommittentiPage from "./main/ospiti/dashboard/DashboardCommittentiPage";
import LottiCommittentePage from "./main/ospiti/lotti/LottiCommittentePage";
import LottoCommittentePage from "./main/ospiti/lotti/LottoCommittentePage";

import LottiProduttorePage from "./main/ospiti/produttori/lotti/LottiProduttorePage";
import LottoProduttorePage from "./main/ospiti/produttori/lotti/LottoProduttorePage";
import AllevamentiCommittentePage from "./main/ospiti/allevamenti/AllevamentiCommittentePage";
import UtentiEsterniPage from "./main/ospiti/utentiEsterni/UtentiEsterniPage";
import UtenteEsternoPage from "./main/ospiti/utentiEsterni/UtenteEsternoPage";
import EventiManutenzionePage from "./main/manutenzione_mezzi/EventiManutenzionePage";
import EventoManutenzionePage from "./main/manutenzione_mezzi/EventoManutenzionePage";

import ResaStampaPage from "./main/reportistica/rese/stampe/ResaStampaPage";
import PalletsPage from "./main/pesaturaPallet/PalletsPage";

import {
	PermissionTypes,
	PROGRAMMA_INCASSETTAMENTO_PERMISSIONS,
	STAMPE_MACELLO_PERMISSIONS,
} from "../components/authentication/permissionsGroups";

import SignIn from "./authentication/SignIn";
import ChangePassword from "./authentication/ChangePasswordPage";
import ForgotPassword from "./authentication/ForgotPassword";
import ResetPasswordProps from "./authentication/ResetPasswordPage";
import WellComePage from "./main/ospiti/WellComePage";
import PesaturePalletGiornoPage from "./main/pesaturaPallet/PesaturePalletGiornoPage";
import PalletPage from "./main/pesaturaPallet/PalletsPage";
import GrandeImballoDdtStampaPage from "./main/pesaturaPallet/stampe/GrandeImballoDdtStampaPage";
import PackingListStampaPage from "./main/pesaturaPallet/stampe/PackingListStampaPage";
import PesaturePalletLottoPage from "./main/pesaturaPallet/PesaturePalletLottoPage";
import RafoodStampaPage from "./main/pesaturaPallet/stampe/RafoodStampaPage";
import DdtContoTerziStampaPage from "components/main/pesaturaPallet/stampe/dttContoTerzi/DdtContoTerziStampaPage";

export enum Routes_path {
	HOME = "/",
	SIGNIN = "/signin/",
	WELL_COME = "/well_come/:uidb64/:token/",
	FORGOT_PASSWORD = "/forgotPassword/",
	RESET_PASSWORD = "/resetPassword/:uidb64/:token/",
	PIANIFICAZIONE = "/pianificazione",
	SCHEDE_CARICO = "/schede_carico",
	PIANI_VIAGGIO = "/piani_viaggio",
	ORDINI_CARICO = "/schede_carico/ordini_carico",
	ORDINE_CARICO = "/schede_carico/ordine_carico/",
	PIANO_LAVORAZIONI = "/lavorazioni_pianificate_giorno",
	SCHEDA_MACELLAZIONE = "/lavorazioni_pianificate_giorno/scheda_macellazione",
	SCHEDA_HACCP = "/lavorazioni_pianificate_giorno/scheda_haccp",
	STAMPA_GRANDE_IMBALLO = "/lavorazioni_pianificate_giorno/stampa_grande_imballo/",
	PROGRAMMA_INCASSETTAMENTO = "/lavorazioni_pianificate_giorno/progamma_incassettamento",
	CONSUNTIVO_VIVO = "/consuntivi_vivo",
	SCHEDA_MACELLO = "/schede_macello",
	CONSUNTIVO_LAVORAZIONI = "/lavorazioni_consuntivi_giorno",

	PESATURA_PALLET = "/pesatura_pallet",
	PALLETS_LAVORAZIONE = "/palles_lavorazione",
	PESATURE_PALLET_LOTTO = "/pesature_pallet_lotto/",
	STAMPA_GRANDE_IMBALLO_DDT = "/stampa_grande_imballo_ddt/",
	STAMPA_PACKING_LIST = "/stampa_packing_list/",
	STAMPA_RAFOOD = "/stampa_rafood/",
	STAMPA_DOCUMENTO_CONTO_TERZI_TRASPORTO = "/stampa_documento_conto_terzi_trasporto/",

	DOCUMENTI_TRASPORTO = "/ddt_list",
	DOCUMENTO_TRASPORTO = "/documento_di_trasporto",
	STAMPA_DOCUMENTO_TRASPORTO = "/documento_di_trasporto/stampa_documento_di_trasporto/",
	REPORT_PALLET = "/report_pallet",

	REPORT_VETERINARIO = "/reportistica/veterinario",
	REPORT_COMMITTENTI = "/reportistica/reportCommittenti",
	STAMPA_RESA = "/rese/stampa_resa/",
	UTENTI_ESTERNI = "/ospiti/utenti_esterni",
	UTENTE_ESTERNO = "/ospiti/utente_esterno",
	DASHBOARD_COMMITTENTI = "/ospiti/dashboard",
	LOTTI_COMMITTENTE = "/ospiti/committente/lotti",
	LOTTO_COMMITTENTE = "/ospiti/committente/lotto/",
	ALLEVAMENTI_COMMITTENTE = "/ospiti/allevamenti/",
	PALLET_COMMITTENTE = "/ospiti/pallets/",
	LOTTI_PRODUTTORE = "/ospiti/produttore/lotti",
	LOTTO_PRODUTTORE = "/ospiti/produttore/lotto/",

	REPORT_IOLEPEC = "/reportistica/report_iolepec",
	MANUTENZIONE_MEZZI = "/iolepec/manutenzioni/",
	EVENTO_MANUTENZIONE = "/iolepec/evento_manutenzione/",
	UNDER_COSTRUCTION = "/underConstruction",
}

const RouterApp = () => {

	// const isMaintenanceMode = true;
	// if (isMaintenanceMode) {
	// 	return <Navigate to={Routes_path.UNDER_COSTRUCTION} replace />;
	// }

	return (
		<ScrollToTop>
			<Routes>
				 <Route path="*" element={<UnderConstruction />} />
				<Route path={Routes_path.HOME} element={<HomePage />} />
				<Route path={Routes_path.SIGNIN} element={<SignIn />} />
				{/* <Route  path={"/register/"} element={Register} /> */}
				<Route path={Routes_path.WELL_COME} element={<WellComePage />} />
				<Route
					path={Routes_path.FORGOT_PASSWORD}
					element={<ForgotPassword />}
				/>
				<Route
					path={Routes_path.RESET_PASSWORD}
					element={<ResetPasswordProps />}
				/>

				<Route
					path={Routes_path.PIANIFICAZIONE}
					element={
						<PrivateRoute
							gruppiAutorizzati={[
								PermissionTypes.PIANIFICATORI,
								PermissionTypes.PIANIFICATORI_LETTURA,
							]}
							element={<PianificazionePage />}
						/>
					}
				/>
				<Route
					path={Routes_path.SCHEDE_CARICO}
					element={
						<PrivateRoute
							gruppiAutorizzati={[
								PermissionTypes.PIANIFICATORI,
								PermissionTypes.PIANIFICATORI_LETTURA,

								PermissionTypes.OPERATORI_LOGISTICA,
								PermissionTypes.OPERATORI_LOGISTICA_LETTURA,
							]}
							element={<SchedeCaricoPage />}
						/>
					}
				/>
				<Route
					path={Routes_path.ORDINI_CARICO}
					element={
						<PrivateRoute
							gruppiAutorizzati={[
								PermissionTypes.OPERATORI_LOGISTICA,
								PermissionTypes.OPERATORI_LOGISTICA_LETTURA,
							]}
							element={<OrdiniCaricoPage />}
						/>
					}
				/>
				<Route
					path={Routes_path.PIANI_VIAGGIO}
					element={
						<PrivateRoute
							gruppiAutorizzati={[
								PermissionTypes.OPERATORI_LOGISTICA,
								PermissionTypes.OPERATORI_LOGISTICA_LETTURA,
								PermissionTypes.AUTISTI,
							]}
							element={<PianiViaggioPage />}
						/>
					}
				/>

				<Route
					path={Routes_path.ORDINE_CARICO + ":scheda_carico_id"}
					element={
						<PrivateRoute
							gruppiAutorizzati={[
								PermissionTypes.OPERATORI_LOGISTICA,
								PermissionTypes.OPERATORI_LOGISTICA_LETTURA,
							]}
							element={<OrdineCaricoPage />}
						/>
					}
				/>

				<Route
					path={Routes_path.PIANO_LAVORAZIONI}
					element={
						<PrivateRoute
							gruppiAutorizzati={[
								PermissionTypes.PIANIFICATORI,
								PermissionTypes.PIANIFICATORI_LETTURA,
								PermissionTypes.OPERATORI_MACELLO,
								PermissionTypes.SCHEDULATORI_LAVORAZIONI,
								PermissionTypes.SCHEDULATORI_ESTERNI,
							]}
							element={<LavorazioniPianificateGiornoPage />}
						/>
					}
				/>
				<Route
					path={Routes_path.SCHEDA_MACELLAZIONE}
					element={
						<PrivateRoute
							gruppiAutorizzati={STAMPE_MACELLO_PERMISSIONS}
							element={<SchedaMacellazioneStampaPage />}
						/>
					}
				/>
				<Route
					path={Routes_path.SCHEDA_HACCP}
					element={
						<PrivateRoute
							gruppiAutorizzati={STAMPE_MACELLO_PERMISSIONS}
							element={<SchedaHaccpStampaPage />}
						/>
					}
				/>
				<Route
					path={Routes_path.STAMPA_GRANDE_IMBALLO}
					element={
						<PrivateRoute
							gruppiAutorizzati={STAMPE_MACELLO_PERMISSIONS}
							element={<GrandeImballoStampaPage />}
						/>
					}
				/>
				<Route
					path={Routes_path.PROGRAMMA_INCASSETTAMENTO}
					element={
						<PrivateRoute
							gruppiAutorizzati={PROGRAMMA_INCASSETTAMENTO_PERMISSIONS}
							element={<ProgammaIncassettamentoStampaPage />}
						/>
					}
				/>

				<Route
					path={Routes_path.CONSUNTIVO_VIVO}
					element={
						<PrivateRoute
							gruppiAutorizzati={[
								PermissionTypes.OPERATORI_LOGISTICA,
								PermissionTypes.OPERATORI_LOGISTICA_LETTURA,
							]}
							element={<ConsuntiviVivoPage />}
						/>
					}
				/>

				<Route
					path={Routes_path.SCHEDA_MACELLO}
					element={
						<PrivateRoute
							gruppiAutorizzati={[PermissionTypes.OPERATORI_MACELLO]}
							element={<SchedeMacelloGiornoPage />}
						/>
					}
				/>
				<Route
					path={Routes_path.CONSUNTIVO_LAVORAZIONI}
					element={
						<PrivateRoute
							gruppiAutorizzati={[PermissionTypes.OPERATORI_MACELLO]}
							element={<LavorazioniConsuntiviGiornoPage />}
						/>
					}
				/>

				<Route
					path={Routes_path.PESATURA_PALLET}
					element={
						<PrivateRoute
							gruppiAutorizzati={[PermissionTypes.OPERATORI_INCASSETTAMENTO]}
							element={<PesaturePalletGiornoPage />}
							// element={<UnderConstruction />}
						/>
					}
				/>

				<Route
					path={Routes_path.PESATURE_PALLET_LOTTO + ":lotto_id"}
					element={
						<PrivateRoute
							gruppiAutorizzati={[PermissionTypes.OPERATORI_INCASSETTAMENTO]}
							element={<PesaturePalletLottoPage />}
							// element={<UnderConstruction />}

						/>
					}
				/>

				<Route
					path={
						Routes_path.STAMPA_GRANDE_IMBALLO_DDT + ":pallet_id"
					}
					element={
						<PrivateRoute
							gruppiAutorizzati={[
								PermissionTypes.PIANIFICATORI,
								PermissionTypes.PIANIFICATORI_LETTURA,
								PermissionTypes.OPERATORI_LOGISTICA,
								PermissionTypes.OPERATORI_LOGISTICA_LETTURA,
								PermissionTypes.COMMITTENTI,
							]}
							element={<GrandeImballoDdtStampaPage />}
						/>
					}
				/>

				<Route
					path={
						Routes_path.STAMPA_PACKING_LIST + ":packing_list_id"
					}
					element={
						<PrivateRoute
							gruppiAutorizzati={[
								PermissionTypes.PIANIFICATORI,
								PermissionTypes.PIANIFICATORI_LETTURA,
								PermissionTypes.OPERATORI_LOGISTICA,
								PermissionTypes.OPERATORI_LOGISTICA_LETTURA,
								PermissionTypes.COMMITTENTI,
							]}
							element={<PackingListStampaPage />}
						/>
					}
				/>
				<Route
					path={
						Routes_path.STAMPA_RAFOOD + ":packing_list_id"
					}
					element={
						<PrivateRoute
							gruppiAutorizzati={[
								PermissionTypes.PIANIFICATORI,
								PermissionTypes.PIANIFICATORI_LETTURA,
								PermissionTypes.OPERATORI_LOGISTICA,
								PermissionTypes.OPERATORI_LOGISTICA_LETTURA,
								PermissionTypes.COMMITTENTI,
							]}
							element={<RafoodStampaPage />}
						/>
					}
				/>
				<Route
					path={
						Routes_path.STAMPA_DOCUMENTO_CONTO_TERZI_TRASPORTO + ":packing_list_id"
					}
					element={
						<PrivateRoute
							gruppiAutorizzati={[
								PermissionTypes.PIANIFICATORI,
								PermissionTypes.PIANIFICATORI_LETTURA,
								PermissionTypes.OPERATORI_LOGISTICA,
								PermissionTypes.OPERATORI_LOGISTICA_LETTURA,
								PermissionTypes.COMMITTENTI,
							]}
							element={<DdtContoTerziStampaPage />}
						/>
					}
				/>

				{/*<Route*/}
				{/*	path={Routes_path.PALLETS_LAVORAZIONE}*/}
				{/*	element={*/}
				{/*		<PrivateRoute*/}
				{/*			gruppiAutorizzati={[PermissionTypes.OPERATORI_INCASSETTAMENTO]}*/}
				{/*			element={<PalletsPage />}*/}
				{/*		/>*/}
				{/*	}*/}
				{/*/>*/}

				<Route
					path="/rese"
					element={
						<PrivateRoute
							gruppiAutorizzati={[PermissionTypes.COMMITTENTI]}
							element={<UnderConstruction />}
						/>
					}
				/>

				<Route
					path={Routes_path.REPORT_VETERINARIO}
					element={
						<PrivateRoute
							gruppiAutorizzati={[PermissionTypes.VETERINARI]}
							element={<VeterinarioSettimanaPage />}
						/>
					}
				/>

				<Route
					path="/reportistica/veterinario/modulo_veterinario"
					element={
						<PrivateRoute
							gruppiAutorizzati={[
								PermissionTypes.PIANIFICATORI,
								PermissionTypes.PIANIFICATORI_LETTURA,
								PermissionTypes.VETERINARI,
							]}
							element={<ModuloVeterinarioPage />}
						/>
					}
				/>

				<Route
					path={Routes_path.STAMPA_RESA + ":resa_id"}
					element={
						<PrivateRoute
							gruppiAutorizzati={[
								PermissionTypes.PIANIFICATORI,
								PermissionTypes.PIANIFICATORI_LETTURA,
								PermissionTypes.COMMITTENTI,
							]}
							element={<ResaStampaPage />}
						/>
					}
				/>

				<Route
					path={Routes_path.REPORT_COMMITTENTI}
					element={
						<PrivateRoute
							gruppiAutorizzati={[
								PermissionTypes.PIANIFICATORI,
								PermissionTypes.PIANIFICATORI_LETTURA,
							]}
							element={<ReportisticaCommittentiPage />}
						/>
					}
				/>

				<Route
					path="/reportistica/report_autisti"
					element={
						<PrivateRoute
							gruppiAutorizzati={[
								PermissionTypes.PIANIFICATORI,
								PermissionTypes.PIANIFICATORI_LETTURA,
								PermissionTypes.OPERATORI_LOGISTICA,
								PermissionTypes.OPERATORI_LOGISTICA_LETTURA,
							]}
							element={<ReportAutistiMesePage />}
						/>
					}
				/>

				<Route
					path="/reportistica/report_iolepec"
					element={
						<PrivateRoute
							gruppiAutorizzati={[
								PermissionTypes.PIANIFICATORI,
								PermissionTypes.PIANIFICATORI_LETTURA,
								PermissionTypes.OPERATORI_LOGISTICA,
								PermissionTypes.OPERATORI_LOGISTICA_LETTURA,
							]}
							element={<ReportIolepecMesePage />}
						/>
					}
				/>
				<Route
					path={Routes_path.DOCUMENTI_TRASPORTO}
					element={
						<PrivateRoute
							gruppiAutorizzati={[
								PermissionTypes.OPERATORI_LOGISTICA,
								PermissionTypes.OPERATORI_LOGISTICA_LETTURA,
							]}
							element={<DocumentiDiTrasportoPage />}
						/>
					}
				/>

				<Route
					path={Routes_path.DOCUMENTO_TRASPORTO}
					element={
						<PrivateRoute
							gruppiAutorizzati={[
								PermissionTypes.OPERATORI_LOGISTICA,
								PermissionTypes.OPERATORI_LOGISTICA_LETTURA,
							]}
							element={<DocumentoDiTrasportoPage />}
						/>
					}
				/>

				<Route
					path={
						Routes_path.STAMPA_DOCUMENTO_TRASPORTO + ":documentoDiTrasporto_id"
					}
					element={
						<PrivateRoute
							gruppiAutorizzati={[
								PermissionTypes.PIANIFICATORI,
								PermissionTypes.PIANIFICATORI_LETTURA,
								PermissionTypes.OPERATORI_LOGISTICA,
								PermissionTypes.OPERATORI_LOGISTICA_LETTURA,
								PermissionTypes.COMMITTENTI,
							]}
							element={<DdtStampaPage />}
						/>
					}
				/>

				<Route
					path={Routes_path.REPORT_PALLET}
					element={
						<PrivateRoute
							gruppiAutorizzati={[
								PermissionTypes.OPERATORI_LOGISTICA,
								PermissionTypes.OPERATORI_LOGISTICA_LETTURA,
							]}
							element={<ReportPalletPage />}
						/>
					}
				/>
				<Route
					path={Routes_path.UTENTI_ESTERNI}
					element={
						<PrivateRoute
							gruppiAutorizzati={[
								PermissionTypes.PIANIFICATORI,
								PermissionTypes.PIANIFICATORI_LETTURA,
							]}
							element={<UtentiEsterniPage />}
						/>
					}
				/>

				<Route
					path={Routes_path.UTENTE_ESTERNO}
					element={
						<PrivateRoute
							gruppiAutorizzati={[
								PermissionTypes.PIANIFICATORI,
								PermissionTypes.PIANIFICATORI_LETTURA,
							]}
							element={<UtenteEsternoPage />}
						/>
					}
				/>
				<Route
					path={Routes_path.DASHBOARD_COMMITTENTI}
					element={
						<PrivateRoute
							gruppiAutorizzati={[
								PermissionTypes.PIANIFICATORI,
								PermissionTypes.PIANIFICATORI_LETTURA,
								PermissionTypes.COMMITTENTI,
							]}
							element={<DashboardCommittentiPage />}
						/>
					}
				/>

				<Route
					path={Routes_path.LOTTI_COMMITTENTE}
					element={
						<PrivateRoute
							gruppiAutorizzati={[
								PermissionTypes.PIANIFICATORI,
								PermissionTypes.PIANIFICATORI_LETTURA,
								PermissionTypes.COMMITTENTI,
							]}
							element={<LottiCommittentePage />}
						/>
					}
				/>

				<Route
					path={Routes_path.LOTTO_COMMITTENTE + ":lotto_id"}
					element={
						<PrivateRoute
							gruppiAutorizzati={[
								PermissionTypes.PIANIFICATORI,
								PermissionTypes.PIANIFICATORI_LETTURA,
								PermissionTypes.COMMITTENTI,
							]}
							element={<LottoCommittentePage />}
						/>
					}
				/>

				<Route
					path={Routes_path.ALLEVAMENTI_COMMITTENTE}
					element={
						<PrivateRoute
							gruppiAutorizzati={[
								PermissionTypes.PIANIFICATORI,
								PermissionTypes.PIANIFICATORI_LETTURA,
								PermissionTypes.COMMITTENTI,
							]}
							element={<AllevamentiCommittentePage />}
						/>
					}
				/>

				<Route
					path={Routes_path.LOTTI_PRODUTTORE}
					element={
						<PrivateRoute
							gruppiAutorizzati={[
								PermissionTypes.PIANIFICATORI,
								PermissionTypes.PIANIFICATORI_LETTURA,
								PermissionTypes.PRODUTTORI,
							]}
							element={<LottiProduttorePage />}
						/>
					}
				/>

				<Route
					path={Routes_path.LOTTO_PRODUTTORE + ":lotto_id"}
					element={
						<PrivateRoute
							gruppiAutorizzati={[
								PermissionTypes.PIANIFICATORI,
								PermissionTypes.PIANIFICATORI_LETTURA,
								PermissionTypes.PRODUTTORI,
							]}
							element={<LottoProduttorePage />}
						/>
					}
				/>

				<Route
					path={Routes_path.PALLET_COMMITTENTE}
					element={
						<PrivateRoute
							gruppiAutorizzati={[
								PermissionTypes.PIANIFICATORI,
								PermissionTypes.OPERATORI_LOGISTICA,
								PermissionTypes.PIANIFICATORI_LETTURA,
								PermissionTypes.OPERATORI_LOGISTICA_LETTURA,
								PermissionTypes.COMMITTENTI,
							]}
							element={<ReportPalletCommittentePage />}
						/>
					}
				/>

				<Route
					path={Routes_path.MANUTENZIONE_MEZZI}
					element={
						<PrivateRoute
							gruppiAutorizzati={[
								PermissionTypes.PIANIFICATORI,
								PermissionTypes.OPERATORI_LOGISTICA,
								PermissionTypes.PIANIFICATORI_LETTURA,
								PermissionTypes.OPERATORI_LOGISTICA_LETTURA,
								PermissionTypes.COMMITTENTI,
							]}
							element={<EventiManutenzionePage />}
						/>
					}
				/>

				<Route
					path={Routes_path.EVENTO_MANUTENZIONE + ":evento_manutenzione_id"}
					element={
						<PrivateRoute
							gruppiAutorizzati={[PermissionTypes.PIANIFICATORI]}
							element={<EventoManutenzionePage />}
						/>
					}
				/>

				<Route
					path={Routes_path.EVENTO_MANUTENZIONE}
					element={
						<PrivateRoute
							gruppiAutorizzati={[PermissionTypes.PIANIFICATORI]}
							element={<EventoManutenzionePage />}
						/>
					}
				/>

				<Route
					path="/me"
					element={
						<PrivateRoute
							gruppiAutorizzati={[PermissionTypes.ALL]}
							element={<ProfilePage />}
						/>
					}
				/>
				<Route
					path="/me/changepassword"
					element={
						<PrivateRoute
							gruppiAutorizzati={[PermissionTypes.ALL]}
							element={<ChangePassword />}
						/>
					}
				/>

				<Route path="/notPermitted" element={<NotPermittedPage />} />
				<Route path="*" element={<NotFoundPage />} />
				<Route
					path={Routes_path.UNDER_COSTRUCTION}
					element={<UnderConstruction />}
				/>
			</Routes>
		</ScrollToTop>
	);
};

export default RouterApp;
