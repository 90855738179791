import { handleResponse, handleError, headerset } from "../apiUtils";
import { DateTime } from 'luxon';
import { SchedaCarico } from "../../components/main/schedeCarico/schedeCaricoSlice";
import { convertToDateTime } from "components/common/dateUtils/convertToDateTime";

const baseUrl = (scheda_carico_id?: number | null) => {
	if (scheda_carico_id) {
		return (
			process.env.API_URL +
			"/api/schede_carico/schede_carico/" +
			scheda_carico_id +
			"/"
		);
	} else {
		return process.env.API_URL + "/api/schede_carico/schede_carico/";
	}
};

export function fetchschedeCarico(
	data_da?: Date,
	data_a?: Date,
	ultimoAggiornamento?: string
) {
	let url = baseUrl();
	// convertToDateTime(data_da)?.setLocale("it").toFormat("yyyy-MM-dd");
	if (data_da || data_a || ultimoAggiornamento) {
		url += `?`;
	}
	if (data_da) {
		url += `data_da=${convertToDateTime(data_da)?.setLocale("it").toFormat("yyyy-MM-dd")}&`;
	}
	if (data_a) {
		url += `data_a=${convertToDateTime(data_a)?.setLocale("it").toFormat("yyyy-MM-dd")}&`;
	}
	if (ultimoAggiornamento != undefined && ultimoAggiornamento != "") {
		url += "ultimo_aggiornamento=" + ultimoAggiornamento;
	}
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function getSchedaCarico(schedaCaricoId: number) {
	let url = baseUrl(schedaCaricoId);
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function saveSchedaCarico(schedaCaricoToSave: SchedaCarico) {
	const schedaCarico = {
		...schedaCaricoToSave,
		data_ora_carico_pianificata: convertToDateTime(schedaCaricoToSave.data_ora_carico_pianificata)?.toISO(),
	};

	return fetch(baseUrl(schedaCaricoToSave.id), {
		method: schedaCaricoToSave.id ? "PUT" : "POST", // PUT to update when id already exists.
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
		body: JSON.stringify({
			...schedaCarico,
			// Parse authorId to a number (in case it was sent as a string).
			// id: parseInt(progetto.id, 10),
		}),
	})
		.then(handleResponse)
		.catch(handleError);
}

export function assegnaCamion(camion_id: number, schedaCarico_id: number) {
	const url = baseUrl(schedaCarico_id) + "assegna_camion/" + camion_id + "/";
	return fetch(url, {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function assegnaAutista(autista_id: number, schedaCarico_id: number) {
	const url = baseUrl(schedaCarico_id) + "assegna_autista/" + autista_id + "/";
	return fetch(url, {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function deleteSchedaCarico(schedaCaricoToDelete: SchedaCarico) {
	return fetch(baseUrl(schedaCaricoToDelete.id), {
		method: "DELETE",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function generaSchedeCarico(lotto_id: number, viaggio_lungo?: boolean) {
	let url =
		process.env.API_URL +
		"/api/schede_carico/genera_schede_carico/" +
		lotto_id +
		"/";
	if (viaggio_lungo) {
		url = url + "?viaggio_lungo=true";
	}

	return fetch(url, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
		body: JSON.stringify({}),
	})
		.then(handleResponse)
		.catch(handleError);
}

export function mandaM4(lotto_id: number, contatto_id: number) {
	let url =
		process.env.API_URL +
		"/api/schede_carico/schede_carico/" +
		lotto_id +
		"/" +
		contatto_id +
		"/manda_m4/";
	return fetch(url, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
		body: JSON.stringify({}),
	})
		.then(handleResponse)
		.catch(handleError);
}

// ******************************************************
// ******************************************************
export function fetchPianiViaggio(data_da?: Date, data_a?: Date) {
	let url = process.env.API_URL + "/api/schede_carico/piani_viaggio/";
	// convertToDateTime(data_da)?.setLocale("it").toFormat("yyyy-MM-dd");
	if (data_da && data_a) {
		url += `?data_da=${convertToDateTime(data_da)?.setLocale("it").toFormat("yyyy-MM-dd")}&data_a=${convertToDateTime(data_a)?.setLocale("it").toFormat('yyyy-MM-dd')}`;
	} else if (data_da) {
		url += `?data_da=${convertToDateTime(data_da)?.setLocale("it").toFormat("yyyy-MM-dd")}`;
	} else if (data_a) {
		url += `?data_a=${convertToDateTime(data_a)?.setLocale("it").toFormat("yyyy-MM-dd")}`;
	}
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

// ******************************************************
// ******************************************************

export function mandaWhatsAppAutisti(schedaCarico_id: number) {
	let url =
		process.env.API_URL +
		"/api/schede_carico/schede_carico/" +
		schedaCarico_id +
		"/manda_whatsapp/";
	return fetch(url, {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
		body: JSON.stringify({}),
	})
		.then(handleResponse)
		.catch(handleError);
}

// ******************************************************
// ******************************************************

export function mandaSmsAppAutisti(schedaCarico_id: number) {
	let url =
		process.env.API_URL +
		"/api/schede_carico/schede_carico/" +
		schedaCarico_id +
		"/manda_sms/";
	return fetch(url, {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
		body: JSON.stringify({}),
	})
		.then(handleResponse)
		.catch(handleError);
}
