import { handleResponse, handleError, headerset } from "../apiUtils";
import { DateTime } from 'luxon';
import { Confezionamento } from "../../components/main/lavorazioni/lavorazioniConsuntivazione/confezionamentiSlice";
import { Lotto } from "../../components/main/pianificazione/pianificazioneSlice";
import { convertToDateTime } from "components/common/dateUtils/convertToDateTime";

const baseUrl = (confezionamento_id?: number | null) => {
	if (confezionamento_id) {
		return (
			process.env.API_URL +
			"/api/confezionamenti/confezionamenti/" +
			confezionamento_id +
			"/"
		);
	} else {
		return process.env.API_URL + "/api/confezionamenti/confezionamenti/";
	}
};

export function fetchConfezionamenti(data_da?: Date, data_a?: Date) {
	let url = baseUrl();
	// convertToDateTime(data_da)?.setLocale("it").toFormat("yyyy-MM-dd");
	if (data_da && data_a) {
		url += `?data_da=${convertToDateTime(data_da)?.setLocale("it").toFormat("yyyy-MM-dd")}&data_a=${convertToDateTime(data_a)?.setLocale("it").toFormat('yyyy-MM-dd')}`;
	} else if (data_da) {
		url += `?data_da=${convertToDateTime(data_da)?.setLocale("it").toFormat("yyyy-MM-dd")}`;
	} else if (data_a) {
		url += `?data_a=${convertToDateTime(data_a)?.setLocale("it").toFormat("yyyy-MM-dd")}`;
	}
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function getConfezionamento(confezionamentoId: number) {
	let url = baseUrl(confezionamentoId);
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function saveConfezionamento(confezionamentoToSave: Confezionamento) {
	const confezionamento = {
		...confezionamentoToSave,
		data_ora_inizio_confezionamento: convertToDateTime(confezionamentoToSave.data_ora_inizio_confezionamento)?.toISO(),
		data_ora_fine_carico: convertToDateTime(confezionamentoToSave.data_ora_fine_confezionamento)?.toISO(),
	};

	return fetch(baseUrl(confezionamentoToSave.id), {
		method: confezionamentoToSave.id ? "PUT" : "POST", // PUT to update when id already exists.
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
		body: JSON.stringify({
			...confezionamento,
			// Parse authorId to a number (in case it was sent as a string).
			// id: parseInt(progetto.id, 10),
		}),
	})
		.then(handleResponse)
		.catch(handleError);
}

export function recuperaPesature(lotto: Lotto) {
	const url = process.env.API_URL + `/api/mes/recupera_pesature/${lotto.id}/`;
	return fetch(url, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}
