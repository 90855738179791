import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Link, useLocation} from "react-router-dom";

import {RootState, useAppDispatch} from "./../../../redux/store";
import {getColore} from "./../pianificazione/utility/lottoUtility";

import {
    LottiState,
    fetchLotti,
    resetLotti,
    Lotto,
} from "../pianificazione/pianificazioneSlice";
import {Lavorazione,} from "../lavorazioni/lavorazioniSlice";

import {Lavorazioni, fetchLavorazioni} from "../lavorazioni/lavorazioniSlice";
import {fetchArticoli} from "../anagrafiche/anagraficheSlice";
import NavigazioneGiorniToolbar from "components/common/dateUtils/NavigazioneGiorniToolbar";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import PalletsPage from "../pesaturaPallet/PalletsPage";
import { DateTime } from 'luxon';

import {
    RecuperaDataCorrente,
    FissaDataCorrente,
} from "components/common/dateUtils/RecuperaDataCorrente";

import {
    ErrorStatusTypes,
    ErrorsStack,
} from "components/common/errorsDeclarations";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

// import DateToday from "./../../../common/dateUtils/DateToday";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import {styled, Box} from "@mui/system";
import PesaturaPalletBreadcrumbs from "./PesaturaPalletBreadcrumbs";
import TransitionsModal from "../../common/TransitionsModal";
import GiornoLottiHistory from "../pianificazione/GiornoLottiHistory";
import CardHeader from "@mui/material/CardHeader";
import Card from "@mui/material/Card";
import {theme} from "../../../theme";
import {Routes_path} from "../../routerApp";

const PesaturePalletGiornoPage = () => {
    
    const dispatch = useAppDispatch();
    const lotti: LottiState = useSelector<RootState, LottiState>(
        (state) => state.pianificazione.lotti
    );

    type LocationState = {
        giornoSettimana: any;
    };
    const location = useLocation();

    const recupera_data_corrente = RecuperaDataCorrente(location);


    const [data, setData] = React.useState<DateTime | null>(
        recupera_data_corrente
    );
    const [refresh, setRefresh] = React.useState<boolean>(false);

    useEffect(() => {
        data && FissaDataCorrente(data);

        // localStorage.setItem(
        // 	DATA_PIANIFICAZIONE_LAVORAZIONI_CORRENTE,
        // 	convertToDateTime(data)?.setLocale("it").toFormat("yyyy-MM-dd")
        // );
        const data_da = data;
        const data_a = data;
        dispatch(resetLotti());
        dispatch(fetchLotti({ data_da: data_da?.toJSDate(), data_a: data_da?.toJSDate(), }));
        dispatch(fetchArticoli());
        setRefresh(false);
    }, [data, refresh]);

    const lavorazioni: Lavorazioni = useSelector<RootState, Lavorazioni>(
        (state) => state.lavorazioniState.lavorazioni
    );

    const confezionamentiErrorsStack: ErrorsStack = useSelector<
        RootState,
        ErrorsStack
    >((state) => state.confezionamentiState.confezionamenti.errorsStack);

    const [expanded, setExpanded] = React.useState<string | false>(false);
    const handleChangeLottoPanel =
        (lotto: Lotto) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            if (lotto.id) {
                dispatch(fetchLavorazioni(lotto.id));
            }
            setExpanded(isExpanded ? "lotto_" + lotto.id : false);
        };

    return (
        <>
            <Backdrop
                sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={
                    lotti.errorsStack.status == ErrorStatusTypes.PENDING ||
                    lavorazioni.errorsStack.status == ErrorStatusTypes.PENDING ||
                    confezionamentiErrorsStack.status == ErrorStatusTypes.PENDING
                }
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
            <PesaturaPalletBreadcrumbs/>
            <NavigazioneGiorniToolbar
                titolo={"Pesatura Pallet"}
                setData={setData}
                data={data}
                refresh={() => {
                    setRefresh(true);
                }}
                // Toolbar={() => {
                // 	return (
                // 		<Stack
                // 			direction="row"
                // 			justifyContent="flex-end"
                // 			alignItems="center"
                // 			sx={{ ml: 2 }}
                // 			spacing={1}
                // 		>
                // 			<Button
                // 				onClick={handleRecuperaPesature}
                // 				variant="contained"
                // 				disabled={true}
                // 			>
                // 				Recupera Pesature
                // 			</Button>
                // 		</Stack>
                // 	);
                // }}
            />
            <Grid container spacing={2}
                  sx={{
                      justifyContent: "center",
                      alignItems: "center",
                  }}>
                <Grid size={{ xs:12, sm:10, md:10, lg:8 }}>
                    {lotti.results.map((lotto) => (
                        <Card key={lotto.id} sx={{m:3}}
                            component={Link}
                              to={{
                                  pathname: Routes_path.PESATURE_PALLET_LOTTO + lotto.id,
                              }}
                        >
                            <CardHeader title={

                                <Stack direction="row" justifyContent="flex-start" spacing={6}>
                                    <Typography variant="h5">Lotto n°{lotto.ordinamento_lotto}</Typography>
                                    <Typography variant="h5">{lotto.codice_tracciabilita}</Typography>
                                    <Typography variant="h5">{lotto.committente_desc}</Typography>
                                    <Typography variant="h5">{lotto.tipo_capo_desc}</Typography>
                                    <Typography variant="h5">{getColore(lotto)}</Typography>
                                </Stack>
                            }
                                        sx={{backgroundColor: theme.palette.primary.main}}
                            />
                        </Card>
                    ))}
                </Grid>
            </Grid>
        </>
    );
};

export default PesaturePalletGiornoPage;
