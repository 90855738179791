import React from "react";
import {styled} from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Header from "./Header";
import MuiAppBar, {AppBarProps as MuiAppBarProps} from "@mui/material/AppBar";

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
    sideMenuDrawerWidth: number;
    // miniSideMenuDrawerWidth: number;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) =>
        prop !== "open" && prop !== "sideMenuDrawerWidth",
})<AppBarProps>(({theme, open, sideMenuDrawerWidth}) => ({
    zIndex: theme.zIndex.drawer + 1,
    // width: `calc(100% - ${miniSideMenuDrawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: sideMenuDrawerWidth,

        // width: `calc(100% - ${sideMenuDrawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

interface TopBarProps {
    open?: boolean;
    sideMenuDrawerWidth: number;
    miniSideMenuDrawerWidth: number;
    appBarAltezza: number;
    logoPiccolo: any;
    tooggleDrawer: () => void;
}

export default function TopBar({
                                   open,
                                   sideMenuDrawerWidth,
                                   miniSideMenuDrawerWidth,
                                   appBarAltezza,
                                   logoPiccolo,
                                   tooggleDrawer,
                               }: TopBarProps) {
    return (
        <AppBar
            position="fixed"
            open={open}
            sideMenuDrawerWidth={sideMenuDrawerWidth}
            sx={{
                height: miniSideMenuDrawerWidth,
                px: 0,
                width: {
                    xs: open ? `calc(100% - ${sideMenuDrawerWidth}px)` : `100%`,
                    // sm: open
                    // 	? `calc(100% - ${sideMenuDrawerWidth}px)`
                    // 	: `calc(100% - ${miniSideMenuDrawerWidth}px)`,
                },
                minHeight: appBarAltezza,
                backgroundColor: "#00d900",
                py: 3,
            }}
        >
            <Toolbar sx={{pr: 1}}>
                <IconButton
                    // color="white"
                    aria-label="open drawer"
                    onClick={tooggleDrawer}
                    edge="start"
                    sx={{
                        m: 0,
                        // ...(open && { display: "none" }),
                        color: "#ed9f0e",
                    }}
                    size="large"
                >
                    <img
                        src={logoPiccolo}
                        alt="GI"
                        style={{
                            height: miniSideMenuDrawerWidth -20,
                            // paddingTop: 15,
                            // paddingBottom: 15,
                            // paddingLeft: 25,
                            // paddingRight: 25,
                        }}
                    />
                </IconButton>
                <Typography
                    variant="h4"
                    noWrap
                    display="inline"
                    color="white"
                >
                    {"T&MA"}
                </Typography>

                <Typography variant="body1" noWrap display="inline" color="white" sx={{ml:2}}>
                    {"Trasporto & Macellazione Avicola"}
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        flex: 1,
                        justifyContent: "flex-end",
                    }}
                >
                    <Header/>
                </Box>
            </Toolbar>
        </AppBar>
    );
}
