import React from "react";
import { Allegato } from "../../allegati/allegatiSlice";
import { Lotto } from "../../pianificazione/pianificazioneSlice";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Routes_path } from "../../../routerApp";
import { Link } from "@mui/material";
import { Link as ReactLink } from "react-router-dom";
import { DateTime } from 'luxon';

import AttachFileIcon from "@mui/icons-material/AttachFile";
import ScaleIcon from "@mui/icons-material/Scale";
import WarehouseIcon from "@mui/icons-material/Warehouse";

import { DocumentoDiTrasporto } from "../../bolle_fatture/bolleFattureSlice";
import {convertToDateTime} from "../../../common/dateUtils/convertToDateTime";

interface LottoCommittenteAllegatiListProps {
	lotto?: Lotto;
	allegati: Allegato[];
	documentiDiTrasporto?: DocumentoDiTrasporto[];
}

const LottoCommittenteAllegatiList = ({
	lotto,
	allegati,
	documentiDiTrasporto,
}: LottoCommittenteAllegatiListProps) => {
	return (
		<List dense={true}>
			{lotto?.resa_confermata && (
				<Box key={"resa_" + lotto?.id}>
					<ListItem
						key={"lotto_" + lotto?.id}
						component={ReactLink}
						to={{
							pathname: Routes_path.STAMPA_RESA + lotto?.id,
						}}
						target="_blank"
					>
						<ListItemAvatar>
							<Avatar sx={{ color: "orange", backgroundColor: "#eef" }}>
								<ScaleIcon />
							</Avatar>
						</ListItemAvatar>
						<ListItemText
							sx={{ color: "#eef" }}
							primary={"Resa lotto " + lotto?.codice_tracciabilita}
							secondary={lotto?.tipo_capo_desc}
						/>
					</ListItem>
					<Divider variant="inset" component="li" />
				</Box>
			)}
			{allegati.length == 0 ? (
				<Box key={"box_nessun allegato"}>
					<Typography variant="h4">
						Non ci sono documenti disponibili al momento
					</Typography>
				</Box>
			) : (
				allegati.map((allegato) => (
					<Box key={"box_allegato_" + allegato.id}>
						<ListItem
							key={"allegato_" + allegato.id}
							component={Link}
							href={allegato.file}
							target="_blank"
						>
							<ListItemAvatar>
								<Avatar sx={{ color: "orange", backgroundColor: "#eef" }}>
									<AttachFileIcon />
								</Avatar>
							</ListItemAvatar>
							<ListItemText
								sx={{ color: "#eef" }}
								primary={allegato.tipo_documento_des?.nome}
								secondary={allegato.descrizione}
							/>
						</ListItem>
						<Divider variant="inset" component="li" />
					</Box>
				))
			)}
			{documentiDiTrasporto?.map((ddt) => (
				<Box key={"box_documentiDiTrasporto_" + ddt.id}>
					<ListItem
						key={"documentiDiTrasporto_" + ddt.id}
						component={ReactLink}
						to={{
							pathname: Routes_path.STAMPA_DOCUMENTO_TRASPORTO + ddt.id,
						}}
						target="_blank"
					>
						<ListItemAvatar>
							<Avatar sx={{ color: "orange", backgroundColor: "#eef" }}>
								<WarehouseIcon />
							</Avatar>
						</ListItemAvatar>
						<ListItemText
							sx={{ color: "#eef" }}
							primary={
								"documento di Trasporto del " +
								convertToDateTime(ddt.data_documento)?.setLocale("it").toFormat("dd LLL yyyy")
							}
							// secondary={ddt.destinazione}
						/>
					</ListItem>
					<Divider variant="inset" component="li" />
				</Box>
			))}
		</List>
	);
};

export default LottoCommittenteAllegatiList;
