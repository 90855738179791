import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "../../../../redux/store";

import {useForm, SubmitHandler} from "react-hook-form";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import {
    CorrispettiviLotto,
    ListinoMacellazione,
    modificaCorrispettiviLotto,
} from "../bolleFattureSlice";
import Alert from "../../../common/Alert";

interface LottoCorrispettiviListinoMacellazioneSelectProps {
    corrispettiviLotto: CorrispettiviLotto;
}

const LottoCorrispettiviListinoMacellazioneSelect = ({
                                                         corrispettiviLotto,
                                                     }: LottoCorrispettiviListinoMacellazioneSelectProps) => {
    const {
        handleSubmit,
        register,
        formState: {errors, isDirty},
        setValue,
        watch,
        getValues,
        control,
        setError,
        reset,
    } = useForm<CorrispettiviLotto>();

    // const committenti = useSelector<RootState, Committente[]>(
    //   (state) => state.anagrafiche.committenti.results
    // );

    const listiniMacellazione = useSelector<RootState, ListinoMacellazione[]>(
        (state) => state.bolleFattureState.listiniMacellazione.results
    );

    const dispatch = useAppDispatch();

    const handlerCambiaListinoMacellazione = (
        corrispettiviLotto: CorrispettiviLotto
    ) => {
        dispatch(modificaCorrispettiviLotto(corrispettiviLotto));
    };

    const listaCampi = Object.keys(corrispettiviLotto) as Array<
        keyof CorrispettiviLotto
    >;

    const [listinoMacellazioneCorrente, setListinoMacellazioneCorrente] =
        React.useState<ListinoMacellazione | undefined>(undefined);

    useEffect(() => {
        handleSubmit(onSubmit);
        setListinoMacellazioneCorrente(
            listiniMacellazione.find(
                (listinoMacellazioneScelto) =>
                    listinoMacellazioneScelto.id == getValues("listino_macellazione")
            )
        );
    }, [watch("listino_macellazione")]);
    // #############################################

    useEffect(() => {
        listaCampi.map((field) => {
            setValue(field, corrispettiviLotto[field]);
        });
    }, [
        listaCampi.forEach((field) => {
            corrispettiviLotto[field];
        }),
        corrispettiviLotto.lotto_id,
    ]);

    useEffect(() => {
        listaCampi.forEach((field) => {
            corrispettiviLotto?.errorsStack?.fieldsErrors &&
            corrispettiviLotto.errorsStack.fieldsErrors[field] &&
            setError(field, {
                type: "server",
                message:
                    corrispettiviLotto.errorsStack.fieldsErrors[field].toString(),
            });
        });
    }, [corrispettiviLotto?.errorsStack?.fieldsErrors]);

    const onSubmit: SubmitHandler<CorrispettiviLotto> = (corrispettiviLotto) => {
        handlerCambiaListinoMacellazione(corrispettiviLotto);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {corrispettiviLotto?.errorsStack?.fieldsErrors?.non_field_errors && (
                <Alert severity="error">
                    {corrispettiviLotto?.errorsStack?.fieldsErrors?.non_field_errors.toString()}
                </Alert>
            )}
            <Autocomplete
                key={listinoMacellazioneCorrente?.id}
                // disabled={getValues("confermato")}
                value={listinoMacellazioneCorrente}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event: any, newValue: any) => {
                    setValue("listino_macellazione", newValue ? newValue.id : null, {
                        shouldDirty: true,
                    });
                    handleSubmit((corrispettiviLotto) =>
                        handlerCambiaListinoMacellazione(corrispettiviLotto)
                    )();
                }}
                id="listino_macellazione"
                options={listiniMacellazione}
                getOptionLabel={(option) =>
                    `${option.nome}${!!option.descrizione ? "(" + option.descrizione + ")" : ""}`
                }
                renderOption={(props, option) => (
                    <Box component="li" {...props}>
                        {`${option.nome}${!!option.descrizione ? "(" + option.descrizione + ")" : ""}`}
                    </Box>
                )}
                renderInput={(params: any) => (
                    <TextField
                        variant="outlined"
                        {...params}
                        label="Listino Macellazione"
                        error={!!errors?.listino_macellazione}
                        helperText={errors?.listino_macellazione?.message}
                    />
                )}
            />
        </form>
    );
};

export default LottoCorrispettiviListinoMacellazioneSelect;
