import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "../../../../redux/store";

import {UtenteLogged} from "../../../authentication/authenticationSlice";
import {PermissionTypes} from "../../../authentication/permissionsGroups";

import {utenteAutorizzato} from "../../../authentication/UtenteAutorizzato";

import {
    ErrorStatusTypes,
    ErrorsStack,
} from "../../../common/errorsDeclarations";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import LavorazioneCommittenteFooter from "../LavorazioneCommittenteFooter";

import LottoOrdineClienteForm from "./LottoOrdineCLienteForm";
import {Lotto} from "../../pianificazione/pianificazioneSlice";
import {Articolo} from "../../anagrafiche/anagraficheSlice";
import {
    Lavorazione,
    Lavorazioni,
    LavorazioneCommittente,
    fetchLavorazioni,
    saveLavorazione,
    saveLavorazioneCommittente,
    resetLavorazioneCommittenteCorrente,
    setLavorazioneCommittenteCorrente,
    getLavorazioneCommittente,
    deleteLavorazioneCommittente,
} from "../lavorazioniSlice";
import LavorazioniGrid from "./LavorazioniGrid";
import {Confezionamento} from "../lavorazioniConsuntivazione/confezionamentiSlice";
import Typography from "@mui/material/Typography";
import moment from "moment/moment";
import Grid from "@mui/material/Grid2";
import {convertToDateTime} from "../../../common/dateUtils/convertToDateTime";
import Stack from "@mui/material/Stack";
import {Box} from "@mui/system";

interface LavorazioniLottoManageProps {
    lotto: Lotto;
    cosuntivazione?: boolean;
}

const LavorazioniLottoManage = ({
                                    lotto,
                                    cosuntivazione,
                                }: LavorazioniLottoManageProps) => {
    const dispatch = useAppDispatch();

    const utente = useSelector<RootState, UtenteLogged>(
        (state) => state.authentication.utenteLogged
    );

    const lavorazioni: Lavorazioni = useSelector<RootState, Lavorazioni>(
        (state) => state.lavorazioniState.lavorazioni
    );

    const lavorazioneCommittenteCorrente: LavorazioneCommittente = useSelector<
        RootState,
        LavorazioneCommittente
    >((state) => state.lavorazioniState.lavorazioneCommittenteCorrente);

    const confezionamentoErrorsStack: ErrorsStack = useSelector<
        RootState,
        ErrorsStack
    >((state) => state.confezionamentiState.confezionamenti.errorsStack);

    // state.confezionamenti.errorsStack

    const articoli: Articolo[] = useSelector<RootState, Articolo[]>(
        (state) => state.anagrafiche.articoli.results
    );

    useEffect(() => {
        if (lotto.id) {
            dispatch(fetchLavorazioni(lotto.id));
        }
        dispatch(resetLavorazioneCommittenteCorrente());
    }, []);

    const handlerSaveLavorazione = (
        lottoId: number,
        lavorazioneToSave: Lavorazione
    ) => {
        dispatch(
            saveLavorazione({
                lottoId: lottoId,
                lavorazioneToSave: lavorazioneToSave,
            })
        );
    };

    const handlerSaveLavorazioneCommittente = (
        lottoId: number,
        lavorazioneCommittenteToSave: LavorazioneCommittente
    ) => {
        dispatch(
            saveLavorazioneCommittente({
                lottoId: lottoId,
                lavorazioneCommittenteToSave: lavorazioneCommittenteToSave,
            })
        );
    };

    const handlerDeleteLavorazioneCommittente = (
        lottoId: number,
        lavorazioneCommittenteToDelete: LavorazioneCommittente
    ) => {
        dispatch(
            deleteLavorazioneCommittente({
                lottoId: lottoId,
                lavorazioneCommittenteToDelete: lavorazioneCommittenteToDelete,
            })
        );
    };

    const handlerSetLavorazioneCommittenteCorrente = (
        lavorazioneCommittente_id: number
    ) => {
        if (lavorazioneCommittente_id != 0) {
            dispatch(
                getLavorazioneCommittente({
                    lottoId: lotto.id || 0,
                    lavorazioneCommittente_id: lavorazioneCommittente_id,
                })
            );
        } else {
            const lavorazioneCommittente: LavorazioneCommittente = {
                id: undefined,
                codice_articolo_committente: "",
                descrizione: "",
                committente: lotto.committente || 0,
                tipo_capo: lotto.tipo_capo || 0,
                articolo: undefined,
                pettorina: "",
                dimensione_cassetta: undefined,
                etichetta_su_cassetta: true,
                etichetta_su_pallet: true,
                materiale_cassetta: "",
                materiale_sotto: "",
                materiale_sopra: "",
                destinazione: "",
                deleted: false,
            };
            dispatch(setLavorazioneCommittenteCorrente({lavorazioneCommittente}));
        }
    };

    const handlerResetLavorazioneCommittenteCorrente = () => {
        dispatch(resetLavorazioneCommittenteCorrente());
    };

    return (
        <>
            <Backdrop
                sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={
                    lavorazioni.errorsStack.status == ErrorStatusTypes.PENDING ||
                    lavorazioneCommittenteCorrente?.errorsStack?.status ==
                    ErrorStatusTypes.PENDING ||
                    confezionamentoErrorsStack.status == ErrorStatusTypes.PENDING
                }
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
            <Grid container>
                <Grid size={{xs: 10}}>
                    <LottoOrdineClienteForm lottoEdited={lotto}/>
                </Grid>
                <Grid size={{xs: 2}}>
                    {lotto.data_ora_inizio_incassettamento &&
                        <Stack direction="row" spacing={0}>
                            <Box
                                sx={{
                                    m: 0,
                                    backgroundColor: "#896742",
                                    color: "white",
                                    paddingX: 2,
                                    paddingY: 0,
                                    borderRadius: "8px 0 0 8px",
                                    // minWidth: "120px", // Larghezza minima per l'etichetta
                                    textAlign: "center",
                                }}
                            >
                                Incassettamento
                            </Box>
                            <Box
                                sx={{
                                    m: 0,
                                    borderColor: "#896742",
                                    borderWidth: 1,
                                    borderStyle: "solid",
                                    paddingX: 2,
                                    paddingY: 0,
                                    borderRadius: "0 8px 8px 0",
                                    textAlign: "end",
                                }}
                            >
                                <Typography>
                                    {lotto.data_ora_inizio_incassettamento &&
                                        `inizio: ${convertToDateTime(lotto.data_ora_inizio_incassettamento)?.setLocale("it").toFormat('HH:mm')}`}
                                </Typography>
                                <Typography>
                                    {lotto.data_ora_fine_incassettamento &&
                                        `  fine: ${convertToDateTime(lotto.data_ora_fine_incassettamento)?.setLocale("it").toFormat('HH:mm')}`}
                                </Typography>
                            </Box>
                        </Stack>
                    }
                </Grid>
            </Grid>
            <LavorazioniGrid
                utente={utente}
                lavorazioni={lavorazioni}
                saveLavorazione={handlerSaveLavorazione}
                gridAutoHeight={true}
                setLavorazioneCommittenteCorrente={
                    handlerSetLavorazioneCommittenteCorrente
                }
                cosuntivazione={cosuntivazione}
            />
            {utenteAutorizzato(utente, [
                PermissionTypes.PIANIFICATORI,
                PermissionTypes.OPERATORI_LOGISTICA,
                PermissionTypes.SCHEDULATORI_LAVORAZIONI,
            ]) && (
                <LavorazioneCommittenteFooter
                    lotto={lotto}
                    lavorazioneCommittenteCorrente={lavorazioneCommittenteCorrente}
                    setLavorazioneCommittenteCorrente={
                        handlerSetLavorazioneCommittenteCorrente
                    }
                    resetLavorazioneCommittenteCorrente={
                        handlerResetLavorazioneCommittenteCorrente
                    }
                    articoli={articoli}
                    saveLavorazioneCommittente={handlerSaveLavorazioneCommittente}
                    deleteLavorazioneCommittente={handlerDeleteLavorazioneCommittente}
                />
            )}
        </>
    );
};

export default LavorazioniLottoManage;
