import React from "react";
import AppTokenMng from "./AppTokenMng";
import {ToastContainer} from "react-toastify";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterLuxon} from "@mui/x-date-pickers/AdapterLuxon";
import "react-toastify/dist/ReactToastify.css";
import {ThemeProvider,} from "@mui/material/styles";
import {theme} from "../theme";
import "react-toastify/dist/ReactToastify.css";

const App = () => {
    return (

        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="it">
                <ToastContainer autoClose={3000} hideProgressBar pauseOnHover={true}/>
                <AppTokenMng/>
            </LocalizationProvider>
        </ThemeProvider>
    );
};

export default App;
