import React, { useEffect } from "react";
import { theme } from "../../../../theme";
import { responsiveFontSizes, ThemeProvider } from "@mui/material/styles";

import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../../redux/store";
import { Link } from "react-router-dom";

import {
	Committente,
	Committenti,
	fetchCommittenti,
} from "../../anagrafiche/anagraficheSlice";

import { UtenteLogged } from "../../../authentication/authenticationSlice";

import LottiCommittenteBox from "../lotti/LottiCommittenteBox";
import {
	GraficoCommittenteTipiCapoMese,
	GraficoCommittenteTipiCapoPesoMedioSettimana,
	fetchGraficoCommittenteTipiCapoMese,
	fetchGraficoCommittenteTipiCapoPesoMedioSettimana,
	GraficoCommittenteReseSettimane,
	fetchGraficoCommittenteReseSettimane,
	GraficiKpiStrutturaState,
	IndicatoriTotaliCommittenteTipiCapo,
	fetchIndicatoriTotaliCommittenteTipiCapo,
} from "../../reportistica/grafici/graficiKpiSlice";
import { Routes_path } from "../../../routerApp";
import { stringToColour } from "../../../common/stringToColour";

import { PermissionTypes } from "../../../authentication/permissionsGroups";

import {
	SelectCommittente,
	SelectPeriodo,
} from "./DashboardCommittentiToolbarOptions";

import CommittenteTipiCapoMeseGrafico from "./CommittenteTipiCapoMeseGrafico";
import CommittenteTipiCapoSettimanePesoMedioGrafico from "./CommittenteTipiCapoSettimanePesoMedioGrafico";
import CommittenteReseSettimaneGrafico from "./CommittenteReseSettimaneGrafico";
import CommittenteResaMediaTipiCapoGrafico from "./CommittenteResaMediaTipiCapoGrafico";
import ReseGrafico from "./ReseGrafico";
import ResaMediaGrafico from "./ResaMediaGrafico";
import Stack from "@mui/material/Stack";

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";

import NavigazioneGiorniToolbar from "../../../common/dateUtils/NavigazioneGiorniToolbar";
import { DATA_SCHEDULAZIONE_CORRENTE } from "../../../../api/apiUtils";

import { DateTime } from 'luxon';

import {
	ErrorStatusTypes,
	ErrorsStack,
} from "../../../common/errorsDeclarations";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { utenteAutorizzato } from "../../../authentication/UtenteAutorizzato";

import Typography from "@mui/material/Typography";

import Grid from "@mui/material/Grid2";
import { styled, Box } from "@mui/system";
import Button from "@mui/material/Button";

const periodi: { valore: string; periodo: string }[] = [
	{ valore: "0.1", periodo: "settimana corrente" },
	{ valore: "0.5", periodo: "ultime 2 settimane" },
	{ valore: "1", periodo: "mese corrente" },
	{ valore: "3", periodo: "ultimi 3 mesi" },
];

const DashboardCommittentiPage = () => {
	
	const dispatch = useAppDispatch();

	const committenti: Committenti = useSelector<RootState, Committenti>(
		(state) => state.anagrafiche.committenti
	);

	const graficiErrorsStack: ErrorsStack = useSelector<RootState, ErrorsStack>(
		(state) => state.graficiKpiState.errorsStack
	);
	const graficoCommittenteTipiCapoMese: GraficoCommittenteTipiCapoMese[] =
		useSelector<RootState, GraficoCommittenteTipiCapoMese[]>(
			(state) => state.graficiKpiState.graficoCommittenteTipiCapoMese
		);
	const graficoCommittenteTipiCapoPesoMedioSettimana: GraficoCommittenteTipiCapoPesoMedioSettimana[] =
		useSelector<RootState, GraficoCommittenteTipiCapoPesoMedioSettimana[]>(
			(state) =>
				state.graficiKpiState.graficoCommittenteTipiCapoPesoMedioSettimana
		);

	const graficoCommittenteReseSettimane: GraficoCommittenteReseSettimane[] =
		useSelector<RootState, GraficoCommittenteReseSettimane[]>(
			(state) => state.graficiKpiState.graficoCommittenteReseSettimane
		);

	const indicatoriTotaliCommittenteTipiCapo: IndicatoriTotaliCommittenteTipiCapo[] =
		useSelector<RootState, IndicatoriTotaliCommittenteTipiCapo[]>(
			(state) => state.graficiKpiState.indicatoriTotaliCommittenteTipiCapo
		);
	const utente = useSelector<RootState, UtenteLogged>(
		(state) => state.authentication.utenteLogged
	);

	const [refresh, setRefresh] = React.useState<boolean>(false);
	const [committenteCorrente, setCommittenteCorrente] =
		React.useState<Committente | null>(null);

	useEffect(() => {
		if (utente.gruppi?.includes(PermissionTypes.PIANIFICATORI)) {
			!committenteCorrente && setCommittenteCorrente(committenti.results[0]);
		} else {
			!!utente.committente &&
				setCommittenteCorrente(
					committenti.results.find(
						(committente) => committente.id == utente.committente
					) || null
				);
		}
	}, [committenti.results]);

	const [periodo, setPeriodo] = React.useState<string>("1");

	useEffect(() => {
		dispatch(fetchCommittenti());
		setRefresh(false);
	}, [refresh]);

	useEffect(() => {
		// !committenteCorrente && setCommittenteCorrente(committenti.results[0]);
		committenteCorrente &&
			dispatch(
				fetchGraficoCommittenteTipiCapoMese({
					committente_id: committenteCorrente.id,
					periodo: periodo,
				})
			);
		committenteCorrente &&
			dispatch(
				fetchGraficoCommittenteTipiCapoPesoMedioSettimana({
					committente_id: committenteCorrente.id,
					periodo: periodo,
				})
			);
		committenteCorrente &&
			dispatch(
				fetchGraficoCommittenteReseSettimane({
					committente_id: committenteCorrente.id,
					periodo: periodo,
				})
			);
		committenteCorrente &&
			dispatch(
				fetchIndicatoriTotaliCommittenteTipiCapo({
					committente_id: committenteCorrente.id,
					periodo: periodo,
				})
			);
	}, [committenti.results]);

	useEffect(() => {
		committenteCorrente &&
			dispatch(
				fetchGraficoCommittenteTipiCapoMese({
					committente_id: committenteCorrente.id,
					periodo: periodo,
				})
			);
		committenteCorrente &&
			dispatch(
				fetchGraficoCommittenteTipiCapoPesoMedioSettimana({
					committente_id: committenteCorrente.id,
					periodo: periodo,
				})
			);
		committenteCorrente &&
			dispatch(
				fetchGraficoCommittenteReseSettimane({
					committente_id: committenteCorrente.id,
					periodo: periodo,
				})
			);
		committenteCorrente &&
			dispatch(
				fetchIndicatoriTotaliCommittenteTipiCapo({
					committente_id: committenteCorrente.id,
					periodo: periodo,
				})
			);
	}, [committenteCorrente]);

	useEffect(() => {
		// committenteCorrente &&
		// 	dispatch(
		// 		fetchGraficoCommittenteTipiCapoMese({
		// 			committente_id: committenteCorrente.id,
		// 			periodo: periodo,
		// 		})
		// 	);
		// committenteCorrente &&
		// 	dispatch(
		// 		fetchGraficoCommittenteTipiCapoPesoMedioSettimana({
		// 			committente_id: committenteCorrente.id,
		// 			periodo: periodo,
		// 		})
		// 	);
		committenteCorrente &&
			dispatch(
				fetchGraficoCommittenteReseSettimane({
					committente_id: committenteCorrente.id,
					periodo: periodo,
				})
			);
		committenteCorrente &&
			dispatch(
				fetchIndicatoriTotaliCommittenteTipiCapo({
					committente_id: committenteCorrente.id,
					periodo: periodo,
				})
			);
	}, [periodo]);

	const num_righe: number = indicatoriTotaliCommittenteTipiCapo.filter(
		(tipoCapo) => tipoCapo.numero_capi > 0
	).length;
	const calcSize = 1.45 - num_righe / 10;
	const scalableSize = calcSize + "rem !important";

	return (
		<Box>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={committenti.errorsStack.status == ErrorStatusTypes.PENDING}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
			<NavigazioneGiorniToolbar
				titolo={"DashBoard " + committenteCorrente?.nome}
				dataPresente={false}
				setData={() => undefined}
				refresh={() => {
					setRefresh(true);
				}}
				Toolbar={() => {
					return (
						<Stack
							direction="row"
							justifyContent="flex-end"
							alignItems="center"
							sx={{ ml: 2 }}
							spacing={1}
						>
							<Grid
								container
								spacing={{ xs: 1, md: 2 }}
								justifyContent="flex-end"
							>
								<Grid size={{ xs:12, sm:12, md:12, lg:6 }}>
									{utente.gruppi?.includes(PermissionTypes.PIANIFICATORI) &&
										committenti.results && (
											<SelectCommittente
												committenteCorrente={committenteCorrente}
												committenti={committenti.results}
												setCommittenteCorrente={setCommittenteCorrente}
											/>
										)}
								</Grid>
								{/* {utenteAutorizzato(utente, [
									PermissionTypes.PIANIFICATORI,
									PermissionTypes.PIANIFICATORI_LETTURA,
								]) && ( */}
								<Grid size={{ xs:12, sm:12, md:12, lg:6 }}>
									<SelectPeriodo
										periodo={periodo}
										periodi={periodi}
										setPeriodo={setPeriodo}
									/>
								</Grid>
								{/* )} */}
							</Grid>
						</Stack>
					);
				}}
			/>
			<Box
				sx={{
					// p: 1,
					// elevation: 0,
					margin: { xs: 0.5, md: 1 },
					// marginY: 2,
					// marginBottom: 5,
					padding: { xs: 1, md: 2, lg: 5 },
					backgroundColor: "#f5f5f5",
					border: 0,
					// borderStyle: "solid",
					borderRadius: 4,
					boxShadow: 2,
				}}
			>
				<Grid container spacing={{ xs: 1, md: 2 }}>
					<Grid size={{ xs:12, sm:6, md:6, lg:4 }}>
						{graficiErrorsStack.status == ErrorStatusTypes.PENDING ? (
							<Box
								sx={{
									width: "100%",
									alignContent: "center",
									backgroundColor: "#bbb",
								}}
							>
								<CircularProgress color="inherit" sx={{ my: 15, mx: 25 }} />
							</Box>
						) : (
							<Grid container spacing={{ xs: 1, md: 2 }}>
								<Grid size={{ xs:12, sm:6}}>
									<Card>
										<CardContent sx={{ py: 0, mt: "1vh", mb: "1vh" }}>
											<Stack
												direction="column"
												justifyContent="space-between"
												alignItems="flex-start"
												sx={{ height: "12vh", minHeight: 110 }}
											>
												<Typography
													variant="h6"
													component="div"
													sx={{
														color: theme.palette.grey[600],
														fontSize: "1.1rem !important",
													}}
												>
													N° Capi{" "}
													{periodi.find((p) => p.valore == periodo)?.periodo}
												</Typography>
												{indicatoriTotaliCommittenteTipiCapo
													.filter((tipoCapo) => tipoCapo.numero_capi > 0)
													.map((tipoCapo) => {
														return (
															// tipoCapo.numero_capi > 0 && (
															<Typography
																key={"key_" + tipoCapo.tipo_capo}
																variant="h5"
																sx={{
																	color: stringToColour(tipoCapo.tipo_capo),
																	fontSize: scalableSize,
																}}
															>
																{tipoCapo.tipo_capo}:{" "}
																{Intl.NumberFormat("it-IT").format(
																	tipoCapo.numero_capi
																)}
															</Typography>
															// )
														);
													})}
											</Stack>
										</CardContent>
									</Card>
								</Grid>
								<Grid size={{ xs:12, sm:6}}>
									<Card>
										<CardContent sx={{ py: 0, mt: "1vh", mb: "1vh" }}>
											<Stack
												direction="column"
												justifyContent="space-between"
												alignItems="flex-start"
												sx={{ height: "12vh", minHeight: 110 }}
											>
												<Typography
													variant="h6"
													component={Box}
													sx={{
														color: theme.palette.grey[600],
														fontSize: "1.1rem !important",
													}}
												>
													N° Lotti{" "}
													{periodi.find((p) => p.valore == periodo)?.periodo}
												</Typography>

												{indicatoriTotaliCommittenteTipiCapo.map(
													(tipoCapo) =>
														tipoCapo.numero_lotti > 0 && (
															<Typography
																key={"key_" + tipoCapo.tipo_capo}
																variant="h5"
																sx={{
																	color: stringToColour(tipoCapo.tipo_capo),
																	fontSize: scalableSize,
																}}
															>
																{tipoCapo.tipo_capo}:{" "}
																{Intl.NumberFormat("it-IT").format(
																	tipoCapo.numero_lotti
																)}
															</Typography>
														)
												)}
											</Stack>
										</CardContent>
									</Card>
								</Grid>
								<Grid size={{ xs:12, sm:6}}>
									<Card>
										<CardContent sx={{ py: 0, mt: "1vh", mb: "1vh" }}>
											<Stack
												direction="column"
												justifyContent="space-between"
												alignItems="flex-start"
												sx={{ height: "12vh", minHeight: 110 }}
											>
												<Typography
													variant="h6"
													component={Box}
													sx={{
														color: theme.palette.grey[600],
														fontSize: "1.1rem !important",
													}}
												>
													Kg vivo{" "}
													{periodi.find((p) => p.valore == periodo)?.periodo}
												</Typography>

												{indicatoriTotaliCommittenteTipiCapo.map(
													(tipoCapo) =>
														tipoCapo.kg_totali > 0 && (
															<Typography
																key={"key_" + tipoCapo.tipo_capo}
																variant="h5"
																sx={{
																	color: stringToColour(tipoCapo.tipo_capo),
																	fontSize: scalableSize,
																}}
															>
																{tipoCapo.tipo_capo}:{" "}
																{Intl.NumberFormat("it-IT").format(
																	tipoCapo.kg_totali
																)}
																{" Kg"}
															</Typography>
														)
												)}
											</Stack>
										</CardContent>
									</Card>
								</Grid>
								<Grid size={{ xs:12, sm:6}}>
									<Card>
										<CardContent sx={{ py: 0, mt: "1vh", mb: "1vh" }}>
											<Stack
												direction="column"
												justifyContent="space-between"
												alignItems="flex-start"
												sx={{ height: "12vh", minHeight: 110 }}
											>
												<Typography
													variant="h6"
													component={Box}
													sx={{
														color: theme.palette.grey[600],
														fontSize: "1.1rem !important",
													}}
												>
													Peso medio vivo{" "}
													{periodi.find((p) => p.valore == periodo)?.periodo}
												</Typography>
												{indicatoriTotaliCommittenteTipiCapo
													.filter((tipoCapo) => tipoCapo.numero_capi > 0)
													.map((tipoCapo) => {
														return (
															// tipoCapo.numero_capi > 0 && (
															<Typography
																key={"key_" + tipoCapo.tipo_capo}
																variant="h5"
																sx={{
																	color: stringToColour(tipoCapo.tipo_capo),
																	fontSize: scalableSize,
																}}
															>
																{tipoCapo.tipo_capo}:{" "}
																{Intl.NumberFormat("it-IT").format(
																	tipoCapo.kg_totali / tipoCapo.numero_capi
																)}
																{" Kg"}
															</Typography>
															// )
														);
													})}

												{/* {indicatoriTotaliCommittenteTipiCapo.map(
												(tipoCapo) =>
													tipoCapo.numero_capi > 0 && (
														<Typography
															key={"key_" + tipoCapo.tipo_capo}
															variant="h5"
															sx={{
																color: stringToColour(tipoCapo.tipo_capo),
																fontSize: scalableSize,
															}}
														>
															{tipoCapo.tipo_capo}:{" "}
															{Intl.NumberFormat("it-IT", {
																minimumFractionDigits: 1,
																maximumFractionDigits: 1,
															}).format(tipoCapo.lavorazioni_medie_lotto)}
														</Typography>
													)
											)} */}
											</Stack>
										</CardContent>
									</Card>
								</Grid>
							</Grid>
						)}
					</Grid>

					<Grid size={{ xs:12, sm:12, md:12, lg:8 }}>
						<Card>
							<CardContent>
								<Box sx={{ height: "28vh", minHeight: 250 }}>
									{graficiErrorsStack.status == ErrorStatusTypes.PENDING ? (
										<CircularProgress color="inherit" sx={{ m: 6 }} />
									) : (
										<CommittenteReseSettimaneGrafico
											graficoCommittenteReseSettimane={
												graficoCommittenteReseSettimane
											}
										/>
									)}
								</Box>

								<Typography
									variant="h5"
									component={Box}
									align="center"
									sx={{ fontSize: "1.25rem !important" }}
								>
									Rese per settimana{" "}
									{periodi.find((p) => p.valore == periodo)?.periodo}
								</Typography>
							</CardContent>
						</Card>
					</Grid>
					<Grid size={{ xs:12, sm:12, md:12, lg:12 }}>
						<Card>
							<CardContent>
								<Box sx={{ height: "28vh", minHeight: 254 }}>
									<CommittenteTipiCapoSettimanePesoMedioGrafico
										graficoCommittenteTipiCapoPesoMedioSettimana={
											graficoCommittenteTipiCapoPesoMedioSettimana
										}
									/>
								</Box>

								<Typography
									variant="h5"
									component={Box}
									align="center"
									sx={{ fontSize: "1.25rem !important" }}
								>
									peso medio per settimana
								</Typography>
							</CardContent>
						</Card>
					</Grid>
					<Grid size={{ xs:12, sm:12, md:12, lg:8 }}>
						<Card>
							<CardContent>
								<Box sx={{ height: "28vh", minHeight: 254 }}>
									<CommittenteTipiCapoMeseGrafico
										graficoCommittenteTipiCapoMese={
											graficoCommittenteTipiCapoMese
										}
									/>
								</Box>

								<Typography
									variant="h5"
									component={Box}
									align="center"
									sx={{ fontSize: "1.25rem !important" }}
								>
									Kg per mese per tipo capo
								</Typography>
							</CardContent>
						</Card>
					</Grid>

					<Grid size={{ xs:12, sm:12, md:6, lg:4 }}>
						<Card>
							<CardContent>
								<Box sx={{ height: "28vh", minHeight: 250 }}>
									{/* <ResaMediaGrafico /> */}
									<CommittenteResaMediaTipiCapoGrafico
										indicatoriTotaliCommittenteTipiCapo={
											indicatoriTotaliCommittenteTipiCapo
										}
									/>
								</Box>

								<Typography
									variant="h5"
									component={Box}
									align="center"
									sx={{ fontSize: "1.25rem !important" }}
								>
									Resa media del trimestre
								</Typography>
							</CardContent>
						</Card>
					</Grid>

					<Grid size={{ xs:12, sm:12, md:12, lg:12 }}>
						<Card>
							<CardContent>
								{committenteCorrente && (
									<LottiCommittenteBox
										committenteCorrente={committenteCorrente}
										numeroRecord={5}
									/>
								)}
								<CardActions>
									<Link
										to={{
											pathname: Routes_path.LOTTI_COMMITTENTE,
										}}
									>
										..vedi tutti..
									</Link>
								</CardActions>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
};

export default DashboardCommittentiPage;
