import React from "react";
import { useState, useEffect } from "react";
import { theme } from "../../../../theme";
import { Link } from "react-router-dom";

import {
	Resa,
	switchConfermaResa,
	getResa,
	saveResa,
} from "../../reportistica/rese/reseSlice";
import {
	CorrispettiviLotto,
	ListinoMacellazione,
	getCorrispettiviLotto,
	fetchListiniMacellazione,
	fetchListiniTrasportoVivo,
} from "../bolleFattureSlice";
import { RootState, useAppDispatch } from "../../../../redux/store";
import { useSelector } from "react-redux";

import {
	ErrorStatusTypes,
	ErrorsStack,
} from "../../../common/errorsDeclarations";
import LottoCorrispettiviLavorazioniCard from "./LottoCorrispettiviLavorazioniCard";
import LottoCorrispettiviMacellazioneCard from "./LottoCorrispettiviMacellazioneCard";
import LottoCorrispettiviTrasportoVivoCard from "./LottoCorrispettiviTrasportoVivoCard";
import LottoCorrispettiviListinoMacellazioneSelect from "./LottoCorrispettiviListinoMacellazioneSelect";
import LottoCorrispettiviListinoTrasportiVivoSelect from "./LottoCorrispettiviListinoTrasportiVivoSelect";
import Grid from "@mui/material/Grid2";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

interface LottoCorrispettiviCardProps {
	lotto_id: number;
}

const LottoCorrispettiviCard = ({ lotto_id }: LottoCorrispettiviCardProps) => {
	const dispatch = useAppDispatch();

	const corrispettiviLotto = useSelector<
		RootState,
		CorrispettiviLotto | undefined
	>((state) =>
		state.bolleFattureState.corrispettiviLotti.results.find(
			(corrispettiviLotto) => corrispettiviLotto.lotto_id == lotto_id
		)
	);

	const corrispettiviStatus = useSelector<
		RootState,
		ErrorStatusTypes | undefined
	>((state) => state.bolleFattureState.corrispettiviLotti.errorsStack.status);

	useEffect(() => {
		dispatch(fetchListiniMacellazione());
		dispatch(fetchListiniTrasportoVivo());
		dispatch(getCorrispettiviLotto(lotto_id));
	}, []);

	return (
		// <Card key="Box_corrispettiviLotto_card" sx={{ my: 1, p: 2 }}>
		<Box>
			<Backdrop
				sx={{
					color: "#fff",
					zIndex: (theme) => theme.zIndex.drawer + 1,
				}}
				open={corrispettiviStatus == ErrorStatusTypes.PENDING}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Grid container direction="row" justifyContent="flex-end">
				<Grid size={{ xs:8, sm:6, md:5, lg:4 }}>
					<Box sx={{ my: 1 }}>
						{corrispettiviLotto && (
							<LottoCorrispettiviListinoMacellazioneSelect
								corrispettiviLotto={corrispettiviLotto}
							/>
						)}
					</Box>
				</Grid>
			</Grid>
			{!!corrispettiviLotto?.costo_macellazione && (
				<Card
					key="Box_corrispettiviLotto_card_costo_macellazione"
					sx={{ mb: 3 }}
				>
					<CardContent>
						{corrispettiviLotto &&
						corrispettiviLotto.costi_di_lavorazione_list.length > 0 ? (
							<LottoCorrispettiviLavorazioniCard
								costi_di_lavorazione_list={
									corrispettiviLotto.costi_di_lavorazione_list
								}
							/>
						) : (
							<LottoCorrispettiviMacellazioneCard
								corrispettiviLotto={corrispettiviLotto}
							/>
						)}
						<Box sx={{ mt: 3 }}>
							<Grid container spacing={2}>
								<Grid size={{ xs:2, sm:2, md:2, lg:2 }}>
									<Typography variant="h6" gutterBottom>
										Totale:
									</Typography>
								</Grid>

								<Grid size={{ xs:10, sm:10, md:10, lg:10 }}>
									<Typography variant="h6" gutterBottom align="right">
										{Intl.NumberFormat("it-IT", {
											maximumFractionDigits: 2,
										}).format(corrispettiviLotto?.costo_macellazione || 0)}{" "}
										€
									</Typography>
								</Grid>
							</Grid>
						</Box>
					</CardContent>
				</Card>
			)}

			<Grid container direction="row" justifyContent="flex-end">
				<Grid size={{ xs:8, sm:6, md:5, lg:4 }}>
					<Box sx={{ my: 1 }}>
						{corrispettiviLotto && (
							<LottoCorrispettiviListinoTrasportiVivoSelect
								corrispettiviLotto={corrispettiviLotto}
							/>
						)}
					</Box>
				</Grid>
			</Grid>
			{corrispettiviLotto &&
				corrispettiviLotto.costi_di_trasporto_vivo_list.length > 0 && (
					<Card
						key="Box_corrispettiviLotto_card_trasporto_vivo"
						sx={{ my: 1, p: 2 }}
					>
						<CardContent>
							<LottoCorrispettiviTrasportoVivoCard
								costi_di_trasporto_vivo_list={
									corrispettiviLotto.costi_di_trasporto_vivo_list
								}
							/>
						</CardContent>
					</Card>
				)}
		</Box>
	);
};

export default LottoCorrispettiviCard;
