import React, { FC, useEffect, ReactElement } from "react";

import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import ReplayIcon from "@mui/icons-material/Replay";
import { DateTime } from "luxon";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DateRangeIcon from "@mui/icons-material/DateRange";
import CardMui from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { styled, Box } from "@mui/system";
import Button from "@mui/material/Button";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";

import { convertToDateTime } from "components/common/dateUtils/convertToDateTime";

const Card = styled(CardMui)(({ theme }) => ({
	// display: "flex:1",
	// "& > *": {
	margin: theme.spacing(1),
	align: "center",
	marginBottom: 1,
	// },
}));

interface NavigazioneSettimaneToolbarProps {
	titolo?: string;
	data?: DateTime | null;
	setData: (data: DateTime | null) => void;
	refresh: () => void;
	Toolbar?: FC;
}

const NavigazioneSettimaneToolbar = ({
	titolo,
	data,
	setData,
	refresh,
	Toolbar,
}: NavigazioneSettimaneToolbarProps) => {
	interface Settimana {
		numeroSettimana: string;
		dateSettimana: Date[];
	}

	const caricaSettimana = (data?: DateTime | null): Date[] => {
		// Se 'data' è fornito e non è null, usa 'data'. Altrimenti, usa la data corrente.
		const dataInizioSettimana = data
			? data.startOf("week").set({ weekday: 1 })
			: DateTime.now().startOf("week").set({ weekday: 1 });

		// Crea un array di 5 giorni a partire dall'inizio della settimana ISO
		const dateSettimana: Date[] = [...Array(5)].map((_, i) =>
			dataInizioSettimana.plus({ days: i }).toJSDate()
		);

		return dateSettimana;
	};

	const cambiaSettimana = (avanti: boolean) => {
		data &&
		(avanti
			? setData(data.plus({ weeks: 1 }))
			: setData(data.minus({ weeks: 1 })));
	};

	const [settimana, setSettimana] = React.useState<Settimana>({
		numeroSettimana: `${data?.weekNumber}`,
		dateSettimana: caricaSettimana(data),
	});
	useEffect(() => {
		const newSettimana = {
			numeroSettimana: `${data?.weekNumber}`,
			dateSettimana: caricaSettimana(data),
		};
		setSettimana(newSettimana);
	}, [data]);

	const [weekPickerView, setWeekPickerView] = React.useState<Boolean>(false);
	const changeData = (newValue: DateTime | null) => {
		setData(newValue);
	};

	return (
		<Card>
			<Stack
				direction="row"
				justifyContent="center"
				alignItems="center"
				sx={{ ml: 2 }}
				spacing={2}
			>
				<Typography variant="h5" component="div">
					{titolo}
				</Typography>

				<IconButton
					aria-label="SettimanaPrecedente"
					onClick={() => {
						cambiaSettimana(false);
					}}
				>
					<ArrowBackIosIcon />
				</IconButton>
				<Typography variant="subtitle1" component="div">
					{"Sett. "}
					{settimana.numeroSettimana}
					{" dal "}
					{convertToDateTime(settimana.dateSettimana.at(0))
						?.setLocale("it")
						.toFormat("dd/MM")}
					{" al "}
					{convertToDateTime(settimana.dateSettimana.at(-1))
						?.setLocale("it")
						.toFormat("dd/MM")}
				</Typography>
				<Button
					sx={{ p: 2, ml: 2, mr: 2 }}
					onClick={() => {
						setWeekPickerView(!weekPickerView);
					}}
				>
					<DateRangeIcon />
				</Button>
				<IconButton
					color="primary"
					aria-label="upload picture"
					component="span"
					onClick={refresh}
				>
					<ReplayIcon />
				</IconButton>
				<IconButton
					aria-label="SettimanaSuccessiva"
					onClick={() => {
						cambiaSettimana(true);
					}}
				>
					<ArrowForwardIosIcon />
				</IconButton>
				{weekPickerView && (
					<StaticDatePicker
						displayStaticWrapperAs="desktop"
						openTo="day"
						value={convertToDateTime(data)}
						onChange={(newValue: DateTime<boolean> | null) => {
							changeData(newValue);
						}}
					/>
				)}
				{Toolbar && <Toolbar />}
			</Stack>
		</Card>
		// 	{/* </Grid>
		// </Grid> */}
	);
};

export default NavigazioneSettimaneToolbar;
