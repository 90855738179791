import React from "react";
import { useEffect } from "react";

import {
	Committente,
	Produttore,
	Autista,
} from "../../anagrafiche/anagraficheSlice";

import { useForm, SubmitHandler, FieldError } from "react-hook-form";
import ReactHookFormSelect from "../../../common/ReactHookFormSelect";

import { UtenteEsterno, User } from "../ospitiSlice";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
// import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import { Button } from "@mui/material";
import Grid from "@mui/material/Grid2";

interface UtenteEsternoFormProps {
	utenteEsterno: UtenteEsterno;
	committenti: Committente[];
	produttori: Produttore[];
	autisti: Autista[];

	saveUtenteEsterno: (utenteEsterno: UtenteEsterno) => void;
}

const UtenteEsternoForm = ({
	utenteEsterno,
	committenti,
	produttori,
	autisti,
	saveUtenteEsterno,
}: UtenteEsternoFormProps) => {
	const {
		handleSubmit,
		register,
		formState: { errors, isDirty },
		setValue,
		watch,
		getValues,
		control,
		setError,
		clearErrors,
		reset,
	} = useForm<UtenteEsterno>();

	const listaCampi = Object.keys(utenteEsterno) as Array<keyof UtenteEsterno>;
	useEffect(() => {
		listaCampi.map((field) => {
			setValue(field, utenteEsterno[field]);
		});
	}, []);

	useEffect(() => {
		clearErrors();
		listaCampi.forEach((field) => {
			utenteEsterno?.errorsStack?.fieldsErrors &&
				utenteEsterno.errorsStack.fieldsErrors[field] &&
				setError(field, {
					type: "server",
					message: utenteEsterno.errorsStack.fieldsErrors[field].toString(),
				});
		});
	}, [utenteEsterno?.errorsStack?.fieldsErrors]);

	const onSubmit: SubmitHandler<UtenteEsterno> = (utenteEsterno) => {
		saveUtenteEsterno(utenteEsterno);
	};

	useEffect(() => {
		if (!utenteEsterno?.user?.username) {
			const nome = getValues("user.first_name");
			const cognome = getValues("user.last_name");
			nome &&
				cognome &&
				setValue("user.username", (nome + "." + cognome).toLowerCase());
		}
	}, [watch("user.first_name"), watch("user.last_name")]);

	// function instanceOfFieldError(object: any): object is FieldError {
	// 	return true;
	// }

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Grid
				container
				rowSpacing={1}
				sx={{ my: 1 }}
				columnSpacing={{ xs: 1 }}
				justifyContent="center"
				// alignItems="flex-start"
			>
				{/* --{instanceOfUser(errors?.user)}-- */}
				<Grid size={{ xs:4, sm:4, md:4, lg:4 }}>
					<TextField
						id="user.username"
						label="user name"
						{...register("user.username")}
						// error={
						// 	!!(!instanceOfFieldError(errors?.user) && errors?.user?.username)
						// }
						// // error={!!(instanceOfUser(errors?.user) && errors?.user?.username)}
						// helperText={
						// 	!instanceOfFieldError(errors?.user) &&
						// 	utenteEsterno?.errorsStack?.fieldsErrors &&
						// 	utenteEsterno.errorsStack.fieldsErrors["user.username"] &&
						// 	utenteEsterno.errorsStack.fieldsErrors["user.username"].toString()
						// }
						disabled={true}
						InputLabelProps={{
							shrink: true,
						}}
						variant="outlined"
						autoComplete={"off"}
						sx={{ width: "100%", bgcolor: "white" }}
					/>
				</Grid>
				<Grid size={{ xs:4, sm:4, md:4, lg:4 }}>
					<TextField
						id="user.first_name"
						label="Nome"
						{...register("user.first_name")}
						// error={!!errors?.user.first_name}
						// helperText={errors?.user.first_name?.message}
						required={true}
						InputLabelProps={{
							shrink: true,
						}}
						variant="outlined"
						autoComplete={"off"}
						sx={{ width: "100%", bgcolor: "white" }}
					/>
				</Grid>
				<Grid size={{ xs:4, sm:4, md:4, lg:4 }}>
					<TextField
						id="user.last_name"
						label="Cognome"
						{...register("user.last_name")}
						// error={!!errors?.user.last_name}
						// helperText={errors?.user.last_name?.message}
						required={true}
						InputLabelProps={{
							shrink: true,
						}}
						variant="outlined"
						autoComplete={"off"}
						sx={{ width: "100%", bgcolor: "white" }}
					/>
				</Grid>
				<Grid size={{ xs:4, sm:4, md:4, lg:4 }}></Grid>

				<Grid size={{ xs:8, sm:8, md:8, lg:8 }}>
					<TextField
						id="user.email"
						label="user.email"
						{...register("user.email", {
							required: "Campo obbligatorio",
							pattern: {
								value: /\S+@\S+\.\S+/,
								message: "valore dell'email non valido",
							},
						})}
						// error={!!errors?.user.email}
						// helperText={errors?.user.email?.message}
						InputLabelProps={{
							shrink: true,
						}}
						required={true}
						variant="outlined"
						autoComplete={"off"}
						sx={{ width: "100%", bgcolor: "white" }}
					/>
				</Grid>
				<Grid size={{ xs:4, sm:4, md:4, lg:4 }}>
					<ReactHookFormSelect
						name="committente"
						label="committente"
						register={register}
						control={control}
						watch={watch}
						variant="outlined"
						// required={true}
						// multiple={true}
						// error={errors?.committenti?.message}
						sx={{ width: "100%" }}
					>
						<MenuItem key="0000" value={undefined}>
							--
						</MenuItem>
						{committenti.map((option) => {
							return (
								<MenuItem key={option.id} value={option.id}>
									{option.nome}
								</MenuItem>
							);
						})}
					</ReactHookFormSelect>
				</Grid>
				<Grid size={{ xs:4, sm:4, md:4, lg:4 }}>
					<ReactHookFormSelect
						name="produttore"
						label="produttore"
						register={register}
						control={control}
						watch={watch}
						variant="outlined"
						// required={true}
						// multiple={true}
						// error={errors?.committenti?.message}
						sx={{ width: "100%" }}
					>
						<MenuItem key="0000" value={undefined}>
							--
						</MenuItem>

						{produttori.map((option) => {
							return (
								<MenuItem key={option.id} value={option.id}>
									{option.nome}
								</MenuItem>
							);
						})}
					</ReactHookFormSelect>
				</Grid>
				<Grid size={{ xs:4, sm:4, md:4, lg:4 }}>
					<ReactHookFormSelect
						name="autista"
						label="autista"
						register={register}
						control={control}
						watch={watch}
						variant="outlined"
						// required={true}
						// multiple={true}
						// error={errors?.committenti?.message}
						sx={{ width: "100%" }}
					>
						<MenuItem key="0000" value={undefined}>
							--
						</MenuItem>

						{autisti.map((option) => {
							return (
								<MenuItem key={option.id} value={option.id}>
									{`${option.nome} ${option.cognome}`}
								</MenuItem>
							);
						})}
					</ReactHookFormSelect>
				</Grid>
				<Grid size={{ xs:12 }}>
					<Stack direction="row" spacing={2} justifyContent="center">
						<Button disabled={!isDirty} variant="contained" type="submit">
							Salva
						</Button>
					</Stack>
				</Grid>
			</Grid>
		</form>
	);
};

export default UtenteEsternoForm;
