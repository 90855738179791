import { handleResponse, handleError, headerset } from "../apiUtils";
import { DateTime } from 'luxon';
import {convertToDateTime} from "../../components/common/dateUtils/convertToDateTime";

const baseUrl = (autista_id?: number, data_conteggio?: Date | string) => {
  let url: string = process.env.API_URL + "/api/anagrafiche/autisti/";

  if (autista_id) {
    url += +autista_id + "/";
  }
  if (data_conteggio) {
    url += `?data_conteggio=${convertToDateTime(data_conteggio)?.setLocale("it").toFormat("yyyy-MM-dd")}`;
  }
  return url;
};

export function fetchAutisti(data_conteggio?: Date | string) {
  return fetch(baseUrl(undefined, data_conteggio), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: headerset().Authorization,
    },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getAutista(autista_id: number, data_conteggio?: Date) {
  let url = baseUrl(autista_id, data_conteggio);
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: headerset().Authorization,
    },
  })
    .then(handleResponse)
    .catch(handleError);
}
