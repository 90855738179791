import React from "react";
import {Link} from "react-router-dom";
import {Routes_path} from "../../../../routerApp";
import {LottoCommittente} from "../../ospitiSlice";
import Grid from "@mui/material/Grid2";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import {convertToDateTime} from "../../../../common/dateUtils/convertToDateTime";
import {Box} from "@mui/system";

const LottiProduttoreCard = ({lotto}: LottiProduttoreCardProps) => {
    return (
        <Box key={"Box_lottiProduttore_page_" + lotto.id}
             component={Link}
             to={Routes_path.LOTTO_PRODUTTORE + lotto.id}
             sx={{fontStyle: "normal", textDecoration: "none", color: "inherit"}}
        >
            <Grid container sx={{m: 1}}>
                <Grid size={{xs: 12, sm: 12, md: 12, lg: 7}}>
                    <Grid container>
                        <Grid size={{xs: 3, sm: 3, md: 3, lg: 3}}>
                            <Stack
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                spacing={2}
                                sx={{mb: 1}}
                            >
                                <Chip
                                    label={lotto.codice_tracciabilita || "________"}
                                    color={"primary"}
                                    variant="outlined"
                                    sx={{
                                        backgroundColor: lotto.resa_confermata
                                            ? "rgba(88, 237, 92, 0.2)"
                                            : "background.paper",
                                        borderColor: lotto.in_dubbio
                                            ? "rgba(250, 250, 250, 0)"
                                            : lotto.stato_lotto.stato == 4
                                                ? "red"
                                                : lotto.stato_lotto.stato == 3
                                                    ? "lightgreen"
                                                    : lotto.stato_lotto.stato == 2
                                                        ? "rgba(91, 188, 247, 1)"
                                                        : lotto.stato_lotto.stato == 1
                                                            ? "#ffdc10"
                                                            : "#999",
                                    }}
                                />
                                <Typography variant="body1" component="div">
                                    {convertToDateTime(lotto.data_lavorazione)?.setLocale("it").toFormat('ccc dd LLL yyyy')}
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid size={{xs: 2, sm: 2, md: 2, lg: 2}}>
                            <Stack
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                spacing={2}
                                sx={{mb: 1}}
                            >
                                <Typography variant="h6" component="div">
                                    {
                                        lotto.numero_capi + " " + lotto.tipo_capo_desc
                                        // +" " + getColore(lotto)
                                    }
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid size={{xs: 4, sm: 4, md: 3, lg: 3}}>
                            <Typography variant="body1" component="div">
                                {lotto.committente_desc}
                            </Typography>
                        </Grid>
                        <Grid size={{xs: 3, sm: 3, md: 3, lg: 3}}>
                            <Typography variant="body1" component="div">
                                {lotto.allevamento_pack?.nome} (
                                {lotto.allevamento_pack?.comune})
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid size={{xs: 12, sm: 12, md: 12, lg: 5}}>
                    <Grid container justifyContent="flex-end">
                        <Grid size={{xs: 3, sm: 3, md: 3, lg: 3}} justifyContent="flex-end">
                            <Typography variant="body1" component="div" align="right">
                                {Intl.NumberFormat("it-IT", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                }).format(lotto.peso_medio || 0) + " kg"}
                            </Typography>
                        </Grid>
                        <Grid size={{xs: 3, sm: 3, md: 3, lg: 3}}>
                            <Typography variant="body1" component="div" align="right">
                                {Intl.NumberFormat("it-IT").format(lotto.capi_ritirati || 0)}
                            </Typography>
                        </Grid>
                        <Grid size={{xs: 3, sm: 3, md: 3, lg: 3}} justifyContent="flex-end">
                            <Typography variant="body1" component="div" align="right">
                                {Intl.NumberFormat("it-IT", {
                                    maximumFractionDigits: 0,
                                }).format(
                                    (lotto.peso_casse_busto_lotto || 0) +
                                    (lotto.peso_casse_tz_lotto || 0)
                                ) + " kg"}
                            </Typography>
                        </Grid>
                        <Grid size={{xs: 3, sm: 3, md: 3, lg: 3}} justifyContent="flex-end">
                            <Typography variant="body1" component="div" align="right">
                                {Intl.NumberFormat("it-IT").format(
                                    (lotto.num_busti_lotto || 0) +
                                    (lotto.num_tz_lotto || 0) +
                                    (lotto.numero_scarti_2 || 0)
                                )}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Divider/>
        </Box>
    );
};

interface LottiProduttoreCardProps {
    lotto: LottoCommittente;
}

export default LottiProduttoreCard;
