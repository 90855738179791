import {createSlice, createAsyncThunk, PayloadAction} from "@reduxjs/toolkit";
import {
    ErrorStatusTypes,
    ErrorsStack,
    parseErrorMessage,
} from "../../common/errorsDeclarations";

import {Articolo} from "../anagrafiche/anagraficheSlice";
import {getLotto} from "../pianificazione/pianificazioneSlice";
import * as lavorazioniApi from "../../../api/lavorazioni/lavorazioniApi";
import {DateTime} from 'luxon';
import {toast} from "react-toastify";


export enum TipoMacellazioneTypes {
    BT = "Busto",
    SB = "Semil.Busto",
    TZ = "TZ pieni",
    TE = "TZ eviscerati",
}

export enum PettorinaTypes {
    S = "Sigillo",
    E = "Sigillo + Pettorina",
    A = "Pettorina adesiva",
}

export const DimensioneCassettaTypes = {4: "40x30", 6: "60x40"};

export enum MaterialeCassettaTypes {
    P = "Plastica",
    C = "Cartone",
}

export enum MaterialeSottoTypes {
    C = "Carta",
    M = "Microforato",
    R = "Cartene",
}

export enum MaterialeSopraTypes {
    C = "Cartene",
}

export enum DisposizioneBilanciaTypes {
    SO = "solo se ordinato",
    SP = "sempre presente",
    CO = "in coda",
}

export interface Lavorazione {
    id: number;
    numero_cassette_richieste?: number;
    codice_articolo_committente?: string;
    descrizione?: string;
    lotto: number;
    lavorazione_committente: number;
    articolo?: Articolo;
    descrizione_articolo?: string;
    pettorina?: string;
    pettorina_desc?: string;
    dimensione_cassetta?: string;
    etichetta_su_cassetta?: boolean;
    etichetta_su_pallet?: boolean;
    etichetta_cassette?: number;
    etichetta_pallet?: number;
    materiale_cassetta?: string;
    materiale_sotto?: string;
    materiale_sopra?: string;
    destinazione?: string;
    disposizione_bilancia?: string;
    tara?: number;
    da_pallettizzare?: boolean;
    cassette_x_pallet?: number;
    numero_cassette_prodotte: number;
    numero_cassette_residue: number;
    peso_cassette_prodotte: number;
    peso_cassette_residue: number;
    note?: string;
    errorsStack?: ErrorsStack;
}

export interface Lavorazioni {
    count: number;
    page: number;
    num_pages: number;
    next?: URL;
    previous?: URL;
    results: Lavorazione[];
    errorsStack: ErrorsStack;
}

export interface LavorazioneCommittente {
    id?: number;
    codice_articolo_committente?: string;
    descrizione?: string;
    committente?: number;
    tipo_capo?: number;
    articolo?: number;
    pettorina?: string;
    dimensione_cassetta?: number;
    etichetta_su_cassetta?: boolean;
    etichetta_su_pallet?: boolean;
    etichetta_cassette?: number;
    etichetta_pallet?: number;
    da_pallettizzare?: boolean;
    cassette_x_pallet?: number;
    velocita_bilancia?: "V" | "L";
    codiciAI?: number;
    materiale_cassetta?: string;
    materiale_sotto?: string;
    materiale_sopra?: string;
    destinazione?: string;
    disposizione_bilancia?: string;
    tara?: number;
    deleted: boolean;
    errorsStack?: ErrorsStack;
}

export interface LavorazioniStrutturaState {
    lavorazioni: Lavorazioni;
    lavorazioneCommittenteCorrente: LavorazioneCommittente;
}

const initialState: LavorazioniStrutturaState = {
    lavorazioni: {
        count: 0,
        page: 0,
        num_pages: 0,
        next: undefined,
        previous: undefined,
        results: [],
        errorsStack: {status: ErrorStatusTypes.OK},
    },
    lavorazioneCommittenteCorrente: {
        id: undefined,
        codice_articolo_committente: undefined,
        descrizione: undefined,
        committente: undefined,
        tipo_capo: undefined,
        articolo: undefined,
        pettorina: undefined,
        dimensione_cassetta: undefined,
        etichetta_su_cassetta: true,
        etichetta_su_pallet: true,
        materiale_cassetta: undefined,
        materiale_sotto: undefined,
        materiale_sopra: undefined,
        destinazione: undefined,
        deleted: false,
        errorsStack: {status: ErrorStatusTypes.OK},
    },
};

export const fetchLavorazioni = createAsyncThunk(
    "lavorazioni/fetchLavorazioni",
    async (lottoId: number) => {
        return await lavorazioniApi.fetchLavorazioni(lottoId);
    }
);

export const getLavorazione = createAsyncThunk(
    "lavorazioni/getLavorazione",
    async (parametri: { lavorazioneId: number }) => {
        return await lavorazioniApi.getLavorazione(
            parametri.lavorazioneId
        );
    }
);

export const saveLavorazione = createAsyncThunk(
    "lavorazioni/saveLavorazione",
    async (
        parametri: { lottoId: number; lavorazioneToSave: Lavorazione },
        thunkApi
    ) => {
        return await lavorazioniApi
            .saveLavorazione(parametri.lavorazioneToSave)
            .then((response) => {
                thunkApi.dispatch(getLotto(parametri.lottoId));
                return response;
            });
    }
);

export const deleteLavorazione = createAsyncThunk(
    "lavorazioni/deleteLavorazione",
    async (parametri: { lavorazioneToDelete: Lavorazione }) => {
        return await lavorazioniApi.deleteLavorazione(
            parametri.lavorazioneToDelete
        );
    }
);

// ################################################################################
// ##########    LAVORAZIONI COMMITTENTE              #############################
// ################################################################################
export const saveLavorazioneCommittente = createAsyncThunk(
    "lavorazioni/saveLavorazioneCommittente",
    async (
        parametri: {
            lottoId: number;
            lavorazioneCommittenteToSave: LavorazioneCommittente;
        },
        thunkApi
    ) => {
        return await lavorazioniApi
            .saveLavorazioneCommittente(
                parametri.lottoId,
                parametri.lavorazioneCommittenteToSave
            )
            .then((response) => {
                thunkApi.dispatch(fetchLavorazioni(parametri.lottoId));
                return response;
            });
    }
);

export const getLavorazioneCommittente = createAsyncThunk(
    "lavorazioni/getLavorazioneCommittente",
    async (parametri: { lottoId: number; lavorazioneCommittente_id: number }) => {
        return await lavorazioniApi.getLavorazioneCommittente(
            parametri.lottoId,
            parametri.lavorazioneCommittente_id
        );
    }
);

export const deleteLavorazioneCommittente = createAsyncThunk(
    "lavorazioni/deleteLavorazioneCommittente",
    async (
        parametri: {
            lottoId: number;
            lavorazioneCommittenteToDelete: LavorazioneCommittente;
        },
        thunkApi
    ) => {
        return await lavorazioniApi
            .deleteLavorazioneCommittente(
                parametri.lottoId,
                parametri.lavorazioneCommittenteToDelete
            )
            .then((response) => {
                thunkApi.dispatch(fetchLavorazioni(parametri.lottoId));
                thunkApi.dispatch(resetLavorazioneCommittenteCorrente());
                return response;
            });
    }
);

export const lavorazioniSlice = createSlice({
    name: "lavorazioniState",
    initialState,
    reducers: {
        resetLavorazioneCommittenteCorrente: (state) => {
            state.lavorazioneCommittenteCorrente = {
                id: undefined,
                codice_articolo_committente: "",
                descrizione: "",
                committente: undefined,
                tipo_capo: undefined,
                articolo: undefined,
                pettorina: "",
                dimensione_cassetta: undefined,
                etichetta_su_cassetta: true,
                etichetta_su_pallet: true,
                materiale_cassetta: "",
                materiale_sotto: "",
                materiale_sopra: "",
                destinazione: "",
                deleted: false,
            };
        },

        setLavorazioneCommittenteCorrente: (
            state,
            action: PayloadAction<{
                lavorazioneCommittente: LavorazioneCommittente;
            }>
        ) => {
            state.lavorazioneCommittenteCorrente =
                action.payload.lavorazioneCommittente;
        },
    },
    extraReducers: (builder) => {
        // fetch Lavorazioni
        builder.addCase(fetchLavorazioni.pending, (state, action) => {
            state.lavorazioni.errorsStack = {status: ErrorStatusTypes.PENDING};
        });
        builder.addCase(fetchLavorazioni.fulfilled, (state, action) => {
            state.lavorazioni = action.payload;

            state.lavorazioni.errorsStack = {status: ErrorStatusTypes.OK};
        });
        builder.addCase(fetchLavorazioni.rejected, (state, action) => {
            toast.error("Errore:" + action?.error?.message || "");
            state.lavorazioni.errorsStack = parseErrorMessage(action.error);
        });

        // get Lavorazione
        builder.addCase(getLavorazione.pending, (state, action) => {
            state.lavorazioni.errorsStack = {status: ErrorStatusTypes.PENDING};
        });
        builder.addCase(getLavorazione.fulfilled, (state, action) => {
            state.lavorazioni.results = state.lavorazioni.results.filter(lavorazione => lavorazione.id != action.payload.id)
            state.lavorazioni.results.push(action.payload);

            // state.lavorazioni.results = state.lavorazioni.results.map(
            //     (lavorazione) => {
            //         if (lavorazione.id == action.payload.id) {
            //             return action.payload;
            //         } else {
            //             return lavorazione;
            //         }
            //     }
            // );
            state.lavorazioni.errorsStack = {status: ErrorStatusTypes.OK};
        });
        builder.addCase(getLavorazione.rejected, (state, action) => {
            toast.error("Errore:" + action?.error?.message || "");
            state.lavorazioni.errorsStack = parseErrorMessage(action.error);
        });

        // save Lavorazione
        builder.addCase(saveLavorazione.pending, (state, action) => {
            state.lavorazioni.errorsStack = {status: ErrorStatusTypes.PENDING};
        });
        builder.addCase(saveLavorazione.fulfilled, (state, action) => {
            state.lavorazioni.results = state.lavorazioni.results.map(
                (lavorazione) => {
                    if (lavorazione.id == action.payload.id) {
                        return action.payload;
                    } else {
                        return lavorazione;
                    }
                }
            );
            state.lavorazioni.errorsStack = {status: ErrorStatusTypes.SUCCESS};
            toast.success("Lavorazione salvata.");
        });
        builder.addCase(saveLavorazione.rejected, (state, action) => {
            toast.error("Errore:" + action?.error?.message || "", {
                autoClose: false,
            });

            state.lavorazioni.results = state.lavorazioni.results.map(
                (lavorazione) => {
                    if (lavorazione.id == action.meta.arg.lavorazioneToSave.id) {
                        return {
                            ...lavorazione,
                            errorsStack: {
                                status: ErrorStatusTypes.ERROR,
                                fieldsErrors: JSON.parse(action?.error?.message || ""),
                            },
                        };
                    } else {
                        return lavorazione;
                    }
                }
            );
            state.lavorazioni.errorsStack = {
                status: ErrorStatusTypes.ERROR,
            };
        });
        // cancella Lavorazione
        builder.addCase(deleteLavorazione.pending, (state, action) => {
            state.lavorazioni.errorsStack = {status: ErrorStatusTypes.PENDING};
        });
        builder.addCase(deleteLavorazione.fulfilled, (state, action) => {
            state.lavorazioni.errorsStack = {status: ErrorStatusTypes.SUCCESS};
            state.lavorazioni.results = state.lavorazioni.results.filter(
                (lavorazione) =>
                    lavorazione.id != action.meta.arg.lavorazioneToDelete.id
            );
            toast.success(action.payload.message || "Lavorazione cancellata.");
        });
        builder.addCase(deleteLavorazione.rejected, (state, action) => {
            toast.error("Errore:" + action?.error?.message || "");
            state.lavorazioni.errorsStack = parseErrorMessage(action.error);
        });

        // ################################################################################
        // ##########    LAVORAZIONI COMMITTENTE              #############################
        // ################################################################################

        // saveLavorazioneCommittente
        builder.addCase(getLavorazioneCommittente.pending, (state, action) => {
            state.lavorazioneCommittenteCorrente.errorsStack = {
                status: ErrorStatusTypes.PENDING,
            };
        });
        builder.addCase(getLavorazioneCommittente.fulfilled, (state, action) => {
            state.lavorazioneCommittenteCorrente = action.payload;
            state.lavorazioni.errorsStack = {status: ErrorStatusTypes.OK};
        });
        builder.addCase(getLavorazioneCommittente.rejected, (state, action) => {
            toast.error("Errore:" + action?.error?.message || "");
            state.lavorazioneCommittenteCorrente.errorsStack = parseErrorMessage(
                action.error
            );
        });

        // saveLavorazioneCommittente
        builder.addCase(saveLavorazioneCommittente.pending, (state, action) => {
            state.lavorazioneCommittenteCorrente.errorsStack = {
                status: ErrorStatusTypes.PENDING,
            };
        });
        builder.addCase(saveLavorazioneCommittente.fulfilled, (state, action) => {
            // state.lavorazioneCommittenteCorrente = action.payload;
            state.lavorazioneCommittenteCorrente = {
                id: undefined,
                codice_articolo_committente: "",
                descrizione: "",
                committente: action.payload.committente,
                tipo_capo: action.payload.tipo_capo,
                articolo: undefined,
                pettorina: "",
                dimensione_cassetta: undefined,
                etichetta_su_cassetta: true,
                etichetta_su_pallet: true,
                materiale_cassetta: "",
                materiale_sotto: "",
                materiale_sopra: "",
                destinazione: "",
                deleted: false,
                errorsStack: {status: ErrorStatusTypes.SUCCESS},
            };

            toast.success("Lavorazione Committente salvata.");
        });
        builder.addCase(saveLavorazioneCommittente.rejected, (state, action) => {
            toast.error("Errore:" + action?.error?.message || "");
            state.lavorazioneCommittenteCorrente.errorsStack = parseErrorMessage(
                action.error
            );
        });

        // cancella Lavorazione
        builder.addCase(deleteLavorazioneCommittente.pending, (state, action) => {
            state.lavorazioneCommittenteCorrente.errorsStack = {
                status: ErrorStatusTypes.PENDING,
            };
        });
        builder.addCase(deleteLavorazioneCommittente.fulfilled, (state, action) => {
            state.lavorazioneCommittenteCorrente = {
                id: undefined,
                codice_articolo_committente: "",
                descrizione: "",
                committente: action.payload.committente,
                tipo_capo: action.payload.tipo_capo,
                articolo: undefined,
                pettorina: "",
                dimensione_cassetta: undefined,
                etichetta_su_cassetta: true,
                etichetta_su_pallet: true,
                materiale_cassetta: "",
                materiale_sotto: "",
                materiale_sopra: "",
                destinazione: "",
                deleted: false,
                errorsStack: {status: ErrorStatusTypes.SUCCESS},
            };

            state.lavorazioneCommittenteCorrente.errorsStack = {
                status: ErrorStatusTypes.SUCCESS,
            };
            // state.lavorazioni.results = state.lavorazioni.results.filter(
            //   (lavorazione) =>
            //     lavorazione.id != action.meta.arg.lavorazioneToDelete.id
            // );
            toast.success(action.payload.message || "Lavorazione cancellata.");
        });
        builder.addCase(deleteLavorazioneCommittente.rejected, (state, action) => {
            toast.error("Errore:" + action?.error?.message || "");
            state.lavorazioneCommittenteCorrente.errorsStack = parseErrorMessage(
                action.error
            );
        });
    },
});

// Action creators are generated for each case reducer function
export const {
    resetLavorazioneCommittenteCorrente,
    setLavorazioneCommittenteCorrente,
} = lavorazioniSlice.actions;

export const lavorazioniSliceReducer = lavorazioniSlice.reducer;
