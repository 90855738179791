import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import * as ospitiApi from "../../../api/ospiti/ospitiApi";
import { toast } from "react-toastify";
import {
	ErrorStatusTypes,
	ErrorsStack,
	parseErrorMessage,
} from "../../common/errorsDeclarations";
import { DateTime } from 'luxon';
import { Lotto } from "../pianificazione/pianificazioneSlice";
import { Allevamento } from "../anagrafiche/anagraficheSlice";
import { SankeyLink } from "recharts/types/util/types";
import {convertToDateTime} from "../../common/dateUtils/convertToDateTime";
export interface User {
	id: number;
	username: string;
	first_name?: string;
	last_name?: string;
	email: string;
	last_login?: string;
}

export interface UtenteEsterno {
	id: number | null;
	user: User | null;
	committente?: number | null;
	produttore?: number | null;
	autista?: number | null;
	solo_lettura: boolean;
	errorsStack: ErrorsStack;
}

export interface UtentiEsterni {
	count: number;
	page: number;
	num_pages: number;
	next?: URL;
	previous?: URL;
	results: UtenteEsterno[];
	errorsStack: ErrorsStack;
}

type LottoRidotto = Omit<
	Lotto,
	// | "stato_lotto"
	// | "committente"
	// | "committente_desc"
	| "produttore"
	| "produttore_pack"
	| "m4_inviato"
	// | "in_dubbio"
	| "note_dubbio"
	| "calcolo_codice_tracciabilita_automatico"
	| "tipo_capo"
	// | "peso_medio"
	// | "colore"
	| "capi_schedulati"
	// | "capi_ritirati"
	| "numero_capi_foro"
	| "numero_fori"
	| "fori_schedulati"
	| "peso_medio_lotto_arrivo"
	| "allevamento"
	| "note"
	| "schede_carico"
	| "consuntivi_vivo"
	| "lavorazioni_lotto"
	| "num_tz_pianificati_lotto"
	| "num_tz_eviscerati_pianificati_lotto"
	| "scheda_macello"
>;

export interface LottoCommittente extends LottoRidotto {
	produttore_desc: string;
	numero_capi_morti?: number | null;
	numero_scarti_1?: number | null;
	numero_scarti_2?: number | null;
	num_busti_lotto?: number | null;
	peso_casse_busto_lotto?: number | null;
	num_tz_lotto?: number | null;
	peso_casse_tz_lotto?: number | null;
	// percentuale_resa?: number | null;
	errorsStack: ErrorsStack;
}

export interface LottiCommittenteProduttore {
	count: number;
	page: number;
	num_pages: number;
	next?: URL;
	previous?: URL;
	results: LottoCommittente[];
	errorsStack: ErrorsStack;
}
export interface AllevamentoCommittente extends Allevamento {
	resa_media?: number;
	numero_lotti?: number;
	errorsStack: ErrorsStack;
}

export interface AllevamentiCommittente {
	count: number;
	page: number;
	num_pages: number;
	next?: URL;
	previous?: URL;
	results: AllevamentoCommittente[];
	errorsStack: ErrorsStack;
}

export interface OspitiStrutturaState {
	utentiEsterni: UtentiEsterni;
	utenteEsterno_id: number | null;
	lottiCommittenteProduttore: LottiCommittenteProduttore;
	allevamentiCommittente: AllevamentiCommittente;
}

const initialState: OspitiStrutturaState = {
	utentiEsterni: {
		count: 0,
		page: 0,
		num_pages: 0,
		next: undefined,
		previous: undefined,
		results: [],
		errorsStack: { status: ErrorStatusTypes.OK },
	},
	utenteEsterno_id: null,
	lottiCommittenteProduttore: {
		count: 0,
		page: 0,
		num_pages: 0,
		next: undefined,
		previous: undefined,
		results: [],
		errorsStack: { status: ErrorStatusTypes.OK, saving: false },
	},
	allevamentiCommittente: {
		count: 0,
		page: 0,
		num_pages: 0,
		next: undefined,
		previous: undefined,
		results: [],
		errorsStack: { status: ErrorStatusTypes.OK, saving: false },
	},
};

export const fetchUtentiEsterni = createAsyncThunk(
	"ospiti/fetchUtentiEsterni",
	async (dataRange?: {
		data_da?: Date;
		data_a?: Date;
		lotti_semplificati?: boolean;
	}) => {
		return await ospitiApi.fetchUtentiEsterni();
	}
);

export const getUtenteEsterno = createAsyncThunk(
	"ospiti/getUtenteEsterno",
	async (utenteEsterno_id: number) => {
		return await ospitiApi.getUtenteEsterno(utenteEsterno_id);
	}
);

export const saveUtenteEsterno = createAsyncThunk(
	"ospiti/saveUtenteEsterno",
	async (utenteEsternoToSave: UtenteEsterno, thunkApi) => {
		return await ospitiApi.saveUtenteEsterno(utenteEsternoToSave);
	}
);

export const deleteUtenteEsterno = createAsyncThunk(
	"ospiti/deleteUtenteEsterno",
	async (utenteEsternoToDelete: UtenteEsterno, thunkApi) => {
		return await ospitiApi.deleteUtenteEsterno(utenteEsternoToDelete);
	}
);

export const invitaUtenteEsterno = createAsyncThunk(
	"ospiti/invitaUtenteEsterno",
	async (utenteEsterno: UtenteEsterno, thunkApi) => {
		return await ospitiApi.invitaUtenteEsterno(utenteEsterno);
	}
);

export const fetchLottiCommittente = createAsyncThunk(
	"ospiti/fetchLottiCommittente",
	async (parametri: {
		committente_id: number;
		numeroRecord?: number;
		page?: number;
	}) => {
		return await ospitiApi.fetchLottiCommittente(
			parametri.committente_id,
			parametri.numeroRecord,
			parametri.page
		);
	}
);

export const fetchAllevamentiCommittente = createAsyncThunk(
	"ospiti/fetchAllevamentiCommittente",
	async (parametri: {
		committente_id: number;
		numeroRecord?: number;
		page?: number;
	}) => {
		return await ospitiApi.fetchAllevamentiCommittente(
			parametri.committente_id,
			parametri.numeroRecord,
			parametri.page
		);
	}
);

// produttori

export const fetchLottiProduttore = createAsyncThunk(
	"ospiti/fetchLottiProduttore",
	async (parametri: {
		produttore_id: number;
		numeroRecord?: number;
		page?: number;
	}) => {
		return await ospitiApi.fetchLottiProduttore(
			parametri.produttore_id,
			parametri.numeroRecord,
			parametri.page
		);
	}
);

export const ospitiSlice = createSlice({
	name: "ospiti",
	initialState,
	reducers: {
		setUtenteCorrente: (
			state,
			action: PayloadAction<{
				utenteEsterno_id: number | null;
			}>
		) => {
			state.utenteEsterno_id = action.payload.utenteEsterno_id;
		},
	},
	extraReducers: (builder) => {
		// fetch utenti esterni
		builder.addCase(fetchUtentiEsterni.pending, (state, action) => {
			state.utentiEsterni.errorsStack.status = ErrorStatusTypes.PENDING;
		});
		builder.addCase(fetchUtentiEsterni.fulfilled, (state, action) => {
			// state.lotti = action.payload;
			state.utentiEsterni = action.payload;
			state.utentiEsterni.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(fetchUtentiEsterni.rejected, (state, action) => {
			state.utentiEsterni.errorsStack = parseErrorMessage(action.error);
		});

		// get utente esterno
		builder.addCase(getUtenteEsterno.pending, (state, action) => {
			state.utentiEsterni.errorsStack.status = ErrorStatusTypes.PENDING;
		});
		builder.addCase(getUtenteEsterno.fulfilled, (state, action) => {
			state.utentiEsterni.errorsStack.status = ErrorStatusTypes.OK;
			let prima_assente: boolean = true;
			state.utentiEsterni.results = state.utentiEsterni.results.map(
				(documentoDiTrasporto) => {
					if (documentoDiTrasporto.id == action.payload.id) {
						// return action.payload;
						prima_assente = false;
						return {
							...action.payload,
							data_ora_trasporto: convertToDateTime(action.payload.user.last_login)?.toISO(),
							errorsStack: {
								status: ErrorStatusTypes.OK,
								fieldsErrors: undefined,
							},
						};
					} else {
						return documentoDiTrasporto;
					}
				}
			);
			if (prima_assente) {
				state.utentiEsterni.results = [
					...state.utentiEsterni.results,
					action.payload,
				];
			}
		});
		builder.addCase(getUtenteEsterno.rejected, (state, action) => {
			state.utentiEsterni.errorsStack = parseErrorMessage(action.error);
		});

		// save documentoDiTrasporto
		builder.addCase(saveUtenteEsterno.pending, (state, action) => {
			state.utentiEsterni.errorsStack = {
				status: ErrorStatusTypes.PENDING,
				saving: true,
			};
		});

		builder.addCase(saveUtenteEsterno.fulfilled, (state, action) => {
			if (action.meta.arg.id) {
				state.utentiEsterni.results = state.utentiEsterni.results.map(
					(documentoDiTrasporto) => {
						if (documentoDiTrasporto.id == action.payload.id) {
							return action.payload;
						} else {
							return documentoDiTrasporto;
						}
					}
				);
			} else {
				state.utentiEsterni.results = [
					...state.utentiEsterni.results,
					action.payload,
				];
			}
			state.utenteEsterno_id = action.payload.id;

			toast.success("utente salvato.");

			state.utentiEsterni.errorsStack = {
				status: ErrorStatusTypes.SUCCESS,
				saving: false,
			};
		});
		builder.addCase(saveUtenteEsterno.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");

			state.utentiEsterni.results = state.utentiEsterni.results.map(
				(documentoDiTrasporto) => {
					if (documentoDiTrasporto.id == action.meta.arg.id) {
						return {
							...documentoDiTrasporto,
							errorsStack: parseErrorMessage(action.error),
						};
					} else {
						return documentoDiTrasporto;
					}
				}
			);

			state.utentiEsterni.errorsStack = parseErrorMessage(action.error);
		});

		// delete documentoDiTrasportoss
		builder.addCase(deleteUtenteEsterno.pending, (state, action) => {
			state.utentiEsterni.errorsStack.status = ErrorStatusTypes.PENDING;
		});

		builder.addCase(deleteUtenteEsterno.fulfilled, (state, action) => {
			state.utentiEsterni.results = state.utentiEsterni.results.filter(
				(documentoDiTrasporto) =>
					documentoDiTrasporto.id != action.payload.documentoDiTrasporto_id
			);
			toast.success("utente cancellato.");

			state.utentiEsterni.errorsStack.status = ErrorStatusTypes.OK;
		});

		builder.addCase(deleteUtenteEsterno.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");

			state.utentiEsterni.errorsStack = parseErrorMessage(action.error);
		});

		// invita utente esterno
		builder.addCase(invitaUtenteEsterno.pending, (state, action) => {
			state.utentiEsterni.errorsStack.status = ErrorStatusTypes.PENDING;
		});

		builder.addCase(invitaUtenteEsterno.fulfilled, (state, action) => {
			// state.utentiEsterni.results = state.utentiEsterni.results.filter(
			// 	(documentoDiTrasporto) =>
			// 		documentoDiTrasporto.id != action.payload.documentoDiTrasporto_id
			// );
			toast.success("E-mail di invito inviata.");

			state.utentiEsterni.errorsStack.status = ErrorStatusTypes.OK;
		});

		builder.addCase(invitaUtenteEsterno.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");

			state.utentiEsterni.errorsStack = parseErrorMessage(action.error);
		});

		// fetch lotti Committente
		builder.addCase(fetchLottiCommittente.pending, (state, action) => {
			state.lottiCommittenteProduttore.errorsStack.status =
				ErrorStatusTypes.PENDING;
		});
		builder.addCase(fetchLottiCommittente.fulfilled, (state, action) => {
			// state.lotti = action.payload;
			state.lottiCommittenteProduttore = action.payload;
			state.lottiCommittenteProduttore.errorsStack = {
				status: ErrorStatusTypes.OK,
			};
		});
		builder.addCase(fetchLottiCommittente.rejected, (state, action) => {
			state.lottiCommittenteProduttore.errorsStack = parseErrorMessage(
				action.error
			);
		});

		// fetch allevamenti
		builder.addCase(fetchAllevamentiCommittente.pending, (state, action) => {
			state.allevamentiCommittente.errorsStack.status =
				ErrorStatusTypes.PENDING;
		});
		builder.addCase(fetchAllevamentiCommittente.fulfilled, (state, action) => {
			// state.lotti = action.payload;
			state.allevamentiCommittente = action.payload;
			state.allevamentiCommittente.errorsStack = {
				status: ErrorStatusTypes.OK,
			};
		});
		builder.addCase(fetchAllevamentiCommittente.rejected, (state, action) => {
			state.allevamentiCommittente.errorsStack = parseErrorMessage(
				action.error
			);
		});

		// fetch lotti Produttore
		builder.addCase(fetchLottiProduttore.pending, (state, action) => {
			state.lottiCommittenteProduttore.errorsStack.status =
				ErrorStatusTypes.PENDING;
		});
		builder.addCase(fetchLottiProduttore.fulfilled, (state, action) => {
			// state.lotti = action.payload;
			state.lottiCommittenteProduttore = action.payload;
			state.lottiCommittenteProduttore.errorsStack = {
				status: ErrorStatusTypes.OK,
			};
		});
		builder.addCase(fetchLottiProduttore.rejected, (state, action) => {
			state.lottiCommittenteProduttore.errorsStack = parseErrorMessage(
				action.error
			);
		});
	},
});

// Action creators are generated for each case reducer function
export const { setUtenteCorrente } = ospitiSlice.actions;

export const ospitiReducer = ospitiSlice.reducer;
