import React from "react";
import { Lotto } from "../pianificazione/pianificazioneSlice";

import Divider from "@mui/material/Divider";

import Grid from "@mui/material/Grid2";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { DateTime } from 'luxon';
import SchedaMacelloForm from "./SchedaMacelloForm";
import { SchedaMacello } from "./schedeMacelloSlice";

interface SchedaMacelloLottoCardProps {
	lotto: Lotto;
	saveSchedaMacello: (schedaMacello: SchedaMacello) => void;
}

const SchedaMacelloLottoCard = ({
	lotto,
	saveSchedaMacello,
}: SchedaMacelloLottoCardProps) => {
	return (
		<Card key={"Box_SchedaMacello_card_" + lotto.id} sx={{ my: 2 }}>
			<CardContent>
				{lotto.scheda_macello && (
					<SchedaMacelloForm
						schedaMacello={lotto.scheda_macello}
						saveSchedaMacello={saveSchedaMacello}
					/>
				)}
			</CardContent>
			{!!lotto.scheda_macello?.minuti_processamento && (
				<CardContent>
					<Grid
						container
						spacing={2}
						justifyContent="flex-start"
						sx={{ mt: 1 }}
					>
						<Grid size={{ xs:6, sm:4, md:2 }}>
							<Typography sx={{ mb: 0.5 }} color="text.secondary">
								Durata
							</Typography>
							<Divider />
							<Typography variant="body1">
								{lotto.scheda_macello?.minuti_processamento &&
									Intl.NumberFormat("it-IT").format(
										lotto.scheda_macello?.minuti_processamento
									) + "'"}
							</Typography>
						</Grid>
						<Grid size={{ xs:6, sm:4, md:2 }}>
							<Typography sx={{ mb: 0.5 }} color="text.secondary">
								Durata prevista (per tot. capi - morti)
							</Typography>
							<Divider />
							<Typography variant="body1">
								{Intl.NumberFormat("it-IT", {
									maximumFractionDigits: 0,
								}).format(
									lotto.scheda_macello?.minuti_processamento_stimati || 0
								) + "'"}
							</Typography>
						</Grid>
					</Grid>
					<Grid
						container
						spacing={2}
						justifyContent="flex-start"
						sx={{ mt: 1 }}
					>
						<Grid size={{ xs:6, sm:4, md:2 }}>
							<Typography sx={{ mb: 0.5 }} color="text.secondary">
								Velocità
							</Typography>
							<Divider />
							<Typography variant="body1">
								{lotto.scheda_macello?.minuti_processamento &&
									Intl.NumberFormat("it-IT", {
										maximumFractionDigits: 0,
									}).format(lotto.scheda_macello?.velocita_processamento || 0) +
										" capi/min."}
							</Typography>
						</Grid>
						<Grid size={{ xs:6, sm:4, md:2 }}>
							<Typography sx={{ mb: 0.5 }} color="text.secondary">
								Velocità stimata
							</Typography>
							<Divider />
							<Typography variant="body1">
								{lotto.scheda_macello?.minuti_processamento &&
									Intl.NumberFormat("it-IT").format(
										lotto.scheda_macello?.velocita_processamento_stimata || 0
									) + " capi/min."}
							</Typography>
						</Grid>
					</Grid>
				</CardContent>
			)}
		</Card>
	);
};

export default SchedaMacelloLottoCard;
