import React, { useState, useEffect } from "react";
import { Camion, Autista } from "../../../anagrafiche/anagraficheSlice";
import { SchedaCarico } from "../../schedeCaricoSlice";
import SchedaCaricoForm from "./SchedaCaricoForm";

import { DateTime } from 'luxon';

import Avatar from "@mui/material/Avatar";
import { styled, Box } from "@mui/system";
import Stack from "@mui/material/Stack";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import Tooltip from "@mui/material/Tooltip";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import PeopleIcon from "@mui/icons-material/People";
import TimerIcon from "@mui/icons-material/Timer";
// import Chip from "@material-ui/core/Chip";  ************************ da cancellare se non ci sono malfunzionamenti
import Chip from "@mui/material/Chip";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { ForiImpegnati } from "../../../../common/CalcoloCaricoAmmissibile";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid2";
import {convertToDateTime} from "../../../../common/dateUtils/convertToDateTime";

const NumberChip = styled(Chip)({
	"&&": {
		height: "1.5rem",
		backgroundColor: "#1b75b1",
		color: "#e1e1e1",
		fontSize: "1.2rem",
		fontWeight: 500,
		paddingLeft: 0,
		paddingRight: 0,
		borderRadius: 5,
	},
	"& .MuiChip-label": {
		paddingLeft: "5px",
		paddingRight: "5px",
	},
});

interface SchedaCaricoCardProps {
	schedaCarico: SchedaCarico;
	saveSchedaCarico: (schedaCarico: SchedaCarico) => void;
	deleteSchedaCarico: (schedaCarico: SchedaCarico) => void;
	mandaWhatsAppAutisti: (schedaCarico_id: number) => void;
	mandaSmsAppAutisti: (schedaCarico_id: number) => void;
	camions: Camion[];
	autisti: Autista[];
}
const SchedaCaricoCard = ({
	schedaCarico,
	saveSchedaCarico,
	deleteSchedaCarico,
	mandaWhatsAppAutisti,
	mandaSmsAppAutisti,
	camions,
	autisti,
}: SchedaCaricoCardProps) => {
	const style = {
		position: "absolute" as "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		maxWidth: 400,
		width: "95%",
		bgcolor: "background.paper",
		border: "2px solid #000",
		boxShadow: 24,
		p: 4,
		pb: 1,
		textAlign: "center",
	};

	return (
		<Accordion
			sx={
				{mb:1
					// boxShadow:
					// 	" 0px 0px 6px 5px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
				}
			}
		>
			<AccordionSummary
				sx={{
					backgroundColor:
						schedaCarico.stato_schedulazione.stato == 0
							? "#eee"
							: schedaCarico.stato_schedulazione.stato == 1
							? "#ffdc1055"
							: schedaCarico.stato_schedulazione.stato == 2
							? "#90EE9055"
							: "#fe020255",
				}}
				expandIcon={<ExpandMoreIcon />}
				aria-controls="scheda_carico_accordion"
				id="scheda_carico_accordion_header"
			>
				<Grid container sx={{width:'100%'}}>
					<Grid size={{ xs:6, sm:6, md:3, lg:6 }}>
						<Stack direction="row" spacing={2} justifyContent="flex-start">
							<NumberChip aria-label="scheda_carico" label={schedaCarico.id} />
							<Box>
								<Typography>
									{"capi:" +
										schedaCarico.numero_capi_pianificati +
										" fori:" +
										schedaCarico.numero_fori}
								</Typography>
							</Box>
							{schedaCarico.stato_schedulazione.stato == 3 && (
								<Box>
									<Tooltip
										title={
											schedaCarico.stato_schedulazione.descrizione +
											": " +
											schedaCarico.stato_schedulazione.messaggio
										}
										placement="left-start"
										disableFocusListener
										enterTouchDelay={10}
										arrow
									>
										<ContactSupportIcon
											sx={{
												color: "#fe0202",
												// opacity: lotto.in_dubbio ? 1 : 0,
												mr: "-0.125rem",
												flexGrow: 1,
											}}
										/>
									</Tooltip>
								</Box>
							)}
						</Stack>
					</Grid>
					<Grid size={{ xs:6, sm:6, md:3, lg:2 }}>
						<Chip
							icon={<TimerIcon />}
							variant="outlined"
							sx={{
								backgroundColor: schedaCarico.data_ora_carico_pianificata
									? "#2196f3"
									: "inherit",
							}}
							color={
								schedaCarico.data_ora_carico_pianificata ? "success" : "default"
							}
							label={
								schedaCarico.data_ora_carico_pianificata &&
								"carico " +
									convertToDateTime(schedaCarico.data_ora_carico_pianificata)?.setLocale("it").toFormat('d LLL HH:mm')
							}
						/>
					</Grid>

					<Grid size={{ xs:6, sm:6, md:3, lg:2 }}>
						<Chip
							variant="outlined"
							icon={<LocalShippingIcon />}
							// color="success"
							color={schedaCarico.camion_pack?.nome ? "success" : "default"}
							// style={{ backgroundColor: "green" }}
							sx={{
								backgroundColor: schedaCarico.viaggio_lungo
									? "#800080aa"
									: !!schedaCarico.camion
									? "#2196f3"
									: "inherit",
							}}
							label={
								schedaCarico.camion_pack?.nome +
								" " +
								schedaCarico.camion_pack?.targa
							}
						/>
					</Grid>
					<Grid size={{ xs:6, sm:6, md:3, lg:2 }}>
						<Chip
							icon={<PeopleIcon />}
							variant="outlined"
							color={
								schedaCarico.autisti_pack.length > 0 ? "success" : "default"
							}
							sx={{
								backgroundColor: schedaCarico.viaggio_lungo
									? "#800080aa"
									: schedaCarico.autisti.length > 0
									? "#2196f3"
									: "inherit",
							}}
							label={schedaCarico.autisti_pack
								.map((autista) => autista.cognome)
								.join(",")}
						/>
					</Grid>
				</Grid>
			</AccordionSummary>
			<AccordionDetails
				sx={{
					py: 3,
					// "&:last-child": { pb: 1 },
				}}
			>
				<SchedaCaricoForm
					schedaCarico={schedaCarico}
					saveSchedaCarico={saveSchedaCarico}
					deleteSchedaCarico={deleteSchedaCarico}
					mandaWhatsAppAutisti={mandaWhatsAppAutisti}
					mandaSmsAppAutisti={mandaSmsAppAutisti}
					camions={camions}
					listaAutisti={autisti}
				/>
			</AccordionDetails>
		</Accordion>
	);
};

export default SchedaCaricoCard;
