import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../redux/store";
import { useLocation, Link } from "react-router-dom";
import { Routes_path } from "../../routerApp";

import {
	LottiState,
	fetchLotti,
	resetLotti,
	Lotto,
} from "../pianificazione/pianificazioneSlice";
import SchedaMacelloCard from "./SchedaMacelloCard";
import {
	SchedaMacello,
	SchedeMacelloState,
	fetchSchedeMacello,
	saveSchedaMacello,
} from "../schedeMacello/schedeMacelloSlice";
import NavigazioneGiorniToolbar from "../../common/dateUtils/NavigazioneGiorniToolbar";
// import { DATA_MACELLAZIONE_CORRENTE } from "../../../api/apiUtils";

import { DateTime } from 'luxon';

import {
	RecuperaDataCorrente,
	FissaDataCorrente,
} from "../../common/dateUtils/RecuperaDataCorrente";

import Stack from "@mui/material/Stack";

import { Button } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";

import {
	ErrorStatusTypes,
	ErrorsStack,
	parseErrorMessage,
} from "../../common/errorsDeclarations";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid2";
import { Box } from "@mui/system";

import DateToday from "../../common/dateUtils/DateToday";
import {convertToDateTime} from "../../common/dateUtils/convertToDateTime";

const SchedeMacelloGiornoPage = () => {
	
	const dispatch = useAppDispatch();
	const lotti: LottiState = useSelector<RootState, LottiState>(
		(state) => state.pianificazione.lotti
	);
	const schedeMacello: SchedeMacelloState = useSelector<
		RootState,
		SchedeMacelloState
	>((state) => state.schedeMacelloState.schedeMacello);

	type LocationState = {
		giornoSettimana: any;
	};
	const location = useLocation();
	const recupera_data_corrente = RecuperaDataCorrente(location);

	// const recupera_data_corrente = () => {
	// 	if (location.state) {
	// 		const { giornoSettimana } = location.state as LocationState;
	// 		if (giornoSettimana) {
	// 			return giornoSettimana;
	// 		}
	// 	}
	// 	const dataMacellazioneCorrente = localStorage.getItem(
	// 		DATA_MACELLAZIONE_CORRENTE
	// 	);
	// 	if (!!dataMacellazioneCorrente) {
	// 		return new Date(dataMacellazioneCorrente);
	// 	}
	// 	return DateToday();
	// };

	const [data, setData] = useState<DateTime | null>(recupera_data_corrente);
	const [refresh, setRefresh] = React.useState<boolean>(false);

	useEffect(() => {
		data && FissaDataCorrente(data);

		// localStorage.setItem(
		// 	DATA_MACELLAZIONE_CORRENTE,
		// 	convertToDateTime(data)?.setLocale("it").toFormat("yyyy-MM-dd")
		// );
		const data_da = data;
		const data_a = data;
		dispatch(resetLotti());
		dispatch(fetchLotti({ data_da: data_da?.toJSDate(), data_a: data_a?.toJSDate(), }));
		dispatch(fetchSchedeMacello({ data_da: data_da?.toJSDate(), data_a: data_a?.toJSDate(), }));
		setRefresh(false);
	}, [data, refresh]);

	const schedaMacelloLotto = (lotto: Lotto) => {
		const schedaMacello: SchedaMacello | undefined = schedeMacello.results.find(
			(scheda) => scheda.lotto == lotto.id
		);
		return schedaMacello;
	};

	const handlerSaveSchedaMacello = (schedaMacelloToSave: SchedaMacello) => {
		const data_lavorazione_toSave: Date | string | undefined =
			lotti.results.find(
				(lotto) => lotto.id == schedaMacelloToSave.lotto
			)?.data_lavorazione;
		const data_ora_inizio_macello_toSave: Date | string = new Date(
			convertToDateTime(data_lavorazione_toSave)?.setLocale("it").toFormat("yyyy-MM-dd") +
				"T" +
				convertToDateTime(schedaMacelloToSave.data_ora_inizio_macello)?.setLocale("it").toFormat('HH:mm:ss')
		);
		const data_ora_fine_macello_toSave: Date | string = new Date(
			convertToDateTime(data_lavorazione_toSave)?.setLocale("it").toFormat("yyyy-MM-dd") +
				"T" +
				convertToDateTime(schedaMacelloToSave.data_ora_fine_macello)?.setLocale("it").toFormat('HH:mm:ss')
		);
		dispatch(
			saveSchedaMacello({
				...schedaMacelloToSave,
				data_ora_inizio_macello: data_ora_inizio_macello_toSave,
				data_ora_fine_macello: data_ora_fine_macello_toSave,
			})
		);
	};

	return (
		<Box>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={
					lotti.errorsStack.status == ErrorStatusTypes.PENDING ||
					schedeMacello.errorsStack.status == ErrorStatusTypes.PENDING
				}
			>
				<CircularProgress color="inherit" />
			</Backdrop>

			<NavigazioneGiorniToolbar
				titolo={"Schede Macello"}
				setData={setData}
				data={data}
				refresh={() => {
					setRefresh(true);
				}}
				Toolbar={() => {
					return (
						<Stack
							direction="row"
							spacing={2}
							alignItems="center"
							justifyContent="flex-end"
							sx={{ height: "100%" }}
						>
							<Button
								variant="contained"
								component={Link}
								to={{
									pathname: Routes_path.SCHEDA_MACELLAZIONE,
								}}
								state={{
									giornoSettimana: data,
								}}
								startIcon={<PrintIcon />}
							>
								Scheda Macellazione
							</Button>
							<Button
								variant="contained"
								component={Link}
								to={{
									pathname: Routes_path.SCHEDA_HACCP,
								}}
								state={{
									giornoSettimana: data,
								}}
								startIcon={<PrintIcon />}
							>
								Modulo Haccp
							</Button>
						</Stack>
					);
				}}
			/>
			<Grid container spacing={2}>
				<Grid size={{  xs:12, sm:12, md:12 }}>
					{lotti.results.map((lotto) => (
						<SchedaMacelloCard
							key={"lotto_scheda_macello" + lotto.id}
							lotto={lotto}
							schedaMacello={schedaMacelloLotto(lotto)}
							saveSchedaMacello={handlerSaveSchedaMacello}
						/>
					))}
				</Grid>
			</Grid>
		</Box>
	);
};
export default SchedeMacelloGiornoPage;
