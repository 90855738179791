import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Link as ReactLink, useLocation, useParams} from "react-router-dom";

import {RootState, useAppDispatch} from "./../../../redux/store";
import {getColore} from "./../pianificazione/utility/lottoUtility";

import {
    Lotto, getLotto,
} from "../pianificazione/pianificazioneSlice";
import {Lavorazione,} from "../lavorazioni/lavorazioniSlice";

import {Lavorazioni, fetchLavorazioni} from "../lavorazioni/lavorazioniSlice";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import PalletsPage from "../pesaturaPallet/PalletsPage";
import { DateTime } from 'luxon';
import {
    ErrorsStack,
} from "components/common/errorsDeclarations";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import {styled, Box} from "@mui/system";
import TransitionsModal from "../../common/TransitionsModal";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import PesaturaPalletLottoBreadcrumbs from "./PesaturaPalletLottoBreadcrumbs";
import {
    deletePallet,
    fetchPackingList,
    fetchPackingListPallets,
    PesaturaPalletsState,
    savePackingList
} from "./pesaturaPalletSlice";
import {PackingList} from "./pesaturaPalletSlice";
import CardContent from "@mui/material/CardContent";
import PrintIcon from "@mui/icons-material/Print";
import {Routes_path} from "../../routerApp";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import Avatar from "@mui/material/Avatar";
import PackingListForm from "./PackingListForm";
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import {deepOrange} from "@mui/material/colors";
import DeleteIcon from "@mui/icons-material/Delete";
import {convertToDateTime} from "../../common/dateUtils/convertToDateTime";


const PesaturePalletLottoPage = () => {
    
    const dispatch = useAppDispatch();

    const {lotto_id} = useParams();

    const [lottoId, setLottoId] = useState<number>(
        parseInt(lotto_id || "")
    );


    const lotto: Lotto | undefined = useSelector<RootState, Lotto | undefined>(
        (state) => state.pianificazione.lotti.results.find((lotto) => lotto.id === lottoId)
    );


    const [refresh, setRefresh] = React.useState<boolean>(false);

    useEffect(() => {
        // localStorage.setItem(
        // 	DATA_PIANIFICAZIONE_LAVORAZIONI_CORRENTE,
        // 	convertToDateTime(data)?.setLocale("it").toFormat("yyyy-MM-dd")
        // );
        console.log("lottoId", lottoId);
        dispatch(getLotto(lottoId));
        // dispatch(fetchArticoli());
        dispatch(fetchLavorazioni(lottoId));
        setRefresh(false);
    }, [lottoId, refresh]);

    useEffect(() => {
        lotto?.committente && dispatch(fetchPackingList({committente_id: lotto?.committente, stato: 'A'}));
        setRefresh(false);
    }, [lotto?.committente]);

    //
    // const packingListState: PackingListState = useSelector<RootState, PackingListState>(
    //     (state) => state.pesaturaPalletState.packingListState
    // );

    const packingListAperta = useSelector<RootState, PackingList | undefined>(
        (state) => state.pesaturaPalletState.packingListState.results.find(
            (packingList) => packingList.stato == "A"
        )
    );

    useEffect(() => {
        packingListAperta?.id && dispatch(fetchPackingListPallets({packing_list_id: packingListAperta.id}));
        setRefresh(false);
    }, [packingListAperta]);

    const pallets: PesaturaPalletsState = useSelector<RootState, PesaturaPalletsState>(
        (state) => state.pesaturaPalletState.pallets
    );


    const lavorazioni: Lavorazioni = useSelector<RootState, Lavorazioni>(
        (state) => state.lavorazioniState.lavorazioni
    );

    const confezionamentiErrorsStack: ErrorsStack = useSelector<
        RootState,
        ErrorsStack
    >((state) => state.confezionamentiState.confezionamenti.errorsStack);


    const [openPallesPage, setOpenPallesPage] = useState(false);

    const [lottoToEdit, setLottoToEdit] = useState<Lotto | undefined>(undefined);
    const [lavorazioneToEdit, setLavorazioneToEdit] = useState<Lavorazione | undefined>(undefined);

    function handleStampaPackingList(): void {
        throw new Error("Function not implemented.");
    }

    // const handleRecuperaPesature = () => {
    // 	setExpanded(false);
    // 	data && dispatch(recuperaPesature(data));
    // };

    return (
        <>
            <PesaturaPalletLottoBreadcrumbs identificativoPosizione={lottoId}/>
            {lotto &&
                <Card>
                    <CardHeader title={
                        <Stack direction="row" justifyContent="space-evenly" spacing={2}>
                            <Box>
                                <Typography variant="h4">Lotto n°{lotto.ordinamento_lotto}</Typography>
                                <Typography variant="h6">{lotto.codice_tracciabilita}</Typography>
                            </Box>

                            <Typography variant="h3">{lotto.committente_desc}</Typography>
                            <Box>
                                <Typography variant="h4">{lotto.tipo_capo_desc} {getColore(lotto)}</Typography>
                                <Typography
                                    variant="h6">del {convertToDateTime(lotto.data_lavorazione)?.setLocale("it").toFormat('dd/MM/yyyy')}</Typography>
                            </Box>
                        </Stack>
                    }/>
                </Card>
            }
            <Grid container spacing={2}>

                <Grid size={{ xs:4, sm:4, md:4, lg:4 }}>
                    <Stack direction="row"
                           justifyContent="center"
                           alignItems="center"
                           spacing={1}
                           sx={{mt: 1}}
                    >
                        {packingListAperta?.id ?
                            <Box sx={{width: '100%'}}>
                                <Card key={packingListAperta?.id || "default-key"}>
                                    <CardHeader title={
                                        <Box>
                                            <Stack direction="row" justifyContent="space-between" spacing={2}>
                                                <Typography variant="h4">Packing List
                                                    n°{packingListAperta?.id}</Typography>
                                                <Typography
                                                    variant="h6">{convertToDateTime(packingListAperta?.data)?.setLocale("it").toFormat('dd/MM/yyyy')}
                                                </Typography>
                                            </Stack>
                                            <Grid container spacing={1}>
                                                <Grid size={{ xs:12, sm:12, md:12, lg:4 }}>
                                                    <Button startIcon={<PrintIcon/>} variant="contained" color="primary"
                                                            size="small"
                                                            component={ReactLink}
                                                            to={{
                                                                pathname: Routes_path.STAMPA_PACKING_LIST + packingListAperta.id,
                                                            }}
                                                            target="_blank"
                                                    >
                                                        Stampa Packing List
                                                    </Button>
                                                </Grid>
                                                <Grid size={{ xs:12, sm:12, md:12, lg:4 }}>

                                                    <Button startIcon={<PrintIcon/>} variant="contained" color="primary"
                                                            size="small"
                                                            component={ReactLink}
                                                            to={{
                                                                pathname: Routes_path.STAMPA_RAFOOD + packingListAperta.id,
                                                            }}
                                                            target="_blank"
                                                    >
                                                        Stampa Rafood
                                                    </Button>
                                                </Grid>
                                                <Grid size={{ xs:12, sm:12, md:12, lg:4 }}>
                                                    <Button startIcon={<PrintIcon/>} variant="contained" color="primary"
                                                            size="small"
                                                            component={ReactLink}
                                                            to={{
                                                                pathname: Routes_path.STAMPA_DOCUMENTO_CONTO_TERZI_TRASPORTO + packingListAperta.id,
                                                            }}
                                                            target="_blank"
                                                    >
                                                        Stampa DDT
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    }
                                    />
                                    <CardContent>
                                        <Grid container spacing={2}>
                                            <Grid size={{ xs:6, sm:6, md:6, lg:6 }}>
                                                <Typography
                                                    variant="h6">Stato: {packingListAperta?.stato == "A" ? "Aperta" : "?????"}</Typography>
                                                <Typography variant="h6">Numero
                                                    Pallet: {packingListAperta?.num_pallets}</Typography>
                                                <Typography variant="h6">Numero
                                                    Cassette: {packingListAperta?.numero_cassette}</Typography>
                                                <Typography variant={"h6"}>Peso
                                                    Netto: {packingListAperta?.peso_netto}</Typography>
                                            </Grid>
                                            <Grid size={{ xs:6, sm:6, md:6, lg:6 }}>
                                                <PackingListForm packingList={packingListAperta}
                                                                 consentiModifica={true}/>
                                            </Grid>
                                        </Grid>
                                    </CardContent>

                                    <CardContent>
                                        <Typography variant="h6">Pallets</Typography>
                                        <Stack direction="column" spacing={0.5}>
                                            {pallets.results.map((pallet) => (
                                                <Grid container spacing={1} alignItems="center" wrap="wrap" key={`pallet_rep_${pallet.id}`}>
                                                    <Grid size={{ xs:1, sm:1, md:1, lg:1 }}>
                                                        <Avatar
                                                            sx={{bgcolor: deepOrange[500]}}
                                                            component={ReactLink}
                                                            to={{
                                                                pathname: Routes_path.STAMPA_GRANDE_IMBALLO_DDT + pallet.id,
                                                            }}
                                                            target="_blank"
                                                        >
                                                            {pallet.id}
                                                        </Avatar>
                                                    </Grid>
                                                    <Grid size={{md:6}}>
                                                        {/*<Typography key={pallet.id} variant="caption"># {pallet.id}</Typography>*/}
                                                        <Stack direction="column" spacing={1} alignItems="flex-start">
                                                            <Typography variant="body1">lotto:{pallet.lotto_codice_tracciabilita}</Typography>
                                                            <Typography variant="caption">{pallet.lavorazione_descrizione}</Typography>
                                                        </Stack>
                                                        {/*<Typography key={pallet.id}*/}
                                                        {/*            variant="body1">{pallet.lavorazione_descrizione} (#{pallet.id})</Typography>*/}
                                                    </Grid>
                                                    <Grid size={{ xs:3, sm:3, md:3, lg:3 }}>
                                                        <Typography key={pallet.id} variant="body1">{pallet.numero_cassette} cassette</Typography>
                                                    </Grid>
                                                    <Grid size={{ xs:1, sm:1, md:1, lg:1 }}>
                                                        <Button startIcon={<DeleteIcon/>}
                                                                type="submit"
                                                                // disabled={!consentiModifica}
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={() => dispatch(deletePallet({palletToDelete: pallet}))}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            ))}
                                        </Stack>

                                        <Button startIcon={<DoDisturbIcon/>} variant="contained" color="info"
                                                sx={{mt: 4}}
                                                onClick={() => {
                                                    dispatch(savePackingList({
                                                        packingListToSave: {
                                                            ...packingListAperta,
                                                            stato: "C"
                                                        }
                                                    }));
                                                }}
                                        >
                                            Chiudi Packing List
                                        </Button>
                                    </CardContent>
                                </Card>
                            </Box>
                            :
                            <Button variant="contained"
                                    color="secondary"
                                    size="large"
                                    onClick={() => {
                                        const nuovoPickingList: PackingList = {
                                            committente: lotto?.committente || 0,
                                            data: new Date(),
                                            stato: "A",
                                        }
                                        dispatch(savePackingList({packingListToSave: nuovoPickingList}));
                                    }}
                            >
                                Apri nuova Packing List
                            </Button>
                        }
                    </Stack>
                </Grid>
                <Grid size={{ xs:8, sm:8, md:8, lg:8 }}>

                    <Box
                        sx={{
                            p: 1,
                            // elevation: 0,
                        }}
                    >
                        <Grid container spacing={1}>
                            {lavorazioni.results.map((lavorazione) => (
                                <Grid size={{ xs:4, sm:3, md:3, lg:2 }} key={"lavorazione_" + lavorazione.id}>
                                    <Button variant="contained"
                                            size="small"
                                            sx={{width: "100%", minHeight: "150px"}}
                                            color="primary"
                                            disabled={!packingListAperta}

                                            onClick={() => {
                                                setOpenPallesPage(true);
                                                setLottoToEdit(lotto);
                                                setLavorazioneToEdit(lavorazione);
                                            }}
                                    >
                                        <Stack direction="column" spacing={1}>
                                            <Typography
                                                variant="h5">{lavorazione.codice_articolo_committente}</Typography>
                                            <Typography variant="subtitle1">{lavorazione.descrizione}</Typography>
                                            <Typography variant="caption">{lavorazione.destinazione}</Typography>
                                        </Stack>
                                    </Button>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>

                    <TransitionsModal
                        open={openPallesPage}
                        handleClose={() => setOpenPallesPage(false)}
                    >{
                        lottoToEdit && lavorazioneToEdit &&
                        <PalletsPage
                            lotto={lottoToEdit}
                            packingList={packingListAperta}
                            lavorazione={lavorazioneToEdit}
                            handleClose={() => {
                                setOpenPallesPage(false)
                            }}
                        />
                    }
                    </TransitionsModal>
                </Grid>
            </Grid>
        </>
    );
};

export default PesaturePalletLottoPage;
