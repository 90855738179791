import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../../redux/store";

import NavigazioneMesiToolbar from "../../../common/dateUtils/NavigazioneMesiToolbar";
import {
	RecuperaDataCorrente,
	FissaDataCorrente,
} from "../../../common/dateUtils/RecuperaDataCorrente";

import {
	SaldiPalletCommittenti,
	fetchSaldoPalletCommittenti,
} from "../../bolle_fatture/pallets/palletsSlice";

import { Committente, Committenti } from "../../anagrafiche/anagraficheSlice";
import { SelectCommittente } from "../dashboard/DashboardCommittentiToolbarOptions";
import { PermissionTypes } from "../../../authentication/permissionsGroups";
import { Utente } from "../../../authentication/authenticationSlice";

import { ErrorStatusTypes } from "../../../common/errorsDeclarations";

import { DateTime } from 'luxon';

import ReportPalletCommittenteList from "../../bolle_fatture/pallets/ReportPalletCommittenteList";

import Backdrop from "@mui/material/Backdrop";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid2";
import {convertToDateTime} from "../../../common/dateUtils/convertToDateTime";

const ReportPalletCommittentePage = () => {
	
	const dispatch = useAppDispatch();

	const utente = useSelector<RootState, Utente>(
		(state) => state.authentication.utenteLogged
	);
	const saldiPalletCommittenti: SaldiPalletCommittenti = useSelector<
		RootState,
		SaldiPalletCommittenti
	>((state) => state.palletsState);

	const committenti: Committenti = useSelector<RootState, Committenti>(
		(state) => state.anagrafiche.committenti
	);

	const [committenteCorrente, setCommittenteCorrente] =
		React.useState<Committente | null>(null);

	useEffect(() => {
		if (utente.gruppi?.includes(PermissionTypes.PIANIFICATORI)) {
			!committenteCorrente && setCommittenteCorrente(committenti.results[0]);
		} else {
			!!utente.committente &&
				setCommittenteCorrente(
					committenti.results.find(
						(committente) => committente.id == utente.committente
					) || null
				);
		}
	}, [committenti.results]);

	const location = useLocation();

	const [refresh, setRefresh] = React.useState<boolean>(false);
	const recupera_data_corrente = RecuperaDataCorrente(location);

	const [data, setData] = React.useState<DateTime | null>(
		recupera_data_corrente
	);

	useEffect(() => {
		const committente_id = committenteCorrente?.id;
		const data_da = convertToDateTime(data)?.startOf('month').toJSDate();
		const data_a = convertToDateTime(data)?.endOf('month').toJSDate();
		dispatch(fetchSaldoPalletCommittenti({ committente_id, data_da, data_a }));
		setRefresh(false);
	}, [committenteCorrente, data, refresh]);

	return (
		<>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={
					saldiPalletCommittenti.errorsStack.status == ErrorStatusTypes.PENDING
				}
			>
				<CircularProgress color="inherit" />
			</Backdrop>

			<NavigazioneMesiToolbar
				titolo={"Report Mensile Contabilità Bancali"}
				setData={setData}
				data={data}
				refresh={() => {
					setRefresh(true);
				}}
				Toolbar={() => {
					return (
						<Stack
							direction="row"
							justifyContent="flex-end"
							alignItems="center"
							sx={{ ml: 2 }}
							spacing={1}
						>
							{utente.gruppi?.includes(PermissionTypes.PIANIFICATORI) &&
								committenti.results && (
									<SelectCommittente
										committenteCorrente={committenteCorrente}
										committenti={committenti.results}
										setCommittenteCorrente={setCommittenteCorrente}
									/>
								)}
						</Stack>
					);
				}}
			/>

			<Grid
				container
				spacing={1}
				sx={{ marginTop: 0.5 }}
				direction="row"
				justifyContent="center"
				alignItems="flex-start"
			>
				<Grid size={{ xs:12, sm:10, md:10, lg:10 }}>
					{committenteCorrente && (
						<ReportPalletCommittenteList
							saldoPalletCommittente={saldiPalletCommittenti.results.find(
								(saldoPalletCommittente) =>
									saldoPalletCommittente.id_committente ==
									committenteCorrente.id
							)}
						/>
					)}
				</Grid>
			</Grid>
		</>
	);
};

export default ReportPalletCommittentePage;
