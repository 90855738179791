import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../../redux/store";
import NavigazioneSettimaneToolbar from "../../../common/dateUtils/NavigazioneSettimaneToolbar";
import { DATA_VETERINARIO_CORRENTE } from "../../../../api/apiUtils";
import { fetchVeterinario, VeterinarioState } from "./veterinarioSlice";

import { DateTime } from 'luxon';

import { ErrorStatusTypes } from "../../../common/errorsDeclarations";

import VeterinarioGiornoTable from "./VeterinarioGiornoTable";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

// import DateToday from "../../../common/dateUtils/DateToday";

import {
	RecuperaDataCorrente,
	FissaDataCorrente,
} from "../../../common/dateUtils/RecuperaDataCorrente";

import { Box } from "@mui/system";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import {convertToDateTime} from "../../../common/dateUtils/convertToDateTime";

const VeterinarioSettimanaPage = () => {
	const dispatch = useAppDispatch();

	const veterinarioState: VeterinarioState = useSelector<
		RootState,
		VeterinarioState
	>((state) => state.veterinarioState.veterinario);

	type LocationState = {
		giornoSettimana: any;
	};
	const location = useLocation();
	const recupera_data_corrente = RecuperaDataCorrente(location);

	// const recupera_data_corrente = () => {
	// 	if (location.state) {
	// 		const { giornoSettimana } = location.state as LocationState;
	// 		if (giornoSettimana) {
	// 			return giornoSettimana;
	// 		}
	// 	}
	// 	const dataLavorazioniCorrente = localStorage.getItem(
	// 		DATA_VETERINARIO_CORRENTE
	// 	);
	// 	if (!!dataLavorazioniCorrente) {
	// 		return new Date(dataLavorazioniCorrente);
	// 	}
	// 	return DateToday();
	// };

	const [data, setData] = React.useState<DateTime | null>(
		recupera_data_corrente
	);
	const [refresh, setRefresh] = React.useState<boolean>(false);

	useEffect(() => {
		data && FissaDataCorrente(data);

		// localStorage.setItem(
		// 	DATA_VETERINARIO_CORRENTE,
		// 	convertToDateTime(data)?.setLocale("it").toFormat("yyyy-MM-dd")
		// );
		const data_da = convertToDateTime(data)?.startOf('week').toJSDate();
		const data_a = convertToDateTime(data)?.endOf('week').toJSDate();
		dispatch(fetchVeterinario({ data_da, data_a }));
		const newSettimana: {
			numeroSettimana: string;
			dateSettimana: (DateTime<boolean> | undefined)[];
		} = {
			numeroSettimana: convertToDateTime(data)?.setLocale("it").toFormat('W')||"",
			dateSettimana: caricaSettimana(data),
		};
		setSettimana(newSettimana);
		setRefresh(false);
	}, [data, refresh]);

	const totaleCapiSettimana = Intl.NumberFormat("it-IT").format(
		veterinarioState.results.reduce(
			(valoreCalcolato, lottoCorrente) =>
				valoreCalcolato +
				+(lottoCorrente.num_busti_lotto || 0) +
				(lottoCorrente.num_tz_lotto || 0) +
				(lottoCorrente.numero_scarti_2 || 0),
			0
		)
	);

	interface Settimana {
		numeroSettimana: string;
		dateSettimana: (DateTime<boolean> | undefined)[];
	}
	const caricaSettimana = (data?: DateTime|null) => {
		const dateSettimana: (DateTime<boolean> | undefined)[] = [...Array(6)].map((_, i) =>
			convertToDateTime(data)?.startOf('week').plus({ days: i })
		);
		return dateSettimana;
	};
	const [settimana, setSettimana] = React.useState<Settimana>({
		numeroSettimana: convertToDateTime(data)?.setLocale("it").toFormat('W')||"",
		dateSettimana: caricaSettimana(data),
	});

	const [expanded, setExpanded] = React.useState<string | false>(false);

	const handleChangeGiornoPanel =
		(giornoSettimana: Date) =>
		(event: React.SyntheticEvent, isExpanded: boolean) => {
			if (giornoSettimana) {
				// dispatch(fetchLavorazioni(lotto.id));
			}
			setExpanded(
				isExpanded ? "giornoSettimana_" + giornoSettimana.getDay() : false
			);
		};

	return (
		<>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={
					veterinarioState.errorsStack.status == ErrorStatusTypes.PENDING
					// ||
					// lavorazioni.errorsStack.status == ErrorStatusTypes.PENDING
				}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
			<NavigazioneSettimaneToolbar
				titolo={"Report Sanitario Settimanale"}
				setData={setData}
				data={data}
				refresh={() => {
					setRefresh(true);
				}}
				Toolbar={() => {
					return (
						<Typography variant="h5" component="div">
							{"Tot. capi settimana: "}
							{totaleCapiSettimana}
						</Typography>
					);
				}}
			/>

			<Box sx={{ margin: 1, marginTop: 2 }}>
				<TableContainer component={Paper}>
					<Table aria-label="collapsible table">
						<TableHead>
							<TableRow>
								<TableCell />
								<TableCell />
								<TableCell align="left">Data</TableCell>
								<TableCell align="left">Ora fine lavoro</TableCell>
								<TableCell align="right">peso Totale&nbsp;(kg)</TableCell>
								<TableCell align="right">Totale Capi</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{settimana.dateSettimana.map((giornoSettimana, i) => (
								<VeterinarioGiornoTable
									key={i}
									giornoSettimana={giornoSettimana||DateTime.now()}
									// lottiGiorno={[]}
									lottiGiorno={veterinarioState.results.filter(
										(lotto) =>
											convertToDateTime(lotto.data_lavorazione)?.setLocale("it").toFormat("yyyy-MM-dd") ==
											convertToDateTime(giornoSettimana)?.setLocale("it").toFormat("yyyy-MM-dd")
									)}
								/>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Box>
		</>
	);
};

export default VeterinarioSettimanaPage;
