import React, { useEffect } from "react";
import {Link, useParams} from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../../redux/store";
import {
	LottiState,
	fetchLotti,
	resetLotti,
} from "../../pianificazione/pianificazioneSlice";
import { SchedeCaricoState, fetchschedeCarico } from "../schedeCaricoSlice";
import {
	fetchCamions,
	fetchAutisti,
	Parametri,
} from "../../anagrafiche/anagraficheSlice";
import OrdiniCaricoPdf from "./OrdiniCaricoPdf";
import NavigazioneGiorniToolbar from "../../../common/dateUtils/NavigazioneGiorniToolbar";
import { DateTime } from 'luxon';
import { Page, Document } from "@react-pdf/renderer";
import {
	ErrorStatusTypes,
	ErrorsStack,
} from "../../../common/errorsDeclarations";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/system";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { Routes_path } from "../../../routerApp";
import ProgrammaMacellazionePdf from "./ProgrammaMacellazionePdf";
import stampaConNome from "../../../common/pdf/stampaConNome";
import {convertToDateTime} from "../../../common/dateUtils/convertToDateTime";


const OrdiniCaricoPage = () => {
	
	const dispatch = useAppDispatch();
	const lotti: LottiState = useSelector<RootState, LottiState>(
		(state) => state.pianificazione.lotti
	);
	const schedeCarico: SchedeCaricoState = useSelector<
		RootState,
		SchedeCaricoState
	>((state) => state.schedeCaricoState.schedeCarico);

	const anagraficheErrorsStack: ErrorsStack = useSelector<
		RootState,
		ErrorsStack
	>((state) => state.anagrafiche.errorsStack);

	const { data_yyyy_mm_dd } = useParams();
	const  giornoSettimana  =  convertToDateTime(data_yyyy_mm_dd);
	const initData = convertToDateTime(giornoSettimana);
	const [data, setData] = React.useState<DateTime | null>(initData);


	const parametri = useSelector<RootState, Parametri | undefined>((state) =>
		state.anagrafiche.storicoParametri.results.find(
			(p) =>
				new Date(p.data_validta_da) <=
					new Date(new Date(data?.toJSDate() || "").getTime() + 6 * 60 * 60 * 1000) &&
				new Date(new Date(data?.toJSDate() || "").getTime() - 6 * 60 * 60 * 1000) <=
					new Date(p.data_validta_a)
		)
	);
	const [refresh, setRefresh] = React.useState<boolean>(false);

	const nomeProgramma = (data: string | DateTime) => {
		return convertToDateTime(data)?.setLocale("it").toFormat('yy_MM_dd') + "_programma_macellazione";
	};
	//
	useEffect(() => {
		// const data_da = data;
		dispatch(resetLotti());
		data && dispatch(fetchLotti({ data_da: data?.toJSDate(), data_a: data?.toJSDate(), }));
		data && dispatch(fetchschedeCarico({ data_da: data?.toJSDate(), data_a: data?.toJSDate(), }));
		data && dispatch(fetchCamions(data.toJSDate()));
		data && dispatch(fetchAutisti(data.toJSDate()));
		setRefresh(false);
	}, [data, refresh]);

	return (
		<Box>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={
					lotti.errorsStack.status == ErrorStatusTypes.PENDING ||
					schedeCarico.errorsStack.status == ErrorStatusTypes.PENDING ||
					anagraficheErrorsStack.status == ErrorStatusTypes.PENDING
				}
			>
				<CircularProgress color="inherit" />
			</Backdrop>

			<NavigazioneGiorniToolbar
				titolo={"Ordini di carico"}
				setData={setData}
				data={data}
				refresh={() => {
					setRefresh(true);
				}}
			/>
			<Box sx={{ backgroundColor: "#999", height: "80vh" }}>
				{parametri && (
					<OrdiniCaricoPdf
						lotti={lotti.results.filter(
							(lotto) => lotto.stato_lotto.stato == 3
						)}
						parametri={parametri}
					/>
				)}
			</Box>

			<Stack
				direction="row"
				justifyContent="flex-start"
				alignItems="flex-start"
				mt={2}
				spacing={1}
			>
				<Button
					variant="contained"
					component={Link}
					to={{
						pathname: Routes_path.SCHEDE_CARICO,
					}}
					endIcon={<KeyboardReturnIcon />}
					// color="success"
				>
					Torna alle schede carico
				</Button>
				<Button
					variant="contained"
					endIcon={<SaveAltIcon />}
					onClick={() => {
						data &&
							lotti.results &&
							stampaConNome({
								Documento: (
									<Document>
										<Page
											size="A4"
											style={{
												backgroundColor: "#fff",
												paddingTop: 20,
											}}
											orientation="landscape"
											key={"scheda_macellazione"}
										>
											<ProgrammaMacellazionePdf lotti={lotti.results} />
										</Page>
									</Document>
								),
								nome: nomeProgramma(data),
							});
					}}
				>
					Salva Programma
				</Button>
			</Stack>
		</Box>
	);
};

export default OrdiniCaricoPage;
