import { handleResponse, handleError, headerset } from "../apiUtils";
import moment from "moment";
import { Resa } from "../../components/main/reportistica/rese/reseSlice";

// Rese #################################################################################

const reseBaseUrl = (rese_id?: number | null) => {
	if (rese_id) {
		return process.env.API_URL + "/api/rese/rese/" + rese_id + "/";
	} else {
		return process.env.API_URL + "/api/rese/rese/";
	}
};

export function fetchRese(data_da?: Date, data_a?: Date) {
	let url = reseBaseUrl();
	// moment(data_da).format("YYYY-MM-DD");
	if (data_da && data_a) {
		url += `?data_da=${moment(data_da).format("YYYY-MM-DD")}&data_a=${moment(
			data_a
		).format("YYYY-MM-DD")}`;
	} else if (data_da) {
		url += `?data_da=${moment(data_da).format("YYYY-MM-DD")}`;
	} else if (data_a) {
		url += `?data_a=${moment(data_a).format("YYYY-MM-DD")}`;
	}
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function getResa(resaId: number) {
	let url = reseBaseUrl(resaId);
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function saveResa(resa: Resa) {
	let url = reseBaseUrl(resa.id);
	return fetch(url, {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
		body: JSON.stringify({
			...resa,
		}),
	})
		.then(handleResponse)
		.catch(handleError);
}

export function switchConfermaResa(resaId: number) {
	let url = reseBaseUrl(resaId) + "switchConfermaResa/";
	return fetch(url, {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

const uploadBaseUrl = (
	nomeFile: string,
	rese_id: number,
	contatto_id: number
) => {
	let uploadBaseUrl =
		process.env.API_URL +
		"/api/rese/send_mail_resa/" +
		rese_id +
		"/" +
		nomeFile;
	uploadBaseUrl += "?contatto_id=" + contatto_id;
	return uploadBaseUrl;
};

export function mandaResaEmail(
	rese_id: number,
	file: File,
	contatto_id: number
) {
	return fetch(uploadBaseUrl(file.name, rese_id, contatto_id), {
		method: "PUT", // PUT to upload.
		headers: {
			"content-type": "application/json",
			Authorization: headerset().Authorization,
		},
		body: file,
	})
		.then(handleResponse)
		.catch(handleError);
}

// Veterinario #################################################################################

export function fetchVeterinario(data_da?: Date, data_a?: Date) {
	let url = process.env.API_URL + "/api/reportistica/veterinario/";
	// moment(data_da).format("YYYY-MM-DD");
	if (data_da && data_a) {
		url += `?data_da=${moment(data_da).format("YYYY-MM-DD")}&data_a=${moment(
			data_a
		).format("YYYY-MM-DD")}`;
	} else if (data_da) {
		url += `?data_da=${moment(data_da).format("YYYY-MM-DD")}`;
	} else if (data_a) {
		url += `?data_a=${moment(data_a).format("YYYY-MM-DD")}`;
	}
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function getVeterinario(lottoId: number) {
	const url =
		process.env.API_URL + "/api/reportistica/veterinario/" + lottoId + "/";
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

// Report Autista #################################################################################

const reportAutistiCamionBaseUrl = (consuntivo_vivo_id?: number | null) => {
	if (consuntivo_vivo_id) {
		return (
			process.env.API_URL +
			"/api/report_autisti_camion/report_autisti_camion/" +
			consuntivo_vivo_id +
			"/"
		);
	} else {
		return (
			process.env.API_URL + "/api/report_autisti_camion/report_autisti_camion/"
		);
	}
};

export function fetchReportAutistiCamion(data_da?: Date, data_a?: Date) {
	let url = reportAutistiCamionBaseUrl();
	// moment(data_da).format("YYYY-MM-DD");
	if (data_da && data_a) {
		url += `?data_da=${moment(data_da).format("YYYY-MM-DD")}&data_a=${moment(
			data_a
		).format("YYYY-MM-DD")}`;
	} else if (data_da) {
		url += `?data_da=${moment(data_da).format("YYYY-MM-DD")}`;
	} else if (data_a) {
		url += `?data_a=${moment(data_a).format("YYYY-MM-DD")}`;
	}
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function fetchReportAutisti(data_da?: Date, data_a?: Date) {
	let url = process.env.API_URL + "/api/report_autisti_camion/report_autisti/";
	// moment(data_da).format("YYYY-MM-DD");
	if (data_da && data_a) {
		url += `?data_da=${moment(data_da).format("YYYY-MM-DD")}&data_a=${moment(
			data_a
		).format("YYYY-MM-DD")}`;
	} else if (data_da) {
		url += `?data_da=${moment(data_da).format("YYYY-MM-DD")}`;
	} else if (data_a) {
		url += `?data_a=${moment(data_a).format("YYYY-MM-DD")}`;
	}
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function fetchReportAutistiIolepec(data_da?: Date, data_a?: Date) {
	let url =
		process.env.API_URL + "/api/report_autisti_camion/report_autisti_iolepec/";
	// moment(data_da).format("YYYY-MM-DD");
	if (data_da && data_a) {
		url += `?data_da=${moment(data_da).format("YYYY-MM-DD")}&data_a=${moment(
			data_a
		).format("YYYY-MM-DD")}`;
	} else if (data_da) {
		url += `?data_da=${moment(data_da).format("YYYY-MM-DD")}`;
	} else if (data_a) {
		url += `?data_a=${moment(data_a).format("YYYY-MM-DD")}`;
	}
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function fetchReportCamionsIolepec(data_da?: Date, data_a?: Date) {
	let url =
		process.env.API_URL + "/api/report_autisti_camion/report_camions_iolepec/";
	// moment(data_da).format("YYYY-MM-DD");
	if (data_da && data_a) {
		url += `?data_da=${moment(data_da).format("YYYY-MM-DD")}&data_a=${moment(
			data_a
		).format("YYYY-MM-DD")}`;
	} else if (data_da) {
		url += `?data_da=${moment(data_da).format("YYYY-MM-DD")}`;
	} else if (data_a) {
		url += `?data_a=${moment(data_a).format("YYYY-MM-DD")}`;
	}
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function getReportAutistaCamion(consuntivoVivoId: number) {
	const url = reportAutistiCamionBaseUrl(consuntivoVivoId);
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

// kgCommittente #################################################################################

export function fetchKgCommittenti(data_da?: Date, data_a?: Date) {
	let url = process.env.API_URL + "/api/kg_committenti/kg_committenti/";
	// moment(data_da).format("YYYY-MM-DD");
	if (data_da && data_a) {
		url += `?data_da=${moment(data_da).format("YYYY-MM-DD")}&data_a=${moment(
			data_a
		).format("YYYY-MM-DD")}`;
	} else if (data_da) {
		url += `?data_da=${moment(data_da).format("YYYY-MM-DD")}`;
	} else if (data_a) {
		url += `?data_a=${moment(data_a).format("YYYY-MM-DD")}`;
	}
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function getKgCommittenti(lottoId: number) {
	const url =
		process.env.API_URL + "/api/kg_committenti/kg_committenti/" + lottoId + "/";
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

// Grafico Tipi Capo - Committente ##############################################################

export function fetchGraficoTipiCapoCommittenti(data_da?: Date, data_a?: Date) {
	let url = process.env.API_URL + "/api/grafici/grafico_tipi_capo_committenti/";
	// moment(data_da).format("YYYY-MM-DD");
	if (data_da && data_a) {
		url += `?data_da=${moment(data_da).format("YYYY-MM-DD")}&data_a=${moment(
			data_a
		).format("YYYY-MM-DD")}`;
	} else if (data_da) {
		url += `?data_da=${moment(data_da).format("YYYY-MM-DD")}`;
	} else if (data_a) {
		url += `?data_a=${moment(data_a).format("YYYY-MM-DD")}`;
	}
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

// Grafico Tipi Capo mese x Committente ##############################################################
export function fetchGraficoCommittenteTipiCapoMese(
	committente_id: number,
	periodo: string
) {
	let url =
		process.env.API_URL +
		"/api/grafici/grafico_committente_tipi_capo/" +
		committente_id +
		"/?periodo=" +
		periodo;

	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

// Grafico Peso medio Tipi Capo settimana x Committente ##############################################################
export function fetchGraficoCommittenteTipiCapoPesoMedioSettimana(
	committente_id: number,
	periodo: string
) {
	let url =
		process.env.API_URL +
		"/api/grafici/pesomedio_settimana_committente_tipicapo/" +
		committente_id +
		"/?periodo=" +
		periodo;

	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

// Grafico Tipi Capo mese x Committente ##############################################################
export function fetchGraficoCommittenteReseSettimane(
	committente_id: number,
	periodo: string
) {
	let url =
		process.env.API_URL +
		"/api/grafici/grafico_committente_rese/" +
		committente_id +
		"/?periodo=" +
		periodo;

	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

// Indicatori Tipi Capo x Committente ##############################################################
export function fetchIndicatoriTotaliCommittenteTipiCapo(
	committente_id: number,
	periodo: string
) {
	let url =
		process.env.API_URL +
		"/api/grafici/indicatori_totali_committente_tipi_capo/" +
		committente_id +
		"/?periodo=" +
		periodo;

	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

// Indicatori Committenti ##############################################################
export function fetchIndicatoriTotaliCommittenti(periodo: string) {
	let url =
		process.env.API_URL +
		"/api/grafici/indicatori_totali_committenti/?periodo=" +
		periodo;

	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

// ##################################################################

export function fetchListaBIViaggiAutistiCamionMese(data: Date) {
	let url =
		process.env.API_URL +
		`/api/report_autisti_camion/lista_bi_viaggi_autisti_camion_mese/${moment(
			data
		).format("YYYY-MM-DD")}/`;
	// moment(data_da).format("YYYY-MM-DD");

	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}
// ##################################################################


export function fetchCSV_lavorazioni(lottoId: number) {
	const url = process.env.API_URL + `/api/pianificazione/lotti/${lottoId}/csv_lavorazioni`;
	return fetch(url, {
		method: "GET",
		headers: {
			// "Content-Type": "application/json",
			// Accept: "text/csv", // Cambia l'intestazione per accettare il formato CSV
			Authorization: headerset().Authorization, // Presumo che `headerset` restituisca l'autorizzazione
		},
	}).then((response) => {
		if (!response.ok) {
			throw new Error(`HTTP error! status: ${response.status}`);
		}
		return response;
	}).catch((error) => {
		console.error("Errore durante la richiesta CSV:", error);
		throw error;
	});
}
