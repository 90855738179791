import React, { useState, useEffect } from "react";
import { useAppDispatch } from "../../../../redux/store";

import {
	Lotto,
	ColoreTypes,
	saveLotto,
} from "../../pianificazione/pianificazioneSlice";
import {
	TipoCapo,
	Committente,
	Produttore,
	Allevamento,
} from "../../anagrafiche/anagraficheSlice";
import { DateTime } from 'luxon';
import { useForm, SubmitHandler, Controller } from "react-hook-form";

import { ForiImpegnati } from "../../../common/CalcoloCaricoAmmissibile";

import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import ReactHookFormSelect from "../../../common/ReactHookFormSelect";
import { Button } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Alert from "../../../common/Alert";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import {convertToDateTime} from "../../../common/dateUtils/convertToDateTime";

interface LottoOrdineClienteFormProps {
	lottoEdited: Lotto;
}

const LottoOrdineClienteForm = ({
	lottoEdited,
}: LottoOrdineClienteFormProps) => {
	const {
		handleSubmit,
		register,
		formState: { errors, isDirty },
		setValue,
		watch,
		getValues,
		control,
		setError,
		clearErrors,
		reset,
	} = useForm<Lotto>();
	const dispatch = useAppDispatch();

	const handlerDetailChaingSubmit = () => {
		if (isDirty) {
			const formValues = getValues();
			dispatch(saveLotto(formValues));
			reset({}, { keepValues: true });
		}
	};

	const listaCampi = Object.keys(lottoEdited) as Array<keyof Lotto>;

	// #############################################
	// Gestione calcolo numero fori
	// #############################################
	const [basatoSulNumFori, setBasatoSulNumFori] = useState(false);
	// const [inDubbio, setInDubbio] = useState(lottoEdited.in_dubbio);

	useEffect(() => {
		reset();
		listaCampi.map((field) => {
			setValue(field, lottoEdited[field]);
		});
	}, [
		listaCampi.forEach((field) => {
			lottoEdited[field];
		}),
		lottoEdited.id,
	]);

	useEffect(() => {
		clearErrors();
		listaCampi.forEach((field) => {
			lottoEdited?.errorsStack?.fieldsErrors &&
				lottoEdited.errorsStack.fieldsErrors[field] &&
				setError(field, {
					type: "server",
					message: lottoEdited.errorsStack.fieldsErrors[field].toString(),
				});
		});
	}, [
		lottoEdited?.errorsStack?.fieldsErrors,
		// lottoEdited.allevamento_pack?.errorsStack?.fieldsErrors,
	]);

	useEffect(() => {
		const dataOrdineCliente = watch("data_ordine_cliente");

		// Verifica se dataOrdineCliente è una stringa valida
		if (dataOrdineCliente && DateTime.fromJSDate(new Date(dataOrdineCliente)).isValid) {
			handlerDetailChaingSubmit();
		}


	}, [watch("data_ordine_cliente")]);

	const onSubmit: SubmitHandler<Lotto> = (lotto: Lotto) => {
		if (isDirty) {
			dispatch(saveLotto(lotto));
			reset({}, { keepValues: true });
		}
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			{lottoEdited?.errorsStack?.fieldsErrors?.non_field_errors && (
				<Alert severity="error">
					{lottoEdited?.errorsStack?.fieldsErrors?.non_field_errors.toString()}
				</Alert>
			)}

			<Grid
				container
				sx={{ flexGrow: 1, mb: 1 }}
				rowSpacing={1}
				columnSpacing={{ xs: 1, sm: 2, md: 3 }}
				// justifyContent="center"
				alignItems="flex-start"
			>
				<Grid size={{ xs: 12, sm:6, md:2 }}>
					<TextField
						id="ordine_cliente"
						label="n° ordine cliente"
						// disabled={getValues("confermato")}
						{...register("ordine_cliente")}
						error={!!errors?.ordine_cliente}
						helperText={errors?.ordine_cliente?.message}
						InputLabelProps={{
							shrink: true,
						}}
						variant="outlined"
						autoComplete={"off"}
						onBlur={() => {
							handleSubmit(onSubmit)();
						}}
						sx={{ width: "100%" }}
					/>
				</Grid>
				<Grid size={{ xs: 12, sm:6, md:2 }}>
					<DesktopDatePicker
						label="data ordine cliente"
						value={convertToDateTime(watch("data_ordine_cliente"))|| null}
						slotProps={{
							textField: {
								required: false,
								error: !!errors?.data_ordine_cliente,
								helperText: errors?.data_ordine_cliente?.message,
								sx: {width: "100%"},
								autoComplete: "off",
								InputLabelProps: {
									shrink: true,
								},
								// disabled: !!richiestaConnessione.stato
							},
							field: {
								clearable: true,
							},
						}}
						onChange={(newValue: any) => {
							console.log("newValue", newValue);

							setValue(
								"data_ordine_cliente",
								newValue ? convertToDateTime(newValue)?.toFormat("yyyy-MM-dd") : "",
								{
									shouldDirty: true,
								}
							);
							clearErrors("data_ordine_cliente");
						}}
					/>
				</Grid>
			</Grid>
			{/* <Grid container sx={{ flexGrow: 1, mb: 1 }} spacing={2}>
				<Grid size={{ xs:12 }}>
					<Stack direction="row" spacing={2} justifyContent="center">
						<Button disabled={!isDirty} variant="outlined" type="submit">
							{lottoEdited.id ? "Salva & Continua" : "Crea & Continua"}
						</Button>
					</Stack>
				</Grid>
			</Grid> */}
		</form>
	);
};

export default LottoOrdineClienteForm;
