import React, { useState, useEffect } from "react";
import { SchedaCarico } from "../../schedeCaricoSlice";
import { Camion, Autista } from "../../../anagrafiche/anagraficheSlice";
import { Link } from "react-router-dom";

import { useForm, SubmitHandler, Controller } from "react-hook-form";

import { ForiImpegnati } from "../../../../common/CalcoloCaricoAmmissibile";
import ConfermaAnnulla from "../../../../common/ConfermaAnnulla";

import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import ReactHookFormSelect from "../../../../common/ReactHookFormSelect";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import PrintIcon from "@mui/icons-material/Print";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import SmsIcon from "@mui/icons-material/Sms";

import { styled } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import {Routes_path} from "../../../../routerApp";

interface SchedaCaricoFormProps {
	schedaCarico: SchedaCarico;
	saveSchedaCarico: (schedaCarico: SchedaCarico) => void;
	deleteSchedaCarico: (schedaCarico: SchedaCarico) => void;
	mandaWhatsAppAutisti: (schedaCarico_id: number) => void;
	mandaSmsAppAutisti: (schedaCarico_id: number) => void;

	camions: Camion[];
	listaAutisti: Autista[];
}

const SchedaCaricoForm = ({
	schedaCarico,
	saveSchedaCarico,
	deleteSchedaCarico,
	mandaWhatsAppAutisti,
	mandaSmsAppAutisti,
	camions,
	listaAutisti,
}: SchedaCaricoFormProps) => {
	const {
		handleSubmit,
		register,
		formState: { errors, isDirty },
		setValue,
		watch,
		getValues,
		control,
		setError,
		clearErrors,
		reset,
	} = useForm<SchedaCarico>();

	const listaCampi = Object.keys(schedaCarico) as Array<keyof SchedaCarico>;

	useEffect(() => {
		listaCampi.map((field) => {
			setValue(field, schedaCarico[field]);
		});
	}, [
		listaCampi.forEach((field) => {
			schedaCarico[field];
		}),
	]);

	useEffect(() => {
		setValue("camion", schedaCarico["camion"]);
	}, [schedaCarico.camion]);

	useEffect(() => {
		setValue("autisti", schedaCarico["autisti"]);
	}, [JSON.stringify(schedaCarico.autisti)]);

	useEffect(() => {
		listaCampi.forEach((field) => {
			schedaCarico?.errorsStack?.fieldsErrors &&
				schedaCarico.errorsStack.fieldsErrors[field] &&
				setError(field, {
					type: "server",
					message: schedaCarico.errorsStack.fieldsErrors[field].toString(),
				});
		});
	}, [schedaCarico?.errorsStack?.fieldsErrors]);

	// useEffect(() => {
	// 	reset({}, { keepValues: true });
	// }, [schedaCarico, reset]);

	const onSubmit: SubmitHandler<SchedaCarico> = (schedaCarico) => {
		saveSchedaCarico(schedaCarico);
	};

	// #############################################
	// Gestione calcolo numero fori
	// #############################################
	const [basatoSulNumFori, setBasatoSulNumFori] = useState(true);

	const camionCorrente = (camion_id?: number | null): Camion | undefined => {
		if (camion_id) {
			return camions.find((camion) => camion.id == camion_id);
		}
	};

	const labelForiConstructor = () => {
		const camion: Camion | undefined = camionCorrente(getValues("camion"));
		const foriImpegnati = ForiImpegnati(
			getValues("numero_fori"),
			camion,
			getValues("rimorchio")
		);

		const labelFori: string = "fori (" + foriImpegnati + ")";

		return labelFori;
	};

	React.useEffect(() => {
		const camion: Camion | undefined = camionCorrente(getValues("camion"));

		const foriImpegnati = ForiImpegnati(
			getValues("numero_fori"),
			camion,
			getValues("rimorchio")
		);
		clearErrors("numero_fori");
		if ((getValues("numero_fori") || 0) > foriImpegnati) {
			setError("numero_fori", {
				type: "server",
				message: "fori pianificati maggiori dei fori disponibili",
			});
		}
	}, [watch("camion"), watch("numero_fori"), watch("rimorchio")]);

	React.useEffect(() => {
		const numero_capi_pianificati = getValues("numero_capi_pianificati");
		if (numero_capi_pianificati) {
			if (!basatoSulNumFori) {
				const numero_capi_foro = getValues("numero_capi_foro");
				numero_capi_foro &&
					numero_capi_foro > 0 &&
					setValue(
						"numero_fori",
						Math.ceil(numero_capi_pianificati / numero_capi_foro)
					);
			} else {
				const numero_fori = getValues("numero_fori");
				numero_fori &&
					numero_fori > 0 &&
					setValue(
						"numero_capi_foro",
						Math.ceil(numero_capi_pianificati / numero_fori)
					);
			}
		}
	}, [watch("numero_capi_pianificati")]);

	React.useEffect(() => {
		const numero_fori = getValues("numero_fori");
		const numero_capi_pianificati = getValues("numero_capi_pianificati");
		const numero_capi_foro = getValues("numero_capi_foro");
		if (numero_capi_pianificati) {
			if (basatoSulNumFori) {
				numero_capi_pianificati &&
					numero_fori &&
					setValue(
						"numero_capi_foro",
						Math.ceil(numero_capi_pianificati / numero_fori)
					);
			} else {
				numero_capi_foro &&
					numero_capi_foro > 0 &&
					setValue(
						"numero_fori",
						Math.ceil(numero_capi_pianificati / numero_capi_foro)
					);
			}
		}
	}, [watch("numero_fori"), watch("numero_capi_foro")]);

	React.useEffect(() => {
		const camion: Camion | undefined = camionCorrente(getValues("camion"));

		clearErrors("camion");
		if (getValues("camion")) {
			if (
				getValues("rimorchio") == true &&
				getValues("rimorchio") != camion?.autotreno
			) {
				setError("camion", {
					type: "server",
					message: "il camion selezionato non prevede il rimorchio",
				});
			}
		}
	}, [watch("camion"), watch("rimorchio")]);

	// #############################################

	//  ######################################################
	//  ##### Gestione conferma cancellazione  #############
	//  ######################################################
	const handlerDeleteSchedaCarico = () => {
		deleteSchedaCarico(schedaCarico);
		handleConfermaClose();
	};
	const [openConferma, setOpenConferma] = React.useState(false);
	const handleModalopenConfermaOpen = () => setOpenConferma(true);
	const handleConfermaClose = () => setOpenConferma(false);
	//  ######################################################

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			{/* {schedaCarico?.errorsStack?.fieldsErrors?.non_field_errors && (
        <Alert severity="error">
          {schedaCarico?.errorsStack?.fieldsErrors?.non_field_errors.toString()}
        </Alert>
      )} */}

			<Grid
				container
				sx={{ flexGrow: 1, mb: 1 }}
				rowSpacing={1}
				columnSpacing={{ xs: 1, sm: 2, md: 3 }}
				justifyContent="center"
				alignItems="flex-start"
			>
				<Grid item xs={12} sm={6} md={5}>
					<ReactHookFormSelect
						name="autisti"
						label="autisti"
						register={register}
						control={control}
						watch={watch}
						variant="outlined"
						multiple={true}
						// error={errors?.autisti?.message}
						sx={{ width: "100%" }}
					>
						{listaAutisti.map((option) => {
							return (
								<MenuItem key={option.id} value={option.id}>
									{option.nome} {option.cognome}
								</MenuItem>
							);
						})}
					</ReactHookFormSelect>
				</Grid>
				<Grid item xs={12} sm={6} md={4}>
					<ReactHookFormSelect
						name="camion"
						label="camion"
						register={register}
						control={control}
						watch={watch}
						variant="outlined"
						error={errors?.camion?.message}
						sx={{ width: "100%" }}
					>
						{camions
							.filter((camion) => !camion.in_manutenzione)
							.map((option) => {
								return (
									<MenuItem key={option.id} value={option.id}>
										{option.nome} - {option.targa}
									</MenuItem>
								);
							})}
					</ReactHookFormSelect>
				</Grid>

				<Grid item xs={12} sm={2} md={1}>
					<FormControlLabel
						label={
							<Typography fontSize="0.8rem" marginLeft="0.1rem">
								{getValues("rimorchio") ? "con rimorchio" : "senza rimorchio"}
							</Typography>
						}
						control={
							<Switch
								id="rimorchio"
								checked={watch("rimorchio") || false}
								onChange={(
									event: React.ChangeEvent<HTMLInputElement>,
									newValue: any
								) => {
									setValue("rimorchio", newValue, {
										shouldDirty: true,
									});
								}}
								color="info"
								size="small"
							/>
						}
					/>
				</Grid>
			</Grid>
			<Grid
				container
				sx={{ flexGrow: 1, mb: 1 }}
				rowSpacing={1}
				columnSpacing={{ xs: 1, sm: 2, md: 3 }}
				justifyContent="center"
				alignItems="flex-start"
			>
				<Grid item xs={12} sm={6} md={3}>
					<DateTimePicker
						label="ora carico"
						disabled={schedaCarico.stato_schedulazione.stato < 1}
						// {...register("data_ora_carico_pianificata")}
						value={
							watch("data_ora_carico_pianificata") ||
							schedaCarico.data_ora_carico_pianificata
						}
						renderInput={(params) => (
							<TextField
								{...params}
								error={!!errors?.data_ora_carico_pianificata}
								helperText={errors?.data_ora_carico_pianificata?.message}
								sx={{ width: "100%" }}
								autoComplete={"off"}
								// {...register("data_ora_carico_pianificata")}
							/>
						)}
						onChange={(newValue: any) => {
							setValue(
								"data_ora_carico_pianificata",
								newValue ? new Date(newValue) : "",
								{
									shouldDirty: true,
								}
							);
							clearErrors("data_ora_carico_pianificata");
						}}

						// inputFormat="DD/MM/YYYY HH:MM"
					/>
				</Grid>
				<Grid item xs={12} sm={6} md={2}>
					<TextField
						label="numero capi pianificati"
						// disabled={schedaCarico.numero_capi_pianificati}
						variant="outlined"
						InputLabelProps={{
							shrink: true,
						}}
						{...register("numero_capi_pianificati")}
						error={!!errors?.numero_capi_pianificati}
						helperText={errors?.numero_capi_pianificati?.message}
						autoComplete={"off"}
						sx={{ width: "100%" }}
					/>
				</Grid>
				<Grid item xs={12} sm={2}>
					<TextField
						id="numero_capi_foro"
						label="capi x foro"
						{...register("numero_capi_foro")}
						error={!!errors?.numero_capi_foro}
						helperText={errors?.numero_capi_foro?.message}
						type="number"
						InputLabelProps={{
							shrink: true,
						}}
						variant="outlined"
						autoComplete={"off"}
						disabled={basatoSulNumFori}
					/>
				</Grid>
				<Grid item xs={12} sm={1}>
					<Switch
						checked={basatoSulNumFori}
						onChange={() => setBasatoSulNumFori(!basatoSulNumFori)}
						color="default"
						size="small"
					/>
				</Grid>
				<Grid item xs={12} sm={2}>
					<TextField
						id="numero_fori"
						label={labelForiConstructor()}
						{...register("numero_fori")}
						error={!!errors?.numero_fori}
						helperText={errors?.numero_fori?.message}
						type="number"
						InputLabelProps={{
							shrink: true,
						}}
						variant="outlined"
						autoComplete={"off"}
						disabled={!basatoSulNumFori}
					/>
				</Grid>
			</Grid>
			<Grid
				container
				sx={{ flexGrow: 1, mb: 1 }}
				rowSpacing={1}
				columnSpacing={{ xs: 1, sm: 2, md: 3 }}
				justifyContent="center"
				alignItems="flex-start"
			>
				<Grid item xs={12} sm={6} md={10}>
					<TextField
						id="note"
						label="note per autista"
						{...register("note")}
						InputLabelProps={{
							shrink: true,
						}}
						variant="outlined"
						fullWidth
						multiline={true}
						rows={3}
						disabled={schedaCarico.stato_schedulazione.stato < 1}
						error={!!errors?.note}
						helperText={errors?.note?.message}
						autoComplete={"off"}
						sx={{ width: "100%" }}
					/>
				</Grid>
			</Grid>

			<Grid sx={{ flexGrow: 1, mt: 3 }} container spacing={2}>
				<Grid item xs={12}>
					<Stack direction="row" spacing={2} justifyContent="center">
						<Button disabled={!isDirty} variant="outlined" type="submit">
							Salva
						</Button>

						<Button
							startIcon={<PrintIcon />}
							variant="outlined"
							component={Link}
							to={{
								pathname: Routes_path.ORDINE_CARICO + schedaCarico.id,
								// state: {
								//   schedaCarico,
								// },
							}}
							state={{
								schedaCaricoScelta: schedaCarico,
							}}
						>
							Ordine di Carico
						</Button>
						<Button
							startIcon={<SmsIcon />}
							variant="outlined"
							// disabled={true}
							disabled={schedaCarico.autisti.length == 0}
							onClick={() => {
								mandaSmsAppAutisti(schedaCarico.id || 0);
							}}
						>
							SMS Autisti
						</Button>
						<Button
							startIcon={<WhatsAppIcon />}
							variant="outlined"
							// disabled={true}
							disabled={schedaCarico.autisti.length == 0}
							onClick={() => {
								mandaWhatsAppAutisti(schedaCarico.id || 0);
							}}
						>
							WhatsApp Autisti
						</Button>
						<Button variant="outlined" onClick={handleModalopenConfermaOpen}>
							Elimina Scheda
						</Button>
					</Stack>
					<ConfermaAnnulla
						handleAnnulla={handleConfermaClose}
						handleConferma={handlerDeleteSchedaCarico}
						domanda={"Sicuro di voler eliminare la scheda di carico"}
						open={openConferma}
					/>
				</Grid>
			</Grid>
		</form>
	);
};

export default SchedaCaricoForm;
