import React from "react";
import { GraficoCommittenteReseSettimane } from "../../reportistica/grafici/graficiKpiSlice";
import { DateTime } from 'luxon';

import {
	LineChart,
	Line,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer,
} from "recharts";
// import {
// 	ValueType,
// 	NameType,
// } from "recharts/src/component/DefaultTooltipContent";

import { stringToColour } from "../../../common/stringToColour";
import {convertToDateTime} from "../../../common/dateUtils/convertToDateTime";
const generaData = (
	graficoCommittenteReseSettimane: GraficoCommittenteReseSettimane[]
) => {
	const data: {}[] = [];

	const settimane: string[] = [];
	graficoCommittenteReseSettimane.map((settimana_tipo_resa) => {
		if (!settimane.includes(settimana_tipo_resa.settimana)) {
			settimane.push(settimana_tipo_resa.settimana);
		}
	});

	const tipiCapoLine: string[] = [];

	settimane.map((settimana) => {
		var dataRecord: any = {};
		dataRecord["settimana"] =
			"W" + convertToDateTime(settimana)?.setLocale("it").toFormat('WW');
		graficoCommittenteReseSettimane
			.filter(
				(settimana_tipo_resa) => settimana_tipo_resa.settimana == settimana
			)
			.map((tipo_capo) => {
				dataRecord[tipo_capo.tipo_capo] = tipo_capo.resa;
				if (tipo_capo.resa > 0) {
					if (!tipiCapoLine.includes(tipo_capo.tipo_capo)) {
						tipiCapoLine.push(tipo_capo.tipo_capo);
					}
				}
			});
		data.push(dataRecord);
	});

	return { data: data, tipiCapoLine: tipiCapoLine };
};

interface CommittenteReseSettimaneGraficoProps {
	graficoCommittenteReseSettimane: GraficoCommittenteReseSettimane[];
}
const CommittenteReseSettimaneGrafico = ({
	graficoCommittenteReseSettimane,
}: CommittenteReseSettimaneGraficoProps) => {
	return (
		<ResponsiveContainer>
			<LineChart
				// width={400}
				// height={200}
				data={generaData(graficoCommittenteReseSettimane).data}
				margin={{
					top: 8,
					right: 5,
					left: -15,
					bottom: 5,
				}}
			>
				<CartesianGrid strokeDasharray="3 3" />
				<XAxis
					dataKey="settimana"
					angle={-90}
					fontSize={12}
					tickMargin={13}
					interval={0}
				/>
				<YAxis
					type="number"
					domain={[0.4, 0.9]}
					tickFormatter={(value) =>
						Intl.NumberFormat("it-IT", {
							minimumFractionDigits: 0,
							maximumFractionDigits: 0,
						}).format(value * 100) + "%"
					}
					fontSize={13}
				/>
				<Tooltip
					formatter={(value) => {
						if (typeof value == "number") {
							return (
								Intl.NumberFormat("it-IT", {
									minimumFractionDigits: 2,
									maximumFractionDigits: 2,
								})
									.format(value * 100)
									.toString() + "%"
							);
						} else {
							return value;
						}
					}}
					wrapperStyle={{ fontSize: 13 }}
				/>
				<Legend
					wrapperStyle={{
						fontSize: 13,
						position: "sticky",
						bottom: "15%",
					}}
				/>
				{generaData(graficoCommittenteReseSettimane).tipiCapoLine.map(
					(tipo) => {
						return (
							<Line
								key={"key_" + tipo}
								type="monotone"
								dataKey={tipo}
								stroke={stringToColour(tipo)}
							/>
						);
					}
				)}
			</LineChart>
		</ResponsiveContainer>
	);
};
export default CommittenteReseSettimaneGrafico;
