import React from "react";
import { useEffect } from "react";
import {useParams} from "react-router-dom";

import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../../../redux/store";
import { Lotto, getLotto } from "../../../pianificazione/pianificazioneSlice";
import {Lavorazione, getLavorazione,} from "../../../lavorazioni/lavorazioniSlice";
import GrandeImballoPdf from "./GrandeImballoPdf";
import { PDFViewer, Page, Document } from "@react-pdf/renderer";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";


const GrandeImballoStampaPage = () => {
	
	const dispatch = useAppDispatch();
	const { lavorazione_id } = useParams();
	const lavorazioneId: number = parseInt(lavorazione_id || '0');

	useEffect(() => {
		console.log("lavorazioneId", lavorazioneId);
		lavorazioneId && dispatch(getLavorazione({lavorazioneId: lavorazioneId}));
	}, [lavorazioneId]);

	const lavorazione: Lavorazione | undefined = useSelector<
		RootState,
		Lavorazione | undefined
	>((state) =>
		state.lavorazioniState.lavorazioni.results.find(
			(lavorazione) => lavorazione.id == lavorazioneId
		)
	);

	useEffect(() => {
		console.log("lavorazione?.lotto=== ", lavorazione?.lotto);
		lavorazione?.lotto && dispatch(getLotto(lavorazione.lotto));
	}, [lavorazione?.lotto]);

	const lotto: Lotto | undefined = useSelector<RootState, Lotto | undefined>(
		(state) =>
			state.pianificazione.lotti.results.find(
				(lotto) => lotto.id == lavorazione?.lotto
			)
	);

	return (
		<Box>
			{/* <Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={
					lotto.errorsStack.status == ErrorStatusTypes.PENDING ||
					anagraficheErrorsStack.status == ErrorStatusTypes.PENDING
				}
			>
				<CircularProgress color="inherit" />
			</Backdrop> */}

			<Box sx={{ backgroundColor: "#999", height: "80vh" }}>
				{(lavorazione && lotto?.id) ? (
					<PDFViewer width={"100%"} height={"100%"} key={`lavorazione_${lavorazioneId}_lotto_${lotto?.id}`}>
						<Document>
							<Page
								size="A4"
								orientation="portrait"
								style={{
									backgroundColor: "#fff",
									paddingTop: 20,
								}}
								key={"grande_imballo" + lavorazioneId}
							>
								{/* <View
								style={{
									flexDirection: "row",
									justifyContent: "center",
								}}
							>
								eeeeeeeeee
							</View> */}
								<GrandeImballoPdf lotto={lotto} lavorazione={lavorazione} />
							</Page>
						</Document>
					</PDFViewer>
				) : (
					<Typography variant="h4" gutterBottom sx={{ p: 30 }} align="center">
						Nessuna lavorazione trovata.
					</Typography>
				)}
			</Box>
		</Box>
	);
};

export default GrandeImballoStampaPage;
