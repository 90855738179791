import React from "react";
import ReactPDF from "@react-pdf/renderer";
import { DateTime } from 'luxon';

import { PDFViewer, Page, Document, StyleSheet } from "@react-pdf/renderer";

import { Lotto } from "../../pianificazione/pianificazioneSlice";
import { Parametri } from "../../anagrafiche/anagraficheSlice";
import FogliOrdiniCaricoPdf from "./FogliOrdiniCaricoPdf";
import ProgrammaMacellazionePdf from "./ProgrammaMacellazionePdf";
import FogliModuliDisinfezionePdf from "./FogliModuliDisinfezionePdf";
import EtichetteGabbiePdf from "./EtichetteGabbiePdf";

// Create styles
const styles = StyleSheet.create({
	page: {
		backgroundColor: "#fff",
		paddingTop: 20,
	},
	section: {
		marginTop: 10,
		marginLeft: 10,
		marginRight: 10,
		padding: 10,
	},
	sectionIntestazione: {
		marginTop: 5,
		marginLeft: 10,
		marginRight: 10,
		padding: 10,
		paddingTop: 10,
		paddingBottom: 7,
		backgroundColor: "#777777",
		color: "#ffffff",
	},
	text: {
		fontSize: 12,
		fontStyle: "normal",
	},
});

interface OrdiniCaricoPdfProps {
	lotti: Lotto[];
	parametri: Parametri;
}

const OrdiniCaricoPdf = ({ lotti, parametri }: OrdiniCaricoPdfProps) => (
	<PDFViewer width={"100%"} height={"100%"}>
		<Document>
			<Page
				size="A4"
				style={styles.page}
				orientation="landscape"
				key={"scheda_macellazione"}
			>
				<ProgrammaMacellazionePdf lotti={lotti} />
			</Page>
			<Page
				size="A4"
				style={styles.page}
				orientation="landscape"
				key={"scheda_macellazione"}
			>
				<ProgrammaMacellazionePdf lotti={lotti} />
			</Page>
			<Page
				size="A4"
				style={styles.page}
				orientation="landscape"
				key={"scheda_macellazione"}
			>
				<ProgrammaMacellazionePdf lotti={lotti} />
			</Page>
			<Page
				size="A4"
				style={styles.page}
				orientation="landscape"
				key={"scheda_macellazione"}
			>
				<ProgrammaMacellazionePdf lotti={lotti} />
			</Page>
			<Page
				size="A4"
				style={styles.page}
				orientation="landscape"
				key={"scheda_macellazione"}
			>
				<ProgrammaMacellazionePdf lotti={lotti} />
			</Page>
			<Page
				size="A4"
				style={styles.page}
				orientation="landscape"
				key={"scheda_macellazione"}
			>
				<ProgrammaMacellazionePdf lotti={lotti} />
			</Page>
			<Page
				size="A4"
				style={styles.page}
				orientation="landscape"
				key={"scheda_macellazione"}
			>
				<ProgrammaMacellazionePdf lotti={lotti} />
			</Page>
			<Page
				size="A4"
				style={styles.page}
				orientation="landscape"
				key={"scheda_macellazione"}
			>
				<ProgrammaMacellazionePdf lotti={lotti} />
			</Page>
			<Page
				size="A4"
				style={styles.page}
				orientation="landscape"
				key={"scheda_macellazione"}
			>
				<ProgrammaMacellazionePdf lotti={lotti} />
			</Page>
			<Page
				size="A4"
				style={styles.page}
				orientation="landscape"
				key={"scheda_macellazione"}
			>
				<ProgrammaMacellazionePdf lotti={lotti} />
			</Page>
			<Page
				size="A4"
				style={styles.page}
				orientation="landscape"
				key={"scheda_macellazione"}
			>
				<ProgrammaMacellazionePdf lotti={lotti} />
			</Page>
			{lotti.map((lotto) => (
				<React.Fragment key={"lotto_" + lotto.id}>
					{lotto.schede_carico.map((schedaCarico) => (
						<React.Fragment key={"schedaCarico_" + schedaCarico.id}>
							{/* <Page
								size="A4"
								style={styles.page}
								key={"scheda_carico_" + schedaCarico.id}
							>
								<FogliOrdiniCaricoPdf
									lotto={lotto}
									schedaCarico={schedaCarico}
								/>
								<FogliModuliDisinfezionePdf
									lotto={lotto}
									schedaCarico={schedaCarico}
									parametri={parametri}
								/>
							</Page> */}
							<Page
								size="A4"
								style={styles.page}
								// orientation="landscape"
								key={"etich_scheda_carico_" + schedaCarico.id}
							>
								<EtichetteGabbiePdf lotto={lotto} schedaCarico={schedaCarico} />
							</Page>
						</React.Fragment>
					))}
				</React.Fragment>
			))}
		</Document>
	</PDFViewer>
);

export default OrdiniCaricoPdf;
