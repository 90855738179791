import React, { useState, useEffect } from "react";
import {
	TipoCapo,
	Committente,
	Produttore,
	Allevamento,
	Provincia,
} from "../../anagrafiche/anagraficheSlice";

import { useForm, SubmitHandler, Controller } from "react-hook-form";

import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";
import ReactHookFormSelect from "../../../common/ReactHookFormSelect";
import { Button } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Alert from "../../../common/Alert";
import Switch from "@mui/material/Switch";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

interface AllevamentoFormProps {
	allevamento: Allevamento;
	saveAllevamento: (allevamento: Allevamento) => void;
	handleAnnulla: () => void;
	province: Provincia[];
}

const AllevamentoForm = ({
	allevamento,
	saveAllevamento,
	handleAnnulla,
	province,
}: AllevamentoFormProps) => {
	const {
		handleSubmit,
		register,
		formState: { errors, isDirty },
		setValue,
		watch,
		getValues,
		control,
		setError,
		reset,
		clearErrors,
	} = useForm<Allevamento>();

	const listaCampi = Object.keys(allevamento) as Array<keyof Allevamento>;

	useEffect(() => {
		listaCampi.map((field) => {
			setValue(field, allevamento[field]);
		});
	}, [
		listaCampi.forEach((field) => {
			allevamento[field];
		}),
	]);

	// useEffect(() => {
	// 	reset({}, { keepValues: true });
	// }, [allevamento, reset]);

	useEffect(() => {
		listaCampi.forEach((field) => {
			allevamento?.errorsStack?.fieldsErrors &&
				allevamento.errorsStack.fieldsErrors[field] &&
				setError(field, {
					type: "server",
					message: allevamento.errorsStack.fieldsErrors[field].toString(),
				});
		});
	}, [allevamento?.errorsStack?.fieldsErrors]);

	const onSubmit: SubmitHandler<Allevamento> = (allevamento) => {
		saveAllevamento(allevamento);
	};
	const salvaChiudi: SubmitHandler<Allevamento> = (allevamento) => {
		saveAllevamento(allevamento);
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			{allevamento?.errorsStack?.fieldsErrors?.non_field_errors && (
				<Alert severity="error">
					{allevamento?.errorsStack?.fieldsErrors?.non_field_errors.toString()}
				</Alert>
			)}
			<Paper
				sx={{
					bgcolor: "inherit",
					border: 1,
					borderColor: "primary.main",
					p: 3,
					mb: 3,
				}}
				elevation={4}
			>
				<Grid
					container
					sx={{ flexGrow: 1, mb: 1 }}
					rowSpacing={1}
					columnSpacing={{ xs: 1, sm: 2, md: 3 }}
					justifyContent="center"
					alignItems="flex-start"
				>
					<Grid size={{ sm:12, md:6 }}>
						<Grid
							container
							sx={{ flexGrow: 1, mb: 1 }}
							rowSpacing={1}
							columnSpacing={{ xs: 1, sm: 2, md: 3 }}
							justifyContent="center"
							alignItems="flex-start"
						>
							<Grid size={{ sm:12, md:4 }}>
								<TextField
									id="codice_asl"
									// disabled={getValues("confermato")}
									label="codice_asl"
									{...register("codice_asl")}
									InputLabelProps={{
										shrink: true,
									}}
									disabled
									variant="outlined"
									fullWidth
									autoComplete={"off"}
									error={!!errors?.codice_asl}
									helperText={errors?.codice_asl?.message}
								/>
							</Grid>
							<Grid size={{  sm:12, md:8 }}>
								<TextField
									id="nome"
									// disabled={getValues("confermato")}
									label="nome"
									{...register("nome")}
									InputLabelProps={{
										shrink: true,
									}}
									disabled
									variant="outlined"
									fullWidth
									autoComplete={"off"}
									error={!!errors?.nome}
									helperText={errors?.nome?.message}
								/>
							</Grid>

							<Grid size={{ sm:12, md:4 }} justifyContent="center">
								<TextField
									id="distanza"
									label="distanza"
									// disabled={getValues("confermato")}
									{...register("distanza")}
									type="number"
									InputLabelProps={{
										shrink: true,
									}}
									variant="outlined"
									fullWidth
									autoComplete={"off"}
									InputProps={{
										inputMode: "numeric",
										// pattern: "[0-9]*",
										endAdornment: (
											<InputAdornment position="end">km</InputAdornment>
										),
									}}
									error={!!errors?.distanza}
									helperText={errors?.distanza?.message}
								/>
							</Grid>
							<Grid size={{ sm:12, md:4 }} justifyContent="center">
								<TextField
									id="distanza_concordata"
									label="distanza_concordata"
									// disabled={getValues("confermato")}
									{...register("distanza_concordata")}
									type="number"
									InputLabelProps={{
										shrink: true,
									}}
									variant="outlined"
									fullWidth
									autoComplete={"off"}
									InputProps={{
										inputMode: "numeric",
										// pattern: "[0-9]*",
										endAdornment: (
											<InputAdornment position="end">km</InputAdornment>
										),
									}}
									error={!!errors?.distanza_concordata}
									helperText={errors?.distanza_concordata?.message}
								/>
							</Grid>
							<Grid size={{ sm:12, md:4 }} justifyContent="center">
								<FormControlLabel
									label={
										<Typography
											fontSize="0.8rem"
											marginLeft="0.1rem"
											sx={{
												color: !getValues("consente_rimorchio")
													? "red"
													: "inerit",
											}}
										>
											{getValues("consente_rimorchio")
												? "consente rimorchio"
												: "non consente rimorchio"}
										</Typography>
									}
									control={
										<Switch
											id="consente_rimorchio"
											// disabled={getValues("confermato")}
											checked={watch("consente_rimorchio") || false}
											onChange={(
												event: React.ChangeEvent<HTMLInputElement>,
												newValue: any
											) => {
												setValue("consente_rimorchio", newValue, {
													shouldDirty: true,
												});
											}}
											color="success"
											size="small"
										/>
									}
								/>
							</Grid>
						</Grid>
					</Grid>

					<Grid size={{  xs:12, sm:6, md:6 }}>
						<TextField
							id="note_di_contatto"
							label="note di contatto"
							{...register("note_di_contatto")}
							InputLabelProps={{
								shrink: true,
							}}
							variant="outlined"
							fullWidth
							multiline={true}
							rows={3}
							autoComplete={"off"}
							error={!!errors?.note_di_contatto}
							helperText={errors?.note_di_contatto?.message}
						/>
					</Grid>
				</Grid>
				<Grid
					container
					sx={{ flexGrow: 1, mb: 1 }}
					rowSpacing={1}
					columnSpacing={{ xs: 1, sm: 2, md: 3 }}
					justifyContent="center"
					alignItems="flex-start"
				>
					<Grid size={{ sm:12, md:4 }}>
						<TextField
							id="indirizzo"
							// disabled={getValues("confermato")}
							label="indirizzo"
							{...register("indirizzo")}
							InputLabelProps={{
								shrink: true,
							}}
							variant="outlined"
							fullWidth
							autoComplete={"off"}
							error={!!errors?.indirizzo}
							helperText={errors?.indirizzo?.message}
						/>
					</Grid>
					<Grid size={{ xs: 12, sm:6, md:2 }}>
						<TextField
							id="cap"
							label="cap"
							// disabled={getValues("confermato")}
							{...register("cap")}
							InputLabelProps={{
								shrink: true,
							}}
							variant="outlined"
							fullWidth
							autoComplete={"off"}
							error={!!errors?.cap}
							helperText={errors?.cap?.message}
						/>
					</Grid>
					<Grid size={{ xs:12, sm:6, md:3 }}>
						<TextField
							id="comune"
							label="comune"
							// disabled={getValues("confermato")}
							{...register("comune")}
							InputLabelProps={{
								shrink: true,
							}}
							variant="outlined"
							fullWidth
							autoComplete={"off"}
							error={!!errors?.comune}
							helperText={errors?.comune?.message}
						/>
					</Grid>
					<Grid size={{ xs:12, sm:6, md:3 }}>
						<Autocomplete
							key={"provincia_" + watch("provincia")}
							// disabled={getValues("confermato")}
							value={province.find(
								(provincia) => provincia.codice_provincia == watch("provincia")
							)}
							isOptionEqualToValue={(option, value) =>
								option.codice_provincia === value.codice_provincia
							}
							onChange={(event: any, newValue: any) => {
								setValue(
									"provincia",
									newValue ? newValue.codice_provincia : null,
									{
										shouldDirty: true,
									}
								);
								clearErrors("provincia");
							}}
							id="provincia"
							options={province}
							getOptionLabel={(option) =>
								option.name + " " + option.codice_targa
							}
							renderOption={(props, option) => (
								<Box component="li" {...props}>
									{option.name + " " + option.codice_targa}
								</Box>
							)}
							renderInput={(params: any) => (
								<TextField
									variant="outlined"
									{...params}
									label="Provincia"
									error={!!errors?.provincia}
									helperText={errors?.provincia?.message}
								/>
							)}
						/>
					</Grid>
				</Grid>
				<Divider sx={{ my: 3 }} />
				<Grid
					container
					sx={{ flexGrow: 1, mb: 1 }}
					rowSpacing={1}
					columnSpacing={{ xs: 1, sm: 2, md: 3 }}
					justifyContent="center"
					alignItems="flex-start"
				>
					<Grid size={{  xs:12, sm:12, md:12 }}>
						<TextField
							id="indirizzo_pesa"
							label="indirizzo_pesa"
							// disabled={getValues("confermato")}
							{...register("indirizzo_pesa")}
							InputLabelProps={{
								shrink: true,
							}}
							multiline={true}
							rows={2}
							fullWidth
							variant="outlined"
							autoComplete={"off"}
							error={!!errors?.indirizzo_pesa}
							helperText={errors?.indirizzo_pesa?.message}
						/>
					</Grid>
				</Grid>
				<Stack direction="row" spacing={2} justifyContent="center">
					{/* <Box sx={{ my: 3 }}> */}
					<Button variant="outlined" onClick={handleAnnulla}>
						Annulla
					</Button>
					<Button disabled={!isDirty} variant="outlined" type="submit">
						Salva
					</Button>
				</Stack>
			</Paper>
		</form>
	);
};

export default AllevamentoForm;
