export enum PermissionTypes {
	PIANIFICATORI = "pianificatori",
	PIANIFICATORI_LETTURA = "pianificatori_lettura",
	OPERATORI_LOGISTICA = "operatori_logistica",
	OPERATORI_LOGISTICA_LETTURA = "operatori_logistica_lettura",
	SCHEDULATORI_LAVORAZIONI = "schedulatori_lavorazioni",
	OPERATORI_MACELLO = "operatori_macello",
	OPERATORI_INCASSETTAMENTO = "operatori_incassettamento",
	GESTORI_ANAGRAFICHE = "gestori_anagrafiche",
	AUTISTI = "autisti",
	VETERINARI = "veterinari",
	COMMITTENTI = "committenti",
	SCHEDULATORI_ESTERNI = "schedulatori_esterni",
	PRODUTTORI = "produttori",
	ALL = "all",
}

export const PROGRAMMA_INCASSETTAMENTO_PERMISSIONS = [
	PermissionTypes.PIANIFICATORI,
	PermissionTypes.OPERATORI_MACELLO,
	PermissionTypes.SCHEDULATORI_LAVORAZIONI,
	PermissionTypes.SCHEDULATORI_ESTERNI,
];

export const STAMPE_MACELLO_PERMISSIONS = [
	PermissionTypes.PIANIFICATORI,
	PermissionTypes.OPERATORI_MACELLO,
	PermissionTypes.SCHEDULATORI_LAVORAZIONI,
];
