import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../redux/store";
import { Routes_path } from "../../routerApp";

import {
	Lotto,
	LottiState,
	fetchLotti,
	resetLotti,
} from "../pianificazione/pianificazioneSlice";

import { DateTime } from 'luxon';
import { getColore } from "../pianificazione/utility/lottoUtility";

import PaperMui from "@mui/material/Paper";

import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import { styled, Box } from "@mui/system";
import {convertToDateTime} from "../../common/dateUtils/convertToDateTime";

interface RigaSchedaCarico {
	id: number | null;
	ordinamento_lotto: number;
	codice_tracciabilita?: string;
	codice_asl?: string;
	committente_desc: string;
	numero_capi: string;
	peso_medio: string;
	allevatore: string;
	comune?: string;
	ora_carico: string;
	autisti: string;
	camion?: string;
	rimorchio: string;
	fori?: number | null;
	numero_capi_foro?: number | null;
	capi?: number | null;
	// M4: string,
}

interface SchedeCaricoGridProps {
	lotti: Lotto[];
}

const SchedeCaricoGrid = ({ lotti }: SchedeCaricoGridProps) => {
	
	const righe: RigaSchedaCarico[] = [];

	lotti.map((lotto: Lotto) => {
		lotto.schede_carico.map((schedaCarico) =>
			righe.push({
				id: schedaCarico.id,
				ordinamento_lotto: lotto.ordinamento_lotto,
				codice_tracciabilita: lotto.codice_tracciabilita,
				codice_asl: lotto.allevamento_pack?.codice_asl,
				committente_desc: lotto.committente_desc,
				numero_capi:
					lotto.numero_capi +
					" " +
					lotto.tipo_capo_desc +
					(lotto.colore ? " " + getColore(lotto) : ""),
				peso_medio:
					Intl.NumberFormat("it-IT").format(lotto.peso_medio || 0) + " kg",
				allevatore: `${lotto.allevamento_pack?.nome}(${lotto.produttore_pack?.nome})`,
				comune: lotto.allevamento_pack?.comune,
				ora_carico: convertToDateTime(schedaCarico.data_ora_carico_pianificata)?.setLocale("it").toFormat('HH:mm')||"",
				autisti: schedaCarico.autisti_pack
					.map(
						(autista) =>
							autista.cognome.charAt(0).toUpperCase() +
							autista.cognome.substring(1, 6).toLowerCase() +
							"." +
							autista.nome.charAt(0).toUpperCase() +
							"."
					)
					.toString(),
				camion: schedaCarico.camion_pack?.nome,
				rimorchio: schedaCarico.rimorchio ? "SI" : "NO",
				fori: schedaCarico.numero_fori,
				numero_capi_foro: schedaCarico.numero_capi_foro,
				capi: schedaCarico.numero_capi_pianificati,
				// M4: schedaCarico.,
			})
		);
		return righe;
	});

	return (
		<Box sx={{ border: "solid", borderColor: "orange", borderRadius: 1, p: 1 }}>
			<Box
				sx={{
					border: "solid",
					borderColor: "orange",
					// borderRadius: 1,
					backgroundColor: "orange",
					color: "white",
					mx: -1,
					mt: -1,
				}}
			>
				<Grid container spacing={2}>
					<Grid size={{ xs:12, sm:9, md:2, lg:2 }}>
						<Grid container spacing={2}>
							<Grid size={{ xs:12, sm:9, md:2, lg:2 }}>
								<Typography variant="body1">#</Typography>
							</Grid>
							<Grid size={{ xs:12, sm:9, md:5, lg:5 }}>
								<Typography variant="body1">Cod. Lotto</Typography>
							</Grid>
							<Grid size={{ xs:12, sm:9, md:5, lg:5 }}>
								<Typography variant="body1">Codice ASL</Typography>
							</Grid>
						</Grid>
					</Grid>
					<Grid size={{ xs:12, sm:9, md:3, lg:3 }}>
						<Grid container spacing={2}>
							<Grid size={{ xs:12, sm:9, md:4, lg:4 }}>
								<Typography variant="body1">Committente</Typography>
							</Grid>
							<Grid size={{ xs:12, sm:9, md:5, lg:5 }}>
								<Typography variant="body1">N° Capi</Typography>
							</Grid>
							<Grid size={{ xs:12, sm:9, md:3, lg:3 }}>
								<Typography variant="body1">Peso Medio</Typography>
							</Grid>
						</Grid>
					</Grid>
					<Grid size={{ xs:12, sm:9, md:3, lg:3 }}>
						<Typography variant="body1">Allevatore</Typography>
						<Typography variant="body1">Comune</Typography>
					</Grid>
					<Grid size={{ xs:12, sm:9, md:2, lg:2 }}>
						<Grid container spacing={2}>
							<Grid size={{ xs:12, sm:9, md:4, lg:4 }}>
								<Typography variant="body1">Ora Carico</Typography>
							</Grid>
							<Grid size={{ xs:12, sm:9, md:8, lg:8 }}>
								<Typography variant="body1">Autisti</Typography>
							</Grid>
						</Grid>
					</Grid>
					<Grid size={{ xs:12, sm:9, md:2, lg:2 }}>
						<Grid container spacing={2}>
							<Grid size={{ xs:12, sm:9, md:4, lg:4 }}>
								<Typography variant="body1">Camion</Typography>
							</Grid>
							<Grid size={{ xs:12, sm:9, md:2, lg:2 }}>
								<Typography variant="body1">Rimorchio</Typography>
							</Grid>
							<Grid size={{ xs:12, sm:9, md:2, lg:2 }}>
								<Typography variant="body1">Fori</Typography>
							</Grid>
							<Grid size={{ xs:12, sm:9, md:2, lg:2 }}>
								<Typography variant="body1">N° Capi Foro</Typography>
							</Grid>
							<Grid size={{ xs:12, sm:9, md:2, lg:2 }}>
								<Typography variant="body1">Capi</Typography>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Box>

			{righe.map((riga: RigaSchedaCarico) => (
				<Grid container spacing={2}>
					<Grid size={{ xs:12, sm:9, md:2, lg:2 }}>
						<Grid container spacing={2}>
							<Grid size={{ xs:12, sm:9, md:2, lg:2 }}>
								<Typography variant="body2" sx={{ fontSize: "0.9rem" }}>
									{riga.ordinamento_lotto}
								</Typography>
							</Grid>
							<Grid size={{ xs:12, sm:9, md:5, lg:5 }}>
								<Typography variant="body2" sx={{ fontSize: "0.9rem" }}>
									{riga.codice_tracciabilita}
								</Typography>
							</Grid>
							<Grid size={{ xs:12, sm:9, md:5, lg:5 }}>
								<Typography variant="body2" sx={{ fontSize: "0.9rem" }}>
									{riga.codice_asl}
								</Typography>
							</Grid>
						</Grid>
					</Grid>
					<Grid size={{ xs:12, sm:9, md:3, lg:3 }}>
						<Grid container spacing={2}>
							<Grid size={{ xs:12, sm:9, md:4, lg:4 }}>
								<Typography variant="body2" sx={{ fontSize: "0.9rem" }}>
									{riga.committente_desc}
								</Typography>
							</Grid>
							<Grid size={{ xs:12, sm:9, md:5, lg:5 }}>
								<Typography variant="body2" sx={{ fontSize: "0.9rem" }}>
									{riga.numero_capi}
								</Typography>
							</Grid>
							<Grid size={{ xs:12, sm:9, md:3, lg:3 }}>
								<Typography variant="body2" sx={{ fontSize: "0.9rem" }}>
									{riga.peso_medio}
								</Typography>
							</Grid>
						</Grid>
					</Grid>
					<Grid size={{ xs:12, sm:9, md:3, lg:3 }}>
						<Typography variant="body2" sx={{ fontSize: "0.9rem" }}>
							{riga.allevatore}
						</Typography>
						<Typography variant="body2" sx={{ fontSize: "0.9rem" }}>
							{riga.comune}
						</Typography>
					</Grid>
					<Grid size={{ xs:12, sm:9, md:2, lg:2 }}>
						<Grid container spacing={2}>
							<Grid size={{ xs:12, sm:9, md:4, lg:4 }}>
								<Typography variant="body2" sx={{ fontSize: "0.9rem" }}>
									{riga.ora_carico}
								</Typography>
							</Grid>
							<Grid size={{ xs:12, sm:9, md:8, lg:8 }}>
								<Typography variant="body2" sx={{ fontSize: "0.9rem" }}>
									{riga.autisti}
								</Typography>
							</Grid>
						</Grid>
					</Grid>

					<Grid size={{ xs:12, sm:9, md:2, lg:2 }}>
						<Grid container spacing={2}>
							<Grid size={{ xs:12, sm:9, md:4, lg:4 }}>
								<Typography variant="body2" sx={{ fontSize: "0.9rem" }}>
									{riga.camion}
								</Typography>
							</Grid>
							<Grid size={{ xs:12, sm:9, md:2, lg:2 }}>
								<Typography variant="body2" sx={{ fontSize: "0.9rem" }}>
									{riga.rimorchio}
								</Typography>
							</Grid>
							<Grid size={{ xs:12, sm:9, md:2, lg:2 }}>
								<Typography variant="body2" sx={{ fontSize: "0.9rem" }}>
									{riga.fori}
								</Typography>
							</Grid>
							<Grid size={{ xs:12, sm:9, md:2, lg:2 }}>
								<Typography variant="body2" sx={{ fontSize: "0.9rem" }}>
									{riga.numero_capi_foro}
								</Typography>
							</Grid>
							<Grid size={{ xs:12, sm:9, md:2, lg:2 }}>
								<Typography variant="body2" sx={{ fontSize: "0.9rem" }}>
									{riga.capi}
								</Typography>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			))}
		</Box>
	);
};

export default SchedeCaricoGrid;
