import React from "react";
import ReactPDF from "@react-pdf/renderer";
import { DateTime } from 'luxon';

import { Text, View, StyleSheet, Image } from "@react-pdf/renderer";

import { Lotto } from "../../pianificazione/pianificazioneSlice";
import { getColore } from "../../pianificazione/utility/lottoUtility";
import { style } from "@mui/system";
import {convertToDateTime} from "../../../common/dateUtils/convertToDateTime";
const logoScarso = require("../../../../../static/images/logo/logo-Scarso_nuovo_solo.png");

// Create styles
const styles = StyleSheet.create({
	page: {
		backgroundColor: "#fff",
		paddingTop: 20,
	},
	section: {
		marginTop: 10,
		marginLeft: 10,
		marginRight: 10,
		padding: 10,
	},
	tableContainer: {
		flexDirection: "row",
		flexWrap: "wrap",
		margin: 5,
	},

	sectionIntestazione: {
		// width: "100%",
		flexDirection: "row",
		fontSize: 12,
		justifyContent: "flex-start",

		// marginTop: 5,
		// marginLeft: 10,
		// marginRight: 10,
		padding: 2,
		paddingTop: 2,
		paddingBottom: 7,
		backgroundColor: "#777777",
		color: "#ffffff",
	},
	sectionRighe: {
		// width: "100%",
		flexDirection: "row",
		justifyContent: "flex-start",

		fontSize: 10,
		// marginTop: 5,
		// marginLeft: 10,
		// marginRight: 10,
		padding: 2,
		paddingTop: 1,
		marginBottom: 1,
		paddingBottom: 5,
		marginTop: 6,

		borderBottom: " 0.5px solid #777777",
	},

	row: {
		flexDirection: "row",
		// alignItems: "center",
		justifyContent: "flex-start",

		fontSize: 10,
	},
	ordinamento_lotto: {
		width: "2%",
	},
	codice_lotto: {
		width: "8%",
	},
	codice_asl: {
		width: "7%",
	},
	committente: {
		width: "9%",
	},
	animali: {
		width: "10%",
	},
	animali_dati: {
		width: "10%",
		backgroundColor: "#E5E4E2",
	},
	peso: {
		width: "5%",
	},
	peso_dati: {
		width: "5%",
		backgroundColor: "#E5E4E2",
	},
	allevatore: {
		width: "16%",
	},
	comune: {
		width: "8%",
	},
	dettaglio_scheda: {
		width: "30%",
	},

	ora: {
		width: "15%",
		fontSize: 9,
	},
	autista: {
		width: "25%",
		fontSize: 9,
	},
	mezzo: {
		width: "26%",
	},
	fori: {
		width: "12%",
		fontSize: 9,
	},
	numero_capi_scheda: {
		width: "12%",
	},
	m4: {
		width: "12px",
		height: "12px",
		border: " 1px solid #777777",
	},
	textIntestazioni: {
		paddingRight: 3,
		marginRight: 6,
		// marginVertical: 20,
		// textTransform: "uppercase",
	},

	textRighe: {
		paddingRight: 3,
		marginRight: 6,
		// marginVertical: 20,
		// textTransform: "uppercase",
	},
	text: {
		fontSize: 20,
		fontStyle: "normal",
		// marginVertical: 20,
		// textTransform: "uppercase",
	},
	sectionTable: {
		flexDirection: "row",
		justifyContent: "space-between",
		marginLeft: 0,
		borderBottomWidth: 1,
	},
	textSeq: {
		// flexDirection: "row",
		paddingHorizontal: 3,
		paddingVertical: 2,
		fontSize: 14,
		fontWeight: "bold",
		borderWidth: 1,
	},
});

interface ProgrammaMacellazionePdfProps {
	lotti: Lotto[];
}

// const calcoloTotaleCapi = (lotti: Lotto[]) => {
// 	const totaleCapi = lotti.reduce(function (previousValue, currentValue) {
// 		return previousValue + (currentValue.numero_capi || 0);
// 	}, 0);
// 	return totaleCapi;
// };

const ProgrammaMacellazionePdf = ({ lotti }: ProgrammaMacellazionePdfProps) => {
	const totaleCapi = lotti.reduce(function (previousValue, currentValue) {
		return previousValue + (currentValue.numero_capi || 0);
	}, 0);
	return (
		<>
			<View
				key={"scheda_macellazione_intestazione"}
				style={{
					flexDirection: "row",
					justifyContent: "center",
				}}
			>
				<Text style={[styles.text, { fontSize: 20, paddingRight: 50 }]}>
					Programma di macellazione{" "}
					<Text style={{ fontSize: 30, paddingRight: 50 }}>
						{convertToDateTime(lotti[0]?.data_lavorazione)?.setLocale("it").toFormat('cccc dd LLL yyyy')}
					</Text>
				</Text>
				<Text
					style={[
						styles.text,
						{
							fontWeight: "bold",
							marginBottom: 1,
							paddingBottom: 0,
							fontSize: 20,
						},
					]}
				>
					<Image
						src={logoScarso}
						style={{
							width: 30,
							height: 30,
							// margin: 0,
							padding: 0,
							paddingLeft: "10px",
							marginLeft: "3px",
						}}
					/>
				</Text>
			</View>
			<View
				style={{
					flexDirection: "row",
					justifyContent: "center",
				}}
			>
				<View style={styles.tableContainer}>
					<View
						key={"scheda_macellazione_lotto_intestazione"}
						style={styles.sectionIntestazione}
					>
						<Text style={[styles.textIntestazioni, styles.ordinamento_lotto]}>
							Seq.
						</Text>
						<Text style={[styles.textIntestazioni, styles.codice_lotto]}>
							Cod. Lotto
						</Text>
						<Text style={[styles.textIntestazioni, styles.codice_asl]}>
							Cod. ASL
						</Text>
						<Text style={[styles.textIntestazioni, styles.committente]}>
							Committente
						</Text>
						<Text style={[styles.textIntestazioni, styles.animali]}>
							n. animali
						</Text>
						<Text style={[styles.textIntestazioni, styles.peso]}>
							peso medio
						</Text>
						<Text style={[styles.textIntestazioni, styles.allevatore]}>
							allevatore{"\n"}
							<Text
								style={[
									styles.textIntestazioni,
									styles.allevatore,
									{ fontFamily: "Times-Italic", fontSize: 10 },
								]}
							>
								(produttore)
							</Text>
						</Text>
						<Text style={[styles.textIntestazioni, styles.comune]}>comune</Text>
						<View
							style={[
								styles.dettaglio_scheda,
								{
									flexDirection: "row",
									justifyContent: "flex-start",
								},
							]}
						>
							<Text style={[styles.textIntestazioni, styles.ora]}>
								ora carico
							</Text>
							<Text style={[styles.textIntestazioni, styles.autista]}>
								autista
							</Text>
							<Text style={[styles.textIntestazioni, styles.mezzo]}>mezzo</Text>
							<Text style={[styles.textIntestazioni, styles.fori]}>fori</Text>
							<Text style={[styles.textIntestazioni, styles.fori]}>
								capi x foro
							</Text>
							<Text
								style={[styles.textIntestazioni, styles.numero_capi_scheda]}
							>
								capi
							</Text>
							<Text style={[styles.textIntestazioni]}>M4</Text>
						</View>
					</View>
					{lotti.map((lotto) => {
						return (
							<View
								key={"scheda_macellazione_lotto_" + lotto.id}
								style={styles.sectionRighe}
							>
								<Text
									style={[
										styles.textIntestazioni,
										styles.ordinamento_lotto,
										{ fontSize: 14 },
									]}
								>
									{lotto.ordinamento_lotto}
								</Text>
								<Text
									style={[
										styles.textIntestazioni,
										styles.codice_lotto,
										{ fontSize: 9 },
									]}
								>
									{lotto.codice_tracciabilita}
								</Text>
								<Text
									style={[
										styles.textIntestazioni,
										styles.codice_asl,
										{ fontSize: 9 },
									]}
								>
									{lotto.allevamento_pack?.codice_asl}
								</Text>
								<Text style={[styles.textIntestazioni, styles.committente]}>
									{lotto.committente_desc}
								</Text>
								<Text style={[styles.textIntestazioni, styles.animali]}>
									<Text
										style={[
											styles.textIntestazioni,
											styles.animali,
											{ backgroundColor: "#f4f624" },
										]}
									>
										{lotto.numero_capi}
									</Text>
									{" " +
										lotto.tipo_capo_desc +
										(lotto.colore ? " " + getColore(lotto) : "")}
								</Text>
								<Text style={[styles.textIntestazioni, styles.peso]}>
									<Text
										style={[
											styles.textIntestazioni,
											styles.peso,
											{
												backgroundColor: "#99f820",
											},
										]}
									>
										{Intl.NumberFormat("it-IT").format(lotto.peso_medio || 0)}
									</Text>
									{" kg"}
								</Text>
								<Text style={[styles.textIntestazioni, styles.allevatore]}>
									{lotto.allevamento_pack?.nome}
									{"\n"}
									<Text
										style={[
											styles.textIntestazioni,
											styles.allevatore,
											{ fontFamily: "Times-Italic", fontSize: 10 },
										]}
									>
										({lotto.produttore_pack?.nome})
									</Text>
								</Text>
								<Text style={[styles.textIntestazioni, styles.comune]}>
									{lotto.allevamento_pack?.comune}
								</Text>
								<View
									style={[
										styles.dettaglio_scheda,
										{
											flexDirection: "column",
											justifyContent: "center",
										},
									]}
								>
									{lotto.schede_carico.map((schedaCarico) => (
										<View
											key={"schedaCarico_view_" + schedaCarico.id}
											style={{
												flexDirection: "row",
												justifyContent: "center",
												marginBottom: 10,
											}}
										>
											<Text style={[styles.textIntestazioni, styles.ora]}>
												{convertToDateTime(schedaCarico.data_ora_carico_pianificata)?.setLocale("it").toFormat('HH:mm')}
											</Text>
											<View
												style={[
													styles.autista,
													{
														flexDirection: "column",
														justifyContent: "center",
													},
												]}
											>
												{schedaCarico.autisti_pack.map((autista) => (
													<Text
														key={"autista_" + autista.id}
														style={styles.textIntestazioni}
													>
														{autista.cognome.charAt(0).toUpperCase() +
															autista.cognome.substring(1, 6).toLowerCase() +
															"." +
															autista.nome.charAt(0).toUpperCase() +
															"."}
													</Text>
												))}
											</View>
											<Text style={[styles.textIntestazioni, styles.mezzo]}>
												{schedaCarico.camion_pack?.nome}
											</Text>
											<Text style={[styles.textIntestazioni, styles.fori]}>
												{schedaCarico.numero_fori}
											</Text>
											<Text style={[styles.textIntestazioni, styles.fori]}>
												{schedaCarico.numero_capi_foro}
											</Text>
											<Text
												style={[
													styles.textIntestazioni,
													styles.numero_capi_scheda,
												]}
											>
												{schedaCarico.numero_capi_pianificati}
											</Text>
											<Text style={[styles.textIntestazioni, styles.m4]}>
												{"\u2B1C"}
											</Text>
										</View>
									))}
								</View>
							</View>
						);
					})}
					<View style={styles.sectionIntestazione}>
						<Text style={[styles.textIntestazioni, { width: "21%" }]}></Text>
						<Text
							style={[
								styles.textIntestazioni,
								{ width: "79%", color: "#f4f624" },
							]}
						>
							{"Tot. capi: "}
							{Intl.NumberFormat("it-IT").format(totaleCapi)}
						</Text>
					</View>
				</View>
			</View>
		</>
	);
};

export default ProgrammaMacellazionePdf;
