import React from "react";
import {PermissionTypes} from "../../authentication/permissionsGroups";
import {useTheme} from "@mui/material/styles";
import {Link} from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from "@mui/material/Tooltip";
import AutorizzazioneGruppi from "components/authentication/AutorizzazioneGruppi";

const styleListItem = {justifyContent: "center"};

interface ElementoMenuControllatoProps {
    path: string;
    label: string;
    gruppiAutorizzati: PermissionTypes[];
    open: boolean;
    selected: boolean;
    Icon: React.ElementType;
    tooggleDrawer: () => void;
}

const ElementoMenuControllato = ({
                                     path,
                                     label,
                                     open,
                                     gruppiAutorizzati,
                                     selected,
                                     Icon,
                                     tooggleDrawer,
                                 }: ElementoMenuControllatoProps) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down("md"));
    const mobiletooggleDrawer = () => {
        matches && tooggleDrawer();
    };

    return (
        <AutorizzazioneGruppi gruppiAutorizzati={gruppiAutorizzati}>
            <ListItem
                component={Link}
                to={path}
                key={path}
                sx={styleListItem}
            >
                <ListItemIcon style={{minWidth: "auto"}}>
                    <Tooltip title={label}>
                        {/* <Badge badgeContent={8} color="error"> */}
                        <Icon
                            sx={{color: selected ? "primary.main" : "inherit"}}
                            // className={selected ? classes.iconSelected : ""}
                            // onClick={mobiletooggleDrawer}
                        />
                        {/* </Badge> */}
                    </Tooltip>
                </ListItemIcon>

                {open && (
                    <ListItemText
                        style={{marginLeft: 12}}
                        primary={label}
                        sx={{color: selected ? "primary.main" : "#222"}}
                        // className={selected ? classes.iconSelected : ""}
                        onClick={mobiletooggleDrawer}
                    />
                )}

            </ListItem>
        </AutorizzazioneGruppi>
    );
};

export default ElementoMenuControllato;
