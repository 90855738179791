import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { Utente } from "./authenticationSlice";
import { PermissionTypes } from "./permissionsGroups";

interface AutorizzazioneGruppiProps {
    gruppiAutorizzati: PermissionTypes[];
    children: React.ReactNode;
}

const AutorizzazioneGruppi: React.FC<AutorizzazioneGruppiProps> = ({ gruppiAutorizzati, children }) => {
    const utente = useSelector<RootState, Utente>((state) => state.authentication.utenteLogged);

    const utenteAutorizzato = (): boolean => {
        if (utente.is_superuser) return true;
        if (utente.isLogged && gruppiAutorizzati.includes(PermissionTypes.ALL)) return true;
        return gruppiAutorizzati.some((gruppo) => utente.gruppi?.includes(gruppo));
    };

    return utenteAutorizzato() ? <>{children}</> : null;
};

export default AutorizzazioneGruppi;
