import { handleResponse, handleError, headerset } from "../apiUtils";
import { DateTime } from 'luxon';
import { SchedaMacello } from "../../components/main/schedeMacello/schedeMacelloSlice";
import {convertToDateTime} from "../../components/common/dateUtils/convertToDateTime";

const baseUrl = (scheda_macello_id?: number | null) => {
  if (scheda_macello_id) {
    return (
      process.env.API_URL +
      "/api/schede_macello/schede_macello/" +
      scheda_macello_id +
      "/"
    );
  } else {
    return process.env.API_URL + "/api/schede_macello/schede_macello/";
  }
};

export function fetchSchedeMacello(data_da?: Date, data_a?: Date) {
  let url = baseUrl();
  // convertToDateTime(data_da)?.setLocale("it").toFormat("yyyy-MM-dd");
  if (data_da && data_a) {
    url += `?data_da=${convertToDateTime(data_da)?.setLocale("it").toFormat("yyyy-MM-dd")}&data_a=${convertToDateTime(data_a)?.setLocale("it").toFormat('yyyy-MM-dd')}`;
  } else if (data_da) {
    url += `?data_da=${convertToDateTime(data_da)?.setLocale("it").toFormat("yyyy-MM-dd")}`;
  } else if (data_a) {
    url += `?data_a=${convertToDateTime(data_a)?.setLocale("it").toFormat("yyyy-MM-dd")}`;
  }
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: headerset().Authorization,
    },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getSchedaMacello(schedaMacelloId: number) {
  let url = baseUrl(schedaMacelloId);
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: headerset().Authorization,
    },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function saveSchedaMacello(schedaMacelloToSave: SchedaMacello) {
  const schedaMacello = {
    ...schedaMacelloToSave,
    data_ora_inizio_macello: convertToDateTime(schedaMacelloToSave.data_ora_inizio_macello)?.toISO(),
    data_ora_fine_macello: convertToDateTime(schedaMacelloToSave.data_ora_fine_macello)?.toISO(),
  };

  return fetch(baseUrl(schedaMacelloToSave.id), {
    method: schedaMacelloToSave.id ? "PUT" : "POST", // PUT to update when id already exists.
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: headerset().Authorization,
    },
    body: JSON.stringify({
      ...schedaMacello,
      // Parse authorId to a number (in case it was sent as a string).
      // id: parseInt(progetto.id, 10),
    }),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function deleteSchedaMacello(schedaMacelloToDelete: SchedaMacello) {
  return fetch(baseUrl(schedaMacelloToDelete.id), {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: headerset().Authorization,
    },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function generaSchedaMacello(lotto_id: number) {
  let url =
    process.env.API_URL +
    "/api/schede_macello/genera_scheda_macello/" +
    lotto_id +
    "/";
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: headerset().Authorization,
    },
    body: JSON.stringify({}),
  })
    .then(handleResponse)
    .catch(handleError);
}
