import React, { FC } from "react";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import ReplayIcon from "@mui/icons-material/Replay";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DateRangeIcon from "@mui/icons-material/DateRange";
import CardMui from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";
import Button from "@mui/material/Button";
import { convertToDateTime } from "./convertToDateTime";
import {DateTime} from "luxon";

const Card = styled(CardMui)(({ theme }) => ({
	margin: theme.spacing(1),
	marginBottom: 1,
	// },
}));

interface NavigazioneGiorniToolbarProps {
	titolo?: string;
	dataPresente?: boolean;
	data?: DateTime | null;
	setData: (data: DateTime | null) => void;
	refresh: () => void;
	Toolbar?: FC;
}

const NavigazioneGiorniToolbar = ({
	titolo,
	dataPresente = true,
	data,
	setData,
	refresh,
	Toolbar,
}: NavigazioneGiorniToolbarProps) => {
	const cambiaGiorno = (avanti: boolean) => {
		data &&
			(avanti
				? setData(data.plus({ days: 1 }))
				: setData(data.minus({ days: 1 })));
	};

	const [datePickerView, setDatePickerView] = React.useState<Boolean>(false);
	const changeData = (newValue: DateTime | null) => {
		setData(newValue);
	};

	return (
		<Card sx={{ p: 1 }}>
			<Grid container spacing={1}>
				<Grid size={{ xs:12, sm:6, md:6, lg:3 }}>
					{/* <Stack
						direction="row"
						justifyContent="center"
						alignItems="center"
						// mr={2}
						marginX={{ xs: 1, md: 2 }}
						spacing={2}
					> */}
					<Typography variant="h5" sx={{ m: 1 }}>
						{titolo}
					</Typography>
				</Grid>{" "}
				<Grid size={{ xs:12, sm:6, md:6, lg:5 }}>
					{dataPresente ? (
						<>
							<Typography
								variant="subtitle1"
								sx={{ display: { sm: "block", md: "none" } }}
							>
								<IconButton
									aria-label="GiornoPrecedente"
									onClick={() => {
										cambiaGiorno(false);
									}}
								>
									<ArrowBackIosIcon />
								</IconButton>

								{convertToDateTime(data)
									?.setLocale("it")
									.toFormat("dd/MM/yyyy")}
								<Button
									// sx={{ ml: 1, mr: 1 }}
									onClick={() => {
										setDatePickerView(!datePickerView);
									}}
								>
									<DateRangeIcon />
								</Button>
								<IconButton
									color="primary"
									aria-label="upload picture"
									component="span"
									onClick={refresh}
								>
									<ReplayIcon />
								</IconButton>
								<IconButton
									aria-label="GiornoSuccessivo"
									onClick={() => {
										cambiaGiorno(true);
									}}
								>
									<ArrowForwardIosIcon />
								</IconButton>
							</Typography>
							<Typography
								variant="h5"
								sx={{ display: { xs: "none", sm: "none", md: "block" } }}
							>
								<IconButton
									aria-label="GiornoPrecedente"
									onClick={() => {
										cambiaGiorno(false);
									}}
								>
									<ArrowBackIosIcon />
								</IconButton>
								{convertToDateTime(data)
									?.setLocale("it")
									.toFormat("dd/MM/yyyy")}{" "}
								<Button
									sx={{ p: 2, ml: 2, mr: 2 }}
									onClick={() => {
										setDatePickerView(!datePickerView);
									}}
								>
									<DateRangeIcon />
								</Button>
								<IconButton
									color="primary"
									aria-label="upload picture"
									component="span"
									onClick={refresh}
								>
									<ReplayIcon />
								</IconButton>
								<IconButton
									aria-label="GiornoSuccessivo"
									onClick={() => {
										cambiaGiorno(true);
									}}
								>
									<ArrowForwardIosIcon />
								</IconButton>
							</Typography>
						</>
					) : (
						<IconButton
							color="primary"
							aria-label="upload picture"
							component="span"
							onClick={refresh}
						>
							<ReplayIcon />
						</IconButton>
					)}
					{datePickerView && (
						<StaticDatePicker
							displayStaticWrapperAs="desktop"
							openTo="day"
							value={convertToDateTime(data)}
							onChange={(newValue: DateTime<boolean> | null) => {
								changeData(newValue);
							}}
						/>
					)}
				</Grid>
				<Grid size={{ xs:12, sm:6, md:6, lg:4 }}>
					{Toolbar && <Toolbar />}
				</Grid>
			</Grid>
		</Card>
	);
};

export default NavigazioneGiorniToolbar;
