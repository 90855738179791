import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../redux/store";
import { Lotto } from "../pianificazione/pianificazioneSlice";
import {
	SchedaCarico,
	saveSchedaCarico,
	deleteSchedaCarico,
	mandaWhatsAppAutisti,
	mandaSmsAppAutisti,
} from "./schedeCaricoSlice";
import { Camion, Autista } from "../anagrafiche/anagraficheSlice";
import DnDDroppableBox from "../../common/DnDDroppableBox";

import Grid from "@mui/material/Grid2";
import SchedaCaricoCard from "./schedeCaricoLottoCard/schedaCaricoCard/SchedaCaricoCard";
import {Box} from "@mui/system";

interface SchedeCaricoLottoListProps {
	lotto: Lotto;
	camions: Camion[];
	autisti: Autista[];
}

const SchedeCaricoLottoList = ({
	lotto,
	camions,
	autisti,
}: SchedeCaricoLottoListProps) => {
	const dispatch = useAppDispatch();
	const schedeCarico = useSelector<RootState, SchedaCarico[]>((state) =>
		state.schedeCaricoState.schedeCarico.results.filter(
			(scheda) => scheda.lotto == lotto.id
		)
	);

	const handlerSaveSchedaCarico = (schedaCaricoToSave: SchedaCarico) => {
		dispatch(saveSchedaCarico(schedaCaricoToSave));
	};

	const handlerDeleteSchedaCarico = (schedaCaricoToDelete: SchedaCarico) => {
		dispatch(deleteSchedaCarico(schedaCaricoToDelete));
	};
	const handlermandaWhatsAppAutisti = (schedaCarico_id: number) => {
		dispatch(mandaWhatsAppAutisti(schedaCarico_id));
	};
	const handlermandaSmsAppAutisti = (schedaCarico_id: number) => {
		dispatch(mandaSmsAppAutisti(schedaCarico_id));
	};

	return (
		<Box sx={{ flexGrow: 1, pt: 1 }} >
			{schedeCarico.map((schedaCarico) => (
				<Box key={"schedaCarico_" + schedaCarico.id} >
					<DnDDroppableBox
						key={schedaCarico.id}
						value={{
							schedaCarico_id: schedaCarico.id,
							lotto_id: lotto.id,
							data_lavorazione: lotto.data_lavorazione,
						}}
						accept={["AUTISTA", "CAMION"]}
						// disabled={giornataChiusa}
					>
						<SchedaCaricoCard
							schedaCarico={schedaCarico}
							saveSchedaCarico={handlerSaveSchedaCarico}
							deleteSchedaCarico={handlerDeleteSchedaCarico}
							mandaWhatsAppAutisti={handlermandaWhatsAppAutisti}
							mandaSmsAppAutisti={handlermandaSmsAppAutisti}
							camions={camions}
							autisti={autisti}
						/>
					</DnDDroppableBox>
				</Box>
			))}
		</Box>
	);
};

export default SchedeCaricoLottoList;
