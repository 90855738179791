import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
	ErrorStatusTypes,
	ErrorsStack,
	parseErrorMessage,
} from "../../common/errorsDeclarations";

import {
	Camion,
	Autista,
	fetchCamions,
	fetchAutisti,
} from "../anagrafiche/anagraficheSlice";
import {
	Lotto,
	getLotto,
	PianificazioneStrutturaState,
} from "../pianificazione/pianificazioneSlice";
import * as schedeMacelloApi from "../../../api/schedeMacello/schedeMacelloApi";
import moment from "moment";
import { toast } from "react-toastify";
// import { useDispatch } from "react-redux";

moment.locale("it");


export enum GiudizioColoreTypes {
	A = "Bianco",
	B = "Bianco Avorio",
	C = "Giallo Paglierino",
	D = "Giallo",
	E = "Giallo Oro",
}

export enum UniformitaTypes {
	A = "1",
	B = "2",
	C = "3",
}


export interface SchedaMacello {
	id: number | null | undefined;
	digiuno?: boolean | null;
	data_ora_inizio_macello?: Date | null;
	data_ora_fine_macello?: Date | null;
	data_ora_inizio_pausa?: Date | null;
	data_ora_fine_pausa?: Date | null;
	croste?: number;
	gozzi?: number;
	ali_rotte_carico?: number;
	ali_rotte_macello?: number;
	ematomi_cosce?: number;
	ematomi_petto?: number;
	fusi_verdi?: number;
	grasso?: number;
	giudizio_colore?: GiudizioColoreTypes;
	uniformita?: UniformitaTypes;
	temperatura_fuori_tunnel?: number;
	temperatura_prima_carico?: number;
	numero_capi_lavorati?: number | null;
	numero_capi_morti?: number | null;
	numero_scarti_1?: number | null;
	numero_scarti_2?: number | null;
	minuti_processamento?: number;
	minuti_processamento_stimati?: number;
	minuti_pausa?: number;
	velocita_processamento?: number;
	velocita_processamento_stimata?: number;
	// conteggio_polli?: number | null;
	lotto: number;
	note?: string;
	errorsStack?: ErrorsStack;
}
export interface SchedeMacelloState {
	count: number;
	page: number;
	num_pages: number;
	next?: URL;
	previous?: URL;
	results: SchedaMacello[];
	errorsStack: ErrorsStack;
}

// export interface LottoCorrente extends Lotto {
//   errorsStack: ErrorsStack;
// }

export interface SchedaMacelloStrutturaState {
	schedeMacello: SchedeMacelloState;
}

const initialState: SchedaMacelloStrutturaState = {
	schedeMacello: {
		count: 0,
		page: 0,
		num_pages: 0,
		next: undefined,
		previous: undefined,
		results: [],
		errorsStack: { status: ErrorStatusTypes.OK },
	},
};

export const fetchSchedeMacello = createAsyncThunk(
	"schedaMacello/fetchSchedeMacello",
	async (dataRange?: { data_da?: Date; data_a?: Date }) => {
		return await schedeMacelloApi.fetchSchedeMacello(
			dataRange?.data_da,
			dataRange?.data_a
		);
	}
);

export const getSchedaMacello = createAsyncThunk(
	"schedaMacello/getSchedaMacello",
	async (schedaMacelloId: number) => {
		return await schedeMacelloApi.getSchedaMacello(schedaMacelloId);
	}
);

export const saveSchedaMacello = createAsyncThunk(
	"schedaMacello/saveSchedaMacello",
	async (schedaMacelloToSave: SchedaMacello, thunkApi) => {
		const pianificazioneState = thunkApi.getState() as {
			pianificazione: PianificazioneStrutturaState;
		};
		const lotto = pianificazioneState.pianificazione.lotti.results.find(
			(lotto) => lotto.id == schedaMacelloToSave.lotto
		);

		return await schedeMacelloApi
			.saveSchedaMacello(schedaMacelloToSave)
			.then((response) => {
				// thunkApi.dispatch(fetchCamions(lotto?.data_lavorazione));
				// thunkApi.dispatch(fetchAutisti(lotto?.data_lavorazione));
				thunkApi.dispatch(getLotto(lotto?.id || 0));
				return response;
			});
	}
);

export const deleteSchedaMacello = createAsyncThunk(
	"schedaMacello/deleteSchedaMacello",
	async (schedaMacelloToDelete: SchedaMacello, thunkApi) => {
		return await schedeMacelloApi
			.deleteSchedaMacello(schedaMacelloToDelete)
			.then((response) => {
				thunkApi.dispatch(getLotto(schedaMacelloToDelete.lotto));
				return response;
			});
	}
);

export const generaSchedaMacello = createAsyncThunk(
	"schedaMacello/generaSchedaMacello",
	async (lotto: Lotto, thunkApi) => {
		return await schedeMacelloApi
			.generaSchedaMacello(lotto.id || 0)
			.then((response) => {
				thunkApi.dispatch(
					fetchSchedeMacello({
						data_da: new Date(lotto.data_lavorazione),
						data_a: new Date(lotto.data_lavorazione),
					})
				);
				thunkApi.dispatch(getLotto(lotto.id || 0));
				return response;
			});
	}
);

export const schedeMacelloSlice = createSlice({
	name: "schedeMacelloState",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// fetch Schede Macelo
		builder.addCase(fetchSchedeMacello.pending, (state, action) => {
			state.schedeMacello.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(fetchSchedeMacello.fulfilled, (state, action) => {
			state.schedeMacello = action.payload;

			state.schedeMacello.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(fetchSchedeMacello.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.schedeMacello.errorsStack = parseErrorMessage(action.error);
		});

		// get Scheda Carico
		builder.addCase(getSchedaMacello.pending, (state, action) => {
			state.schedeMacello.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(getSchedaMacello.fulfilled, (state, action) => {
			state.schedeMacello.results = state.schedeMacello.results.map(
				(schedaMacello) => {
					if (schedaMacello.id == action.payload.id) {
						return action.payload;
					} else {
						return schedaMacello;
					}
				}
			);
			state.schedeMacello.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(getSchedaMacello.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.schedeMacello.errorsStack = parseErrorMessage(action.error);
		});

		// save save SchedaMacello
		builder.addCase(saveSchedaMacello.pending, (state, action) => {
			state.schedeMacello.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(saveSchedaMacello.fulfilled, (state, action) => {
			state.schedeMacello.results = state.schedeMacello.results.map(
				(schedaMacello) => {
					if (schedaMacello.id == action.payload.id) {
						return action.payload;
					} else {
						return schedaMacello;
					}
				}
			);
			state.schedeMacello.errorsStack = { status: ErrorStatusTypes.SUCCESS };
			toast.success("Scheda macello salvata.");
		});
		builder.addCase(saveSchedaMacello.rejected, (state, action) => {
			state.schedeMacello.results = state.schedeMacello.results.map(
				(schedaMacello) => {
					if (schedaMacello.id == action.meta.arg.id) {
						return {
							...schedaMacello,
							errorsStack: parseErrorMessage(action.error)
						};
					} else {
						return schedaMacello;
					}
				}
			);
			state.schedeMacello.errorsStack = {
				status: ErrorStatusTypes.ERROR,
			};
		});
		// cancella SchedaMacello
		builder.addCase(deleteSchedaMacello.pending, (state, action) => {
			state.schedeMacello.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(deleteSchedaMacello.fulfilled, (state, action) => {
			state.schedeMacello.errorsStack = { status: ErrorStatusTypes.SUCCESS };
			state.schedeMacello.results = state.schedeMacello.results.filter(
				(schedaMacello) => schedaMacello.id != action.meta.arg.id
			);
			toast.success(action.payload.message || "Scheda macello cancellata.");
		});
		builder.addCase(deleteSchedaMacello.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.schedeMacello.errorsStack = parseErrorMessage(action.error);
		});

		// generaSchedeMacello
		builder.addCase(generaSchedaMacello.pending, (state, action) => {
			state.schedeMacello.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(generaSchedaMacello.fulfilled, (state, action) => {
			state.schedeMacello.errorsStack = { status: ErrorStatusTypes.SUCCESS };
			// toast.success("Scheda macello creata.");
		});
		builder.addCase(generaSchedaMacello.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.schedeMacello.errorsStack = parseErrorMessage(action.error);
		});
	},
});

// Action creators are generated for each case reducer function
export const {} = schedeMacelloSlice.actions;

export const schedeMacelloSliceReducer = schedeMacelloSlice.reducer;
