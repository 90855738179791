import React from "react";
import { CostoTrasporto } from "../bolleFattureSlice";
import Grid from "@mui/material/Grid2";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

interface LottoCorrispettiviTrasportoVivoCardProps {
	costi_di_trasporto_vivo_list: CostoTrasporto[];
}

const LottoCorrispettiviTrasportoVivoCard = ({
	costi_di_trasporto_vivo_list,
}: LottoCorrispettiviTrasportoVivoCardProps) => {
	return (
		<Box>
			<Typography variant="h5" gutterBottom sx={{mt:3}}>
				Corrispettivi Trasporto Vivo
			</Typography>

			{costi_di_trasporto_vivo_list.length > 0 && (
				<Box sx={{ mt: 1 }}>
					<Grid container spacing={2}>
						<Grid size={{ xs:4, sm:4, md:4, lg:4 }}>
							<Typography variant="body2">Fascia trasporto</Typography>
						</Grid>

						<Grid size={{ xs:2, sm:2, md:2, lg:2 }}>
							<Typography variant="body2" align="right">
								Q.tà
							</Typography>
						</Grid>
						<Grid size={{ xs:2, sm:2, md:2, lg:2 }}>
							<Typography variant="body2" align="right">
								Rimorchio
							</Typography>
						</Grid>
						<Grid size={{ xs:2, sm:2, md:2, lg:2 }}>
							<Typography variant="body2" align="right">
								Tariffa
							</Typography>
						</Grid>
						<Grid size={{ xs:2, sm:2, md:2, lg:2 }}>
							<Typography variant="body2" align="right">
								Corrispettivo
							</Typography>
						</Grid>
					</Grid>
					<Divider />
				</Box>
			)}

			{costi_di_trasporto_vivo_list.map((costoTrasporto) => {
				return (
					<Grid container spacing={2} key={costoTrasporto.id}>
						<Grid size={{ xs:4, sm:4, md:4, lg:4 }}>
							<Typography variant="body1" gutterBottom>
								{costoTrasporto.fascia_trasporto}
							</Typography>
							{costoTrasporto.lotti_per_viaggio > 1 && (
								<Typography variant="caption" gutterBottom>
									(viaggio condiviso in {costoTrasporto.lotti_per_viaggio} con:{" "}
									{costoTrasporto.codici_lotti_condivisi})
								</Typography>
							)}
						</Grid>

						<Grid size={{ xs:2, sm:2, md:2, lg:2 }}>
							<Typography variant="subtitle2" align="right">
								{
									{
										F: "costo fisso", //Flat: costo fisso fin tanto che si rientra nella fascia
										P:
											Intl.NumberFormat("it-IT", {
												maximumFractionDigits: 0,
											}).format(costoTrasporto.kilometri_ar || 0) + " km", // Proporzionale ai km percorsi su distanza allevamento
										T:
											Intl.NumberFormat("it-IT", {
												maximumFractionDigits: 0,
											}).format(costoTrasporto.peso_arrivo || 0) + " kg", //Proporzionale ai kg trasportati
									}[costoTrasporto.tipo_tariffa]
								}
							</Typography>

							<Typography variant="body2" gutterBottom align="right">
								{costoTrasporto.rimorchio
									? costoTrasporto.minimo_garantito_autotreno >
											costoTrasporto.peso_arrivo &&
									  "minimo garantito:" +
											Intl.NumberFormat("it-IT", {
												maximumFractionDigits: 0,
											}).format(costoTrasporto.minimo_garantito_autotreno || 0)
									: costoTrasporto.minimo_garantito_motrice >
											costoTrasporto.peso_arrivo &&
									  "minimo garantito:" +
											Intl.NumberFormat("it-IT", {
												maximumFractionDigits: 0,
											}).format(costoTrasporto.minimo_garantito_motrice || 0)}
							</Typography>
						</Grid>
						<Grid size={{ xs:2, sm:2, md:2, lg:2 }}>
							<Typography variant="subtitle2" gutterBottom align="right">
								{costoTrasporto.rimorchio ? "si" : "no"}
							</Typography>
						</Grid>
						<Grid size={{ xs:2, sm:2, md:2, lg:2 }}>
							<Typography variant="subtitle2" gutterBottom align="right">
								{costoTrasporto.rimorchio
									? Intl.NumberFormat("it-IT", {
											maximumFractionDigits: 4,
									  }).format(costoTrasporto.tariffa_autotreno || 0)
									: Intl.NumberFormat("it-IT", {
											maximumFractionDigits: 4,
									  }).format(costoTrasporto.tariffa_motrice || 0)}{" "}
								€
							</Typography>
						</Grid>
						<Grid size={{ xs:2, sm:2, md:2, lg:2 }}>
							<Typography variant="subtitle2" gutterBottom align="right">
								{Intl.NumberFormat("it-IT", {
									maximumFractionDigits: 2,
								}).format(costoTrasporto.costo_trasporto || 0)}{" "}
								€
							</Typography>
						</Grid>
					</Grid>
				);
			})}
		</Box>
	);
};

export default LottoCorrispettiviTrasportoVivoCard;
