import React, {useEffect, useState, useCallback} from "react";
import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "../../../redux/store";
import {Lotto, LottoConAttrezzaggio} from "./pianificazioneSlice";
import {TipoCapo, Parametri} from "../anagrafiche/anagraficheSlice";
import {lottiConAttrezzaggio} from "./utility/lottoUtility";

import {DateTime} from 'luxon';

import {
    CalcoloCaricoAmmissibile,
    DataOraInizioLotti,
    DataOraFineLotti,
    CalcolaPausa,
} from "../../common/CalcoloCaricoAmmissibile";

import {styled, Box} from "@mui/system";
import PaperMui from "@mui/material/Paper";

import Typography from "@mui/material/Typography";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import {theme} from "../../../theme";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import TimerOffTwoToneIcon from "@mui/icons-material/TimerOffTwoTone";
import Avatar from "@mui/material/Avatar";
import {convertToDateTime} from "../../common/dateUtils/convertToDateTime";

const Paper = styled(PaperMui)(({theme}) => ({
    color: theme.palette.primary.contrastText,
    backgroundColor: "#ccc",
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
}));

interface GiornoLottiHistoryProps {
    giornoSettimana?: DateTime;
    lotti: Lotto[];
    tipiCapo: TipoCapo[];
    // parametri: Parametri;
    // handleOpen: any;
}

const GiornoLottiHistory = ({
                                giornoSettimana,
                                lotti,
                                tipiCapo,
                            }: // parametri,
// handleOpen,
                            GiornoLottiHistoryProps) => {

    const parametri = useSelector<RootState, Parametri | undefined>((state) =>
        state.anagrafiche.storicoParametri.results.find((p) => {
            const startDate = convertToDateTime(p.data_validta_da);
            const endDate = convertToDateTime(p.data_validta_a);
            const adjustedData = convertToDateTime(giornoSettimana);

            return (
                startDate &&
                endDate &&
                adjustedData &&
                startDate <= adjustedData.plus({ hours: 6 }) &&
                adjustedData.minus({ hours: 6 }) <= endDate
            );
        })
    );



    const {
        minutiPrevisti,
        capiPrevisti,
        minutiEffettivi,
        capiEffettivi,
        eccedeTempo,
    }: {
        minutiPrevisti: number;
        capiPrevisti: number;
        minutiEffettivi: number;
        capiEffettivi: number;
        eccedeTempo: boolean;
    } = CalcoloCaricoAmmissibile(lotti, tipiCapo, parametri, giornoSettimana);
    const m: number = minutiPrevisti % 60;

    const me: number = minutiEffettivi % 60;
    const tempoEffettivo =
        minutiEffettivi > 0
            ? "tempo effettivo: " +
            (minutiEffettivi - me) / 60 +
            "h " +
            Math.round(me) +
            "'"
            : "tempo effettivo: ";

    lotti.sort((a, b) => {
        if (
            new Date(a.scheda_macello?.data_ora_inizio_macello || "") <
            new Date(b.scheda_macello?.data_ora_inizio_macello || "")
        ) {
            return -1;
        }
        return 1;
    });

    return (
        <Paper elevation={2}>
            <Typography
                variant="h4"
                component="span"
                color="theme.text.primary"
                // sx={{ flexGrow: 2 }}
            >
                Tempi macellazione
            </Typography>
            <Typography
                variant="h5"
                component="span"
                color="theme.text.primary"
                sx={{mx: 2}}
            >
                {convertToDateTime(giornoSettimana)?.setLocale("it").toFormat('cccc dd LLLL')}
            </Typography>
            <Timeline position="right">
                <TimelineItem>
                    <TimelineOppositeContent>
                        <Typography variant="h4" color={theme.palette.primary.main}>
                            Teorico
                        </Typography>
                        <Typography variant="subtitle1">{`tempo previsto: ${
                            (minutiPrevisti - m) / 60
                        }h ${Math.round(m)}'`}</Typography>
                        <Typography variant="subtitle1">{`n° capi previsto: ${Intl.NumberFormat(
                            "it-IT"
                        ).format(capiPrevisti)}`}</Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineDot/>
                        <TimelineConnector/>
                    </TimelineSeparator>
                    <TimelineContent>
                        <Typography variant="h4" color={theme.palette.secondary.main}>
                            Reale
                        </Typography>
                        <Typography variant="subtitle1">
                            {tempoEffettivo}
                            {"("}
                            {convertToDateTime(DataOraInizioLotti(lotti))?.setLocale("it").toFormat('HH:mm')}
                            {"-"}
                            {convertToDateTime(DataOraFineLotti(lotti))?.setLocale("it").toFormat('HH:mm')}
                            {")"}
                        </Typography>
                        <Typography variant="subtitle1">{`n° capi effettivo: ${Intl.NumberFormat(
                            "it-IT"
                        ).format(capiEffettivi)}`}</Typography>
                    </TimelineContent>
                </TimelineItem>

                {lottiConAttrezzaggio(lotti, tipiCapo, parametri).map(
                    (lotto, index) => {
                        let dataOraFineMacelloPrecedente: Date | null;
                        try {
                            dataOraFineMacelloPrecedente = new Date(
                                lotti[index - 1].scheda_macello?.data_ora_fine_macello || ""
                            );
                        } catch (err) {
                            dataOraFineMacelloPrecedente = null;
                        }

                        return (
                            <Box key={"punti_lotto_" + lotto.id}>
                                {dataOraFineMacelloPrecedente && (
                                    <TimelineItem>
                                        <TimelineOppositeContent color="text.secondary">
                                            <Typography variant="body2" sx={{mt: 2}}>
                                                Attrezzaggio prevista: {lotto.tempo_attrezzaggio}'
                                            </Typography>
                                        </TimelineOppositeContent>
                                        <TimelineSeparator>
                                            {/* <TimelineConnector /> */}

                                            <TimelineDot color="grey">
                                                <TimerOffTwoToneIcon/>
                                            </TimelineDot>

                                            {/* <TimelineConnector /> */}
                                        </TimelineSeparator>
                                        <TimelineContent>
                                            {lotto.scheda_macello?.data_ora_inizio_macello && (
                                                <Typography variant="body2" sx={{mt: 2}}>
                                                    {"Durata attrezzaggio: "}
                                                    {Intl.NumberFormat("it-IT", {
                                                        maximumFractionDigits: 0,
                                                    }).format(
                                                        CalcolaPausa(
                                                            dataOraFineMacelloPrecedente,
                                                            lotto.scheda_macello?.data_ora_inizio_macello
                                                        )
                                                    )}
                                                    '
                                                    {CalcolaPausa(
                                                        dataOraFineMacelloPrecedente,
                                                        lotto.scheda_macello?.data_ora_inizio_macello
                                                    ) > 12 && <FastfoodIcon sx={{mt: -1}}/>}
                                                </Typography>
                                            )}
                                        </TimelineContent>
                                    </TimelineItem>
                                )}
                                <TimelineItem>
                                    <TimelineOppositeContent color="text.secondary">
                                        <Typography variant="h6">
                                            {lotto.committente_desc}
                                        </Typography>
                                        <Typography variant="body2">
                                            {Intl.NumberFormat("it-IT").format(
                                                lotto.numero_capi || 0
                                            )}{" "}
                                            {lotto.tipo_capo_desc}
                                        </Typography>
                                        <Typography variant="body2">
                                            Durata:
                                            {Intl.NumberFormat("it-IT", {
                                                maximumFractionDigits: 0,
                                            }).format(
                                                lotto.scheda_macello?.minuti_processamento_stimati || 0
                                            )}
                                            '
                                        </Typography>

                                        <Typography variant="body2">
                                            Velocità:
                                            {Intl.NumberFormat("it-IT", {
                                                maximumFractionDigits: 0,
                                            }).format(
                                                lotto.scheda_macello?.velocita_processamento_stimata ||
                                                0
                                            ) + " capi/min."}
                                        </Typography>
                                    </TimelineOppositeContent>
                                    <TimelineSeparator>
                                        <TimelineConnector/>

                                        <TimelineDot
                                            sx={{
                                                bgcolor: lotto.resa_confermata
                                                    ? theme.palette.success.main
                                                    : theme.palette.primary.main,
                                            }}
                                        >
                                            <Avatar
                                                sx={{
                                                    width: "1.5rem",
                                                    height: "1.5rem",
                                                    bgcolor: lotto.resa_confermata
                                                        ? theme.palette.success.main
                                                        : theme.palette.primary.main,
                                                    color: "white",
                                                }}
                                            >
                                                {lotto.ordinamento_lotto}
                                            </Avatar>
                                        </TimelineDot>

                                        <TimelineConnector/>
                                    </TimelineSeparator>
                                    <TimelineContent>
                                        <Typography variant="h6">
                                            {lotto.produttore_pack?.nome}
                                        </Typography>
                                        <Typography variant="body2">
                                            {Intl.NumberFormat("it-IT").format(
                                                lotto.scheda_macello?.numero_capi_lavorati || 0
                                            )}
                                            {" capi lavorati"}
                                        </Typography>
                                        <Typography variant="body2">
                                            Durata:
                                            {Intl.NumberFormat("it-IT", {
                                                maximumFractionDigits: 0,
                                            }).format(
                                                lotto.scheda_macello?.minuti_processamento || 0
                                            ) + "' ("}
                                            {convertToDateTime(lotto.scheda_macello?.data_ora_inizio_macello)?.setLocale("it").toFormat('HH:mm')}{" "}
                                            -
                                            {convertToDateTime(lotto.scheda_macello?.data_ora_fine_macello)?.setLocale("it").toFormat('HH:mm')}
                                            {")"}
                                            {!!lotto.scheda_macello?.minuti_pausa && (
                                                <Typography variant="body2" display="inline">
                                                    {" - Pausa:"}
                                                    {Intl.NumberFormat("it-IT", {
                                                            maximumFractionDigits: 0,
                                                        }).format(lotto.scheda_macello?.minuti_pausa || 0) +
                                                        "' ("}
                                                    {convertToDateTime(lotto.scheda_macello?.data_ora_inizio_pausa)?.setLocale("it").toFormat('HH:mm')}{" "}
                                                    -
                                                    {convertToDateTime(lotto.scheda_macello?.data_ora_fine_pausa)?.setLocale("it").toFormat('HH:mm')}
                                                    {")"}
                                                    <FastfoodIcon sx={{mt: -1}}/>
                                                </Typography>
                                            )}
                                        </Typography>

                                        <Typography variant="body2">
                                            Velocità:
                                            {Intl.NumberFormat("it-IT", {
                                                maximumFractionDigits: 0,
                                            }).format(
                                                lotto.scheda_macello?.velocita_processamento || 0
                                            ) + " capi/min."}
                                        </Typography>
                                    </TimelineContent>
                                </TimelineItem>
                            </Box>
                        );
                    }
                )}
            </Timeline>
        </Paper>
    );
};

export default GiornoLottiHistory;
