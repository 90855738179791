import React from "react";
import Button from "@mui/material/Button";
import PrintIcon from "@mui/icons-material/Print";
import {fetchCSV_lavorazioni} from "../../../../api/reportistica/reportisticaApi";

interface ScaricaCSVLavorazioniButtonProps {
    resa_id: number;
    resa_confermata: boolean;
}

const ScaricaCSVLavorazioniButton = ({resa_id, resa_confermata}: ScaricaCSVLavorazioniButtonProps) => {

    const handleFetchCSV = async () => {
        try {
            const response = await fetchCSV_lavorazioni(resa_id);
            const blob = await response.blob(); // Interpreta la risposta come blob
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = `Lavorazioni_lotto_${resa_id}.csv`; // Nome del file di download
            a.click();
            window.URL.revokeObjectURL(url); // Rimuovi l'URL temporaneo per liberare memoria
        } catch (error) {
            console.error("Errore durante il download del CSV:", error);
            alert("Si è verificato un errore durante il download del CSV.");
        }
    };


    return (
        <Button sx={{marginTop: 2, marginBottom: 0}}
                startIcon={<PrintIcon/>}
                variant="contained"
                onClick={handleFetchCSV}
                disabled={!resa_confermata}>
            Scarica CSV lavorazioni
        </Button>
    );
};

export default ScaricaCSVLavorazioniButton;