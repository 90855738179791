import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "../../../redux/store";
import {
    LottiState,
    Lotto,
    fetchLotti,
    moveLotto,
    resetLottoCorrente,
    resetLotti,
    setLottoCorrente,
    deleteLotto,
    cambiaGiornoLotto,
    consolidaOrdinamento,
    getLotto,
} from "./pianificazioneSlice";
import {
    aggiornaTutteAnagrafiche,
    TipoCapo,
} from "../anagrafiche/anagraficheSlice";
import LottoPage from "./LottoPage";
import GiornoColumn from "./GiornoColumn";
import {DateTime} from 'luxon';
import {
    RecuperaDataCorrente,
    FissaDataCorrente,
} from "../../common/dateUtils/RecuperaDataCorrente";
import NavigazioneSettimaneToolbar from "../../common/dateUtils/NavigazioneSettimaneToolbar";
import {ErrorStatusTypes,} from "../../common/errorsDeclarations";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import TransitionsModal from "../../common/TransitionsModal";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import {convertToDateTime} from "../../common/dateUtils/convertToDateTime";

const lotti_semplificati: boolean = true;


const PianificazionePage = () => {

    const dispatch = useAppDispatch();
    const lotti: LottiState = useSelector<RootState, LottiState>(
        (state) => state.pianificazione.lotti
    );

    const tipiCapo = useSelector<RootState, TipoCapo[]>(
        (state) => state.anagrafiche.tipiCapo.results
    );

    const recupera_data_corrente = RecuperaDataCorrente();

    const minutiRefreshLotti = useSelector<RootState, number>(
        (state) => state.authentication.minutiRefreshLotti
    );

    const [data, setData] = React.useState<DateTime | null>(
        recupera_data_corrente
    );

    interface Settimana {
        numeroSettimana?: string;
        dateSettimana: (DateTime | undefined)[];
    }

    const caricaSettimana = (data?: DateTime | null) => {
        const dateSettimana: (DateTime | undefined)[] = [...Array(6)].map((_, i) =>
            convertToDateTime(data)?.startOf('week').plus({days: i})
        );
        return dateSettimana;
    };

    const [settimana, setSettimana] = React.useState<Settimana>({
        numeroSettimana: convertToDateTime(data)?.setLocale("it").toFormat('W'),
        dateSettimana: data ? caricaSettimana(data) : [],
    });

    const lottoCorrente_id = useSelector<RootState, number | null>(
        (state) => state.pianificazione.lottoCorrente_id
    );

    const [openLottoForm, setOpenLottoForm] = React.useState(false);

    useEffect(() => {
        data && FissaDataCorrente(data);

        let data_da = convertToDateTime(data)?.startOf('week').toJSDate();
        let data_a = convertToDateTime(data)?.startOf('week').toJSDate();
        dispatch(resetLotti());
        dispatch(fetchLotti({data_da, data_a, lotti_semplificati}));
        data_da = DateTime.fromJSDate(new Date(data_da || "")).plus({days: 1}).toJSDate();
        data_a = DateTime.fromJSDate(new Date(data_da || "")).plus({days: 1}).toJSDate();
        dispatch(fetchLotti({data_da, data_a, lotti_semplificati}));
        data_da = DateTime.fromJSDate(new Date(data_da || "")).plus({days: 1}).toJSDate();
        data_a = DateTime.fromJSDate(new Date(data_da || "")).plus({days: 1}).toJSDate();
        dispatch(fetchLotti({data_da, data_a, lotti_semplificati}));
        data_da = DateTime.fromJSDate(new Date(data_da || "")).plus({days: 1}).toJSDate();
        data_a = DateTime.fromJSDate(new Date(data_da || "")).plus({days: 1}).toJSDate();
        dispatch(fetchLotti({data_da, data_a, lotti_semplificati}));
        data_da = DateTime.fromJSDate(new Date(data_da || "")).plus({days: 1}).toJSDate();
        data_a = DateTime.fromJSDate(new Date(data_da || "")).plus({days: 1}).toJSDate();
        dispatch(fetchLotti({data_da, data_a, lotti_semplificati}));

        // const data_da = convertToDateTime(data)?.startOf('week').toJSDate();
        // const data_a = convertToDateTime(data)?.endOf('week').toJSDate();
        // dispatch(fetchLotti({ data_da, data_a, lotti_semplificati }));

        const newSettimana = {
            numeroSettimana: convertToDateTime(data)?.setLocale("it").toFormat('W'),
            dateSettimana: caricaSettimana(data),
        };
        setSettimana(newSettimana);
    }, [data]);

    useEffect(() => {
        const timeRefresh = minutiRefreshLotti * 60 * 1000;

        const intervalloRefreshLotti = setInterval(() => {
            const ultimoAggiornamento = lotti.ultimo_aggiornamento;
            const data_da = convertToDateTime(data)?.startOf('week').toJSDate();
            const data_a = convertToDateTime(data)?.endOf('week').toJSDate();

            dispatch(
                fetchLotti({data_da, data_a, lotti_semplificati, ultimoAggiornamento})
            );
        }, timeRefresh);
        return () => clearInterval(intervalloRefreshLotti);
    }, [data, lotti.ultimo_aggiornamento]);

    const refresh = () => {
        const data_da = convertToDateTime(data)?.startOf('week').toJSDate();
        const data_a = convertToDateTime(data)?.endOf('week').toJSDate();
        dispatch(resetLotti());
        dispatch(fetchLotti({data_da, data_a, lotti_semplificati}));
        dispatch(aggiornaTutteAnagrafiche());
    };

    const [lottoCorrenteId, setlottoCorrenteId] = React.useState<
        number | null | undefined
    >(null);

    const [dataCorrente, setDataCorrente] = React.useState<Date | null>(null);

    const [contaLottiDubbi, setContaLottiDubbi] = React.useState(true);
    const handleChangeLottiDubbi = () => {
        setContaLottiDubbi(!contaLottiDubbi);
    };

    const lottiFiltroDubbio = !contaLottiDubbi
        ? lotti.results.filter((lotto) => lotto.in_dubbio == false)
        : lotti.results;

    const totaleCapiSettimana = Intl.NumberFormat("it-IT").format(
        lottiFiltroDubbio.reduce(
            (valoreCalcolato, lottoCorrente) =>
                valoreCalcolato +
                (lottoCorrente.numero_capi == undefined
                    ? 0
                    : lottoCorrente.numero_capi),
            0
        )
    );

    const handleOpen =
        (lottoId: number | null, giornoSettimana: Date | null) =>
            (event: React.MouseEvent) => {
                event.preventDefault();
                dispatch(setLottoCorrente({lottoCorrente_id: lottoId}));
                setDataCorrente(giornoSettimana);
                setOpenLottoForm(true);
            };

    const handleClose = () => {
        lottoCorrente_id && dispatch(getLotto(lottoCorrente_id));
        dispatch(resetLottoCorrente());
        setOpenLottoForm(false);
    };

    const handlerMoveLotto = (dragId: number, hoverId: number) => {
        dispatch(moveLotto({dragId, hoverId}));
    };

    const handlerDeleteLotto = (lottoToDelete: Lotto) => {
        dispatch(deleteLotto(lottoToDelete));
    };

    const handlerConsolidaOrdinamento = (dragId: number) => {
        dispatch(consolidaOrdinamento({dragId}));
    };
    const handlerCambiaGiornoLotto = (dragId: number, hoverValue: any) => {
        dispatch(cambiaGiornoLotto({dragId, hoverValue}));
    };

    return (
        <>
            <Backdrop
                sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={lotti.errorsStack.status == ErrorStatusTypes.PENDING}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
            <TransitionsModal open={openLottoForm} handleClose={handleClose}>
                <LottoPage
                    lotto_id={lottoCorrenteId}
                    dataCorrente={dataCorrente}
                    handleClose={handleClose}
                />
            </TransitionsModal>
            <NavigazioneSettimaneToolbar
                titolo={"Pianificazione"}
                setData={setData}
                data={data}
                refresh={refresh}
                Toolbar={() => {
                    return (
                        <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            sx={{ml: 2}}
                            spacing={1}
                        >
                            <Typography variant="h6" component="div">
                                {"Tot. capi settimana: "}
                                {totaleCapiSettimana}
                            </Typography>

                            <FormControlLabel
                                label={
                                    <Typography>
                                        {contaLottiDubbi ? "compresi dubbi" : "esclusi dubbi"}{" "}
                                    </Typography>
                                }
                                control={
                                    <Switch
                                        id="switch_lotti_dubbi"
                                        checked={contaLottiDubbi}
                                        onChange={handleChangeLottiDubbi}
                                        color="primary"
                                        size="small"
                                    />
                                }
                            />
                            {/* il pulsante per generare i lotti standard è stato commentato perchè non utilizzato e per evitare che venga cliccato per errore*/}
                            {/* <Tooltip title="Aggiungi lotti standard">
								<Button
									size="small"
									variant="outlined"
									onClick={handleOpenStandard}
								>
									<DashboardCustomizeIcon />
								</Button>
							</Tooltip> */}
                        </Stack>
                    );
                }}
            />
            <Grid container spacing={1} sx={{marginTop: 1}}>
                {settimana.dateSettimana.map((giornoSettimana, i) => (
                    <Grid size={{xs: 11, sm:6, md: 4, lg: 2}} key={`colonna_${i}`}>
                        <GiornoColumn
                            key={`giorno_${i}`}
                            giornoSettimana={giornoSettimana}
                            lotti={lottiFiltroDubbio}
                            tipiCapo={tipiCapo}
                            // parametri={parametri}
                            handleOpen={handleOpen}
                            moveLotto={handlerMoveLotto}
                            deleteLotto={handlerDeleteLotto}
                            cambiaGiornoLotto={handlerCambiaGiornoLotto}
                            consolidaOrdinamento={handlerConsolidaOrdinamento}
                        />
                    </Grid>
                ))}
                {/* </DndProvider> */}
            </Grid>
        </>
    );
};

export default PianificazionePage;
