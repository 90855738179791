import React from "react";
import type { CSSProperties, FC } from "react";
import { useDrop } from "react-dnd";
import { ItemTypes } from "./ItemTypes";
import Box from "@mui/material/Box";

export interface DnDDroppableBoxProps {
	// id: number;
	value: any;
	accept: string[];
	children?: React.ReactNode;
	disabled?: boolean;
}

const DnDDroppableBox: FC<DnDDroppableBoxProps> = ({
	// id,
	value,
	accept,
	disabled = false,
	...props
}) => {
	const [{ canDrop, isOver }, drop] = useDrop(() => ({
		accept,
		drop: () => ({ value: value }),
		collect: (monitor) => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop(),
		}),
	}));

	const isActive = canDrop && isOver;
	let backgroundColor = "#222";
	if (isActive) {
		backgroundColor = "darkgreen";
	} else if (canDrop) {
		backgroundColor = "darkkhaki";
	}

	return (
		<Box
			ref={!disabled ? drop : null}
			role={"Dustbin"}
			sx={{ border: isOver ? 3 : 0, borderColor: "primary.main" }}
			children={props.children}
		/>
	);
};

export default DnDDroppableBox;
