import React from "react";
import { CorrispettiviLotto } from "../bolleFattureSlice";
import Grid from "@mui/material/Grid2";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

interface LottoCorrispettiviMacellazioneCardProps {
	corrispettiviLotto: CorrispettiviLotto;
}

const totaleCorrispettiviMacellazione = (
	corrispettiviLotto: CorrispettiviLotto
) => {
	const totaleCorrispettiviMacellazione =
		corrispettiviLotto.tariffa_macellazione.calcolo_peso == "P"
			? corrispettiviLotto.peso_lotto_partenza *
			  corrispettiviLotto.tariffa_macellazione.prezzo
			: corrispettiviLotto.peso_lotto_arrivo *
			  corrispettiviLotto.tariffa_macellazione.prezzo;
	return totaleCorrispettiviMacellazione;
};

const LottoCorrispettiviMacellazioneCard = ({
	corrispettiviLotto,
}: LottoCorrispettiviMacellazioneCardProps) => {
	return (
		<Box>
			<Typography variant="h6" gutterBottom>
				Corrispettivi Macellazione
			</Typography>
			<Box sx={{ mt: 3 }}>
				<Grid container spacing={2}>
					<Grid size={{ xs:4, sm:4, md:4, lg:4 }}>
						<Typography variant="body2">Tipo capo</Typography>
					</Grid>

					<Grid size={{ xs:3, sm:3, md:3, lg:3 }}>
						<Typography variant="body2" align="right">
							{corrispettiviLotto.tariffa_macellazione.calcolo_peso == "P"
								? "Peso Partenza"
								: "Peso Arrivo"}
						</Typography>
					</Grid>
					<Grid size={{ xs:2, sm:2, md:2, lg:2 }}>
						<Typography variant="body2" align="right">
							Tariffa
						</Typography>
					</Grid>

					<Grid size={{ xs:3, sm:3, md:3, lg:3 }}>
						<Typography variant="body2" align="right">
							Corrispettivo
						</Typography>
					</Grid>
				</Grid>
				<Divider />
			</Box>

			<Grid container spacing={2} key="tariffa_macellazione">
				<Grid size={{ xs:4, sm:4, md:4, lg:4 }}>
					<Typography variant="subtitle2" gutterBottom>
						{corrispettiviLotto.tariffa_macellazione.tipo_capo_desc}
					</Typography>
				</Grid>
				<Grid size={{ xs:3, sm:3, md:3, lg:3 }}>
					<Typography variant="subtitle2" gutterBottom align="right">
						{corrispettiviLotto.tariffa_macellazione.calcolo_peso == "P"
							? Intl.NumberFormat("it-IT", {
									maximumFractionDigits: 0,
							  }).format(corrispettiviLotto.peso_lotto_partenza || 0)
							: Intl.NumberFormat("it-IT", {
									maximumFractionDigits: 0,
							  }).format(corrispettiviLotto.peso_lotto_arrivo || 0)}{" "}
						kg
					</Typography>
				</Grid>

				<Grid size={{ xs:2, sm:2, md:2, lg:2 }}>
					<Typography variant="subtitle2" gutterBottom align="right">
						{Intl.NumberFormat("it-IT", {
							maximumFractionDigits: 4,
						}).format(corrispettiviLotto.tariffa_macellazione.prezzo || 0)}{" "}
						€
					</Typography>
				</Grid>
				<Grid size={{ xs:3, sm:3, md:3, lg:3 }}>
					<Typography variant="subtitle2" gutterBottom align="right">
						{Intl.NumberFormat("it-IT", {
							maximumFractionDigits: 2,
						}).format(
							totaleCorrispettiviMacellazione(corrispettiviLotto || 0)
						)}{" "}
						€
					</Typography>
				</Grid>
			</Grid>
		</Box>
	);
};

export default LottoCorrispettiviMacellazioneCard;
