import React, { useEffect } from "react";

import { ReportAutisti } from "../reportAutistiCamionSlice";

import {DateTime, Duration} from 'luxon';
import { CSVLink, CSVDownload } from "react-csv";

import { tabellaOrariAutistaMesePayroll } from "./tabellaOrariAutistaMesePayroll";
import { Box } from "@mui/system";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {convertToDateTime} from "../../../common/dateUtils/convertToDateTime";

interface ReportAutistiMeseTableProps {
	reportAutistiMese: ReportAutisti[];
	nome: string | undefined;
	cognome: string | undefined;
}

const calcoloTotaleOre = (reportAutistiMese: ReportAutisti[]) => {
	const totaleOre = reportAutistiMese.reduce(function (
		previousValue: number,
		currentValue: ReportAutisti
	) {
		return previousValue + Number(currentValue.delta_turno_per_autista || 0);
	},
	0);
	return totaleOre;
};

const ReportAutistiMeseTable = ({
	reportAutistiMese,
	nome,
	cognome,
}: ReportAutistiMeseTableProps) => {
	const [totaleOre, setTotaleOre] = React.useState(0);

	useEffect(() => {
		setTotaleOre(calcoloTotaleOre(reportAutistiMese));
	}, [reportAutistiMese]);

	return (
		<React.Fragment>
			<TableContainer component={Paper} sx={{ width: "100%" }}>
				<Box>
					<Table size="small" aria-label="report mensile autisti">
						<TableHead>
							<TableRow>
								<TableCell
									align="center"
									colSpan={4}
									sx={{
										paddingTop: 3,
										paddingBottom: 3,
										fontWeight: "bold",
										fontSize: 18,
										textTransform: "uppercase",
									}}
								>
									{cognome} {nome}
									{" - "}
									<span style={{ textTransform: "none" }}>Ore totali</span>{" "}
									{
										Math.floor(Duration.fromObject({ seconds: Number(totaleOre) || 0 }).as("hours"))
									} :
									{
										Math.floor(Duration.fromObject({ seconds: Number(totaleOre) || 0 }).as("milliseconds") / 60000)
									}

								</TableCell>
								<TableCell align="center" colSpan={1}>
									{reportAutistiMese.length > 0 && (
										<CSVLink
											data={tabellaOrariAutistaMesePayroll(reportAutistiMese)}
										>
											Dati x payroll
										</CSVLink>
									)}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell sx={{ fontWeight: "bold" }}>
									Data inizio turno
								</TableCell>
								<TableCell sx={{ fontWeight: "bold" }} align="center">
									Ora inizio turno
								</TableCell>
								<TableCell sx={{ fontWeight: "bold" }}>
									Data fine turno
								</TableCell>
								<TableCell sx={{ fontWeight: "bold" }} align="center">
									Ora fine turno
								</TableCell>
								<TableCell sx={{ fontWeight: "bold" }} align="center">
									Ore lavorate
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{reportAutistiMese.length > 0 &&
								reportAutistiMese.map((dettaglioAutista) => (
									<TableRow key={dettaglioAutista.id}>
										<TableCell>
											{dettaglioAutista.data_ora_partenza_sede &&
												convertToDateTime(dettaglioAutista.data_ora_partenza_sede)?.setLocale("it").toFormat('dd LLLL yyyy')}
										</TableCell>
										<TableCell align="center">
											{dettaglioAutista.data_ora_partenza_sede &&
												convertToDateTime(dettaglioAutista.data_ora_partenza_sede)?.setLocale("it").toFormat('HH:mm')}
										</TableCell>
										<TableCell>
											{dettaglioAutista.data_ora_fine_turno &&
												convertToDateTime(dettaglioAutista.data_ora_fine_turno)?.setLocale("it").toFormat('dd LLLL yyyy')}
										</TableCell>
										<TableCell align="center">
											{dettaglioAutista.data_ora_fine_turno &&
												convertToDateTime(dettaglioAutista.data_ora_fine_turno)?.setLocale("it").toFormat('HH:mm')}
										</TableCell>
										<TableCell align="center">
											{
												Math.floor(
													Duration.fromObject({ seconds: Number(dettaglioAutista.delta_turno_per_autista) || 0 }).as("hours")
												)
											} :
											{
												Math.floor(
													Duration.fromObject({ seconds: Number(dettaglioAutista.delta_turno_per_autista) || 0 }).as("milliseconds") / 60000
												)
											}

										</TableCell>
									</TableRow>
								))}
						</TableBody>
					</Table>
				</Box>
			</TableContainer>
		</React.Fragment>
	);
};

export default ReportAutistiMeseTable;
