import React from "react";
import { IndicatoriTotaliCommittente } from "../../grafici/graficiKpiSlice";
import { DateTime } from 'luxon';

import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer,
	ComposedChart,
	Line,
} from "recharts";

import { KgCommittenti } from "./kgCommittentiSlice";
import { Committente } from "../../../anagrafiche/anagraficheSlice";

import { stringToColour } from "../../../../common/stringToColour";
const generaData = (
	kgCommittentiMese: KgCommittenti[],
	committenti: Committente[]
) => {
	const data: {}[] = [];

	// const mesi: string[] = [];
	// indicatoriTotaliCommittenti.map((mese_tipo_kg) => {
	// 	if (!mesi.includes(mese_tipo_kg.mese)) {
	// 		mesi.push(mese_tipo_kg.mese);
	// 	}
	// });

	committenti.map((committente) => {
		const peso_lotto_partenza = kgCommittentiMese
			.filter(
				(meseCommittente) => meseCommittente.committente == committente.id
			)
			.reduce(
				(previousValue, currentValue) =>
					previousValue + (currentValue.peso_lotto_partenza || 0),
				0
			);
		const peso_lotto_arrivo = kgCommittentiMese
			.filter(
				(meseCommittente) => meseCommittente.committente == committente.id
			)
			.reduce(
				(previousValue, currentValue) =>
					previousValue + (currentValue.peso_lotto_arrivo || 0),
				0
			);
		const record = {
			committente: committente.nome,
			peso_lotto_partenza: peso_lotto_partenza,
			peso_lotto_arrivo: peso_lotto_arrivo,

			calo: (peso_lotto_partenza - peso_lotto_arrivo) / peso_lotto_partenza,
		};
		if (record.peso_lotto_arrivo > 0) {
			data.push(record);
		}
	});

	return { data: data };
};

interface KgCommittentiMeseGraficoProps {
	kgCommittentiMese: KgCommittenti[];
	committenti: Committente[];
}
const KgCommittentiMeseGrafico = ({
	kgCommittentiMese,
	committenti,
}: KgCommittentiMeseGraficoProps) => {
	return (
		<ResponsiveContainer>
			<ComposedChart
				// width={400}
				// height={200}
				// data={generaData(indicatoriTotaliCommittenti).data}
				data={generaData(kgCommittentiMese, committenti).data}
				margin={{
					top: 8,
					right: 15,
					left: 35,
					bottom: 5,
				}}
				layout="vertical"
			>
				<CartesianGrid strokeDasharray="3 3" />
				<XAxis
					type="number"
					fontSize={13}
					tickFormatter={(value) =>
						Intl.NumberFormat("it-IT", {
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
						}).format(value * 100) + "%"
					}
				/>
				<YAxis width={120} dataKey="committente" type="category" />
				<Tooltip
					formatter={(value) => {
						if (typeof value == "number") {
							return (
								Intl.NumberFormat("it-IT", {
									minimumFractionDigits: 2,
									maximumFractionDigits: 2,
								})
									.format(value * 100)
									.toString() + "%"
							);
						} else {
							return value;
						}
					}}
				/>

				<Legend wrapperStyle={{ fontSize: 13 }} />
				<Bar barSize={12} key="key_kg" dataKey="calo" fill={"#57a639"} />
			</ComposedChart>
		</ResponsiveContainer>
	);
};
export default KgCommittentiMeseGrafico;
