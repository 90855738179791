import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
	ErrorStatusTypes,
	ErrorsStack,
	parseErrorMessage,
} from "../../../common/errorsDeclarations";

import {
	Lotto,
	getLotto,
	PianificazioneStrutturaState,
} from "../../pianificazione/pianificazioneSlice";
import { fetchLavorazioni } from "../../lavorazioni/lavorazioniSlice";
import * as confezionamentiApi from "../../../../api/confezionamenti/confezionamentiApi";
import { DateTime } from 'luxon';
import { toast } from "react-toastify";



export interface Confezionamento {
	id: number | null | undefined;
	lotto: number;
	data_ora_inizio_confezionamento?: Date | null;
	data_ora_fine_confezionamento?: Date | null;
	note?: string;
	errorsStack?: ErrorsStack;
}
export interface ConfezionamentiState {
	count: number;
	page: number;
	num_pages: number;
	next?: URL;
	previous?: URL;
	results: Confezionamento[];
	errorsStack: ErrorsStack;
}

// export interface LottoCorrente extends Lotto {
//   errorsStack: ErrorsStack;
// }

export interface ConfezionamentoStrutturaState {
	confezionamenti: ConfezionamentiState;
}

const initialState: ConfezionamentoStrutturaState = {
	confezionamenti: {
		count: 0,
		page: 0,
		num_pages: 0,
		next: undefined,
		previous: undefined,
		results: [],
		errorsStack: { status: ErrorStatusTypes.OK },
	},
};

export const fetchConfezionamenti = createAsyncThunk(
	"confezionamento/fetchConfezionamenti",
	async (dataRange?: { data_da?: Date; data_a?: Date }) => {
		return await confezionamentiApi.fetchConfezionamenti(
			dataRange?.data_da,
			dataRange?.data_a
		);
	}
);

export const getConfezionamento = createAsyncThunk(
	"confezionamento/getConfezionamento",
	async (confezionamentoId: number) => {
		return await confezionamentiApi.getConfezionamento(confezionamentoId);
	}
);

export const saveConfezionamento = createAsyncThunk(
	"confezionamento/saveConfezionamento",
	async (confezionamentoToSave: Confezionamento, thunkApi) => {
		const pianificazioneState = thunkApi.getState() as {
			pianificazione: PianificazioneStrutturaState;
		};
		const lotto = pianificazioneState.pianificazione.lotti.results.find(
			(lotto) => lotto.id == confezionamentoToSave.lotto
		);

		return await confezionamentiApi
			.saveConfezionamento(confezionamentoToSave)
			.then((response) => {
				thunkApi.dispatch(getLotto(lotto?.id || 0));
				return response;
			});
	}
);

export const recuperaPesature = createAsyncThunk(
	"confezionamento/recuperaPesature",
	async (lotto: Lotto, thunkApi) => {
		return await confezionamentiApi.recuperaPesature(lotto).then((response) => {
			thunkApi.dispatch(fetchLavorazioni(lotto?.id || 0));
			return response;
		});
	}
);

export const confezionamentiSlice = createSlice({
	name: "confezionamentiState",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// fetch Confezionamenti
		builder.addCase(fetchConfezionamenti.pending, (state, action) => {
			state.confezionamenti.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(fetchConfezionamenti.fulfilled, (state, action) => {
			state.confezionamenti = action.payload;

			state.confezionamenti.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(fetchConfezionamenti.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.confezionamenti.errorsStack = parseErrorMessage(action.error);
		});

		// get Confezionamento
		builder.addCase(getConfezionamento.pending, (state, action) => {
			state.confezionamenti.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(getConfezionamento.fulfilled, (state, action) => {
			state.confezionamenti.results = state.confezionamenti.results.map(
				(confezionamento) => {
					if (confezionamento.id == action.payload.id) {
						return action.payload;
					} else {
						return confezionamento;
					}
				}
			);
			state.confezionamenti.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(getConfezionamento.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.confezionamenti.errorsStack = parseErrorMessage(action.error);
		});

		// save Confezionamento
		builder.addCase(saveConfezionamento.pending, (state, action) => {
			state.confezionamenti.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(saveConfezionamento.fulfilled, (state, action) => {
			state.confezionamenti.results = state.confezionamenti.results.map(
				(confezionamento) => {
					if (confezionamento.id == action.payload.id) {
						return action.payload;
					} else {
						return confezionamento;
					}
				}
			);
			state.confezionamenti.errorsStack = { status: ErrorStatusTypes.SUCCESS };
			toast.success("Confezionamento salvato.");
		});
		builder.addCase(saveConfezionamento.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");

			state.confezionamenti.results = state.confezionamenti.results.map(
				(confezionamento) => {
					if (confezionamento.id == action.meta.arg.id) {
						return {
							...confezionamento,
							errorsStack: {
								status: ErrorStatusTypes.ERROR,
								fieldsErrors: JSON.parse(action?.error?.message || ""),
							},
						};
					} else {
						return confezionamento;
					}
				}
			);
			state.confezionamenti.errorsStack = {
				status: ErrorStatusTypes.ERROR,
			};
		});

		// recupera le pesate Confezionamento
		builder.addCase(recuperaPesature.pending, (state, action) => {
			state.confezionamenti.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(recuperaPesature.fulfilled, (state, action) => {
			state.confezionamenti.errorsStack = { status: ErrorStatusTypes.SUCCESS };
			toast.success(action.payload);
		});
		builder.addCase(recuperaPesature.rejected, (state, action) => {
			toast.error("Recupero fallito:" + action?.error?.message || "");
		});
	},
});

// Action creators are generated for each case reducer function
export const {} = confezionamentiSlice.actions;

export const confezionamentiSliceReducer = confezionamentiSlice.reducer;
