import React from "react";
import { useEffect, useState } from "react";
import { useParams} from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../../../redux/store";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import {
	LottiState,
	fetchLotti,
	resetLotti,
	Lotto,
} from "../../../pianificazione/pianificazioneSlice";
import NavigazioneGiorniToolbar from "../../../../common/dateUtils/NavigazioneGiorniToolbar";
import {FissaDataCorrente,} from "../../../../common/dateUtils/RecuperaDataCorrente";
import LottoLavorazioniPdf from "./LottoLavorazioniPdf";
import { ErrorStatusTypes } from "../../../../common/errorsDeclarations";
import { PDFViewer, Page, Document } from "@react-pdf/renderer";
import { DateTime } from 'luxon';
import { Box } from "@mui/system";
import {convertToDateTime} from "../../../../common/dateUtils/convertToDateTime";

interface ProgammaIncassettamentoProps {
	lotti: Lotto[];
}

const ProgammaIncassettamento = ({ lotti }: ProgammaIncassettamentoProps) => {
	return (
		<Document>
			{lotti.map((lotto) => {
				return (
					<Page
						size="A4"
						orientation="landscape"
						style={{ backgroundColor: "#fff", paddingTop: 20 }}
						key={"ProgammaIncassettamento_lotto_" + lotto.id}
					>
						<LottoLavorazioniPdf lotto={lotto} />
					</Page>
				);
			})}
		</Document>
	);
};

const ProgammaIncassettamentoStampaPage = () => {
	
	const dispatch = useAppDispatch();

	const lotti: LottiState = useSelector<RootState, LottiState>(
		(state) => state.pianificazione.lotti
	);

	const { data_yyyy_mm_dd } = useParams();
	const  giornoSettimana  =  convertToDateTime(data_yyyy_mm_dd);
	const initData = convertToDateTime(giornoSettimana);
	const [data, setData] = React.useState<DateTime | null>(initData);
	const [refresh, setRefresh] = useState<boolean>(false);

	useEffect(() => {
		data && FissaDataCorrente(data);

		// localStorage.setItem(
		// 	DATA_PROGRAMMA_DI_INCASSETTAMENTO_CORRENTE,
		// 	convertToDateTime(data)?.setLocale("it").toFormat("yyyy-MM-dd")
		// );
		const data_da = data;
		const data_a = data;
		dispatch(resetLotti());
		dispatch(fetchLotti({ data_da: data_da?.toJSDate(), data_a: data_a?.toJSDate(), }));
		setRefresh(false);
	}, [data, refresh]);

	return (
		<Box>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={lotti.errorsStack.status == ErrorStatusTypes.PENDING}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
			<NavigazioneGiorniToolbar
				titolo={"Programma Incassettamento"}
				setData={setData}
				data={data}
				refresh={() => {
					setRefresh(true);
				}}
			/>
			<Box sx={{ backgroundColor: "#999", height: "80vh" }}>
				<PDFViewer width={"100%"} height={"100%"}>
					<ProgammaIncassettamento lotti={lotti.results} />
				</PDFViewer>
			</Box>
		</Box>
	);
};

export default ProgammaIncassettamentoStampaPage;
