import React from "react";
import ReactPDF from "@react-pdf/renderer";
import moment from "moment";

import { Text, View, StyleSheet, Image } from "@react-pdf/renderer";

import { Lotto } from "../../pianificazione/pianificazioneSlice";
import { SchedaCarico } from "../schedeCaricoSlice";
const logoScarso = require("../../../../../static/images/logo/logo-Scarso_nuovo_solo.png");

// Create styles
const styles = StyleSheet.create({
	page: {
		backgroundColor: "#fff",
		paddingTop: 20,
	},
	section: {
		marginTop: 10,
		marginLeft: 10,
		marginRight: 10,
		padding: 10,
	},
	sectionIntestazione: {
		// width: "100%",
		marginTop: 5,
		marginLeft: 10,
		marginRight: 10,
		padding: 10,
		paddingTop: 10,
		paddingBottom: 7,
		backgroundColor: "#777777",
		color: "#ffffff",
	},
	text: {
		fontSize: 14,
		fontStyle: "normal",
	},
});

interface FogliOrdiniCaricoPdfProps {
	lotto: Lotto;
	schedaCarico: SchedaCarico;
}

const FogliOrdiniCaricoPdf = ({
	lotto,
	schedaCarico,
}: FogliOrdiniCaricoPdfProps) => (
	<View
		style={{
			flexDirection: "column",
			justifyContent: "space-between",
			height: 820,
		}}
		key={"schedaCarico_" + schedaCarico.id}
	>
		<View>
			<View
				style={[
					styles.sectionIntestazione,
					{
						flexDirection: "row",
						justifyContent: "space-between",
						marginTop: 0,
						paddingTop: 5,
					},
				]}
			>
				<Text style={styles.text}>
					Ordine di Carico n°
					<Text style={{ fontSize: 18, color: "#f5ed9a" }}>
						{schedaCarico.numero_ordine_giorno}
					</Text>
					{" del " + moment(lotto.data_lavorazione).format("DD/MM/YYYY")}
				</Text>
				<Text
					style={[
						styles.text,
						{
							fontWeight: "bold",
							marginBottom: 1,
							paddingBottom: 0,
							fontSize: 20,
						},
					]}
				>
					{"Scarso Romualdo  "}
					<Image
						src={logoScarso}
						style={{
							width: 30,
							height: 30,
							// margin: 0,
							padding: 0,
							paddingLeft: "10px",
							marginLeft: "3px",
						}}
					/>
				</Text>
			</View>
			<View
				style={{
					flexDirection: "row",
					justifyContent: "space-between",
				}}
			>
				<View style={styles.section}>
					<View
						style={{
							flexDirection: "row",
						}}
					>
						<Text style={[styles.text, { paddingTop: "6px" }]}>
							autisti:{"   "}
						</Text>
						<View>
							{schedaCarico.autisti_pack.map((autista) => {
								return (
									<Text
										style={[
											styles.text,
											{
												fontWeight: "bold",
												marginBottom: 1,
												paddingBottom: 0,
												fontSize: 20,
											},
										]}
										key={"autista_" + autista.id}
									>
										{autista.nome + " " + autista.cognome}
									</Text>
								);
							})}
						</View>
					</View>

					<Text style={[styles.text, {}]}>
						{"camion: "}
						<Text
							style={[
								styles.text,
								{
									fontWeight: "bold",
									marginBottom: 1,
									paddingBottom: 0,
									fontSize: 20,
								},
							]}
						>
							{schedaCarico.camion_pack?.nome +
								" " +
								schedaCarico.camion_pack?.targa}
						</Text>
						{"\n"}
						{schedaCarico.rimorchio ? "   con rimorchio" : "   senza rimorchio"}
					</Text>
				</View>

				<View style={styles.section}>
					<Text style={styles.text}>
						{"destinatario:   "}
						<Text
							style={[
								styles.text,
								{
									fontWeight: "bold",
									marginBottom: 1,
									paddingBottom: 0,
									fontSize: 20,
									textOverflow: "ellipsis",
									maxWidth: 200,
								},
							]}
						>
							{lotto.committente_desc}
						</Text>
					</Text>
					<Text style={styles.text}>destinazione: macello Scarso</Text>
					<Text style={styles.text}>trasportatore: Iolepec</Text>
				</View>
			</View>

			<View style={styles.section}>
				<Text style={styles.text}>
					appuntamento:{" "}
					<Text style={{ fontSize: 20, color: "#052282" }}>
						{moment(schedaCarico.data_ora_carico_pianificata).format(
							"dddd, D MMMM YYYY, H:mm"
						)}
					</Text>
				</Text>
			</View>
			<View style={styles.section}>
				<View style={{ flexDirection: "row" }}>
					<Text style={[styles.text, { marginRight: 3 }]}>Allevatore:</Text>
					<View>
						<Text style={{ fontSize: 18, fontWeight: "medium" }}>
							{lotto.allevamento_pack?.nome +
								" (" +
								lotto.allevamento_pack?.codice_asl +
								")"}
						</Text>
						<Text style={{ fontSize: 18, fontStyle: "medium" }}>
							{lotto.allevamento_pack?.indirizzo}
						</Text>
						<Text style={{ fontSize: 18 }}>
							{lotto.allevamento_pack?.cap} {lotto.allevamento_pack?.comune} -{" "}
							{lotto.allevamento_pack?.provincia_desc}
						</Text>
						<Text style={{ fontSize: 15, fontStyle: "semibold" }}></Text>
					</View>
				</View>
				<View style={{ flexDirection: "row", marginTop: 3 }}>
					<Text style={[styles.text, { marginLeft: 24, marginRight: 3 }]}>
						pesa:
					</Text>
					<View>
						{lotto.allevamento_pack?.indirizzo_pesa ? (
							<Text style={styles.text}>
								{lotto.allevamento_pack?.indirizzo_pesa}
							</Text>
						) : (
							<Text style={styles.text}>presso l'allevamento</Text>
						)}
					</View>
				</View>
			</View>
			<View style={[styles.section]}>
				<Text style={[styles.text, { fontSize: 18, marginBottom: 2 }]}>
					{lotto.tipo_capo_desc + ": " + schedaCarico.numero_capi_pianificati}
				</Text>
				<Text style={[styles.text, { fontSize: 18, marginBottom: 2 }]}>
					{"peso previsto: " +
						(lotto.peso_medio
							? Intl.NumberFormat("it-IT").format(lotto.peso_medio || 0) + " kg"
							: "")}
				</Text>
				<Text style={[styles.text, { fontSize: 18, marginBottom: 2 }]}>
					{"gabbie: " + schedaCarico.numero_fori}
				</Text>
				<Text style={[styles.text, { fontSize: 18, marginBottom: 2 }]}>
					{"animali per gabbia: " + schedaCarico.numero_capi_foro}
				</Text>
			</View>

			<View style={[styles.section, { marginTop: 0 }]}>
				<Text style={[styles.text, { textTransform: "uppercase" }]}>
					{"orario inizio carico  ____ : ____"}
				</Text>
				<Text
					style={[styles.text, { marginTop: 10, textTransform: "uppercase" }]}
				>
					{"orario fine carico    ____ : ____"}
				</Text>
			</View>
			<View style={[styles.section, { marginLeft: 250 }]}>
				<Text style={styles.text}>
					firma dell'allevatore per presa visione al carico
				</Text>
				<Text style={[styles.text, { marginLeft: 50, marginTop: 30 }]}>
					..............................................
				</Text>
			</View>
		</View>
		<View>
			<View
				style={[
					styles.section,
					{
						marginBottom: 0,
						paddingBottom: 0,
						paddingLeft: 0,
					},
				]}
			>
				<Text
					style={{
						color: "#666",
						fontSize: 9,
						marginLeft: 0,
						paddingLeft: 0,
					}}
				>
					note per autista
				</Text>
			</View>
			<View style={[styles.section, { borderWidth: 0.5, marginTop: 2 }]}>
				<Text style={styles.text}>{schedaCarico.note}</Text>
			</View>
			<View style={[styles.section, { marginTop: 20 }]}>
				<Text style={[styles.text, { textTransform: "uppercase" }]}>
					{
						"inizio orario di lavoro ____ : ____            km partenza: ______________________"
					}
				</Text>
				<Text
					style={[styles.text, { marginTop: 10, textTransform: "uppercase" }]}
				>
					{
						"fine orario di lavoro   ____ : ____                 km arrivo: ______________________"
					}
				</Text>
			</View>
		</View>
		<Text break />
	</View>
);

export default FogliOrdiniCaricoPdf;
