import React, {useEffect} from "react";
import {Veterinario} from "./veterinarioSlice";
import {DateTime} from 'luxon';
import {Box} from "@mui/system";
import {Link} from "react-router-dom";
import {Button} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import PrintIcon from "@mui/icons-material/Print";
import Typography from "@mui/material/Typography";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Collapse from "@mui/material/Collapse";
import {useSelector} from "react-redux";
import {RootState} from "../../../../redux/store";
import {Parametri, TipoCapo} from "../../anagrafiche/anagraficheSlice";
import {toast} from "react-toastify";
import {CalcoloCaricoAmmissibile} from "../../../common/CalcoloCaricoAmmissibile";
import {convertToDateTime} from "components/common/dateUtils/convertToDateTime";


interface VeterinarioGiornoTableProps {
    lottiGiorno: Veterinario[];
    giornoSettimana: DateTime;
}

const giornataPubblicabile = (
    lottiGiorno: Veterinario[],
    giornoSettimana: DateTime
) => {
    if (lottiGiorno.length === 0) return false;
    const lottiGiornoNonPubblicabili = lottiGiorno
        .filter((lotto) => convertToDateTime(lotto.data_lavorazione)?.setLocale("it").toFormat("yyyy-MM-dd") == convertToDateTime(giornoSettimana)?.setLocale("it").toFormat("yyyy-MM-dd"))
        .filter((lotto) => lotto.resa_confermata === false)

    return (lottiGiornoNonPubblicabili.length === 0);
};

const calcoloTotalePeso = (
    lottiGiorno: Veterinario[],
    giornoSettimana: DateTime
) => {
    const totalePeso = lottiGiorno
        .filter(
            (lotto) =>
                convertToDateTime(lotto.data_lavorazione)?.setLocale("it").toFormat("yyyy-MM-dd") ==
                convertToDateTime(giornoSettimana)?.setLocale("it").toFormat("yyyy-MM-dd")
        )
        .reduce(function (previousValue, currentValue) {
            return (
                previousValue +
                (currentValue.peso_casse_busto_lotto || 0) +
                (currentValue.peso_casse_tz_lotto || 0)
            );
        }, 0);
    return totalePeso;
};

const calcoloCapiPrevisti = (
    lottiGiorno: Veterinario[],
    giornoSettimana: DateTime
) => {
    const totaleCapiPrevisti = lottiGiorno
        .filter(
            (lotto) =>
                convertToDateTime(lotto.data_lavorazione)?.setLocale("it").toFormat("yyyy-MM-dd") ==
                convertToDateTime(giornoSettimana)?.setLocale("it").toFormat("yyyy-MM-dd")
        ).reduce(function (previousValue, currentValue) {
            return (previousValue + (currentValue.numero_capi || 0));
        }, 0);
    console.log(giornoSettimana, totaleCapiPrevisti)
    return totaleCapiPrevisti;
};

const calcoloTotaleCapi = (
    lottiGiorno: Veterinario[],
    giornoSettimana: DateTime
) => {
    const totaleCapi = lottiGiorno
        .filter(
            (lotto) =>
                convertToDateTime(lotto.data_lavorazione)?.setLocale("it").toFormat("yyyy-MM-dd") ==
                convertToDateTime(giornoSettimana)?.setLocale("it").toFormat("yyyy-MM-dd")
        )
        .reduce(function (previousValue, currentValue) {
            return (
                previousValue +
                (currentValue.num_busti_lotto || 0) +
                (currentValue.num_tz_lotto || 0) +
                (currentValue.numero_scarti_2 || 0)
            );
        }, 0);
    return totaleCapi;
};

const calcoloOrarioFine = (
    lottiGiorno: Veterinario[],
    giornoSettimana: DateTime
) => {
    const orarioFine = lottiGiorno
        .filter(
            (lotto) =>
                convertToDateTime(lotto.data_lavorazione)?.setLocale("it").toFormat("yyyy-MM-dd") ==
                convertToDateTime(giornoSettimana)?.setLocale("it").toFormat("yyyy-MM-dd")
        )
        .reduce((previousValue: Date, currentValue: Veterinario) => {
            return previousValue > new Date(currentValue.data_ora_fine_macello)
                ? previousValue
                : new Date(currentValue.data_ora_fine_macello);
        }, new Date(0));
    return orarioFine;
};


const calcoloParametroAggiustamento = (totalePeso: number) => {
    let parametroAggiustamento: number = 1;
    if (totalePeso > 32000 && totalePeso < 42000) {
        parametroAggiustamento = 0.95;
    } else if (totalePeso >= 42000 && totalePeso < 48000) {
        parametroAggiustamento = 0.9;
    } else if (totalePeso >= 48000 && totalePeso < 54000) {
        parametroAggiustamento = 0.85;
    } else if (totalePeso >= 54000 && totalePeso < 60000) {
        parametroAggiustamento = 0.8;
    } else if (totalePeso >= 60000 && totalePeso < 67000) {
        parametroAggiustamento = 0.75;
    } else if (totalePeso >= 67000) {
        parametroAggiustamento = 0.7;
    }
    return parametroAggiustamento;
};


const VeterinarioGiornoTable = ({
                                    lottiGiorno,
                                    giornoSettimana,
                                }: VeterinarioGiornoTableProps) => {

    const tipiCapo = useSelector<RootState, TipoCapo[]>(
        (state) => state.anagrafiche.tipiCapo.results
    );


    const parametri = useSelector<RootState, Parametri | undefined>((state) =>
        state.anagrafiche.storicoParametri.results.find(
            (p) =>
                new Date(p.data_validta_da) <=
                new Date(new Date(giornoSettimana.toJSDate()).getTime() + 6 * 60 * 60 * 1000) &&
                new Date(new Date(giornoSettimana.toJSDate()).getTime() - 6 * 60 * 60 * 1000) <=
                new Date(p.data_validta_a)
        )
    );
    !parametri &&
    toast.error(
        `Non ci sono parametri validi per la giornata ${convertToDateTime(giornoSettimana)?.setLocale("it").toLocaleString(DateTime.DATE_FULL)
        }`,
        {autoClose: 7000}
    );

    const [giornoPubblicabile, setGiornoPubblicabile] = React.useState(false);
    const [totalePeso, setTotalePeso] = React.useState(0);
    const [totaleCapiSchedulati, setTotaleCapiSchedulati] = React.useState(0);
    const [totaleCapi, setTotaleCapi] = React.useState(0);
    const [orarioFine, setOrarioFine] = React.useState(new Date(0));
    const [tempoPrevisto, setTempoPrevisto] = React.useState("");

    const [parametroAggiustamento, setParametroAggiustamento] = React.useState(1);
    useEffect(() => {
        setGiornoPubblicabile(giornataPubblicabile(lottiGiorno, giornoSettimana));
        setTotalePeso(calcoloTotalePeso(lottiGiorno, giornoSettimana));
        setTotaleCapiSchedulati(calcoloCapiPrevisti(lottiGiorno, giornoSettimana));
        setTotaleCapi(calcoloTotaleCapi(lottiGiorno, giornoSettimana));
        setOrarioFine(calcoloOrarioFine(lottiGiorno, giornoSettimana));

        const {minutiPrevisti,} = CalcoloCaricoAmmissibile(
            lottiGiorno,
            tipiCapo,
            parametri,
            giornoSettimana
        );

        const m: number = minutiPrevisti % 60;
        setTempoPrevisto("tempo previsto: " + (minutiPrevisti - m) / 60 + "h " + Math.round(m) + "'")
    }, [lottiGiorno]);
    useEffect(() => {
        setParametroAggiustamento(calcoloParametroAggiustamento(totalePeso));
    }, [totalePeso]);


    const [open, setOpen] = React.useState(false);
    return (
        <React.Fragment>
            <TableRow
                sx={{
                    "& > *": {borderBottom: "unset"},
                    backgroundColor: giornoPubblicabile ? "#2ffd2f33" : "#999",
                }}
            >
                <TableCell>
                    {giornoPubblicabile && (
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                        </IconButton>
                    )}
                </TableCell>
                <TableCell>
                    {giornoPubblicabile && (
                        <Button
                            startIcon={<PrintIcon/>}
                            variant="outlined"
                            component={Link}
                            to={{
                                pathname: "/reportistica/veterinario/modulo_veterinario",
                                // state: {
                                //   schedaCarico,
                                // },
                            }}
                            state={{
                                lottiGiornoScelto: lottiGiorno,
                                parametroAggiustamentoCalc: parametroAggiustamento,
                                totalePesoCalc: totalePeso,
                                totaleCapiCalc: totaleCapi,
                                orarioFineCalc: orarioFine,
                            }}
                        >
                            Stampa Report
                        </Button>
                    )}
                </TableCell>
                <TableCell component="th" scope="row">
                    {convertToDateTime(giornoSettimana)?.setLocale("it").toFormat('ccc d LLLL yyyy')}
                </TableCell>
                <TableCell>
                    {giornoPubblicabile ? convertToDateTime(orarioFine)?.setLocale("it").toFormat('HH:mm') : `${tempoPrevisto}`}
                </TableCell>
                <TableCell align="right">
                    {giornoPubblicabile &&
                        Intl.NumberFormat("it-IT", {maximumFractionDigits: 0}).format(
                            totalePeso * parametroAggiustamento
                        ) + " kg"}
                </TableCell>
                <TableCell align="right">
                    {giornoPubblicabile ? Intl.NumberFormat("it-IT").format(totaleCapi) : `totale capi previsti: ${Intl.NumberFormat("it-IT").format(totaleCapiSchedulati)}`}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{margin: 1}}>
                            <Table size="small" aria-label="report veterinario">
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{fontWeight: "bold"}}>
                                            Cod. Rintrac.
                                        </TableCell>
                                        <TableCell sx={{fontWeight: "bold"}}>
                                            Allevatore
                                        </TableCell>
                                        <TableCell sx={{fontWeight: "bold"}}>Cod. ASL</TableCell>
                                        <TableCell align="right" sx={{fontWeight: "bold"}}>
                                            N. capi ritirati
                                        </TableCell>
                                        <TableCell sx={{fontWeight: "bold"}}>Tipo</TableCell>
                                        <TableCell align="right" sx={{fontWeight: "bold"}}>
                                            Peso partenza
                                        </TableCell>
                                        <TableCell align="right" sx={{fontWeight: "bold"}}>
                                            Peso arrivo
                                        </TableCell>
                                        <TableCell align="right" sx={{fontWeight: "bold"}}>
                                            Peso medio arrivo
                                        </TableCell>
                                        <TableCell align="center" sx={{fontWeight: "bold"}}>
                                            Inizio mac.
                                        </TableCell>
                                        <TableCell align="center" sx={{fontWeight: "bold"}}>
                                            Fine mac.
                                        </TableCell>
                                        <TableCell align="right" sx={{fontWeight: "bold"}}>
                                            M.TI
                                        </TableCell>
                                        <TableCell align="right" sx={{fontWeight: "bold"}}>
                                            SC1
                                        </TableCell>
                                        <TableCell align="right" sx={{fontWeight: "bold"}}>
                                            SC2
                                        </TableCell>

                                        <TableCell align="right" sx={{fontWeight: "bold"}}>
                                            peso BST
                                        </TableCell>
                                        <TableCell align="right" sx={{fontWeight: "bold"}}>
                                            N. BST
                                        </TableCell>

                                        <TableCell align="right" sx={{fontWeight: "bold"}}>
                                            peso TZ
                                        </TableCell>
                                        <TableCell align="right" sx={{fontWeight: "bold"}}>
                                            N. TZ
                                        </TableCell>
                                        <TableCell align="right" sx={{fontWeight: "bold"}}>
                                            Peso Macellato
                                        </TableCell>
                                        <TableCell align="right" sx={{fontWeight: "bold"}}>
                                            N. capi lavorati
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {lottiGiorno.map((lotto) => (
                                        <TableRow key={lotto.id}>
                                            <TableCell>{lotto.codice_tracciabilita}</TableCell>
                                            <TableCell>
                                                <Typography
                                                    style={{fontStyle: "italic", fontSize: "0.8rem"}}
                                                >
                                                    {lotto.allevamento_pack?.nome}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                {lotto.allevamento_pack?.codice_asl}
                                            </TableCell>
                                            <TableCell align="right">
                                                {Intl.NumberFormat("it-IT").format(
                                                    lotto.capi_ritirati || 0
                                                )}
                                            </TableCell>
                                            <TableCell>{lotto.tipo_capo_desc}</TableCell>
                                            <TableCell align="right" sx={{whiteSpace: "nowrap"}}>
                                                {Intl.NumberFormat("it-IT").format(
                                                    lotto.peso_lotto_partenza || 0
                                                ) + " kg"}
                                            </TableCell>
                                            <TableCell align="right" sx={{whiteSpace: "nowrap"}}>
                                                {Intl.NumberFormat("it-IT").format(
                                                    lotto.peso_lotto_arrivo || 0
                                                ) + " kg"}
                                            </TableCell>
                                            <TableCell align="right" sx={{whiteSpace: "nowrap"}}>
                                                {Intl.NumberFormat("it-IT", {
                                                    maximumFractionDigits: 2,
                                                }).format(
                                                    (lotto.peso_lotto_arrivo || 0) /
                                                    (lotto.capi_ritirati || 1)
                                                ) + " kg"}
                                            </TableCell>
                                            <TableCell align="center">
                                                {lotto.data_ora_inizio_macello &&
                                                    convertToDateTime(lotto.data_ora_inizio_macello)?.setLocale("it").toFormat('HH:mm')}
                                            </TableCell>
                                            <TableCell align="center">
                                                {lotto.data_ora_fine_macello &&
                                                    convertToDateTime(lotto.data_ora_fine_macello)?.setLocale("it").toFormat('HH:mm')}
                                            </TableCell>
                                            <TableCell align="right">
                                                {Intl.NumberFormat("it-IT").format(
                                                    lotto.numero_capi_morti || 0
                                                )}
                                            </TableCell>
                                            <TableCell align="right">
                                                {Intl.NumberFormat("it-IT").format(
                                                    lotto.numero_scarti_1 || 0
                                                )}
                                            </TableCell>
                                            <TableCell align="right">
                                                {Intl.NumberFormat("it-IT").format(
                                                    lotto.numero_scarti_2 || 0
                                                )}
                                            </TableCell>
                                            <TableCell align="right" sx={{whiteSpace: "nowrap"}}>
                                                {Intl.NumberFormat("it-IT", {
                                                    maximumFractionDigits: 0,
                                                }).format(
                                                    (lotto.peso_casse_busto_lotto || 0) *
                                                    parametroAggiustamento
                                                ) + " kg"}
                                            </TableCell>

                                            <TableCell align="right">
                                                {Intl.NumberFormat("it-IT").format(
                                                    lotto.num_busti_lotto || 0
                                                )}
                                            </TableCell>

                                            <TableCell align="right" sx={{whiteSpace: "nowrap"}}>
                                                {Intl.NumberFormat("it-IT", {
                                                    maximumFractionDigits: 0,
                                                }).format(
                                                    (lotto.peso_casse_tz_lotto || 0) *
                                                    parametroAggiustamento
                                                ) + " kg"}
                                            </TableCell>
                                            <TableCell align="right">
                                                {Intl.NumberFormat("it-IT").format(
                                                    lotto.num_tz_lotto || 0
                                                )}
                                            </TableCell>
                                            <TableCell align="right">
                                                {Intl.NumberFormat("it-IT", {
                                                    maximumFractionDigits: 0,
                                                }).format(
                                                    ((lotto.peso_casse_busto_lotto || 0) +
                                                        (lotto.peso_casse_tz_lotto || 0)) *
                                                    parametroAggiustamento
                                                ) + " kg"}
                                            </TableCell>
                                            <TableCell align="right">
                                                {Intl.NumberFormat("it-IT").format(
                                                    (lotto.num_busti_lotto || 0) +
                                                    (lotto.num_tz_lotto || 0) +
                                                    (lotto.numero_scarti_2 || 0)
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
};

export default VeterinarioGiornoTable;
