import React from "react";
import { GraficoCommittenteTipiCapoPesoMedioSettimana } from "../../reportistica/grafici/graficiKpiSlice";
import { DateTime } from 'luxon';

import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer,
} from "recharts";

import { stringToColour } from "../../../common/stringToColour";
const generaData = (
	graficoCommittenteTipiCapoPesoMedioSettimana: GraficoCommittenteTipiCapoPesoMedioSettimana[]
) => {
	const data: {}[] = [];

	const settimane: string[] = [];
	graficoCommittenteTipiCapoPesoMedioSettimana.map((settimana_tipo_kg) => {
		if (!settimane.includes(`W${settimana_tipo_kg.settimana}`)) {
			settimane.push(`W${settimana_tipo_kg.settimana}`);
		}
	});

	const tipiCapoBar: string[] = [];

	settimane.map((settimana) => {
		var dataRecord: any = {};
		dataRecord["settimana"] = settimana;
		graficoCommittenteTipiCapoPesoMedioSettimana
			.filter(
				(settimana_tipo_kg) => `W${settimana_tipo_kg.settimana}` == settimana
			)
			.map((tipo_capo) => {
				dataRecord[tipo_capo.tipo_capo] = tipo_capo.peso_medio_resa;
				if (tipo_capo.peso_medio_resa > 0) {
					if (!tipiCapoBar.includes(tipo_capo.tipo_capo)) {
						tipiCapoBar.push(tipo_capo.tipo_capo);
					}
				}
			});
		data.push(dataRecord);
	});

	return { data: data, tipiCapoBar: tipiCapoBar };
};

interface CommittenteTipiCapoSettimanePesoMedioGraficoProps {
	graficoCommittenteTipiCapoPesoMedioSettimana: GraficoCommittenteTipiCapoPesoMedioSettimana[];
}
const CommittenteTipiCapoSettimanePesoMedioGrafico = ({
	graficoCommittenteTipiCapoPesoMedioSettimana,
}: CommittenteTipiCapoSettimanePesoMedioGraficoProps) => {
	return (
		<ResponsiveContainer>
			<BarChart
				// width={400}
				// height={200}
				data={generaData(graficoCommittenteTipiCapoPesoMedioSettimana).data}
				margin={{
					top: 8,
					right: 5,
					left: 5,
					bottom: 5,
				}}
			>
				<CartesianGrid strokeDasharray="3 3" />
				<XAxis dataKey="settimana" fontSize={12} />
				<YAxis
					type="number"
					tickFormatter={(value) => Intl.NumberFormat("it-IT").format(value)}
					fontSize={13}
				/>
				<Tooltip
					formatter={(value) => {
						if (typeof value == "number") {
							return (
								Intl.NumberFormat("it-IT").format(value).toString() + " Kg"
							);
						} else {
							return value;
						}
					}}
					wrapperStyle={{ fontSize: 13 }}
				/>
				<Legend wrapperStyle={{ fontSize: 13 }} />
				{generaData(
					graficoCommittenteTipiCapoPesoMedioSettimana
				).tipiCapoBar.map((tipo) => {
					return (
						<Bar
							key={"key_" + tipo}
							dataKey={tipo}
							fill={stringToColour(tipo)}
						/>
					);
				})}
			</BarChart>
		</ResponsiveContainer>
	);
};
export default CommittenteTipiCapoSettimanePesoMedioGrafico;
