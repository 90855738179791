import { TipoCapo, Parametri } from "../main/anagrafiche/anagraficheSlice";
import {
	consolidaOrdinamento,
	Lotto,
	LottoConAttrezzaggio,
} from "../main/pianificazione/pianificazioneSlice";

import { Veterinario } from "../main/reportistica/veterinario/veterinarioSlice";

import { Camion } from "../main/anagrafiche/anagraficheSlice";
import {
	foriImpegnati,
	lottiConAttrezzaggio,
} from "../main/pianificazione/utility/lottoUtility";
import moment from "moment";
import { fetchParametri } from "api/anagrafiche/parametriApi";

const MinutiPrevisti = (
	lotti: Lotto[] | Veterinario[],
	tipiCapo: TipoCapo[],
	parametri?: Parametri
): number => {
	var minutiPrevistiSenzaPause = lotti.reduce(
		(valoreCalcolato: any, lottoCorrente: Lotto | Veterinario, currentIndex) => {
			const capiAlMinuto: number =
				tipiCapo.find(
					(tipoCapo: TipoCapo) => tipoCapo.id == lottoCorrente.tipo_capo
				)?.velocita_processamento || 9999999999999;
			// const minuti_pausa: number =
			// 	currentIndex == 0 ? 0 : parametri.minuti_pausa;
			return (
				valoreCalcolato +
				(lottoCorrente.numero_capi == undefined
					? 0
					: lottoCorrente.numero_capi / capiAlMinuto) //+ minuti_pausa
			);
		},
		0
	);
	var totalePauseAttrezzaggio = lottiConAttrezzaggio(
		lotti,
		tipiCapo,
		parametri
	).reduce((valoreCalcolato: any, lottoCorrente: Lotto|Veterinario) => {
		return (
			valoreCalcolato +
			(lottoCorrente.tempo_attrezzaggio == undefined
				? 0
				: lottoCorrente.tempo_attrezzaggio)
		);
	}, 0);

	var minutiPrevisti =
		minutiPrevistiSenzaPause +
		totalePauseAttrezzaggio +
		(parametri?.minuti_pausa || 4);
	return minutiPrevisti;
};

const CapiPrevisti = (lotti: (Lotto | Veterinario)[]
) => {
	const capiPrevisti: number = lotti.reduce(
		(valoreCalcolato: any, lottoCorrente: Lotto|Veterinario, currentIndex) => {
			return (
				valoreCalcolato +
				(lottoCorrente.numero_capi == undefined ? 0 : lottoCorrente.numero_capi)
			);
		},
		0
	);
	return capiPrevisti;
};

export const DataOraInizioLotti = (lotti: (Lotto | Veterinario)[]
): Date =>
	lotti.reduce((dataOraInizio: Date, lottoCorrente: Lotto|Veterinario, currentIndex) => {
		return new Date(
			lottoCorrente.scheda_macello?.data_ora_inizio_macello || ""
		) < dataOraInizio
			? new Date(lottoCorrente.scheda_macello?.data_ora_inizio_macello || "")
			: dataOraInizio;
	}, new Date());

export const DataOraFineLotti = (lotti: (Lotto | Veterinario)[]
): Date =>
	lotti.reduce((dataOraFine: Date, lottoCorrente: Lotto|Veterinario, currentIndex) => {
		return new Date(lottoCorrente.scheda_macello?.data_ora_fine_macello || "") >
			dataOraFine
			? new Date(lottoCorrente.scheda_macello?.data_ora_fine_macello || "")
			: dataOraFine;
	}, new Date(0));

const MinutiEffettivi = (lotti: (Lotto | Veterinario)[]
): number => {
	const giornataNonConfermata: boolean =
		lotti.length == 0 ||
		lotti.filter((lotto) => lotto.resa_confermata == false).length > 0;
	if (giornataNonConfermata) {
		return 0;
	}
	const dataOraInizio = DataOraInizioLotti(lotti);
	const dataOraFine = DataOraFineLotti(lotti);
	const minutiEffettivi = Math.floor(
		Math.abs(dataOraFine.getTime() - dataOraInizio.getTime()) / 1000 / 60
	);
	return minutiEffettivi;
};

const CapiEffettivi = (lotti: (Lotto | Veterinario)[]
) => {
	const capiEffettivi: number = lotti.reduce(
		(valoreCalcolato: any, lottoCorrente: Lotto|Veterinario, currentIndex) => {
			return (
				valoreCalcolato +
				(lottoCorrente.scheda_macello?.numero_capi_lavorati == undefined
					? 0
					: lottoCorrente.scheda_macello.numero_capi_lavorati)
			);
		},
		0
	);
	return capiEffettivi;
};

const EccedeTempo = (
	minuti: number,
	parametri?: Parametri,
	giornoSettimana?: Date
): boolean => {
	const numeroGiornoSettimana: number = moment(giornoSettimana).isoWeekday();
	if (numeroGiornoSettimana == 6) {
		return minuti > (parametri?.minuti_max_sabato || 300);
	} else {
		return minuti > (parametri?.minuti_max_giorno || 420);
	}
};

export const CalcolaPausa = (inizioPausa: Date, finePausa: Date): number => {
	const minutiEffettivi = Math.floor(
		Math.abs(new Date(finePausa).getTime() - new Date(inizioPausa).getTime()) /
			1000 /
			60
	);
	return minutiEffettivi;
};

export const CalcoloCaricoAmmissibile = (
	lotti: Lotto[] | Veterinario[],
	tipiCapo: TipoCapo[],
	parametri?: Parametri,
	giornoSettimana?: Date
): {
	minutiPrevisti: number;
	capiPrevisti: number;
	minutiEffettivi: number;
	capiEffettivi: number;
	eccedeTempo: boolean;
} => {
	const minutiPrevisti = MinutiPrevisti(lotti, tipiCapo, parametri);
	const capiPrevisti = CapiPrevisti(lotti);
	const minutiEffettivi = MinutiEffettivi(lotti);
	const capiEffettivi = CapiEffettivi(lotti);
	const eccedeTempo = EccedeTempo(minutiPrevisti, parametri, giornoSettimana);
	return {
		minutiPrevisti,
		capiPrevisti,
		minutiEffettivi,
		capiEffettivi,
		eccedeTempo,
	};
};

// -----------------------------------------------------

const ForiUtilizzati = (
	lotti: Lotto[],
	tipiCapo: TipoCapo[],
	parametri?: Parametri
): number => {
	var foriUtilizzati = lotti.reduce(
		(valoreCalcolato: any, lottoCorrente: Lotto, currentIndex) => {
			return (
				valoreCalcolato +
				(lottoCorrente.numero_fori == undefined ? 0 : lottoCorrente.numero_fori)
			);
		},
		0
	);
	return foriUtilizzati;
};

// funzione che lavora sia per il lotto (in cui ci possono essere più camion) che per la scheda di carico
export const ForiImpegnati = (
	numero_fori?: number | null,
	camion?: Camion,
	rimorchio: boolean = true
): number => {
	const fori_rimorchio: number = 80;
	if (camion && camion.numero_fori > 80) {
		return camion.numero_fori;
	}
	if (camion && rimorchio) {
		return camion.numero_fori + fori_rimorchio;
	}
	if (camion) {
		return camion.numero_fori || 80;
	}

	//  se il camion non c'è ancora
	if (numero_fori && numero_fori > 80 && numero_fori <= 96) {
		return 96;
	}
	if (numero_fori && rimorchio) {
		return Math.ceil(numero_fori / 80) * 80;
	}
	return 80;
};

const TotaleForiImpegnati = (
	lotti: Lotto[],
	tipiCapo: TipoCapo[],
	parametri?: Parametri
): number => {
	var montanteForiImpegnati = lotti.reduce(
		(valoreCalcolato: any, lottoCorrente: Lotto, currentIndex) => {
			return valoreCalcolato + foriImpegnati(lottoCorrente);
		},
		0
	);
	return montanteForiImpegnati;
};

const EccedeForiDisponibili = (
	foriImpegnati: number,
	parametri?: Parametri
): boolean => {
	return foriImpegnati > (parametri?.alert_fori_giornata || 960);
	// return foriImpegnati > 960;
};

export const CalcoloForiPrevisti = (
	lotti: Lotto[],
	tipiCapo: TipoCapo[],
	parametri?: Parametri
): {
	foriUtilizzati: number;
	foriImpegnati: number;
	massimoForiGiornata: number;
	eccedeFori: boolean;
} => {
	const foriUtilizzati = ForiUtilizzati(lotti, tipiCapo, parametri);
	const foriImpegnati = TotaleForiImpegnati(lotti, tipiCapo, parametri);
	const massimoForiGiornata = parametri?.massimo_fori_giornata || 960;
	const eccedeFori = EccedeForiDisponibili(foriImpegnati, parametri);
	return { foriUtilizzati, foriImpegnati, massimoForiGiornata, eccedeFori };
};

// -----------------------------------------------------

export const TotaleKg = (lotti: Lotto[], tipiCapo: TipoCapo[]): number => {
	var totaleKg = lotti.reduce(
		(valoreCalcolato: any, lottoCorrente: Lotto, currentIndex) => {
			const pesoMedio: number =
				lottoCorrente.peso_medio != null
					? lottoCorrente.peso_medio
					: tipiCapo.find(
							(tipoCapo: TipoCapo) => tipoCapo.id == lottoCorrente.tipo_capo
					  )?.peso_medio || 9999999999999;
			return (
				valoreCalcolato +
				(lottoCorrente.numero_capi == undefined
					? 0
					: lottoCorrente.numero_capi * pesoMedio)
			);
		},
		0
	);
	return totaleKg;
};
