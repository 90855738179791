import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
	ErrorStatusTypes,
	ErrorsStack,
	parseErrorMessage,
} from "../../common/errorsDeclarations";

import {
	Allevamento,
	getAllevamento,
	getCommittente,
	Produttore,
} from "../anagrafiche/anagraficheSlice";
import {
	SchedaCarico,
	getSchedaCarico,
} from "../schedeCarico/schedeCaricoSlice";
import { Lavorazione } from "../lavorazioni/lavorazioniSlice";
import { ConsuntivoVivo } from "../schedeCarico/consuntiviVivo/consuntiviVivoSlice";
import { SchedaMacello } from "../schedeMacello/schedeMacelloSlice";

import * as lottiApi from "../../../api/pianificazione/lottiApi";
import moment from "moment";
import { toast } from "react-toastify";
// import { useDispatch } from "react-redux";

moment.locale("it");
moment.locale("it");

// export enum ErrorStatusTypes {
//   OK = "ok",
//   ERROR = "error",
//   PENDING = "pending",
//   SUCCESS = "success",
// }
// export interface ErrorsStack {
//   status: ErrorStatusTypes;
//   data?: [] | {};
//   messages?: [];
//   errors?: [];
//   fieldsErrors?: { [key: string]: string[] };
// }

export enum ColoreTypes {
	B = "Bianchi",
	G = "Gialli",
	R = "Rossi",
}
export interface StatoLotto {
	stato: number;
	descrizione: string;
	schede_carico_mancanti: number;
	messaggio: string;
}
export interface Lotto {
	id: number | null;
	stato_lotto: StatoLotto;
	data_lavorazione: Date | string;
	viaggio_lungo: boolean;
	ordinamento_lotto: number;
	committente: number | null;
	committente_desc: string;
	produttore: number | null;
	produttore_pack: Produttore | null;
	m4_inviato: boolean;
	m4_inviati: number[];
	in_dubbio: boolean;
	note_dubbio: string;
	codice_tracciabilita?: string;
	ordine_cliente?: string;
	data_ordine_cliente?: Date | string;
	calcolo_codice_tracciabilita_automatico?: boolean;
	tipo_capo: number | null;
	tipo_capo_desc?: string;
	colore?: ColoreTypes | null;
	peso_medio: number | null;
	numero_capi?: number | null;
	capi_schedulati?: number | null;
	capi_ritirati?: number | null;
	numero_capi_foro?: number | null;
	numero_fori?: number | null;
	fori_schedulati?: number | null;
	peso_lotto_partenza?: number | null;
	peso_lotto_arrivo?: number | null;
	peso_medio_lotto_arrivo?: number | null;
	allevamento: number | null;
	allevamento_pack?: Allevamento;
	resa_confermata: boolean;
	note: string;
	schede_carico: SchedaCarico[];
	consuntivi_vivo: ConsuntivoVivo[];
	lavorazioni_lotto: Lavorazione[];
	documenti_trasporto?: number[];
	aggiustamento_resa_bst?: boolean;
	num_tz_pianificati_lotto?: number;
	num_tz_eviscerati_pianificati_lotto?: number;
	num_busti_pianificati_lotto?: number;
	scheda_macello: SchedaMacello | null;
	tempo_attrezzaggio?: number;
	errorsStack: ErrorsStack;
}
export interface LottiState {
	count: number;
	page: number;
	num_pages: number;
	next?: URL;
	previous?: URL;
	results: Lotto[];
	ultimo_aggiornamento: string;
	errorsStack: ErrorsStack;
}

export interface LottoCorrente extends Lotto {
	// errorsStack: ErrorsStack;
}
export interface LottoConAttrezzaggio extends Lotto {
	tempo_attrezzaggio?: number;
}

export interface PianificazioneStrutturaState {
	lotti: LottiState;
	//   lottoCorrente: LottoCorrente;
	lottoCorrente_id: number | null;
}

const initialState: PianificazioneStrutturaState = {
	lotti: {
		count: 0,
		page: 0,
		num_pages: 0,
		next: undefined,
		previous: undefined,
		results: [],
		ultimo_aggiornamento: "",
		errorsStack: { status: ErrorStatusTypes.OK, saving: false },
	},
	lottoCorrente_id: null,
};

export const fetchLotti = createAsyncThunk(
	"lotti/fetchLotti",
	async (dataRange?: {
		data_da?: Date;
		data_a?: Date;
		lotti_semplificati?: boolean;
		ultimoAggiornamento?: string;
	}) => {
		return await lottiApi.fetchLotti(
			dataRange?.data_da,
			dataRange?.data_a,
			dataRange?.lotti_semplificati,
			dataRange?.ultimoAggiornamento
		);
	}
);

export const getLotto = createAsyncThunk(
	"lotti/getLotto",
	async (lottoId: number) => {
		return await lottiApi.getLotto(lottoId);
	}
);

export const saveLotto = createAsyncThunk(
	"lotti/saveLotto",
	async (lottoToSave: Lotto, thunkApi) => {
		return lottiApi.saveLotto(lottoToSave).then((response) => {
			// const lunedi: Date = moment(lottoToSave.data_lavorazione)
			//   .startOf("isoWeek")
			//   .toDate();
			// const domenica: Date = moment(lottoToSave.data_lavorazione)
			//   .endOf("isoWeek")
			//   .toDate();
			lottoToSave.allevamento &&
				thunkApi.dispatch(getAllevamento(lottoToSave.allevamento));
			// lottoToSave.committente &&
			// 	thunkApi.dispatch(getCommittente(lottoToSave.committente));
			// thunkApi.dispatch(fetchLotti({ data_da: lunedi, data_a: domenica }));
			lottoToSave.schede_carico.map((schedaCarico) =>
				thunkApi.dispatch(getSchedaCarico(schedaCarico.id || 0))
			);
			return response;
		});
	}
);

export const deleteLotto = createAsyncThunk(
	"lotti/deleteLotto",
	async (lottoToDelete: Lotto, thunkApi) => {
		return await lottiApi.deleteLotto(lottoToDelete).then((response) => {
			const lunedi: Date = moment(lottoToDelete.data_lavorazione)
				.startOf("isoWeek")
				.toDate();
			const domenica: Date = moment(lottoToDelete.data_lavorazione)
				.endOf("isoWeek")
				.toDate();
			thunkApi.dispatch(fetchLotti({ data_da: lunedi, data_a: domenica }));
			return response;
		});
	}
);

export const creaLottiStandard = createAsyncThunk(
	"generapianificazione",
	async (data: Date, thunkApi) => {
		return await lottiApi.creaLottiStandard(data).then((response) => {
			const lunedi: Date = moment(data).startOf("isoWeek").toDate();
			const domenica: Date = moment(data).endOf("isoWeek").toDate();
			thunkApi.dispatch(fetchLotti({ data_da: lunedi, data_a: domenica }));
			return response;
		});
	}
);

export const cambiaGiornoLotto = createAsyncThunk(
	"cambiaGiornoLotto",
	async (payload: { dragId: number; hoverValue: any }, thunkApi) => {
		const state = thunkApi.getState() as {
			pianificazione: PianificazioneStrutturaState;
		};
		const maxIndiceGiorno = Math.max(
			...state.pianificazione.lotti.results
				.filter(
					(lotto) =>
						lotto.data_lavorazione ==
						moment(new Date(payload.hoverValue)).format("YYYY-MM-DD")
				)
				.map((lotto) => {
					return lotto.ordinamento_lotto;
				})
		);
		const lottoInModifica: Lotto | undefined =
			state.pianificazione.lotti.results.find(
				(lotto) => lotto.id == payload.dragId
			);

		if (lottoInModifica && lottoInModifica.stato_lotto.stato >= 2) {
			toast.warning(
				"Attenzione!! Un Lotto già schedulato: rivedere i giri di carico."
			);
		}

		if (lottoInModifica != undefined) {
			const lottoToSave = {
				...lottoInModifica,
				data_lavorazione: moment(new Date(payload.hoverValue)).format(
					"YYYY-MM-DD"
				),
				ordinamento_lotto: maxIndiceGiorno + 1,
			};

			thunkApi.dispatch(saveLotto(lottoToSave));
			return lottoToSave;
		}
	}
);

export const consolidaOrdinamento = createAsyncThunk(
	"consolidaOrdinamento",
	async (payload: { dragId: number }, thunkApi) => {
		const state = thunkApi.getState() as {
			pianificazione: PianificazioneStrutturaState;
		};

		const lottoInModifica: Lotto | undefined =
			state.pianificazione.lotti.results.find(
				(lotto) => lotto.id == payload.dragId
			);

		if (lottoInModifica != undefined) {
			const nuovoOrdinamento: { id: number | null; ordinamento: number }[] =
				state.pianificazione.lotti.results
					.filter(
						(lotto) =>
							lotto.data_lavorazione == lottoInModifica.data_lavorazione
					)
					.map((lotto) => {
						return {
							id: lotto.id,
							ordinamento: lotto.ordinamento_lotto,
						};
					});

			return await lottiApi
				.consolidaOrdinamentoLotti(nuovoOrdinamento)
				.then((response) => {
					const lunedi: Date = moment(lottoInModifica.data_lavorazione)
						.startOf("isoWeek")
						.toDate();
					const domenica: Date = moment(lottoInModifica.data_lavorazione)
						.endOf("isoWeek")
						.toDate();
					thunkApi.dispatch(fetchLotti({ data_da: lunedi, data_a: domenica }));
					return response;
				});
		}
	}
);

export const pianificazioneSlice = createSlice({
	name: "pianificazione",
	initialState,
	reducers: {
		setLottoCorrente: (
			state,
			action: PayloadAction<{
				lottoCorrente_id: number | null;
			}>
		) => {
			state.lottoCorrente_id = action.payload.lottoCorrente_id;
		},

		resetLotti: (state) => {
			// state.lottoCorrente = initialState.lottoCorrente;
			state.lottoCorrente_id = null;
			state.lotti = {
				count: 0,
				page: 0,
				num_pages: 0,
				next: undefined,
				previous: undefined,
				results: [],
				ultimo_aggiornamento: "",
				errorsStack: { status: ErrorStatusTypes.OK, saving: false },
			};
		},
		resetLottoCorrente: (state) => {
			// state.lottoCorrente = initialState.lottoCorrente;
			state.lottoCorrente_id = null;

			state.lotti.errorsStack = {
				...state.lotti.errorsStack,
				fieldsErrors: undefined,
			};
		},
		moveLotto: (
			state,
			action: PayloadAction<{
				dragId: number;
				hoverId: number;
			}>
		) => {
			let lottiOrdinati = state.lotti.results.sort((a, b) => {
				return (
					new Date(a.data_lavorazione).getTime() -
						new Date(b.data_lavorazione).getTime() ||
					a.ordinamento_lotto - b.ordinamento_lotto
				);
			});

			const dragId = action.payload.dragId;
			const dragLotto = lottiOrdinati.find((lotto) => lotto.id == dragId);
			const dragIdx = lottiOrdinati.findIndex((lotto) => lotto.id == dragId);
			const hoverId = action.payload.hoverId;
			const hoverLotto = lottiOrdinati.find((lotto) => lotto.id == hoverId);
			const hoverIdx = lottiOrdinati.findIndex((lotto) => lotto.id == hoverId);
			if (dragLotto != undefined && hoverLotto != undefined) {
				lottiOrdinati.splice(dragIdx, 1);
				lottiOrdinati.splice(hoverIdx, 0, dragLotto);

				let ordinamento_lotto = 1;
				let cursoreData = lottiOrdinati[0]?.data_lavorazione;
				state.lotti.results = lottiOrdinati.map((lotto) => {
					if (lotto.id == dragLotto.id) {
						lotto = {
							...lotto,
							data_lavorazione: hoverLotto.data_lavorazione,
						};
					}

					if (cursoreData.valueOf() != lotto.data_lavorazione.valueOf()) {
						cursoreData = lotto.data_lavorazione;
						ordinamento_lotto = 1;
					}
					lotto = { ...lotto, ordinamento_lotto: ordinamento_lotto };

					ordinamento_lotto += 1;
					return lotto;
				});
			}
		},
	},
	extraReducers: (builder) => {
		// fetch lotti
		builder.addCase(fetchLotti.pending, (state, action) => {
			// se è un aggiornamento con data di filtro lo faccio silenziosamente
			if (action.meta.arg?.ultimoAggiornamento == undefined) {
				state.lotti.errorsStack.status = ErrorStatusTypes.PENDING;
			}
		});
		builder.addCase(fetchLotti.fulfilled, (state, action) => {
			state.lotti.ultimo_aggiornamento = action.payload.ultimo_aggiornamento;
			state.lottoCorrente_id;
			state.lotti.results = [
				...action.payload.results.filter(
					(lotto: Lotto) => lotto.id != state.lottoCorrente_id
				),
				// qui sotto vengono aggiunti i lotti nello store che non arrivano dalla fetch
				// per compensare lo scaricamento giorno per giorno
				...state.lotti.results.filter((lotto) => {
					if (
						action.payload.results.find(
							(lottoNew: Lotto) =>
								lottoNew.id == lotto.id && lottoNew.id != state.lottoCorrente_id
						)
					) {
						return false;
					} else {
						return true;
					}
				}),
			];

			state.lotti.errorsStack = {
				...state.lotti.errorsStack,
				status: ErrorStatusTypes.OK,
			};
		});
		builder.addCase(fetchLotti.rejected, (state, action) => {
			state.lotti.errorsStack = parseErrorMessage(action.error);
		});

		// get lotto
		builder.addCase(getLotto.pending, (state, action) => {
			state.lotti.errorsStack.status = ErrorStatusTypes.PENDING;
		});
		builder.addCase(getLotto.fulfilled, (state, action) => {
			// state.lottoCorrente = action.payload;

			state.lotti.errorsStack.status = ErrorStatusTypes.OK;

            //
			// state.lotti.results = state.lotti.results.map((lotto) => {
			// 	if (lotto.id == action.payload.id) {
			// 		// return action.payload;
            //
			// 		return {
			// 			...action.payload,
			// 			data_lavorazione: moment(action.payload.data_lavorazione).format(
			// 				"YYYY-MM-DD"
			// 			),
			// 			errorsStack: {
			// 				status: ErrorStatusTypes.OK,
			// 				fieldsErrors: undefined,
			// 			},
			// 		};
			// 	} else {
			// 		return lotto;
			// 	}
			// });


			state.lotti.results = state.lotti.results.filter(
				(lotto) => lotto.id != action.payload.id
			);
			state.lotti.results.push(
                {
                    ...action.payload,
                    data_lavorazione: moment(action.payload.data_lavorazione).format(
                        "YYYY-MM-DD"
                    ),
                    errorsStack: {
                        status: ErrorStatusTypes.OK,
                        fieldsErrors: undefined,
                    }
                }
			);
		// 	riordino i lotti per ordinamento
			state.lotti.results = state.lotti.results.sort((a, b) => {
				return a.ordinamento_lotto - b.ordinamento_lotto;
			});


		});
		builder.addCase(getLotto.rejected, (state, action) => {
			state.lotti.errorsStack = parseErrorMessage(action.error);
		});

		// save lotto
		builder.addCase(saveLotto.pending, (state, action) => {
			state.lotti.errorsStack = {
				status: ErrorStatusTypes.PENDING,
				saving: true,
			};
		});

		builder.addCase(saveLotto.fulfilled, (state, action) => {
			if (action.meta.arg.id) {
				state.lotti.results = state.lotti.results.map((lotto) => {
					if (lotto.id == action.payload.id) {
						return action.payload;
					} else {
						return lotto;
					}
				});
			} else {
				state.lotti.results = [...state.lotti.results, action.payload];
			}

			state.lottoCorrente_id = action.payload.id;

			// state.lottoCorrente = action.payload;

			toast.success("Lotto salvato.");

			state.lotti.errorsStack = {
				status: ErrorStatusTypes.SUCCESS,
				saving: false,
			};
		});
		builder.addCase(saveLotto.rejected, (state, action) => {
			// toast.error("Errore:" + action?.error?.message || "");

			state.lotti.results = state.lotti.results.map((lotto) => {
				if (lotto.id == action.meta.arg.id) {
					return {
						...lotto,
						errorsStack: parseErrorMessage(action.error),
					};
				} else {
					return lotto;
				}
			});

			state.lotti.errorsStack = parseErrorMessage(action.error);

			// state.lottoCorrente.errorsStack = {
			//   status: ErrorStatusTypes.ERROR,
			//   fieldsErrors: JSON.parse(action?.error?.message || ""),
			// };
			// if (state.lottoCorrente.allevamento_pack) {
			//   state.lottoCorrente.allevamento_pack.errorsStack = {
			//     status: ErrorStatusTypes.ERROR,
			//     fieldsErrors: JSON.parse(action?.error?.message || ""),
			//   };
			// }
		});

		// delete lottoss
		builder.addCase(deleteLotto.pending, (state, action) => {
			state.lotti.errorsStack.status = ErrorStatusTypes.PENDING;
		});

		builder.addCase(deleteLotto.fulfilled, (state, action) => {
			state.lotti.results = state.lotti.results.filter(
				(lotto) => lotto.id != action.payload.lotto_id
			);
			toast.success("Lotto cancellato.");

			state.lotti.errorsStack.status = ErrorStatusTypes.OK;
		});

		builder.addCase(deleteLotto.rejected, (state, action) => {
			// toast.error("Errore:" + action?.error?.message || "");

			state.lotti.errorsStack = parseErrorMessage(action.error);
		});

		// cambia giorno lotto
		builder.addCase(cambiaGiornoLotto.pending, (state, action) => {
			state.lotti.errorsStack.status = ErrorStatusTypes.PENDING;
		});

		builder.addCase(cambiaGiornoLotto.fulfilled, (state, action) => {
			state.lotti.errorsStack.status = ErrorStatusTypes.OK;
			state.lotti.results = state.lotti.results.map((lotto) => {
				if (action.payload != undefined && lotto.id == action.payload.id) {
					return { ...lotto, ...action.payload };
				} else {
					return lotto;
				}
			});
		});
		builder.addCase(cambiaGiornoLotto.rejected, (state, action) => {
			// toast.error("Errore:" + action?.error?.message || "");

			state.lotti.errorsStack = parseErrorMessage(action.error);
		});
	},
});

// Action creators are generated for each case reducer function
export const {
	// setNuovoLotto,
	resetLottoCorrente,
	resetLotti,
	setLottoCorrente,
	moveLotto,
	//   cambiaGiornoLotto,
} = pianificazioneSlice.actions;

export const pianificazioneReducer = pianificazioneSlice.reducer;
