import React, {useEffect} from "react";
import {SchedaCarico} from "../schedeCaricoSlice";
import {ConsuntivoVivo} from "./consuntiviVivoSlice";
import {Parametri} from "../../anagrafiche/anagraficheSlice";
import {
    useForm,
    SubmitHandler,
    Controller,
} from "react-hook-form";
import {NumericFormat} from "react-number-format";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import {DateTimePicker} from "@mui/x-date-pickers/DateTimePicker";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Button} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {convertToDateTime} from "../../../common/dateUtils/convertToDateTime";
import {DateTime} from "luxon";


interface ConsuntivoVivoFormProps {
    consuntivoVivo: ConsuntivoVivo;
    saveConsuntivoVivo: (consuntivoVivo: ConsuntivoVivo) => void;
    parametri: Parametri;
}

const ConsuntivoVivoForm = ({
                                consuntivoVivo,
                                saveConsuntivoVivo,
                                parametri,
                            }: ConsuntivoVivoFormProps) => {
    const {
        handleSubmit,
        register,
        formState: {errors, isDirty},
        setValue,
        watch,
        getValues,
        control,
        setError,
        clearErrors,
    } = useForm<ConsuntivoVivo>();

    const listaCampi = Object.keys(consuntivoVivo) as Array<keyof ConsuntivoVivo>;

    useEffect(() => {
        listaCampi.map((field) => {
            setValue(field, consuntivoVivo[field]);
        });
    }, [
        listaCampi.forEach((field) => {
            consuntivoVivo[field];
        }),
    ]);

    useEffect(() => {
        listaCampi.forEach((field) => {
            consuntivoVivo?.errorsStack?.fieldsErrors &&
            consuntivoVivo.errorsStack.fieldsErrors[field] &&
            setError(field, {
                type: "server",
                message: consuntivoVivo.errorsStack.fieldsErrors[field].toString(),
            });
        });
    }, [consuntivoVivo]);

    const onSubmit: SubmitHandler<SchedaCarico> = (consuntivoVivo) => {
        saveConsuntivoVivo(consuntivoVivo);
    };

    // #############################################
    // Controllo e gestioni errori
    // #############################################

    React.useEffect(() => {
        clearErrors("data_ora_partenza_sede");
        clearErrors("data_ora_fine_turno");
        clearErrors("data_ora_inizio_carico");
        clearErrors("data_ora_fine_carico");
        const data_ora_partenza_sede: string | Date | undefined = getValues(
            "data_ora_partenza_sede"
        );
        const data_ora_fine_turno: string | Date | undefined = getValues(
            "data_ora_fine_turno"
        );
        const data_ora_inizio_carico: string | Date | undefined = getValues(
            "data_ora_inizio_carico"
        );
        const data_ora_fine_carico: string | Date | undefined = getValues(
            "data_ora_fine_carico"
        );

        if (data_ora_partenza_sede && data_ora_fine_turno) {
            if (new Date(data_ora_partenza_sede) > new Date(data_ora_fine_turno)) {
                setError("data_ora_partenza_sede", {
                    type: "custom",
                    message:
                        "Inizio orario di lavoro troppo ritardato rispetto a fine orario di lavoro",
                });
                setError("data_ora_fine_turno", {
                    type: "custom",
                    message:
                        "Fine orario di lavoro troppo anticipato rispetto a inizio orario di lavoro",
                });
            }
        }

        if (data_ora_inizio_carico && data_ora_fine_carico) {
            if (new Date(data_ora_inizio_carico) > new Date(data_ora_fine_carico)) {
                setError("data_ora_inizio_carico", {
                    type: "custom",
                    message: "Ora di inizio carico posteriore a ora di fine carico",
                });
                setError("data_ora_fine_carico", {
                    type: "custom",
                    message: "Ora di fine carico precedente a ora di inizio carico",
                });
            }
        }

        if (data_ora_inizio_carico && data_ora_partenza_sede) {
            if (new Date(data_ora_inizio_carico) < new Date(data_ora_partenza_sede)) {
                setError("data_ora_inizio_carico", {
                    type: "custom",
                    message:
                        "Ora di inizio carico incoerente con inizio orario di lavoro",
                });
                setError("data_ora_partenza_sede", {
                    type: "custom",
                    message:
                        "Inizio orario di lavoro incoerente con ora di inizio carico",
                });
            }
        }

        if (data_ora_fine_turno && data_ora_fine_carico) {
            if (new Date(data_ora_fine_turno) < new Date(data_ora_fine_carico)) {
                setError("data_ora_fine_turno", {
                    type: "custom",
                    message: "Fine orario di lavoro incoerente con ora di fine carico",
                });
                setError("data_ora_fine_carico", {
                    type: "custom",
                    message: "Ora di fine carico incoerente con fine orario di lavoro",
                });
            }
        }
    }, [
        watch("data_ora_partenza_sede"),
        watch("data_ora_fine_turno"),
        watch("data_ora_inizio_carico"),
        watch("data_ora_fine_carico"),
    ]);

    // #############################################
    // Calcolo peso alla partenza
    // #############################################

    React.useEffect(() => {
        const lordo_in_carico_motrice: number = Number(
            getValues("lordo_in_carico_motrice") || 0
        );
        const tara_in_carico_motrice: number = Number(
            getValues("tara_in_carico_motrice") || 0
        );
        const lordo_in_carico_rimorchio: number = Number(
            getValues("lordo_in_carico_rimorchio") || 0
        );
        const tara_in_carico_rimorchio: number = Number(
            getValues("tara_in_carico_rimorchio") || 0
        );
        const rimorchio = getValues("rimorchio");
        if (rimorchio) {
            const peso_partenza =
                lordo_in_carico_motrice +
                lordo_in_carico_rimorchio -
                tara_in_carico_motrice -
                tara_in_carico_rimorchio;
            setValue("peso_partenza", peso_partenza);
        } else {
            const peso_partenza = lordo_in_carico_motrice - tara_in_carico_motrice;

            setValue("peso_partenza", peso_partenza);
        }
    }, [
        watch("lordo_in_carico_motrice"),
        watch("tara_in_carico_motrice"),
        watch("lordo_in_carico_rimorchio"),
        watch("tara_in_carico_rimorchio"),
    ]);

    // #############################################
    // Calcolo peso all'arrivo
    // #############################################

    React.useEffect(() => {
        const lordo_in_arrivo_motrice: number = Number(
            getValues("lordo_in_arrivo_motrice") || 0
        );
        const tara_in_arrivo_motrice: number = Number(
            getValues("tara_in_arrivo_motrice") || 0
        );
        const lordo_in_arrivo_rimorchio: number = Number(
            getValues("lordo_in_arrivo_rimorchio") || 0
        );
        const tara_in_arrivo_rimorchio: number = Number(
            getValues("tara_in_arrivo_rimorchio") || 0
        );
        const numero_gabbioni_motrice: number = Number(
            getValues("numero_gabbioni_motrice") || 0
        );
        const numero_gabbioni_rimorchio: number = Number(
            getValues("numero_gabbioni_rimorchio") || 0
        );

        const rimorchio = getValues("rimorchio");

        if (rimorchio) {
            const peso_gabbie =
                (numero_gabbioni_motrice + numero_gabbioni_rimorchio) *
                parametri.peso_gabbione;
            const peso_arrivo =
                lordo_in_arrivo_motrice +
                lordo_in_arrivo_rimorchio -
                tara_in_arrivo_motrice -
                tara_in_arrivo_rimorchio -
                peso_gabbie;
            setValue("peso_arrivo", peso_arrivo);
        } else {
            const peso_gabbie = numero_gabbioni_motrice * parametri.peso_gabbione;
            const peso_arrivo =
                lordo_in_arrivo_motrice - tara_in_arrivo_motrice - peso_gabbie;

            setValue("peso_arrivo", peso_arrivo);
        }
    }, [
        watch("lordo_in_arrivo_motrice"),
        watch("lordo_in_carico_motrice"),
        watch("tara_in_arrivo_motrice"),
        watch("lordo_in_arrivo_rimorchio"),
        watch("tara_in_arrivo_rimorchio"),
        watch("numero_gabbioni_motrice"),
        watch("numero_gabbioni_rimorchio"),
    ]);

    // #############################################
    // Calcolo calo del peso e peso medio per capo
    // #############################################

    React.useEffect(() => {
        const peso_partenza: number = Number(getValues("peso_partenza") || 0);
        const peso_arrivo: number = Number(getValues("peso_arrivo") || 0);
        const numero_capi_ritirati: number = Number(
            getValues("numero_capi_ritirati") || 0
        );
        if (!getValues("calo_concordato")) {
            const calo = peso_partenza - peso_arrivo;
            setValue("calo", calo);
        }
        if (numero_capi_ritirati > 0) {
            const peso_medio_arrivo = Number(
                (Math.round((peso_arrivo / numero_capi_ritirati) * 100) / 100).toFixed(
                    2
                )
            );
            setValue("peso_medio_arrivo", peso_medio_arrivo);
        } else {
            setValue("peso_medio_arrivo", 0);
        }
    }, [
        watch("peso_partenza"),
        watch("peso_arrivo"),
        watch("numero_capi_ritirati"),
    ]);

    // #############################################
    // Calcolo con calo del peso concordato
    // #############################################

    React.useEffect(() => {
        if (getValues("calo_concordato")) {
            const numero_gabbioni_motrice: number = Number(
                getValues("numero_gabbioni_motrice") || 0
            );
            const numero_gabbioni_rimorchio: number = Number(
                getValues("numero_gabbioni_rimorchio") || 0
            );
            const calo: number = Number(getValues("calo") || 0);

            if (getValues("rimorchio")) {
                const peso_gabbie_motrice =
                    numero_gabbioni_motrice * parametri.peso_gabbione;
                const peso_gabbie_rimorchio =
                    numero_gabbioni_rimorchio * parametri.peso_gabbione;
                setValue(
                    "tara_in_carico_motrice",
                    (watch("tara_in_arrivo_motrice") || 0) + peso_gabbie_motrice
                );
                setValue(
                    "tara_in_carico_rimorchio",
                    (getValues("tara_in_arrivo_rimorchio") || 0) + peso_gabbie_rimorchio
                );
            } else {
                const peso_gabbie: number =
                    numero_gabbioni_motrice * parametri.peso_gabbione;
                setValue(
                    "tara_in_carico_motrice",
                    (getValues("tara_in_arrivo_motrice") || 0) + peso_gabbie
                );
            }

            if (getValues("rimorchio")) {
                setValue(
                    "lordo_in_carico_motrice",
                    (getValues("lordo_in_arrivo_motrice") || 0) +
                    (calo * numero_gabbioni_motrice) /
                    (numero_gabbioni_motrice + numero_gabbioni_rimorchio)
                );
                setValue(
                    "lordo_in_carico_rimorchio",
                    (getValues("lordo_in_arrivo_rimorchio") || 0) +
                    (calo * numero_gabbioni_rimorchio) /
                    (numero_gabbioni_motrice + numero_gabbioni_rimorchio)
                );
            } else {
                setValue(
                    "lordo_in_carico_motrice",
                    (getValues("lordo_in_arrivo_motrice") || 0) +
                    (calo * numero_gabbioni_motrice) /
                    (numero_gabbioni_motrice + numero_gabbioni_rimorchio)
                );
            }
        }

        // if (numero_capi_ritirati > 0) {
        // 	const peso_medio_arrivo = Number(
        // 		(Math.round((peso_arrivo / numero_capi_ritirati) * 100) / 100).toFixed(
        // 			2
        // 		)
        // 	);
        // 	setValue("peso_medio_arrivo", peso_medio_arrivo);
        // } else {
        // 	setValue("peso_medio_arrivo", 0);
        // }
    }, [
        watch("calo"),
        watch("numero_gabbioni_motrice"),
        watch("numero_gabbioni_rimorchio"),
        watch("tara_in_arrivo_rimorchio"),
        watch("tara_in_arrivo_motrice"),
        watch("lordo_in_arrivo_motrice"),
        watch("lordo_in_arrivo_rimorchio"),
    ]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {/* {schedaCarico?.errorsStack?.fieldsErrors?.non_field_errors && (
        <Alert severity="error">
          {schedaCarico?.errorsStack?.fieldsErrors?.non_field_errors.toString()}
        </Alert>
      )} */}

            <Grid
                container
                sx={{flexGrow: 1, mb: 1}}
                rowSpacing={1.5}
                columnSpacing={{xs: 1, sm: 1, md: 1}}
                justifyContent="center"
                alignItems="flex-start"
            >
                <Grid size={{xs: 12, sm: 6, md: 2}}>
                    <DateTimePicker
                        // components={{
                        // 	OpenPickerIcon: ClockIcon,
                        // }}
                        format="dd/MM/yy HH:mm"
                        // mask={"__/__/__ __:__"}
                        label="inizio orario di lavoro"
                        value={convertToDateTime(
                            watch("data_ora_partenza_sede") ||
                            consuntivoVivo.data_ora_partenza_sede)
                        }

                        slotProps={{
                            textField: {
                                // required: false,
                                error: !!errors?.data_ora_partenza_sede,
                                helperText: errors?.data_ora_partenza_sede?.message,
                                sx: {width: "100%"},
                                autoComplete: "off",
                                // disabled: !!richiestaConnessione.stato
                            },
                            field: {
                                clearable: true,
                            },
                        }}
                        onChange={(newValue: DateTime | null) => {
                            setValue(
                                "data_ora_partenza_sede",
                                newValue ? convertToDateTime(newValue)?.toFormat("yyyy-MM-dd HH:mm") : "",
                                {
                                    shouldDirty: true,
                                }
                            );
                            clearErrors("data_ora_partenza_sede");
                        }}
                    />
                </Grid>
                <Grid size={{xs: 12, sm: 6, md: 2}}>
                    <DateTimePicker
                        // components={{
                        // 	OpenPickerIcon: ClockIcon,
                        // }}
                        format="dd/MM/yy HH:mm"
                        // mask={"__/__/__ __:__"}
                        label="fine orario di lavoro"
                        // {...register("data_ora_fine_turno")}
                        value={convertToDateTime(
                            watch("data_ora_fine_turno") ||
                            consuntivoVivo.data_ora_fine_turno)
                        }
                        slotProps={{
                            textField: {
                                // required: false,
                                error: !!errors?.data_ora_fine_turno,
                                helperText: errors?.data_ora_fine_turno?.message,
                                sx: {width: "100%"},
                                autoComplete: "off",
                                // disabled: !!richiestaConnessione.stato
                            },
                            field: {
                                clearable: true,
                            },
                        }}

                        onChange={(newValue: DateTime<boolean> | null) => {
                            setValue(
                                "data_ora_fine_turno",
                                newValue ? convertToDateTime(newValue)?.toFormat("yyyy-MM-dd HH:mm") : "",
                                {
                                    shouldDirty: true,
                                }
                            );
                            clearErrors("data_ora_fine_turno");
                        }}
                    />
                </Grid>
                <Grid size={{xs: 12, sm: 6, md: 2}}>
                    <Controller
                        name="km_ar"
                        control={control}
                        render={({field}) => (
                            <NumericFormat
                                id="km_ar"
                                customInput={TextField}
                                variant="outlined"
                                label="km a/r"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={!!errors?.km_ar}
                                helperText={errors?.km_ar?.message}
                                // {...register("km_ar")}
                                value={field.value}
                                autoComplete="off"
                                prefix="km "
                                thousandSeparator="."
                                decimalSeparator=","
                                valueIsNumericString={true}
                                onValueChange={(v) => {
                                    setValue("km_ar", Number(v.value), {shouldDirty: true});
                                }}
                                sx={{width: "100%"}}
                            />
                        )}
                    />
                </Grid>
                <Grid size={{xs: 12, sm: 6, md: 2}}>
                    <DateTimePicker
                        // components={{
                        // 	OpenPickerIcon: ClockIcon,
                        // }}
                        format="dd/MM/yy HH:mm"
                        // mask={"__/__/__ __:__"}
                        label="ora inizio carico"
                        // {...register("data_ora_carico_pianificata")}

                        value={convertToDateTime(
                            watch("data_ora_inizio_carico") ||
                            consuntivoVivo.data_ora_inizio_carico)
                        }

                        slotProps={{
                            textField: {
                                // required: false,
                                error: !!errors?.data_ora_inizio_carico,
                                helperText: errors?.data_ora_inizio_carico?.message,
                                sx: {width: "100%"},
                                autoComplete: "off",
                                // disabled: !!richiestaConnessione.stato
                            },
                            field: {
                                clearable: true,
                            },
                        }}
                        onChange={(newValue: DateTime<boolean> | null) => {
                            setValue(
                                "data_ora_inizio_carico",
                                newValue ? convertToDateTime(newValue)?.toFormat("yyyy-MM-dd HH:mm") : "",
                                {
                                    shouldDirty: true,
                                }
                            );
                            clearErrors("data_ora_inizio_carico");
                        }}
                    />
                </Grid>
                <Grid size={{xs: 12, sm: 6, md: 2}}>
                    <DateTimePicker
                        // components={{
                        // 	OpenPickerIcon: ClockIcon,
                        format="dd/MM/yy HH:mm"
                        // mask={"__/__/__ __:__"}
                        label="ora fine carico"
                        // {...register("data_ora_carico_pianificata")}

                        value={convertToDateTime(
                            watch("data_ora_fine_carico") ||
                            consuntivoVivo.data_ora_fine_carico)
                        }
                        slotProps={{
                            textField: {
                                // required: false,
                                error: !!errors?.data_ora_fine_carico,
                                helperText: errors?.data_ora_fine_carico?.message,
                                sx: {width: "100%"},
                                autoComplete: "off",
                                // disabled: !!richiestaConnessione.stato
                            },
                            field: {
                                clearable: true,
                            },
                        }}

                        onChange={(newValue: DateTime<boolean> | null) => {
                            setValue(
                                "data_ora_fine_carico",
                                newValue ? convertToDateTime(newValue)?.toFormat("yyyy-MM-dd HH:mm") : "",
                                {
                                    shouldDirty: true,
                                }
                            );
                            clearErrors("data_ora_fine_carico");
                        }}
                    />
                </Grid>
                <Grid size={{xs: 12, sm: 6, md: 2}}>
                    <Controller
                        name="numero_capi_ritirati"
                        control={control}
                        render={({field}) => (
                            <NumericFormat
                                id="numero_capi_ritirati"
                                customInput={TextField}
                                variant="outlined"
                                label="n. capi ritirati"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={!!errors?.numero_capi_ritirati}
                                helperText={errors?.numero_capi_ritirati?.message}
                                // {...register("numero_capi_ritirati")}
                                value={field.value}
                                autoComplete="off"
                                thousandSeparator="."
                                decimalSeparator=","
                                valueIsNumericString={true}
                                onValueChange={(v) => {
                                    setValue("numero_capi_ritirati", Number(v.value), {
                                        shouldDirty: true,
                                    });
                                }}
                                sx={{width: "100%"}}
                            />
                        )}
                    />
                </Grid>
            </Grid>
            <Grid
                container
                sx={{flexGrow: 1, mb: 1}}
                rowSpacing={1.5}
                columnSpacing={{xs: 1, sm: 1, md: 1}}
                justifyContent="flex-start"
                alignItems="center"
            >
                <Grid size={{xs: 12, sm: 6, md: 2}}>
                    <Controller
                        name="lordo_in_carico_motrice"
                        control={control}
                        render={({field}) => (
                            <NumericFormat
                                id="lordo_in_carico_motrice"
                                customInput={TextField}
                                label="peso partenza lordo motrice"
                                variant={!getValues("calo_concordato") ? "outlined" : "filled"}
                                InputProps={{
                                    readOnly: getValues("calo_concordato"),
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={!!errors?.lordo_in_carico_motrice}
                                helperText={errors?.lordo_in_carico_motrice?.message}
                                // {...register("lordo_in_carico_motrice")}
                                value={field.value}
                                autoComplete="off"
                                prefix="kg "
                                thousandSeparator="."
                                decimalSeparator=","
                                valueIsNumericString={true}
                                onValueChange={(v) => {
                                    setValue("lordo_in_carico_motrice", Number(v.value), {
                                        shouldDirty: true,
                                    });
                                }}
                                sx={{width: "100%"}}
                            />
                        )}
                    />
                </Grid>
                <Grid size={{xs: 12, sm: 6, md: 2}}>
                    <Controller
                        name="tara_in_carico_motrice"
                        control={control}
                        render={({field}) => (
                            <NumericFormat
                                id="tara_in_carico_motrice"
                                customInput={TextField}
                                label="peso partenza tara motrice"
                                variant={!getValues("calo_concordato") ? "outlined" : "filled"}
                                InputProps={{
                                    readOnly: getValues("calo_concordato"),
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={!!errors?.tara_in_carico_motrice}
                                helperText={errors?.tara_in_carico_motrice?.message}
                                // {...register("tara_in_carico_motrice")}
                                value={field.value}
                                autoComplete="off"
                                prefix="kg "
                                thousandSeparator="."
                                decimalSeparator=","
                                valueIsNumericString={true}
                                onValueChange={(v) => {
                                    setValue("tara_in_carico_motrice", Number(v.value), {
                                        shouldDirty: true,
                                    });
                                }}
                                sx={{width: "100%"}}
                            />
                        )}
                    />
                </Grid>
                <Grid size={{xs: 12, sm: 6, md: 2}}>
                    <Controller
                        name="lordo_in_carico_rimorchio"
                        control={control}
                        render={({field}) => (
                            <NumericFormat
                                id="lordo_in_carico_rimorchio"
                                customInput={TextField}
                                label="peso partenza lordo rimorchio"
                                variant={!getValues("calo_concordato") ? "outlined" : "filled"}
                                InputProps={{
                                    readOnly: getValues("calo_concordato"),
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={
                                    !consuntivoVivo.rimorchio ||
                                    !consuntivoVivo.camion_pack?.autotreno
                                }
                                error={!!errors?.lordo_in_carico_rimorchio}
                                helperText={errors?.lordo_in_carico_rimorchio?.message}
                                // {...register("lordo_in_carico_rimorchio")}
                                value={field.value}
                                autoComplete="off"
                                prefix="kg "
                                thousandSeparator="."
                                decimalSeparator=","
                                valueIsNumericString={true}
                                onValueChange={(v) => {
                                    setValue("lordo_in_carico_rimorchio", Number(v.value), {
                                        shouldDirty: true,
                                    });
                                }}
                                sx={{width: "100%"}}
                            />
                        )}
                    />
                </Grid>
                <Grid size={{xs: 12, sm: 6, md: 2}}>
                    <Controller
                        name="tara_in_carico_rimorchio"
                        control={control}
                        render={({field}) => (
                            <NumericFormat
                                id="tara_in_carico_rimorchio"
                                customInput={TextField}
                                label="peso partenza tara rimorchio"
                                variant={!getValues("calo_concordato") ? "outlined" : "filled"}
                                InputProps={{
                                    readOnly: getValues("calo_concordato"),
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={
                                    !consuntivoVivo.rimorchio ||
                                    !consuntivoVivo.camion_pack?.autotreno
                                }
                                error={!!errors?.tara_in_carico_rimorchio}
                                helperText={errors?.tara_in_carico_rimorchio?.message}
                                // {...register("tara_in_carico_rimorchio")}
                                value={field.value}
                                autoComplete="off"
                                prefix="kg "
                                thousandSeparator="."
                                decimalSeparator=","
                                valueIsNumericString={true}
                                onValueChange={(v) => {
                                    setValue("tara_in_carico_rimorchio", Number(v.value), {
                                        shouldDirty: true,
                                    });
                                }}
                                sx={{width: "100%"}}
                            />
                        )}
                    />
                </Grid>
                <Grid size={{xs: 12, sm: 6, md: 2}}>
                    <Controller
                        name="peso_partenza"
                        control={control}
                        render={({field}) => (
                            <NumericFormat
                                id="peso_partenza"
                                customInput={TextField}
                                variant="filled"
                                label="Peso alla partenza"
                                InputProps={{
                                    readOnly: true,
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={true}
                                error={!!errors?.peso_partenza}
                                helperText={errors?.peso_partenza?.message}
                                // {...register("peso_partenza")}
                                value={field.value}
                                autoComplete="off"
                                prefix="kg "
                                thousandSeparator="."
                                decimalSeparator=","
                                valueIsNumericString={true}
                                onValueChange={(v) => {
                                    setValue("peso_partenza", Number(v.value), {
                                        shouldDirty: true,
                                    });
                                }}
                                sx={{width: "100%"}}
                            />
                        )}
                    />
                </Grid>
            </Grid>
            <Grid
                container
                sx={{flexGrow: 1, mb: 1}}
                rowSpacing={1.5}
                columnSpacing={{xs: 1, sm: 1, md: 1}}
                justifyContent="flex-start"
                alignItems="center"
            >
                <Grid size={{xs: 12, sm: 6, md: 2}}>
                    <Controller
                        name="lordo_in_arrivo_motrice"
                        control={control}
                        render={({field}) => (
                            <NumericFormat
                                id="lordo_in_arrivo_motrice"
                                customInput={TextField}
                                variant="outlined"
                                label="peso arrivo lordo motrice"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={!!errors?.lordo_in_arrivo_motrice}
                                helperText={errors?.lordo_in_arrivo_motrice?.message}
                                // {...register("lordo_in_arrivo_motrice")}
                                value={field.value}
                                autoComplete="off"
                                prefix="kg "
                                thousandSeparator="."
                                decimalSeparator=","
                                valueIsNumericString={true}
                                onValueChange={(v) => {
                                    setValue("lordo_in_arrivo_motrice", Number(v.value), {
                                        shouldDirty: true,
                                    });
                                }}
                                sx={{width: "100%"}}
                            />
                        )}
                    />
                </Grid>
                <Grid size={{xs: 12, sm: 6, md: 2}}>
                    <Controller
                        name="tara_in_arrivo_motrice"
                        control={control}
                        render={({field}) => (
                            <NumericFormat
                                id="tara_in_arrivo_motrice"
                                customInput={TextField}
                                variant="outlined"
                                label="peso arrivo tara motrice"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={!!errors?.tara_in_arrivo_motrice}
                                helperText={errors?.tara_in_arrivo_motrice?.message}
                                // {...register("tara_in_arrivo_motrice")}
                                value={field.value}
                                autoComplete="off"
                                prefix="kg "
                                thousandSeparator="."
                                decimalSeparator=","
                                valueIsNumericString={true}
                                onValueChange={(v) => {
                                    setValue("tara_in_arrivo_motrice", Number(v.value), {
                                        shouldDirty: true,
                                    });
                                }}
                                sx={{width: "100%"}}
                            />
                        )}
                    />
                </Grid>
                <Grid size={{xs: 12, sm: 6, md: 2}}>
                    <Controller
                        name="lordo_in_arrivo_rimorchio"
                        control={control}
                        render={({field}) => (
                            <NumericFormat
                                id="lordo_in_arrivo_rimorchio"
                                customInput={TextField}
                                variant="outlined"
                                label="peso arrivo lordo rimorchio"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={
                                    !consuntivoVivo.rimorchio ||
                                    !consuntivoVivo.camion_pack?.autotreno
                                }
                                error={!!errors?.lordo_in_arrivo_rimorchio}
                                helperText={errors?.lordo_in_arrivo_rimorchio?.message}
                                // {...register("lordo_in_arrivo_rimorchio")}
                                value={field.value}
                                autoComplete="off"
                                prefix="kg "
                                thousandSeparator="."
                                decimalSeparator=","
                                valueIsNumericString={true}
                                onValueChange={(v) => {
                                    setValue("lordo_in_arrivo_rimorchio", Number(v.value), {
                                        shouldDirty: true,
                                    });
                                }}
                                sx={{width: "100%"}}
                            />
                        )}
                    />
                </Grid>
                <Grid size={{xs: 12, sm: 6, md: 2}}>
                    <Controller
                        name="tara_in_arrivo_rimorchio"
                        control={control}
                        render={({field}) => (
                            <NumericFormat
                                id="tara_in_arrivo_rimorchio"
                                customInput={TextField}
                                variant="outlined"
                                label="peso arrivo tara rimorchio"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={
                                    !consuntivoVivo.rimorchio ||
                                    !consuntivoVivo.camion_pack?.autotreno
                                }
                                error={!!errors?.tara_in_arrivo_rimorchio}
                                helperText={errors?.tara_in_arrivo_rimorchio?.message}
                                // {...register("tara_in_arrivo_rimorchio")}
                                value={field.value}
                                autoComplete="off"
                                prefix="kg "
                                thousandSeparator="."
                                decimalSeparator=","
                                valueIsNumericString={true}
                                onValueChange={(v) => {
                                    setValue("tara_in_arrivo_rimorchio", Number(v.value), {
                                        shouldDirty: true,
                                    });
                                }}
                                sx={{width: "100%"}}
                            />
                        )}
                    />
                </Grid>
            </Grid>
            <Grid
                container
                sx={{flexGrow: 1, mb: 1}}
                rowSpacing={2}
                columnSpacing={{xs: 1, sm: 1, md: 1}}
                justifyContent="center"
                alignItems="flex-start"
            >
                {/* <Grid size={{ xs: 12, sm:6, md:2 }}></Grid> */}
                {/* <Grid size={{ xs: 12, sm:6, md:2 }}></Grid> */}

                <Grid size={{xs: 12, sm: 6, md: 2}}>
                    <TextField
                        label="n. gabbioni motrice"
                        type="number"
                        variant="outlined"
                        InputProps={{inputProps: {min: 0, max: 100}}}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        {...register("numero_gabbioni_motrice")}
                        error={!!errors?.numero_gabbioni_motrice}
                        helperText={errors?.numero_gabbioni_motrice?.message}
                        autoComplete={"off"}
                        sx={{width: "100%"}}
                    />
                </Grid>
                <Grid size={{xs: 12, sm: 6, md: 2}}></Grid>

                <Grid size={{xs: 12, sm: 6, md: 2}}>
                    <TextField
                        label="n. gabbioni rimorchio"
                        type="number"
                        disabled={
                            !consuntivoVivo.rimorchio ||
                            !consuntivoVivo.camion_pack?.autotreno
                        }
                        variant="outlined"
                        InputProps={{inputProps: {min: 0, max: 100}}}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        {...register("numero_gabbioni_rimorchio")}
                        error={!!errors?.numero_gabbioni_rimorchio}
                        helperText={errors?.numero_gabbioni_rimorchio?.message}
                        autoComplete={"off"}
                        sx={{width: "100%"}}
                    />
                </Grid>

                <Grid size={{xs: 12, sm: 6, md: 2}}>
                    <Controller
                        name="peso_arrivo"
                        control={control}
                        render={({field}) => (
                            <NumericFormat
                                id="peso_arrivo"
                                customInput={TextField}
                                variant="filled"
                                label="Peso all'arrivo"
                                InputProps={{
                                    readOnly: true,
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={true}
                                error={!!errors?.peso_arrivo}
                                helperText={errors?.peso_arrivo?.message}
                                // {...register("peso_arrivo")}
                                value={field.value}
                                autoComplete="off"
                                prefix="kg "
                                thousandSeparator="."
                                decimalSeparator=","
                                valueIsNumericString={true}
                                onValueChange={(v) => {
                                    setValue("peso_arrivo", Number(v.value), {
                                        shouldDirty: true,
                                    });
                                }}
                                sx={{width: "100%"}}
                            />
                        )}
                    />
                </Grid>
            </Grid>
            <Grid
                container
                sx={{flexGrow: 1, mb: 1}}
                rowSpacing={2}
                columnSpacing={{xs: 1, sm: 1, md: 1}}
                justifyContent="center"
                alignItems="flex-start"
            >
                <Grid size={{xs: 12, sm: 6, md: 2}}></Grid>
                <Grid size={{xs: 12, sm: 6, md: 2}}></Grid>
                <Grid size={{xs: 12, sm: 6, md: 2}}></Grid>
                <Grid size={{xs: 12, sm: 6, md: 2}}>
                    <Box display="flex" justifyContent="flex-end">
                        <FormControlLabel
                            labelPlacement="top"
                            label={
                                <Typography fontSize="0.8rem" marginLeft="0.1rem">
                                    {getValues("calo_concordato")
                                        ? "calo concordato"
                                        : "calo misurato"}
                                </Typography>
                            }
                            control={
                                <Switch
                                    id="calo_concordato"
                                    // disabled={getValues("confermato")}
                                    checked={watch("calo_concordato") || false}
                                    onChange={(
                                        event: React.ChangeEvent<HTMLInputElement>,
                                        newValue: any
                                    ) => {
                                        setValue("calo_concordato", newValue, {
                                            shouldDirty: true,
                                        });
                                        // setStyleBkg(watch("calo_concordato"));
                                    }}
                                    color="error"
                                    size="small"
                                />
                            }
                        />
                    </Box>
                </Grid>

                <Grid size={{xs: 12, sm: 6, md: 2}}>
                    <Controller
                        name="calo"
                        control={control}
                        render={({field}) => (
                            <NumericFormat
                                id="calo"
                                customInput={TextField}
                                label="Calo"
                                variant={getValues("calo_concordato") ? "outlined" : "filled"}
                                InputProps={{
                                    readOnly: !getValues("calo_concordato"),
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={!getValues("calo_concordato")}
                                error={!!errors?.calo}
                                helperText={errors?.calo?.message}
                                // {...register("calo")}
                                value={field.value}
                                autoComplete="off"
                                prefix="kg "
                                thousandSeparator="."
                                decimalSeparator=","
                                valueIsNumericString={true}
                                onValueChange={(v) => {
                                    setValue("calo", Number(v.value), {shouldDirty: true});
                                }}
                                sx={{width: "100%"}}
                            />
                        )}
                    />
                </Grid>
                <Grid size={{xs: 12, sm: 6, md: 2}}>
                    <Controller
                        name="peso_medio_arrivo"
                        control={control}
                        render={({field}) => (
                            <NumericFormat
                                id="peso_medio_arrivo"
                                customInput={TextField}
                                variant="filled"
                                label="Peso medio per capo"
                                InputProps={{
                                    readOnly: true,
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={true}
                                error={!!errors?.peso_medio_arrivo}
                                helperText={errors?.peso_medio_arrivo?.message}
                                // {...register("peso_medio_arrivo")}
                                value={field.value}
                                autoComplete="off"
                                prefix="kg "
                                thousandSeparator="."
                                decimalSeparator=","
                                valueIsNumericString={true}
                                onValueChange={(v) => {
                                    setValue("peso_medio_arrivo", Number(v.value), {
                                        shouldDirty: true,
                                    });
                                }}
                                sx={{width: "100%"}}
                            />
                        )}
                    />
                </Grid>
            </Grid>

            <Grid sx={{flexGrow: 1, mt: 3}} container spacing={2}>
                <Grid size={{xs: 12}}>
                    <Stack direction="row" spacing={2} justifyContent="center">
                        {/* <Button variant="outlined" onClick={svuotaCampi}>
							Svuota modulo
						</Button> */}

                        <Button disabled={!isDirty} variant="outlined" type="submit">
                            Salva
                        </Button>
                    </Stack>
                </Grid>
            </Grid>
        </form>
    );
};

export default ConsuntivoVivoForm;
