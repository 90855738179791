import React from "react";
import { DateTime } from 'luxon';

import { Text, View, StyleSheet, Image } from "@react-pdf/renderer";

import {
	DocumentoDiTrasporto,
	DestinazioneTrasporto,
	VettoreTrasporto,
} from "../bolleFattureSlice";
import DettaglioDdtPDF from "./DettaglioDdtPDF";
import { getCausaleDdt, gettipoDdt } from "../utility/ddtUtiliy";
import { Committente } from "components/main/anagrafiche/anagraficheSlice";
import {convertToDateTime} from "../../../common/dateUtils/convertToDateTime";
const logoScarso = require("../../../../../static/images/logo/logo-Scarso_nuovo_solo.png");

// Create styles
const styles = StyleSheet.create({
	section: {
		marginTop: 5,
		marginLeft: 25,
		marginRight: 25,
		padding: 5,
	},
	text: {
		fontSize: 12,
		fontStyle: "normal",
	},
});

interface DdtPdfProps {
	documentoDiTrasporto: DocumentoDiTrasporto;
	destinatario: Committente;
	destinazione?: DestinazioneTrasporto;
	vettore?: VettoreTrasporto;
}

const DdtPdf = ({
	documentoDiTrasporto,
	destinatario,
	destinazione,
	vettore,
}: DdtPdfProps) => (
	<View
		style={{
			flexDirection: "column",
			justifyContent: "space-between",
			margin: 5,
			height: 800,
		}}
	>
		<View>
			<View
				key={"documentoDiTrasportoPDF_logo"}
				style={{
					flexDirection: "row",
					justifyContent: "flex-start",
					marginLeft: 10,
				}}
			>
				<Text
					style={{
						fontWeight: "bold",
						marginBottom: 1,
						paddingBottom: 0,
						fontSize: 20,
					}}
				>
					<Image
						src={logoScarso}
						style={{
							width: 32,
							height: 32,
							// margin: 0,
							padding: 0,
							paddingLeft: "10px",
							marginLeft: "3px",
						}}
					/>
				</Text>
				<View
					key={"documentoDiTrasportoPDF_intestazione"}
					style={{
						flexDirection: "column",
						justifyContent: "center",
						marginLeft: 10,
					}}
				>
					<Text style={(styles.text, { fontSize: 17 })}>
						Scarso Romualdo e Figli S.r.l.
					</Text>
					<Text style={(styles.text, { fontSize: 12 })}>
						Macellazione avicola
					</Text>
				</View>
			</View>
			<View
				// key={"scheda_macellazione_intestazione"}
				style={{
					flexDirection: "row",
					justifyContent: "space-between",
				}}
			>
				<View style={(styles.section, { marginLeft: 52 })}>
					<Text style={(styles.text, { fontSize: 8 })}>
						Sede: 35020 Ponte San Nicolò (PD) - via Garibaldi, 43
					</Text>
					<Text style={(styles.text, { fontSize: 8 })}>
						Tel. (049)8961477 - Fax. (049)8961468
					</Text>
					<Text style={(styles.text, { fontSize: 8 })}>
						E-mail: produzione@macelloscarso.it
					</Text>
					<Text style={(styles.text, { fontSize: 8 })}>
						R.E.A. n. 123641 - C.F. ee P.IVA n. 00227510286
					</Text>
					<Text style={(styles.text, { fontSize: 8 })}>
						Cap.Soc.: € 500.000 l.v.
					</Text>
					<Text style={(styles.text, { fontSize: 8 })}>
						Iscr. Trib. Padova 14038 - Reg. imp. 9324
					</Text>
					<Text style={(styles.text, { fontSize: 8 })}>
						Codice Comunitario IT00227510286
					</Text>
				</View>
				<View
					style={{
						marginRight: 10,
						// width: 280,
					}}
				>
					<Text style={(styles.text, { marginLeft: 10, fontSize: 8 })}>
						Destinatario:
					</Text>

					<View
						style={{
							// width: 280,
							borderRadius: 10,
							borderWidth: 1,
							borderColor: "#243",
							padding: 10,
							marginBottom: 10,
						}}
					>
						<Text style={(styles.text, { fontSize: 12 })}>
							{destinatario.ragione_sociale}
						</Text>
						<Text style={(styles.text, { fontSize: 8 })}>
							{destinatario.indirizzo}
						</Text>
						<Text style={(styles.text, { fontSize: 8 })}>
							{destinatario.cap + " " + destinatario.comune}
						</Text>
					</View>
					<Text style={(styles.text, { marginLeft: 10, fontSize: 8 })}>
						Destinazione:
					</Text>
					<View
						style={{
							// width: 280,
							borderRadius: 10,
							borderWidth: 1,
							borderColor: "#243",
							padding: 10,
						}}
					>
						<Text style={(styles.text, { fontSize: 12 })}>
							{destinazione
								? destinazione.ragione_sociale
								: destinatario.ragione_sociale}
						</Text>
						<Text style={(styles.text, { fontSize: 8 })}>
							{destinazione ? destinazione.indirizzo : destinatario.indirizzo}
						</Text>
						<Text style={(styles.text, { fontSize: 8 })}>
							{destinazione
								? destinazione.cap + " " + destinazione.comune
								: destinatario.cap + " " + destinatario.comune}
						</Text>
					</View>
				</View>
			</View>
			<View
				style={{
					// width: 280,
					borderRadius: 10,
					borderWidth: 1,
					borderColor: "#243",
					// padding: 10,
					margin: 10,
					paddingBottom: 15,
				}}
			>
				<Text
					style={(styles.text, { fontSize: 12, margin: 15, marginBottom: 5 })}
				>
					Documenti di trasporto n.{" "}
					{documentoDiTrasporto.progressivo || documentoDiTrasporto.id}/T del{" "}
					{convertToDateTime(documentoDiTrasporto.data_documento)?.setLocale("it").toFormat('d LLLL yyyy')}
				</Text>
				<View
					// key={"scheda_macellazione_intestazione"}
					style={{
						flexDirection: "row",
						justifyContent: "space-between",
						margin: 15,
						marginTop: 5,
					}}
				>
					<Text style={(styles.text, { fontSize: 8 })}>
						Cod. Cli:{destinatario.id}
					</Text>
					<Text style={(styles.text, { fontSize: 8 })}>
						Cod.Fisc.:{destinatario.codice_fiscale}{" "}
					</Text>
					<Text style={(styles.text, { fontSize: 8 })}>
						P.IVA:{destinatario.piva}
					</Text>
					<Text style={(styles.text, { fontSize: 8 })}>
						Causale trasporto:
						{getCausaleDdt(documentoDiTrasporto)}
					</Text>
				</View>
				<DettaglioDdtPDF dettagliDdt={documentoDiTrasporto.righe_dettaglio} />
			</View>
		</View>

		<View
			style={{
				// width: 280,
				borderRadius: 10,
				borderWidth: 1,
				borderColor: "#243",
				// padding: 10,
				margin: 10,
				flexDirection: "row",
				// justifyContent: "space-between",
			}}
		>
			<View
				style={{
					// width: 280,
					borderRightWidth: 0.5,
					borderColor: "#243",
					// padding: 10,
					margin: 0,
					padding: 10,
					flexDirection: "column",
					justifyContent: "space-between",
					width: "70%",
				}}
			>
				<Text
					style={
						(styles.text,
						{
							marginBottom: 8,
							paddingBottom: 5,
							borderBottomWidth: 0.5,
							fontSize: 8,
						})
					}
				>
					{vettore
						? "Trasporto a mezzo: VETTORE"
						: "Trasporto a cura del destinatario"}
				</Text>
				<View
					style={{
						flexDirection: "row",
						justifyContent: "space-between",
						// width: "70%",
						textAlign: "right",
					}}
				>
					<Text style={(styles.text, { fontSize: 8, width: "30%" })}>
						numero colli:
					</Text>
					<Text style={(styles.text, { fontSize: 8, width: "30%" })}>
						peso netto:
					</Text>
					<Text style={(styles.text, { fontSize: 8, width: "30%" })}>
						peso lordo:
					</Text>
				</View>
				<View
					style={{
						flexDirection: "row",
						justifyContent: "space-between",
						// width: "70%",
						textAlign: "right",
						marginBottom: 8,
						paddingBottom: 5,
						borderBottomWidth: 0.5,
					}}
				>
					<Text style={(styles.text, { fontSize: 10, width: "30%" })}>
						{Intl.NumberFormat("it-IT").format(
							documentoDiTrasporto.colli_consegna || 0
						)}
					</Text>
					<Text style={(styles.text, { fontSize: 10, width: "30%" })}>
						{Intl.NumberFormat("it-IT").format(
							documentoDiTrasporto.kg_consegna || 0
						)}{" "}
						kg
					</Text>
					<Text style={(styles.text, { fontSize: 10, width: "30%" })}>
						{/* {Intl.NumberFormat("it-IT").format(
						documentoDiTrasporto.kg_consegna || 0
					)} kg */}
					</Text>
				</View>
				<View
					style={{
						marginBottom: 8,
						paddingBottom: 5,
						minHeight: 50,
						borderBottomWidth: 0.5,
					}}
				>
					<Text style={(styles.text, { fontSize: 8 })}>Annotazioni:</Text>

					<Text style={(styles.text, { fontSize: 10, width: "100%" })}>
						{documentoDiTrasporto.annotazioni}
					</Text>
					<Text style={(styles.text, { fontSize: 7, width: "100%" })}>
						{documentoDiTrasporto.annotazione_automatica}
					</Text>
				</View>

				<View
					style={{
						flexDirection: "row",
						justifyContent: "space-between",
						// width: "70%",
					}}
				>
					<Text style={(styles.text, { fontSize: 8, width: "60%" })}>
						{vettore ? "Vettore:" : ""}
					</Text>
					<Text
						style={
							(styles.text, { textAlign: "right", fontSize: 8, width: "40%" })
						}
					>
						data ora trasporto:
					</Text>
				</View>
				<View
					style={{
						flexDirection: "row",
						justifyContent: "space-between",
						// width: "70%",
						marginBottom: 8,
						paddingBottom: 5,
						borderBottomWidth: 0.5,
					}}
				>
					<View
						style={{
							flexDirection: "column",
						}}
					>
						{vettore && (
							<>
								<Text style={(styles.text, { fontSize: 10, width: "20%" })}>
									{vettore?.ragione_sociale}
								</Text>

								<Text style={(styles.text, { fontSize: 10 })}>
									{vettore.indirizzo}
								</Text>
								<Text style={(styles.text, { fontSize: 10 })}>
									{vettore.cap +
										" " +
										vettore.comune +
										" (" +
										vettore.provincia +
										")"}
								</Text>
							</>
						)}
					</View>
					<Text
						style={
							(styles.text, { textAlign: "right", fontSize: 10, width: "40%" })
						}
					>
						{documentoDiTrasporto.data_ora_trasporto &&
							convertToDateTime(documentoDiTrasporto.data_ora_trasporto)?.setLocale("it").toFormat('d LLLL yyyy HH:mm')}
					</Text>
				</View>
			</View>
			<View
				style={{
					padding: 10,
					flexDirection: "column",
					textAlign: "left",
					width: "30%",
					// justifyContent: "space-between",
				}}
			>
				<Text
					style={
						(styles.text,
						{
							fontSize: 8,
							marginBottom: 1,
							paddingBottom: 5,
						})
					}
				>
					aspetto esteriore dei beni:
				</Text>
				<Text
					style={
						(styles.text,
						{
							fontSize: 10,
							marginBottom: 8,
							paddingBottom: 5,
							borderBottomWidth: 0.5,
						})
					}
				>
					{gettipoDdt(documentoDiTrasporto)}
				</Text>
				<Text
					style={{
						minHeight: 30,
						fontSize: 8,
						borderBottomWidth: 0.5,
						marginTop: 15,
					}}
				>
					firma conducente:
				</Text>
				<Text
					style={{
						minHeight: 30,
						fontSize: 8,
						borderBottomWidth: 0.5,
						marginTop: 15,
					}}
				>
					firma destinatario:
				</Text>
				<Text
					style={{
						minHeight: 30,
						fontSize: 8,
						borderBottomWidth: 0.5,
						marginTop: 15,
					}}
				>
					firma vettore:
				</Text>
			</View>
		</View>
	</View>
);

export default DdtPdf;
