import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../../redux/store";
import NavigazioneMesiToolbar from "../../../common/dateUtils/NavigazioneMesiToolbar";
import {
	RecuperaDataCorrente,
	FissaDataCorrente,
} from "../../../common/dateUtils/RecuperaDataCorrente";
import {
	SaldiPalletCommittenti,
	fetchSaldoPalletCommittenti,
} from "./palletsSlice";

import { ErrorStatusTypes } from "../../../common/errorsDeclarations";

import { DateTime } from 'luxon';

import ReportPalletList from "./ReportPalletList";
import ReportPalletCommittenteList from "./ReportPalletCommittenteList";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid2";
import {convertToDateTime} from "../../../common/dateUtils/convertToDateTime";

const ReportPalletPage = () => {
	
	const dispatch = useAppDispatch();

	const saldiPalletCommittenti: SaldiPalletCommittenti = useSelector<
		RootState,
		SaldiPalletCommittenti
	>((state) => state.palletsState);

	const location = useLocation();

	const [refresh, setRefresh] = React.useState<boolean>(false);
	const recupera_data_corrente = RecuperaDataCorrente(location);

	const [committenteCorrenteId, setCommittenteCorrenteId] = React.useState<
		number | null
	>(null);

	const [data, setData] = React.useState<DateTime | null>(
		recupera_data_corrente
	);

	useEffect(() => {
		const data_da = convertToDateTime(data)?.startOf('month').toJSDate();
		const data_a = convertToDateTime(data)?.endOf('month').toJSDate();
		dispatch(fetchSaldoPalletCommittenti({ data_da, data_a }));
		setRefresh(false);
	}, [data, refresh]);

	return (
		<>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={
					saldiPalletCommittenti.errorsStack.status == ErrorStatusTypes.PENDING
				}
			>
				<CircularProgress color="inherit" />
			</Backdrop>

			<NavigazioneMesiToolbar
				titolo={"Report Mensile Contabilità Bancali"}
				setData={setData}
				data={data}
				refresh={() => {
					setRefresh(true);
				}}
			/>

			<Grid
				container
				spacing={1}
				sx={{ marginTop: 0.5 }}
				direction="row"
				justifyContent="center"
				alignItems="flex-start"
			>
				<Grid size={{ xs:12, sm:10, md:10, lg:10 }}>
					<Grid container spacing={1} sx={{ marginTop: 0.5 }}>
						<Grid size={{ xs:6, sm:4, md:4, lg:4 }}>
							<ReportPalletList
								saldiPalletCommittenti={saldiPalletCommittenti.results}
								committenteCorrenteId={committenteCorrenteId}
								setCommittenteCorrenteId={setCommittenteCorrenteId}
							/>
						</Grid>
						<Grid size={{ xs:6, sm:8, md:8, lg:8 }}>
							{committenteCorrenteId && (
								<ReportPalletCommittenteList
									saldoPalletCommittente={saldiPalletCommittenti.results.find(
										(saldoPalletCommittente) =>
											saldoPalletCommittente.id_committente ==
											committenteCorrenteId
									)}
								/>
							)}
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};

export default ReportPalletPage;
