import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
	ErrorStatusTypes,
	ErrorsStack,
	parseErrorMessage,
} from "../../../common/errorsDeclarations";

import {
	Lotto,
	getLotto,
	PianificazioneStrutturaState,
} from "../../pianificazione/pianificazioneSlice";

import * as veterinarioApi from "../../../../api/reportistica/reportisticaApi";
import { DateTime } from 'luxon';
import { toast } from "react-toastify";


// export interface StatoConsuntivoVivo {
// 	stato: number;
// 	descrizione: string;
// 	messaggio: string;
// }

type LottoRidotto = Omit<
	Lotto,
	| "stato_lotto"
	// | "committente"
	// | "committente_desc"
	| "produttore"
	| "produttore_pack"
	| "m4_inviato"
	| "in_dubbio"
	| "note_dubbio"
	| "calcolo_codice_tracciabilita_automatico"
	// | "tipo_capo"
	| "peso_medio"
	| "colore"
	| "capi_ritirati"
	| "numero_capi_foro"
	| "numero_fori"
	| "fori_schedulati"
	| "peso_medio_lotto_arrivo"
	| "allevamento"
	| "note"
	| "schede_carico"
	| "consuntivi_vivo"
	| "lavorazioni_lotto"
	| "num_tz_pianificati_lotto"
	| "num_tz_eviscerati_pianificati_lotto"
	| "scheda_macello"
>;

export interface Veterinario extends LottoRidotto {
	data_ora_inizio_macello: Date | string;
	data_ora_fine_macello: Date | string;
	capi_ritirati?: number | null;
	numero_capi_morti?: number | null;
	numero_scarti_1?: number | null;
	numero_scarti_2?: number | null;
	num_busti_lotto?: number | null;
	peso_casse_busto_lotto?: number | null;
	num_tz_lotto?: number | null;
	peso_casse_tz_lotto?: number | null;
	scheda_macello?: null;
	produttore_pack?: null;
	// tempo_attrezzaggio?: number;
	// percentuale_resa?: number | null;
	errorsStack: ErrorsStack;
}
export interface VeterinarioState {
	count: number;
	page: number;
	num_pages: number;
	next?: URL;
	previous?: URL;
	results: Veterinario[];
	errorsStack: ErrorsStack;
}

export interface ResaStrutturaState {
	veterinario: VeterinarioState;
}

const initialState: ResaStrutturaState = {
	veterinario: {
		count: 0,
		page: 0,
		num_pages: 0,
		next: undefined,
		previous: undefined,
		results: [],
		errorsStack: { status: ErrorStatusTypes.OK },
	},
};

export const fetchVeterinario = createAsyncThunk(
	"veterinario/fetchVeterinario",
	async (dataRange?: { data_da?: Date; data_a?: Date }) => {
		return await veterinarioApi.fetchVeterinario(
			dataRange?.data_da,
			dataRange?.data_a
		);
	}
);

export const veterinarioSlice = createSlice({
	name: "veterinarioState",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// fetch dati per il veterinario
		builder.addCase(fetchVeterinario.pending, (state, action) => {
			state.veterinario.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(fetchVeterinario.fulfilled, (state, action) => {
			state.veterinario = action.payload;

			state.veterinario.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(fetchVeterinario.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.veterinario.errorsStack = parseErrorMessage(action.error);
		});
	},
});

// Action creators are generated for each case reducer function
export const {} = veterinarioSlice.actions;

export const veterinarioSliceReducer = veterinarioSlice.reducer;
