import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { Utente } from "../../authentication/authenticationSlice";

import { useLocation } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Routes_path } from "../../routerApp";
import { PermissionTypes } from "../../authentication/permissionsGroups";
import { utenteAutorizzato } from "../../authentication/UtenteAutorizzato";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ElementoMenuControllato from "./ElementoMenuControllato";
import Divider from "@mui/material/Divider";

import HomeIcon from "@mui/icons-material/Home";
import TableChartIcon from "@mui/icons-material/TableChart";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import ShareLocationIcon from "@mui/icons-material/ShareLocation";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import DepartureBoardIcon from "@mui/icons-material/DepartureBoard";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import AllInboxIcon from "@mui/icons-material/AllInbox";
import BalanceIcon from "@mui/icons-material/Balance";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import BuildCircleIcon from "@mui/icons-material/BuildCircle";
// import MedicalInformationIcon from "@mui/icons-material/MedicalInformation";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import ScaleIcon from "@mui/icons-material/Scale";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import DomainIcon from "@mui/icons-material/Domain";
import DashboardTwoToneIcon from "@mui/icons-material/DashboardTwoTone";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AddLocationAltTwoToneIcon from "@mui/icons-material/AddLocationAltTwoTone";
import ListAltIcon from "@mui/icons-material/ListAlt";
import ListAltTwoToneIcon from "@mui/icons-material/ListAltTwoTone";
import ListIcon from "@mui/icons-material/List";
import PeopleIcon from "@mui/icons-material/People";
import AgricultureIcon from "@mui/icons-material/Agriculture";
import Grid4x4Icon from "@mui/icons-material/Grid4x4";

const PREFIX = "SideMenu";

const classes = {
	iconSelected: `${PREFIX}-iconSelected`,
};

const StyledList = styled(List)(() => ({
	[`& .${classes.iconSelected}`]: {
		color: "#1b75b1",
	},
}));

const styleListItem = { justifyContent: "center" };

interface SideMenuProps {
	open: boolean;
	handleDrawerClose: () => void;
}
const SideMenu = ({ open, handleDrawerClose }: SideMenuProps) => {
	const location = useLocation();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down("md"));
	// const matches = useMediaQuery("min-width:900px)");
	const mobileHandleDrawerClose = () => {
		matches && handleDrawerClose();
	};

	const utente = useSelector<RootState, Utente>(
		(state) => state.authentication.utenteLogged
	);

	return (
		<StyledList>
			<ListItem
				component={Link}
				to="/"
				button={true}
				key={"Home"}
				sx={styleListItem}
			>
				<ListItemIcon sx={{ minWidth: "auto" }}>
					<HomeIcon
						className={location.pathname == "/" ? classes.iconSelected : ""}
						onClick={mobileHandleDrawerClose}
					/>
				</ListItemIcon>
				{open && (
					<ListItemText
						style={{ marginLeft: 12 }}
						primary={"Home"}
						className={location.pathname == "/" ? classes.iconSelected : ""}
						onClick={mobileHandleDrawerClose}
					/>
				)}
			</ListItem>
			<ElementoMenuControllato
				utente={utente}
				path={Routes_path.PIANIFICAZIONE}
				label="Pianificazione"
				gruppiAutorizzati={[
					PermissionTypes.PIANIFICATORI,
					PermissionTypes.PIANIFICATORI_LETTURA,
				]}
				open={open}
				selected={location.pathname == Routes_path.PIANIFICAZIONE}
				handleDrawerClose={handleDrawerClose}
				Icon={TableChartIcon}
			/>
			<ElementoMenuControllato
				utente={utente}
				path={Routes_path.SCHEDE_CARICO}
				label="Schede Carico"
				gruppiAutorizzati={[
					PermissionTypes.OPERATORI_LOGISTICA,
					PermissionTypes.OPERATORI_LOGISTICA_LETTURA,
				]}
				open={open}
				selected={location.pathname == Routes_path.SCHEDE_CARICO}
				handleDrawerClose={handleDrawerClose}
				Icon={LocalShippingIcon}
			/>

			<ElementoMenuControllato
				utente={utente}
				path={Routes_path.PIANI_VIAGGIO}
				label="Piani di Viaggio"
				gruppiAutorizzati={[
					PermissionTypes.OPERATORI_LOGISTICA,
					PermissionTypes.OPERATORI_LOGISTICA_LETTURA,
					PermissionTypes.AUTISTI,
				]}
				open={open}
				selected={location.pathname == Routes_path.PIANI_VIAGGIO}
				handleDrawerClose={handleDrawerClose}
				Icon={ShareLocationIcon}
			/>

			<ElementoMenuControllato
				utente={utente}
				path={Routes_path.PIANO_LAVORAZIONI}
				label="Piano Lavorazioni"
				gruppiAutorizzati={[
					PermissionTypes.PIANIFICATORI,
					PermissionTypes.PIANIFICATORI_LETTURA,
					PermissionTypes.OPERATORI_MACELLO,
					PermissionTypes.SCHEDULATORI_LAVORAZIONI,
					PermissionTypes.SCHEDULATORI_ESTERNI,
				]}
				open={open}
				selected={location.pathname == Routes_path.PIANO_LAVORAZIONI}
				handleDrawerClose={handleDrawerClose}
				Icon={FormatListNumberedIcon}
			/>
			<ElementoMenuControllato
				utente={utente}
				path={Routes_path.CONSUNTIVO_VIVO}
				label="Consuntivi Vivo"
				gruppiAutorizzati={[
					PermissionTypes.OPERATORI_LOGISTICA,
					PermissionTypes.OPERATORI_LOGISTICA_LETTURA,
				]}
				open={open}
				selected={location.pathname == Routes_path.CONSUNTIVO_VIVO}
				handleDrawerClose={handleDrawerClose}
				Icon={AppRegistrationIcon}
			/>
			<ElementoMenuControllato
				utente={utente}
				path={Routes_path.SCHEDA_MACELLO}
				label="Schede Macello"
				gruppiAutorizzati={[PermissionTypes.OPERATORI_MACELLO]}
				open={open}
				selected={location.pathname == Routes_path.SCHEDA_MACELLO}
				handleDrawerClose={handleDrawerClose}
				Icon={ContentPasteIcon}
			/>

			<ElementoMenuControllato
				utente={utente}
				path={Routes_path.CONSUNTIVO_LAVORAZIONI}
				label="Consuntivo Lavorazioni"
				gruppiAutorizzati={[PermissionTypes.OPERATORI_MACELLO]}
				open={open}
				selected={location.pathname == Routes_path.CONSUNTIVO_LAVORAZIONI}
				handleDrawerClose={handleDrawerClose}
				Icon={AllInboxIcon}
			/>
			<ElementoMenuControllato
				utente={utente}
				path={Routes_path.PESATURA_PALLET}
				label="Pesatura Pallet"
				gruppiAutorizzati={[PermissionTypes.OPERATORI_INCASSETTAMENTO]}
				open={open}
				selected={location.pathname == Routes_path.PESATURA_PALLET}
				handleDrawerClose={handleDrawerClose}
				Icon={BalanceIcon}
			/>

			{/* 
			<ElementoMenuControllato
				utente={utente}
				path="/rese"
				label="Rese"
				gruppiAutorizzati={[PermissionTypes.COMMITTENTI]}
				open={open}
				selected={location.pathname == "/rese"}
				handleDrawerClose={handleDrawerClose}
				Icon={BalanceIcon}
			/> */}

			<ElementoMenuControllato
				utente={utente}
				path={Routes_path.REPORT_VETERINARIO}
				label="Report Veterinario"
				gruppiAutorizzati={[PermissionTypes.VETERINARI]}
				open={open}
				selected={location.pathname == Routes_path.REPORT_VETERINARIO}
				handleDrawerClose={handleDrawerClose}
				Icon={MedicalServicesIcon}
			/>

			<Divider />

			<ElementoMenuControllato
				utente={utente}
				path={Routes_path.DOCUMENTI_TRASPORTO}
				label="Documenti di Trasporto"
				gruppiAutorizzati={[
					PermissionTypes.OPERATORI_LOGISTICA,
					PermissionTypes.OPERATORI_LOGISTICA_LETTURA,
				]}
				open={open}
				selected={location.pathname == Routes_path.DOCUMENTI_TRASPORTO}
				handleDrawerClose={handleDrawerClose}
				Icon={WarehouseIcon}
			/>
			<ElementoMenuControllato
				utente={utente}
				path={Routes_path.REPORT_PALLET}
				label="Report Bancali"
				gruppiAutorizzati={[
					PermissionTypes.OPERATORI_LOGISTICA,
					PermissionTypes.OPERATORI_LOGISTICA_LETTURA,
				]}
				open={open}
				selected={location.pathname == Routes_path.REPORT_PALLET}
				handleDrawerClose={handleDrawerClose}
				Icon={Grid4x4Icon}
			/>

			<Divider />

			<ElementoMenuControllato
				utente={utente}
				path={Routes_path.REPORT_COMMITTENTI}
				label="Report Kg Committente"
				gruppiAutorizzati={[
					PermissionTypes.PIANIFICATORI,
					PermissionTypes.PIANIFICATORI_LETTURA,
				]}
				open={open}
				selected={location.pathname == Routes_path.REPORT_COMMITTENTI}
				handleDrawerClose={handleDrawerClose}
				Icon={ScaleIcon}
			/>
			<ElementoMenuControllato
				utente={utente}
				path="/reportistica/report_autisti"
				label="Presenze Autisti"
				gruppiAutorizzati={[
					PermissionTypes.OPERATORI_LOGISTICA,
					PermissionTypes.OPERATORI_LOGISTICA_LETTURA,
				]}
				open={open}
				selected={location.pathname == "/reportistica/report_autisti"}
				handleDrawerClose={handleDrawerClose}
				Icon={DepartureBoardIcon}
			/>

			<ElementoMenuControllato
				utente={utente}
				path="/reportistica/report_iolepec"
				label="Report costi Iolepec"
				gruppiAutorizzati={[
					PermissionTypes.OPERATORI_LOGISTICA,
					PermissionTypes.OPERATORI_LOGISTICA_LETTURA,
				]}
				open={open}
				selected={location.pathname == Routes_path.REPORT_IOLEPEC}
				handleDrawerClose={handleDrawerClose}
				Icon={PriceCheckIcon}
			/>

			<ElementoMenuControllato
				utente={utente}
				path={Routes_path.MANUTENZIONE_MEZZI}
				label="Manutenzioni Iolepec"
				gruppiAutorizzati={[
					PermissionTypes.OPERATORI_LOGISTICA,
					PermissionTypes.OPERATORI_LOGISTICA_LETTURA,
				]}
				open={open}
				selected={location.pathname == Routes_path.MANUTENZIONE_MEZZI}
				handleDrawerClose={handleDrawerClose}
				Icon={BuildCircleIcon}
			/>
			{utenteAutorizzato(utente, [
				PermissionTypes.PIANIFICATORI,
				// PermissionTypes.PIANIFICATORI_LETTURA,
				PermissionTypes.PRODUTTORI,
			]) && <Divider>{open && "Produttori"}</Divider>}

			<ElementoMenuControllato
				utente={utente}
				path={Routes_path.UNDER_CONSTRUCITON}
				label="Dashboard Produttore"
				gruppiAutorizzati={[
					PermissionTypes.PIANIFICATORI,
					// PermissionTypes.PIANIFICATORI_LETTURA,
					PermissionTypes.PRODUTTORI,
				]}
				open={open}
				selected={location.pathname == Routes_path.UNDER_CONSTRUCITON}
				handleDrawerClose={handleDrawerClose}
				Icon={DashboardTwoToneIcon}
			/>
			<ElementoMenuControllato
				utente={utente}
				path={Routes_path.LOTTI_PRODUTTORE}
				label="Lotti Produttore"
				gruppiAutorizzati={[
					PermissionTypes.PIANIFICATORI,
					// PermissionTypes.PIANIFICATORI_LETTURA,
					PermissionTypes.PRODUTTORI,
				]}
				open={open}
				selected={location.pathname == Routes_path.LOTTI_PRODUTTORE}
				handleDrawerClose={handleDrawerClose}
				Icon={ListAltTwoToneIcon}
			/>
			<ElementoMenuControllato
				utente={utente}
				path={Routes_path.UNDER_CONSTRUCITON}
				label="Pianif. Allevamenti"
				gruppiAutorizzati={[
					PermissionTypes.PIANIFICATORI,
					// PermissionTypes.PIANIFICATORI_LETTURA,
					PermissionTypes.PRODUTTORI,
				]}
				open={open}
				selected={location.pathname == Routes_path.UNDER_CONSTRUCITON}
				handleDrawerClose={handleDrawerClose}
				Icon={AddLocationAltTwoToneIcon}
			/>

			{utenteAutorizzato(utente, [
				PermissionTypes.PIANIFICATORI,
				PermissionTypes.COMMITTENTI,
			]) && <Divider>{open && "Committenti"}</Divider>}
			<ElementoMenuControllato
				utente={utente}
				path={Routes_path.DASHBOARD_COMMITTENTI}
				label="Dashboard Committente"
				gruppiAutorizzati={[
					PermissionTypes.PIANIFICATORI,
					PermissionTypes.PIANIFICATORI_LETTURA,
					PermissionTypes.COMMITTENTI,
				]}
				open={open}
				selected={location.pathname == Routes_path.DASHBOARD_COMMITTENTI}
				handleDrawerClose={handleDrawerClose}
				Icon={DashboardIcon}
			/>

			<ElementoMenuControllato
				utente={utente}
				path={Routes_path.LOTTI_COMMITTENTE}
				label="Lotti Committente"
				gruppiAutorizzati={[
					PermissionTypes.PIANIFICATORI,
					PermissionTypes.PIANIFICATORI_LETTURA,
					PermissionTypes.COMMITTENTI,
				]}
				open={open}
				selected={location.pathname == Routes_path.LOTTI_COMMITTENTE}
				handleDrawerClose={handleDrawerClose}
				Icon={ListAltIcon}
			/>
			<ElementoMenuControllato
				utente={utente}
				path={Routes_path.ALLEVAMENTI_COMMITTENTE}
				label="Allevamenti Committente"
				gruppiAutorizzati={[
					PermissionTypes.PIANIFICATORI,
					PermissionTypes.PIANIFICATORI_LETTURA,
					PermissionTypes.COMMITTENTI,
				]}
				open={open}
				selected={location.pathname == Routes_path.ALLEVAMENTI_COMMITTENTE}
				handleDrawerClose={handleDrawerClose}
				Icon={AgricultureIcon}
			/>
			<ElementoMenuControllato
				utente={utente}
				path={Routes_path.PALLET_COMMITTENTE}
				label="Report Bancali Committente"
				gruppiAutorizzati={[
					PermissionTypes.OPERATORI_LOGISTICA,
					PermissionTypes.OPERATORI_LOGISTICA_LETTURA,
					PermissionTypes.PIANIFICATORI,
					PermissionTypes.PIANIFICATORI_LETTURA,
					PermissionTypes.COMMITTENTI,
				]}
				open={open}
				selected={location.pathname == Routes_path.PALLET_COMMITTENTE}
				handleDrawerClose={handleDrawerClose}
				Icon={Grid4x4Icon}
			/>

			<Divider />
			<ElementoMenuControllato
				utente={utente}
				path={Routes_path.UTENTI_ESTERNI}
				label="Utenti Esterni"
				gruppiAutorizzati={[
					PermissionTypes.PIANIFICATORI,
					PermissionTypes.PIANIFICATORI_LETTURA,
				]}
				open={open}
				selected={location.pathname == Routes_path.UTENTI_ESTERNI}
				handleDrawerClose={handleDrawerClose}
				Icon={PeopleIcon}
			/>
			{(utente.is_staff || utente.is_superuser) && (
				<ListItem
					component="a"
					target="_blank"
					rel="noopener"
					href={process.env.API_URL + "/admin"}
					button
					key="Admin_be"
					sx={styleListItem}
				>
					<ListItemIcon style={{ minWidth: "auto" }}>
						<ManageAccountsIcon
							className={
								location.pathname == process.env.API_URL + "/admin"
									? classes.iconSelected
									: ""
							}
							onClick={mobileHandleDrawerClose}
						/>
					</ListItemIcon>
					{open && (
						<ListItemText
							style={{ marginLeft: 12, fontStyle: "italic" }}
							primary={"Admin"}
							className={
								location.pathname == process.env.API_URL + "/admin"
									? classes.iconSelected
									: ""
							}
							onClick={mobileHandleDrawerClose}
						/>
					)}
				</ListItem>
			)}
		</StyledList>
	);
};

export default SideMenu;
