import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../../redux/store";
import NavigazioneMesiToolbar from "../../../common/dateUtils/NavigazioneMesiToolbar";

// import { DATA_IOLEPEC_CORRENTE } from "../../../../api/apiUtils";
import {
	fetchReportAutistiIolepec,
	fetchReportCamionsIolepec,
	ReportCamionsIolepec,
	ReportAutistiCamionState,
	ReportAutistiIolepec,
	ListaBIViaggiAutistiCamionMese,
	fetchListaBIViaggiAutistiCamionMese,
} from "../reportAutistiCamionSlice";
import { tabellaListaBIViaggiAutistiCamionMese } from "./tabellaListaBIViaggiAutistiCamionMese";
import { CSVLink, CSVDownload } from "react-csv";

import { ErrorStatusTypes } from "../../../common/errorsDeclarations";

import { DateTime } from 'luxon';

import {
	RecuperaDataCorrente,
	FissaDataCorrente,
} from "../../../common/dateUtils/RecuperaDataCorrente";

import IolepecCamionMeseTable from "./IolepecCamionMeseTable";
import IolepecAutistiMeseTable from "./IolepecAutistiMeseTable";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress from "@mui/material/LinearProgress";
// import DateToday from "../../../common/dateUtils/DateToday";
import Grid from "@mui/material/Grid2";
import { Button } from "@mui/material";
import {convertToDateTime} from "../../../common/dateUtils/convertToDateTime";

const ReportIolepecMesePage = () => {
	
	const dispatch = useAppDispatch();

	const reportAutistiCamionState: ReportAutistiCamionState = useSelector<
		RootState,
		ReportAutistiCamionState
	>((state) => state.reportAutistiCamionState.reportAutistiCamion);

	const reportCamionsIolepec: ReportCamionsIolepec[] = useSelector<
		RootState,
		ReportCamionsIolepec[]
	>((state) => state.reportAutistiCamionState.reportCamionsIolepec);

	const reportAutistiIolepec: ReportAutistiIolepec[] = useSelector<
		RootState,
		ReportAutistiIolepec[]
	>((state) => state.reportAutistiCamionState.reportAutistiIolepec);

	const listaBIViaggiAutistiCamionMese: ListaBIViaggiAutistiCamionMese[] =
		useSelector<RootState, ListaBIViaggiAutistiCamionMese[]>(
			(state) => state.reportAutistiCamionState.listaBIViaggiAutistiCamionMese
		);

	type LocationState = {
		giornoMese: any;
	};
	const location = useLocation();
	const recupera_data_corrente = RecuperaDataCorrente(location);

	// const recupera_data_corrente = () => {
	// 	if (location.state) {
	// 		const { giornoMese } = location.state as LocationState;
	// 		if (giornoMese) {
	// 			return giornoMese;
	// 		}
	// 	}
	// 	const dataReportIolepecCorrente = localStorage.getItem(
	// 		DATA_IOLEPEC_CORRENTE
	// 	);
	// 	if (!!dataReportIolepecCorrente) {
	// 		return new Date(dataReportIolepecCorrente);
	// 	}
	// 	return DateToday();
	// };

	const [data, setData] = React.useState<DateTime | null>(
		recupera_data_corrente
	);
	const [refresh, setRefresh] = React.useState<boolean>(false);

	useEffect(() => {
		data && FissaDataCorrente(data);

		// localStorage.setItem(
		// 	DATA_IOLEPEC_CORRENTE,
		// 	convertToDateTime(data)?.setLocale("it").toFormat("yyyy-MM-dd")
		// );
		const data_da = convertToDateTime(data)?.startOf('month').toJSDate();
		const data_a = convertToDateTime(data)?.endOf('month').toJSDate();
		dispatch(fetchReportCamionsIolepec({ data_da, data_a }));
		dispatch(fetchReportAutistiIolepec({ data_da, data_a }));
		!!data_da &&  dispatch(fetchListaBIViaggiAutistiCamionMese(data_da));
	}, [data, refresh]);

	return (
		<>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={
					reportAutistiCamionState.errorsStack.status ==
					ErrorStatusTypes.PENDING
					// ||
					// lavorazioni.errorsStack.status == ErrorStatusTypes.PENDING
				}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
			<NavigazioneMesiToolbar
				titolo={"Report Costi Iolepec Mensile"}
				setData={setData}
				data={data}
				refresh={() => {
					setRefresh(true);
				}}
			/>

			<Grid container spacing={1} sx={{ marginTop: 1 }}>
				<Grid size={{ xs:12, sm:6, md:6, lg:6 }}>
					<IolepecCamionMeseTable reportCamionsIolepec={reportCamionsIolepec} />
				</Grid>
				<Grid size={{  xs:12, sm:6, md:6 }}>
					<IolepecAutistiMeseTable
						reportAutistiIolepec={reportAutistiIolepec}
					/>

					<Button variant="contained" sx={{ mt: 2, color: "#333" }}>
						{listaBIViaggiAutistiCamionMese.length > 0 ? (
							<CSVLink
								data={tabellaListaBIViaggiAutistiCamionMese(
									listaBIViaggiAutistiCamionMese
								)}
								style={{ color: "#222" }}
							>
								Dati x BI
							</CSVLink>
						) : (
							<LinearProgress color="inherit" />
						)}
					</Button>
				</Grid>
			</Grid>
		</>
	);
};

export default ReportIolepecMesePage;
