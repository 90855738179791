import React, { FC } from "react";

import moment from "moment";

import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";

import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import ReplayIcon from "@mui/icons-material/Replay";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DateRangeIcon from "@mui/icons-material/DateRange";
import CardMui from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { styled, Box } from "@mui/system";
import Button from "@mui/material/Button";

const Card = styled(CardMui)(({ theme }) => ({
	margin: theme.spacing(1),
	marginBottom: 1,
	// },
}));

interface NavigazioneMesiToolbarProps {
	titolo?: string;
	data?: Date;
	setData: (data: Date) => void;
	refresh: () => void;
	Toolbar?: FC;
}

const NavigazioneMesiToolbar = ({
	titolo,
	data,
	setData,
	refresh,
	Toolbar,

	...props
}: NavigazioneMesiToolbarProps) => {
	// moment.locale("it");

	const cambiaMese = (avanti: boolean) => {
		data &&
			(avanti
				? setData(new Date(data.setMonth(data.getMonth() + 1)))
				: setData(new Date(data.setMonth(data.getMonth() - 1))));
	};

	const [datePickerView, setDatePickerView] = React.useState<boolean>(false);
	const changeData = (newValue: Date) => {
		setData(newValue);
	};

	return (
		<Grid container spacing={1}>
			<Grid item xs={12}>
				<Card>
					<Stack
						direction="row"
						justifyContent="center"
						alignItems="center"
						mr={2}
						spacing={2}
					>
						<Typography variant="h4" component="div">
							{titolo}
						</Typography>
						<Typography variant="h4" component="div">
							<IconButton
								aria-label="GiornoPrecedente"
								onClick={() => {
									cambiaMese(false);
								}}
							>
								<ArrowBackIosIcon />
							</IconButton>
							{moment(data).format("MMMM YYYY")}{" "}
							<Button
								sx={{ p: 2, ml: 2, mr: 2 }}
								onClick={() => {
									setDatePickerView(!datePickerView);
								}}
							>
								<DateRangeIcon />
							</Button>
							<IconButton
								color="primary"
								aria-label="upload picture"
								component="span"
								onClick={refresh}
							>
								<ReplayIcon />
							</IconButton>
							<IconButton
								aria-label="GiornoSuccessivo"
								onClick={() => {
									cambiaMese(true);
								}}
							>
								<ArrowForwardIosIcon />
							</IconButton>
						</Typography>
						{/* {datePickerView && <WeekPicker data={data} setData={setData} />} */}
						{datePickerView && (
							<StaticDatePicker
								displayStaticWrapperAs="desktop"
								openTo="day"
								value={data}
								onChange={(newValue) => {
									newValue && changeData(new Date(newValue));
								}}
								renderInput={(params) => <TextField {...params} />}
							/>
						)}
						{Toolbar && <Toolbar />}
					</Stack>
				</Card>
			</Grid>
		</Grid>
	);
};

export default NavigazioneMesiToolbar;
