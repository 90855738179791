import React from "react";
import {
    Box,
    Button,
    Card,
    CardContent,
    Typography,
} from "@mui/material";
import {useSelector} from "react-redux";
import {RootState, useAppDispatch,} from "../../../redux/store"; // Modifica il percorso in base alla tua struttura
import {
    savePallet,
    Pallet,
    PesaturaPalletsState,
    PackingList
} from "./pesaturaPalletSlice"; // Definisci questa action nel tuo slice
import {Lavorazione,} from "../lavorazioni/lavorazioniSlice";
import { Lotto} from "../pianificazione/pianificazioneSlice";
import { DateTime } from 'luxon';
import PalletForm from "./PallerForm";
import Stack from "@mui/material/Stack";
import AddIcon from "@mui/icons-material/Add";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import {convertToDateTime} from "../../common/dateUtils/convertToDateTime";


interface PalletsPageProps {
    packingList?: PackingList;
    lavorazione: Lavorazione;
    lotto: Lotto;
    handleClose: () => void
}

const PalletsPage: React.FC<PalletsPageProps> = ({packingList, lavorazione, lotto, handleClose}) => {
    const dispatch = useAppDispatch();

    // // Recupera i dati dei pallet
    // useEffect(() => {
    //     lavorazione.id && dispatch(fetchLavorazionePallets({lavorazione_id: lavorazione.id}));
    // }, [dispatch, lavorazione.id]);


    const pallets: PesaturaPalletsState = useSelector<RootState, PesaturaPalletsState>(
        (state) => state.pesaturaPalletState.pallets
    );

    // Dati dei pallet dallo store (puoi sostituire con il selettore corretto)
    // const palletList = useSelector((state: RootState) => state.pallet.pallets);

    const handleAddPallet = () => {
        // Logica per aggiungere un nuovo pallet
        const pallet: Pallet = {
            lotto: lotto.id || 0,
            packing_list: packingList?.id || 0,
            lavorazione: lavorazione.id || 0,
            numero_cassette: lavorazione.cassette_x_pallet,
            data_produzione: convertToDateTime()?.setLocale("it").toFormat("yyyy-MM-dd"),
            data_scadenza: DateTime.now().plus({ days: 12 }).setLocale("it").toFormat("yyyy-MM-dd"),
        };
        lavorazione.id && dispatch(savePallet({palletToSave: pallet}));
    };

    return (
        <Box sx={{p: 3}}>
            <Card key={packingList?.id || "default-key"} sx={{m: 3}}>
                <CardContent>
                    <Stack direction="row" justifyContent="space-between" spacing={2} sx={{mb: 3}}>
                        <Typography variant="h3">
                            Packing List n°{packingList?.id} del {convertToDateTime(packingList?.data)?.setLocale("it").toFormat('dd/MM/yyyy')}
                        </Typography>
                        <Box>
                            <Button startIcon={<KeyboardReturnIcon/>} variant="contained" color="primary"
                                    onClick={handleClose}>
                                Esci
                            </Button>
                        </Box>
                    </Stack>
                    <Stack direction="row" justifyContent="space-between" spacing={3}>
                        <Box>
                            <Typography
                                variant="h6">Stato: {packingList?.stato == "A" ? "Aperta" : "?????"}</Typography>
                            <Typography variant="h6">Numero Pallet: {packingList?.num_pallets}</Typography>
                            <Typography variant="h6">Numero Cassette: {packingList?.numero_cassette}</Typography>
                            <Typography variant={"h6"}>Peso Netto: {packingList?.peso_netto}</Typography>
                        </Box>
                        <Box>
                            <Typography variant="h4">{`Lotto: ${lotto.codice_tracciabilita || "N/A"}`}</Typography>
                            <Typography
                                variant="h6">{`Produttore: ${lotto.produttore_pack?.nome || "N/A"}`} del {convertToDateTime(lotto.data_lavorazione)?.setLocale("it").toFormat('dd/MM/yyyy')}</Typography>
                        </Box>

                        <Box>
                            <Typography variant="h5">
                                <strong>Lavorazione:</strong>{" "}
                                {lavorazione.codice_articolo_committente} {lavorazione.descrizione} {lavorazione.destinazione}
                            </Typography>
                            <Typography variant="body1">
                                <strong>Numero Cassette Richieste:</strong>{" "}
                                {lavorazione.numero_cassette_richieste || 0}
                            </Typography>
                        </Box>
                    </Stack>


                </CardContent>
            </Card>

                    <Card elevation={2}>
                        <CardContent>
                            <Button startIcon={<AddIcon/>} variant="contained" color="primary"
                                    onClick={handleAddPallet}>
                                Aggiungi Pallet
                            </Button>
                        </CardContent>
                        <CardContent>
                            {pallets.results.map((pallet, index) => (
                                <PalletForm key={`pallet_${pallet.id}_${index}`} pallet={pallet}
                                            consentiModifica={pallet.lavorazione == lavorazione.id}/>
                            ))}
                        </CardContent>
                    </Card>

            {/* Pulsante per aggiungere un nuovo pallet */}
            <Stack direction='row' alignContent="center" spacing={2} sx={{mt: 3, textAlign: "center"}}>


            </Stack>
        </Box>
    );
};

export default PalletsPage;
