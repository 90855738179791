import {handleResponse, handleError, headerset} from "../apiUtils";
import moment from "moment";
import {Pallet, PickingList} from "components/main/pesaturaPallet/pesaturaPalletSlice";
import EventiManutenzionePage from "components/main/manutenzione_mezzi/EventiManutenzionePage";


export function fetchLavorazionePallets(
    lavorazione_id : number,
) {
    const url = `${process.env.API_URL}/api/mes/lavorazione_pallets/${lavorazione_id}/`;

    return fetch(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: headerset().Authorization,
        },
    })
        .then(handleResponse)
        .catch(handleError);
}

export function getPallet(lavorazione_id : number, pallet_id: number) {
    const url = `${process.env.API_URL}/api/mes/lavorazione_pallets/${lavorazione_id}/${pallet_id}/`;

    return fetch(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: headerset().Authorization,
        },
    })
        .then(handleResponse)
        .catch(handleError);
}

export function savePallet(
    palletToSave: Pallet
) {
    const pallet = {
        ...palletToSave,
        data_produzione: palletToSave.data_produzione
            ? moment(palletToSave.data_produzione).format(
                "YYYY-MM-DD"
            )
            : null,
        data_fine_pianificata: palletToSave.data_scadenza
            ? moment(palletToSave.data_scadenza).format(
                "YYYY-MM-DD"
            )
            : null,
    };
    let url:string
    if (pallet.id) {
        url = `${process.env.API_URL}/api/mes/lavorazione_pallets/${palletToSave.lavorazione}/${palletToSave.id}/`;
    }
    else {
        url = `${process.env.API_URL}/api/mes/lavorazione_pallets/${palletToSave.lavorazione}/`;
    }


    return fetch(url, {
        method: palletToSave.id ? "PUT" : "POST", // PUT to update when id already exists.
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: headerset().Authorization,
        },
        body: JSON.stringify({
            ...pallet,
            // Parse authorId to a number (in case it was sent as a string).
            // id: parseInt(progetto.id, 10),
        }),
    })
        .then(handleResponse)
        .catch(handleError);
}

// ###################################################
// ###################################################

export function deletePallet(
    palletToDelete: Pallet
) {
    const url = `${process.env.API_URL}/api/mes/lavorazione_pallets/${palletToDelete.lavorazione}/${palletToDelete.id}/`;

    return fetch(url, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: headerset().Authorization,
        },
    })
        .then(handleResponse)
        .catch(handleError);
}


export function fetchPickinList(
    committente_id : number,
    stato?: string,
    data_da?: Date,
    data_a?: Date,
    numeroRecord?: number,
    page?: number,
    search?: string
) {
    let url = `${process.env.API_URL}/api/mes/pickin_list_committente/${committente_id}/`;
    if (
        stato != undefined ||
        data_da != undefined ||
        data_a != undefined ||
        numeroRecord != undefined ||
        search != undefined ||
        page != undefined
    ) {
        url += "?";
    }

    if (stato != undefined) {
        url += `stato=${stato}&`;
    }
    if (data_da != undefined) {
        url += `data_da=${moment(data_da).format("YYYY-MM-DD")}&`;
    }
    if (data_a != undefined) {
        url += `data_a=${moment(data_a).format("YYYY-MM-DD")}&`;
    }
    if (numeroRecord != undefined) {
        url += `numeroRecord=${numeroRecord}&`;
    }
    if (page != undefined) {
        url += `page=${page}&`;
    }
    if (search != undefined) {
        url += `search=${search}&`;
    }
    return fetch(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: headerset().Authorization,
        },
    })
        .then(handleResponse)
        .catch(handleError);
}


export function savePickinList(
    pickinListToSave: PickingList
) {
    const pallet = {
        ...pickinListToSave,
        data: pickinListToSave.data
            ? moment(pickinListToSave.data).format(
                "YYYY-MM-DD"
            )
            : null,
    };
    let url:string
    if (pallet.id) {
        url = `${process.env.API_URL}/api/mes/pickin_list/${pickinListToSave.id}/`;
    }
    else {
        url = `${process.env.API_URL}/api/mes/pickin_list_committente/${pickinListToSave.committente}/`;
    }


    return fetch(url, {
        method: pickinListToSave.id ? "PUT" : "POST", // PUT to update when id already exists.
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: headerset().Authorization,
        },
        body: JSON.stringify({
            ...pallet,
            // Parse authorId to a number (in case it was sent as a string).
            // id: parseInt(progetto.id, 10),
        }),
    })
        .then(handleResponse)
        .catch(handleError);
}
