import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Parametri } from "../anagrafiche/anagraficheSlice";
import { RootState, useAppDispatch } from "../../../redux/store";

import { useForm, SubmitHandler } from "react-hook-form";
import {
	Allegato,
	TipoDocumento,
	uploadAllegato,
	AllegatiState,
} from "./allegatiSlice";
import ReactHookFormSelect from "../../common/ReactHookFormSelect";

import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid2";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import MenuItem from "@mui/material/MenuItem";

import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import { Typography } from "@mui/material";

interface AllegatiUploadFormProps {
	allegato: Allegato;
	tipiDocumento: TipoDocumento[];
	uploadAllegato: (allegato: Allegato, selectedFile: File | null) => void;
	uploading: boolean;
}

const AllegatiUploadForm = ({
	allegato,
	tipiDocumento,
	uploadAllegato,
	uploading,
}: AllegatiUploadFormProps) => {
	const {
		handleSubmit,
		register,
		formState: { errors, isDirty },
		setValue,
		watch,
		getValues,
		control,
		setError,
		clearErrors,
		reset,
	} = useForm<Allegato>();

	const [selectedFile, setSelectedFile] = useState<File | null>(null);

	const listaCampi = Object.keys(allegato) as Array<keyof Allegato>;

	useEffect(() => {
		listaCampi.map((field) => {
			setValue(field, allegato[field]);
		});
	}, [
		allegato.id,
		listaCampi.forEach((field) => {
			allegato[field];
		}),
		// allegato.errorsStack?.status == ErrorStatusTypes.SUCCESS,
	]);

	const onSubmit: SubmitHandler<Allegato> = (allegato) => {
		selectedFile && uploadAllegato(allegato, selectedFile);
	};

	useEffect(() => {
		selectedFile && uploading == false && setSelectedFile(null);
		selectedFile && uploading == false && setValue("descrizione", "");
	}, [uploading]);

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			{selectedFile ? (
				<Grid
					container
					sx={{ flexGrow: 1, mb: 1 }}
					rowSpacing={1}
					columnSpacing={1}
					justifyContent="center"
					alignItems="flex-start"
				>
					<Grid size={{ xs:6, sm:4, md:2 }}>
						<ReactHookFormSelect
							name="tipo_documento"
							label="tipo documento"
							register={register}
							control={control}
							watch={watch}
							variant="outlined"
							required={true}
							error={errors?.tipo_documento?.message}
							sx={{ width: "100%" }}
						>
							{tipiDocumento.map((option) => {
								return (
									<MenuItem key={option.id} value={option.id}>
										{option.nome}
									</MenuItem>
								);
							})}
						</ReactHookFormSelect>
					</Grid>
					<Grid size={{ xs:6, sm:4, md:3 }}>
						<TextField
							label="descrizione"
							// disabled={schedaCarico.numero_capi_pianificati}
							variant="outlined"
							InputLabelProps={{
								shrink: true,
							}}
							{...register("descrizione")}
							error={!!errors?.descrizione}
							helperText={errors?.descrizione?.message}
							autoComplete={"off"}
							sx={{ width: "100%" }}
						/>
					</Grid>
					<Grid size={{ xs:6, sm:4, md:3 }}>
						<ButtonGroup
							orientation="vertical"
							aria-label="vertical outlined button group"
						>
							<Button
								variant="outlined"
								// component="label"
								// onClick={submitForm}
								type="submit"
								size="small"
								disabled={uploading}
							>
								{uploading ? (
									<>
										in caricamento
										<CircularProgress size={12} />
									</>
								) : (
									<>{"Avvia Upload " + selectedFile.name}</>
								)}
							</Button>
							<Button
								variant="outlined"
								// component="label"
								onClick={() => {
									setSelectedFile(null);
								}}
								size="small"
								disabled={uploading}
							>
								Annulla
							</Button>
						</ButtonGroup>
					</Grid>
				</Grid>
			) : (
				<Button variant="outlined" component="label" size="small">
					Carica File
					<input
						name="file"
						style={{ padding: "10px" }}
						type="file"
						onChange={(e) =>
							setSelectedFile(e.target.files && e.target.files[0])
						}
						hidden
					/>
				</Button>
			)}
		</form>
	);
};

export default AllegatiUploadForm;
