import React from "react";
import ReactPDF from "@react-pdf/renderer";
import { DateTime } from 'luxon';

import { Text, View, StyleSheet, Image } from "@react-pdf/renderer";

import { Veterinario } from "../veterinarioSlice";

import { Lotto } from "../../../pianificazione/pianificazioneSlice";
import { SchedaCarico } from "../../../schedeCarico/schedeCaricoSlice";
import { Parametri } from "../../../anagrafiche/anagraficheSlice";
import {convertToDateTime} from "../../../../common/dateUtils/convertToDateTime";
const logoIolepec = require("../../../../../../static/images/logo/logo-Iolepec-trasp.png");

// Create styles
const styles = StyleSheet.create({
	page: {
		backgroundColor: "#fff",
		paddingTop: 20,
	},
	section: {
		marginTop: 30,
		marginLeft: 25,
		marginRight: 25,
		padding: 5,
	},
	sectionIntestazione: {
		padding: 5,
		margin: 10,
		border: "2px",
		borderColor: "#777777",
		color: "#000000",
	},
	text: {
		fontSize: 12,
		fontStyle: "normal",
		whiteSpace: "wrap",
	},
	textIntestazione: {
		fontSize: 9,
		fontStyle: "normal",
		whiteSpace: "wrap",
		fontWeight: "bold",
		paddingLeft: 1,
		paddingRight: 1,
		paddingTop: 5,
		paddingBottom: 5,
	},
	textDati: {
		fontSize: 9,
		fontStyle: "normal",
		// fontWeight: 100,
		paddingLeft: 1,
		paddingRight: 1,
		paddingTop: 5,
		paddingBottom: 5,
	},
	sectionTableIntestazione: {
		flexDirection: "row",
		marginHorizontal: 20,
		marginTop: 20,
		// marginBottom: 10,
		borderTop: "1px",
		borderBottom: "1px",
		backgroundColor: "#E5E4E2",
	},
	sectionTableDati: {
		flexDirection: "row",
		marginHorizontal: 20,
	},
});

interface ModuloVeterinarioPdfProps {
	lottiGiorno: Veterinario[];
	parametroAggiustamento: number | undefined;
	totalePeso: number | undefined;
	totaleCapi: number | undefined;
	orarioFine: Date | undefined;
	// giornoSettimana: Date;
}

const ModuloVeterinarioPdf = ({
	lottiGiorno,
	parametroAggiustamento,
	totalePeso,
	totaleCapi,
	orarioFine,
}: // giornoSettimana,
ModuloVeterinarioPdfProps) => (
	<View
		style={{
			flexDirection: "column",
			justifyContent: "space-between",
			height: 410,
		}}
		// key={"disinfezione_schedaCarico_" + schedaCarico.id}
	>
		<View>
			<View>
				<View
					style={[
						styles.section,
						{
							flexDirection: "row",
							justifyContent: "center",
							// margin: 0,
							padding: 0,
							// paddingLeft: 0,
						},
					]}
				>
					<Text
						style={{
							color: "#000000",
							fontSize: 20,
						}}
					>
						{" Report Sanitario del " +
							convertToDateTime(lottiGiorno[0].data_lavorazione)?.setLocale("it").toFormat('dd/MM/yyyy')}
					</Text>
				</View>
			</View>
			<View style={styles.sectionTableIntestazione}>
				<Text style={[styles.textIntestazione, { width: "10%" }]}>
					Cod. Rintrac.
				</Text>
				<Text style={[styles.textIntestazione, { width: "12%" }]}>
					<Text style={{ fontFamily: "Times-Italic", fontSize: 8 }}>
						Allevatore
					</Text>
				</Text>
				<Text
					style={[
						styles.textIntestazione,
						{ width: "7%", textAlign: "center" },
					]}
				>
					Cod. ASL
				</Text>
				<Text
					style={[styles.textIntestazione, { width: "5%", textAlign: "right" }]}
				>
					N° Capi{"\n"}Ritirati
				</Text>
				<Text
					style={[
						styles.textIntestazione,
						{ width: "5%", textAlign: "center" },
					]}
				>
					Tipo
				</Text>
				<Text
					style={[styles.textIntestazione, { width: "5%", textAlign: "right" }]}
				>
					Peso{"\n"}partenza
				</Text>
				<Text
					style={[styles.textIntestazione, { width: "5%", textAlign: "right" }]}
				>
					Peso{"\n"}arrivo
				</Text>
				<Text
					style={[styles.textIntestazione, { width: "5%", textAlign: "right" }]}
				>
					P.medio arrivo
				</Text>
				<Text
					style={[
						styles.textIntestazione,
						{ width: "5%", textAlign: "center" },
					]}
				>
					Inizio mac.
				</Text>
				<Text
					style={[
						styles.textIntestazione,
						{ width: "4%", textAlign: "center" },
					]}
				>
					Fine mac.
				</Text>
				<Text
					style={[
						styles.textIntestazione,
						{ width: "3%", textAlign: "center" },
					]}
				>
					M.TI
				</Text>
				<Text
					style={[
						styles.textIntestazione,
						{ width: "3%", textAlign: "center" },
					]}
				>
					SC1
				</Text>
				<Text
					style={[
						styles.textIntestazione,
						{ width: "3%", textAlign: "center" },
					]}
				>
					SC2
				</Text>
				<Text
					style={[styles.textIntestazione, { width: "5%", textAlign: "right" }]}
				>
					Peso{"\n"}BST
				</Text>
				<Text
					style={[styles.textIntestazione, { width: "4%", textAlign: "right" }]}
				>
					N°{"\n"}BST
				</Text>

				<Text
					style={[styles.textIntestazione, { width: "5%", textAlign: "right" }]}
				>
					Peso{"\n"}TZ
				</Text>
				<Text
					style={[styles.textIntestazione, { width: "4%", textAlign: "right" }]}
				>
					N°{"\n"}TZ
				</Text>
				<Text
					style={[styles.textIntestazione, { width: "5%", textAlign: "right" }]}
				>
					Peso{"\n"}Macell.
				</Text>
				<Text
					style={[styles.textIntestazione, { width: "5%", textAlign: "right" }]}
				>
					N° Capi{"\n"}Lavorati
				</Text>
			</View>
			<View style={{ flexDirection: "column" }}>
				{lottiGiorno.map((lotto) => (
					<View style={styles.sectionTableDati}>
						<Text style={[styles.textDati, { width: "10%" }]}>
							{lotto.codice_tracciabilita}
						</Text>
						<Text style={[styles.textDati, { width: "12%" }]}>
							<Text style={{ fontFamily: "Times-Italic", fontSize: 8 }}>
								({lotto.allevamento_pack?.nome})
							</Text>
						</Text>
						<Text
							style={[styles.textDati, { width: "7%", textAlign: "center" }]}
						>
							{lotto.allevamento_pack?.codice_asl}
						</Text>
						<Text
							style={[styles.textDati, { width: "5%", textAlign: "right" }]}
						>
							{Intl.NumberFormat("it-IT").format(lotto.capi_ritirati || 0)}
						</Text>
						<Text
							style={[styles.textDati, { width: "5%", textAlign: "center" }]}
						>
							{lotto.tipo_capo_desc}
						</Text>
						<Text
							style={[styles.textDati, { width: "5%", textAlign: "right" }]}
						>
							{Intl.NumberFormat("it-IT").format(
								lotto.peso_lotto_partenza || 0
							)}
						</Text>
						<Text
							style={[styles.textDati, { width: "5%", textAlign: "right" }]}
						>
							{Intl.NumberFormat("it-IT").format(lotto.peso_lotto_arrivo || 0)}
						</Text>
						<Text
							style={[styles.textDati, { width: "5%", textAlign: "right" }]}
						>
							{Intl.NumberFormat("it-IT", {
								maximumFractionDigits: 2,
							}).format(
								(lotto.peso_lotto_arrivo || 0) / (lotto.capi_ritirati || 1)
							)}
						</Text>
						<Text
							style={[styles.textDati, { width: "5%", textAlign: "center" }]}
						>
							{lotto.data_ora_inizio_macello &&
								convertToDateTime(lotto.data_ora_inizio_macello)?.setLocale("it").toFormat('HH:mm')}
						</Text>
						<Text
							style={[styles.textDati, { width: "4%", textAlign: "center" }]}
						>
							{lotto.data_ora_fine_macello &&
								convertToDateTime(lotto.data_ora_fine_macello)?.setLocale("it").toFormat('HH:mm')}
						</Text>
						<Text
							style={[styles.textDati, { width: "3%", textAlign: "center" }]}
						>
							{Intl.NumberFormat("it-IT").format(lotto.numero_capi_morti || 0)}
						</Text>
						<Text
							style={[styles.textDati, { width: "3%", textAlign: "center" }]}
						>
							{Intl.NumberFormat("it-IT").format(lotto.numero_scarti_1 || 0)}
						</Text>
						<Text
							style={[styles.textDati, { width: "3%", textAlign: "center" }]}
						>
							{Intl.NumberFormat("it-IT").format(lotto.numero_scarti_2 || 0)}
						</Text>
						<Text
							style={[styles.textDati, { width: "5%", textAlign: "right" }]}
						>
							{Intl.NumberFormat("it-IT", {
								maximumFractionDigits: 0,
							}).format(
								(lotto.peso_casse_busto_lotto || 0) *
									(parametroAggiustamento ? parametroAggiustamento : 1)
							)}
						</Text>
						<Text
							style={[styles.textDati, { width: "4%", textAlign: "right" }]}
						>
							{Intl.NumberFormat("it-IT").format(lotto.num_busti_lotto || 0)}
						</Text>

						<Text
							style={[styles.textDati, { width: "5%", textAlign: "right" }]}
						>
							{Intl.NumberFormat("it-IT", {
								maximumFractionDigits: 0,
							}).format(
								(lotto.peso_casse_tz_lotto || 0) *
									(parametroAggiustamento ? parametroAggiustamento : 1)
							)}
						</Text>
						<Text
							style={[styles.textDati, { width: "4%", textAlign: "right" }]}
						>
							{Intl.NumberFormat("it-IT").format(lotto.num_tz_lotto || 0)}
						</Text>
						<Text
							style={[styles.textDati, { width: "5%", textAlign: "right" }]}
						>
							{Intl.NumberFormat("it-IT", {
								maximumFractionDigits: 0,
							}).format(
								((lotto.peso_casse_busto_lotto || 0) +
									(lotto.peso_casse_tz_lotto || 0)) *
									(parametroAggiustamento ? parametroAggiustamento : 1)
							)}
						</Text>
						<Text
							style={[styles.textDati, { width: "5%", textAlign: "right" }]}
						>
							{Intl.NumberFormat("it-IT").format(
								(lotto.num_busti_lotto || 0) +
									(lotto.num_tz_lotto || 0) +
									(lotto.numero_scarti_2 || 0)
							)}
						</Text>
					</View>
				))}
			</View>
			<View style={[styles.sectionTableIntestazione, { marginTop: 5 }]}>
				<Text style={[styles.textIntestazione, { width: "49.5%" }]}>
					TOTALI COMPLESSIVI
				</Text>
				<Text style={[styles.textIntestazione, { width: "40.5%" }]}>
					{"Ora fine - " + convertToDateTime(orarioFine)?.setLocale("it").toFormat('HH:mm')}
				</Text>

				<Text
					style={[styles.textIntestazione, { width: "5%", textAlign: "right" }]}
				>
					{Intl.NumberFormat("it-IT", {
						maximumFractionDigits: 0,
					}).format(
						(totalePeso || 0) *
							(parametroAggiustamento ? parametroAggiustamento : 1)
					)}
				</Text>
				<Text
					style={[styles.textIntestazione, { width: "5%", textAlign: "right" }]}
				>
					{Intl.NumberFormat("it-IT", {
						maximumFractionDigits: 0,
					}).format(totaleCapi || 0)}
				</Text>
			</View>
		</View>

		<Text break />
	</View>
);

export default ModuloVeterinarioPdf;
