import React from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { theme } from "../../../theme";
import { convertToDateTime } from "components/common/dateUtils/convertToDateTime";
import { RootState, useAppDispatch } from "../../../redux/store";
import { Routes_path } from "../../routerApp";
import {ErrorStatusTypes,} from "../../common/errorsDeclarations";
import { Utente } from "../../authentication/authenticationSlice";
import {
	PreferenzeListaEventiManutenzione,
	setPreferenzeListaEventiManutenzione,
} from "../../userPreference/userPreferenceSlice";
import { Camion } from "../anagrafiche/anagraficheSlice";
import {
	fetchEventiManutenzione,
	StatoEventoManutenzioneTypes,
	EventiManutenzioneState,
	resetEventoManutenzioneId,
} from "./manutenzioneMezziSlice";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import AddIcon from "@mui/icons-material/Add";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import Pagination from "@mui/material/Pagination";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ReplayIcon from "@mui/icons-material/Replay";
import {RecuperaDataCorrente,} from "../../common/dateUtils/RecuperaDataCorrente";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import {DateTime} from "luxon";


const EventiManutenzionePage = () => {
	const location = useLocation();

	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const recupera_data_corrente = RecuperaDataCorrente(location);

	type LocationState = {
		camionId?: number;
		statoId?: string;
		tipoArticoloId?: number;
	};

	const preferenzeListaEventiManutenzione = useSelector<
		RootState,
		PreferenzeListaEventiManutenzione
	>((state) => state.userPreferenceState.preferenzeListaEventiManutenzione);

	const eventiManutenzione = useSelector<RootState, EventiManutenzioneState>(
		(state) => state.manutenzioneMezziState.eventiManutenzione
	);

	const utente = useSelector<RootState, Utente>(
		(state) => state.authentication.utenteLogged
	);

	const camions: Camion[] = useSelector<RootState, Camion[]>((state) =>
		state.anagrafiche.camions.results.filter((c) => c.proprieta == true)
	);

	const [statoId, setStatoId] = useState<string | undefined>(
		location.state
			? (location.state as LocationState)?.statoId
			: preferenzeListaEventiManutenzione?.statoId
	);

	const [camionId, setCamionId] = useState<number | undefined>(
		location.state
			? (location.state as LocationState)?.camionId
			: preferenzeListaEventiManutenzione?.camionId
	);

	const [page, setPage] = useState<number>(
		preferenzeListaEventiManutenzione?.page || 1
	);
	const [numeroRecord, setNumeroRecord] = useState<number>(
		preferenzeListaEventiManutenzione?.numeroRecord || 10
	);
	const [search, setSearch] = useState<string | undefined>(undefined);

	const handleChangeNumeroRecord = (event: SelectChangeEvent) => {
		setNumeroRecord(Number(event.target.value));
	};

	// const clienti = useSelector<RootState, Cliente[]>(
	// 	(state) => state.clientiState.clienti.results
	// );
	const handlerPager = (event: React.ChangeEvent<unknown>, page: number) => {
		setPage(page);
	};

	const [refresh, setRefresh] = useState<boolean>(false);

	const [anchorElStatoFilter, setAnchorElStatoFilter] = useState(null);
	const [anchorElTipoArticoloFilter, setAnchorElTipoArticoloFilter] =
		useState(null);
	const [anchorElCamionFilter, setAnchorElCamionFilter] = useState(null);

	const handleStatoFilterClose = () => {
		setAnchorElStatoFilter(null);
		// onClose(selectedValue);
	};

	const handleTipoArticoloFilterClose = () => {
		setAnchorElTipoArticoloFilter(null);
		// onClose(selectedValue);
	};

	const handleCamionFilterClose = () => {
		setAnchorElCamionFilter(null);
		// onClose(selectedValue);
	};

	useEffect(() => {
		dispatch(
			fetchEventiManutenzione({
				stato: statoId,
				camion_id: camionId,
				numeroRecord: numeroRecord,
				page: page,
				search: search,
			})
		);
		dispatch(
			setPreferenzeListaEventiManutenzione({
				statoId: statoId,
				camionId: camionId,

				numeroRecord: numeroRecord,
				page: page,
			})
		);
		setRefresh(false);
	}, [statoId, camionId, page, numeroRecord, search, refresh]);

	useEffect(() => {
		setRefresh(false);
	}, [refresh]);

	const handlerCreateEventoManutenzione = () => {
		dispatch(resetEventoManutenzioneId());
		navigate(Routes_path.EVENTO_MANUTENZIONE);
	};

	return (
		<Box
			sx={{
				backgroundColor: "#fff",
				elevation: 20,
				// p: 2,
				"& .campo-editabile": {
					backgroundColor: "#bbbbbb11",
					background: "linear-gradient(to right bottom, #f5f7f6, #dce3e0)",
					// borderWidth: "5px",
					borderColor: "#ff91003d",
					// borderColor: "#0000003d",
					// borderStyle: "outset",
					borderWidth: 3,
					borderStyle: "ridge",
					color: "#1a3e72",
					fontWeight: "600",
				},
				"& .campo-errore": {
					backgroundColor: "rgba(124, 3, 0, 0.3)",
					color: "#ffffff",
					fontWeight: "600",
				},
			}}
		>
			<List sx={{ mt: 2 }}>
				<ListItem>
					<ButtonGroup
						variant="outlined"
						aria-label="outlined button group"
						sx={{ mb: 2 }}
					>
						<Button
							color="primary"
							startIcon={<ReplayIcon />}
							onClick={() => setRefresh(true)}
						>
							aggiorna
						</Button>

						<Button
							color="primary"
							startIcon={<AddIcon />}
							// disabled={
							// 	!utenteAutorizzato(utente, [
							// 		PermissionTypes.GESTORI_ITER,
							// 		// PermissionTypes.CLIENTI,
							// 		// PermissionTypes.OPERATORI,
							// 		PermissionTypes.PROGETTISTI,
							// 	])
							// }
							onClick={(event) => {
								event.preventDefault();
								handlerCreateEventoManutenzione();
							}}
						>
							Aggiungi nuovo Evento di Manutenzione
						</Button>
					</ButtonGroup>
					{/* <NavigazioneMesiToolbar
						titolo={"Report Pratiche Fatturabili"}
						setData={setData}
						data={data}
						refresh={() => {
							setRefresh(true);
						}}
					/> */}
				</ListItem>
				<ListItemText
					key={"Lista_saldo_pallet_committente"}
					sx={{ backgroundColor: "#1b75b1", color: "#ddd", mb: 1 }}
				>
					<Grid container spacing={{ xs: 0.5, md: 1, lg: 2 }} sx={{ px: 2 , width:"100%"}}>
						<Grid size={{ xs:3, sm:3, md:2, lg:1 }}>
							<Typography variant="h5">Ordine</Typography>
						</Grid>
						<Grid size={{ xs:3, sm:3, md:2, lg:2 }}>
							<Stack direction="row" spacing={1}>
								<Typography variant="h5" gutterBottom>
									Camion
								</Typography>
								<IconButton
									sx={{
										color: !!camionId ? theme.coloriFiltro.attivo : "inherit",
									}}
									onClick={(event: any) =>
										setAnchorElCamionFilter(event.currentTarget)
									}
								>
									<FilterAltIcon sx={{ mt: -0.5 }} />
								</IconButton>
							</Stack>
							<Box style={{ display: "flex", justifyContent: "space-between" }}>
								<Menu
									id="simple-menu"
									anchorEl={anchorElCamionFilter}
									keepMounted
									open={Boolean(anchorElCamionFilter)}
									onClose={handleCamionFilterClose}
								>
									{camions.map((camion) => (
										// return (
										<MenuItem
											key={`camion_${camion.id}`}
											onClick={() => {
												setCamionId(camion.id);
												setAnchorElTipoArticoloFilter(null);
											}}
											sx={{
												backgroundColor:
													camion.id == camionId ? "#4aaa" : "inherit",
											}}
										>
											{camion.targa} {camion.nome}
										</MenuItem>

										// );
									))}

									<MenuItem
										onClick={() => {
											setCamionId(undefined);
											setAnchorElCamionFilter(null);
										}}
										sx={{
											backgroundColor: !camionId ? "#4aaa" : "inherit",
										}}
									>
										tutte
									</MenuItem>
								</Menu>
							</Box>
						</Grid>
						<Grid size={{ xs:3, sm:3, md:2, lg:1 }}>
							<Stack direction="row" spacing={1}>
								<Stack direction="row" spacing={1}>
									<Typography variant="h5">Stato</Typography>
									<IconButton
										sx={{
											color: statoId ? theme.coloriFiltro.attivo : "inherit",
										}}
										onClick={(event: any) =>
											setAnchorElStatoFilter(event.currentTarget)
										}
									>
										<FilterAltIcon sx={{ mt: -0.5 }} />
									</IconButton>
								</Stack>
								<Box
									style={{ display: "flex", justifyContent: "space-between" }}
								>
									<Menu
										id="simple-menu"
										anchorEl={anchorElStatoFilter}
										keepMounted
										open={Boolean(anchorElStatoFilter)}
										onClose={handleStatoFilterClose}
									>
										{Object.entries(StatoEventoManutenzioneTypes).map(
											([key, value]) => {
												return (
													<MenuItem
														key={`stato_${key}`}
														value={key}
														onClick={() => {
															setStatoId(key);
															setAnchorElStatoFilter(null);
														}}
														sx={{
															backgroundColor:
																key == statoId ? "#4aaa" : "inherit",
														}}
													>
														{value}
													</MenuItem>
												);
											}
										)}

										<MenuItem
											onClick={() => {
												setStatoId(undefined);
												setAnchorElStatoFilter(null);
											}}
											sx={{
												backgroundColor: !statoId ? "#4aaa" : "inherit",
											}}
										>
											tutti
										</MenuItem>
									</Menu>
								</Box>
							</Stack>
						</Grid>

						<Grid size={{ xs:3, sm:3, md:2, lg:2 }}>
							<Typography variant="h5" gutterBottom>
								Data pianificata
							</Typography>
						</Grid>
						<Grid size={{ xs:3, sm:3, md:2, lg:2 }}>
							<Typography variant="h5" gutterBottom>
								Data Manutenzione
							</Typography>
						</Grid>
						<Grid size={{ xs:3, sm:3, md:2, lg:3 }}>
							<Typography variant="h5" gutterBottom>
								Intervento
							</Typography>
						</Grid>
						<Grid size={{ xs:3, sm:3, md:2, lg:1 }}>
							<Typography variant="h5" gutterBottom>
								Costo
							</Typography>
						</Grid>
					</Grid>
				</ListItemText>

				<Backdrop
					sx={{
						color: "#fff",
						zIndex: (theme) => theme.zIndex.drawer + 1,
					}}
					open={
						eventiManutenzione.errorsStack.status == ErrorStatusTypes.PENDING
						// errorsStack.status == ErrorStatusTypes.PENDING
					}
				>
					<CircularProgress color="inherit" />
				</Backdrop>

				{eventiManutenzione.results?.map((eventoManutenzione, index) => (
					<ListItemButton
						key={`eventoManutenzione_${index}`}
						component={Link}
						to={Routes_path.EVENTO_MANUTENZIONE + eventoManutenzione.id}
						sx={{
							color: "black",
							textDecoration: "none",
							backgroundColor: index % 2 === 0 ? "#fff" : "#f2f2f2",
						}}
					>
						<Grid container spacing={{ xs: 0.5, md: 1, lg: 2 }} sx={{ mb: 1, width:"100%"}}>
							<Grid size={{ xs:3, sm:3, md:2, lg:1 }}>
								{eventoManutenzione.id}
							</Grid>

							<Grid size={{ xs:3, sm:3, md:2, lg:2 }}>
								<Typography key={index} variant="body1">
									{
										camions.find(
											(camion) => camion.id == eventoManutenzione.camion
										)?.targa
									}{" "}
									{
										camions.find(
											(camion) => camion.id == eventoManutenzione.camion
										)?.nome
									}
								</Typography>
							</Grid>
							<Grid size={{ xs:3, sm:3, md:2, lg:1 }}>
								<Typography key={index} variant="body1">
									{eventoManutenzione.stato
										? StatoEventoManutenzioneTypes[
												eventoManutenzione.stato as keyof typeof StatoEventoManutenzioneTypes
										  ]
										: "Stato non definito"}
								</Typography>
							</Grid>
							<Grid size={{ xs:3, sm:3, md:2, lg:2 }}>
								<Typography key={index} variant="body1">
									{convertToDateTime(eventoManutenzione.data_inizio_pianificata)
										?.setLocale("it")
										.toFormat("dd/MM/yyyy")}
									{/* .toFormat("DD")} */}
								</Typography>
							</Grid>
							<Grid size={{ xs:3, sm:3, md:2, lg:2 }}>
								<Typography key={index} variant="body1">
									{convertToDateTime(
										eventoManutenzione.data_inizio_manutenzione
									)
										?.setLocale("it")
										.toFormat("dd/MM/yyyy")}
									{/* .toFormat("DD")} */}
								</Typography>
							</Grid>

							<Grid size={{ xs:3, sm:3, md:2, lg:3 }}>
								<Typography noWrap>{eventoManutenzione.descrizione}</Typography>
							</Grid>
							<Grid size={{ xs:3, sm:3, md:2, lg:1 }}>
								<Typography noWrap>
									{eventoManutenzione.costo &&
										Intl.NumberFormat("it-IT").format(
											eventoManutenzione.costo
										) + " €"}
								</Typography>
							</Grid>
						</Grid>
					</ListItemButton>
				))}

				<ListItem
					sx={{
						alignContent: "right",
						width: "100%",
						mt: 2,
						borderTopStyle: "solid",
						borderTopWidth: 1,
					}}
				>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						spacing={2}
						sx={{ width: "100%" }}
					>
						<Pagination
							count={eventiManutenzione.num_pages}
							onChange={handlerPager}
							page={page}
						/>
						<Typography noWrap>
							Totale:{"  "}
							{eventiManutenzione.count}
						</Typography>

						<TextField
							id="input-with-icon-textfield"
							// label="TextField"
							InputProps={{
								startAdornment: (
									<InputAdornment position="end">
										<SearchIcon />
									</InputAdornment>
								),
							}}
							variant="standard"
							value={search}
							onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
								setSearch(event.target.value);
							}}
						/>
						<FormControl>
							<InputLabel id="demo-simple-select-label">Record</InputLabel>
							<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								value={numeroRecord.toString()}
								label="Age"
								onChange={handleChangeNumeroRecord}
								variant="outlined"
							>
								<MenuItem value={10}>10</MenuItem>
								<MenuItem value={20}>20</MenuItem>
								<MenuItem value={50}>50</MenuItem>
							</Select>
						</FormControl>
					</Stack>
				</ListItem>
			</List>
		</Box>
	);
};

export default EventiManutenzionePage;
