import React from "react";
import {useState, useEffect} from "react";
import {theme} from "../../../../theme";
import {Link} from "react-router-dom";

import {
    Resa,
    switchConfermaResa,
    getResa,
    saveResa,
} from "../../reportistica/rese/reseSlice";
import ResaManualeForm from "./ResaManualeForm";
import {RootState, useAppDispatch} from "../../../../redux/store";
import {useSelector} from "react-redux";
import {Routes_path} from "../../../routerApp";

import {
    ErrorStatusTypes,
    ErrorsStack,
} from "../../../common/errorsDeclarations";
import PrintIcon from "@mui/icons-material/Print";
import BrushIcon from "@mui/icons-material/Brush";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import ScaricaCSVLavorazioniButton from "./ScaricaCSVLavorazioniButton";

interface ResaLottoCardProps {
    resa_id: number;
}

const ResaLottoCard = ({resa_id}: ResaLottoCardProps) => {
    const dispatch = useAppDispatch();

    const [ritoccaResa, setRitoccaResa] = useState<boolean>(false);
    useEffect(() => {
        if (resa_id) {
            dispatch(getResa(resa_id));
        }
    }, []);

    const handlerSwitchConfermaResa = () => {
        dispatch(switchConfermaResa(resa_id));
    };

    const resa = useSelector<RootState, Resa | undefined>((state) =>
        state.reseState.rese.results.find((resa) => resa.id == resa_id)
    );

    const handlerSaveResa = (percentuale_resa?: number | null) => {
        if (resa) {
            const resaToSave = {
                ...resa,
                percentuale_resa_manuale: percentuale_resa && percentuale_resa / 100,
            };
            dispatch(saveResa(resaToSave));
        }
    };

    const handlerRipristinaResaCalcolata = () => {
        if (resa) {
            const resaToSave = {
                ...resa,
                // percentuale_resa_manuale: resa.calcolo_percentuale_resa,
                percentuale_resa_manuale: null,
            };
            dispatch(saveResa(resaToSave));
        }
    };

    const errorsStack = useSelector<RootState, ErrorsStack>(
        (state) => state.reseState.rese.errorsStack
    );

    const resa_percentuale: number = resa?.percentuale_resa
        ? resa.percentuale_resa * 100
        : 0;

    return resa ? (
        <Card key={"Box_Resa_card_" + resa.id} sx={{my: 2}}>
            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={errorsStack.status == ErrorStatusTypes.PENDING}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
            <CardContent>
                <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                    spacing={4}
                    sx={{mb: 1}}
                >
                    <FormControlLabel
                        value={resa.resa_confermata}
                        control={
                            <Switch
                                color="success"
                                checked={resa.resa_confermata}
                                onChange={handlerSwitchConfermaResa}
                            />
                        }
                        label={
                            resa.resa_confermata ? "resa confermata" : "resa da confermare"
                        }
                        labelPlacement="start"
                    />
                    <Stack direction={"column"} spacing={2}>
                        <Button
                            sx={{marginTop: 2, marginBottom: 0}}
                            startIcon={<PrintIcon/>}
                            variant="contained"
                            component={Link}
                            to={{
                                pathname: Routes_path.STAMPA_RESA + resa?.id,
                            }}
                            disabled={!resa.resa_confermata}
                        >
                            Stampa resa
                        </Button>
                        <ScaricaCSVLavorazioniButton resa_id={resa_id} resa_confermata={resa.resa_confermata}/>

                    </Stack>
                </Stack>
                <Box>
                    {/* {lotto.scheda_macello && ( */}
                    <Grid
                        container
                        spacing={2}
                        justifyContent="flex-start"
                        sx={{mt: 1}}
                    >
                        <Grid item xs={6} sm={4} md={2}>
                            <Typography sx={{mb: 0.5}} color="text.secondary">
                                Peso Partenza
                            </Typography>
                            <Divider/>
                            <Typography variant="body1">
                                {resa.peso_lotto_partenza &&
                                    Intl.NumberFormat("it-IT").format(resa.peso_lotto_partenza) +
                                    " kg"}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={4} md={2}>
                            <Typography sx={{mb: 0.5}} color="text.secondary">
                                Peso Arrivo
                            </Typography>
                            <Divider/>
                            <Typography variant="body1">
                                {resa.peso_lotto_arrivo &&
                                    Intl.NumberFormat("it-IT").format(resa.peso_lotto_arrivo) +
                                    " kg"}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={2}
                        justifyContent="flex-start"
                        sx={{mt: 1}}
                    >
                        <Grid item xs={6} sm={4} md={2}>
                            <Typography sx={{mb: 0.5}} color="text.secondary">
                                Capi morti
                            </Typography>
                            <Divider/>

                            <Typography variant="body1">
                                {resa.numero_capi_morti &&
                                    Intl.NumberFormat("it-IT").format(resa.numero_capi_morti)}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={4} md={2}>
                            <Typography sx={{mb: 0.5}} color="text.secondary">
                                Scarto 1
                            </Typography>
                            <Divider/>

                            <Typography variant="body1">
                                {resa.numero_scarti_1 &&
                                    Intl.NumberFormat("it-IT").format(resa.numero_scarti_1)}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={4} md={2}>
                            <Typography sx={{mb: 0.5}} color="text.secondary">
                                Scarto 2
                            </Typography>
                            <Divider/>

                            <Typography variant="body1">
                                {resa.numero_scarti_2 &&
                                    Intl.NumberFormat("it-IT").format(resa.numero_scarti_2)}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={2}
                        justifyContent="flex-start"
                        sx={{mt: 1}}
                    >
                        <Grid item xs={6} sm={4} md={2}>
                            <Typography sx={{mb: 0.5}} color="text.secondary">
                                N° Busti
                            </Typography>
                            <Divider/>

                            <Typography variant="body1">
                                {resa.num_busti_lotto &&
                                    Intl.NumberFormat("it-IT").format(resa.num_busti_lotto)}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={4} md={2}>
                            <Typography sx={{mb: 0.5}} color="text.secondary">
                                N° casse Busto
                            </Typography>
                            <Divider/>

                            <Typography variant="body1">
                                {resa.num_casse_busto_lotto &&
                                    Intl.NumberFormat("it-IT").format(resa.num_casse_busto_lotto)}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={4} md={2}>
                            <Typography sx={{mb: 0.5}} color="text.secondary">
                                Peso Busti
                            </Typography>
                            <Divider/>

                            <Typography variant="body1">
                                {resa.peso_casse_busto_lotto &&
                                    Intl.NumberFormat("it-IT", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    }).format(resa.peso_casse_busto_lotto) + " kg"}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={2}
                        justifyContent="flex-start"
                        sx={{mt: 1}}
                    >
                        <Grid item xs={6} sm={4} md={2}>
                            <Typography sx={{mb: 0.5}} color="text.secondary">
                                {" "}
                                N° TZ
                            </Typography>
                            <Divider/>

                            <Typography variant="body1">
                                {resa.num_tz_lotto &&
                                    Intl.NumberFormat("it-IT").format(resa.num_tz_lotto)}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={4} md={2}>
                            <Typography sx={{mb: 0.5}} color="text.secondary">
                                N° casse TZ
                            </Typography>
                            <Divider/>

                            <Typography variant="body1">
                                {resa.num_casse_tz_lotto &&
                                    Intl.NumberFormat("it-IT").format(resa.num_casse_tz_lotto)}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={4} md={2}>
                            <Typography sx={{mb: 0.5}} color="text.secondary">
                                Peso TZ
                            </Typography>
                            <Divider/>

                            <Typography variant="body1">
                                {resa.peso_casse_tz_lotto &&
                                    Intl.NumberFormat("it-IT", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    }).format(resa.peso_casse_tz_lotto) + " kg"}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={2}
                        justifyContent="flex-start"
                        sx={{mt: 1}}
                    >
                        <Grid item xs={6} sm={4} md={2}>
                            <Typography
                                sx={{mb: 0.5}}
                                color="text.secondary"
                                fontWeight={500}
                            >
                                Totale capi
                            </Typography>
                            <Divider/>

                            <Typography variant="body1" fontWeight={500}>
                                {resa.numero_capi_resa &&
                                    Intl.NumberFormat("it-IT").format(resa.numero_capi_resa)}
                            </Typography>
                        </Grid>

                        <Grid item xs={6} sm={4} md={2}>
                            <Typography sx={{mb: 0.5}} color="text.secondary">
                                Peso medio resa
                            </Typography>
                            <Divider/>

                            <Typography variant="body1">
                                {resa.peso_medio_resa &&
                                    Intl.NumberFormat("it-IT").format(resa.peso_medio_resa) +
                                    " kg"}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={4} md={2}>
                            <Stack
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                spacing={2}
                                sx={{mt: 1}}
                            >
                                <Typography sx={{mb: 0.5}} color="text.secondary">
                                    Percentuale resa
                                </Typography>
                                <Divider/>

                                <Typography variant="body1">
                                    {resa.percentuale_resa &&
                                        Intl.NumberFormat("it-IT", {
                                            maximumFractionDigits: 2,
                                            minimumFractionDigits: 2,
                                        }).format(resa_percentuale) + "%"}
                                </Typography>
                                <IconButton
                                    color="primary"
                                    aria-label="upload picture"
                                    component="span"
                                    onClick={() => setRitoccaResa(!ritoccaResa)}
                                >
                                    <BrushIcon/>
                                </IconButton>
                            </Stack>

                            {ritoccaResa && (
                                <ResaManualeForm
                                    percentuale_resa={
                                        resa.percentuale_resa && resa.percentuale_resa * 100
                                    }
                                    calcolo_percentuale_resa={resa.calcolo_percentuale_resa}
                                    savePercentualeResaManuale={handlerSaveResa}
                                    ripristinaPercentualeResaCalcolata={
                                        handlerRipristinaResaCalcolata
                                    }
                                />
                            )}
                        </Grid>
                    </Grid>
                </Box>
            </CardContent>
            <Typography sx={{ml: 2, color: theme.palette.primary.main}}>
                {resa.dichiarazione_aggiustamento_resa}
            </Typography>
        </Card>
    ) : (
        <></>
    );
};

export default ResaLottoCard;
