import { handleResponse, handleError, headerset } from "../apiUtils";
import moment from "moment";
import { Lotto } from "../../components/main/pianificazione/pianificazioneSlice";



const baseUrl = (lotto_id?: number | null) => {
	if (lotto_id) {
		return process.env.API_URL + "/api/pianificazione/lotti/" + lotto_id + "/";
	} else {
		return process.env.API_URL + "/api/pianificazione/lotti/";
	}
};

export function fetchLotti(
	data_da?: Date,
	data_a?: Date,
	lotti_semplificati?: boolean,
	ultimoAggiornamento?: string
) {
	let url = baseUrl();
	// moment(data_da).format("YYYY-MM-DD");
	if (data_da || data_a || lotti_semplificati || ultimoAggiornamento) {
		url += `?`;
	}
	if (data_da) {
		url += `data_da=${moment(data_da).format("YYYY-MM-DD")}&`;
	}
	if (data_a) {
		url += `data_a=${moment(data_a).format("YYYY-MM-DD")}&`;
	}
	if (lotti_semplificati) {
		url += `lotti_semplificati=true&`;
	}
	if (ultimoAggiornamento != undefined && ultimoAggiornamento != "") {
		url += "ultimo_aggiornamento=" + ultimoAggiornamento;
	}
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function getLotto(lottoId: number) {
	let url = baseUrl(lottoId);
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function saveLotto(lottoToSave: Lotto) {
	const lotto = {
		...lottoToSave,
		data_lavorazione: moment(lottoToSave.data_lavorazione).format("YYYY-MM-DD"),
		data_ordine_cliente: lottoToSave.data_ordine_cliente
			? moment(lottoToSave.data_ordine_cliente).format("YYYY-MM-DD")
			: null,
	};

	return fetch(baseUrl(lottoToSave.id), {
		method: lottoToSave.id ? "PUT" : "POST", // PUT to update when id already exists.
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
		body: JSON.stringify({
			...lotto,
			// Parse authorId to a number (in case it was sent as a string).
			// id: parseInt(progetto.id, 10),
		}),
	})
		.then(handleResponse)
		.catch(handleError);
}

export function deleteLotto(lottoToDelete: Lotto) {
	return fetch(baseUrl(lottoToDelete.id), {
		method: "DELETE",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function creaLottiStandard(data?: Date) {
	let url = process.env.API_URL + "/api/pianificazione/generapianificazione/";
	return fetch(url, {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
		body: JSON.stringify({ data: moment(data).format("YYYY-MM-DD") }),
	})
		.then(handleResponse)
		.catch(handleError);
}

export function consolidaOrdinamentoLotti(
	nuovoOrdinamento: { id: number | null; ordinamento: number }[]
) {
	let url =
		process.env.API_URL + "/api/pianificazione/consolida_ordinamento_lotti/";
	return fetch(url, {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
		body: JSON.stringify(nuovoOrdinamento),
	})
		.then(handleResponse)
		.catch(handleError);
}


export async function getCriticitaReparti(data: Date) {
	const url = process.env.API_URL + "/api/pianificazione/criticita_reparti/" +  moment(data).format("YYYY-MM-DD") + "/";
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}