import {createSlice, createAsyncThunk, PayloadAction} from "@reduxjs/toolkit";
import {
    ErrorStatusTypes,
    ErrorsStack,
    parseErrorMessage,
} from "../../common/errorsDeclarations";

import {
    Camion,
    Autista,
    fetchCamions,
    fetchAutisti,
} from "../anagrafiche/anagraficheSlice";
import {
    Lotto,
    getLotto,
    PianificazioneStrutturaState,
} from "../pianificazione/pianificazioneSlice";
import * as palletsApi from "../../../api/pallets/palletsApi";
import moment from "moment";
import {toast} from "react-toastify";
// import { useDispatch } from "react-redux";

moment.locale("it");


export interface PickingList {
    id?: number | null;
    committente: number;
    stato: "A" | "C" | "S";
    data?: Date | string;
    numero_cassette?: number;
    peso_netto?: number;
    peso_lordo?: number;
    errorsStack?: ErrorsStack;
}

export interface PickingListState {
    count: number;
    page: number;
    num_pages: number;
    next?: URL;
    previous?: URL;
    results: PickingList[];
    errorsStack: ErrorsStack;
}


export interface Pallet {
    id?: number | null;
    lavorazione: number;
    lotto?: number;
    numero_cassette?: number;
    peso_netto?: number;
    peso_lordo?: number;
    data_produzione?: Date | string;
    data_scadenza?: Date | string;
    errorsStack?: ErrorsStack;
}

export interface PesaturaPalletsState {
    count: number;
    page: number;
    num_pages: number;
    next?: URL;
    previous?: URL;
    results: Pallet[];
    errorsStack: ErrorsStack;
}

// export interface LottoCorrente extends Lotto {
//   errorsStack: ErrorsStack;
// }

export interface PesaturaPalletsStrutturaState {
    pallets: PesaturaPalletsState;
    pickingListState: PickingListState;
    nuovopalletId?: number;
}

const initialState: PesaturaPalletsStrutturaState = {
    pallets: {
        count: 0,
        page: 0,
        num_pages: 0,
        next: undefined,
        previous: undefined,
        results: [],
        errorsStack: {status: ErrorStatusTypes.OK},
    },
    pickingListState: {
        count: 0,
        page: 0,
        num_pages: 0,
        next: undefined,
        previous: undefined,
        results: [],
        errorsStack: {status: ErrorStatusTypes.OK},
    },
};

export const fetchLavorazionePallets = createAsyncThunk(
    "PesaturaPallet/fetchLavorazionePallets",
    async (parametri: {
        lavorazione_id: number;
    }) => {
        return await palletsApi.fetchLavorazionePallets(
            parametri.lavorazione_id,
        );
    }
);

export const getPallet = createAsyncThunk(
    "PesaturaPallet/getPallet",
    async (parametri: { lavorazione_id: number; pallet_id: number }) => {
        return await palletsApi.getPallet(
            parametri.lavorazione_id,
            parametri.pallet_id
        );
    }
);

export const savePallet = createAsyncThunk(
    "PesaturaPallet/savePallet",
    async (parametri: { palletToSave: Pallet }, thunkApi) => {
        return await palletsApi.savePallet(
            parametri.palletToSave
        ).then((response) => {
            parametri.palletToSave.lavorazione &&
            thunkApi.dispatch(fetchLavorazionePallets({lavorazione_id: parametri.palletToSave.lavorazione}));
            return response;
        });
    }
);

export const deletePallet = createAsyncThunk(
    "PesaturaPallet/deletePallet",
    async (parametri: { palletToDelete: Pallet }, thunkApi) => {
        return await palletsApi.deletePallet(
            parametri.palletToDelete
        ).then(
            (response) => {
                parametri.palletToDelete.lavorazione &&
                thunkApi.dispatch(fetchLavorazionePallets({lavorazione_id: parametri.palletToDelete.lavorazione}));
                return response;
            }
        )
            ;
    }
);


export const fetchPickinList = createAsyncThunk(
    "PesaturaPallet/fetchPickinList",
    async (parametri: {
        committente_id: number;
        stato?: string;
        data_da?: Date;
        data_a?: Date;
        numeroRecord?: number;
        page?: number,
        search?: string
    }) => {
        return await palletsApi.fetchPickinList(
            parametri.committente_id,
            parametri.stato,
            parametri.data_da,
            parametri.data_a,
            parametri.numeroRecord,
            parametri.page,
            parametri.search
        );
    }
);

export const savePickinList = createAsyncThunk(
    "PesaturaPallet/savePickinList",
    async (parametri: { pickinListToSave: PickingList }, thunkApi) => {
        return await palletsApi.savePickinList(
            parametri.pickinListToSave
        ).then((response) => {
                parametri.pickinListToSave.committente &&
                thunkApi.dispatch(fetchPickinList({committente_id: parametri.pickinListToSave.committente, stato: "A"}));
                return response;

            }
        );
    }
);


export const palletSlice = createSlice({
    name: "palletState",
    initialState,
    reducers: {
        resetPalletId: (state) => {
            state.nuovopalletId = undefined;
        },
    },
    extraReducers: (builder) => {
        // fetch Schede Macelo
        builder.addCase(fetchLavorazionePallets.pending, (state, action) => {
            state.pallets.errorsStack = {
                status: ErrorStatusTypes.PENDING,
            };
        });
        builder.addCase(fetchLavorazionePallets.fulfilled, (state, action) => {
            state.pallets = action.payload;

            // riotorna l'array ordinato per id
            state.pallets.results.sort((a, b) => {
                if (a.id && b.id) {
                    return b.id - a.id;
                } else {
                    return 0;
                }
            });

            state.pallets.errorsStack = {status: ErrorStatusTypes.OK};
        });
        builder.addCase(fetchLavorazionePallets.rejected, (state, action) => {
            state.pallets.errorsStack = parseErrorMessage(action.error);
        });

        // get
        builder.addCase(getPallet.pending, (state, action) => {
            state.pallets.errorsStack = {
                status: ErrorStatusTypes.PENDING,
            };
        });
        builder.addCase(getPallet.fulfilled, (state, action) => {
            state.pallets.results =
                state.pallets.results.filter((Pallet) => {
                    Pallet.id != action.payload.id;
                });
            state.pallets.results.push(action.payload);
            // riotorna l'array ordinato per id
            state.pallets.results.sort((a, b) => {
                if (a.id && b.id) {
                    return b.id - a.id;
                } else {
                    return 0;
                }
            });
            state.pallets.errorsStack = {status: ErrorStatusTypes.OK};
        });
        builder.addCase(getPallet.rejected, (state, action) => {
            state.pallets.errorsStack = parseErrorMessage(action.error);
        });

        // save save Pallet
        builder.addCase(savePallet.pending, (state, action) => {
            state.pallets.errorsStack = {
                status: ErrorStatusTypes.PENDING,
            };
        });
        builder.addCase(savePallet.fulfilled, (state, action) => {
            state.pallets.results = state.pallets.results.map(
                (Pallet) => {
                    if (Pallet.id == action.payload.id) {
                        return action.payload;
                    } else {
                        return Pallet;
                    }
                }
            );
            state.pallets.errorsStack = {
                status: ErrorStatusTypes.SUCCESS,
            };


        });
        builder.addCase(savePallet.rejected, (state, action) => {
            toast.error("Errore:" + action?.error?.message || "");

            state.pallets.results = state.pallets.results.map(
                (Pallet) => {
                    if (Pallet.id == action.meta.arg?.palletToSave?.id) {
                        return {
                            ...Pallet,
                            errorsStack: parseErrorMessage(action.error),
                        };
                    } else {
                        return Pallet;
                    }
                }
            );
            state.pallets.errorsStack = parseErrorMessage(action.error);
        });

        // cancella Pallet
        builder.addCase(deletePallet.pending, (state, action) => {
            state.pallets.errorsStack = {
                status: ErrorStatusTypes.PENDING,
            };
        });
        builder.addCase(deletePallet.fulfilled, (state, action) => {
            state.pallets.errorsStack = {
                status: ErrorStatusTypes.SUCCESS,
            };
            state.pallets.results =
                state.pallets.results.filter(
                    (Pallet) => Pallet.id != action.meta.arg.palletToDelete.id
                );
            toast.success(action.payload.message || "Scheda macello cancellata.");
        });
        builder.addCase(deletePallet.rejected, (state, action) => {
            state.pallets.errorsStack = parseErrorMessage(action.error);
        });

        // fetch PickinList
        builder.addCase(fetchPickinList.pending, (state, action) => {
            state.pickingListState.errorsStack = {
                status: ErrorStatusTypes.PENDING,
            };
        });
        builder.addCase(fetchPickinList.fulfilled, (state, action) => {
            state.pickingListState = action.payload;
            state.pickingListState.errorsStack = {
                status: ErrorStatusTypes.SUCCESS,
            };
        });
        builder.addCase(fetchPickinList.rejected, (state, action) => {
            state.pickingListState.errorsStack = parseErrorMessage(action.error);
        });


        // save save savePickinList
        builder.addCase(savePickinList.pending, (state, action) => {
            state.pickingListState.errorsStack = {
                status: ErrorStatusTypes.PENDING,
            };
        });

        builder.addCase(savePickinList.fulfilled, (state, action) => {

            state.pickingListState.results = state.pickingListState.results.filter(
                (pickinList) => pickinList.id !== action.payload.id
            );

            state.pickingListState.results.push({
                ...action.payload,
                errorsStack: {status: ErrorStatusTypes.SUCCESS},
            });
            // riotorna l'array ordinato per data
            state.pickingListState.results.sort((a, b) => {
                return (
                    new Date(a?.data || "").getTime() -
                    new Date(b?.data || "").getTime()

                );
            });
            state.pickingListState.errorsStack = {
                status: ErrorStatusTypes.SUCCESS,
            };
        });

        builder.addCase(savePickinList.rejected, (state, action) => {
            toast.error("Errore:" + action?.error?.message || "");

            state.pickingListState.results = state.pickingListState.results.map(
                (pickinList) => {
                    if (pickinList.id == action.meta.arg?.pickinListToSave?.id) {
                        return {
                            ...pickinList,
                            errorsStack: parseErrorMessage(action.error),
                        };
                    } else {
                        return pickinList;
                    }
                }
            );
            state.pallets.errorsStack = parseErrorMessage(action.error);
        });
    },
});

// Action creators are generated for each case reducer function
export const {resetPalletId} = palletSlice.actions;

export const pesaturaPalletReducer = palletSlice.reducer;
