import React from "react";
import {DateTime, Duration} from 'luxon';

import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";

import {Resa} from "../../reportistica/rese/reseSlice";
import {UtenteLogged} from "../../../authentication/authenticationSlice";
import {getColore} from "../../pianificazione/utility/lottoUtility";

import LottoCorrispettiviTrasportoVivoCard from "../../bolle_fatture/corrispettivi/LottoCorrispettiviTrasportoVivoCard";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import {CausaleTrasportoTypes} from "../../bolle_fatture/bolleFattureSlice";
import {GiudizioColoreTypes, UniformitaTypes} from "../../schedeMacello/schedeMacelloSlice";
import {convertToDateTime} from "../../../common/dateUtils/convertToDateTime";

// Create styles

interface LottoCommittenteIntestazioneProps {
    resa: Resa;
    utente: UtenteLogged;
}

const LottoCommittenteIntestazione = ({
                                          resa,
                                          utente,
                                      }: // giornoSettimana,
                                      LottoCommittenteIntestazioneProps) => (
    <Grid container spacing={4}>
        <Grid size={{ xs:12, sm:12, md:12, lg:5 }}>
            <Grid container spacing={2}>
                <Grid size={{md:6}}>
                    <Typography variant="subtitle2">Cod. Rintrac.</Typography>
                    <Typography variant="h6" gutterBottom>
                        {resa.codice_tracciabilita}
                    </Typography>
                </Grid>
                <Grid size={{ xs:3, sm:3, md:3, lg:3 }}>
                    {resa.peso_lotto_partenza != null && resa.peso_lotto_partenza > 0 && (
                        <>
                            <Typography variant="subtitle2">Peso partenza</Typography>
                            <Typography variant="h6" gutterBottom>
                                {Intl.NumberFormat("it-IT").format(
                                    resa.peso_lotto_partenza || 0
                                )}{" "}
                                kg
                            </Typography>
                        </>
                    )}
                </Grid>
                <Grid size={{ xs:3, sm:3, md:3, lg:3 }}>
                    {resa.peso_lotto_arrivo != undefined &&
                        resa.peso_lotto_arrivo != null &&
                        resa.peso_lotto_arrivo > 0 && (
                            <>
                                <Typography variant="subtitle2">Peso arrivo</Typography>
                                <Typography variant="h6" gutterBottom>
                                    {Intl.NumberFormat("it-IT").format(
                                        resa.peso_lotto_arrivo || 0
                                    )}{" "}
                                    kg
                                </Typography>
                            </>
                        )}
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid size={{md:6}}>
                    {resa.allevamento_pack && (
                        <>
                            <Typography variant="subtitle2">
                                Allevatore (Cod. ASL: {resa.allevamento_pack?.codice_asl})
                            </Typography>
                            <Typography variant="h6" gutterBottom>
                                {resa.allevamento_pack?.nome &&
                                resa.allevamento_pack?.nome.length > 33
                                    ? resa.allevamento_pack?.nome.substr(0, 32) + "..."
                                    : resa.allevamento_pack?.nome}
                            </Typography>
                        </>
                    )}
                </Grid>
                <Grid size={{ xs:3, sm:3, md:3, lg:3 }}>
                    {resa.data_ora_arrivo_lotto && (
                        <>
                            <Typography variant="subtitle2">Arrivo macello</Typography>
                            <Typography variant="h6" gutterBottom>
                                {resa.data_ora_arrivo_lotto &&
                                    convertToDateTime(resa.data_ora_arrivo_lotto)?.setLocale("it").toFormat('HH:mm')}
                            </Typography>
                        </>
                    )}
                </Grid>

                <Grid size={{ xs:3, sm:3, md:3, lg:3 }}>
                    {resa.sosta_macello != null && resa.sosta_macello > 0 && (
                        <>
                            <Typography variant="subtitle2">Sosta macello</Typography>
                            <Typography variant="h6" gutterBottom>
                                {
                                    Math.floor(Duration.fromObject({ seconds: Number(resa.sosta_macello) || 0 }).as("hours"))
                                } :
                                {
                                    Math.floor(Duration.fromObject({ seconds: Number(resa.sosta_macello) || 0 }).as("milliseconds") / 60000)
                                }
                            </Typography>
                        </>
                    )}
                </Grid>
            </Grid>

            <Grid container spacing={2} sx={{mt: 2}}>
                <Grid size={{md:6}}>
                    <Grid container spacing={2}>
                        <Grid size={{ xs:3, sm:3, md:3, lg:3 }}>
                            <Typography variant="subtitle2">Km percorsi</Typography>

                            <Typography variant="h6" gutterBottom>
                                {/* {Intl.NumberFormat("it-IT").format(resa.km_ar_lotto || 0)} km */}
                                {Intl.NumberFormat("it-IT").format(
                                    resa.allevamento_pack?.distanza
                                        ? resa.allevamento_pack.distanza * 2
                                        : 0 || 0
                                )}
                                <Typography display="inline" sx={{fontSize: 18}}>
                                    {" "}
                                    km
                                    {resa.schede_carico.length > 1
                                        ? "x" + resa.schede_carico.length
                                        : ""}
                                </Typography>
                            </Typography>
                        </Grid>

                        <Grid size={{ xs:3, sm:3, md:3, lg:3 }}>
                            <Typography variant="subtitle2">Inizio carico </Typography>
                            <Typography variant="h6" gutterBottom>
                                {resa.data_ora_inizio_carico_lotto &&
                                    convertToDateTime(resa.data_ora_inizio_carico_lotto)?.setLocale("it").toFormat('HH:mm')}
                            </Typography>
                        </Grid>
                        <Grid size={{ xs:3, sm:3, md:3, lg:3 }}>
                            <Typography variant="subtitle2">Fine carico</Typography>
                            <Typography variant="h6" gutterBottom>
                                {resa.data_ora_fine_carico_lotto &&
                                    convertToDateTime(resa.data_ora_fine_carico_lotto)?.setLocale("it").toFormat('HH:mm')}
                            </Typography>
                        </Grid>
                        <Grid size={{ xs:3, sm:3, md:3, lg:3 }}>
                            {resa.tempo_carico_lotto != null &&
                                resa.tempo_carico_lotto > 0 && (
                                    <>
                                        <Typography variant="subtitle2">Tempo carico</Typography>
                                        <Typography variant="h6" gutterBottom>
                                            {
                                                Math.floor(Duration.fromObject({ seconds: Number(resa.tempo_carico_lotto) || 0 }).as("hours"))
                                            } :
                                            {
                                                Math.floor(Duration.fromObject({ seconds: Number(resa.tempo_carico_lotto) || 0 }).as("milliseconds") / 60000)
                                            }

                                        </Typography>
                                    </>
                                )}
                        </Grid>
                    </Grid>
                </Grid>

                <Grid size={{ xs:3, sm:3, md:3, lg:3 }}>
                    {resa.data_ora_inizio_macello &&
                        convertToDateTime(resa.data_ora_inizio_macello)?.setLocale("it").toFormat('HH:mm') != "00:00" && (
                            <>
                                <Typography variant="subtitle2">Inizio macellazione</Typography>
                                <Typography variant="h6" gutterBottom>
                                    {resa.data_ora_inizio_macello &&
                                        convertToDateTime(resa.data_ora_inizio_macello)?.setLocale("it").toFormat('HH:mm')}
                                </Typography>
                            </>
                        )}
                </Grid>
                <Grid size={{ xs:3, sm:3, md:3, lg:3 }}>
                    {resa.data_ora_fine_macello &&
                        convertToDateTime(resa.data_ora_fine_macello)?.setLocale("it").toFormat('HH:mm') != "00:00" && (
                            <>
                                <Typography variant="subtitle2">Fine macellazione</Typography>
                                <Typography variant="h6" gutterBottom>
                                    {resa.data_ora_fine_macello &&
                                        convertToDateTime(resa.data_ora_fine_macello)?.setLocale("it").toFormat('HH:mm')}
                                </Typography>
                            </>
                        )}
                </Grid>
            </Grid>
            <Typography variant="subtitle2" sx={{mt: 4}}>Capi Ordinati</Typography>
            <Typography variant="h4" gutterBottom>
                {Intl.NumberFormat("it-IT").format(resa.numero_capi || 0)}{" "}
                {resa.tipo_capo_desc} {getColore(resa)}
            </Typography>

            <Grid container spacing={2}>
                <Grid size={{ xs:3, sm:3, md:3, lg:3 }}>
                    <Typography variant="subtitle2">P.medio dichiarato</Typography>
                    <Typography variant="h6" gutterBottom>
                        {Intl.NumberFormat("it-IT", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        }).format(resa.peso_medio || 0)}{" "}
                        kg
                    </Typography>
                </Grid>
                <Grid size={{ xs:3, sm:3, md:3, lg:3 }}>
                    {utente.is_staff && (
                        <>
                            <Typography variant="subtitle2">P.medio partenza</Typography>
                            <Typography variant="h6" gutterBottom>
                                {Intl.NumberFormat("it-IT", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                }).format(
                                    (resa.peso_lotto_partenza || 0) /
                                    (resa.numero_capi_resa || 0)
                                )}{" "}
                                kg
                            </Typography>
                        </>
                    )}
                </Grid>

                <Grid size={{ xs:3, sm:3, md:3, lg:3 }}>
                    {(resa.peso_casse_busto_lotto || 0) +
                        (resa.peso_casse_tz_lotto || 0) >
                        0 && (
                            <>
                                <Typography variant="subtitle2">Peso macellato</Typography>
                                <Typography variant="h6" gutterBottom>
                                    {Intl.NumberFormat("it-IT", {
                                        maximumFractionDigits: 0,
                                    }).format(
                                        (resa.peso_casse_busto_lotto || 0) +
                                        (resa.peso_casse_tz_lotto || 0)
                                    )}{" "}
                                    kg
                                </Typography>
                            </>
                        )}
                </Grid>

                <Grid size={{ xs:3, sm:3, md:3, lg:3 }}>
                    {(resa.num_busti_lotto || 0) +
                        (resa.num_tz_lotto || 0) +
                        (resa.numero_scarti_2 || 0) >
                        0 && (
                            <>
                                <Typography variant="subtitle2">Conta polli</Typography>
                                <Typography variant="h6" gutterBottom>
                                    {Intl.NumberFormat("it-IT").format(
                                        (resa.num_busti_lotto || 0) +
                                        (resa.num_tz_lotto || 0) +
                                        (resa.numero_scarti_2 || 0)
                                    )}
                                </Typography>
                            </>
                        )}
                </Grid>
            </Grid>
        </Grid>
        <Grid size={{ xs:12, sm:9, md:9, lg:5 }} sx={{backgroundColor: "#eee"}}>
            {resa.resa_confermata && (
                <>
                    <Grid container spacing={2}>
                        <Grid size={{ xs:3, sm:3, md:3, lg:3 }}>
                            <Typography variant="subtitle2">M.TI</Typography>
                            <Typography variant="h6" gutterBottom>
                                {Intl.NumberFormat("it-IT").format(resa.numero_capi_morti || 0)}
                            </Typography>
                        </Grid>

                        <Grid size={{ xs:3, sm:3, md:3, lg:3 }}>
                            <Typography variant="subtitle2">SC1</Typography>
                            <Typography variant="h6" gutterBottom>
                                {Intl.NumberFormat("it-IT").format(resa.numero_scarti_1 || 0)}
                            </Typography>
                        </Grid>
                        <Grid size={{ xs:3, sm:3, md:3, lg:3 }}>
                            <Typography variant="subtitle2">SC2</Typography>
                            <Typography variant="h6" gutterBottom>
                                {Intl.NumberFormat("it-IT").format(resa.numero_scarti_2 || 0)}
                            </Typography>
                        </Grid>
                        <Grid size={{ xs:3, sm:3, md:3, lg:3 }}></Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid size={{ xs:3, sm:3, md:3, lg:3 }}>
                            <Typography variant="subtitle2">Peso BST</Typography>
                            <Typography variant="h6" gutterBottom>
                                {Intl.NumberFormat("it-IT", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                }).format(resa.peso_casse_busto_lotto || 0)}{" "}
                                kg
                            </Typography>
                        </Grid>

                        <Grid size={{ xs:3, sm:3, md:3, lg:3 }}>
                            <Typography variant="subtitle2">N° BST</Typography>
                            <Typography variant="h6" gutterBottom>
                                {Intl.NumberFormat("it-IT").format(resa.num_busti_lotto || 0)}
                            </Typography>
                        </Grid>
                        <Grid size={{ xs:3, sm:3, md:3, lg:3 }}>
                            <Typography variant="subtitle2">N° casse BST</Typography>
                            <Typography variant="h6" gutterBottom>
                                {Intl.NumberFormat("it-IT", {
                                    maximumFractionDigits: 0,
                                }).format(resa.num_casse_busto_lotto || 0)}
                            </Typography>
                        </Grid>
                        <Grid size={{ xs:3, sm:3, md:3, lg:3 }}>
                            <Typography variant="subtitle2">P.medio BST</Typography>
                            <Typography variant="h6" gutterBottom>
                                {Intl.NumberFormat("it-IT", {
                                    maximumFractionDigits: 2,
                                }).format(
                                    (resa.peso_casse_busto_lotto || 0) /
                                    (resa.num_busti_lotto || 1)
                                )}{" "}
                                kg
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid size={{ xs:3, sm:3, md:3, lg:3 }}>
                            <Typography variant="subtitle2">Peso TZ</Typography>
                            <Typography variant="h6" gutterBottom>
                                {Intl.NumberFormat("it-IT", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                }).format(resa.peso_casse_tz_lotto || 0)}{" "}
                                kg
                            </Typography>
                        </Grid>

                        <Grid size={{ xs:3, sm:3, md:3, lg:3 }}>
                            <Typography variant="subtitle2">N° TZ</Typography>
                            <Typography variant="h6" gutterBottom>
                                {Intl.NumberFormat("it-IT").format(resa.num_tz_lotto || 0)}
                            </Typography>
                        </Grid>
                        <Grid size={{ xs:3, sm:3, md:3, lg:3 }}>
                            <Typography variant="subtitle2">N° casse TZ</Typography>
                            <Typography variant="h6" gutterBottom>
                                {Intl.NumberFormat("it-IT", {
                                    maximumFractionDigits: 0,
                                }).format(resa.num_casse_tz_lotto || 0)}
                            </Typography>
                        </Grid>
                        <Grid size={{ xs:3, sm:3, md:3, lg:3 }}>
                            <Typography variant="subtitle2">P.medio TZ</Typography>
                            <Typography variant="h6" gutterBottom>
                                {Intl.NumberFormat("it-IT", {
                                    maximumFractionDigits: 2,
                                }).format(
                                    (resa.peso_casse_tz_lotto || 0) / (resa.num_tz_lotto || 1)
                                )}{" "}
                                kg
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid size={{ xs:3, sm:3, md:3, lg:3 }}>
                            <Typography variant="subtitle2">Croste&Bolle</Typography>
                            <Typography variant="h6" gutterBottom>
                                {resa.croste &&
                                    `${Intl.NumberFormat("it-IT", {
                                        maximumFractionDigits: 2,
                                    }).format(resa.croste)}	%`}
                            </Typography>
                        </Grid>

                        <Grid size={{ xs:3, sm:3, md:3, lg:3 }}>
                            <Typography variant="subtitle2">Gozzi</Typography>
                            <Typography variant="h6" gutterBottom>
                                {resa.gozzi &&
                                    `${Intl.NumberFormat("it-IT", {
                                        maximumFractionDigits: 2,
                                    }).format(resa.gozzi)}	%`}
                            </Typography>
                        </Grid>
                        <Grid size={{ xs:3, sm:3, md:3, lg:3 }}>
                            <Typography variant="subtitle2">Ali rotte carico</Typography>
                            <Typography variant="h6" gutterBottom>
                                {resa.ali_rotte_carico &&
                                    `${Intl.NumberFormat("it-IT", {
                                        maximumFractionDigits: 2,
                                    }).format(resa.ali_rotte_carico)}	%`}
                            </Typography>
                        </Grid>
                        <Grid size={{ xs:3, sm:3, md:3, lg:3 }}>
                            <Typography variant="subtitle2">Ali rotte macello</Typography>
                            <Typography variant="h6" gutterBottom>
                                {resa.ali_rotte_macello &&
                                    `${Intl.NumberFormat("it-IT", {
                                        maximumFractionDigits: 2,
                                    }).format(resa.ali_rotte_macello)}	%`}
                            </Typography>
                        </Grid>
                        <Grid size={{ xs:3, sm:3, md:3, lg:3 }}>
                            <Typography variant="subtitle2">Grasso</Typography>
                            <Typography variant="h6" gutterBottom>
                                {resa.grasso &&
                                    `${Intl.NumberFormat("it-IT", {
                                        maximumFractionDigits: 2,
                                    }).format(resa.grasso)}	%`}
                            </Typography>
                        </Grid>
                        <Grid size={{ xs:3, sm:3, md:3, lg:3 }}>
                            <Typography variant="subtitle2">Ematomi cosce</Typography>
                            <Typography variant="h6" gutterBottom>
                                {resa.ematomi_cosce &&
                                    `${Intl.NumberFormat("it-IT", {
                                        maximumFractionDigits: 2,
                                    }).format(resa.ematomi_cosce)}	%`}
                            </Typography>
                        </Grid>

                        <Grid size={{ xs:3, sm:3, md:3, lg:3 }}>
                            <Typography variant="subtitle2">Ematomi petto</Typography>
                            <Typography variant="h6" gutterBottom>
                                {resa.ematomi_petto &&
                                    `${Intl.NumberFormat("it-IT", {
                                        maximumFractionDigits: 2,
                                    }).format(resa.ematomi_petto)}	%`}
                            </Typography>
                        </Grid>
                        <Grid size={{ xs:3, sm:3, md:3, lg:3 }}>
                            <Typography variant="subtitle2">Fusi verdi</Typography>
                            <Typography variant="h6" gutterBottom>
                                {resa.fusi_verdi &&
                                    `${Intl.NumberFormat("it-IT", {
                                        maximumFractionDigits: 2,
                                    }).format(resa.fusi_verdi)}	%`}
                            </Typography>
                        </Grid>
                        <Grid size={{ xs:3, sm:3, md:3, lg:3 }}>
                            <Typography variant="subtitle2">Giudizio Colore</Typography>
                            <Typography variant="h6" gutterBottom>
                                {Object.entries(GiudizioColoreTypes).find(
                                    ([key, value]) =>
                                        key == resa.giudizio_colore
                                )?.[1]}
                            </Typography>
                        </Grid>
                        <Grid size={{ xs:3, sm:3, md:3, lg:3 }}>
                            <Typography variant="subtitle2">Uniformità</Typography>
                            <Typography variant="h6" gutterBottom>
                                {Object.entries(UniformitaTypes).find(
                                    ([key, value]) =>
                                        key == resa.uniformita
                                )?.[1]}
                            </Typography>
                        </Grid>
                        <Grid size={{ xs:3, sm:3, md:3, lg:3 }}>
                            <Typography variant="subtitle2">Temp.Fuori Tunnel</Typography>
                            <Typography variant="h6" gutterBottom>
                                {resa.temperatura_fuori_tunnel &&
                                    `${Intl.NumberFormat("it-IT", {
                                        maximumFractionDigits: 2,
                                    }).format(resa.temperatura_fuori_tunnel)}°`}
                            </Typography>
                        </Grid>
                        <Grid size={{ xs:3, sm:3, md:3, lg:3 }}>
                            <Typography variant="subtitle2">Temp.Prima Carico</Typography>
                            <Typography variant="h6" gutterBottom>
                                {resa.temperatura_prima_carico &&
                                    `${Intl.NumberFormat("it-IT", {
                                        maximumFractionDigits: 2,
                                    }).format(resa.temperatura_prima_carico)}°`}
                            </Typography>
                        </Grid>
                    </Grid>

                    {resa.costi_di_trasporto_vivo_list.length > 0 && (
                        <LottoCorrispettiviTrasportoVivoCard
                            costi_di_trasporto_vivo_list={resa.costi_di_trasporto_vivo_list}
                        />
                    )}
                </>
            )}
        </Grid>
        <Grid size={{ xs:12, sm:9, md:3, lg:2 }}sx={{backgroundColor: "#eee"}}>
            <Card raised sx={{backgroundColor: "#2368a1", color: "#fff"}}>
                <CardContent sx={{mb: 2}}>
                    {resa.resa_confermata ? (
                        <Grid container spacing={2}>
                            <Grid size={{ xs:12, sm:12, md:12, lg:12 }}>
                                <Typography variant="subtitle2">Totale capi</Typography>
                                <Typography variant="h6" gutterBottom>
                                    {Intl.NumberFormat("it-IT").format(
                                        resa.numero_capi_resa || 0
                                    )}
                                </Typography>
                            </Grid>
                            <Grid size={{ xs:12, sm:12, md:12, lg:12 }}>
                                <Typography variant="subtitle2">Peso medio resa</Typography>
                                <Typography variant="h6" gutterBottom>
                                    {Intl.NumberFormat("it-IT", {
                                        maximumFractionDigits: 2,
                                    }).format(resa.peso_medio_resa || 0)}{" "}
                                    kg
                                </Typography>
                            </Grid>
                            <Grid size={{ xs:12, sm:12, md:12, lg:12 }}>
                                <Typography variant="subtitle2">Resa</Typography>
                                <Typography variant="h6" gutterBottom>
                                    {resa.percentuale_resa &&
                                        Intl.NumberFormat("it-IT", {
                                            maximumFractionDigits: 2,
                                            minimumFractionDigits: 2,
                                        }).format(resa.percentuale_resa * 100) + "%"}
                                </Typography>
                            </Grid>
                        </Grid>
                    ) : (
                        <Typography variant="h6" gutterBottom sx={{minHeight: "7vh"}}>
                            Resa ancora non disponibile
                        </Typography>
                    )}
                </CardContent>
            </Card>
        </Grid>
    </Grid>
);

export default LottoCommittenteIntestazione;
