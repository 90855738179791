import React from "react";
import {Text, View, StyleSheet, Image} from "@react-pdf/renderer";
import {useState} from "react";

import {
    ColoreTypes,
    Lotto,
} from "components/main/pianificazione/pianificazioneSlice";
import {getColore} from "components/main/pianificazione/utility/lottoUtility";
import {Lavorazione} from "components/main/lavorazioni/lavorazioniSlice";
import moment from "moment";
import em_poultry from "../../../../../../static/images/common/em_poultry.png";
import {PackingList, Pallet} from "../../pesaturaPalletSlice";


interface Rafood16PdfProps {
    // colore: string
    packingList: PackingList;
    // pallets: Pallet[];
}

const Rafood16Pdf = ({packingList}: Rafood16PdfProps) => {

    return (
        <>
            <View style={{
                flexDirection: "column",
                justifyContent: "space-between",
                borderStyle: "solid",
                borderColor: "#000",
                borderWidth: 1,
                padding:5
            }}>
                <View key={'rettangolo_colorato_numero'} style={{flexDirection: "row"}}>
                    <Text style={{fontSize: 16,}}>16</Text>
                    <View
                        style={{flexDirection: "column", marginLeft: 10,}}
                    >
                        <Text style={{fontSize: 6,}}>
                            Trasportatore
                        </Text>
                        <Text style={{fontSize: 6,}}>
                            Carrier
                        </Text>
                        <Text style={{fontSize: 6,}}>
                            Frachführer
                        </Text>
                    {/*</View>*/}
                    {/*<View*/}
                    {/*    style={{flexDirection: "column", marginLeft: 10,}}*/}
                    {/*>*/}
                        <Text style={{fontSize: 12, marginTop:3}}>
                            {packingList?.targhe_camion}
                        </Text>
                    </View>
                </View>
                <View key={'rettangolo_colorato_numero'} style={{flexDirection: "row", marginTop:29, marginBottom:41}}>
                    <Text style={{fontSize: 16,}}>17</Text>
                    <View
                        style={{flexDirection: "column", marginLeft: 10,}}
                    >
                        <Text style={{fontSize: 6,}}>
                            Trasportatori successivi
                        </Text>
                        <Text style={{fontSize: 6,}}>
                            Successive carriers
                        </Text>
                        <Text style={{fontSize: 6,}}>
                            Nachfolgende Frachführer
                        </Text>
                    </View>
                </View>
            </View>

        </>
    );
}

export default Rafood16Pdf;
