import React from "react";
import { useState } from "react";
import { DocumentoDiTrasporto, DettaglioDDT } from "./bolleFattureSlice";
import { Articolo } from "../anagrafiche/anagraficheSlice";
import CardMui from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { styled, Box } from "@mui/system";

import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";
import DdtDettaglioForm from "./DdtDettaglioForm";
import CircularProgress from "@mui/material/CircularProgress";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import UndoIcon from "@mui/icons-material/Undo";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid2";

import TransitionsModal from "../../common/TransitionsModal";

const Card = styled(CardMui)(({ theme }) => ({
	display: "flex:1",
	align: "center",
	marginBottom: theme.spacing(0.7),
}));

interface DettaglioDDTListProps {
	documentoDiTrasporto: DocumentoDiTrasporto;
	articoli: Articolo[];
	nuovoDettaglioDdt: DettaglioDDT;
	aggiungiDettagliDdt: (dettaglioDdt: DettaglioDDT) => void;
	deleteDettaglioDocumentoDiTrasporto: (rddt_id: number) => void;
}
const DettaglioDDTList = ({
	documentoDiTrasporto,
	articoli,
	nuovoDettaglioDdt,
	aggiungiDettagliDdt,
	deleteDettaglioDocumentoDiTrasporto,
}: DettaglioDDTListProps) => {
	const [openDettaglioForm, setOpenDettaglioForm] = useState(false);
	const [dettaglioDttToEdit, setDettaglioDttToEdit] =
		useState(nuovoDettaglioDdt);

	const handleOpenDettaglioForm = (riga_dettaglio?: DettaglioDDT) => {
		riga_dettaglio
			? setDettaglioDttToEdit(riga_dettaglio)
			: setDettaglioDttToEdit(nuovoDettaglioDdt);
		setOpenDettaglioForm(true);
	};
	const handleCloseDettaglioForm = () => {
		setOpenDettaglioForm(false);
	};
	const handleAggiungiDettagliDdt = (dettaglioDdt: DettaglioDDT) => {
		setOpenDettaglioForm(false);
		aggiungiDettagliDdt(dettaglioDdt);
	};

	return (
		<Box>
			<Stack sx={{ paddingLeft: 2 }} direction="row" spacing={2}>
				<Typography variant="h5" gutterBottom>
					Righe dettaglio DDT
				</Typography>
				<Button onClick={() => handleOpenDettaglioForm()}>
					aggiungi dettaglio libero
				</Button>
			</Stack>

			<Card
				sx={{
					border: 2,
					borderStyle: "solid",
					backgroundColor: "background.paper",
					borderColor: "#3fdc10",
					minHeight: "30vh",
					borderRadius: 4,
					boxShadow: 2,
				}}
			>
				<CardContent
					sx={{
						py: 0,
						"&:last-child": { pb: 1 },
					}}
				>
					{!documentoDiTrasporto ? (
						<CircularProgress color="inherit" />
					) : (
						<List sx={{ width: "100%", bgcolor: "background.paper" }}>
							{documentoDiTrasporto?.righe_dettaglio.map((riga_dettaglio) => {
								const labelId = `checkbox-list-label-${riga_dettaglio.id}`;

								return (
									<ListItem key={riga_dettaglio.id} disablePadding>
										<ListItemButton role={undefined} dense>
											<ListItemIcon sx={{ minWidth: 0 }}>
												<IconButton
													edge="start"
													aria-label="comments"
													onClick={() =>
														riga_dettaglio.id &&
														deleteDettaglioDocumentoDiTrasporto(
															riga_dettaglio.id
														)
													}
												>
													<UndoIcon />
												</IconButton>
											</ListItemIcon>
											<ListItemText
												id={labelId}
												primary={
													<Grid container>
														<Grid size={{ xs:12, sm:12, md:12, lg:12 }}>
															{riga_dettaglio.storico_descrizione}
														</Grid>
														<Grid size={{sm:3}}>
															{Intl.NumberFormat("it-IT").format(
																riga_dettaglio.colli
															) + " colli"}
														</Grid>
														<Grid size={{sm:2}}>
															{Intl.NumberFormat("it-IT").format(
																riga_dettaglio.quantita
															) + " kg"}
														</Grid>
														<Grid size={{sm:2}}>
															{riga_dettaglio.storico_prezzo &&
																Intl.NumberFormat("it-IT").format(
																	riga_dettaglio.storico_prezzo
																) + " €/kg"}
														</Grid>
														<Grid size={{sm:1}}>
															{riga_dettaglio.lavorazione && (
																<IconButton
																	edge="start"
																	aria-label="comments"
																	onClick={() =>
																		handleOpenDettaglioForm(riga_dettaglio)
																	}
																>
																	<EditIcon />
																</IconButton>
															)}
														</Grid>
													</Grid>
												}
											/>
										</ListItemButton>
									</ListItem>
								);
							})}
						</List>
					)}
				</CardContent>
			</Card>
			{documentoDiTrasporto && (
				<TransitionsModal
					open={openDettaglioForm}
					handleClose={handleCloseDettaglioForm}
				>
					<DdtDettaglioForm
						articoli={articoli}
						aggiungiDettagliDdt={handleAggiungiDettagliDdt}
						dettaglioDdt={dettaglioDttToEdit}
						documentoDiTrasporto={documentoDiTrasporto}
					/>
					<Button onClick={handleCloseDettaglioForm}>annulla</Button>
				</TransitionsModal>
			)}
		</Box>
	);
};

export default DettaglioDDTList;
