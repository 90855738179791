import React from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams, useNavigate } from "react-router-dom";

import { RootState, useAppDispatch } from "../../../redux/store";
import { Routes_path } from "../../routerApp";
import AllegatiPage from "../allegati/AllegatiPage";

import { DateTime } from 'luxon';

import {
	ErrorStatusTypes,
	ErrorsStack,
	parseErrorMessage,
} from "../../common/errorsDeclarations";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import { utenteAutorizzato } from "../../authentication/UtenteAutorizzato";
import { PermissionTypes } from "../../authentication/permissionsGroups";
import EventiManutenzioneBreadcrumbs from "./EventiManutenzioneBreadcrumbs";
import { UtenteLogged } from "../../authentication/authenticationSlice";
import {
	EventoManutenzione,
	getEventoManutenzione,
	StatoEventoManutenzioneTypes,
} from "./manutenzioneMezziSlice";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EventoManutenzioneForm from "./EventoManutenzioneForm";
// import NuovoEventoManutenzioneForm from "./NuovoEventoManutenzioneForm";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { Button, Divider } from "@mui/material";
import Stack from "@mui/material/Stack";
import { theme } from "../../../theme";
import Paper from "@mui/material/Paper";

const EventoManutenzionePage = () => {
	
	const dispatch = useAppDispatch();
	const { evento_manutenzione_id } = useParams();
	// const eventoManutenzioneId: number = parseInt(evento_manutenzione_id || "");

	const [eventoManutenzioneId, setEventoManutenzioneId] = useState<number>(
		parseInt(evento_manutenzione_id || "")
	);

	const location = useLocation();
	const navigate = useNavigate();
	const utente = useSelector<RootState, UtenteLogged>(
		(state) => state.authentication.utenteLogged
	);

	const nuovoEventoManutenzioneId = useSelector<RootState, number | undefined>(
		(state) => state.manutenzioneMezziState.nuovoEventoManutenzioneId
	);

	// const EventoManutenzioneCorrenteId = useSelector<
	// 	RootState,
	// 	number | undefined
	// >((state) => state.manutenzioneMezziState.EventoManutenzioneCorrenteId);

	useEffect(() => {
		eventoManutenzioneId &&
			dispatch(getEventoManutenzione(eventoManutenzioneId));
	}, [eventoManutenzioneId]);

	useEffect(() => {
		nuovoEventoManutenzioneId &&
			setEventoManutenzioneId(nuovoEventoManutenzioneId);
	}, [nuovoEventoManutenzioneId]);

	const eventoManutenzione: EventoManutenzione | undefined = useSelector<
		RootState,
		EventoManutenzione | undefined
	>((state) =>
		state.manutenzioneMezziState.eventiManutenzione.results.find(
			(eventoManutenzione) => eventoManutenzione?.id == eventoManutenzioneId
		)
	);

	const nuovoEventoManutenzione: EventoManutenzione = {
		stato: "P",
		tipo_manutentore: "I",
	};

	const errorsStack: ErrorsStack = useSelector<RootState, ErrorsStack>(
		(state) => state.manutenzioneMezziState.eventiManutenzione.errorsStack
	);

	return (
		<>
			<EventiManutenzioneBreadcrumbs eventoManutenzione={eventoManutenzione} />
			<Box
				sx={{
					backgroundColor: "#fff",
					elevation: 20,
					p: 2,
				}}
			>
				<Backdrop
					sx={{
						color: "#fff",
						zIndex: (theme) => theme.zIndex.drawer + 1,
					}}
					open={errorsStack.status == ErrorStatusTypes.PENDING}
				>
					<CircularProgress color="inherit" />
				</Backdrop>

				<Stack
					direction="row"
					justifyContent="flex-start"
					alignItems="flex-start"
					spacing={4}
					sx={{ mb: 1 }}
				>
					{eventoManutenzioneId ? (
						<Typography variant="h3" component="div" color="text.secondary">
							evento Manutenzione n°{eventoManutenzioneId} -{" "}
							{
								Object.entries(StatoEventoManutenzioneTypes).find(
									([key, value]) => key == eventoManutenzione?.stato
								)?.[1]
							}
						</Typography>
					) : (
						<Typography variant="h3" component="div" color="text.secondary">
							Nuovo evento Manutenzione
						</Typography>
					)}
				</Stack>

				<Divider sx={{ mb: 1 }} />
				<EventoManutenzioneForm
					eventoManutenzione={eventoManutenzione || nuovoEventoManutenzione}
				/>
				<AllegatiPage eventoManutenzione_id={eventoManutenzione?.id} />
			</Box>
		</>
	);
};

export default EventoManutenzionePage;
