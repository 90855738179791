import React from "react";
import { DateTime } from 'luxon';

import {Text, View, StyleSheet, Image} from "@react-pdf/renderer";
import {DestinazioneTrasporto, VettoreTrasporto,} from "components/main/bolle_fatture/bolleFattureSlice";
import {PackingList,} from "components/main/pesaturaPallet/pesaturaPalletSlice";
import DettaglioDdtContoTerziPDF from "./DettaglioDdtContoTerziPDF";
import {getCausaleDdt, gettipoDdt} from "components/main/bolle_fatture/utility/ddtUtiliy";
import {Committente} from "components/main/anagrafiche/anagraficheSlice";
import {convertToDateTime} from "../../../../common/dateUtils/convertToDateTime";

// Create styles
const styles = StyleSheet.create({
    section: {
        marginTop: 5,
        marginLeft: 25,
        marginRight: 25,
        padding: 5,
    },
    text: {
        fontSize: 12,
        fontStyle: "normal",
    },
});

interface DdtContoTerziPdfProps {
    packingList: PackingList;
    destinatario: Committente;
    destinazione?: DestinazioneTrasporto;
    // vettore?: VettoreTrasporto;
}

const DdtContoTerziPdf = ({
                              packingList,
                              destinatario,
                              destinazione,
                              // vettore,
                          }: DdtContoTerziPdfProps) => (
    <View
        style={{
            flexDirection: "column",
            justifyContent: "space-between",
            margin: 5,
        }}
    >
        <Text style={(styles.text, {
            fontSize: 17,
            width: '100%',
            textAlign: "center",
            backgroundColor: "#444",
            color: "#fff",
            padding: 10,
            marginBottom: 10,
        })}>
            DOCUMENTO DI TRASPORTO
        </Text>
        <View
            style={{
                flexDirection: "row",
                justifyContent: "flex-start",
                marginLeft: 10,
            }}
        >
            <View
                key={"packingListPDF_intestazione"}
                style={{
                    flexDirection: "column",
                    justifyContent: "center",
                    marginLeft: 10,
                    width: "50%",
                }}
            >
                <Text style={(styles.text, {fontSize: 15})}>
                    Mittente:
                </Text>
                <Text style={(styles.text, {fontSize: 12})}>
                    Zarattini Menotti s.r.l.
                </Text>
                <Text style={(styles.text, {fontSize: 8})}>
                    via Leonardo da Vinci 50, 35018
                </Text>
                <Text style={(styles.text, {fontSize: 8})}>
                    San Martino di Lupari (PD) - Italy
                </Text>
                <Text style={(styles.text, {fontSize: 8})}>
                    tel: +39 049 9460 522 - fax: +39 049 9460 432
                </Text>
                <Text style={(styles.text, {fontSize: 8})}>
                    PI/CF: 01911380283
                </Text>
            </View>
            <View
                key={"packingListPDF_intestazione"}
                style={{
                    flexDirection: "column",
                    justifyContent: "center",
                    marginLeft: 10,
                    width: "50%",

                }}
            >
                <Text style={(styles.text, {fontSize: 15})}>
                    Documento di Trasporto:
                </Text>
                <Text style={(styles.text, {fontSize: 12})}>
                    n°: {packingList.numero_ddt_committente ? packingList.numero_ddt_committente: '_________'} del {convertToDateTime(packingList.data)?.setLocale("it").toFormat('d LLLL yyyy')}
                </Text>
                <Text style={(styles.text, {fontSize: 12, marginTop: 6})}>
                    trasporto a mezzo:
                </Text>
                <View style={{flexDirection: 'row', alignItems: 'center'}}>
                    <View style={{width: 12, height: 12, borderWidth: 1, marginRight: 5}}/>
                    <Text style={{fontSize: 12, marginRight: 20}}>Mittente</Text>

                    <View style={{width: 12, height: 12, borderWidth: 1, marginRight: 5}}/>
                    <Text style={{fontSize: 12, marginRight: 20}}>Vettore</Text>

                    <View style={{width: 12, height: 12, borderWidth: 1, marginRight: 5}}/>
                    <Text style={{fontSize: 12}}>Destinatario</Text>
                </View>
            </View>
        </View>

        <View
            style={{
                flexDirection: "row",
                justifyContent: "flex-start",
                marginLeft: 10,
                marginTop: 15
            }}
        >
            <View style={{width: "50%",}}>
                <Text style={(styles.text, {marginLeft: 10, fontSize: 12})}>
                    Destinazione:
                </Text>
                <View
                    style={{
                        // width: 280,
                        borderRadius: 10,
                        borderWidth: 1,
                        borderColor: "#243",
                        padding: 10,
                    }}
                >
                    <Text style={(styles.text, {fontSize: 12})}>
                        {destinazione
                            ? destinazione.ragione_sociale
                            : destinatario.ragione_sociale}
                    </Text>
                    <Text style={(styles.text, {fontSize: 11})}>
                        {destinazione ? destinazione.indirizzo : destinatario.indirizzo}
                    </Text>
                    <Text style={(styles.text, {fontSize: 11})}>
                        {destinazione?.comune}
                    </Text>
                    <Text style={(styles.text, {fontSize: 11})}>
                        causale del trasporto: Vendita
                    </Text>
                </View>
            </View>
            <View style={{width: "50%", marginLeft: 70}}>
                <Text style={(styles.text, {marginLeft: 10, fontSize: 12})}>
                    Destinatario:
                </Text>

                <View
                    style={{
                        // width: 280,
                        borderRadius: 10,
                        borderWidth: 1,
                        borderColor: "#243",
                        padding: 10,
                        marginBottom: 10,
                    }}
                >
                    <Text style={(styles.text, {fontSize: 12})}>
                        {destinatario.ragione_sociale}
                    </Text>
                    <Text style={(styles.text, {fontSize: 11})}>
                        {destinatario.indirizzo}
                    </Text>
                    <Text style={(styles.text, {fontSize: 11})}>
                        {destinatario.cap + " " + destinatario.comune}
                    </Text>
                    <Text style={(styles.text, {fontSize: 11})}>
                        P.IVA: {destinatario.piva}
                    </Text>
                </View>
            </View>
            <View
                // key={"scheda_macellazione_intestazione"}
                style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                }}
            >
                <View style={(styles.section, {marginLeft: 52})}>

                </View>
                <View
                    style={{
                        marginRight: 10,
                        // width: 280,
                    }}
                >


                </View>
            </View>
        </View>
        <View
            style={{
                // width: 280,
                borderRadius: 10,
                borderWidth: 1,
                borderColor: "#243",
                // padding: 10,
                margin: 10,
                paddingBottom: 15,
            }}
        >
            {/*<Text style={(styles.text, {fontSize: 12, margin: 15, marginBottom: 5})}>Descrizione dei Beni</Text>*/}

            <DettaglioDdtContoTerziPDF dettagliDdt={
                [{
                    ddt: packingList.id||0,
                    colli: packingList.num_pallets||0,
                    quantita: packingList.peso_lordo||0,
                    storico_codice_tracciabilita: "",
                    storico_articolo: "",
                    storico_descrizione: "Fresh Whole Heavy Hens",
                    storico_prezzo: 0
                },{
                    ddt: 3333,
                    colli: 0,
                    quantita: 0,
                    storico_codice_tracciabilita: "",
                    storico_articolo: "",
                    storico_descrizione: "Macellazione: "+packingList.date_macellazione?.join(" - "),
                    storico_prezzo: 33
                },{
                    ddt: 3333,
                    colli: 0,
                    quantita: 0,
                    storico_codice_tracciabilita: "",
                    storico_articolo: "",
                    storico_descrizione: "Scadenza: "+packingList.date_scadenza?.join(" - "),
                    storico_prezzo: 33
                },{
                    ddt: 44444,
                    colli: 0,
                    quantita:0,
                    storico_codice_tracciabilita: "",
                    storico_articolo: "",
                    storico_descrizione: "Lotto: "+packingList.lista_lotti?.join(" - "),
                    storico_prezzo: 33
                },
                ]

            }/>
        </View>

        <View
            style={{
                // width: 280,
                borderRadius: 10,
                borderWidth: 1,
                borderColor: "#243",
                // padding: 10,
                margin: 10,
                flexDirection: "row",
                // justifyContent: "space-between",
            }}
        >
            <View
                style={{
                    // width: 280,
                    borderRightWidth: 0.5,
                    borderColor: "#243",
                    // padding: 10,
                    margin: 0,
                    padding: 10,
                    flexDirection: "column",
                    justifyContent: "space-between",
                    width: "70%",
                }}
            >
                {/*<Text*/}
                {/*	style={*/}
                {/*		(styles.text,*/}
                {/*		{*/}
                {/*			marginBottom: 8,*/}
                {/*			paddingBottom: 5,*/}
                {/*			borderBottomWidth: 0.5,*/}
                {/*			fontSize: 8,*/}
                {/*		})*/}
                {/*	}*/}
                {/*>*/}
                {/*	{vettore*/}
                {/*		? "Trasporto a mezzo: VETTORE"*/}
                {/*		: "Trasporto a cura del destinatario"}*/}
                {/*</Text>*/}
                <View
                    style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        // width: "70%",
                        textAlign: "right",
                    }}
                >
                    <Text style={(styles.text, {fontSize: 8, width: "20%"})}>
                        numero colli:
                    </Text>
                    <Text style={(styles.text, {fontSize: 8, width: "20%"})}>
                        peso netto:
                    </Text>
                    <Text style={(styles.text, {fontSize: 8, width: "20%"})}>
                        peso lordo:
                    </Text>
                    <Text style={(styles.text, {fontSize: 8, width: "20%"})}>
                        Porto
                    </Text>
                </View>
                <View
                    style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        // width: "70%",
                        textAlign: "right",
                        marginBottom: 8,
                        paddingBottom: 5,
                        borderBottomWidth: 0.5,
                    }}
                >
                    <Text style={(styles.text, {fontSize: 10, width: "20%"})}>
                        {Intl.NumberFormat("it-IT").format(
                            packingList.num_pallets || 0
                        )}
                    </Text>
                    <Text style={(styles.text, {fontSize: 10, width: "20%"})}>
                        {Intl.NumberFormat("it-IT").format(
                            packingList.peso_netto || 0
                        )}{" "}
                        kg
                    </Text>
                    <Text style={(styles.text, {fontSize: 10, width: "20%"})}>
                        {Intl.NumberFormat("it-IT").format(
                            packingList.peso_lordo || 0
                        )}{" "}
                        kg
                    </Text>
                    <Text style={(styles.text, {fontSize: 10, width: "20%"})}>
                        Franco
                    </Text>
                </View>
                <View
                    style={{
                        marginBottom: 8,
                        paddingBottom: 5,
                        minHeight: 50,
                        // borderBottomWidth: 0.5,
                    }}
                >
                    <Text style={(styles.text, {fontSize: 12})}>Vettore:</Text>

                </View>

                <View
                    style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        // width: "70%",
                    }}
                >
                    {/*<Text style={(styles.text, { fontSize: 8, width: "60%" })}>*/}
                    {/*	{vettore ? "Vettore:" : ""}*/}
                    {/*</Text>*/}
                    <Text
                        style={
                            (styles.text, {textAlign: "right", fontSize: 8, width: "40%"})
                        }
                    >
                        data ora del ritiro:
                    </Text>
                </View>
                <View
                    style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        // width: "70%",
                        marginBottom: 8,
                        paddingBottom: 5,
                        borderBottomWidth: 0.5,
                    }}
                >
                    <View
                        style={{
                            flexDirection: "column",
                        }}
                    >
                    </View>
                </View>
            </View>
            <View
                style={{
                    padding: 10,
                    flexDirection: "column",
                    textAlign: "left",
                    width: "30%",
                    // justifyContent: "space-between",
                }}
            >
                <Text
                    style={
                        (styles.text,
                            {
                                fontSize: 8,
                                marginBottom: 1,
                                paddingBottom: 5,
                            })
                    }
                >
                    aspetto esteriore dei beni:
                </Text>
                <Text
                    style={
                        (styles.text,
                            {
                                fontSize: 10,
                                marginBottom: 8,
                                paddingBottom: 5,
                                borderBottomWidth: 0.5,
                            })
                    }
                >
                    MAGNUM BOXEN
                </Text>
                <Text
                    style={{
                        minHeight: 30,
                        paddingBottom:40,
                        fontSize: 10,
                        borderBottomWidth: 0.5,
                        marginTop: 25,
                    }}
                >
                    firma conducente:
                </Text>
                <Text
                    style={{
                        minHeight: 30,
                        fontSize: 10,
                        paddingBottom:40,
                        borderBottomWidth: 0.5,
                        marginTop: 25,
                    }}
                >
                    firma destinatario:
                </Text>
                <Text
                    style={{
                        minHeight: 30,
                        fontSize: 10,
                        paddingBottom:40,
                        borderBottomWidth: 0.5,
                        marginTop: 25,
                    }}
                >
                    firma vettore:
                </Text>
            </View>
        </View>
    </View>
);

export default DdtContoTerziPdf;
