import React from "react";
import {useLocation} from "react-router-dom";
import {CSSObject, styled, Theme, useTheme} from "@mui/material/styles";

import MuiDrawer, {DrawerProps as MuiDrawerProps} from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import SideMenu from "./SideMenu";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronRight";

// import logowebylia from "../../../../static/images/logo/logo-biglia_contornaW.png";

const logoScarso = require("../../../../static/images/logo/logo-Scarso-Nuovo.png");

const DrawerHeader = styled("div")(({theme}) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    // ...theme.mixins.toolbar,
}));

interface DrawerProps extends MuiDrawerProps {
    theme?: any;
    sideMenuDrawerWidth: number | string;
    miniSideMenuDrawerWidth: number | string;
    openedMixin: any;
    closedMixin: any;
}

const openedMixin = (
    theme: Theme,
    sideMenuDrawerWidth: number | string
): CSSObject => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
    width: sideMenuDrawerWidth,
});

const closedMixin = (
    theme: Theme,
    miniSideMenuDrawerWidth: number | string
): CSSObject => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
        // width: `calc(${theme.spacing(9)} + 1px)`,
        width: miniSideMenuDrawerWidth,
    },
});

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) =>
        prop !== "open" &&
        prop !== "sideMenuDrawerWidth" &&
        prop !== "miniSideMenuDrawerWidth" &&
        prop !== "openedMixin" &&
        prop !== "closedMixin",
})(
    ({
         theme,
         sideMenuDrawerWidth,
         miniSideMenuDrawerWidth,
         openedMixin,
         closedMixin,
         open,
     }: DrawerProps) => ({
        // width: sideMenuDrawerWidth,
        // flexShrink: 0,
        whiteSpace: "nowrap",
        boxSizing: "border-box",
        ...(open && {
            ...openedMixin(theme, sideMenuDrawerWidth),
            "& .MuiDrawer-paper": openedMixin(theme, sideMenuDrawerWidth),
        }),
        ...(!open && {
            ...closedMixin(theme, miniSideMenuDrawerWidth),
            "& .MuiDrawer-paper": closedMixin(theme, miniSideMenuDrawerWidth),
        }),
    })
);

interface SideBarProps extends MuiDrawerProps {
    open: boolean;
    sideMenuDrawerWidth: number | string;
    miniSideMenuDrawerWidth: number;
    appBarAltezza: number;
    tooggleDrawer: () => void;
    drawerPreviewOn: () => void;
    drawerPreviewOff: () => void;
    // openedMixin: any;
    // closedMixin: any;
    window?: () => Window;
    logoPiccolo: any;
    logoGrande: any;
}

export default function SideBar({
                                    drawerPreviewOn,
                                    drawerPreviewOff,
                                    open,
                                    sideMenuDrawerWidth,
                                    miniSideMenuDrawerWidth,
                                    appBarAltezza,
                                    tooggleDrawer,
                                    // openedMixin,
                                    // closedMixin,
                                    logoPiccolo,
                                    logoGrande,

                                    ...props
                                }: SideBarProps) {
    const {window} = props;
    const theme = useTheme();
    const container =
        window !== undefined ? () => window().document.body : undefined;

    const [mobileOpen, setMobileOpen] = React.useState(false);
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawerContent = (
		<>
			<DrawerHeader>
				<img
					src={open ? logoGrande : logoPiccolo}
					alt="GI"
					style={{
						height: appBarAltezza,
						paddingTop: 15,
						paddingBottom: 15,
						paddingLeft: 25,
						paddingRight: 25,
					}}
				/>
				<IconButton onClick={tooggleDrawer} size="large">
					{/* <ChevronRightIcon /> */}
					<ChevronLeftIcon />
				</IconButton>
			</DrawerHeader>
			<Divider />
			<SideMenu key="Sidemenu_call" open={open} tooggleDrawer={tooggleDrawer} />
		</>
    );
    return (
        <>
            <Drawer
                variant="permanent"
                open={open}
                sideMenuDrawerWidth={sideMenuDrawerWidth}
                miniSideMenuDrawerWidth={miniSideMenuDrawerWidth}
                openedMixin={openedMixin}
                closedMixin={closedMixin}
                sx={{
                    // display: { xs: "none", sm: "block" },
                    display: {xs: "none", sm: "block", md: "block"},
                }}
            >
                {drawerContent}
            </Drawer>
            <MuiDrawer
                container={container}
                // variant="temporary"
                variant="persistent"
                open={open}
                onClose={tooggleDrawer}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: {xs: "block", sm: "none"},
                    "& .MuiDrawer-paper": {
                        boxSizing: "border-box",
                        width: sideMenuDrawerWidth,
                    },
                }}
            >
                {drawerContent}
            </MuiDrawer>
        </>
    );
}
