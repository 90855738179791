import React from "react";
import { Allegato } from "./allegatiSlice";
import { Lotto } from "../pianificazione/pianificazioneSlice";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";

import IconButton from "@mui/material/IconButton";
import { Link } from "@mui/material";
import { Link as ReactLink } from "react-router-dom";
import { Routes_path } from "../../routerApp";
import { DateTime } from 'luxon';

import AttachFileIcon from "@mui/icons-material/AttachFile";
import DeleteIcon from "@mui/icons-material/Delete";
import ScaleIcon from "@mui/icons-material/Scale";
import WarehouseIcon from "@mui/icons-material/Warehouse";

import { DocumentoDiTrasporto } from "../bolle_fatture/bolleFattureSlice";
import {convertToDateTime} from "../../common/dateUtils/convertToDateTime";

interface AllegatiListProps {
	lotto?: Lotto;
	allegati: Allegato[];
	documentiDiTrasporto?: DocumentoDiTrasporto[];
	handleDelete: (allegato: Allegato) => void;
}

const AllegatiList = ({
	lotto,
	allegati,
	documentiDiTrasporto,
	handleDelete,
}: AllegatiListProps) => {
	return (
		<List dense={true}>
			{lotto?.resa_confermata && (
				<Box key={"resa_" + lotto?.id}>
					<ListItem key={"lotto_" + lotto?.id}>
						<ListItemAvatar>
							<Avatar
								component={ReactLink}
								to={{
									pathname: Routes_path.STAMPA_RESA + lotto?.id,
								}}
								target="_blank"
							>
								<ScaleIcon />
							</Avatar>
						</ListItemAvatar>
						<ListItemText
							primary={"Resa lotto " + lotto?.codice_tracciabilita}
							secondary={lotto?.tipo_capo_desc}
						/>
					</ListItem>
					<Divider variant="inset" component="li" />
				</Box>
			)}
			{allegati?.map((allegato) => (
				<Box key={"box_allegato_" + allegato.id}>
					<ListItem
						key={"allegato_" + allegato.id}
						secondaryAction={
							<IconButton
								edge="end"
								aria-label="delete"
								onClick={() => handleDelete(allegato)}
							>
								<DeleteIcon />
							</IconButton>
						}
					>
						<ListItemAvatar>
							<Avatar component={Link} href={allegato.file} target="_blank">
								<AttachFileIcon />
							</Avatar>
						</ListItemAvatar>
						<ListItemText
							primary={allegato.tipo_documento_des?.nome}
							secondary={allegato.descrizione}
						/>
					</ListItem>
					<Divider variant="inset" component="li" />
				</Box>
			))}
			{documentiDiTrasporto?.map((ddt) => (
				<Box key={"box_documentiDiTrasporto_" + ddt.id}>
					<ListItem key={"documentiDiTrasporto_" + ddt.id}>
						<ListItemAvatar>
							<Avatar
								component={ReactLink}
								to={{
									pathname: Routes_path.STAMPA_DOCUMENTO_TRASPORTO + ddt.id,
								}}
								target="_blank"
							>
								<WarehouseIcon />
							</Avatar>
						</ListItemAvatar>
						<ListItemText
							primary={
								"documento di Trasporto del " +
								convertToDateTime(ddt.data_documento)?.setLocale("it").toFormat("dd LLL yyyy")
							}
							// secondary={ddt.destinazione}
						/>
					</ListItem>
					<Divider variant="inset" component="li" />
				</Box>
			))}
		</List>
	);
};

export default AllegatiList;
