import React, {useEffect} from "react";
import {useLocation, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "../../../../redux/store";
import {getLotto, Lotto} from "components/main/pianificazione/pianificazioneSlice";
import {
    Lavorazioni,
    Lavorazione,
    fetchLavorazioni,
} from "components/main/lavorazioni/lavorazioniSlice";
import GrandeImballoDdtPdf from "components/main/pesaturaPallet/stampe/GrandeImballoDdtPdf";
import {PDFViewer, Page, Document, StyleSheet} from "@react-pdf/renderer";
import { DateTime } from 'luxon';
import {
    ErrorStatusTypes,
    ErrorsStack,
} from "components/common/errorsDeclarations";
import {Box} from "@mui/system";
import {Typography} from "@mui/material";
import {
    fetchPackingListPallets,
    getPackingList,
    getPallet,
    PackingList,
    Pallet,
    PesaturaPalletsState
} from "../pesaturaPalletSlice";
import PackingListPdf from "./PackingListPdf";

// Create styles
interface PackingListStampaPageProps {
}

const PackingListStampaPage = () => {
    
    const dispatch = useAppDispatch();
    const {packing_list_id} = useParams();
    const packingListId: number = parseInt(packing_list_id || '0');

    useEffect(() => {
        packingListId && dispatch(getPackingList({packing_list_id: packingListId}));
    }, [packingListId]);


    const packingList = useSelector<RootState, PackingList | undefined>(
        (state) => state.pesaturaPalletState.packingListState.results.find(
            (packingList) => packingList.id == packingListId
        )
    );

    useEffect(() => {
        packingList?.id && dispatch(fetchPackingListPallets({packing_list_id: packingList.id}));
    }, [packingList]);

    const pallets: PesaturaPalletsState = useSelector<RootState, PesaturaPalletsState>(
        (state) => state.pesaturaPalletState.pallets
    );

    return (
        <Box>
            <Box sx={{backgroundColor: "#999", height: "80vh"}}>
                <PDFViewer width={"100%"} height={"100%"}>
                    <Document>
                        <Page
                            size="A4"
                            orientation="portrait"
                            style={{
                                backgroundColor: "#fff",
                                paddingTop: 20,
                            }}
                            key={"PackingListStampaPage_" + packing_list_id}
                        >
                            {packingList && <PackingListPdf packingList={packingList} pallets={pallets.results} />}
                        </Page>
                    </Document>
                </PDFViewer>
            </Box>
        </Box>
    );
};

export default PackingListStampaPage;
