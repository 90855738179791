import React from "react";
import { Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import { useState } from "react";

import {
	ColoreTypes,
	Lotto,
} from "components/main/pianificazione/pianificazioneSlice";
import { getColore } from "components/main/pianificazione/utility/lottoUtility";
import { Lavorazione } from "components/main/lavorazioni/lavorazioniSlice";
import { DateTime } from 'luxon';
import em_poultry from "../../../../../static/images/common/em_poultry.png";
import {PackingList, Pallet} from "../pesaturaPalletSlice";
import {convertToDateTime} from "../../../common/dateUtils/convertToDateTime";

// Create styles
const styles = StyleSheet.create({
	page: {
		backgroundColor: "#fff",
		paddingTop: 20,
	},
	section: {
		marginTop: 10,
		marginLeft: 10,
		marginRight: 10,
		padding: 10,
	},
	sectionIntestazione: {
		// width: "100%",
		marginTop: 10,
		marginHorizontal: 20,
		padding: 5,
		// paddingTop: 10,
		// paddingBottom: 7,
		// backgroundColor: "#777777",
		// color: "#ffffff",
		borderWidth: 1,
		borderStyle: "solid",
		borderColor: "#000",
	},
	text: {
		fontSize: 15,
		fontStyle: "normal",
		// marginVertical: 20,
		textTransform: "uppercase",
	},
	sectionTable: {
		flexDirection: "row",
		justifyContent: "space-between",
		marginLeft: 0,
		borderBottomWidth: 1,
	},
	sectionFrame: {
		flexDirection: "row",
		paddingHorizontal: 60,
		paddingVertical: 40,
		fontSize: 180,
		fontStyle: "normal",
		borderWidth: 1,
	},
});

interface GrandeImballoPdfProps {
	pallet: Pallet;
	packingList: PackingList;
	lotto: Lotto;
}

const GrandeImballoDdtPdf = ({ pallet, packingList, lotto }: GrandeImballoPdfProps) => {

	return (
		<>
			<View
				key={"etichetta_schedaCarico_" + pallet.id}
				style={[
					styles.sectionIntestazione,
					{
						// borderWidth: 1,
						flexDirection: "column",
						justifyContent: "center",
					},
				]}
			>

				<Image
					src={em_poultry}
					style={{
						width: "95%",
						height: 70,
						// margin: 0,
						padding: 0,
						marginLeft: "3px",
					}}
				/>
			</View>
			<View
				key={"Intestazioni_" + pallet.id}
				style={{
					borderWidth: 0,
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					marginTop: 3,
					marginHorizontal: 20,
					padding: 2,
				}}
			>
				<Text style={[styles.text, { fontSize: 20, }]}>
					PALLETTENSCHEIN / FOGLIO GRANDE IMBALLO
				</Text>
				<Text style={[styles.text, { fontSize: 28, }]}>
					Kunde:  GEW
				</Text>
				<Text style={[styles.text, { fontSize: 23, }]}>
					FRISCHE SCHWERE HUHNER
				</Text>
				<Text style={[styles.text, { fontSize: 23,  }]}>
					GALLINE PESANTI  A BUSTO  FRESCHE
				</Text>
			</View>
			<View
				key={"macellazione_" + pallet.id}
				style={{
					borderWidth: 0,
					flexDirection: "row",
					justifyContent: "flex-start",
					alignItems: "flex-end",
					marginTop: 8,
					marginHorizontal: 20,
					padding: 2,
				}}
			>
				<Text style={[/* styles.text, */ {fontSize: 14,},]}>Schlachtdatum - Macellazione:</Text>
				<Text style={[styles.text, { fontSize: 16, paddingBottom: 1, marginLeft: 5 },]}>
					{convertToDateTime(pallet.data_produzione)?.setLocale("it").toFormat('cccc dd LLL yyyy')}
				</Text>
			</View>
			<View
				key={"macellazione_" + pallet.id}
				style={{
					borderWidth: 0,
					flexDirection: "row",
					justifyContent: "flex-start",
					alignItems: "flex-end",
					marginTop: 8,
					marginHorizontal: 20,
					padding: 2,
				}}
			>
				<Text style={[/* styles.text, */ {fontSize: 14,},]}>MHD –  Scadenza:</Text>
				<Text style={[styles.text, { fontSize: 16, paddingBottom: 1, marginLeft: 5 },]}>
					{convertToDateTime(pallet.data_scadenza)?.setLocale("it").toFormat('cccc dd LLL yyyy')}
				</Text>
			</View>
			<View
				key={"Lotto_" + pallet.id}
				style={{
					borderWidth: 0,
					flexDirection: "row",
					justifyContent: "flex-start",
					alignItems: "flex-end",
					marginTop: 8,
					marginHorizontal: 20,
					padding: 2,
				}}
			>
				<Text style={[/* styles.text, */ {fontSize: 14,},]}>Charge Nr. – Lotto nr.:</Text>
				<Text style={[styles.text, { fontSize: 16, paddingBottom: 1, marginLeft: 5 },]}>
					{lotto.codice_tracciabilita}
				</Text>
			</View>
			<View
				key={"PalletID_" + pallet.id}
				style={{
					borderWidth: 0,
					flexDirection: "row",
					justifyContent: "flex-start",
					alignItems: "flex-end",
					marginTop: 8,
					marginHorizontal: 20,
					padding: 2,
				}}
			>
				<Text style={[/* styles.text, */ {fontSize: 14,},]}>Pallet ID:</Text>
				<Text style={[styles.text, { fontSize: 16, paddingBottom: 1, marginLeft: 5 },]}>
					{pallet.id}
				</Text>
			</View>
			<View
				key={"PesoLordo_" + pallet.id}
				style={{
					borderWidth: 0,
					flexDirection: "row",
					justifyContent: "flex-start",
					alignItems: "flex-end",
					marginTop: 8,
					marginHorizontal: 20,
					padding: 2,
				}}
			>
				<Text style={[/* styles.text, */ {fontSize: 14,},]}>Brutto Gewicht – Peso lordo:</Text>
				<Text style={[styles.text, { fontSize: 16, paddingBottom: 1, marginLeft: 5 },]}>
					{pallet.peso_lordo && Intl.NumberFormat("it-IT").format(pallet.peso_lordo) + " kg"}
				</Text>
			</View>
			<View
				key={"PesoNetto_" + pallet.id}
				style={{
					borderWidth: 0,
					flexDirection: "row",
					justifyContent: "flex-start",
					alignItems: "flex-end",
					marginTop: 8,
					marginHorizontal: 20,
					padding: 2,
				}}
			>
				<Text style={[/* styles.text, */ {fontSize: 14,},]}>Netto Gewicht – Peso netto:</Text>
				<Text style={[styles.text, { fontSize: 16, paddingBottom: 1, marginLeft: 5 },]}>
					{pallet.peso_netto && Intl.NumberFormat("it-IT").format(pallet.peso_netto) + " kg"}
				</Text>
			</View>
			<View
				key={"Provenienza_" + pallet.id}
				style={{
					borderWidth: 0,
					flexDirection: "row",
					justifyContent: "flex-start",
					alignItems: "flex-end",
					marginTop: 8,
					marginHorizontal: 20,
					padding: 2,
				}}
			>
				<Text style={[/* styles.text, */ {fontSize: 14,},]}>Herkunft – Provenienza:</Text>
				<Text style={[styles.text, { fontSize: 16, paddingBottom: 1, marginLeft: 5 },]}>ITALIA  - IT 0307 M CE</Text>
			</View>
			<View
				key={"Provenienza_" + pallet.id}
				style={{
					borderWidth: 0,
					flexDirection: "row",
					justifyContent: "flex-start",
					alignItems: "flex-end",
					marginTop: 8,
					marginHorizontal: 20,
					padding: 2,
				}}
			>
				<Text style={[/* styles.text, */ {fontSize: 14,},]}>BESTELLUNG NR:</Text>
				<Text style={[styles.text, { fontSize: 16, paddingBottom: 1, marginLeft: 5 },]}>
					{packingList.numero_ordinine_consegna}
				</Text>
			</View>
		</>
	);
};

export default GrandeImballoDdtPdf;
