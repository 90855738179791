import React, {useState, useEffect} from "react";
import {SchedaMacello} from "./schedeMacelloSlice";
import {Lotto} from "../pianificazione/pianificazioneSlice";
import {theme} from "../../../theme";

import SchedaMacelloForm from "./SchedaMacelloForm";
import SchedaMacelloInfoLotto from "./SchedaMacelloInfoLotto";

import Avatar from "@mui/material/Avatar";
import {Box} from "@mui/system";
import {getColore} from "../pianificazione/utility/lottoUtility";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";

import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid2";
import Stack from "@mui/material/Stack";

interface SchedaMacelloCardProps {
    lotto: Lotto;
    schedaMacello?: SchedaMacello;
    saveSchedaMacello: (schedaMacello: SchedaMacello) => void;
}

const SchedaMacelloCard = ({
                               lotto,
                               schedaMacello,
                               saveSchedaMacello,
                           }: SchedaMacelloCardProps) => {
    const [dettagliOn, setDettagliOn] = useState<boolean>(true);
    return (
        <Card
            key={"lotto_card_scheda_macello" + lotto.id}
            sx={{my: 2}}
            // expanded={expanded === "lotto_" + lotto.id}
            // onChange={handleChangeLottoPanel(lotto)}
        >
            <CardHeader
                sx={{pb: 1, pt: 1}}
                avatar={
                    <IconButton
                        sx={{ml: -1}}
                        aria-label="delete"
                        onClick={() => setDettagliOn(!dettagliOn)}
                    >
                        <Avatar
                            sx={{
                                width: "1.5rem",
                                height: "1.5rem",
                                bgcolor: theme.palette.primary.main,
                                color: "white",
                            }}
                            aria-label="recipe"
                        >
                            {lotto.ordinamento_lotto}
                        </Avatar>
                    </IconButton>
                }
                title={
                    <Grid container spacing={1} justifyContent="flex-start">
                        <Grid size={{sm: 2}}>
                            <Box>
                                <Typography variant="h6">{lotto.committente_desc}</Typography>
                                <Typography variant="body1">
                                    {Intl.NumberFormat("it-IT").format(lotto.numero_capi || 0) +
                                        " " +
                                        lotto.tipo_capo_desc +
                                        " " +
                                        (lotto.colore ? " " + getColore(lotto) : "")}
                                </Typography>
                                <Typography variant="body1">
                                    {lotto.codice_tracciabilita}
                                </Typography>
                                <Typography variant="body1">
                                    <em>{lotto.produttore_pack?.nome}</em>
                                </Typography>
                            </Box>

                        </Grid>
                        <Grid size={{sm: 2}}>
                            <Box>
                                {lotto.num_tz_eviscerati_pianificati_lotto != 0 && (
                                    <Typography variant="h6">
                                        {"TZ eviscer.: " +
                                            lotto.num_tz_eviscerati_pianificati_lotto}
                                    </Typography>
                                )}
                                {lotto.num_tz_pianificati_lotto != 0 && (
                                    <Typography variant="h6">
                                        {"TZ: " + lotto.num_tz_pianificati_lotto}
                                    </Typography>
                                )}
                            </Box>
                        </Grid>
                        <Grid size={{sm: 8}}>
                            <Box
                                sx={{
                                    border: 1,
                                    borderColor: theme.palette.primary.main,
                                    p: 1
                                    // margin: 3,
                                }}
                            >
                                <SchedaMacelloInfoLotto lotto={lotto}/>
                            </Box>
                        </Grid>
                    </Grid>
                }
            />

            {schedaMacello && (
                <CardContent sx={{backgroundColor: theme.palette.grey[100],}}>
                    {dettagliOn && <SchedaMacelloForm
                        schedaMacello={schedaMacello}
                        saveSchedaMacello={saveSchedaMacello}
                    />}
                </CardContent>
            )}
            {dettagliOn && (
                <CardContent
                    sx={{
                        border: 1,
                        borderColor: theme.palette.primary.main,
                        margin: 3,
                    }}
                >
                    <SchedaMacelloInfoLotto lotto={lotto}/>
                </CardContent>
            )}
        </Card>
    );
};

export default SchedaMacelloCard;
