import React, { useEffect } from "react";
import { theme } from "../../../../theme";

import { useLocation } from "react-router-dom";
import {
	BarChart,
	Bar,
	LineChart,
	Line,
	CartesianGrid,
	XAxis,
	YAxis,
	ResponsiveContainer,
	Tooltip,
} from "recharts";

import NavigazioneMesiToolbar from "../../../common/dateUtils/NavigazioneMesiToolbar";

import { GraficoTipiCapoCommittenti } from "./graficiKpiSlice";

import { TipoCapo } from "../../anagrafiche/anagraficheSlice";

import { DateTime } from 'luxon';

interface KgTipiCapoGraficoProps {
	graficoTipiCapoCommittenti: GraficoTipiCapoCommittenti[];
	tipiCapo: TipoCapo[];
}

interface GraficoKgTipiCapo {
	nome: string;
	kg_cumulati: number;
}

const calcoloGraficoKgTipiCapo = (
	graficoTipiCapoCommittenti: GraficoTipiCapoCommittenti[],
	tipiCapo: TipoCapo[]
) => {
	/// calcoloGraficoKgTipiCapo = array con i dati cumulativi sui kg per tipo capo da esporre poi nel grafico (asseX: nome, asseY: kg_cumulati)

	const graficoKgTipiCapo: GraficoKgTipiCapo[] = tipiCapo.map((tipoCapo) =>
		graficoTipiCapoCommittenti
			.filter((array) => array.id == tipoCapo.id)
			.reduce(
				(
					previousValue: GraficoKgTipiCapo,
					currentValue: GraficoTipiCapoCommittenti
				) => {
					return {
						nome: tipoCapo.nome,
						kg_cumulati:
							previousValue.kg_cumulati + Number(currentValue.kg_totali || 0),
					};
				},
				{
					nome: tipoCapo.nome,
					kg_cumulati: 0,
				}
			)
	);
	return graficoKgTipiCapo;
};

const KgTipiCapoGrafico = ({
	graficoTipiCapoCommittenti,
	tipiCapo,
}: KgTipiCapoGraficoProps) => {
	

	const [graficoKgTipiCapo, setGraficoKgTipiCapo] = React.useState<
		GraficoKgTipiCapo[]
	>([]);

	useEffect(() => {
		setGraficoKgTipiCapo(
			calcoloGraficoKgTipiCapo(graficoTipiCapoCommittenti, tipiCapo)
		);
	}, [graficoTipiCapoCommittenti, tipiCapo]);

	return (
		<ResponsiveContainer>
			<BarChart
				margin={{
					top: 8,
					right: 5,
					left: 25,
					bottom: 5,
				}}
				data={graficoKgTipiCapo}
			>
				<XAxis dataKey="nome" />
				<YAxis />
				<CartesianGrid stroke="#aaa" strokeDasharray="5 5" />
				<Bar
					dataKey="kg_cumulati"
					barSize={30}
					fill={theme.palette.secondary.main}
				/>
			</BarChart>
		</ResponsiveContainer>
	);
};

export default KgTipiCapoGrafico;
