import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
	ErrorStatusTypes,
	ErrorsStack,
	parseErrorMessage,
} from "../../../common/errorsDeclarations";

import {
	Lotto,
	getLotto,
	PianificazioneStrutturaState,
} from "../../pianificazione/pianificazioneSlice";
import { SchedaCarico } from "../schedeCaricoSlice";
import * as consuntiviVivoApi from "../../../../api/consuntiviVivo/consuntiviVivoApi";
import { DateTime } from 'luxon';
import { toast } from "react-toastify";


export interface StatoConsuntivoVivo {
	stato: number;
	descrizione: string;
	messaggio: string;
}
export interface ConsuntivoVivo extends SchedaCarico {
	numero_capi_ritirati?: number | null;
	data_ora_partenza_sede?: Date | string;
	data_ora_inizio_carico?: Date | string;
	data_ora_fine_carico?: Date | string;
	data_ora_arrivo_pianificata?: Date | string;
	data_ora_arrivo_effettiva?: Date | string;
	data_ora_fine_turno?: Date | string;
	peso_medio_dichiarato?: number | null;
	peso_medio_arrivo?: number | null;
	calo_concordato?: boolean;
	tara_in_carico_motrice?: number | null;
	tara_in_carico_rimorchio?: number | null;
	lordo_in_carico_motrice?: number | null;
	lordo_in_carico_rimorchio?: number | null;
	lordo_in_arrivo_motrice?: number | null;
	lordo_in_arrivo_rimorchio?: number | null;
	tara_in_arrivo_motrice?: number | null;
	tara_in_arrivo_rimorchio?: number | null;
	numero_gabbioni_motrice?: number | null | string;
	numero_gabbioni_rimorchio?: number | null | string;
	peso_partenza?: number | null;
	peso_arrivo?: number | null;
	calo?: number | null;
	// tara_gabbie?: number | null;
	km_ar?: number | null;
	stato_consuntivo_vivo?: StatoConsuntivoVivo;
	note?: string;
	errorsStack?: ErrorsStack;
}
export interface ConsuntiviVivoState {
	count: number;
	page: number;
	num_pages: number;
	next?: URL;
	previous?: URL;
	results: SchedaCarico[];
	errorsStack: ErrorsStack;
}

export interface ConsuntivoVivoStrutturaState {
	consuntiviVivo: ConsuntiviVivoState;
}

const initialState: ConsuntivoVivoStrutturaState = {
	consuntiviVivo: {
		count: 0,
		page: 0,
		num_pages: 0,
		next: undefined,
		previous: undefined,
		results: [],
		errorsStack: { status: ErrorStatusTypes.OK },
	},
};

export const fetchConsuntiviVivo = createAsyncThunk(
	"consuntivoVivo/fetchConsuntiviVivo",
	async (dataRange?: { data_da?: Date; data_a?: Date }) => {
		return await consuntiviVivoApi.fetchConsuntiviVivo(
			dataRange?.data_da,
			dataRange?.data_a
		);
	}
);

export const getConsuntivoVivo = createAsyncThunk(
	"consuntivoVivo/getConsuntivoVivo",
	async (consuntivoVivoId: number) => {
		return await consuntiviVivoApi.getConsuntivoVivo(consuntivoVivoId);
	}
);

export const saveConsuntivoVivo = createAsyncThunk(
	"consuntivoVivo/saveConsuntivoVivo",
	async (consuntivoVivoToSave: ConsuntivoVivo, thunkApi) => {
		const pianificazioneState = thunkApi.getState() as {
			pianificazione: PianificazioneStrutturaState;
		};
		const lotto = pianificazioneState.pianificazione.lotti.results.find(
			(lotto) => lotto.id == consuntivoVivoToSave.lotto
		);

		// compensazione per comportamento anomali nella form
		if (consuntivoVivoToSave.numero_gabbioni_rimorchio == "") {
			consuntivoVivoToSave.numero_gabbioni_rimorchio = null;
		}
		if (consuntivoVivoToSave.numero_gabbioni_motrice == "") {
			consuntivoVivoToSave.numero_gabbioni_motrice = null;
		}
		console.log('consuntivoVivoToSave =====>>>', consuntivoVivoToSave);
		return await consuntiviVivoApi
			.saveConsuntivoVivo(consuntivoVivoToSave)
			.then((response) => {
				thunkApi.dispatch(getLotto(lotto?.id || 0));
				return response;
			});
	}
);

export const consuntiviVivoSlice = createSlice({
	name: "consuntiviVivoState",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// fetch Consuntivi Vivo
		builder.addCase(fetchConsuntiviVivo.pending, (state, action) => {
			state.consuntiviVivo.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(fetchConsuntiviVivo.fulfilled, (state, action) => {
			state.consuntiviVivo = action.payload;

			state.consuntiviVivo.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(fetchConsuntiviVivo.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.consuntiviVivo.errorsStack = parseErrorMessage(action.error);
		});

		// get Consuntivo Vivo
		builder.addCase(getConsuntivoVivo.pending, (state, action) => {
			state.consuntiviVivo.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(getConsuntivoVivo.fulfilled, (state, action) => {
			state.consuntiviVivo.results = state.consuntiviVivo.results.map(
				(consuntivoVivo) => {
					if (consuntivoVivo.id == action.payload.id) {
						return action.payload;
					} else {
						return consuntivoVivo;
					}
				}
			);
			state.consuntiviVivo.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(getConsuntivoVivo.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.consuntiviVivo.errorsStack = parseErrorMessage(action.error);
		});

		// save Consuntivo Vivo
		builder.addCase(saveConsuntivoVivo.pending, (state, action) => {
			state.consuntiviVivo.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(saveConsuntivoVivo.fulfilled, (state, action) => {
			state.consuntiviVivo.results = state.consuntiviVivo.results.map(
				(consuntivoVivo) => {
					if (consuntivoVivo.id == action.payload.id) {
						return action.payload;
					} else {
						return consuntivoVivo;
					}
				}
			);
			state.consuntiviVivo.errorsStack = {
				status: ErrorStatusTypes.SUCCESS,
			};
			toast.success("Consuntivo vivo salvato.");
		});
		builder.addCase(saveConsuntivoVivo.rejected, (state, action) => {
			state.consuntiviVivo.results = state.consuntiviVivo.results.map(
				(consuntivoVivo) => {
					if (consuntivoVivo.id == action.meta.arg.id) {
						return {
							...consuntivoVivo,
							errorsStack: parseErrorMessage(action.error)
						};
					} else {
						return consuntivoVivo;
					}
				}
			);
			toast.error("Errore:" + action?.error?.message || "");
			state.consuntiviVivo.errorsStack = parseErrorMessage(action.error);
		});
	},
});

// Action creators are generated for each case reducer function
export const {} = consuntiviVivoSlice.actions;

export const consuntiviVivoSliceReducer = consuntiviVivoSlice.reducer;
