import React from "react";
import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";

import {
	EventoManutenzione,
	saveEventoManutenzione,
} from "./manutenzioneMezziSlice";
import { useAppDispatch, RootState } from "../../../redux/store";
import { useNavigate } from "react-router-dom";
import { DateTime } from 'luxon';
import { Camion, fetchCamions } from "../anagrafiche/anagraficheSlice";

import { useForm, SubmitHandler, Controller, set } from "react-hook-form";
import ReactHookFormSelect from "../../common/ReactHookFormSelect";

import MenuItem from "@mui/material/MenuItem";
import { Routes_path } from "../../routerApp";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import Stack from "@mui/material/Stack";
import { NumericFormat, NumericFormatProps } from "react-number-format";

import Paper from "@mui/material/Paper";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import Alert from "../../common/Alert";
import Typography from "@mui/material/Typography";

import { Button, Divider } from "@mui/material";

import ButtonGroup from "@mui/material/ButtonGroup";
import { theme } from "../../../theme";
import {convertToDateTime} from "../../common/dateUtils/convertToDateTime";

interface EventoManutenzioneFormProps {
	eventoManutenzione: EventoManutenzione;
}

const EventoManutenzioneForm = ({
	eventoManutenzione,
}: EventoManutenzioneFormProps) => {
	const {
		handleSubmit,
		register,
		formState: { errors, isDirty, isValid },
		setValue,
		watch,
		getValues,
		control,
		setError,
		clearErrors,
		reset,
	} = useForm<EventoManutenzione>();

	const listaCampi = Object.keys(eventoManutenzione) as Array<
		keyof EventoManutenzione
	>;

	const dispatch = useAppDispatch();

	const camions: Camion[] = useSelector<RootState, Camion[]>((state) =>
		state.anagrafiche.camions.results.filter((c) => c.proprieta == true)
	);
	const navigate = useNavigate();
	const [pesonaFisica, setPesonaFisica] = useState<boolean>(true);

	useEffect(() => {
		reset();
		listaCampi.map((field) => {
			setValue(field, eventoManutenzione[field]);
		});
	}, [
		listaCampi.forEach((field) => {
			eventoManutenzione[field];
		}),
		eventoManutenzione.id,
	]);

	useEffect(() => {
		clearErrors();
		listaCampi.forEach((field) => {
			eventoManutenzione?.errorsStack?.fieldsErrors &&
				eventoManutenzione.errorsStack.fieldsErrors[field] &&
				setError(field, {
					type: "server",
					message:
						eventoManutenzione.errorsStack.fieldsErrors[field].toString(),
				});
		});
	}, [
		eventoManutenzione?.errorsStack?.fieldsErrors,
		// EventoManutenzione.allevamento_pack?.errorsStack?.fieldsErrors,
	]);

	const handlerSaveEventoManutenzione = (
		eventoManutenzione: EventoManutenzione
	) => {
		dispatch(saveEventoManutenzione(eventoManutenzione));
	};

	const onSubmit: SubmitHandler<EventoManutenzione> = (eventoManutenzione) => {
		handlerSaveEventoManutenzione(eventoManutenzione);
	};

	return (
		<Box sx={{ my: 1, pt: 2 }}>
			<form onSubmit={handleSubmit(onSubmit)}>
				{eventoManutenzione?.errorsStack?.fieldsErrors?.non_field_errors && (
					<Alert severity="error">
						{eventoManutenzione?.errorsStack?.fieldsErrors?.non_field_errors.toString()}
					</Alert>
				)}
				<Grid container spacing={2} sx={{ mb: 2 }}>
					<Grid size={{ xs:6, sm:4, md:2 }}>
						<ReactHookFormSelect
							name="camion"
							label="camion"
							register={register}
							control={control}
							watch={watch}
							variant="outlined"
							error={errors?.camion?.message}
							sx={{ width: "100%" }}
						>
							{camions
								.filter((camion) => !camion.in_manutenzione)
								.map((option) => {
									return (
										<MenuItem key={option.id} value={option.id}>
											{option.nome} - {option.targa}
										</MenuItem>
									);
								})}
						</ReactHookFormSelect>
					</Grid>
					<Grid size={{ xs:6, sm:4, md:2 }}>
						<Controller
							name="km_evento"
							control={control}
							render={({ field }) => (
								<NumericFormat
									id="km_evento"
									customInput={TextField}
									variant="outlined"
									label="km a/r"
									InputLabelProps={{
										shrink: true,
									}}
									error={!!errors?.km_evento}
									helperText={errors?.km_evento?.message}
									// {...register("km_evento")}
									value={field.value}
									autoComplete="off"
									prefix="km "
									thousandSeparator="."
									decimalSeparator=","
									valueIsNumericString={true}
									onValueChange={(v) => {
										setValue("km_evento", Number(v.value), {
											shouldDirty: true,
										});
									}}
									sx={{ width: "100%" }}
								/>
							)}
						/>
					</Grid>

					<Grid size={{ xs:6, sm:4, md:2 }}>
						<ReactHookFormSelect
							name="tipo_manutentore"
							label="tipo intevento"
							register={register}
							control={control}
							watch={watch}
							variant="outlined"
							error={errors?.tipo_manutentore?.message}
							sx={{ width: "100%" }}
						>
							<MenuItem key={"I"} value={"I"}>
								Interna Iolepec
							</MenuItem>
							<MenuItem key={"E"} value={"E"}>
								Officina Esterna
							</MenuItem>
						</ReactHookFormSelect>
					</Grid>
					<Grid size={{ xs:12, sm:6, md:6, lg:6 }}>
						<TextField
							label="manutentore"
							// disabled={schedaCarico.numero_capi_pianificati}
							variant="outlined"
							InputLabelProps={{
								shrink: true,
							}}
							{...register("manutentore")}
							error={!!errors?.manutentore}
							helperText={errors?.manutentore?.message}
							autoComplete={"off"}
							sx={{ width: "100%" }}
							fullWidth
							// onBlur={handlerDetailChaingSubmit}
						/>
					</Grid>

					<Grid size={{ xs:12 }}>
						<TextField
							label="descrizione intervento previsto"
							// disabled={schedaCarico.numero_capi_pianificati}
							variant="outlined"
							InputLabelProps={{
								shrink: true,
							}}
							multiline
							rows={4}
							{...register("descrizione")}
							error={!!errors?.descrizione}
							helperText={errors?.descrizione?.message}
							autoComplete={"off"}
							sx={{ width: "100%" }}
							fullWidth
							// onBlur={handlerDetailChaingSubmit}
						/>
					</Grid>
				</Grid>
				<Grid container spacing={2} sx={{ mb: 2 }}>
					<Grid size={{ sm:12, md:6 }}>
						<Divider sx={{ mx: 2 }}>Pianificazione</Divider>
						<Grid
							container
							spacing={4}
							sx={{ m: 2 }}
							justifyContent="center"
							alignItems="center"
						>
							<Grid size={{ sm:12, md:4 }}>
								<DatePicker
									label="data inizio pianificata"
									value={convertToDateTime(watch("data_inizio_pianificata"))}
									disabled={["E", "T"].includes(eventoManutenzione.stato)}
									slotProps={{
										textField: {
											required: false,
											error: !!errors?.data_inizio_pianificata,
											helperText: errors?.data_inizio_pianificata?.message,
											sx: {width: "100%"},
											autoComplete: "off",
											// disabled: !!richiestaConnessione.stato
										},
										field: {
											clearable: true,
										},
									}}
									onChange={(newValue: any) => {
										setValue(
											"data_inizio_pianificata",
											newValue ? new Date(newValue) : "",
											{
												shouldDirty: true,
											}
										);
										clearErrors("data_inizio_pianificata");
									}}
								/>
							</Grid>
							<Grid size={{ sm:12, md:4 }}>
								<DatePicker
									label="data fine pianificata"
									value={convertToDateTime(watch("data_fine_pianificata"))}
									disabled={["E", "T"].includes(eventoManutenzione.stato)}
									slotProps={{
										textField: {
											required: false,
											error: !!errors?.data_fine_pianificata,
											helperText: errors?.data_fine_pianificata?.message,
											sx: {width: "100%"},
											autoComplete: "off",
											// disabled: !!richiestaConnessione.stato
										},
										field: {
											clearable: true,
										},
									}}
									onChange={(newValue: any) => {
										setValue(
											"data_fine_pianificata",
											newValue ? new Date(newValue) : "",
											{
												shouldDirty: true,
											}
										);
										clearErrors("data_fine_pianificata");
									}}
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid size={{ sm:12, md:6 }}>
						<Divider sx={{ mx: 2 }}>Esecuzione</Divider>
						<Grid
							container
							spacing={4}
							sx={{ m: 2 }}
							justifyContent="center"
							alignItems="center"
						>
							<Grid size={{ sm:12, md:4 }}>
								<DatePicker
									label="data inizio manutenzione"
									value={convertToDateTime(watch("data_inizio_manutenzione"))}
									disabled={eventoManutenzione.stato == "P"}
									slotProps={{
										textField: {
											required: false,
											error: !!errors?.data_inizio_manutenzione,
											helperText: errors?.data_inizio_manutenzione?.message,
											sx: {width: "100%"},
											autoComplete: "off",
											// disabled: !!richiestaConnessione.stato
										},
										field: {
											clearable: true,
										},
									}}
									onChange={(newValue: any) => {
										setValue(
											"data_inizio_manutenzione",
											newValue ? new Date(newValue) : "",
											{
												shouldDirty: true,
											}
										);
										clearErrors("data_inizio_manutenzione");
									}}
								/>
							</Grid>
							<Grid size={{ sm:12, md:4 }}>
								<DatePicker
									label="data fine manutenzione"
									value={convertToDateTime(watch("data_fine_manutenzione"))}
									disabled={eventoManutenzione.stato == "P"}
									slotProps={{
										textField: {
											required: false,
											error: !!errors?.data_fine_manutenzione,
											helperText: errors?.data_fine_manutenzione?.message,
											sx: {width: "100%"},
											autoComplete: "off",
											// disabled: !!richiestaConnessione.stato
										},
										field: {
											clearable: true,
										},
									}}
									onChange={(newValue: any) => {
										setValue(
											"data_fine_manutenzione",
											newValue ? new Date(newValue) : "",
											{
												shouldDirty: true,
											}
										);
										clearErrors("data_fine_manutenzione");
									}}
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid size={{ xs:10}}>
						<TextField
							label="descrizione intervento fatto"
							variant="outlined"
							disabled={eventoManutenzione.stato == "P"}
							InputLabelProps={{
								shrink: true,
							}}
							multiline
							rows={4}
							{...register("note")}
							error={!!errors?.note}
							helperText={errors?.note?.message}
							autoComplete={"off"}
							sx={{ width: "100%" }}
							fullWidth
							// onBlur={handlerDetailChaingSubmit}
						/>
					</Grid>
					<Grid size={{  xs:2}}>
						<Controller
							name="costo"
							control={control}
							render={({ field }) => (
								<NumericFormat
									id="costo"
									customInput={TextField}
									variant="outlined"
									size="small"
									label="costo"
									InputLabelProps={{
										shrink: true,
									}}
									error={!!errors?.costo}
									helperText={errors?.costo?.message}
									{...register("costo")}
									// value={Intl.NumberFormat("it-IT").format(field.value || 0)}
									// value={Intl.NumberFormat("it-IT").format(getValues("costo") || 0)}
									value={field.value}
									autoComplete="off"
									prefix="€ "
									thousandSeparator="."
									decimalSeparator=","
									valueIsNumericString={true}
									onValueChange={(v) => {
										setValue("costo", Number(v.value));
									}}
									sx={{ width: "100%" }}
									// onBlur={handlerDetailChaingSubmit}
								/>
							)}
						/>
					</Grid>
				</Grid>
				<ButtonGroup variant="contained" aria-label="Basic button group">
					<Button disabled={!isDirty} type="submit">
						Salva
					</Button>
					<Button
						disabled={!isDirty}
						type="submit"
						onClick={() => {
							handleSubmit(onSubmit)();
							navigate(Routes_path.MANUTENZIONE_MEZZI);
						}}
					>
						Salva e chiudi
					</Button>
					<Button
						disabled={eventoManutenzione.stato == "P"}
						type="submit"
						color="info"
						sx={{
							display: ["P"].includes(eventoManutenzione.stato)
								? "none"
								: "inherit",
						}}
						onClick={() => {
							// settiamo lo stato e poi sottomettiamo il form
							setValue("stato", "P", {
								shouldDirty: true,
							});
							handleSubmit(onSubmit)();
						}}
					>
						Torna in pianificazione
					</Button>
					<Button
						disabled={eventoManutenzione.stato == "E"}
						type="submit"
						color="warning"
						sx={{
							display: ["E", "T"].includes(eventoManutenzione.stato)
								? "none"
								: "inherit",
						}}
						onClick={() => {
							// settiamo lo stato e poi sottomettiamo il form
							setValue("data_inizio_manutenzione", new Date(), {
								shouldDirty: true,
							});
							setValue("stato", "E", {
								shouldDirty: true,
							});
							handleSubmit(onSubmit)();
						}}
					>
						Inizio Manutenzione
					</Button>
					<Button
						disabled={eventoManutenzione.stato != "E"}
						type="submit"
						color="success"
						// sx={{ display: "none" }}
						sx={{
							display: eventoManutenzione.stato == "E" ? "inherit" : "none",
						}}
						onClick={() => {
							const data_fine_manutenzione = getValues(
								"data_fine_manutenzione"
							);
							if (!data_fine_manutenzione) {
								setValue("data_fine_manutenzione", new Date(), {
									shouldDirty: true,
								});
							}
							// settiamo lo stato e poi sottomettiamo il form
							setValue("stato", "T", {
								shouldDirty: true,
							});
							handleSubmit(onSubmit)();
						}}
					>
						Fine Manutenzione
					</Button>
				</ButtonGroup>
			</form>
		</Box>
	);
};

export default EventoManutenzioneForm;
